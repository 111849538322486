<template>
	<div>
		<b-col class="quickreply-list-scrollable scrollbar">  
			<div class="ml-4 mt-3">
				<b-row>  
					<h3 class="lbl-title">Mensagens automáticas</h3>  
				</b-row>
				<b-row>           
					<span class="custom-font-open-18-400">Configure mensagens que devem ser enviadas pelo sistema, baseado na sessão iniciada pelos seus contatos.</span>
				</b-row>  
				<b-row>
					<div class="w-100">
						<hr>
					</div>
				</b-row>  
			</div>
			<b-row class="ml-1 mb-2">
				<b-col md="auto" class="mt-2">
					<span class="custom-font-open-16-400" style="line-height: 30px;">Departamentos:</span>
				</b-col>
				<b-col>
					<div>
						<b-dropdown id="dropdown-form" 
							:text="currentDepartment" v-b-tooltip.hover class="custom-font-open-12 mb-2 ml-1" :class="this.$mq != 'xs' ? 'my-dropdown' : 'my-dropdown-mobile'"
							menu-class="menu-style"
							ref="dropdown"
							variant="ligth"
						>
							<div class="search">  
								<b-input-group>
									<b-form-input 
										type="search"                     
										placeholder="Departamento"
										v-model="search"
										class="mt-1 ml-3"
										@update="clearFilter">
									</b-form-input>
									<b-input-group-prepend is-text class="mt-1">
										<b-icon icon="search"></b-icon>
									</b-input-group-prepend>
								</b-input-group>
							</div>
							<hr>
							<b-dropdown-item v-for="x in departmentFilteredList"
								:key="x.departmentId"
								:value="x.departmentId" @click="selectDepartment(x.departmentId, x.departmentName)"> 
								<b-row class="ml-0 mt-1 my-dropdown2 flex-nowrap">  
									<span class="ml-2 mr-2 ">
										<div v-if="x.departmentName != undefined" class="avatar" :style="x.avatar">{{ x.departmentName.substring(0,2).toUpperCase() }}</div>
										<div v-else class="avatar"></div>
									</span>         
									<span class="td-data ml-1" style="margin-top:3px">{{x.departmentName}}</span>   
								</b-row>                                             
							</b-dropdown-item> 
						</b-dropdown>
					</div>
				</b-col>
			</b-row>        
			<b-row class="ml-2">
				<b-form-checkbox v-model="checked" name="check-button" switch :disabled="disableOptions" @change="setType(1, checked)">
					<span class="custom-font-open-16-400">Boas vindas - mensagem que será enviada assim que a sessão for aberta.</span>
				</b-form-checkbox>              
			</b-row>
			<div v-show="checked == true">
				<div v-if="automationTriggerDescription1 != ''">
					<b-row class="mt-4 ml-4">
						<span class="message-tag">{{automationTriggerDescription1}}</span>
					</b-row>
					<b-row>
						<b-col class="ml-5">
							<span v-if="automationTriggerType1 == 1" class="custom-font-roboto-12">*Mensagem de Respostas Rápidas</span>
							<span v-else-if="automationTriggerType1 == 2" class="custom-font-roboto-12">*Mensagem de template</span>
							<span v-else-if="automationTriggerType1 == 3" class="custom-font-roboto-12">*Mensagem de Fluxo Chatbot</span>
						</b-col>
						<b-col class="ml-4">
							<message-options :departmentId="departmentId" 
								:action="1" 
								:actionDescription="'Alterar mensagem'"
								:automationType="1"
								v-on:sendMessageData="getMessageData"/>
						</b-col>                                       
					</b-row>
				</div>
				<b-row v-else class="mt-2 ml-4">
					<message-options :departmentId="departmentId"
						:action="2" 
						:actionDescription="'+ Adicionar mensagem'"
						:automationType="1"
						v-on:sendMessageData="getMessageData"/>
				</b-row>   
			</div>
			<b-row class="ml-2 mt-3">
				<b-form-checkbox v-model="checked4" name="check-button" switch :disabled="disableOptions" @change="setType(4, checked4)">
					<span class="custom-font-open-16-400">Palavra Chave - Quando a mensagem do cliente não ativar nenhuma palavra chave, enviar a seguinte resposta.</span>
				</b-form-checkbox>              
			</b-row>
			<div v-show="checked4 == true">
				<div v-if="automationTriggerDescription4 != ''">
					<b-row class="mt-4 ml-4">
						<span class="message-tag">{{automationTriggerDescription4}}</span>
					</b-row>
					<b-row>
						<b-col class="ml-5">
							<span v-if="automationTriggerType4 == 1" class="custom-font-roboto-12">*Mensagem de Respostas Rápidas</span>
							<span v-else class="custom-font-roboto-12">*Mensagem de template</span>
						</b-col>
						<b-col class="ml-4">
							<message-options :departmentId="departmentId" 
								:action="1" 
								:actionDescription="'Alterar mensagem'"
								:automationType="4"
								v-on:sendMessageData="getMessageData"/>
						</b-col>                                       
					</b-row>
				</div>
				<b-row v-else class="mt-2 ml-4">
					<message-options :departmentId="departmentId"
						:action="2" 
						:actionDescription="'+ Adicionar mensagem'"
						:automationType="4"
						v-on:sendMessageData="getMessageData"/>
				</b-row>   
			</div>
    
			<b-row class="ml-2 mt-3">
				<b-form-checkbox v-model="checked2" name="check-button" switch :disabled="disableOptions" @change="setType(2, checked2)">
					<span class="custom-font-open-16-400">Ausência - mensagem que será enviada quando uma sessão for aberta fora do horário de atendimento.</span>
				</b-form-checkbox>              
			</b-row>
			<div v-show="checked2 == true">
				<div v-if="automationTriggerDescription2 != ''">
					<b-row class="mt-4 ml-4">
						<span class="message-tag">{{automationTriggerDescription2}}</span>
					</b-row>
					<b-row>
						<b-col class="ml-5">
							<span v-if="automationTriggerType2 == 1" class="custom-font-roboto-12">*Mensagem de Respostas Rápidas</span>
							<span v-else class="custom-font-roboto-12">*Mensagem de template</span>
						</b-col>
						<b-col class="ml-4">
							<message-options :departmentId="departmentId" 
								:action="1" 
								:actionDescription="'Alterar mensagem'"
								:automationType="2"
								v-on:sendMessageData="getMessageData"/>
						</b-col>                                       
					</b-row>
				</div>
				<b-row v-else class="mt-2 ml-4">
					<message-options :departmentId="departmentId" 
						:action="2" 
						:actionDescription="'+ Adicionar mensagem'"
						:automationType="2"
						v-on:sendMessageData="getMessageData"/>
				</b-row>    
			</div>
        
			<b-row class="ml-2 mt-3">
				<b-form-checkbox v-model="checked3" name="check-button" switch :disabled="disableOptions" @change="setType(3, checked3)">
					<span class="custom-font-open-16-400">Fim da Sessão - mensagem que será enviada quando faltarem 30 minutos para o fim da sessão de 24 horas.</span>
				</b-form-checkbox>              
			</b-row>     
			<div v-show="checked3 == true">
				<div v-if="automationTriggerDescription3 != ''">
					<b-row class="mt-4 ml-4">
						<span class="message-tag">{{automationTriggerDescription3}}</span>
					</b-row>
					<b-row>
						<b-col class="ml-5">
							<span v-if="automationTriggerType3 == 1" class="custom-font-roboto-12">*Mensagem de Respostas Rápidas</span>
							<span v-else class="custom-font-roboto-12">*Mensagem de template</span>
						</b-col>
						<b-col class="ml-4">
							<message-options :departmentId="departmentId" 
								:action="1" 
								:actionDescription="'Alterar mensagem'"
								:automationType="3"
								v-on:sendMessageData="getMessageData"/>
						</b-col>                                       
					</b-row>
				</div>
				<b-row v-else class="mt-2 ml-4">
					<message-options :departmentId="departmentId" 
						:action="2" 
						:actionDescription="'+ Adicionar mensagem'"
						:automationType="3"
						v-on:sendMessageData="getMessageData"/>
				</b-row>   
			</div>                                   
		</b-col>              
	</div>                                          
</template>

<script>
import WAutomationAPI from '@/api/WAutomationAPI'
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import Vue2Filters from 'vue2-filters'
import { avatarUrl} from '@/global'
import WAutomaticMessageOptions from './WAutomaticMessageOptions'
export default {
	name: 'WAutomaticMessages',
	mixins: [Vue2Filters.mixin], 
	props: [
		'step'
	],   
	components: {
		'message-options': WAutomaticMessageOptions
	},
	computed:{
		departmentFilteredList () {
			let teste = this.departmentOrderedList			
			return teste.filter(item => {
				return item[this.sortBy].toLowerCase().includes(this.search.toLowerCase())
			})
		},
		departmentOrderedList () {
			const items = this.departments	
				
			if(items){
				items.sort(this.dynamicSort([this.sortBy]))	
			}										
			return items
		}, 
	},
	data () {
		return { 
			addAction: false,
			btnDisable: true,
			checked: false,
			checked2: false,
			checked3: false,    
			checked4: false,           
			filter: '',
			currentDepartmentIndex: null,
			avtUrl: avatarUrl,            
			departments: [],
			currentDepartment: 'Selecionar',
			departmentId: '',
			automationTriggerType1: 0,
			automationTriggerType2: 0,
			automationTriggerType3: 0,
			automationTriggerType4: 0,
			automationTriggerDescription1: '',
			automationTriggerDescription2: '',
			automationTriggerDescription3: '',
			automationTriggerDescription4: '',
			automationType: 0,
			disableOptions: true,
			search: '',
			sortBy:'departmentName'
		}
	},    
	methods: {
		getAvatarUrl (id) {
			return avatarUrl + this.$route.params.companyId + '&imageId='+id
		},         
		setType (type, checked) {
			this.automationType = type
			if (checked == true ){
				WAutomationAPI.postEnable(this.$route.params.companyId, this.departmentId, type, response => {  
					if (response){
						console.log('Operação efetuada com sucesso!')                       
					} else {
						alert('Falha ao habilitar departamentos!')
					}                                      
				})                 
			} else {
				WAutomationAPI.postDisable(this.$route.params.companyId, this.departmentId, type, response => {  
					if (response){
						console.log('Operação efetuada com sucesso!')                                
					} else {
						alert('Falha ao desabilitar departamentos!')
					}                                          
				})  
			}
		},
		getMessageData (msg) {
			console.log(msg)
			this.getDepartmentById(this.departmentId)
		},        
		getDepartmentList () {
			WDepartmentsAPI.getDepartmentsOfUser(this.$route.params.companyId, response => {
				let departments = response.data
				this.departments = departments         
			})
		},   
		getDepartmentById (departmentId) {
			this.checked = false
			this.checked3 = false
			this.checked4 = false
			this.automationTriggerDescription1 = ''    
			this.automationTriggerDescription2 = ''    
			this.automationTriggerDescription3 = ''
			this.automationTriggerDescription4 = ''
			WAutomationAPI.getDepartmentAutomation(this.$route.params.companyId, departmentId, response => {
				let departments = response.data
				console.log(departments)
				for (const key in departments){
					let department = departments[key]                
					if (department.automationType == 1 && department.automationTriggerDescription != '-'){
						this.checked = department.enabled
						this.automationTriggerType1 = department.automationTriggerType
						this.automationTriggerDescription1 = department.automationTriggerDescription
					} 
					if (department.automationType == 2 && department.automationTriggerDescription != '-'){
						this.checked2 = department.enabled
						this.automationTriggerType2 = department.automationTriggerType
						this.automationTriggerDescription2 = department.automationTriggerDescription
					}       
					if (department.automationType == 3 && department.automationTriggerDescription != '-'){
						this.checked3 = department.enabled
						this.automationTriggerType3 = department.automationTriggerType
						this.automationTriggerDescription3 = department.automationTriggerDescription
					} 
					if (department.automationType == 4 && department.automationTriggerDescription != '-'){
						this.checked4 = department.enabled
						this.automationTriggerType4 = department.automationTriggerType
						this.automationTriggerDescription4 = department.automationTriggerDescription
					} 
				}
			})
		},                   
		selectDepartment (departmentId, departmentName) {
			this.disableOptions = false
			this.currentDepartment = departmentName
			this.departmentId = departmentId       
			this.getDepartmentById(departmentId)      
		},   
		dynamicSort (fields) {
			return function (a, b) {
				return fields
					.map(function (o) {
						let dir = 1
						if (o[0] === '-') {
							dir = -1
							o=o.substring(1)
						}
						if (a[o].toLowerCase() > b[o].toLowerCase()) 
							return dir
						if (a[o].toLowerCase() < b[o].toLowerCase()) 
							return -(dir)
						return 0
					})
					.reduce(function firstNonZeroValue (p,n) {
						return p ? p : n
					}, 0)
			}
		}, 
		clearFilter () {
			if(this.search == ''){
				this.departmentFilteredList()
			}
		}                            
	},
	mounted () {       
		this.getDepartmentList()                               
	}, 
}
</script>

<style scoped>
.quickreply-list-scrollable {
    height: 88vh;
    width: 100%;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
}
    .search input { 
        border: none !important;
        width: 50% !important;
    }    
    .btn-light {
        color: #212529;
        background-color:  #F5F5F5;
        border-color:  #F5F5F5;
    }  
    .mylabel {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #777777;
        cursor: pointer; 
    }
    .tempTitle {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: #777777;    
    }  
    .tempSubTitle {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #999999;            
    }
    .bcn-card-custom-type {
        width: 150px;
        height: 150px;
        max-width: 150px;
        max-height: 150px;     
        border-radius: 16px;    
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;  
        cursor: pointer;
    }

    .bcn-box-shadow {
        background: #FFFFFF;
        box-shadow: 0px 1px 4px 2px rgba(119, 119, 119, 0.2);
        border-radius: 16px;
    }  

    .bcn-box-shadow-check {
        background: #FFFFFF;
        border: 1px solid var(--primary);
        box-sizing: border-box;
        box-shadow: 0px 0px 8px rgba(187, 26, 94, 0.3);
        border-radius: 16px;
    }    
    .message-tag {
        padding: 6px;
        background: #F5F5F5;
        border-radius: 100px;  
        font-family: 'Roboto';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #999999;          
    }

	/* .my-dropdown2 > span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	flex-wrap: nowrap;
	max-width: 200px;
} */
.menu-style{
		width: 25vw;
	}
</style>

 
