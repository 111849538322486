import axios from 'axios'
import i18n from '../plugins/i18n'
import { baseApiUrl, access_token} from '../global'


axios.defaults.headers.common['Authorization'] = `bearer ${access_token}`


export default {
	//Get api
	getByCompany: (companyId, callback) => {		
		const url = `${baseApiUrl}`+'chatflow/'+ companyId +'/list'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	}, 	 	
	//Post api createDraft
	postCreateDraft (companyId, flowName, callback) {
		const params = new URLSearchParams()
		params.append('flowName', flowName)    			       
		let url = `${baseApiUrl}` + 'chatflow/'+ companyId + '/createDraft'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	}, 	
	//Post api postUpdateTitle	
	postUpdateTitle (companyId, chatflowId, title, callback) {
		const params = new URLSearchParams()
		params.append('title', title)    			       
		let url = `${baseApiUrl}` + 'chatflow/'+ companyId + '/' + chatflowId + '/updateTitle'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error) 
				callback(false)               
			})    
	}, 				
	//Post api disableChatflow
	postDisableChatflow (companyId, chatflowId, callback) {	  			       
		let url = `${baseApiUrl}` + 'chatflow/'+ companyId + '/' + chatflowId + '/disableChatflow'
		axios.post(url)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)       
				callback(false)          
			})    
	}, 	
	//Post api enableChatflow
	postEnableChatflow (companyId, chatflowId, callback) {	  			       
		let url = `${baseApiUrl}` + 'chatflow/'+ companyId + '/' + chatflowId + '/enableChatflow'
		axios.post(url)
			.then(response => {
				if (response){                
					callback(true)  
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)       
				callback(false)         
			})    
	}, 	
	//Delete api deleteChatflow
	deleteChatflow (companyId, chatflowId, callback) {            
		let url = `${baseApiUrl}` + 'chatflow/'+ companyId + '/' + chatflowId+ '/deleteChatflow'
		axios.delete(url)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)       
				callback(false)         
			})    
	},  	
	postDepartments (companyId, chatflowId, departmentAll, departmentIdList, callback) {
		const params = new URLSearchParams() 
		params.append('departmentAll', departmentAll)     
		params.append('departmentIdList', departmentIdList)           
		let url = `${baseApiUrl}` + 'chatflow/'+ companyId + '/' + chatflowId + '/departments'
		axios.post(url, params)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},
	//Post api updateDraft
	postUpdateDraft (companyId, chatflowId, visual, edges, callback) {
		const params = new URLSearchParams()
		params.append('visual', visual)    	
		params.append('edges', edges)		       
		let url = `${baseApiUrl}` + 'chatflow/'+ companyId + '/' + chatflowId + '/updateDraft'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	}, 		
	//Post api editChatflow
	postEditChatflow (companyId, chatflowId, callback) {						
		let url = `${baseApiUrl}` + 'chatflow/'+ companyId + '/' + chatflowId + '/editChatflow'
		axios.post(url)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error) 
				callback(false)               
			})    
	},
	//Post api finishBuilder
	postFinishBuilder (companyId, chatflowId, callback) {						
		let url = `${baseApiUrl}` + 'chatflow/'+ companyId + '/' + chatflowId + '/finishBuilder'
		axios.post(url)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	}, 		
	//Post api publishChatflow
	postePublishChatflow (companyId, chatflowId, callback) {						
		let url = `${baseApiUrl}` + 'chatflow/'+ companyId + '/' + chatflowId + '/publishChatflow'
		axios.post(url)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	}, 	
	// Retrieve all chatflow based at a department
	getChatflowByDepartmentId: (companyId,departmentId, callback) => {		
		const url = `${baseApiUrl}`+'chatflow/'+ companyId +'/'+departmentId+'/chatflowByDepartmentId'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	}, 
}