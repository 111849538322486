<template>
	<div>
		<span v-if="item.labelColor">
			<span>                
				<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0.647582 8.40511L0.702352 1.62951C0.703679 1.45388 0.737848 1.28025 0.802908 1.11854C0.867968 0.956839 0.962642 0.810232 1.08152 0.687103C1.20039 0.563974 1.34113 0.466737 1.4957 0.400955C1.65026 0.335172 1.81562 0.302134 1.98231 0.303728L9.27913 0.364722C9.53738 0.366881 9.78414 0.477055 9.96511 0.671L13.7878 4.76773C13.9762 4.96963 13.9734 5.29435 13.7816 5.49302L9.89396 9.51984C9.70964 9.71076 9.46092 9.81673 9.20257 9.81441L1.90148 9.7489C1.56638 9.74484 1.24643 9.60124 1.01149 9.34946C0.776552 9.09768 0.645725 8.75818 0.647582 8.40511Z" :fill="item.labelColor"/>
				</svg>
				{{name}}
			</span>				
		</span>
		<span v-else-if="avt" class="td-attendant">
			<b-row class="ml-0">
				<b-avatar v-if="item.avatarCustom == true" :src="avt+id" class="avatar"></b-avatar> 
				<b-avatar v-else class="avatar " :style="item.avatar">{{ name.substring(0,2).toUpperCase() }}</b-avatar>
				<span class="mylabel mt-1 ml-1">{{name}}</span>
				<a @click="openNumberDetail(item)" class="mylabel mt-1 ml-1" style="color:#999;cursor:pointer" v-if="item.default && item.default == true">(Departamento Padrão)</a>
			</b-row>
											
		</span>
		

	</div>
</template>

<script>
import { avatarUrl} from '@/global'
export default {
	name: 'TableCellDepartment',
	data () {
		return {	
			avt : avatarUrl + this.$route.params.companyId + '&imageId='
		}
	},
	props: [
		'item',
		'name',
		'id',
	],
	methods:{
		openContactDetail (id, waId) {
			this.$emit('openContactDetail',id,waId)
		},
		openNumberDetail (item) {
			this.$emit('openNumberDetail',JSON.stringify(item))
		},
	},
	mounted () {
		// this.avt = avatarUrl + this.$route.params.companyId + '&imageId='+this.id 
	}
}

</script>

<style>
.td-attendant {
    margin-top: 20px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}
</style>