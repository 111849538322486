import axios from 'axios'
import { baseApiUrl} from '../global'
import i18n from '../plugins/i18n'

export default {    
	getRealTime: (companyId, phonenumberId , callback) => {
		const url = `${baseApiUrl}`+'dashboard/'+ companyId + '/' +phonenumberId +'/realtime'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},   
	getFinancial: (companyId, phonenumberId, startTime, endTime, callback) => {
		const url = `${baseApiUrl}`+'dashboard/'+ companyId + '/' +phonenumberId 
		+'/financial/' + startTime + '/' + endTime
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},   
	postAttendanceData (companyId, phonenumberId, startTime, endTime, departmentIdList, callback) {
		const params = new URLSearchParams()
		params.append('departmentIdList', departmentIdList)
		const url = `${baseApiUrl}`+'dashboard/'+ companyId + '/' +phonenumberId                                   		
		+'/attendance/' + startTime + '/' + endTime
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},   
	postPhoneNumberData (companyId, phonenumberId, startTime, endTime, departmentIdList, callback) {
		const params = new URLSearchParams()
		params.append('departmentIdList', departmentIdList)
		const url = `${baseApiUrl}`+'dashboard/'+ companyId + '/' +phonenumberId                                   		
		+'/phonenumber/' + startTime + '/' + endTime
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {				
				console.log(i18n.t('Message.error') + error)       
				callback(false)         
			})    
	},   
	postTeamData (companyId, phonenumberId, startTime, endTime, userIdList, departmentIdList, callback) {
		const params = new URLSearchParams()
		params.append('userIdList', userIdList)
		params.append('departmentIdList', departmentIdList)
		const url = `${baseApiUrl}`+'dashboard/'+ companyId + '/' +phonenumberId                                   		
		+'/team/' + startTime + '/' + endTime
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {				
				console.log(i18n.t('Message.error') + error)       
				callback(false)         
			})    
	}, 		
	postTeamAverageData (companyId, phonenumberId, startTime, endTime, userIdList, departmentIdList, callback) {
		const params = new URLSearchParams()
		params.append('userIdList', userIdList)
		params.append('departmentIdList', departmentIdList)
		const url = `${baseApiUrl}`+'dashboard/'+ companyId + '/' +phonenumberId                                   		
		+'/teamAverageTime/' + startTime + '/' + endTime
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {				
				console.log(i18n.t('Message.error') + error)       
				callback(false)         
			})    
	},
	postRealtimeReportContact (companyId, phonenumberId,reportType, callback) {
		const params = new URLSearchParams()
		const url = `${baseApiUrl}`+'dashboard/'+ companyId + '/' +phonenumberId                                   		
		+'/realtimeReport/' + reportType
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {				
				console.log(i18n.t('Message.error') + error)       
				callback(false)         
			})    
	}, 								
}