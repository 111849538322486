<template>
	<GChart
		id="chart"
		:type="type"
		:options="options"
		:data="collectionData"
		:resizeDebounce="100"
	/>    
</template>
 
<script>
import { GChart } from 'vue-google-charts'
export default {
	name: 'WDashboardChart',
	props: ['type', 'options', 'collectionData'],
	components: {
		GChart
	},
	data () {
		return {
		}
	}
}
</script>
<style>
/* google chart */
#chart rect[fill="#414141"] {
	padding: 25px;
}
.google-visualization-tooltip {
	border:solid 1px #bdbdbd;
	border-radius: 8px !important;
	background-color: white;
	position: absolute;
	box-shadow: 0px 2px 2px 0px rgba(204, 204, 204, 0.6);
	font-size: 12px;
	padding: 0px 20px 0px 20px !important;
	-moz-box-shadow: 0px 2px 2px 0px rgba(204, 204, 204, 0.6);
	-webkit-box-shadow: 0px 2px 2px 0px rgba(204, 204, 204, 0.6);
	width: auto !important;

}
.google-visualization-tooltip-action-list {
	list-style-type: none;
	margin: 0;
	padding: 0.5em 0em 0.5em 0em;
	cursor: hand;
}
.google-visualization-tooltip-action {
	margin: 0;
	cursor: pointer;
	padding: 0.5em 2em 0.5em 1em;
}
.google-visualization-tooltip-action:hover {
	background-color: #eeeeee;
}
.google-visualization-tooltip-item-list {
	list-style-type: none;
	margin: 1em 0 1em 0;
	padding: 0em;
}
.google-visualization-tooltip-item {
	margin-top: 5px !important;
	padding: 5px !important;
	border-top-style: none !important;
	border-bottom-style: ridge !important;
}
.google-visualization-tooltip-item-list
.google-visualization-tooltip-item:first-child {
	margin: 0 !important;
	text-align: left;
}
.google-visualization-tooltip-item-list
.google-visualization-tooltip-item:last-child {
	margin: 0 !important;
	border-bottom-style: none !important;
}
.google-visualization-tooltip-separator {
	margin: 0;
	padding: 0;
	height: 5px !important;
	background-color: white !important;
}
.google-visualization-tooltip-square {
	display: inline-block;
	/* IE does not support inline-block fall back to float left */
	float: left;
	clear: none;
	width: 1.1em !important;
	height: 1.1em !important;
	margin-top: 2px !important;
	margin: 0.16em 0.7em 0em 0em;
	border-bottom: solid 0.1em white;
}
</style>