<template>
	<b-col class="ml-5 mt-4">
		<b-row>
			<span class="lbl-title">Relatório de Envios</span>
		</b-row>
		<b-row>
			<span class="sucess-font">Sucesso!</span>
		</b-row>
		<b-row>
			<span class="custom-font-open-14-gray-normal">Você receberá seu relatório no e-mail:</span>
		</b-row>
		<br>
		<b-row>
			<span><b>{{profile.email}}</b></span>
		</b-row>
		<br>
		
		
		<b-row style="width:600px">
			<span class="custom-font-open-14-gray-normal">Seu relatório está sendo enviado em formato .csv para o e-mail acima. O envio do mesmo varia de acordo com o volume de dados selecionados podendo demorar até 1 hora. Qualquer dúvida, acione o nosso suporte.</span>
		</b-row>
		
		<b-row class="mt-3">
			<b-button class="send-button" @click="goBack">Finalizar</b-button>
		</b-row>
	</b-col>
</template>

<script>
import WUserAPI from '@/api/WUserAPI'
export default {
	data: function () {
		return {
			profile:{}
		}
	},
	methods: {
		getUserProfile () {
			WUserAPI.getUserProfile(response => {
				this.profile = response.data
			})  
		},
		goBack () {
			let obj = {step: 2}
			this.$emit('sendMessageData', obj)   
		},   		
	},
	mounted () {
		this.getUserProfile()
	},
	
}
</script>

<style scoped>
.sucess-font{
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 36px;
	color: var(--primary);
}
.send-button{
width: 180px;
height: 38px;
align-items: flex-start;
background: var(--primary);
border-radius: 8px;
color: white;
border-color: white;
font-family: Open Sans;
font-style: normal;
font-weight: 600;
font-size: 16px;
}

</style>