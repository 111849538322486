import axios from 'axios'
import i18n from '@/plugins/i18n'
import { baseApiUrl} from '@/global'

export default {    
	postSendTextMessage (companyId, phonenumberId, contact, text, callback) {  
		const params = new URLSearchParams()
		params.append('contact',contact)              
		params.append('text',text) 
		let url = `${baseApiUrl}` + 'message/' +companyId+ '/'+ phonenumberId + '/sendText'
		axios.post(url, params)
			.then(response => {
				if (response){                                
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},   

	postMessageId (companyId, phonenumberId, messageId, callback) {  
		const params = new URLSearchParams()
		params.append('messageId',messageId)              
		let url = `${baseApiUrl}` + 'message/' +companyId+ '/'+ phonenumberId + '/messageId'
		axios.post(url, params)
			.then(response => {
				if (response){                                
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},   
	postSendPixel (companyId, phonenumberId, contact, pixelId, eventName, callback) {  
		const params = new URLSearchParams()
		params.append('pixelId', pixelId)
		params.append('eventName', eventName)
		params.append('contact', contact)
		let url = `${baseApiUrl}` + 'message/'+ companyId + '/'+ phonenumberId  + '/sendPixel'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(response.data)       
				}
			})
			.catch(function (error) {
				alert(error) 
				callback(false)                    
			})       
	}, 
	postSendTemplate (companyId, phonenumberId, contact, templateId, departmentId, propertyMap, callback) {  
		const params = new URLSearchParams()
		params.append('templateId', templateId)
		params.append('departmentId', departmentId)
		params.append('contact', contact)
		params.append('propertyMap', propertyMap)        
		let url = `${baseApiUrl}` + 'message/'+ companyId + '/'+ phonenumberId  + '/sendTemplate'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(response.data)       
				}
			})
			.catch(function (error) {
				alert(error) 
				callback(false)                    
			})       
	},   
	postSendMedia (companyId, phonenumberId, mediaId, contact, text, mediaType, callback) { 
		const params = new URLSearchParams()
		params.append('mediaId',mediaId)              
		params.append('contact',contact)  
		if (text != ''){
			params.append('text',text)             
		}                     
		let url = `${baseApiUrl}` + 'message/' +companyId+ '/'+ phonenumberId + '/' + mediaType
		axios.post(url, params)
			.then(response => {
				if (response){                                
					callback(response.data)       
				}
			})
			.catch(function (error) {
				callback(error)
				console.log(i18n.t('Message.error') + error)                
			})       
	},  
        
	postCurrentIndexWithMessageIds (companyId, phonenumberId, contact, messageIds,isTabActive, callback) { 
		const params = new URLSearchParams()        
		params.append('contact',contact)
		params.append('messageIds',messageIds)  
		params.append('isTabActive',isTabActive)           
		let url = `${baseApiUrl}` + 'phonenumber/' +companyId+ '/'+ phonenumberId + '/currentIndexWithMessageIds'
		axios.post(url, params)
			.then(response => {
				if (response){                                
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},         

	postUploadMedia (companyId, phonenumberId, file, callback) {  
		let config = { headers: { 'Content-Type': 'multipart/form-data'}}          
		let url = `${baseApiUrl}` + 'message/uploadMedia/' + companyId + '/' + phonenumberId 
		axios.post(url, file, config)
			.then(response => {
				if (response){                                
					callback(response.data)        
				}
			})
			.catch(function (error) {
				callback(undefined)
				// store.commit('shiftFileQueue')
				console.log(i18n.t('Message.error') + error)                
			})    
	},    
	postLastMessages (companyId, phonenumberId, contact, callback) { 
		const params = new URLSearchParams()         
		params.append('contact',contact)          
		let url = `${baseApiUrl}` + 'phonenumber/' +companyId+ '/'+ phonenumberId + '/lastMessages'
		axios.post(url, params)
			.then(response => {
				if (response){                                
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},   
       
	postError (companyId, phonenumberId, messageId, callback) {      
		const params = new URLSearchParams()         
		params.append('messageId',messageId)               
		let url = `${baseApiUrl}` + 'message/' +companyId+ '/'+ phonenumberId + '/error'
		axios.post(url, params)
			.then(response => {
				if (response){                                
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},       
	postMessagesBetween (companyId, phonenumberId, contact, startIndex, endIndex, callback) { 
		const params = new URLSearchParams()         
		params.append('contact',contact)       
		params.append('startIndex',startIndex) 
		params.append('endIndex',endIndex)    
		let url = `${baseApiUrl}` + 'phonenumber/'+ companyId+ '/'+ phonenumberId + '/messagesBetween'
		axios.post(url, params)
			.then(response => {
				if (response){                                
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},   
             
	postSendQuickReply (companyId, phonenumberId, quickReplyId, contact, callback) { 
		const params = new URLSearchParams() 
		params.append('quickReplyId',quickReplyId)            
		params.append('contact',contact)          
		let url = `${baseApiUrl}` + 'message/' +companyId+ '/'+ phonenumberId + '/sendQuickReply'
		axios.post(url, params)
			.then(response => {
				if (response){                                
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},       
	getArrayBufferMedia (url, callback) {
		axios.get(url, {
			responseType: 'arraybuffer'
		})
			.then(response => {
				const buffer = Buffer.from(response.data, 'binary').toString('base64')
				callback(buffer)
			})
			.catch(ex => {
				console.error(ex)
			})                
	},
	postChatflowStop (companyId, phonenumberId, waId, chatflowId, callback) { 
		const params = new URLSearchParams() 
		params.append('waId',waId)            
		params.append('chatflowId',chatflowId)          
		let url = `${baseApiUrl}` + 'message/' +companyId+ '/'+ phonenumberId + '/chatflowStop'
		axios.post(url, params)
			.then(response => {
				if (response){                                
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	}, 
	postChatflowStart (companyId, phonenumberId, waId, chatflowId, callback) { 
		const params = new URLSearchParams() 
		params.append('waId',waId)            
		params.append('chatflowId',chatflowId)          
		let url = `${baseApiUrl}` + 'message/' +companyId+ '/'+ phonenumberId + '/chatflowStart'
		axios.post(url, params)
			.then(response => {
				if (response){                                
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	}, 
	postUploadMediaBase64 (companyId, phonenumberId, filename, base64Data, callback) { 
		const params = new URLSearchParams() 
		params.append('filename',filename)            
		params.append('base64Data',base64Data)          
		let url = `${baseApiUrl}` + 'message/uploadMediaBase64/' +companyId+ '/'+ phonenumberId
		axios.post(url, params)
			.then(response => {
				if (response){                                
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},
	getThumbnailHas (companyId,messageId, callback) { 
		let url = `${baseApiUrl}` + 'message/' +companyId+ '/'+ messageId+ '/thumbnailHas'
		axios.get(url)
			.then(response => {
				if (response){                                
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},
}
