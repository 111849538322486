import axios from 'axios'
import i18n from '../plugins/i18n'
import { baseApiUrl, access_token} from '../global'


axios.defaults.headers.common['Authorization'] = `bearer ${access_token}`


export default {
   
	getKeyAPIList: (companyId, callback) => {
		const url = `${baseApiUrl}`+'api/'+ companyId +'/listApi'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	}, 
	getProxyParameter: (companyId,phonenumberId, callback) => {
		const url = `${baseApiUrl}`+'api/'+ companyId +'/'+phonenumberId+ '/proxyParameter'
		axios.get(url).then(response => {
			if (response){
				callback(response.data)                        
			}        
		})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)
			})  
	},       
	getDatasourceImporter (companyId, callback) {  
		let url = `${baseApiUrl}` + 'api/'+ companyId + '/datasourceImporter'
		axios.get(url)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	}, 


	createApi (companyId, apiTitle, apiDescription, callback) {  
		const params = new URLSearchParams()
		params.append('apiTitle', apiTitle)
		params.append('apiDescription', apiDescription)
		let url = `${baseApiUrl}` + 'api/'+ companyId + '/createApi'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(true)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},        
  
	postProxyParameter (companyId,phonenumberId,messagePersist,statusBroadcast,traceLog,markAsReadedAsync,webhookUrl,webhookUserAgent,proxyName,headerCustomKey,headerCustomValue,deleteMessagesFromDbCycleConfig,callback) {  
		const params = new URLSearchParams()
		params.append('messagePersist', messagePersist)
		params.append('statusBroadcast', statusBroadcast)
		params.append('traceLog', traceLog)
		params.append('markAsReadedAsync', markAsReadedAsync)
		params.append('webhookUrl', webhookUrl)
		params.append('webhookUserAgent', webhookUserAgent)
		params.append('proxyName', proxyName)
		params.append('headerCustomKey', headerCustomKey)
		params.append('headerCustomValue', headerCustomValue)
		params.append('deleteMessagesFromDbCycleConfig', deleteMessagesFromDbCycleConfig)
		let url = `${baseApiUrl}`+'api/'+ companyId +'/'+phonenumberId+ '/proxyParameter'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(true)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},  
	postUpdateApi (companyId, apiKeyId, apiTitle, apiDescription, callback) {  
		const params = new URLSearchParams()
		params.append('apiTitle', apiTitle)
		params.append('apiDescription', apiDescription)
		let url = `${baseApiUrl}` + 'api/'+ companyId + '/' + apiKeyId + '/update'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(true)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},   
	
	postWebhookListHistory (companyId, webhookId,endTime, callback) {  
		const params = new URLSearchParams()
		params.append('endTime', endTime)
		let url = `${baseApiUrl}` + 'api/'+ companyId +'/'+webhookId+ '/listHistory'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})        
	},
	postWebhookHistoryRedo (companyId, webhookHistoryId, callback) {  
		let url = `${baseApiUrl}` + 'api/'+ companyId +'/'+webhookHistoryId+ '/webhookHistoryRedo'
		axios.post(url)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})        
	},
	postListApiLog (companyId, startTime, endTime, phonenumberFilter,ipFilter,userAgentFilter,endpointFilter, callback) {  
		const params = new URLSearchParams()
		params.append('startTime', startTime)
		params.append('endTime', endTime)
		params.append('phonenumberFilter', phonenumberFilter)
		params.append('ipFilter', ipFilter)
		params.append('userAgentFilter', userAgentFilter)
		params.append('endpointFilter', endpointFilter)
		let url = `${baseApiUrl}` + 'api/'+ companyId + '/listApiLog'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})        
	},  

	getApiLogDetail: (companyId, transactionId,callback) => {
		const url = `${baseApiUrl}`+'api/'+ companyId +'/'+transactionId+'/apiLogDetail'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},  
	removeApi (companyId, apiKeyId, callback) {            
		let url = `${baseApiUrl}` + 'api/'+ companyId + '/' + apiKeyId + '/remove'
		axios.delete(url)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},                             
}
