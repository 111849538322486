

<template>
	<div>
		<div>
			<i @click="openEditValue">
				<svg class="float-right mt-1" width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M7 11.6667H12.25" stroke="#999999" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M9.625 2.04164C9.85706 1.80957 10.1718 1.6792 10.5 1.6792C10.6625 1.6792 10.8234 1.71121 10.9735 1.77339C11.1237 1.83558 11.2601 1.92673 11.375 2.04164C11.4899 2.15654 11.5811 2.29296 11.6432 2.44309C11.7054 2.59322 11.7374 2.75413 11.7374 2.91664C11.7374 3.07914 11.7054 3.24005 11.6432 3.39018C11.5811 3.54032 11.4899 3.67673 11.375 3.79164L4.08333 11.0833L1.75 11.6666L2.33333 9.3333L9.625 2.04164Z" stroke="#999999" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>                
			</i>  
		</div>

		<b-modal 
			v-model="show"
			:title="title"        
		>
			<b-container>
				<b-row >
					<b-form-input id="input" v-model="value" class="custom-font-open-14" :placeholder="placeholder"></b-form-input>
				</b-row>                    
			</b-container>
			<template #modal-footer>
				<div class="w-100">
					<b-button variant="outline-primary" class="mr-2"
						@click="show=false">Cancelar
					</b-button>   
					<b-button variant="primary" class="float-right mr-5"
						@click="save">Salvar           
					</b-button>                     
				</div>  
			</template>                
		</b-modal>                                                               
	</div>                                          
</template>

<script>
import WContactsAPI from '@/api/WContactsAPI'

export default {
	name: 'WContactData',
	props: [
		'contactId',
		'title',
		'type',
		'field',
		'defaultValue',
		'placeholder'
	],

	data () {
		return { 
			show: false,
			value: '',                    
		}
	},    
   
	methods: { 
		openEditValue () {
			this.value = this.defaultValue
			this.show = true
		},      
		save () {
			if (this.value == '' || this.value == undefined ){
				alert('Campo ' + this.field + ' obrigatório!')
				return
			}     
			if (this.type == 'email'){
				WContactsAPI.postEmail(this.$route.params.companyId, this.contactId, this.value, response => {
					if (response){
						this.show = false
						let obj = {file: null, mediaType: '', msg: 'email', visible: false}                        
						this.$emit('sendMessageData', obj)    
					} else {
						this.show = true
					}
				})                  
			} else {
				WContactsAPI.postName(this.$route.params.companyId, this.contactId, this.value, response => {
					if (response){
						this.show = false
						let obj = {file: null, mediaType: '', msg: 'name', visible: false}                        
						this.$emit('sendMessageData', obj)                         
					} else {
						this.show = true
					}
				})                     
			}
		},   
               
	},
	mounted () {
	}, 
}
</script>

<style>

</style>

 
