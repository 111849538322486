<template>
	<div class="ml-2">
		<b-dropdown id="dropdown-form" ref="dropdown" right variant="link" menu-class="zindex-test" no-caret style="left:10px;">
			<template #button-content>
				<span @click="chatStart">
					<svg width="50" height="40" viewBox="0 0 50 40" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect x="4" y="5" width="40" height="32" rx="8" fill="var(--secondary)"/>
						<path d="M14.2151 23.472C14.2151 23.984 14.6151 24.384 15.1271 24.384C15.6231 24.384 16.0391 23.984 16.0391 23.472V21.248H18.3111C18.7911 21.248 19.1911 20.864 19.1911 20.368C19.1911 19.888 18.7911 19.488 18.3111 19.488H16.0391V17.264C16.0391 16.752 15.6231 16.352 15.1271 16.352C14.6151 16.352 14.2151 16.752 14.2151 17.264V19.488H11.9431C11.4631 19.488 11.0631 19.888 11.0631 20.368C11.0631 20.864 11.4631 21.248 11.9431 21.248H14.2151V23.472Z" fill="white"/>
						<g clip-path="url(#clip0)">
							<path d="M37.4289 19.9584C37.4319 21.2083 37.1566 22.4413 36.6253 23.5569C35.9953 24.8938 35.0268 26.0183 33.8284 26.8044C32.6299 27.5904 31.2487 28.0071 29.8396 28.0077C28.6611 28.011 27.4986 27.7189 26.4467 27.1554L21.3574 28.9547L23.0539 23.5569C22.5225 22.4413 22.2472 21.2083 22.2503 19.9584C22.2508 18.4639 22.6437 16.999 23.3848 15.7279C24.126 14.4568 25.1862 13.4296 26.4467 12.7615C27.4986 12.198 28.6611 11.9059 29.8396 11.9092H30.286C32.147 12.0181 33.9048 12.8512 35.2227 14.249C36.5407 15.6469 37.3262 17.5112 37.4289 19.485V19.9584Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</g>
						<defs>
							<clipPath id="clip0">
								<rect width="18" height="20" fill="white" transform="translate(20.5 11)"/>
							</clipPath>
						</defs>
					</svg>
				</span>

			</template>                    
			<div>
				<div class="ml-3" v-show="phoneNumberList.length > 1">
					<b-row class="mb-1">
						<span class="medium-font">Número que iniciará a conversa:</span>
					</b-row>
					<div class="chatstart-phonenumber scrollbar">
						<b-form-group v-model="phoneNumber">
							<b-form-radio v-model="phoneNumber" :value="x" v-for="(x,index) in phoneNumberList" :key="index">
								<span v-if="(x.phonenumber != '' && x.phonenumberName != '')">{{x.phonenumber}} - {{x.phonenumberName}}</span>
								<span v-else>{{(x.phonenumberName != ''?x.phonenumberName:(x.phonenumber != ''?x.phonenumber:x.phonenumberId))}}</span>
							</b-form-radio>
						</b-form-group>

					</div>
				</div>

				<b-row class="mt-2">
					<span class="medium-font ml-3">Para iniciar o bate-papo insira um número</span>
					<b-col md="5">
						<country-code :key="componentCountryKey" :countryNumber="countryCode" v-on:setCountryCode="getCountryCode"/>
					</b-col>
					<b-col md="7">
						<b-row class="mr-1">
							<b-form-input                    
								id="dropdown-form-phoneNumber"
								type="text"
								placeholder="(47) 0000-00000"
								v-model="phoneNumberInput"
							></b-form-input>
						</b-row>
					</b-col>

				</b-row>

				<div class="d-flex justify-content-center">
					<span>ou</span>
				</div> 
				<span class="medium-font">Digite um nome cadastrado na sua lista de contatos</span>
				<!-- <b-dropdown v-model="search" @keyup="searchContact" size="lg" variant="ligth" :text="selectedPhoneNumber">
					<b-dropdown-item v-for="(key, value, index) in contactMap" :key="index" @click="selectedOption(key,value)">{{key}}</b-dropdown-item>							
				</b-dropdown>  	 -->
				
				<b-form-group>
					<b-input-group>
						<b-form-input    
							id="contact-Id"                     
							list="contact-list"
							type="search"                     
							placeholder="Digite parte do nome"
							v-model="search"
							@input="selectedOption"
							@keyup="searchContact" class="mt-1"
							autocomplete="off"
							@update="clearFilter">
						</b-form-input>
						<b-input-group-prepend is-text class="mt-1 mr-1">
							<b-icon icon="search"></b-icon>
						</b-input-group-prepend>                                          
					</b-input-group>   
					<b-form-datalist id="contact-list">
						<option v-for="(key, value, index) in contactMap" :key="index" :data-value="value">{{key}} - {{value}}</option>
					</b-form-datalist>                  
				</b-form-group>   
			</div>    
			<hr>
			<div class="mt-3">
				<b-button style="color: gray;" @click="cancel()">Cancelar</b-button>
				<b-button @click="nextStep()">Avançar</b-button>                
			</div>    
                              

		</b-dropdown>             
	</div>                                          
</template>

<script>
import WPhoneNumberAPI from '@/api/WPhoneNumberAPI'
import WContactsAPI from '@/api/WContactsAPI'
import WCountryCode from '../settings/WCountryCode'
export default {
	name: 'WChatStart',
	props: [
		'contact'
	],
	components: {
		'country-code': WCountryCode                            
	},       
	data () {
		return { 
			step: 1,
			search: '',
			checkedPhones: [],
			countryCode: '+55',
			phoneNumber: '',
			phoneNumberInput: '',
			selectedName: '',
			userName: '',
			phonenumberId: '',
			contactMap: [],
			phoneNumberList: [],
			componentCountryKey: 0, 
			selectedPhoneNumber: '',
			aux:''                      
		}
	},    
	methods: {
		teste () {
			console.log(this.aux)
			console.log('clickado')
		},
		getCountryCode (code) {
			this.countryCode = code
		},           
		chatStart () {
			this.contactMap = []
			// this.phoneNumber = ''
			this.phoneNumberInput = ''
			this.selectedName = ''
			let opts = document.getElementById('contact-list').childNodes
			document.getElementById('contact-Id').value = ''
			for (let i = 0; i < opts.length; i++){
				opts[i].value = ''
			}              
		},
		selectOnlyThis () {
			// console.log(this.phoneNumber)
			// let checkbox = document.getElementsByName('checkbox')
			// Array.prototype.forEach.call(checkbox,function (el) {
			// 	el.checked = false
			// })
			// document.getElementById(id).checked = true
			// this.phonenumberId = phoneId
		},   
		getPhonenumbers () {
			WPhoneNumberAPI.getPhonenumbers(this.$route.params.companyId, response => {
				this.phoneNumberList = response.data
				if (Object.keys(this.phoneNumberList).length == 1){
					this.phonenumberId = Object.keys(this.phoneNumberList)
				}                
			})                
		},
		cancel () {
			this.$refs.dropdown.hide(true)
		},
		nextStep () {
			// console.log(this.userName)
			// console.log(this.phoneNumberInput)
			if(this.phoneNumber == 0){
				// console.log(this.phoneNumber)
				alert('Selecione um número')
				return
			}
			let phoneNumber = ''
			if (this.phoneNumberInput != ''){
				WContactsAPI.postContactWaIdOrCreate(this.$route.params.companyId, this.phoneNumber.phonenumberId, this.countryCode.replace('+', ''), this.phoneNumberInput, response => {
					if (!response) {
						alert('Número inválido, tente novamente ou digite um nome cadastrado na sua lista de contatos.')
					} else {
						phoneNumber = response.data	
						WContactsAPI.getContactByWaId(this.$route.params.companyId,phoneNumber, response => {
							console.log(response.data)
							let obj = {file: null, mediaType: 'contact', msg: {...response.data,phonenumberId: this.phoneNumber.phonenumberId,}, visible: false}
							this.$emit('sendMessageData', obj) 
							this.$refs.dropdown.hide(true) 
						})				
					}               
				})            
			} else {
				// phoneNumber = this.phoneNumber
				phoneNumber = this.selectedPhoneNumber
				WContactsAPI.getContactByWaId(this.$route.params.companyId,phoneNumber, response => {
					console.log(response.data)
					let obj = {file: null, mediaType: 'contact', msg: {...response.data,phonenumberId: this.phoneNumber.phonenumberId,}, visible: false}
					this.$emit('sendMessageData', obj) 
					this.$refs.dropdown.hide(true) 
				})
				// let contact = 
				// {
				// 	phonenumberId: this.phoneNumber.phonenumberId,                 
				// 	name: this.userName,
				// 	phoneNumber: this.selectedPhoneNumber,                                                                         
				// }    
				// contact                                                         
				// let obj = {file: null, mediaType: 'contact', msg: contact, visible: false}
				// this.$emit('sendMessageData', obj) 
				// this.$refs.dropdown.hide(true)                     
			}
		},
		searchContact () {
			if (this.search != '' && this.search != undefined){
				WContactsAPI.postSearchContacts(this.$route.params.companyId, this.search, response => {
					// console.log(this.contactMap)
					this.contactMap = response.data
				})                   
			}
		},   
		selectedOption () {
			if(this.search.includes('-')){
				this.selectedPhoneNumber = this.search.split('-')[1].replace(/ /g,'')
				console.log(this.selectedPhoneNumber)
			}else{
				let val = document.getElementById('contact-Id').value
				let opts = document.getElementById('contact-list').childNodes
				console.log(val)
				for (let i = 0; i < opts.length; i++){
					if (opts[i].value === val){
						this.userName = opts[i].value 
						this.selectedPhoneNumber = opts[i].getAttribute('data-value')
						break
					}
				} 
			}
		},
		clearFilter () {
			if(this.search == ''){
				this.searchContact()
			}
		}                
	},
	async mounted () {
		await this.$store.dispatch('getPhoneNumberList')
		this.phoneNumberList = this.$store.getters.getPhoneNumberList
		this.phoneNumber = this.phoneNumberList[0]
		// console.log(this.phoneNumber)
		this.selectedName = ''
		this.contactMap = []
		// this.getPhonenumbers()
	}, 
}
</script>

<style>
    .card {
		
        margin-top: -10px;
		z-index: 1;
        min-width: 380px !important;
    }
	.zindex-test{
	/* position: fixed !important; */
	/* top: inherit !important; */
	min-width: 300px !important;
	/* z-index: 999 !important; */
	/* left: 55vw !important; */
	/* overflow: hidden !important; */
	padding: 20px!important;
	/* border-radius: 10px!important; */
	}
</style>

 
