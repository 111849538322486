<template>
	<div>
		<b-button v-b-toggle.sidebar-right variant="secondary"
			@click="create()" >
			<span>
				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8 3V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M3 8H13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</span>
			<span class="ml-1">
				Criar nova   
			</span>
        
		</b-button> 
		<b-sidebar 
			id="sidebar-right" 
			title="Adicionar transmissão"
			width="380px"
			right
			shadow>
			<template #default="{ hide }">            
				<div class="m-3">
					<b-col md="12" sm="12" class="mt-3">
						<b-row>
							<b-col >
								<h4 class="lbl-title">Nova transmissão</h4>
							</b-col>                
						</b-row>
						<Loading v-if="loading==true"/> 
						<b-form v-else>
							<b-row>  
								<b-col >
									<b-form-group label="Dê um nome para essa transmissão" label-for="broadcast">
										<b-form-input id="broadcast" type="text"
											v-model="broadcastName" placeholder="Nome" 
											:autofocus="true"/>
									</b-form-group>                      
								</b-col>                                        
							</b-row>         
							<div class="ml-3" v-show="listLength > 1">
								<b-row>
									<span class="medium-font">Número</span>
								</b-row>
								<b-row>
									<b-dropdown id="phoneNumberList" :text="phoneNumber" variant="ligth">
										<b-dropdown-item v-for="(value, key,  index) in phoneNumberList" 
											:key="index" @click="setPhoneNumber(key, value)">{{(value != ''?value:key)}}
										</b-dropdown-item>
									</b-dropdown>                                
								</b-row>
							</div>                         
							<div class="ml-3">
								<b-row>
									<span class="medium-font">Escolha um departamento</span>
								</b-row>
								<b-row class="mt-1">
									<b-dropdown id="departments" :text="selectedDepartment" variant="ligth">
										<b-dropdown-item v-for="( x,  index) in orderedDepartmentList" :key="index" @click="setDepartment(x.departmentId, x.departmentName)">                      
											<!-- <b-avatar size="1.2rem" :src="getAvatarUrl(x.departmentId)"></b-avatar>  -->
											<b-row>
												<div v-if="x.departmentName != undefined" class="avatar " :style="x.avatar">{{ x.departmentName.substring(0,2).toUpperCase() }}</div>
												<div v-else class="avatar"></div>
												<span class="custom-font-open-12 ml-2">{{x.departmentName}}</span>
											</b-row>
										</b-dropdown-item>
									</b-dropdown>                                
								</b-row>
							</div>                         
							<b-row class="mt-5">
								<b-col>
									<b-row class="ml-2">
										<b-col>
											<b-button variant="outline-primary" class="mr-2"
												@click="hide">Cancelar
											</b-button> 
										</b-col>
										<b-col>
											<b-button variant="primary" 
												@click="next()">Continuar
											</b-button> 
										</b-col>                          
									</b-row>
								</b-col>
							</b-row>                       
						</b-form>
					</b-col>
				</div>
			</template>
		</b-sidebar>                 
	</div>    
</template>

<script>
import Loading from '@/views/Loading'
import { avatarUrl} from '@/global'
import WPhoneNumberAPI from '@/api/WPhoneNumberAPI'
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import WBroadcastAPI from '@/api/WBroadcastAPI'
export default {    
	components: {Loading},
	name: 'WBroadcast',  
	data: function () {
		return {
			loading: false,
			phonenumberId: '',
			broadcastName: '',
			phoneNumber: '-Selecionar-',
			phoneNumberList: [],
			departments: [],
			selectedDepartment: '-Selecionar-',
			departmentId: '',
			listLength: 0
		}
	},
	computed: {
		orderedDepartmentList () {
			let list = this.departments
			list.sort(this.dynamicSort('departmentName')) 
			return list
		}, 
     
	},   	
	methods: {
		getAvatarUrl (id) {
			return avatarUrl + this.$route.params.companyId + '&imageId='+id
		},           
		next () { 
			if (this.broadcastName == '' || this.broadcastName == undefined){
				alert('Informe um nome para transmissão!')            
				return
			}   
			if (this.broadcastName.length < 6){
				alert('Informe um nome para transmissão como no mínimo 6 dígitos!')            
				return
			}                       
			if (this.departmentId == '' || this.departmentId == undefined){
				alert('Selecione um departamento!')            
				return
			}
			WBroadcastAPI.postDraft(this.$route.params.companyId, this.phonenumberId, this.broadcastName, this.departmentId, response => {
				let broadcastId = response.data    
				if (broadcastId != '' && broadcastId != undefined){
					let broadcast = 
						{
							broadcastName: this.broadcastName,
							broadcastPhoneNumber: this.phoneNumber,
							broadcastDepartament: this.selectedDepartment,
							broadcastStartAt: ''
						}
					localStorage.removeItem('__wpp_broadcast_name')
					localStorage.setItem('__wpp_broadcast_name', JSON.stringify(broadcast))
					this.$router.push({ path: '/broadcast/'+ this.$route.params.companyId +'/'+ this.phonenumberId+ '/' +this.departmentId+ '/' + broadcastId + '/new' })                                         
				} else {
					alert('Falha ao criar transmissão!')
				}                                       
			})                        
		}, 
		setPhoneNumber (id, number) {
			this.phonenumberId = id
			this.phoneNumber = number
		},
		setDepartment (id, name) {
			this.departmentId = id
			this.selectedDepartment = name
		},
		cancel () {
			this.disable = true 
		},
		create () {
			this.$emit('sendMessageData') 
			localStorage.removeItem('__wpp_broadcast_data_validate')            
			this.getPhonenumbers()
			this.getDepartmentList()
		},
		getPhonenumbers () {
			WPhoneNumberAPI.getPhonenumbers(this.$route.params.companyId, response => {
				this.phoneNumberList = response.data
				if (Object.keys(this.phoneNumberList).length == 1){
					this.listLength = 1
					this.phonenumberId = Object.keys(this.phoneNumberList)                
					for (let value of Object.values(this.phoneNumberList)){
						this.phoneNumber = value 
					}      
					console.log(this.phoneNumberList)              
				} else {
					this.listLength = Object.keys(this.phoneNumberList).length
				}               
			})                 
		},  
		dynamicSort (property) {
			let sortOrder = 1
			if(property[0] === '-') {
				sortOrder = -1
				property = property.substr(1)
			}
			return function (a,b) {
				let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
				return result * sortOrder
			}
		},  		    
		getDepartmentList () {
			WDepartmentsAPI.getDepartmentsOfUser(this.$route.params.companyId, response => {
				let departments = response.data
				departments.forEach(dep => {
					dep.visible = true                    
				})
				this.departments = departments
			})
		},                   
	},
	mounted () {

	}    
}
</script>

<style>
.dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
}

.avatar {
    width: 20px;
    height: 20px;
    background-color: var(--primary);
    border-radius: 50%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    color: #FFFFFF;
}  
</style>

