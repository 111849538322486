<template>
	<div>
		<b-col md="8">
			<b-row class="ml-2 mt-3">  
				<h3 v-if="step == 4.1" class="lbl-title">Novo departamento</h3>    
				<h3 v-else class="lbl-title">Editar departamento</h3>                                                           
			</b-row>
			<div class="w-100">
				<hr>
			</div>   
			<b-row class="ml-2">
				<span class="custom-font-open-14-gray-normal">
					Identifique seu departamento com um nome
				</span>                       
			</b-row>                 
			<b-row class="ml-2 mt-3">
				<b-form-input
					id="inputName"
					v-model="departmentName"
					placeholder="Informe um nome"
				></b-form-input>
			</b-row>
			<div v-if="step ==4.1 && phoneNumberList.length > 1">
				<b-row class="ml-2 mt-3">
					<span>Escolha apenas um número</span>
				</b-row>
				<b-row class="ml-2 mt-3">
					<span><b>Importante:</b> você não poderá alterar um departamento entre números. Escolha com atenção</span>
				</b-row>
				<b-row class="ml-2 mt-3">
					<b-form-group v-model="selectedPhoneNumber">
						<b-form-radio v-model="selectedPhoneNumber" :value="x" v-for="(x,index) in phoneNumberList" :key="index">
							<span v-if="(x.phonenumber != '' && x.phonenumberName != '')">{{x.phonenumber}} - {{x.phonenumberName}}</span>
							<span v-else>{{(x.phonenumberName != ''?x.phonenumberName:(x.phonenumber != ''?x.phonenumber:x.phonenumberId))}}</span>
						</b-form-radio>
					</b-form-group>
				</b-row>
			</div>

			<b-row class="mt-5">
				<b-col>
					<div class="d-flex justify-content-center">
						<b-button variant="outline-primary" class="mr-2"
							@click="cancel()">Cancelar
						</b-button>                      
						<b-button v-if="step == 4.1" variant="primary" 
							@click="next()" :disabled="btnDisable">Avançar
						</b-button>        
						<b-button v-else variant="primary" 
							@click="next()">Salvar
						</b-button>                                          
					</div>
				</b-col>            
			</b-row>          
		</b-col> 
	</div>
</template>

<script>
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import WPhoneNumberAPI from '@/api/WPhoneNumberAPI'
export default {
	name: 'WDepartment',
	props: [
		'step'
	],
	computed:{
		btnDisable () {
			if(this.departmentName == ''){
				return true
			}else{
				return false
			}
		}
	},
	data () {
		return {
			departmentId: '',
			phonenumberId: '',
			departmentName: '',
			description: '',
			phoneNumberList: [],
			listLength: 0,
			selectedPhoneNumber: undefined,
		}
	},
	methods: {
		getPhonenumbers () {
			WPhoneNumberAPI.getList(this.$route.params.companyId, response => {
				this.phoneNumberList = response.data
				this.selectedPhoneNumber = response.data[0]
				if (Object.keys(this.phoneNumberList).length == 1){
					this.listLength = 1
					this.phonenumberId = Object.keys(this.phoneNumberList)     
					for (let value of Object.values(this.phoneNumberList)){
						this.phoneNumber = value                             
					}                    
				} else {
					this.listLength = Object.keys(this.phoneNumberList).length
				}          
			})                
		},              
		setId (id) {
			this.phonenumberId = id
		},
		next () {
			if (this.departmentName == '' || this.departmentName == undefined){
				alert('Campo nome obrigatório!')
				return
			}     
			if (this.step == 4.1){
				WDepartmentsAPI.postCreateDraft(this.$route.params.companyId, this.departmentName, this.selectedPhoneNumber.phonenumberId, response => {
					if (response){  
						let objDepartment = {
							departmentId: response.data
						}
						localStorage.removeItem('__wpp_settings_obj')
						localStorage.setItem('__wpp_settings_obj', JSON.stringify(objDepartment))                        
						let obj = {step: 4.3}
						this.$emit('sendMessageData', obj)                                                           
					} else {
						alert('Falha ao criar departamento!')
					}
				})                      
			} else {
				WDepartmentsAPI.postDepartmentName(this.$route.params.companyId, this.departmentId, this.departmentName, response => {
					if (response){  
						localStorage.removeItem('__wpp_settings_obj')                       
						let obj = {step: 4}
						this.$emit('sendMessageData', obj)                                                           
					} else {
						alert('Falha ao editar nome departamento!')
					}
				})                      
			}
		},
		cancel () {
			let obj = {step: 4}
			this.$emit('sendMessageData', obj)                    
		}
	},
	mounted () {
		if (this.step == 4.1){
			this.getPhonenumbers()
		} else {
			let objDepartment = JSON.parse(localStorage.getItem('__wpp_settings_obj'))
			if (objDepartment != null){
				this.departmentId = objDepartment.departmentId 
				this.departmentName = objDepartment.departmentName
			}   
		}
	}        
}
</script>