import { render, staticRenderFns } from "./WTemplateFinalize.vue?vue&type=template&id=dbd9096e&scoped=true&"
import script from "./WTemplateFinalize.vue?vue&type=script&lang=js&"
export * from "./WTemplateFinalize.vue?vue&type=script&lang=js&"
import style0 from "./WTemplateFinalize.vue?vue&type=style&index=0&id=dbd9096e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbd9096e",
  null
  
)

export default component.exports