import axios from 'axios'
import i18n from '../plugins/i18n'
import { baseApiUrl, access_token} from '../global'


axios.defaults.headers.common['Authorization'] = `bearer ${access_token}`


export default {
   
	getPropertyList: (companyId, callback) => {
		const url = `${baseApiUrl}`+'property/'+ companyId + '/list'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},
	postCreate (companyId, name, description,callback) {
		const params = new URLSearchParams()
		params.append('name',name)
		params.append('description', description)                                       
		let url = `${baseApiUrl}` + 'property/'+ companyId + '/create'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},   
	postUpdate (companyId, propertyId, description,callback) {
		const params = new URLSearchParams()
		params.append('description', description)                                       
		let url = `${baseApiUrl}` + 'property/'+ companyId + '/' + propertyId + '/update'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},       
	deleteProperty (companyId, propertyId, callback) {            
		let url = `${baseApiUrl}` + 'property/'+ companyId + '/' + propertyId + '/delete'
		axios.delete(url)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},        
	getPropertyListTemplates: (companyId, propertyId, callback) => {
		const url = `${baseApiUrl}`+'property/'+ companyId + '/' + propertyId + '/listTemplates'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},   
	getListQuickReply: (companyId, propertyId, callback) => {
		const url = `${baseApiUrl}`+'property/'+ companyId + '/' + propertyId + '/listQuickReply'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},         
         
}