<template>
	<div class="email">
		<b-form-input :label="label" v-model="value" :style="styleInput" style="width:100%" :type="type" :placeholder="placeHolder" @blur="validate" @input="isEmpty"></b-form-input>
	</div>  					 
</template>

<script>
export default {
	name: 'Input',
	props: [
		'type',
		'placeHolder',
		'label',
		'styleInput'
	],
	data () {
		return {
			value:'',
			validated: false,
			empty:true,
			messageError: '',
		}
	},
	methods: { 
		validate () {
			if(this.value !== undefined && this.value !== ''){
				switch(this.type){
				
				case 'email':
					if(this.validateEmail(this.value) == false){
						this.validated = false
						this.messageError = 'Email inválido'		
					}else{
						this.validated = true
					}	
					break		
				}
				let obj = {
					validated: this.validated,
					value: this.value,
					messageError: this.messageError,
					empty: this.empty,
					
				}
				this.$emit('sendMessageData', obj)	
			}
		},
		validateEmail (email) {
			let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			return re.test(email)
		}, 		
		isEmpty () {
			if(this.value !== null && this.value !== ''){
				this.empty = false
			}else{
				this.empty = true
			}	
			let obj = {
				validated: this.validated,
				value: this.value,
				messageError: this.messageError,
				empty: this.empty,
			}
			this.$emit('sendMessageData', obj)
		},
	},
	mounted () {
		
	}

}
</script>

<style>

	.email {
		width: 100%;
	}

</style>