import axios from 'axios'
import i18n from '@/plugins/i18n'
import { baseApiUrl} from '@/global'

export default {    
	getList: (companyId,callback) => {
		const url = `${baseApiUrl}`+'phonenumber/'+ companyId + '/list'
		axios.get(url).then(response => {
			if (response){
				callback(response.data)                        
			}        
		})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)
			})  
	}, 
	getPhoneNumberList: (companyId, callback) => {
		const url = `${baseApiUrl}`+'phonenumber/'+ companyId +'/list'
		axios.get(url).then(response => {
			if (response){
				callback(response.data)                        
			}        
		})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)
			})  
	},   
	getPhoneNumberDetail: (companyId, phonenumberId, callback) => {
		const url = `${baseApiUrl}`+'phonenumber/'+ companyId + '/' + phonenumberId + '/phonenumberDetail'
		axios.get(url).then(response => {
			if (response){
				callback(response.data)                        
			}        
		})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)
			})  
	},   	
	getPhonenumberDev (callback) {     
		axios.post(`${baseApiUrl}` + 'datafix/phonenumberDev/a7576b6d1dcf')
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},
	postCurrentChats (companyId, targetId,isTabActive,  callback) {  
		const params = new URLSearchParams()
		params.append('targetId', targetId)
		params.append('isTabActive', isTabActive)
		let url = `${baseApiUrl}` + 'phonenumber/'+ companyId + '/currentChats'
		axios.post(url, params)
			.then(response => {
				if (response){                                
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	}, 
	postDepartmentsChatsPaged (companyId, afterTime, searchFilter, chatStatus, pageSize, tabActive , postType,chatOrderIndex, callback) {  
		const params = new URLSearchParams()
		params.append('afterTime', afterTime)
		params.append('searchFilter', searchFilter)
		params.append('tabActive', tabActive)
		params.append('chatOrderIndex', chatOrderIndex)
		if (postType == 'departmentsChatsPaged') {
			params.append('chatStatus', chatStatus)			
		}
		params.append('pageSize', pageSize)
		let url = `${baseApiUrl}` + 'phonenumber/'+ companyId + '/' + postType
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},
	postCurrentChatsPaged (companyId, targetId,afterTime, searchFilter, chatStatus, pageSize, tabActive,chatOrderIndex, callback) {  
		const params = new URLSearchParams()
		params.append('targetId', targetId)
		params.append('afterTime', afterTime)
		params.append('searchFilter', searchFilter)
		params.append('tabActive', tabActive)
		params.append('chatStatus', chatStatus)			
		params.append('pageSize', pageSize)
		params.append('chatOrderIndex', chatOrderIndex)
		let url = `${baseApiUrl}` + 'phonenumber/'+ companyId + '/currentChatsPaged'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},  

	getAgentLastInteraction: (companyId, callback) => {
		const url = `${baseApiUrl}`+'phonenumber/'+ companyId + '/agentLastInteraction'
		axios.get(url).then(response => {
			if (response){
				callback(response.data)                        
			}        
		})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)
			})  
	},     
	getAgentsResume: (companyId, callback) => {
		const url = `${baseApiUrl}`+'phonenumber/'+ companyId + '/agentsResume'
		axios.get(url).then(response => {
			if (response){
				callback(response.data)                        
			}        
		})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)
			})  
	}, 
	getAgentDepartmentLastInteraction: (companyId, callback) => {
		const url = `${baseApiUrl}`+'phonenumber/'+ companyId + '/agentDepartmentLastInteraction'
		axios.get(url).then(response => {
			if (response){
				callback(response.data)                        
			}        
		})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)
			})  
	},     	
	getChatDetail: (companyId, phonenumberId, waId,  callback) => {
		const url = `${baseApiUrl}`+'phonenumber/'+ companyId + '/'+ phonenumberId  + '/' + waId + '/chatDetail'
		axios.get(url).then(response => {
			if (response){
				localStorage.removeItem('__wpp_departmentId_chat')
				localStorage.setItem('__wpp_departmentId_chat',response.data.data.departmentId)
				callback(response.data)                        
			}        
		})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)
			})  
	},     

	getChatStatusOptions: (companyId, phonenumberId, waId,  callback) => {
		const url = `${baseApiUrl}`+'phonenumber/'+ companyId + '/'+ phonenumberId  + '/' + waId + '/chatStatusOptions'
		axios.get(url).then(response => {
			if (response){
				callback(response.data)                        
			}        
		})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)
			})  
	},      

	postChatStatus (companyId, phonenumberId, waId, chatStatus, callback) {  
		const params = new URLSearchParams()
		params.append('waId', waId)
		params.append('chatStatus', chatStatus)
		let url = `${baseApiUrl}` + 'phonenumber/'+ companyId + '/'+ phonenumberId  + '/chatStatus'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(true)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	}, 
	getPhonenumbers: (companyId, callback) => {
		const url = `${baseApiUrl}`+'phonenumber/'+ companyId +'/phonenumbers'
		axios.get(url).then(response => {
			if (response){
				callback(response.data)                        
			}        
		})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)
			})  
	},     
	getTransferOptions: (companyId, phonenumberId, waId,  callback) => {
		const url = `${baseApiUrl}`+'phonenumber/'+ companyId + '/'+ phonenumberId  + '/' + waId + '/transferOptions'
		axios.get(url).then(response => {
			if (response){
				callback(response.data)                        
			}        
		})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)
			})  
	},  
	postAssignLeadIsSystem (companyId, phonenumberId, waId, callback) {  
		const params = new URLSearchParams()
		params.append('waId', waId)
		let url = `${baseApiUrl}` + 'phonenumber/'+ companyId + '/'+ phonenumberId  + '/assignLeadIsSystem'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(true)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	}, 
	postAssignLeadIsMe (companyId, phonenumberId, waId, callback) {  
		const params = new URLSearchParams()
		params.append('waId', waId)
		let url = `${baseApiUrl}` + 'phonenumber/'+ companyId + '/'+ phonenumberId  + '/assignLeadIsMe'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(true)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},   
	postAssignLead (companyId, phonenumberId, waId, leadId, callback) {  
		const params = new URLSearchParams()
		params.append('waId', waId)
		params.append('leadId', leadId)
		let url = `${baseApiUrl}` + 'phonenumber/'+ companyId + '/'+ phonenumberId  + '/assignLead'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(true)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},     
	postSearchMessages (companyId, phonenumberId, waId, body, callback) {  
		const params = new URLSearchParams()
		params.append('body', body)
		let url = `${baseApiUrl}` + 'phonenumber/'+ companyId + '/'+ phonenumberId  + '/' + waId + '/search'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	}, 
        
	postSearchMessagesByLabel (companyId, phonenumberId, waId, label, callback) {  
		const params = new URLSearchParams()
		params.append('label', label)
		let url = `${baseApiUrl}` + 'phonenumber/'+ companyId + '/'+ phonenumberId  + '/' + waId + '/searchByLabel'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},     
	postDepartmentsChats (companyId, callback) {  
		let url = `${baseApiUrl}` + 'phonenumber/'+ companyId + '/departmentsChats'
		axios.post(url)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},         
	postUpdateAllDepartmentPending (companyId, callback) {  
		let url = `${baseApiUrl}` + 'phonenumber/'+ companyId + '/updateAllDepartmentPending'
		axios.post(url)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},
	postUsageReportEmail (companyId, phonenumberId, reportType,startTime,endTime, callback) {  
		const params = new URLSearchParams()
		params.append('reportType', reportType)
		params.append('startTime', startTime)
		params.append('endTime', endTime)
		let url = `${baseApiUrl}`+ 'phonenumber/'+ companyId+'/'+phonenumberId + '/usageReportEmail'
		console.log(url)
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},    
	postAssignChatToMe (companyId, phonenumberId, waId, callback) {  
		const params = new URLSearchParams()
		params.append('waId', waId)
		let url = `${baseApiUrl}` + 'phonenumber/'+ companyId + '/'+ phonenumberId  + '/assignChatToMe'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(true)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},   
	postAssignChatAgentOrDepartment (companyId, phonenumberId, waId, targetId, callback) {  
		const params = new URLSearchParams()
		params.append('waId', waId)
		params.append('targetId', targetId)        
		let url = `${baseApiUrl}` + 'phonenumber/'+ companyId + '/'+ phonenumberId  + '/assignChatAgentOrDepartment'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(true)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},    
	postTransferDepartmentInsideInbox (companyId, phonenumberId, waId, departmentTargetId, callback) {  
		const params = new URLSearchParams()
		params.append('waId', waId)
		params.append('departmentTargetId', departmentTargetId)        
		let url = `${baseApiUrl}` + 'phonenumber/'+ companyId + '/'+ phonenumberId  + '/transferDepartmentInsideInbox'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(true)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},   

	getAvatar (url, callback) {
		axios.get(url, {
			responseType: 'arraybuffer'
		})
			.then(response => {
				const buffer = Buffer.from(response.data, 'binary').toString('base64')
				let mimeType = this.base64MimeType(buffer)
				let blob = this.base64toBlob(buffer, mimeType)
				let avatarUrl = window.URL.createObjectURL(blob) 
				alert(mimeType + ' -- '+ avatarUrl)
				let data = {avatarUrl: avatarUrl, mimeType: mimeType}
				callback(data)
			})
			.catch(ex => {
				console.error(ex)
			})                
	},
      
	base64MimeType (encoded) {
		let result = null
      
		if (typeof encoded !== 'string'){
			return result
		}
      
		let mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
      
		if (mime && mime.length){
			result = mime[1]
		}
      
		return result
	},     
	base64toBlob (base64Data, contentType) {
		contentType = contentType || ''
		let sliceSize = 1024
		let byteCharacters = atob(base64Data)
		let bytesLength = byteCharacters.length
		let slicesCount = Math.ceil(bytesLength / sliceSize)
		let byteArrays = new Array(slicesCount)
    
		for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex){
			let begin = sliceIndex * sliceSize
			let end = Math.min(begin + sliceSize, bytesLength)
    
			let bytes = new Array(end - begin)
			for (let offset = begin, i = 0; offset < end; ++i, ++offset){
				bytes[i] = byteCharacters[offset].charCodeAt(0)
			}
			byteArrays[sliceIndex] = new Uint8Array(bytes)
		}
		return new Blob(byteArrays, { type: contentType })
	}    

}
