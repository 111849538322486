<template>
	<div>
		<div v-if="propertyType == 'chat'">
			<span class="property-custom-font">Propriedades personalizadas</span>
			<span class="" style="cursor: pointer;" @click="add()">
				<svg class="ml-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect width="20" height="20" rx="4" fill="var(--secondary)"/>
					<path d="M9.05444 15.1411C9.05444 15.6056 9.39667 15.9722 9.86111 15.9722C10.3256 15.9722 10.6922 15.6056 10.6922 15.1411V10.6678H15.1656C15.6056 10.6678 15.9722 10.3256 15.9722 9.86111C15.9722 9.39667 15.6056 9.03 15.1656 9.03H10.6922V4.55667C10.6922 4.11667 10.3256 3.75 9.86111 3.75C9.39667 3.75 9.05444 4.11667 9.05444 4.55667V9.03H4.58111C4.11667 9.03 3.75 9.39667 3.75 9.86111C3.75 10.3256 4.11667 10.6678 4.58111 10.6678H9.05444V15.1411Z" fill="white"/>
				</svg>           
			</span>
		</div>
		<div v-else class="mb-3 ml-2 icon-clickable" @click="add()">        
			<span>
				<svg class="ml-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect width="20" height="20" rx="4" fill="var(--secondary)"/>
					<path d="M9.05444 15.1411C9.05444 15.6056 9.39667 15.9722 9.86111 15.9722C10.3256 15.9722 10.6922 15.6056 10.6922 15.1411V10.6678H15.1656C15.6056 10.6678 15.9722 10.3256 15.9722 9.86111C15.9722 9.39667 15.6056 9.03 15.1656 9.03H10.6922V4.55667C10.6922 4.11667 10.3256 3.75 9.86111 3.75C9.39667 3.75 9.05444 4.11667 9.05444 4.55667V9.03H4.58111C4.11667 9.03 3.75 9.39667 3.75 9.86111C3.75 10.3256 4.11667 10.6678 4.58111 10.6678H9.05444V15.1411Z" fill="white"/>
				</svg>           
			</span>
			<span class="ml-2 property-custom-font" style="color:var(--secondary); font-weight: 700;">Adicionar nova</span>        
		</div>

		<b-modal 
			v-model="show"
			:title="title"
			centered
			scrollable>
			<b-col>
				<b-row  class="ml-1" v-show="action ==3">
					<h5 class="custom-font-open-16-gray-600">{{description}}</h5>
				</b-row>
				<b-row  class="ml-1">
					<b-form-group>
						<label for="property-propertyId" class="custom-font-open-16-gray-600">{{label1}}</label> 
						<b-form-input 
							type="search" 
							id="property-propertyId" 
							list="property-list" 
							debounce="500" 
							placeholder="Selecionar" 
							v-model="selectedProperty"
							@input="selectedOption" 
							:disabled="disable"
							autocomplete="off"
							class="custom-font-open-14-gray"
							@update="clearFilter"></b-form-input>
						<datalist id="property-list">
							<option v-for="(key, index) in orderBy(propertyList, 'propertyName')" :key="index" :data-value="key.propertyId">{{key.propertyName}}</option>
						</datalist>                
					</b-form-group>  
				</b-row>
        
			</b-col>
        
			<b-container fluid class="ml-2" v-show="showValue==true">           
				<b-row>
					<span class="custom-font-open-16-gray-600">{{label2}}</span>
				</b-row>   
				<b-row>
					<span class="custom-font-open-14-gray">{{description}}:</span>
				</b-row>    
				<b-row>
					<label for="input" class="custom-font-open-14-gray">Valor:</label>  
					<b-form-input id="input" type="text" 
						v-model="value" class="custom-font-open-14-gray">
					</b-form-input>                  
				</b-row>                      
			</b-container>         
			<template #modal-footer>
				<div class="w-100">
					<b-button variant="outline-primary" class="mr-2"
						@click="cancel()">{{button1}}
					</b-button>   
					<b-button variant="primary" class="float-right mr-5"
						@click="btnAction()">{{button2}}            
					</b-button>                     
				</div>  
			</template>
		</b-modal>          
		<div :class="[ this.$mq == 'xs' ? 'properties-scrollable-mobile' : 'properties-scrollable' ]" class="scrollbar mt-1">  
			<div v-for="(value, key, index) in propertyMap" :key="index" class="mt-2"> 
				<b-tooltip :target="key" placement="bottom" noninteractive>
					<span class="custom-font-open-14-white">
						{{key}}: {{value}}
					</span>
                
				</b-tooltip> 
				<b-row style="width:300px;">
					<div style="width:185px" class="propertie-tag description-ellipsis" >
						<span :id="key" class="custom-font-open-14-gray-normal">{{key}}: {{value}}</span>   
					</div>
					<div style="background-color:#F5F5F5;border-top-right-radius: 25px;border-bottom-right-radius: 25px;padding: 4px 8px 4px 8px;">
						<span style="cursor: pointer;" @click="edit(key, value)">
							<svg class="float-right mt-1" width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M7 11.6667H12.25" stroke="#999999" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M9.625 2.04164C9.85706 1.80957 10.1718 1.6792 10.5 1.6792C10.6625 1.6792 10.8234 1.71121 10.9735 1.77339C11.1237 1.83558 11.2601 1.92673 11.375 2.04164C11.4899 2.15654 11.5811 2.29296 11.6432 2.44309C11.7054 2.59322 11.7374 2.75413 11.7374 2.91664C11.7374 3.07914 11.7054 3.24005 11.6432 3.39018C11.5811 3.54032 11.4899 3.67673 11.375 3.79164L4.08333 11.0833L1.75 11.6666L2.33333 9.3333L9.625 2.04164Z" stroke="#999999" stroke-linecap="round" stroke-linejoin="round"/>
							</svg> 
						</span>  
					</div>
					
					<div class="ml-1" style="padding: 4px 8px 4px 8px;background-color:#F5F5F5;border-radius: 50%">
						<span style="background-color:#F5F5F5;width:25px;height: 25px;line-height: 25px;border-radius: 50%;color: red;text-align: center;cursor:pointer" @click="remove(key, value)">X</span> 
					</div>  
				</b-row>   
			</div>       
		</div> 

	</div>
                                       
</template>

<script>
import WContactsAPI from '@/api/WContactsAPI'
import Vue2Filters from 'vue2-filters'
export default {
	name: 'WContactProperties',
	props: [
		'propertyMap',
		'propertyDescriptiopMap',
		'contactId',
		'propertyType'
	],
	mixins: [Vue2Filters.mixin],    
	data () {
		return { 
			filter: '',
			show: false,
			title: 'Adicionar uma propriedade ao contato',
			label1: 'Escolha uma propriedade',
			label2: 'Descrição',
			description: '',
			value: '',
			action: 1,
			button1: 'Cancelar',
			button2: 'Salvar',
			placeholder: 'Buscar propriedades',
			showValue: false,
			selectedProperty: '',
			propertyName: '',
			propertyKey: '',
			propertyList: [],
			disable: false
		}
	},    
	methods: {
		cancel () {
			this.show = false
		},
		add () {
			this.show = true
			this.title = 'Adicionar uma propriedade ao contato'
			this.label1 = 'Escolha uma propriedade'
			this.label2 = 'Descrição'
			this.description = ''
			this.value = ''
			this.action = 1
			this.button1 = 'Cancelar'
			this.button2 = 'Salvar'
			this.placeholder = 'Buscar propriedades'
			this.showValue = false            
			this.getPropertyList()
		},
		edit (propertyName, propertyValue) {
			this.disable = true
			this.showValue = true
			this.show = true
			this.selectedProperty = propertyName
			this.propertyKey = propertyName
			this.value = propertyValue
			this.title = 'Editar propriedade do contato'
			this.label1 = 'Propriedade selecionada'
			this.label2 = 'Descrição'        
			for (const [key, value] of Object.entries(this.propertyDescriptiopMap)){
				if (key == propertyName){
					this.description = value                    
				}
			}              
			this.button1 = 'Cancelar'
			this.button2 = 'Salvar'
			this.placeholder = 'Buscar propriedades'
		},
		remove (propertyName, propertyValue) {
			this.disable = true
			this.showValue = false
			this.show = true
			this.selectedProperty = propertyName + ': '+ propertyValue
			this.propertyName = propertyName
			this.title = 'Remover propriedade do contato'
			this.label1 = ''
			this.label2 = ''
			this.description = 'Você deseja realmente remover essa propriedade?'
			this.value = ''
			this.button1 = 'Cancelar'
			this.button2 = 'Remover'
			this.placeholder = 'Buscar propriedades'            
			this.action = 3
		},        
		btnAction () {                       
			if (this.action == 3){
				WContactsAPI.postErasePropertyData(this.$route.params.companyId, this.contactId, this.propertyName, response => {
					if (response){
						this.show = false 
						let obj = {file: null, mediaType: 'property', msg: '', visible: false}                        
						this.$emit('sendMessageData', obj)  
					} else {
						alert('Erro ao processar a requisição!')
						this.show = false 
					}                    
				})                 
			} else {
				WContactsAPI.postPropertyData(this.$route.params.companyId, this.contactId, this.propertyKey, this.value, response => {
					if (response){
						this.show = false 
						let obj = {file: null, mediaType: 'property', msg: '', visible: false}                        
						this.$emit('sendMessageData', obj)                           
					} else {
						alert('Erro ao processar a requisição!')
						this.show = false 
					}
				})               
			}
		},  
		selectedOption () {
			this.showValue = true
			let val = document.getElementById('property-propertyId').value
			let opts = document.getElementById('property-list').childNodes
			for (let i = 0; i < opts.length; i++){
				if (opts[i].value === val){
					this.propertyKey = opts[i].value 
					let propertyId = opts[i].getAttribute('data-value')
					this.propertyList.forEach(el => {
						if (el.propertyId == propertyId){
							this.description = el.propertyDescription
						}
					})
					break
				}
			}                   
		}, 
		getPropertyList () {           
			WContactsAPI.getEmptyPropertyList(this.$route.params.companyId, this.contactId, response => {
				this.propertyList = response.data
			})                               
		},
		clearFilter () {
			if(this.selectedProperty == ''){
				this.selectedOption()
			}
		}                         
	},
	mounted () {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			console.log('Modal is about to be shown', bvEvent, modalId)
			this.disable = false
			this.showValue = false
			this.show = false
			this.selectedProperty = ''
			this.title = ''
			this.label1 = ''
			this.label2 = ''
			this.description = ''
			this.value = ''
			this.button1 = ''
			this.button2 = ''
			this.placeholder = ''
		})        
	}, 
}
</script>

<style scoped>
.propertie-tag{
	background-color:#F5F5F5;
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;
	padding: 4px 8px 4px 8px;
	
}
.list-group-item:first-child {
    border-top-left-radius: none !important;
    border-top-right-radius: none !important;
}
.list-group-item {
	/* width: inherit; */
    margin-bottom: 3px !important;
    padding: 3px !important;    
    background-color: #F5F5F5 !important;
	/* background-color:red!important; */
    border-radius: 100px !important;
    padding-left: 10px !important;    
}
.description-ellipsis { 
	/* width: 50%; */
    white-space: nowrap;  
    overflow: hidden;
    text-overflow: ellipsis;
	margin-left: 15px!important;
}
.btn-outline-primary {
    color: var(--primary);
    background-color: #fff;
    border-color: var(--primary);
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 500;
}
</style>

 
