<template>
	<div style="margin: 30px 30px 30px" class="contatc-list-scrollable scrollbar">
		<b-col class="mt-2 ml-2">
			<b-row class="ml-1">
				<b-col class="mt-2 ml-2">
					<h3 class="lbl-title">Contatos</h3>
				</b-col>
				<b-col>
					<b-col>
						<b-row class="justify-content-end mr-5">
							<contact-create/>                   
						</b-row>
					</b-col>                
				</b-col>
			</b-row>
		</b-col>
		<b-col md="12" sm="12" class="screen-template">
			<loading v-if="this.$store.state.loading ==true"/>  
			<b-row class="mt-1">            
				<b-col class="ml-2">
					<div>
						<tableT  
							v-if="contactList"
							:list="contactList" 
							:fields="fields" 
							:perPage="10" 
							:selected="10"
							:searchBar="true"
							:searchPlaceHolder = "'Busque pelo nome ou número'"
							:filterFields ="['name','waId']"
							:sortBy ="'name'"
							:agentFilter="true"
							:departmentFilter="true"
							:advancedSearch="true"
							listTittle="Departamentos"
							v-on:openLabelDetail="openContactDetail"
						/>
					</div>
              
				</b-col>   
			</b-row>
		</b-col>
	</div>    
</template>

<script>
import Loading from '@/views/Loading'
import WContactsAPI from '@/api/WContactsAPI'
import WContact from './WContact'
import Vue2Filters from 'vue2-filters'
import Table from '@/components/global/TableComponent/Table'

export default {
	mixins: [Vue2Filters.mixin],
	components: {
		tableT:Table,
		loading: Loading,
		'contact-create': WContact
	},
	name: 'WContactList',
	data: function () {
		return {
			loading: false,  
			showCreateContact: false, 
			filter: '',
			contactList: undefined,   
			departmentList:undefined,
			attendantList:undefined,
			originalContactList: [],
			perPage: 20,
			selected: 20,
			currentPage: 1,  
			totalPage: 1,  
			paginatedItems: [], 
			perPageOptions: [20, 50, 100, 200, 500],        
			fields: [
				{
					key: 'name',
					sortable: true,
					label:'Contatos'
					
				},
				{
					key: 'waId',
					sortable: true,
					label:'Número'
				},
				{
					key: 'statusDescription',
					sortable: true,
					label:'Status do número'
				},
				{
					key: 'email',
					sortable: true,
					label:'E-mail'
				},
				{
					key:'propertyCount',
					sortable: true,
					label:'Propriedades'	
				},
				{
					key:'labelCount',
					sortable: true,
					label:'Marcações'	
				},
				{
					key:'agentDepartmentLeadCustom',
					sortable: true,
					label:'Responsável Padrão'	
				},
				
			],                                              
		}
	},
	computed: {
		rows () {
			return this.contactList.length
		},
		lists () {
			const items = this.contactList
			console.log(this.contactList)
			// items.sort(this.dynamicSort('name')) 
			return items.slice(
				(this.currentPage - 1) * this.perPage,
				this.currentPage * this.perPage
			)
		},      
	},    
	methods: {           
		openContactDetail (contactId, waId) {
			this.$router.push({ path: '/contact/'+ this.$route.params.companyId +'/'+contactId+'/'+ '0/' + waId +'/detail' })            
		},
		getContactList () {
			this.$store.commit('setShowLoading', true)
			WContactsAPI.getContacts(this.$route.params.companyId, response => {
				let contactList = response.data
				contactList.forEach(element => {
					element.visible = true
				})
				this.contactList = contactList 
				this.originalContactList = contactList
				this.$store.commit('setShowLoading', false)
			})
		},  
		setPerPage () {
			this.perPage = this.selected
		},
		dynamicSort (property) {
			let sortOrder = 1
			if(property[0] === '-'){
				sortOrder = -1
				property = property.substr(1)
			}
			return function (a,b) {
				let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
				return result * sortOrder
			}
		},           
		searchContacts () {
			let contactList = this.originalContactList
			this.contactList = []            
			contactList.forEach( el => {
				if ( el.name.toLowerCase().includes(this.filter.toLowerCase()) 
                || el.waId.toLowerCase().includes(this.filter.toLowerCase())){
					el.visible = true
					this.contactList.push(el)
				} else {
					el.visible = false
				}
			})
		},   
		searchContactsStatus (status) {
			let contactList = this.originalContactList
			this.contactList = []
			contactList.forEach( el => {
				if ( el.status == status || status == 'all'){
					el.visible = true
					this.contactList.push(el)
				} else {
					el.visible = false
				}
			})        
		},                 
		create () {
			this.showCreateContact = true
		}            
	},      
	async mounted () {
		this.getContactList()
	}
}
</script>

<style scoped>
u {
    cursor: pointer;
}
th {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #999999 !important;    
}

td {
    margin: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #777777; 
    height: 50px;
    vertical-align: middle !important;
}
.td-contact {
    margin-top: 20px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.td-phonenumber {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;  
}
.td-status {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-email {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}
.td-property {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}
.sidebar-right-contacts {
    display: flex !important;
    width: 100% !important;
    flex-direction: column !important;
    background: white !important;
    padding: 10px;
    margin-right: 50px;
}
.custom-pagination-input {
    width: 50px !important;
}
.custom-pagination-select {
    width: 60px !important;
}

.contatc-list-scrollable {
    height: 90vh;
    width: 95%;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
	border-radius: 10px;
	padding-right: 50px;
}
.lblPagination {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px; 
    color: #777777;   
}

.form-control:focus {
    background-color: #F5F5F5;
    box-shadow: none;
}
</style>
