<template>
	<div>
		<b-row>
			<b-dropdown variant="light" size="sm" :text="currentStatus" class="m-1 dropdown-style">
				<b-dropdown-item  v-for="(value, key, index) in statusOptions"
					:key="index"
					:value="key" @click="changeStatus(key)"> <span class="medium-font">{{value}}</span>
				</b-dropdown-item>
			</b-dropdown>  
		</b-row>
		<div>
			<template-modal-cancel
				:descriptionHtml = "'<b> Deseja fechar este atendimento?</b><br><br> Um atendimento fechado só pode ser reaberto com o envio de um template, ou a resposta desse contato.'"
				:description = "''"
				:description1= "''"
				:value = "''"
				:title="'Encerramento de conversa'"
				:placeholder = "''"
				:button1 = "'Cancelar'"
				:button2 = "'Fechar Atendimento'"
				:start="showModalCancel"   
				v-on:sendModalData="postChange"
				:key="componentCancelModalKey"                        
			/>
		</div> 
                     
	</div>                                          
</template>

<script>
import WPhoneNumberAPI from '@/api/WPhoneNumberAPI'
import WModal from '@/views/WModal'
export default {
	name: 'WChatStatus',
	components: {
		'template-modal-cancel': WModal,
	},
	props: [
		'phonenumberId',
		'waId',
		'currentStatus',
		'contactName'
	],
	data () {
		return { 
			statusOptions: [],
			showModalCancel: false, 
			componentCancelModalKey: 0,
			chatStatus: ''            
		}
	},    
	methods: {
		postChange () {
			this.showModalCancel = false 
			WPhoneNumberAPI.postChatStatus(this.$route.params.companyId, this.phonenumberId, this.waId, this.chatStatus, response => {
				if (response){  
					let obj = {file: null, mediaType: 'default', msg: '', visible: false}
					this.$emit('sendMessageData', obj)                    
				} else {
					alert('Erro ao alterar o status!')
				}
			}) 
		},   
		changeStatus (chatStatus) {
			if(chatStatus == 4){
				this.chatStatus = chatStatus
				this.componentCancelModalKey ++
				this.showModalCancel = true 
			}else{
				WPhoneNumberAPI.postChatStatus(this.$route.params.companyId, this.phonenumberId, this.waId, chatStatus, response => {
					if (response){  
						let obj = {file: null, mediaType: 'default', msg: '', visible: false}
						this.$emit('sendMessageData', obj)                    
					} else {
						alert('Erro ao alterar o status!')
					}
				}) 
			}
		},  
		getChatStatusOptions () {
			WPhoneNumberAPI.getChatStatusOptions(this.$route.params.companyId, this.phonenumberId, this.waId, response => {
				this.statusOptions = response.data
			})              
		}             
	},
	mounted () {
		if (this.waId != '' && this.waId != undefined ){
			this.getChatStatusOptions()
		}        
	}, 
}
</script>

<style>

</style>

 
