<template>
	<div >

		<span class="custom-font-open-14-gray">Usuário atual:</span>
		<div >
			<b-dropdown id="dropdown-grouped" variant="light" :text="currentAttendant" v-b-tooltip.hover :title="currentAttendant" class="custom-font-open-12" :class="this.$mq != 'xs' ? 'my-dropdown' : 'my-dropdown-mobile'">
				<b-dropdown-group id="dropdown-group-1" header="Filtrar" >
					<b-col sm="12" class="ml-2">
						<b-input-group>
							<b-form-input 
								size="sm"
								type="search"                     
								class="mt-1"
								v-model="filter"
								@keyup="search"
								@update="clearFilter">
							</b-form-input>
							<b-input-group-prepend is-text class="mt-1">
								<b-icon icon="search"></b-icon>
							</b-input-group-prepend>                    
						</b-input-group> 
					</b-col>
				</b-dropdown-group>
				<b-dropdown-group id="dropdown-group-2" header="Departamentos" >
					<b-dropdown-item v-for="x in orderedAttendantList "
						:key="x.id"
						v-show="x.department == true"
						:title="x.description"
						:value="x.id" @click="selectAttendant(x.id, x.description)"> 
					
						<b-row class="my-dropdown2 flex-nowrap ml-0">
							<b-avatar v-if="x.avatarCustom == false" class="avatar" :style="x.avatar">{{ x.description.substring(0,2).toUpperCase()}}</b-avatar>
							<b-avatar v-else :src="getAvatarUrl(x.id)" class="avatar"></b-avatar>    
							<span class="custom-font-open-12 ml-2" style="margin-top:2px">{{x.description}}</span>       
						</b-row>                    
					</b-dropdown-item> 
				</b-dropdown-group>
				<b-dropdown-group id="dropdown-group-3" header="Usuários">
					<b-dropdown-item v-for="x in orderedAttendantList"
						:key="x.id"
						v-show="x.department == false"
						:value="x.id" @click="selectAttendant(x.id, x.description)"> 
						<b-row class="ml-0">
							<b-avatar v-if="x.avatarCustom == true" class="avatar" :src="getAvatarUrl(x.id)"></b-avatar>
							<b-avatar v-else class="avatar">{{ x.description.substring(0,2).toUpperCase()}}</b-avatar> 
							<span class="custom-font-open-12 ml-2">{{x.description}}</span>     
						</b-row>                       
					</b-dropdown-item>
				</b-dropdown-group>
			</b-dropdown>
		</div>
		<b-modal 
			v-model="show"
			title="Transferir conversa"
			centered
			scrollable>
			<b-col>
				<b-row class="m-1">
					<h5>Você deseja realmente atribuir essa conversa a este usuário?</h5>
				</b-row>
			</b-col>
            
			<b-container fluid class="m-3">           
				<b-row>
					<b-avatar :src="currentAttendantAvatarUrl"></b-avatar>
					<span class="medium-font ml-2 mt-2">{{currentAttendant}}</span>
					<span class="mt-1 mr-2">
						<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M8.99951 13.5H24.7495" stroke="#C4C4C4" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M20.2495 9L24.7495 13.5L20.2495 18" stroke="#C4C4C4" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</span>
					<b-avatar :src="destinationAttendantAvatarUrl"></b-avatar>
					<span class="medium-font ml-2 mt-2">{{destinationAttendant}}</span>
				</b-row>                         
			</b-container>         
			<template #modal-footer>
				<div class="w-100">
					<b-button variant="outline-primary" class="mr-2"
						@click="show=false">Cancelar
					</b-button>   
					<b-button variant="primary" class="float-right mr-5"
						@click="transfer">Atribuir           
					</b-button>                     
				</div>  
			</template>
		</b-modal>                        
	</div>                                          
</template>

<script>
import { avatarUrl} from '@/global'
import WPhoneNumberAPI from '@/api/WPhoneNumberAPI'
export default {
	name: 'WChatTransfer',
	props: [
		'phonenumberId',
		'waId',
		'agentId',
		'agentName'
	],
	data () {
		return { 
			show: false,
			targetId: '',
			filter: '',
			attendants: [],
			originalAttendants:[],
			currentAttendant: this.agentName, 
			destinationAttendant: '',
			currentAttendantAvatarUrl: avatarUrl + this.$route.params.companyId + '&imageId='+this.agentId,                                                              
			destinationAttendantAvatarUrl: ''
		}
	},    
	computed: {	
		orderedAttendantList () {
			const items = this.attendants	
			items.sort(this.dynamicSort(['description']))												
			return items
		},      	
	},  	
	methods: {  
		search () {
			const list = this.originalAttendants
			this.attendants = []
			if (this.filter == ''){
				this.attendants = this.originalAttendants
				return
			}
			this.attendants = list.filter(el => {
				return el.description.toLowerCase().includes(this.filter.toLowerCase())
			})
		},
		getAvatarUrl (id) {
			return avatarUrl + this.$route.params.companyId + '&imageId='+id
		},      
		selectAttendant (id, name) {
			this.show = true    
			this.targetId = id
			this.destinationAttendantAvatarUrl =  avatarUrl + this.$route.params.companyId + '&imageId='+id
			this.destinationAttendant = name            
		},
		transfer () {                          
			WPhoneNumberAPI.postAssignChatAgentOrDepartment(this.$route.params.companyId, this.phonenumberId, this.waId, this.targetId, response => {
				if (response){
					this.show = false    
					let obj = {file: null, mediaType: 'default', msg: '', visible: false}
					this.$emit('sendMessageData', obj)
				} else {
					alert('Erro ao transferir a conversa!')
					this.show = true
				}
			})               
		},  
		getAttendants () {
			WPhoneNumberAPI.getTransferOptions(this.$route.params.companyId, this.phonenumberId, this.waId, response => {
				this.attendants = response.data
				this.originalAttendants =response.data
			})              
		},       
		dynamicSort (fields) {
			return function (a, b) {
				return fields
					.map(function (o) {
						let dir = 1
						if (o[0] === '-') {
							dir = -1
							o=o.substring(1)
						}
						if (a[o].toLowerCase() > b[o].toLowerCase()) 
							return dir
						if (a[o].toLowerCase() < b[o].toLowerCase()) 
							return -(dir)
						return 0
					})
					.reduce(function firstNonZeroValue (p,n) {
						return p ? p : n
					}, 0)
			}
		},
		clearFilter () {
			if(this.filter == ''){
				this.search()
			}
		}		      
	},
	mounted () {	
		if (this.waId != ''){
			this.currentAttendant = this.agentName
			this.getAttendants()
		}
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			console.log('Modal chat-transfer is about to be shown', bvEvent, modalId)
			let obj = {file: null, mediaType: 'default', msg: 'close_modal', visible: false}
			this.$emit('sendMessageData', obj) 
		})          
	}, 
}
</script>

<style>
.my-dropdown > button  {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}
.my-dropdown .dropdown-menu {
    max-height: 70vh;
    overflow-y: auto;
	overflow-x: hidden;
  }

.my-dropdown-mobile > button  {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
}

.my-dropdown2 > span  {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	flex-wrap: nowrap;
	max-width: 200px;
}
.avatar2 {
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--primary);
	border-radius: 50%;
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 10px;
	line-height: 22px;
	text-align: center;
	color: #FFFFFF;
} 
</style>

 
