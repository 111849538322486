<template>
	<div>
		<h5 class="custom-font-open-rob-500">Nenhum dado encontrado.</h5>
	</div>	
</template>
<script>
export default {
	name: 'NoDataFound',
	data () {
		return {	
		}
	},
	methods: {  
	},
	mounted () {
		
	}

}
</script>



