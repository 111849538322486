<template>
	<div>
		<b-modal 
			v-model="show"
			centered
			size="lg"
			scrollable>
			<template #modal-title>
				<span v-if="step == 1">Selecione um flow.</span>
				<span v-if="step == 2">Selecione um flow2.</span>
			</template>
			<div class="scrollbar">
				<div v-if="step == 1" style="min-height:300px">
					<tableT v-if="flowList" 
						:list="flowList" 
						:fields="fieldsFlow" 
						:perPage="10" 
						:searchBar="true"
						:filterFields="['flowName']"
						:searchPlaceHolder = "'Busque pelo nome'"
						:sortBy ="'flowName'"
						:selected="10"
						:disablePagination="false"
						@radioClicked="radioClicked"
					/>
				</div>     
				<div class="ml-3" v-else-if="step == 2">
					
				</div>  
			</div>
			<template #modal-footer>
				<div class="w-100">
					
					<b-button variant="outline-primary" class="float-left" @click="hide">
						Cancelar
					</b-button>  
					<b-button variant="primary" class="float-right" @click="next" :disabled="((step == 1 && !selectedFlow||step == 2 && !selectedEvent) )">
						{{step==1?'Avançar':'Escolher'}}
					</b-button>
				</div>
			</template>
		</b-modal> 
	</div>
</template>

<script>
import WChatFlowAPI from '@/api/WChatFlowAPI'
import Table from '@/components/global/TableComponent/Table'
export default {
	components:{
		tableT:Table,
		
	}, 
	props:[
		'contactActive'
	],
	data () {
		return {	
			flowList:undefined,
			fieldsFlow: [
				{
					key:'radio',
					label:''
				},
				{
					key: 'flowName',
					sortable: true,
					label:'Nome do Fluxo'
				},
				{
					key: 'stepCount',
					sortable: true,
					label:'Etapas'
				},
				{
					key: 'endCount',
					sortable: true,
					label:'Finalizado'
				},
				{
					key: 'userCreatedName',
					sortable: true,
					label:'Criado por'
				},
			],
			selectedFlow:undefined,
			step: 1,
			eventList:[],
			selectedEvent:undefined,
			show:true
		}
	},
	watch:{
		selectedEvent () {
			let aux = {id: this.selectedFlow.pixelId,step: 1}
			this.$emit('sendMessageData', aux)
		}
	},
	methods: {
		hide () {
			this.$emit('hide')
		},
		next () {
			let flow = this.selectedFlow
			let obj = {file: null, mediaType: 'flow', flow}
			this.$emit('sendMessageData', obj) 
		},
		radioClicked (obj) {
			this.selectedFlow = obj
			this.eventList = this.selectedFlow.eventList
		},
		getChatFlowList () {
			this.loading = true
			this.pixelList = undefined
			WChatFlowAPI.getChatflowByDepartmentId(this.$route.params.companyId,this.contactActive.departmentId,response=>{
				console.log(response.data)
				this.flowList = response.data
				
				this.loading = false
			})
		},
	},
	mounted () {  
		console.log(this.contactActive)
		this.getChatFlowList()  
	}, 
}
</script>

<style>

</style>