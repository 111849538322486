<template>
	<div>     
		<div v-show="messageInfo.mediaType != 'becon' 
			&& messageInfo.mediaType != 'template'
			&& messageInfo.mediaType != 'quickreply'
			&& messageInfo.mediaType != 'others'
			&& messageInfo.quickreply == false">   
			<div>                           
				<div v-if="messageInfo.hasURL == true" class="custom-font-open-14" style="white-space: pre-line; word-break: break-word;" v-html="replaceMulti(messageInfo.message)" v-linkified:options="{ className: 'b' }"></div>
				<span v-else class="custom-font-open-14" style="white-space: pre-line;word-break: break-word;" v-html="replaceMulti(messageInfo.message)"></span>                                    
			</div>
			<b-col v-if="messageInfo.mediaSize == 0" class="d-flex justify-content-end mr-2">
				<span v-if="messageInfo.status=='received' && messageInfo.contextMessageId == '' && messageInfo.mediaType != 'video'" class="dateReceived">
					{{messageInfo.timeSending}}                                                       
					<span v-show="messageInfo.tick == 0"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" fill="none"><g id="clock"><path id="Vector" d="M7 5V8L9 9" stroke="#999999" stroke-linecap="round" stroke-linejoin="round"/><path id="Rectangle 93" d="M2.5 6C2.5 4.067 4.067 2.5 6 2.5H7.5H9C10.933 2.5 12.5 4.067 12.5 6V9C12.5 10.933 10.933 12.5 9 12.5H6C4.067 12.5 2.5 10.933 2.5 9V6Z" stroke="#999999"/></g></svg></span>
					<span v-show="messageInfo.tick == 1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck" x="2047" y="2061"><path d="M15.01 3.316l .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#92a58c"/></svg></span>                               
					<span v-show="messageInfo.tick == 2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck" x="2047" y="2061"><path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#92a58c"/></svg></span>                                    
					<span v-show="messageInfo.tick == 3"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck-ack" x="2063" y="2076"><path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#4fc3f7"/></svg></span>                                    
				</span>
			</b-col>                
		</div>
		<b-row v-if="messageInfo.mediaSize != 0">
			<b-col class="d-flex justify-content-start ml-3">
				<span class="dateReceived" v-show="messageInfo.mediaSize > 0 && messageInfo.quickreply ==false">{{bytesToSize(messageInfo.mediaSize )}}</span>
			</b-col>
			<b-col class="d-flex justify-content-end mr-2">
				<span v-if="messageInfo.status=='received' && messageInfo.contextMessageId == '' && messageInfo.mediaType != 'video'" class="dateReceived">
					{{messageInfo.timeSending}}                                                       
					<span v-show="messageInfo.tick == 0"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" fill="none"><g id="clock"><path id="Vector" d="M7 5V8L9 9" stroke="#999999" stroke-linecap="round" stroke-linejoin="round"/><path id="Rectangle 93" d="M2.5 6C2.5 4.067 4.067 2.5 6 2.5H7.5H9C10.933 2.5 12.5 4.067 12.5 6V9C12.5 10.933 10.933 12.5 9 12.5H6C4.067 12.5 2.5 10.933 2.5 9V6Z" stroke="#999999"/></g></svg></span>
					<span v-show="messageInfo.tick == 1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck" x="2047" y="2061"><path d="M15.01 3.316l .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#92a58c"/></svg></span>                               
					<span v-show="messageInfo.tick == 2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck" x="2047" y="2061"><path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#92a58c"/></svg></span>                                    
					<span v-show="messageInfo.tick == 3"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck-ack" x="2063" y="2076"><path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#4fc3f7"/></svg></span>                                    
				</span>
			</b-col>
		</b-row>                                                                                                                                                                                  
	</div>                                          
</template>

<script>
export default {
	name: 'WMessageText',
	props: [
		'messageInfo'
	],
	data () {
		return {            
		}
	},    
	methods: {        
		bytesToSize (bytes) {
			let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
			if (bytes == 0) 
				return '0 Byte'
			let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
			return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
		},
		replaceMulti (haystack) {
			if (typeof haystack === 'string' || haystack instanceof String) {
				if (haystack != undefined){
					return haystack.split('\\n').join('<br>')                
				}
			}
		},        
	},    
	mounted () {
	}, 
}
</script>

<style scoped>
    .dateReceived {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        color: #777777;
    }    
    .a {
        display: flex;
    }          
    .a >>> .b {
        display: contents !important;
    }       
</style>



 