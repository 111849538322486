<template>
	<div>
		<b-row>	
			<span v-if="actionType == 8" class="custom-font-open-14-400-gray">Selecione apenas um departamento. A partir daqui, <b>todo atendimento que o contato abrir, será no departamento selecionado.</b></span>        			
			<span v-else class="custom-font-open-14-400-gray">Selecione apenas um departamento para quem o sistema deverá atribuir este contato</span>        
		</b-row>       
		<b-row>
			<b-col md="10" class="mb-2">
				<div class="search" style="margin-left: -15px;">  
					<b-input-group>
						<b-form-input 
							type="search"                     
							placeholder="Busque pelo nome do departamento"
							v-model="filter"
							@keyup="searchDepartment"
							class="mt-1"
							@update="clearFilter">
						</b-form-input>
						<b-input-group-prepend is-text class="mt-1">
							<b-icon icon="search"></b-icon>
						</b-input-group-prepend>                    
					</b-input-group>                                                                                       
				</div>
			</b-col>
		</b-row>
		<div class="automation-departments-scrollable scrollbar" id="list">
			<div v-for="(x, index) in orderDepartmentList" :key="x.departmentId" v-show="x.visible == true" :class="[ '', index == currentDepartmentIndex ? 'template-active' : '' ]">
				<label :for="x.departmentId" @change="setDepartmentId(index, x.departmentId)" style="width: 100%;margin-left: 8px;" class="icon-clickable">                             
					<b-form-radio
						:id="x.departmentId"
						:name="'option-selected'"
						value="x.departmentId"
						size="lg"
					>
						<b-row class="ml-1 mt-1">
							<span class="ml-2 mr-2" style="margin-top: -1px;">
								<div v-if="x.departmentName != undefined" class="avatar" :style="x.avatar">{{ x.departmentName.substring(0,2).toUpperCase() }}</div>
								<div v-else class="avatar"></div>
							</span>         
							<span class="ml-1">{{x.departmentName}}</span>   
			
						</b-row>

					</b-form-radio>                                                
				</label>               
			</div> 
		</div>                                        
	</div>                                          
</template>

<script>
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import Vue2Filters from 'vue2-filters'
import { avatarUrl} from '@/global'
import WSortBy from '@/js/utils/WSortBy'
export default {
	mixins: [Vue2Filters.mixin],
	name: 'WKeywordsDepartments',    
	props: [
		'actionType'
	],   	
	data () {
		return { 
			filter: '',
			currentDepartmentIndex: null,
			avtUrl: avatarUrl,
			departments: []
		}
	},
	computed: {	
		orderDepartmentList () {					
			return WSortBy.orderMyList('departmentName',this.departments)
		},      	
	},    
	methods: {
		getDepartmentList () {
			WDepartmentsAPI.getDepartmentsOfUser(this.$route.params.companyId, response => {
				let departments = response.data
				departments.forEach(dep => {
					dep.visible =  true
				})
				this.departments = departments
			})
		}, 
        
		setDepartmentId (index, id) {
			this.currentDepartmentIndex = index
			let obj = {id: id}
			this.$emit('sendMessageData', obj)            
		},           
		searchDepartment () {
			this.departments.forEach( el => {
				if ( el.departmentName.toLowerCase().includes(this.filter.toLowerCase()) ){
					el.visible = true
				} else {
					el.visible = false
				}
			})        
		},
		dynamicSort (fields) {
			return function (a, b) {
				return fields
					.map(function (o) {
						let dir = 1
						if (o[0] === '-') {
							dir = -1
							o=o.substring(1)
						}
						if (a[o].toLowerCase() > b[o].toLowerCase()) 
							return dir
						if (a[o].toLowerCase() < b[o].toLowerCase()) 
							return -(dir)
						return 0
					})
					.reduce(function firstNonZeroValue (p,n) {
						return p ? p : n
					}, 0)
			}
		},
		clearFilter () {
			if(this.filter == ''){
				this.searchDepartment()
			}
		}                    
	},
	mounted () {      
		this.getDepartmentList()                      
	}, 
}
</script>

<style scoped>
.automation-departments-scrollable {
    height: 35vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden; 
    margin-left: -15px;
}
.td-data {
    padding-top: 15px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px; 
    color: #777777; 
}
.avatar {
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--primary);
        border-radius: 50%;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
    }   
	#list {
  height: 43vh;
}

@media only screen and (max-width: 1024px) {
  #list {
	height: 37vh;
  }
}
</style>

 
