<template>
	<div class="">
		<div  class="base-timer">
			<svg
				class="base-timer__svg"
				viewBox="0 0 100 100"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g class="base-timer__circle">
					<circle
						:class="[ 'base-timer__path-elapsed-green', sessionExpiresIn == '00:00' ? 'base-timer__path-elapsed-gray' : '' ]"          
						cx="50"
						cy="50"
						r="46.5"
					/>
				</g>
			</svg>
			<span class="base-timer__label">
				{{(sessionExpiresIn)}}
			</span>
		</div>
	</div>  
</template>

<script>
export default {
	name: 'WChatCountDown',
	props: [
		'sessionExpiresIn'
	],
	data () {
		return {            
		}
	},    
	methods: {
		minutesToHours (n) {
			let num = n
			let hours = (num / 60)
			let rhours = Math.floor(hours)
			let minutes = (hours - rhours) * 60
			let rminutes = Math.round(minutes)
			return rhours + ':' + rminutes 
		}                   
	},
	mounted () {

	}, 
}
</script>
<style scoped lang="scss">
    .base-timer {
    position: relative;
    width: 48px;
    height: 48px;
    /* Removes SVG styling that would hide the time label */
    &__circle {
        fill: none;
        stroke: none;
    }
    /* The SVG path that displays the timer's progress */
    &__path-elapsed-green {
        stroke-width: 7px;
        stroke:#25D366;
    }
    &__path-elapsed-gray {
        stroke-width: 7px;
        stroke:#999999;
    }
    .base-timer__label {
        position: absolute;
        top:0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 700;
    }  
}
</style>