<template>
	<div v-if="!context" class="w-video"> 	
		<div :class="`w-play-${type}`">
			<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle opacity="0.5" cx="20" cy="20" r="20" fill="#414141"/>
				<path d="M15 10.8317C15 10.0405 15.8752 9.56266 16.5408 9.99049L29.6915 18.4445C30.3038 18.8382 30.3038 19.7333 29.6915 20.1269L16.5408 28.5809C15.8752 29.0088 15 28.5309 15 27.7398V10.8317Z" fill="white"/>
			</svg>		
		</div>	
		<div :class="`v-icon-${type}`">
			<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M13.9163 4.08325L9.83301 6.99992L13.9163 9.91659V4.08325Z" fill="white"/>
				<path d="M8.66634 2.91675H2.24967C1.60534 2.91675 1.08301 3.43908 1.08301 4.08341V9.91675C1.08301 10.5611 1.60534 11.0834 2.24967 11.0834H8.66634C9.31067 11.0834 9.83301 10.5611 9.83301 9.91675V4.08341C9.83301 3.43908 9.31067 2.91675 8.66634 2.91675Z" fill="white"/>
			</svg>
		</div>		
		<div :class="`v-time-${type}`">
			{{time}}
		</div>						 				                                                          			
	</div>  
	<div v-else class="w-video-context"> 		
		<div :class="`v-icon-${type}`">
			<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M13.9163 4.08325L9.83301 6.99992L13.9163 9.91659V4.08325Z" fill="white"/>
				<path d="M8.66634 2.91675H2.24967C1.60534 2.91675 1.08301 3.43908 1.08301 4.08341V9.91675C1.08301 10.5611 1.60534 11.0834 2.24967 11.0834H8.66634C9.31067 11.0834 9.83301 10.5611 9.83301 9.91675V4.08341C9.83301 3.43908 9.31067 2.91675 8.66634 2.91675Z" fill="white"/>
			</svg>			
			<span class="w-video-context-text">Vídeo</span>			
		</div>		
		<div :class="`v-time-${type}`">
			{{time}}
		</div>						 				                                                          			
	</div>   	                                        
</template>

<script>

export default {
	name: 'WVideo',
	props: [
		'type',
		'time',
		'context'
	],
	data () {
		return {
                
		}
	},    
	methods: {      
	},    
	mounted () {
	}
}
</script>

<style scoped>   
	.w-video {
		position: relative;
		width: 100%;
		min-width: 250px;
		height: 170px;
		background: #666;	
		text-align: center;	
		border-radius: 10px;		
	}
	.w-video-context {
		position: relative;
		width: 150px;
		height: 100px;
		background: #666;	
		text-align: center;	
		border-radius: 10px;		
	}	
	.w-video:hover {
		opacity: 0.9;
		transition: 0.3s;				
	}	
	.w-video .w-play-1 {
		position: absolute;
		top: 48%;
		left: 50%;
		transform: translate(-50%,-50%);
	}		
	.w-video .w-play-2 {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
	.w-video .v-icon-1 {
		margin-left: 15px;
		text-align: left;
		position: absolute;
		bottom: 8px;
	}
	.w-video .v-icon-2 {		
		margin-left: 15px;
		text-align: left;
		position: absolute;
		bottom: 8px;
	}

	.w-video-context .v-icon-1 {
		margin-left: 15px;
		text-align: left;
		position: absolute;
		bottom: 8px;
	}
	.w-video-context .v-icon-2 {		
		margin-left: 15px;
		text-align: left;
		position: absolute;
		bottom: 8px;
	}

	.w-video .v-time-1 {
		position: absolute;		
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;		
		color: #fff;
		right: 10px;
		bottom: 10px;			
	}	
	.w-video .v-time-2 {
		position: absolute;		
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;		
		color: #fff;
		right: 10px;
		bottom: 10px;		
	}	
	.w-video-context .v-time-1 {
		position: absolute;		
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;		
		color: #fff;
		right: 10px;
		bottom: 10px;			
	}	
	.w-video-context .v-time-2 {
		position: absolute;		
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;		
		color: #fff;
		right: 10px;
		bottom: 10px;		
	}	
	.w-video-context-text {
		margin-top: 7px;
		margin-left: 10px;
		position: absolute;		
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;		
		color: #fff;	
	}			
</style>



 