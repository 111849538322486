import axios from 'axios'
import i18n from '../plugins/i18n'
import { baseApiUrl, access_token} from '../global'


axios.defaults.headers.common['Authorization'] = `bearer ${access_token}`


export default {
   
	getListKeywords: (companyId,  callback) => {
		const url = `${baseApiUrl}`+'automation/'+ companyId + '/listKeywords'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},   
	postDraftKeyword (companyId, keywordList, matchingMethod, fuzzyRatio, callback) {
		const params = new URLSearchParams()
		params.append('keywordList', keywordList)  
		params.append('matchingMethod', matchingMethod)
		params.append('fuzzyRatio', fuzzyRatio)               
		let url = `${baseApiUrl}` + 'automation/'+ companyId + '/draftKeyword'
		axios.post(url, params)
			.then(response => {
				if (response){                                 
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},                
  
	getDepartmentAutomation: (companyId, departmentId, callback) => {
		const url = `${baseApiUrl}`+'automation/'+ companyId + '/' + departmentId + '/departmentAutomation'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},   
	postEnable (companyId, departmentId, automationType, callback) {
		let url = `${baseApiUrl}` + 'automation/'+ companyId + '/' + departmentId+ '/' + automationType + '/enableDepartmentAutomation'
		axios.post(url)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},      
	postDisable (companyId, departmentId, automationType, callback) {
		let url = `${baseApiUrl}` + 'automation/'+ companyId + '/' + departmentId+ '/' + automationType + '/disableDepartmentAutomation'
		axios.post(url)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},    
	postLinkQuickReply (companyId, departmentId, automationType, quickReplyId, callback) {
		const params = new URLSearchParams()
		params.append('quickReplyId', quickReplyId)  
		let url = `${baseApiUrl}` + 'automation/'+ companyId + '/' + departmentId+ '/' + automationType + '/linkQuickReplyInDepartmentAutomation'
		axios.post(url, params)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},     
	postLinkTemplate (companyId, departmentId, automationType, templateId, callback) {
		const params = new URLSearchParams()
		params.append('templateId', templateId)  
		let url = `${baseApiUrl}` + 'automation/'+ companyId + '/' + departmentId+ '/' + automationType + '/linkTemplateInDepartmentAutomation'
		axios.post(url, params)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	}, 
	postLinkChatflow (companyId, departmentId, automationType, chatflowId, callback) {
		const params = new URLSearchParams()
		params.append('chatflowId', chatflowId)  
		let url = `${baseApiUrl}` + 'automation/'+ companyId + '/' + departmentId+ '/' + automationType + '/linkChatflowInDepartmentAutomation'
		axios.post(url, params)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	}, 
	deleteRemoveLink (companyId, departmentId, automationType, callback) {            
		let url = `${baseApiUrl}` + 'automation/'+ companyId + '/' + departmentId+ '/' + automationType + '/removeLinkInDepartmentAutomation'
		axios.delete(url)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},  
	postEnableKeyword (companyId, keywordId, callback) {
		let url = `${baseApiUrl}` + 'automation/'+ companyId + '/' + keywordId + '/enableKeyword'
		axios.post(url)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},    
	postDisableKeyword (companyId, keywordId, callback) {
		let url = `${baseApiUrl}` + 'automation/'+ companyId + '/' + keywordId + '/disableKeyword'
		axios.post(url)
			.then(response => {
				if (response){                                 
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},      
	deleteKeyword (companyId, keywordId, callback) {            
		let url = `${baseApiUrl}` + 'automation/'+ companyId + '/' + keywordId + '/deleteKeyword'
		axios.delete(url)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},       
	deleteAllKeywordAction (companyId, keywordId, callback) {            
		let url = `${baseApiUrl}` + 'automation/'+ companyId + '/' + keywordId + '/deleteAllKeywordAction'
		axios.delete(url)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},  
	deleteKeywordAction (companyId, keywordId, keywordActionId, callback) {                     
		let url = `${baseApiUrl}` + 'automation/'+ companyId + '/' + keywordId + '/' + keywordActionId + '/deleteKeywordAction'
		axios.delete(url)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},    
	getListKeywordsActions: (companyId, keywordId,  callback) => {
		const url = `${baseApiUrl}`+'automation/'+ companyId + '/' + keywordId + '/listKeywordsActions'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},
	postPermissions (companyId, keywordId, departmentAll, departmentIdList, callback) {
		const params = new URLSearchParams()
		params.append('departmentAll', departmentAll)  
		params.append('departmentIdList', departmentIdList)                   
		let url = `${baseApiUrl}` + 'automation/'+ companyId + '/' + keywordId + '/departments'
		axios.post(url, params)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},     
	postCreateAction (companyId, keywordId, actionType, actionReferenceId, extra, callback) {
		const params = new URLSearchParams()
		params.append('actionType', actionType)  
		params.append('actionReferenceId', actionReferenceId)  
		params.append('extra', extra)                 
		let url = `${baseApiUrl}` + 'automation/'+ companyId + '/' + keywordId + '/createAction'
		axios.post(url, params)
			.then(response => {
				if (response){                                 
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},    
	postUpdateKeywordData (companyId, keywordId, keywordList, matchingMethod, fuzzyRatio, callback) {
		const params = new URLSearchParams()
		params.append('keywordList', keywordList)  
		params.append('matchingMethod', matchingMethod)  
		params.append('fuzzyRatio', fuzzyRatio)                 
		let url = `${baseApiUrl}` + 'automation/'+ companyId + '/' + keywordId + '/updateKeywordData'
		axios.post(url, params)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},              
	getKeywordDetail: (companyId, keywordId, callback) => {
		const url = `${baseApiUrl}`+'automation/'+ companyId + '/' + keywordId + '/detail'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},   
	getDepartments: (companyId, keywordId, callback) => {
		const url = `${baseApiUrl}`+'automation/'+ companyId + '/' + keywordId + '/departments'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},                                                
}
