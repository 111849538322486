<template>
	<div class="ml-5 mt-2"> 
		<b-row class="mr-5">
			<automation-action :addNew="true" :showButtons="false" v-on:sendMessageData="getMessageData"/>
			<div v-show="showActionList == true" class="automation-action-scrollable scrollbar ml-4 mt-2">
				<div>
					<automation-modal-delete
						:title = "'Excluir (Re)Ação'"
						:subTitle = "'Tem certeza que deseja excluir a (Re)Ação?'"
						:description = "actionName"
						:description1= "''"
						:value = "''"
						:placeholder = "''"
						:button1 = "'Fechar'"
						:button2 = "'Excluir'"
						:start="showModalDelete"   
						v-on:sendModalData="deleteAction"
						:key="componentDeleteModalKey"                        
					/>
				</div>             
				<b-table-simple id="my-table" hover small responsive>
					<b-thead head-variant="ligth">
						<b-tr>
							<b-th>#</b-th>
							<b-th>Categoria</b-th>                
							<b-th>Nome/Descrição</b-th>
							<b-th>Prévia/Valor</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr :key="index" v-for="(x, index) in orderBy(actionList, 'actionIndex')">
							<b-td class="td-automation">{{x.actionIndex}}</b-td>                    
							<b-td class="td-date">{{x.actionTypeDescription}}</b-td>                     
							<b-td class="td-date">{{x.actionDescription}}</b-td> 
							<b-td class="td-data">{{x.actionDetail}}</b-td> 
							<b-td>
								<span @click="setActionDelete(x.keywordId, x.keywordActionId, x.actionIndex)" class="icon-clickable">
									<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M3 4H4.33333H15" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M6.33337 4.00004V2.66671C6.33337 2.31309 6.47385 1.97395 6.7239 1.7239C6.97395 1.47385 7.31309 1.33337 7.66671 1.33337H10.3334C10.687 1.33337 11.0261 1.47385 11.2762 1.7239C11.5262 1.97395 11.6667 2.31309 11.6667 2.66671V4.00004M13.6667 4.00004V13.3334C13.6667 13.687 13.5262 14.0261 13.2762 14.2762C13.0261 14.5262 12.687 14.6667 12.3334 14.6667H5.66671C5.31309 14.6667 4.97395 14.5262 4.7239 14.2762C4.47385 14.0261 4.33337 13.687 4.33337 13.3334V4.00004H13.6667Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M7.66663 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M10.3334 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M3 4H4.33333H15" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M6.33337 4.00004V2.66671C6.33337 2.31309 6.47385 1.97395 6.7239 1.7239C6.97395 1.47385 7.31309 1.33337 7.66671 1.33337H10.3334C10.687 1.33337 11.0261 1.47385 11.2762 1.7239C11.5262 1.97395 11.6667 2.31309 11.6667 2.66671V4.00004M13.6667 4.00004V13.3334C13.6667 13.687 13.5262 14.0261 13.2762 14.2762C13.0261 14.5262 12.687 14.6667 12.3334 14.6667H5.66671C5.31309 14.6667 4.97395 14.5262 4.7239 14.2762C4.47385 14.0261 4.33337 13.687 4.33337 13.3334V4.00004H13.6667Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M7.66663 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M10.3334 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								</span>                        
							</b-td>     
						</b-tr>
					</b-tbody>
				</b-table-simple>  
			</div>          
		</b-row>        
		<b-row v-show="showActionList == true">
			<div class="w-100 ml-5 mt-3">
				<b-button variant="outline-primary" class="mr-2"
					@click="cancel">Cancelar
				</b-button> 
				<b-button v-if="originalStep == 5" variant="primary" class="float-right mr-5"
					@click="next">Avançar           
				</b-button>  
				<b-button v-else variant="primary" class="float-right mr-5"
					@click="save">Salvar           
				</b-button>              
			</div>                   
		</b-row>                                       
	</div>                                          
</template>

<script>
import WAutomationAPI from '@/api/WAutomationAPI'
import WAutomationAction from './WKeywordsAction'
import Vue2Filters from 'vue2-filters'
import WModal from '@/views/WModal'
export default {
	mixins: [Vue2Filters.mixin],
	name: 'WAutomationActionList', 
	props: ['step'],
	components: {
		'automation-action': WAutomationAction,   
		'automation-modal-delete': WModal,                   
	},      
	data () {
		return { 
			filter: '',
			actionList: [],
			keywordId: '',
			showActionList: true,
			keywordActionId: '',
			componentDeleteModalKey: 0,
			showModalDelete: false,
			actionName: '',
			originalStep: ''          
		}
	},    
	methods: {
		add () {
			let obj = {step: 3}
			this.$emit('sendMessageData', obj)  
		},
		getActionList () {
			WAutomationAPI.getListKeywordsActions(this.$route.params.companyId, this.keywordId, response => {
				console.log(response.data)
				this.actionList = response.data
			})
		},      
		getMessageData (obj) {
			this.showActionList = obj.showActionList
			if(obj.showActionList == true){
				this.cancel()
			}

			// eslint-disable-next-line no-prototype-builtins
			if (obj.hasOwnProperty('actionType')){
				let objSend = {step: 4, actionType: obj.actionType}
				this.$emit('sendMessageData', objSend)                    
			}            
		},      
		setActionDelete (id, actionId, name) {  
			this.keywordId = id
			this.keywordActionId = actionId
			this.componentDeleteModalKey ++
			this.showModalDelete = true 
			this.actionName = name
		},   

		deleteAction (deleteKeywords) {
			this.showModalDelete = false
			if (deleteKeywords){
				WAutomationAPI.deleteKeywordAction(this.$route.params.companyId, this.keywordId, this.keywordActionId, response => {
					if (response){
						this.getActionList()                      
					} else {
						alert('Falha ao excluir palavras chave!')
					}                                       
				})                
			}
		},           
		next () {       
			let obj = {step: 6, actionType: this.actionType }
			this.$emit('sendMessageData', obj)                                  
		},  
		save () {       
			let obj = {step: 1 }
			this.$emit('sendMessageData', obj)                                  
		},              
		cancel () {      
			let obj = {step: 1}
			this.$emit('sendMessageData', obj)             
		},   
		goBack () {
			let obj = {step: 1}
			this.$emit('sendMessageData', obj) 
		}                                       
	},
	mounted () {     
		let objKeyword = JSON.parse(localStorage.getItem('__wpp_automation_keyword_obj'))
		if (objKeyword != null){
			this.keywordId = objKeyword.keywordId
			if (objKeyword.step != undefined){
				this.originalStep = objKeyword.step
			} else {
				this.originalStep = this.step
			}
           
			this.getActionList()               
		}                                 
	}, 
}
</script>

<style scoped>
.automation-action-scrollable {
    height: 35vh;
    width: 100%;
    min-height: 100px;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden; 
}
th {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #999999 !important;    
}

td {
    margin: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #777777; 
    height: 50px;
    vertical-align: middle !important;
}
.td-automation {
    padding-top: 13px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px; 
    color: #777777;
    width: 30px;
}
.td-data {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
</style>

 
