<template>
	<html>
		<head>
			<title>Whatsapp Business</title>
		</head>
		<body>            
			Carregando...
		</body>
	</html>
</template>

<script>
import axios from 'axios'
import router from '@/router'
import { baseApiUrl} from '@/global'
import WCompanyAPI from '@/api/WCompanyAPI'
export default {
	name: 'RedirectTo',
	data () {
		return {
		}
	},
	methods: {
		redirect () {
			let accessToken = this.getURLParameters('accessToken')             
			let url = decodeURI(this.getURLParameters('redirectTo'))                 
			console.log('Parameters ->' + ' accessToken: ' + accessToken + ' url: ' + url)
			if (url == '' || url == null || url ==undefined) {
				router.push({ path: '/login'}) 
			} else {
				this.validateAccessTokenAndRedirect(accessToken, url) 
			}                                 
		}, 		
		getURLParameters (paramName) {
			let sURL = window.document.URL.toString()
			if (sURL.indexOf('?') > 0){
				let arrParams = sURL.split('?')
				let arrURLParams = arrParams[1].split('&')
				let arrParamNames = new Array(arrURLParams.length)
				let arrParamValues = new Array(arrURLParams.length)
				let i = 0
				for (i = 0; i<arrURLParams.length; i++){
					let sParam =  arrURLParams[i].split('=')
					arrParamNames[i] = sParam[0]
					if (sParam[1] != '')
						arrParamValues[i] = unescape(sParam[1])
					else
						arrParamValues[i] = 'No Value'
				}
				for (i = 0; i < arrURLParams.length; i++) {
					if (arrParamNames[i] == paramName) {
						return arrParamValues[i]
					} 
				}
			}
			return ''
		}, 
		validateAccessTokenAndRedirect (accessToken, url) {
			url   
			axios.defaults.headers.common['Authorization'] = `bearer ${accessToken}` 
			axios.get(`${baseApiUrl}oauth2/token/` + accessToken)
				.then(response => {
					let access_token = response.data.data.access_token
					if (access_token != '' && access_token != undefined) {
						localStorage.setItem('__wpp_access_token',accessToken)
						WCompanyAPI.getCompanyId()
						// window.location = url                                               
					} else {
						router.push({ path: '/login'}) 
					}
				})
				.catch(function (error) {
					console.log(error)
					router.push({ path: '/login'}) 
				})  
		}                  
	},
	mounted () {
		this.redirect()
	}     
}
</script>    