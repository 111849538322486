<template>
	<div>
		<!-- <span>{{`item[data.index].${count}`}}</span> -->
		<!-- <span>{{count}}</span> -->
		<!-- <span>{{spanTittle}}</span> -->
		<!-- <span>{{list}}</span> -->
		<span v-if="attributeAll == true">Todos</span>
		<span v-else>
			<button class="active-popover" :id="id" @click="getList(id)"><u>{{count}} {{spanTittle}}</u></button>                                                           
			<b-popover                       
				:target="id" 
				placement="auto"
				triggers="focus">
				<template #title>
					<b-button @click="onClose(id)" class="close ml-3" aria-label="Close">
						<span class="d-inline-block" >&times;</span>
					</b-button>
					{{spanTittle}}
				</template>                                    
				<div :key="index_" v-for="(d, index_) in list">
					<span class="custom-font-open-12-400">{{d}}</span>
				</div>                                                                                  
			</b-popover>                                  
		</span>         
	</div>
</template>

<script>
import WPropertiesAPI from '@/api/WPropertiesAPI'
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
export default {
	name: 'TableCellDepartment',
	props: [
		'item',
		'data',
		'attributeAll',
		'count',
		'spanTittle',
		'id',
	],
	data () {
		return {	
			list:[]
		}
	},
	methods:{
		onClose (id) {
			this.$root.$emit('bv::hide::popover', id)
		},
		getList (id) {
			let departmentDescriptionList = []
			switch(this.spanTittle){
			case 'Templates':
				WPropertiesAPI.getPropertyListTemplates(this.$route.params.companyId, id, response => {
					this.list = response.data
				})
				break
			case 'Usuários':
				WDepartmentsAPI.getUsersOfDepartment(this.$route.params.companyId, id, response => {
					this.loading = false
					let agentList = response.data  
					this.list = this.sortByValue(agentList)
				})                             
				break
			case 'Departamentos':
				
				if(this.item.departmentDescriptionList){
					this.list = this.item.departmentDescriptionList
				}else{
					console.log(this.item)
					this.item.departmentIdList.forEach(element => {
						WDepartmentsAPI.getDeparmentDetail(this.$route.params.companyId, element, response => {
							departmentDescriptionList.push(response.data.departmentName)
						}) 
					})
					this.list = departmentDescriptionList
				}
				
				break
			case 'Funções':
				this.list = this.item.rolesDescriptionList
				break
			case 'Eventos':
				this.list = this.item.eventList
				break
			
			
			case 'Resposta rápidas':
				WPropertiesAPI.getListQuickReply(this.$route.params.companyId, this.item.propertyId, response => {
					this.list = response.data
				}) 
				break
			}
		},
		sortByValue (jsObj) {
			let sortedArray = []
			for(let i in jsObj)
			{
				sortedArray.push(jsObj[i])
			}
			return sortedArray.sort()
		}, 

	},
}

</script>

<style>
	.active-popover{
		border: none;
		background: none;
		color: #777777;
	}
</style>