<template>
	<div>
		<b-modal 
			id="modal-header"
			footer={null}
			v-model="show"
			:title="mediaName"
			size="xl"
			centered
			class="m-3">
			<template #modal-header>
				<b-col md="12">
					<b-row class="d-flex justify-content-end">
						<span class="icon-clickable" style="position: absolute; right: 50px" @click="download">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M20.5716 13.7142V18.2857C20.5716 18.8919 20.3709 19.4733 20.0137 19.9019C19.6565 20.3306 19.172 20.5714 18.6668 20.5714H5.33347C4.8283 20.5714 4.34381 20.3306 3.9866 19.9019C3.62939 19.4733 3.42871 18.8919 3.42871 18.2857V13.7142" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M6.85742 10.2858L12.0003 13.7143L17.1431 10.2858" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M12 13.7143V3.42859" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
						</span>
						<button type="button" class="close" aria-label="Close" @click="show=false">
							<span>&times;</span>
						</button>     
					</b-row>
				</b-col>

			</template>           
			<b-col>
				<b-row class="justify-content-center">
					<!-- imagem -->
					<b-img  v-if="mediaType == 'image'" :src="mediaPath"  alt="imagem" class="image-view">
					</b-img>   

					<!-- video -->

					<media-load v-if="mediaType == 'video'"
						:mediaId="mediaId"
						:mediaPath="mediaPath"
						:mediaType="mediaType"
						:mimeType="mimeType">
						<video id="media" ref="media" controls autoplay src="" class="media-view">
						</video>  		
					</media-load>
				</b-row>
			</b-col>
			<template #modal-footer>
				<b-col class="m-3"></b-col>
			</template>
		</b-modal>                        
	</div>                                          
</template>

<script>
import WMediaLoad from './media/WMediaLoad'
import WMimeType from './js/WMimeTypes'
export default {
	name: 'WChatMediaView',
	props: [
		'mediaId',
		'mediaName', 
		'mediaPath', 
		'mediaType',
		'mimeType',
		'status'
	],
	components: {
		'media-load': WMediaLoad
	},
	data () {
		return { 
			show: true,
			videoUrl: ''
		}
	},    
	methods: {  	
		download () {
			let url = this.mediaPath
			let mediaName = this.mediaName			
			if (this.mediaName == '' || this.mediaName == undefined || this.status == 'received') {				
				mediaName = this.mediaId + '.'+ WMimeType.getExtensionFromMimeType(this.mimeType)
			}
			this.downloadFetch(url, mediaName)
		},      
		downloadFetch (url, filename) {
			fetch(url)
				.then(response => response.blob())
				.then(blob => {
					const link = document.createElement('a')
					link.href = URL.createObjectURL(blob)
					link.download = filename
					link.click()
				})
				.catch(console.error)
		}		
	},
	mounted () {
	}, 
}
</script>

<style scoped>
    .preview-bottom-bar{
        bottom:0;
        left: 0;
        width: 100%;
        padding: 30px;
        position: absolute;
        background: #ECE5DD;
    }  
    .image-view {
        width: auto;
		max-height: calc(100vh - 240px);
		max-width: 100vw;		
    }	
    .media-view {
        width: 100%;
		max-height: calc(100vh - 240px);
		max-width: 100vw;		
    }
    .bg-danger {
        background: var(--primary) !important;
    }
</style>

 
