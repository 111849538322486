import axios from 'axios'
import { baseApiUrl} from '../global'
import i18n from '../plugins/i18n'

export default {    
	postUploadMedia (companyId, imageId, file, callback) {  
		let config = { headers: { 'Content-Type': 'multipart/form-data'}}          
		let url = `${baseApiUrl}` + 'avatar/uploadImage/' + companyId + '/' + imageId 
		axios.post(url, file, config)
			.then(response => {
				if (response){                                
					callback(true)        
				}
			})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)                
			})    
	},    
}