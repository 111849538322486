
<template>
	<div class="success-content">
		<div class="success-modal">
			<img src="@/assets/logo.png" width="200px" height="150px" alt="Logo" />
			<div class="mt-5 mb-5">
				<h6 class="blueColor">{{$t("SignUpSuccess.title")}}</h6> 
			</div>
			<div class="forgot-title mb-5">
				{{$t("SignUpSuccess.msgEmail")}}
			</div>
			<!-- <label>{{$t("SignUpSuccess.labelSendAgain")}}</label> -->
			<button class="mt-2 mb-2" @click="resend()">{{$t("SignUpSuccess.sendAgain")}}</button>     
             
			<router-link :to="{ path: '/login' }">
				<button class="mt-2 mb-2">{{$t("SignUpSuccess.goToLogin")}}</button>     
			</router-link>                       
		</div>
	</div>
</template>

<script>
import { baseApiUrl, config } from '@/global'
import axios from 'axios'  
export default {
	name: 'SignUpSuccess',
	data () {
		return {            
			signupId: null
		}
	},    
	methods: {
		resend () {
			axios.post(`${baseApiUrl}signup/`+ this.$route.params.signupId + '/resendConfirmation', config)
				.then(response => {
					let data = response.data  
					data
					alert(this.$t('Message.success'))
				})
				.catch(function (error) {  
					alert(this.$t('Message.error') + error)
				})            
		}
	}
}
</script>


<style>
    .success-content {
        margin-top: 100px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .success-modal {
        background-color: #FFF;
        width: 350px;
        padding: 35px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .forgot-title {
        font-size: 1.2rem;
        font-weight: 200;
        text-align: center;
    }

    .success-modal input {
        border: 1px solid #BBB;
        width: 100%;
        margin-bottom: 15px;
        padding: 12px 17px;
        margin: 2px 0 11px;
        padding-left: 10px;
        height: 40px;
        outline: none;  
        border-radius: 10px;    
    }

    .success-modal button {
        background-color: var(--primary);
        color: #FFF;
        padding: 5px 15px;
        width: 200px;
        justify-content: center;
        align-items: center;
        font-family: 'Montserrat','HelveticaNeue','Helvetica Neue','sans-serif';
        font-size:16px;
        color:white;    
        font-weight: 500;  
        cursor: pointer;
        padding: 8px;
        border-radius: 10px;          
    }

    .success-modal a {
        margin-top: 5px;
        font-size: 16px;
        font-weight: 500;
    }

    .email {
        color: gray;
        font-weight: 500; 
    }

    hr.forgot {
        border: 1px solid gray;
        width: 260px;
    }    
    .blueColor {
        color: var(--primary);
    }
</style>
