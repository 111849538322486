<template>
	<div style="display:flex">
		<side-bar-menu :key="reload" :loadedItem="item" :menuItems="menuItems" @openMenuItem="openMenuItem" />
		<b-col md="10" class="ml-3 mt-4 screen-template-custom" v-if="step==1">
			<developer-key-api-list 
				v-on:sendMessageData="getMessageData"
			/>
				
		</b-col>                                     
		<b-col md="10" class="ml-3 mt-4 screen-template-custom" v-if="step==1.1 || step==1.2">
			<developer-key-api
				:step="step" v-on:sendMessageData="getMessageData"
			/>
		</b-col>     
		<b-col md="10" class="ml-3 mt-4 screen-template-custom" v-if="step==2">
			<developer-webhook-list 
				v-on:sendMessageData="getMessageData"
			/>
				
		</b-col> 
		<b-col md="10" class="ml-3 mt-4 screen-template-custom" v-if="step==2.1 || step==2.2">
			<developer-webhook-action
				:step="step" v-on:sendMessageData="getMessageData"
			/>
		</b-col>             
		<b-col md="10" class="ml-3 mt-4 screen-template-custom" v-if="step==2.3 || step==2.4">
			<developer-webhook
				:step="step" v-on:sendMessageData="getMessageData"
			/>
		</b-col>
		<b-col md="10" class="ml-3 mt-4 screen-template-custom" v-if="step==2.5 || step==2.6">
			<developer-webhookLog
				v-on:sendMessageData="getMessageData"
			/>
		</b-col>      
		<b-col md="10" class="ml-3 mt-4 screen-template-custom" v-if="step==3">
			<developer-dictionary-id-list 
				v-on:sendMessageData="getMessageData"
			/>
		</b-col>
		<b-col md="10" class="ml-3 mt-4 screen-template-custom" v-if="step==4">
			<developer-widgets 
				v-on:sendMessageData="getMessageData"
			/>
		</b-col>
		<b-col md="10" class="ml-3 mt-4 screen-template-custom" v-if="step==5">
			<developer-apiLog
				v-on:sendMessageData="getMessageData"
			/>
		</b-col> 	
		<b-col md="10" class="ml-3 mt-4 screen-template-custom" v-if="step==6">
			<developer-number-proxy
				v-on:sendMessageData="getMessageData"
			/>
		</b-col> 
		<b-col md="10" class="ml-3 mt-4 screen-template-custom" v-if="step==6.1 || step==6.2">
			<developer-proxy
				v-on:sendMessageData="getMessageData"
			/>
		</b-col>
		<b-col md="10" class="ml-3 mt-4 screen-template-custom" v-if="step==7">
			<developer-imports
				v-on:sendMessageData="getMessageData"
			/>
		</b-col> 			                                                                                                                                                                                  
	</div>   
</template>

<script>
import WKeyAPIList from './keys/WKeyAPIList'
import WDictionaryIDList from './dictionary/WDictionaryIDList'
import WKeyAPI from './keys/WKeyAPI'
import WWebhookList from './webhook/WWebhookList'
import WWebhookAction from './webhook/WWebhookAction'
import WWebhook from './webhook/WWebhook'
import WapiLog from './apiLog/WapiLog'
import WWidgets from './widgets/WWidgets'
import WWebhookLog from './webhookLog/WWebhookLog'
import WProxy from './proxy/WProxy'
import WNumberProxy from './proxy/WNumberProxy'
import WImports from './imports/WImports'
import SideBarMenu from '@/components/global/SideBarMenu'
export default {
	components: 
	{
		'developer-key-api-list': WKeyAPIList,
		'developer-dictionary-id-list': WDictionaryIDList,
		'developer-key-api': WKeyAPI,
		'developer-webhook-list': WWebhookList,  
		'developer-webhook-action': WWebhookAction,           
		'developer-webhook': WWebhook, 
		'developer-widgets': WWidgets,
		'developer-webhookLog': WWebhookLog,
		'developer-apiLog': WapiLog, 
		'developer-proxy':  WProxy, 
		'developer-number-proxy':  WNumberProxy,    
		'developer-imports':  WImports,
		'side-bar-menu':SideBarMenu,   
	},
	name: 'WDeveloperMain',  
	data: function () {
		return {
			step: 1,
			item: 0,
			reload:0,
			loading: false,
			developerName: '',
			phoneNumber: '',
			developerStartAt: '',
			whocalled: '',
			menuItems:[
				{
					name:'Chaves de API',
				},
				{
					name:'Webhook'
				},
				{
					name:'Dicionário de IDs',
				},
				{
					name:'Widget para Sites'
				},
				{
					name:'Logs da API'
				},
				{
					name:'Proxy'
				},
				{
					name:'Importações'
				}
			]
		}
	},
	methods: { 
		openMenuItem (obj) {
			switch(obj.index){
			case 0:
				this.step =1
				break
			case 1:
				this.step = 2	
				break
			case 2:
				this.step = 3
				break
			case 3:
				this.step = 4
				break
			case 4:
				this.step = 5	
				break
			case 5:
				this.step = 6	
				break
			case 6:
				this.step = 7	
				break

			}
			localStorage.removeItem('__wpp_developer_obj') 
			localStorage.removeItem('__wpp_developer_step') 
			let objStep = {currentStep: this.step, currentMenuItem: obj.index}
			localStorage.setItem('__wpp_developer_step', JSON.stringify(objStep)) 
		},
		openKeyApi () {
			this.item = 1             
			this.step =1            
			localStorage.removeItem('__wpp_developer_obj') 
			localStorage.removeItem('__wpp_developer_step') 
			let objStep = {currentStep: this.step, currentMenuItem: this.item}
			localStorage.setItem('__wpp_developer_step', JSON.stringify(objStep))             
		},
		openWebhook () {
			this.item = 2            
			this.step =2
			localStorage.removeItem('__wpp_developer_obj') 
			localStorage.removeItem('__wpp_developer_step') 
			let objStep = {currentStep: this.step, currentMenuItem: this.item}
			localStorage.setItem('__wpp_developer_step', JSON.stringify(objStep))             
		},       
		openDictionaryId () {
			this.item = 3            
			this.step =3
			localStorage.removeItem('__wpp_developer_obj') 
			localStorage.removeItem('__wpp_developer_step') 
			let objStep = {currentStep: this.step, currentMenuItem: this.item}
			localStorage.setItem('__wpp_developer_step', JSON.stringify(objStep))             
		},
		openWidgets () {
			this.item = 4            
			this.step = 4
			localStorage.removeItem('__wpp_developer_obj') 
			localStorage.removeItem('__wpp_developer_step') 
			let objStep = {currentStep: this.step, currentMenuItem: this.item}
			localStorage.setItem('__wpp_developer_step', JSON.stringify(objStep))             
		},
		openApiLog () {
			this.item = 5            
			this.step = 5
			localStorage.removeItem('__wpp_developer_obj') 
			localStorage.removeItem('__wpp_developer_step') 
			let objStep = {currentStep: this.step, currentMenuItem: this.item}
			localStorage.setItem('__wpp_developer_step', JSON.stringify(objStep))             
		}, 
		openProxy () {
			this.item = 6            
			this.step = 6
			localStorage.removeItem('__wpp_developer_obj') 
			localStorage.removeItem('__wpp_developer_step') 
			let objStep = {currentStep: this.step, currentMenuItem: this.item}
			localStorage.setItem('__wpp_developer_step', JSON.stringify(objStep))             
		},
		openImports () {
			this.item = 7          
			this.step = 7
			localStorage.removeItem('__wpp_developer_obj') 
			localStorage.removeItem('__wpp_developer_step') 
			let objStep = {currentStep: this.step, currentMenuItem: this.item}
			localStorage.setItem('__wpp_developer_step', JSON.stringify(objStep))             
		},                
		getMessageData (obj) {
			localStorage.removeItem('__wpp_developer_step')        
			let objStep = {currentStep: obj.step, currentMenuItem: this.item}
			localStorage.setItem('__wpp_developer_step', JSON.stringify(objStep))            
			this.step = obj.step
		},          
	},
	mounted () {
		let step = JSON.parse(localStorage.getItem('__wpp_developer_step'))   
		if (step != null && step != undefined){
			this.step = step.currentStep   
			this.item = step.currentMenuItem
			this.reload++
		}        
	}    
}
</script>


<style scoped>

.nav-link {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 20px !important;  
    color: #414141;
}

.active {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 20px !important;  
    color: var(--primary);
    background: #F5F5F5;    
    border-right: solid 3px var(--primary);    
}
</style>

