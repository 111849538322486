<template>
	<b-col sm="12">
		<div v-if="advancedSearch">
			<b-row>
				<b-col class="mt-3" sm="8">
					<search-department-filter
						v-if="advancedSearch"
						:departmentFilter="departmentFilter"
						:agentFilter="agentFilter"

						:sortBy="'text'"
						v-on:checkedItems="setSelectedItems($event)"/>
				</b-col>
				<b-col class="search mr-5"  sm="">
					<b-row class="mb-2 mr-5">
					
						<b-input-group>
							<b-form-input
								class="mt-1"
								v-if="searchBar"
								id="filter-input"
								v-model="filter"
								type="search"
								:placeholder="searchPlaceHolder"
								@keyup="search()"
								@update="clearFilter"
								style="width: 250px"

							></b-form-input>  
			
							<b-input-group-prepend is-text class="mt-1">
								<b-icon icon="search"></b-icon>
							</b-input-group-prepend>                    
						</b-input-group>
					</b-row>
				</b-col>
			</b-row>
			
			
			
			
		</div>  
		<b-col v-else sm="5" class="search mb-2">
			<b-input-group>
				<b-form-input
					class="mt-1"
					v-if="searchBar"
					id="filter-input"
					v-model="filter"
					type="search"
					:placeholder="searchPlaceHolder"
					@keyup="search()"
					@update="clearFilter"

				></b-form-input>  
			
				<b-input-group-prepend is-text class="mt-1">
					<b-icon icon="search"></b-icon>
				</b-input-group-prepend>                    
			</b-input-group>                                                                                       
		</b-col>  
		
		<b-table 
			@sort-changed="headSortColor($event)" 
			thead-class="custom-head" 
			:sort-by="sortBy" 
			:sort-asc="true" 
			v-model="item" 
			tbody-class="custom-body" 
			hover 
			id="my-table" 
			:items="currentList" 
			:fields="fields" 
			:per-page="selected" 
			:current-page="filterRow" 
		>
			<!-- STATUS -->
			<template #cell(statusDescription)="data">
				<table-cell-status v-if="item[data.index]" :item="item" :data="data"/>
			</template>
			<template #cell(status)="data">
				<div v-if="item[data.index]">
					<svg v-if="item[data.index].status" :class="`attendant-status-color-${item[data.index].status}`" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="4" cy="4" r="4" fill="`attendant-status-color-${item[data.index].status}`"/>
					</svg>
					<span v-if="data.value == 1" class="ml-2 custom-body">Ativo</span>
					<span v-if="data.value == 2" class="ml-2 custom-body">Expirado</span>
				</div>
			</template>
			<!-- STATUS -->
			<!-- DEPARTMENTS OF USER -->
			<template #cell(departmentDescriptionList)="data">
				<table-cell-pop-up
					v-if="item[data.index]"
					:item="item[data.index]"
					:data="data"
					:count="item[data.index].departmentCount"
					:spanTittle="'Departamentos'"
					:id="item[data.index].phonenumber"
					:attributeAll="item[data.index].departmentAll"
				/>
				<!-- <table-cell-department v-if="item[data.index]" :item="item" :data="data"/> -->
			</template>
			<!-- DEPARTMENTS OF USER -->
			<!-- ROLES OF USER -->
			<template #cell(rolesDescriptionList)="data">
				<!-- <table-cell-role v-if="item[data.index]" :item="item" :data="data"/> -->
				<table-cell-pop-up
					v-if="item[data.index]"
					:item="item[data.index]"
					:data="data"
					:count="item[data.index].rolesDescriptionList.length"
					:spanTittle="'Funções'"
					:id="item[data.index].userId"
					:attributeAll="item[data.index].companyAdmin"
				/>
			</template>
			<!-- ROLES OF USER -->
			<!-- MILI TO TIME -->
			<template #cell(usageLastTime)="data">
				<table-cell-date v-if="item[data.index]" :date="data.value"/>
			</template>
			<template #cell(createdTime)="data">
				<table-cell-date v-if="item[data.index]" :date="data.value"/>
			</template>		
			<template #cell(editedTime)="data">
				<table-cell-date v-if="item[data.index]" :date="data.value"/>
			</template>					
			<!-- MILI TO TIME -->
			<!-- DROPDOWN SETTINGS USER -->
			<template #cell(dropdownOptionsSettingsUser)="data">
				<table-cell-dropdown 
					v-if="item[data.index]"
					:dropdownOptions="dropdownOptionsSettingsUser"
					v-on="$listeners"
					:item="item[data.index]"
					:id ="item[data.index].userId"
					:name ="item[data.index].attendantName"
					:attributeAll ="item[data.index].departmentAll"
					:editDepartmentTittle="'Editar departamento'"
				/>
			</template>
			<!-- DROPDOWN SETTINGS USER-->
			<!-- DROPDOWN SETTINGS-->
			<!-- REQUIRES FORMATTED OBJECT CONTANING 
					[
						{content:{},icon:{icon name and emit message}}
					] -->
			<template #cell(dropdownOptionsArray)="data">
				<div v-if="data.field.formatter">
					<table-cell-dropdown 
						v-if="item[data.index]"
						:dropdownOptions="data.field.formatter"
						v-on="$listeners"
						:item="item[data.index]"
					/>
				</div>
			</template>
			<!-- DROPDOWN SETTINGS FACEBOOK -->
			<!-- DROPDOWN SETTINGS DEPARTMENT-->
			<template #cell(dropdownOptionsSettingsDepartment)="data">
				<table-cell-dropdown 
					v-if="item[data.index]"
					:dropdownOptions="dropdownOptionsSettingsDepartment"
					v-on="$listeners"
					:item="item[data.index]"
					:id ="item[data.index].departmentId"
					:name ="item[data.index].departmentName"
					:editDepartmentTittle="'Editar nome'"
				/>
			</template>
			<!-- DROPDOWN SETTINGS DEPARTMENT-->
			<!-- DROPDOWN SETTINGS PROPERTIES-->
			<template #cell(dropdownOptionsSettingsProperty)="data">
				<table-cell-dropdown 
					v-if="item[data.index]"
					:dropdownOptions="dropdownOptionsSettingsProperty"
					v-on="$listeners"
					:item="item[data.index]"
					:id ="item[data.index].propertyId"
					:name ="item[data.index].propertyName"
					:description ="item[data.index].propertyDescription"
					:editDepartmentTittle="'Editar nome'"
				/>
			</template>
			<!-- DROPDOWN SETTINGS PROPERTIES-->
			<!-- DROPDOWN SETTINGS LABELS-->
			<template #cell(dropdownOptionsSettingsLabel)="data">
				<table-cell-dropdown 
					v-if="item[data.index]"
					:dropdownOptions="dropdownOptionsSettingsLabel"
					v-on="$listeners"
					:item="item[data.index]"
					:id ="item[data.index].labelId"
					:name ="item[data.index].labelDescription"
					:description ="item[data.index].labelDescription"
				/>
			</template>
			<!-- DROPDOWN SETTINGS LABELS-->
			<!-- USER+AVATAR-->
			<template #cell(name)="data">
				<table-cell-label-link
					v-on="$listeners"
					v-if="getLabelOfField('name') == 'Contatos' && item[data.index]"
					:item="item[data.index]"
					:name="item[data.index].name"
					:id="item[data.index].userId"
					:content="item[data.index].name"
					:label="'Nome'"
				/>

				<table-cell-avatar
					v-on="$listeners"
					v-else-if="item[data.index]"
					:item="item[data.index]"
					:name="item[data.index].name"
					:id="item[data.index].userId"
				/>
			</template>
			<!-- USER+AVATAR -->
			<!-- DEPARTMENT NAME+AVATAR -->
			<template #cell(departmentName)="data">
				<table-cell-avatar
					v-if="item[data.index]"
					:item="item[data.index]"
					:name="item[data.index].departmentName"
					:id="item[data.index].departmentId"
					v-on="$listeners"

				/>
			</template>
			<!-- DEPARTMENT NAME+AVATAR -->
			<!-- LABEL NAME+AVATAR -->
			<template #cell(labelDescription)="data">
				<table-cell-avatar
					v-if="item[data.index]"
					:item="item[data.index]"
					:name="item[data.index].labelDescription"
				/>
			</template>
			<!-- LABEL NAME+AVATAR -->
			<!-- USERS OF DEPARTMENT-->
			<template #cell(userCount)="data">
				<table-cell-pop-up
					v-if="item[data.index]"
					:item="item[data.index]"
					:data="data"
					:count="item[data.index].userCount"
					:spanTittle="'Usuários'"
					:id="item[data.index].departmentId"
				/>
			</template>
			<!-- USERS OF DEPARTMENT -->
			<!-- TEMPLATES USED-->
			<template #cell(templatesLinked)="data">
				<table-cell-pop-up
					v-if="item[data.index]"
					:item="item[data.index]"
					:data="data"
					:tittle="'Templates'"
					:count="item[data.index].templatesLinked"
					:spanTittle="'Templates'"
					:id="item[data.index].propertyId"
				/>
			</template>
			<!--  TEMPLATES USED -->
			<!-- QUICKREPLY USED-->
			<template #cell(quickReplyLinked)="data">
				<table-cell-pop-up
					v-if="item[data.index]"
					:item="item[data.index]"
					:data="data"
					:count="item[data.index].templatesLinked"
					:spanTittle="'Resposta rápidas'"
					:id="item[data.index].propertyName"
				/>
			</template>
			<!-- QUICKREPLY USED -->
			<!-- PHONENUMBER LINK-->
			<template #cell(waId)="data">
				<table-cell-label-link
					v-on="$listeners"
					v-if="item[data.index]"
					:item="item[data.index]"
					:name="item[data.index].name"
					:id="item[data.index].userId"
					:content="item[data.index].waId"
					:label="'Número'"
				/>
			</template>
			<!-- PHONENUMBER LINK -->
			<!-- POPUP FACEBOOK -->
			<!-- FIELD FORMATTER MUST BE AN OBJECT CONTANING: 
			{count:{show the amount of items on the cell.MUST be and INT or Array}},
			spanTittle:'{tittle of the count}',
			id:'string of the id property IF you're api calling inside the component'} -->
			<template #cell(popUpList)="data">
				<table-cell-pop-up
					v-if="item[data.index]"
					:item="item[data.index]"
					:data="data"
					:count="Number.isInteger(data.item[data.field.formatter.count])?data.item[data.field.formatter.count]:data.item[data.field.formatter.count].length"
					:spanTittle="data.field.formatter.spanTittle"
					:id="item[data.index][data.field.formatter.id]"
				/>
			</template>
			<!-- POPUP FACEBOOK -->
			<!-- LABEL MESSAGES-->
			<template #cell(usageCount)="data">
				<div v-if="item[data.index]">
					<span v-if="item[data.index].usageCount ==0">
						Nenhuma mensagem marcada
					</span>                             
					<span v-if="item[data.index].usageCount ==1">
						{{item[data.index].usageCount}} Mensagem
					</span>    
					<span v-if="item[data.index].usageCount > 1">
						{{item[data.index].usageCount}} Mensagens
					</span>               
				</div>
				

			</template>
			<!-- LABEL MESSAGES -->
			<!-- CHECKBOXUNIQUE-->
			<template #cell(radio)="data">
				<div @click="radioClicked(item[data.index])">
					<b-form-radio @change="radioClicked(item[data.index])" :id="data.index.toString()" v-model="radioSelected" :name="data.index.toString()" :value="item[data.index]"></b-form-radio>
				</div>
			</template>
			<!-- CHECKBOXUNIQUE-->
			<!-- LABEL DEPARTMENT -->
			<template #cell(labelDepartment)="data">
				<!-- <table-cell-department :data="data" :item="item[data.index]"/> -->
				<table-cell-pop-up
					v-if="item[data.index]"
					:item="item[data.index]"
					:data="data"
					:count="item[data.index].departmentIdList.length"
					:spanTittle="'Departamentos'"
					:id="item[data.index].labelId"
					:attributeAll="item[data.index].departmentAll"
				/>

			</template>
			<!-- LABEL DEPARTMENT -->
			<!-- EMAIL-->
			<template #cell(email)="data">
				<div  v-if="item[data.index]">
					<span v-if="item[data.index].email">{{data.value}}</span>                        
					<span class="custom-body" v-else>
						Não cadastrado
					</span>                 
				</div>
				

			</template>
			<template #cell(apiStatus)="data">
				<span>{{data.value}}</span>
			</template>
			<template #cell(cronStatus)="data">
				<span>{{data.value}}</span>
			</template>
			<template #cell(databaseStatus)="data">
				<span>{{data.value}}</span>
			</template>
			<template #cell(sslStatus)="data">
				<span>{{data.value}}</span>
			</template>
			<!-- EMAIL -->
			<!-- RESPONSÁVEL PADRÃO -->
			<template #cell(agentDepartmentLeadCustom)="data">
				<!-- <table-cell-department :data="data" :item="item[data.index]"/> -->
				
				
				<div v-if="item[data.index]">
					<span v-if="item[data.index].agentDepartmentLeadCustom == false || !item[data.index].agentDepartmentLeadCustom">Padrão</span>
					<span v-else>
						<span :id="item[data.index].contactId" @click="openPopOver(data.index)"><u>Customizado</u></span>                                                              
						<b-popover                       
							:target="item[data.index].contactId" 
							placement="auto"
							triggers="click">
							<template #title>
								<b-button @click="onClose(item[data.index].contactId)" class="close ml-3" aria-label="Close">
									<span class="d-inline-block" >&times;</span>
								</b-button>
								Responsável Customizado
							</template>                                    
							<div :key="index_" v-for="(d, index_) in nameList">
								<span class="custom-font-open-12-400">{{d}}</span>
							</div>                                                                                  
						</b-popover>                                   
					</span>
				</div>
				

			</template>
			<!-- RESPONSÁVEL PADRÃO -->
		</b-table>
		<b-col v-if="!disablePagination">
			<b-row>
				<b-col sm="6">
					<b-row class="ml-2">
						<span class="mt-2 lblPagination">Visualizar por página:</span> 
						<b-col sm="4">                                                 
							<b-form-select style="width:60px" v-model="selected" :options="perPageOptions" size="sm" @change="setPerPage()"></b-form-select>                
						</b-col>
					</b-row>
				</b-col>
				
				
				<b-col class="mr-5 d-flex justify-content-end">
					<b-pagination
						v-if="rows() > perPage"
						v-model="currentPage"
						:total-rows="rows()"
						:per-page="perPage"
						aria-controls="my-table"
						pills
						size="sm"
					></b-pagination> 
				</b-col>
			</b-row>
			
		</b-col>
		

		<b-row class="justify-content-center">
			<span class="custom-head" v-if="currentList.length == 0">Nenhum Resultado Encontrado</span>
		</b-row>
	</b-col>
	
    
</template>

<script>
import TableCellStatus from '@/components/global/TableComponent/TableCells/TableCellStatus'
import TableCellDate from '@/components/global/TableComponent/TableCells/TableCellDate'
import TableCellDropdown from '@/components/global/TableComponent/TableCells/TableCellDropdown'
import TableCellAvatar from '@/components/global/TableComponent/TableCells/TableCellAvatar'
import TableCellPopUp from '@/components/global/TableComponent/TableCells/TableCellPopUp'
// import TableCellDepartment from '@/components/global/TableComponent/TableCells/TableCellDepartment'
import TableCellLabelLink from '@/components/global/TableComponent/TableCells/TableCellLabelLink'
import SearchCheckBoxFilterVue from '@/components/filters/SearchCheckBoxFilter.vue'
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import WContactsAPI from '@/api/WContactsAPI'
export default {	
	name: 'Table',
	computed: {
		
		lists () {
			const items = this.currentList
			return items.slice(
				(this.currentPage - 1) * this.perPage,
				this.currentPage * this.perPage
			)
		},
		filterRow () {
			if(this.currentList.length < this.perPage){
				return 1
			}else{
				return this.currentPage
			}
		},
		filteredAdvancedList () {
			return this.list.filter(el =>{
				let shouldFilter = false
				this.selectedId.forEach(element => {
					if(el.departmentIdList){
						if(el.departmentIdList.includes(element)){
							shouldFilter = true
							return
						}
					}
					if(el.agentDepartmentLeadCustomList){
						if(el.agentDepartmentLeadCustomList.includes(element)){
							shouldFilter = true
							return
						}
					}
				})
				return shouldFilter
			})
		},
		
	},  

	components: 
	{   
		TableCellStatus,
		TableCellDate,
		TableCellDropdown,
		TableCellAvatar,
		TableCellPopUp,
		TableCellLabelLink,
		// TableCellDepartment,
		'search-department-filter': SearchCheckBoxFilterVue,
	},
	props: [
		'list',
		'fields',
		'perPage',
		'searchBar',
		'sortBy',
		'dropdownOptionsSettingsUser',
		'dropdownOptionsSettingsDepartment',
		'dropdownOptionsSettingsProperty',
		'dropdownOptionsSettingsLabel',
		'dropdownOptionsArray',
		'searchPlaceHolder',
		'filterFields',
		'primaryKey',
		'advancedSearch',
		'departmentFilter',
		'agentFilter',
		'selected',
		'disablePagination'

	],
	data () {
		return {	
			show: false,
			currentPage: 1,
			filter:'',
			item :[],
			test: 0,
			currentList:this.list,
			perPageOptions: [5,10,20, 50, 100, 200, 500],
			departmentList:[],
			selectedId:[],
			updatePopUp:0,
			delayPopOver:{ show: 300},
			nameList:[],
			radioSelected:undefined

		}
	},
	methods: {  
		radioClicked (obj) {
			this.radioSelected = obj
			console.log('click')
			// console.log(this.radioSelected.flowName)
			this.$emit('radioClicked',obj)
		},
		async openPopOver (index) {
			this.$root.$emit('bv::hide::popover')
			this.nameList= await new Promise((resolve) =>{
				WContactsAPI.getContactPhonenumberLeadDescription(this.item[index].companyId, this.item[index].contactId, response => {
					resolve(Object.values(response.data))
				})
			})
		},
		onClose (id) {
			this.$root.$emit('bv::hide::popover', id)   
		},
		setPerPage () {
			this.perPage = this.selected
		},
		search () {
			let list = this.list
			if(this.selectedId.length > 0){
				list = this.filteredAdvancedList
			}
			this.currentList = []
			// empty filter
			if(this.filter == '' && this.selectedId.length == 0){
				this.currentList = this.list
				return
			}
			this.currentList = list.filter(el =>{
				let shouldFilter = false
				if(this.filterFields == undefined){
					shouldFilter = el[this.fields[0].key].toLowerCase().includes(this.filter.toLowerCase())
				}else{
					this.filterFields.forEach(element => {
						if(typeof el[element] != 'string'){
							undefined
						}else if(el[element].toLowerCase().includes(this.filter.toLowerCase())){
							shouldFilter = true
						}
					})
				}
				return shouldFilter
			})
			// empty filter
			// no filter fields
			// if(this.filterFields == undefined){
			// 	this.currentList = list.filter(el =>{
			// 		let shouldFilter = false
			// 		let aux =this.fields[0].key
			// 		if(this.selectedId.length > 0){
			// 			this.selectedId.forEach(element => {
			// 				if(el.departmentIdList.includes(element)){
			// 					if(el[aux].toLowerCase().includes(this.filter.toLowerCase())){
			// 						shouldFilter = true
			// 						return
			// 					}
			// 				}
			// 			})
			// 			return shouldFilter
			// 		}else{
			// 			return el[aux].toLowerCase().includes(this.filter.toLowerCase())
			// 		}
			// 	})
			// 	// no filter fields
			// }  else{
			// 	console.log('aqui')
			// 	// filter fields
			// 	this.currentList = list.filter( el => {
			// 		let shouldFilter = false
			// 		let shouldFilterAdvanced = false
			// 		if(this.selectedId.length > 0){
			// 			if(el.agentDepartmentLeadCustomList && shouldFilterAdvanced == false){
			// 				el.agentDepartmentLeadCustomList.forEach(element => {
			// 					if(this.selectedId.includes(element)){
			// 						shouldFilterAdvanced = true
			// 						return
			// 					}
			// 				})
			// 			}
			// 			this.filterFields.forEach(element => {
			// 				if(el[element]){
			// 					if(el[element].toLowerCase().includes(this.filter.toLowerCase())){
			// 						shouldFilter = true
			// 						if(shouldFilterAdvanced == true){
			// 							return 
			// 						}
			// 					}
			// 				}
			// 			})
			// 			console.log('estou na search bar:' +shouldFilter )
			// 			console.log('estou na selecionado?:' +shouldFilterAdvanced )
			// 			return (shouldFilter && shouldFilterAdvanced)
			// 		}else{
			// 			console.log('aqui2')
			// 			this.filterFields.forEach(element => {
			// 				if(!el[element] || typeof el[element] == 'object'){
			// 					return
			// 				}
			// 				if(el[element].toLowerCase().includes(this.filter.toLowerCase())){
			// 					shouldFilter = true
			// 					shouldFilterAdvanced = true
			// 					return
			// 				}
			// 			})
			// 			return (shouldFilter && shouldFilterAdvanced)
			// 		}
			// })
				
			// }
		},   

		headSortColor () {
			setTimeout(() => {
				document.querySelectorAll('[aria-sort]').forEach(element => {
					if(element.ariaSort == 'none'){
						element.classList.remove('sort-style')
						element.classList.remove('sort-style2')
					}else{
						element.classList.add('sort-style')
					}
				})		
			}, 100)
		},
		rows () {
			return this.currentList.length
		},
		addStatus (value, key, item) {
			value
			key
			
			if(item.status == 1){
				return 'attendant-status-color-1'
			}
			if(item.status == 2){
				return 'attendant-status-color-2'
			}
			if(item.status == 3){
				return 'attendant-status-color-3'
			}
		},
		getLabelOfField (key) {
			let aux = undefined
			this.fields.forEach(element => {
				if(element.key == key){
					aux = element.label
				}
			})
			return aux
		},
		getDepartmentsList () {
			WDepartmentsAPI.getDepartmentsOfCompany (
				this.$route.params.companyId, response => {
					let resp = response.data
					resp.forEach(item => {
						let dpt = {
							text: item.departmentName,
							value: item.departmentId
						}
						this.departmentList.push(dpt)
					})
				}
			)
		},
		setSelectedItems (list) {
			this.selectedId = list
			this.search()
		},
		clearFilter () {
			if(this.filter === ''){
				this.search()
			}
		}
		
	},
	mounted () {
		this.headSortColor()
		if(this.advancedSearch){
			this.getDepartmentsList()
		}
	}
}
</script>

<style>

.custom-head{
   font-family: Open Sans ;
    font-style: normal ;
    font-weight: 700 ;
    font-size: 12px ;
    color: #999999 ;    
}
.custom-body{
    margin: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #777777; 
    height: 50px;
    vertical-align: middle;

}
.attendant-status-color-1 {
    color: #128C7E;
    fill: #128C7E;
}
.attendant-status-color-2 {
    color: #DD4747;
    fill:#DD4747;
}

.attendant-status-color-3 {
    color: #999999;
    fill:#999999;
}  
.sort-style {
   color: black;
}

</style>