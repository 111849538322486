<template>
	<div class="phonenumber-scrollable scrollbar mt-4">
		<b-col md="12" class="pb-5 ml-1">
			<b-overlay po :show="loading" variant="light"
				no-wrap>
				<template #overlay>
					<b-spinner style="width: 3rem; height: 3rem; color: #21065c" label="Large Spinner"></b-spinner>
				</template>                    
			</b-overlay>    
			<b-col>
				<b-row>		
					<h1 class="lbl-title-custom mt-1">
						Painel Geral - Número
					</h1>	
				</b-row>				
				<!-- componente period0 -->	
				<b-row>
					<b-col md="2">
						<b-row class="mt-3">
							<span class="select-custom-font">
								Selecione departamento:
							</span>							
						</b-row>
						<b-row>
							<search-check-box-filter
								:departmentFilter="true"
								:sortBy="'text'"
								:toggleClass="'pannel-toggle-dropdown-style'"
								:placeHolder="'Departamentos'"
								v-on:checkedItems="setSelectedItems($event)"
							/>
						</b-row>
					</b-col>					
					<b-col md="auto" class="ml-4">
						<dashboard-period :key="componentKey" v-on:sendMessageData="getMessageData"/>
					</b-col>
					<b-col md="auto" v-show="showDataFilter == true">
						<b-row class="mt-3">
							<!-- <span class="select-custom-font ml-1">
								Período máximo 3 meses:
							</span> -->
							<span class="select-custom-font ml-1">
								Período máximo 2 meses:
							</span>								
						</b-row>
						<b-row>
							<b-dropdown
								:text="selectedPeriod"
								ref="calendar"
								variant="ligth"
								
							>
								<v-date-picker
									v-model="range"
									mode="date"
									is-range																					
									:columns="$screens({ default: 1, lg: 3 })"
									:is-expanded="$screens({ default: true, lg: false })"
									:min-date='minTime'
									:max-date='maxTime'
									@input="setHide"
									@dayclick="setMinMaxTime"
									@popoverDidShow="clearTime"
									locale = "PT-br"
									color="blue"									
								/>							
							</b-dropdown>
							<b-icon v-show="showIcon == true" class="m-2 icon-clickable" icon="x-circle" scale="1.5" variant="danger" v-b-tooltip.hover title="Clique para limpar" @click="clearTime"></b-icon>							
						</b-row>					
					</b-col>
				</b-row>							
				<b-row class="block-template-custom mt-3 mr-2">
					<b-col :md="(this.$mq == 'md' ? '9' : '10')" style="min-height:340px; max-height:340px;">
						<b-row class="ml-3">
							<span class="custom-font-open-14-gray mt-2">
								TOTAL DE MENSAGENS TROCADAS
							</span>
							<popover class="mt-1 ml-2" :id="'showPopHelpMessageTraffic'" :message="'Quantidade de mensagens, independente de sessões ou conversas, trocadas no período de tempo selecionado. <br><br> <b>Dica:</b> Para uma análise de seus gastos, vá na aba <b>Financeiro</b>.'" :btnText="'Entendi!'" style="margin-top: -3px;"/>						
						</b-row>
						<b-row class="ml-3">
							<span class="custom-font-open-12-gray-2 mt-2">
								Total de mensagens, recebidas e envidas, trocadas no seu sistema. É importante reforçar que esse gráfico pode mudar de um dia para o outro.							</span>
						</b-row>												
						<div v-if="hasMessageTraffic == true">
							<GChart
								:key="componentChartKey"
								:type="'LineChart'"
								:options="messageTrafficOptions"
								:collectionData="messageTraffic"
							/> 
						</div>
						<div v-else class="m-5">
							<no-data-found/>
						</div>		
					</b-col>   	
					<b-col :md="(this.$mq == 'md' ? '3' : '2')">
						<b-row class="justify-content-center mt-3" :style="this.$mq == 'md' ? 'margin-left: 15px;' : ''">
							<div>
								<export-button
									:departmentIdList="this.departmentIdList"
									:fileName="`numero_historico_mensagens_${timeToDateAmerican(this.startTime)}-${timeToDateAmerican(this.endTime)}`"
									:startTime="this.startTime" 
									:endTime="this.endTime" 
									:currentPhoneNumber="currentPhoneNumber" 
									:dashboardTitle="'Número'"
									:cardResource="'phoneNumber'" 
									:selectedCard="'Total De Mensagens Trocadas'"
									:reportType="1"/>
							</div>				
							<span class="custom-font-open-14-gray mt-2">
								TOTAL NO PERÍODO
							</span>									
						</b-row>
						<div class="mb-2" :style="this.$mq == 'md' ? 'min-height:240px; margin-left: 30px; border-left: 1px inset;' : 'min-height:240px; border-left: 1px inset;'">
							<b-row class="justify-content-center">
								<span class="custom-font-open-12-gray-2 mt-2">
									Mensagens Enviadas
								</span>							
							</b-row>
							<b-row class="justify-content-center">
								<span class="card1-custom-font-roboto-36">
									{{phonenumberData.messageTrafficSendCount}}
								</span>	
							</b-row>		
							<b-row class="justify-content-center">
								<span class="custom-font-open-12-gray-2 mt-2">
									Mensagens Entregues
								</span>							
							</b-row>
							<b-row class="justify-content-center">
								<span class="card2-custom-font-roboto-36">
									{{phonenumberData.messageTrafficDeliverCount}}
								</span>	
							</b-row>
							<b-row class="justify-content-center">
								<span class="custom-font-open-12-gray-2 mt-2">
									Mensagens Recebidas
								</span>							
							</b-row>
							<b-row class="justify-content-center">
								<span class="card3-custom-font-roboto-36">
									{{phonenumberData.messageTrafficReceive}}
								</span>	
							</b-row>	
							<b-row class="justify-content-center">
								<span class="custom-font-open-12-gray-2 mt-2">
									Mensagens Trocadas
								</span>							
							</b-row>
							<b-row class="justify-content-center">
								<span class="card3-custom-font-roboto-36">
									{{phonenumberData.messageTrafficSendCount + phonenumberData.messageTrafficReceive}}
								</span>	
							</b-row>	
						</div>																																													
					</b-col>	
				</b-row>

				<b-row class="block-template-custom mt-3 mr-2" style="min-height:400px; max-height:400px">
					<b-col>
						<b-row style="display:flex;">
							<b-col class="ml-4">
								<b-row class="ml-3 mt-2">
									<span class="custom-font-open-14-gray">
										TEMPLATES NA SUA CONTA
									</span>											
									<popover class="ml-2" :id="'showPopHelpPhonenumber'" :message="'Todos os templates enviados no período: <br> <br> <b>Transmissões:</b> Templates usados para o envio de listas de transmissão; <br> <b>Usuários:</b> Enviados pelos seus usuários na plataforma; <br> <b>Integrações:</b> Templates enviados por uma integração de WebHook ou API direta.'" :btnText="'Entendi!'" style="margin-top: -3px;"/>
								</b-row>
								<b-row class="ml-3" style="display:flex">
									<span class="custom-font-open-12-gray-2 mt-2">
										Total de templates usados, e como foram utilizados, dentro da sua conta em nossa plataforma.
									</span>
								</b-row>	
							</b-col>
							<div style="margin-left:auto;margin-right:65px;margin-top:10px">
								<export-button
									:departmentIdList="this.departmentIdList"
									:fileName="`numero_templates${timeToDateAmerican(this.startTime)}-${timeToDateAmerican(this.endTime)}`"
									:startTime="this.startTime" 
									:endTime="this.endTime" 
									:currentPhoneNumber="currentPhoneNumber" 
									:dashboardTitle="'Número'"
									:cardResource="'phoneNumber'" 
									:selectedCard="'Templates Na Sua Conta'"
									:reportType="2"/>
							</div>							
						</b-row>
						<div v-if="hasTemplateList == true" class="ml-3 mr-2">
							<b-table 
								id="phonenumberTable"
								responsive								
								class="mb-2"	
								style="min-height:290px; max-height:290px; overflow-x: hidden; overflow-y: hidden;"						
								:items="orderedPhonenumberList"
								:fields="phonenumberFields"
								:per-page="perPage"
								:current-page="currentPage"	
								:sort-by="sortBy"
								:sort-asc="true"	
								fixed																	
								@head-clicked="rowClickHandler">
								<template #cell(templateName)="data">																		
									<div class="contact-name-ellipsis" v-b-tooltip.hover :title="data.item.templateName">
										{{data.item.templateName}}
									</div>																														
								</template>		
								<template #cell(departmentCount)="data">																		
									<div v-if="data.item.departmentAll == true" >
										Todos departamentos
									</div>	
									<div v-else>
										{{data.item.departmentCount}} Departamentos
									</div>																																							
								</template>																																								
							</b-table>																								
							<b-row v-show="rows > perPage" class="d-flex justify-content-center mb-3">				
								<b-pagination
									v-model="currentPage"
									:total-rows="rows"
									:per-page="perPage"
									pills
									size="sm"
									aria-controls="phonenumberTable"
								></b-pagination> 			       
							</b-row> 	
						</div>
						<div v-else class="m-5">
							<no-data-found/>
						</div>														  
					</b-col>
				</b-row>		
			</b-col>	
		</b-col>
	</div>
</template>
<script>
import ConverterDateTime from '@/mixins/ConverterDateTime'
import WDashboardAPI from '@/api/WDashboardAPI'
import GChart from './WDashboardChart'
import WDashboardPeriod from './WDashboardPeriod'
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import SearchCheckBoxFilterVue from '@/components/filters/SearchCheckBoxFilter'
import NoDataFound from '@/components/global/NoDataFound'
import Popover from '@/components/global/Popover'
import Vue2Filters from 'vue2-filters'
import WDashboardExportButton from './WDashboardExportButton'
export default {
	name: 'WDashboardPhonenumber',
	components: {
		GChart,
		'dashboard-period': WDashboardPeriod,
		'search-check-box-filter': SearchCheckBoxFilterVue,
		'no-data-found': NoDataFound,
		popover: Popover,
		'export-button': WDashboardExportButton
	},	
	props:[
		'currentPhoneNumber'
	],
	
	mixins: [ConverterDateTime, Vue2Filters.mixin],	
	data () {
		return {            
			loading: false,
			showIcon: false,
			cardActive: 1,
			selectedDateTime: 'Últimos 7 dias',
			phonenumberData: [],
			startTime: 0,
			endTime: 0,
			showDataFilter: false,
			selectedPeriod: 'Selecionar período',	
			range: {
				start: '',
				end: '',
			},
			templateVOList: [],
			perPage: 6,
			currentPage: 1,  	
			labelTotalVOList: [],
			departmentList: [],			
			messageTraffic: [],
			messageTrafficOptions: {		
				focusTarget: 'category',
				crosshair: {orientation: 'vertical', trigger: 'focus'},
				tooltip: { isHtml: true,
					textStyle: {
						color: '#777777',    
						fontName: 'Open Sans',
						fontSize: 12,
						fontWeight: 700,					
					},								
				},		
				width: '100%',
				height: 260,		
				format: 'none',
				series: {
					1: { lineDashStyle: [4, 4] },
					2: { lineDashStyle: [4, 4] },
				},								
				colors: [this.primaryLineColor, this.secondaryLineColor, '#414141'],
				legend: 'none',
				chartArea:{left:45, right:10, bottom:50, top:20},
				hAxis: {textStyle: {
					color: '#777777',    
					fontName: 'Open Sans',
					fontSize: 12,
					fontWeight: 600,
					paddingTop: 40					
				},
				color: '#e3e3e3',
				baselineColor: '#f3f3f3',
				lineWidth: 2	
				},				
				vAxis: {textStyle: {
					color: '#777777',    
					fontName: 'Open Sans',
					fontSize: 12,
					fontWeight: 600,
					paddingTop: 40,	
				},					
				},				
			},			
			sortBy: 'templateName',
			phonenumberFields: [
				{
					key: 'templateName',
					label: 'Templates',
					sortable: true,					
					thStyle: '',
					thClass: 'b-th-data-center',
					tdClass: 'b-td-data-center'
				},
				{					
					key: 'broadcastCount',
					label: 'Transmissões',					
					sortable: true,					
					thStyle: '',
					thClass: 'b-th-data-center',
					tdClass: 'b-td-data-center'			
				},				
				{					
					key: 'userCount',
					label: 'Usuários',					
					sortable: true,					
					thStyle: '',
					thClass: 'b-th-data-center',
					tdClass: 'b-td-data-center'			
				},
				{
					key: 'apiCount',
					label: 'Integrações',
					sortable: true,
					thStyle: '',
					thClass: 'b-th-data-center',
					tdClass: 'b-td-data-center'
				},
				{					
					key: 'departmentCount',
					label: 'Departamentos',					
					sortable: true,
					thStyle: '',
					thClass: 'b-th-data-center',
					tdClass: 'b-td-data-center'
				}								
			],			
			minTime: '',
			maxTime: new Date(),	
			firstClick: true,
			componentKey: 0,
			componentChartKey: 0,
			departmentIdList: [],
			hasMessageTraffic: false,
			hasTemplateList: false,
		}
	},  
	computed: {		
		rows () {
			return this.templateVOList.length
		},		
		orderedPhonenumberList () {
			let items = this.templateVOList							
			return items						
		},	
		primaryLineColor () {
			let docStyle = getComputedStyle(document.documentElement)
			let primaryVar = docStyle.getPropertyValue('--primary')	
			return 	primaryVar			
		},	
		secondaryLineColor () {
			let docStyle = getComputedStyle(document.documentElement)
			let secundaryVar = docStyle.getPropertyValue('--secondary')	
			return secundaryVar	
		}									 	                  
	},  	 
	methods: {
		onResize () {
			this.renderChartData()
		},		
		rowClickHandler (record) {
			this.phonenumberFields.forEach(el => {
				if (el.key == record) {
					el.thStyle = 'color: black !important; font-size: 13px !important;'
				} else {
					el.thStyle = ''
				}
			})
		},			
		clearTime () {
			this.selectedPeriod = 'Selecionar período'
			this.minTime = ''
			this.maxTime = new Date()
			this.range = []
			this.showIcon = false
			this.firstClick = true
		},
		setMinMaxTime (day) {
			let today = new Date().setHours(23,59,59,999)
			this.firstClick = false
			this.showIcon = true
			this.minTime = day.range.start		
			let startTime = new Date(day.range.start).setHours(0,0,0,0)	
			this.minTime = startTime
			let endTime = startTime + 60*86400000	
			//let endTime = startTime + 90*86400000	
			if (endTime > today) {
				this.maxTime = today
			} else {
				this.maxTime = endTime
			}												
		},
		setHide () {	
			this.firstClick = true		
			let startTime = new Date(this.range.start).setHours(0,0,0,0)
			let endTime = new Date(this.range.end).setHours(23,59,59,999)
			this.selectedPeriod = this.timeToDate(startTime) + '-' + this.timeToDate(endTime)
			this.getPhonenumberData (startTime, endTime)	
			this.$refs.calendar.hide(true)	
			this.$nextTick(() => {
				this.minTime = ''
				this.maxTime = new Date()				
				// this.range = []					
			})
		},	
		setChartData (header, data) {
			let collectionData = []
			let headerData = []			
			headerData.push('')													
			Object.entries(header).forEach(([key, value]) => {
				key
				// console.log(key + ' ' + value)
				headerData.push(value)							
			})			
			collectionData.push(headerData)			
			for (const item in data) {
				let itemData = []
				let objData = data[item]					
				Object.entries(objData).forEach(([key, value]) => {
					if (key == 'indexXLabel') {			
						let year = value.substr(0,4)
						let month = value.substr(4,2)
						let day = value.substr(6,2)
						let dateString = day + '/' + month + '/' + year  						
						itemData.push(dateString)
					}	
					if (key.includes('column')) {
						itemData.push(value)						
					}							
				})			
				collectionData.push(itemData)
			}			
			return collectionData											
		},			
		renderChartData () {
			this.componentChartKey ++
			this.messageTraffic = null
			this.messageTraffic = this.setChartData(this.phonenumberData.messageTrafficTableHeader, this.phonenumberData.messageTrafficTableDataList)				
		},		
		getMessageData (obj) {
			this.range = {}		
			if (obj == null) {
				this.showDataFilter = true				
			} else {
				if (obj.period == 5 || obj.period == 6 ) {
					this.messageTrafficOptions.chartArea.left = 60
				}
				this.showDataFilter = false
				this.startTime = obj.startTime
				this.endTime = obj.endTime
				this.getPhonenumberData(this.startTime, this.endTime)
			}				
		},  	

		getDepartmentsList () {
			WDepartmentsAPI.getDepartmentsOfCompany (
				this.$route.params.companyId, response => {
					let resp = response.data
					resp.forEach(item => {
						let dpt = {
							text: item.departmentName,
							value: item.departmentId
						}
						this.departmentList.push(dpt)
					})
				}
			)
		},  
		setSelectedItems (list) {
			this.departmentIdList = list	
			this.getPhonenumberData(this.startTime, this.endTime)						
		},						
		getPhonenumberData (startTime, endTime) {
			if(this.range.start && this.range.end){
				// console.log(new Date(this.range.start).getTime())
				startTime = new Date(this.range.start).getTime()
				endTime = new Date(this.range.end).getTime()
				startTime = new Date(startTime).setHours(0,0,0,0)
				endTime = new Date(endTime).setHours(23,59,59,59)
			}
			console.log(this.timeToDate(startTime) + ' -- ' +this.timeToDate(endTime))	
			let phonenumberId = this.currentPhoneNumber
			this.loading = true
			let departmentIdList = JSON.stringify(this.departmentIdList)			
			WDashboardAPI.postPhoneNumberData(this.$route.params.companyId, phonenumberId, startTime, endTime, departmentIdList,  response => {				
				this.loading = false
				if (response) {
					this.phonenumberData = response.data 
					this.templateVOList = this.phonenumberData.templateVOList
					if (this.phonenumberData.messageTrafficTableDataList.length > 0) {
						this.hasMessageTraffic = true
					} else {
						this.hasMessageTraffic = false
					}						
					if (this.templateVOList.length > 0) {
						this.hasTemplateList = true
					} else {
						this.hasTemplateList = false
					}															
					this.renderChartData()
	
					this.rowClickHandler(this.sortBy)								
				}								
			})   			
		},		
	},
	mounted () {
		window.addEventListener('resize', this.onResize)
		let colors = [this.primaryLineColor, this.secondaryLineColor, '#414141']
		this.messageTrafficOptions.colors = colors	
		let currentData = new Date()
		currentData.setHours(0,0,0,0)
		this.endTime = currentData.getTime()
		this.startTime = this.endTime - (7*86400000)			
		this.getPhonenumberData(this.startTime, this.endTime)		
		this.getDepartmentsList()		
	}
}
</script>

<style lang="scss" scoped>
.phonenumber-scrollable {
    height: 90vh;
    width: 100%;
    overflow-y: 9;
    overflow-x: hidden;
}

.select-custom-font {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #21065C;
}

.table > tbody > tr:first-child > td {
    border: none;
}

td {
    margin: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #777777; 
    height: 42px !important;
    vertical-align: middle !important;

}
.td-data {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.contact-name-ellipsis { 
    white-space: nowrap !important;  
    overflow: hidden !important;
    text-overflow: ellipsis !important;
	width: 250px !important;
}

.block-template-custom-gray {
    min-height: 100px; 
	background: #f4f4f5;
}

.chart-responsive {
	width: 90%;
}

.card1-custom-font-roboto-36 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;  
	text-align: center;
    color: var(--primary);	
}
.card2-custom-font-roboto-36 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;  
	text-align: center;
    color: var(--secondary);
}
.card3-custom-font-roboto-36 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;  
	text-align: center;
    color: #414141;
}
</style>
