<template>
	<b-col md="10" class="ml-3">
		<b-row class="mt-3">  
			<h3 v-if="step == 4.3" class="lbl-title">Novo departamento - Usuários</h3>  
			<h3 v-else class="lbl-title">Editar departamento - Usuários</h3>                                              
		</b-row>
		<div class="w-100">
			<hr>
		</div>  
		<b-row>
			<span class="custom-font-open-14-gray-normal">
				Quais usuários fazem parte deste departamento?
			</span>                       
		</b-row>            
		<b-row class="mt-2">
			<div class="departments-attendants-scrollable scrollbar" >
				<div v-for="(x, index) in agents" :key="index" v-show="x.visible == true">         
					
					<label :for="x.userId">   
						<b-form-checkbox
							:id="JSON.stringify(index)"
							checked="1"
							v-model="x.checked"
							unchecked-value="0"                        
						>                    
							
							<!-- <b-avatar :src="avtUrl + companyId + '&imageId='+x.userId" size="1.4em"></b-avatar> -->
								
							<b-row class="ml-2">
								<b-avatar v-if="x.avatarCustom == true" :src="avtUrl + companyId + '&imageId='+x.userId" class="avatar"></b-avatar> 
								<b-avatar v-else class="avatar " >{{ x.name.substring(0,2).toUpperCase() }}</b-avatar>  
								<span class="mylabel ml-2">{{x.name}}</span> 
							</b-row>
								
						
								
						</b-form-checkbox>    
					</label>                 
				</div>    
			</div>                  
		</b-row>
		<b-row class="mt-5">
			<b-col>
				<div class="d-flex justify-content-center">
					<b-button variant="outline-primary" class="mr-2"
						@click="cancel()">Cancelar
					</b-button>                     
					<b-button v-if="step == 4.3" variant="primary" 
						@click="next()">Avançar
					</b-button>        
					<b-button v-else variant="primary" 
						@click="next()">Salvar
					</b-button>                                         
				</div>
			</b-col>
		</b-row>          
	</b-col>
</template>

<script>
import WAttendantsAPI from '@/api/WAttendantsAPI'
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import { avatarUrl} from '@/global'
export default {
	name: 'WDepartmentAttendant',
	props: [
		'step'
	],
	data () {
		return {
			companyId: this.$route.params.companyId,
			departments: [],
			departmentId: '',
			agentId: '',
			agents: [],
			btnDisable: true,
			avtUrl: avatarUrl,
			targetId: ''
		}
	},    
	methods: {
		setDepartmentId (id) {
			this.targetId = id
			this.btnDisable = false
		},        
		setAgentId (id) {
			this.agentId = id
			this.btnDisable = false
		},
		getAgentsList () {
			WAttendantsAPI.getAgentsList(this.$route.params.companyId, response => {
				let agents = response.data
				if (agents){       
					if (this.step == 4.4){
						WDepartmentsAPI.getUsersOfDepartment(this.$route.params.companyId, this.departmentId, response => {
							let departmentList = response.data
							agents.forEach(age => {
								age.checked = false
								for (const key in departmentList){                      
									if (age.userId == key){                                    
										age.checked = true
									}                             
								}
								age.visible =  true
							})
							this.agents = agents.sort(this.dynamicSort('name')) 
						})                        
					} else {
						agents.forEach(agent => {
							agent.visible = true
							agent.checked = 0
						})             
						this.agents = agents.sort(this.dynamicSort('name'))            
					}
				}
			})    
		}, 
		dynamicSort (property) {
			let sortOrder = 1
			if(property[0] === '-'){
				sortOrder = -1
				property = property.substr(1)
			}
			return function (a,b) {
				let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
				return result * sortOrder
			}
		},           
		next () {
			let attendantIdList = ''
			this.agents.forEach(el => {
				if (el.checked == 1){
					attendantIdList += el.userId + ','
				}                   
			}) 
			if (attendantIdList == ''){
				alert('Pelo menos um usuário deve ser selecionado!')
			}       
			WDepartmentsAPI.postDepartmentAgents(this.$route.params.companyId, this.departmentId, attendantIdList, response => {
				if (response){ 
					if (this.step == 4.4){
						let obj = {step: 4}
						this.$emit('sendMessageData', obj)
					} else {
						let obj = {step: 4.5}
						this.$emit('sendMessageData', obj)                        
					}                                                          
				} else {
					alert('Falha ao adicionar usuários ao departamento!')
				}
			})   
		},   
		cancel () {
			localStorage.removeItem('__wpp_settings_obj')
			let obj = {step: 4}
			this.$emit('sendMessageData', obj)             
		}         
	},
	mounted () {
		let objDepartment = JSON.parse(localStorage.getItem('__wpp_settings_obj'))
		if (objDepartment != null){
			this.departmentId = objDepartment.departmentId
		}                   
		this.getAgentsList()
	}, 
}
</script>

<style scoped>
.departments-attendants-scrollable {
    height: 50vh;
    width: 100%;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;        
}
</style>
