<template>
	<div>
		<span :id="id" style="cursor: pointer;">
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M8.00004 14.6666C11.6819 14.6666 14.6667 11.6818 14.6667 7.99992C14.6667 4.31802 11.6819 1.33325 8.00004 1.33325C4.31814 1.33325 1.33337 4.31802 1.33337 7.99992C1.33337 11.6818 4.31814 14.6666 8.00004 14.6666Z" fill="#C4C4C4"/>
				<path d="M6.05994 6.00014C6.21667 5.55458 6.52604 5.17887 6.93324 4.93956C7.34044 4.70024 7.8192 4.61276 8.28472 4.69261C8.75024 4.77246 9.17248 5.01449 9.47666 5.37582C9.78083 5.73716 9.94731 6.19448 9.9466 6.6668C9.9466 8.00014 7.9466 8.6668 7.9466 8.6668" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M8 11.3333H8.00667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>							
		</span>
		<b-popover :show.sync="show" :target="id" title="" placement="bottom">
			<div style="text-align: justify;" v-html="message">
			</div>							
			<div>
				<a href="javascript:;" @click="show=false" class="float-right" style="color: #D4206C;"><b>{{btnText}}</b></a>
			</div>                            
		</b-popover>  	
	</div>	
</template>
<script>
export default {
	name: 'Popover',
	props: [
		'id',
		'message',
		'btnText'
	],
	data () {
		return {	
			show: false
		}
	},
	methods: {  
	},
	mounted () {
	
	}

}
</script>