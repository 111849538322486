<template>
	<div style="margin-left: 10px;" class="attendant-list-scrollable scrollbar">
		<b-col class="mt-2 ml-2">
			<b-row class="ml-1">
				<b-col class="mt-2">
					<h3 class="lbl-title">Gestão de Usuários</h3>
				</b-col>
				<b-col>
					<b-col>
						<b-row class="justify-content-end mr-5">
							<b-button v-b-toggle.sidebar-right variant="secondary"
								@click="create()" >
								<span>
									<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M8 3V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M3 8H13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								</span>
								<span class="ml-1">
									Adicionar usuário
								</span>                        
							</b-button>                                                             
						</b-row>
					</b-col>                
				</b-col>
			</b-row>
		</b-col>
		<b-col md="12" sm="12" class="screen-template">        
			<loading v-if="this.$store.state.loading ==true"/>     
			<b-row  v-if="this.$store.state.loading ==false">
			</b-row>
			<b-row class="mt-1">    
				<div>
					<session-modal-reset
						:title = "'Encerrar sessões'"
						:subTitle = "'Você tem certeza que deseja sair de todas as sessões ativas?'"
						:description = "msgSession"
						:description1= "''"
						:value = "''"
						:placeholder = "''"
						:button1 = "'Cancelar'"
						:button2 = "'Confirmar'"
						:start="showModalSession"   
						v-on:sendModalData="removeSession"
						:key="componentSessionModalKey"                       
					/>
				</div>                                                                       
				<b-col v-if="this.$store.state.loading ==false">
					<div >
						<tableT v-if="attendantList" 
							:list="attendantList" 
							:fields="adaptTable" 
							:perPage="10" 
							:selected="10"
							:searchBar="true"
							:searchPlaceHolder = "'Busque pelo nome ou e-mail do usuário'"
							:sortBy ="'name'"
							:dropdownOptionsSettingsUser="dropdownOptionsSettingsUser"
							:filterFields="['name', 'phonenumber', 'email']"
							
							v-on:editAttendant="editAttendant"
							v-on:editDepartment="editDepartment"
							v-on:reactivateAttendant="reactivateAttendant"
							v-on:disableAttendant="disableAttendant"
							v-on:removeAttendant="removeAttendant"
							v-on:exitActiveSessions="exitActiveSessions"
						/>
					</div>
				</b-col>
			</b-row>
		</b-col>
	</div>    
</template>

<script>
import Loading from '@/views/Loading'
import WPhoneNumberAPI from '@/api/WPhoneNumberAPI'
import WAttendantsAPI from '@/api/WAttendantsAPI'
import Vue2Filters from 'vue2-filters'
import { avatarUrl} from '@/global'
import WModal from '@/views/WModal'
import WSortBy from '@/js/utils/WSortBy'
import Table from '@/components/global/TableComponent/Table'
export default {
	name: 'WAttendantList',
	mixins: [Vue2Filters.mixin],
	components: {
		tableT: Table,
		loading: Loading,    
		'session-modal-reset': WModal,           
	},    

	data: function () {
		return {
			loading: false,
			avtUrl: avatarUrl,  
			filter: '',
			attendantList: [],   
			originalAttendantList: [],                                 
			perPage: 20,
			selected: 20,
			currentPage: 1,  
			totalPage: 1,  
			paginatedItems: [], 
			perPageOptions: [20, 50, 100, 200, 500],  
			phoneNumberList: [],   
			showModalSession: false,  
			componentSessionModalKey: 0,                                                       
			userIndex: null,
			msgSession: '',
			fields: [
				{
					key: 'name',
					sortable: true,
					label:'Usuário'
					
				},
				{
					key: 'statusDescription',
					tdClass: 'addStatus',
					sortable: true,
					label:'Status'
				},
				{
					key: 'phonenumber',
					sortable: true,
					label:'Contato'
				},
				{
					key: 'email',
					sortable: true,
					label:'Email',
					thClass:'adaptTable'
				},
				{
					key: 'departmentDescriptionList',
					sortable: true,
					label:'Departamento'
				},
				
				
				{
					key: 'rolesDescriptionList',
					sortable: true,
					label:'Função'
				},
				{
					key: 'usageLastTime',
					sortable: true,
					label:'	Último login'
				},
				{
					key:'dropdownOptionsSettingsUser',
					label:'',
				},
				
			],
			dropdownOptionsSettingsUser: {
				editAttendant: true,
				editDepartment:true,
				reactivateAttendant:true,
				disableAttendant:true,
				removeAttendant:true,
				exitActiveSessions:true
			},
		}
	},
	computed: {
		adaptTable () {					
			if(this.$mq == 'xl'){
				let fields = [
					{
						key: 'name',
						sortable: true,
						label:'Usuário'
					
					},
					{
						key: 'statusDescription',
						tdClass: 'addStatus',
						sortable: true,
						label:'Status'
					},
					{
						key: 'phonenumber',
						sortable: true,
						label:'Contato'
					},
					{
						key: 'email',
						label:'Email',
						sortable: true,
					},
					{
						key: 'departmentDescriptionList',
						sortable: true,
						label:'Departamento'
					},
				
				
					{
						key: 'rolesDescriptionList',
						sortable: true,
						label:'Função'
					},
					{
						key: 'usageLastTime',
						sortable: true,
						label:'	Último login'
					},
					{
						key:'dropdownOptionsSettingsUser',
						label:''	
					},
				
				]
				return fields
			}else{
				let fields = [
					{
						key: 'name',
						sortable: true,
						label:'Usuário'
					
					},
					{
						key: 'statusDescription',
						tdClass: 'addStatus',
						sortable: true,
						label:'Status'
					},
					{
						key: 'departmentDescriptionList',
						sortable: true,
						label:'Departamento'
					},
				
				
					{
						key: 'rolesDescriptionList',
						sortable: true,
						label:'Função'
					},
					{
						key: 'usageLastTime',
						sortable: true,
						label:'	Último login'
					},
					{
						key:'dropdownOptionsSettingsUser',
						label:''	
					},
				
				]
				return fields
			}
		},     
		rows () {
			return this.attendantList.length
		},
		orderUserList () {
			const items = WSortBy.orderMyList('name',this.attendantList)
			return items.slice(
				(this.currentPage - 1) * this.perPage,
				this.currentPage * this.perPage
			)
		},	    	                  
	},    
	methods: {    
		onClose (id) {
			this.$root.$emit('bv::hide::popover', id)   
		},
		openAttendantList () {
			this.openArchived = false
			this.getAttendantList()
		},  
		create () {
			let obj = {step: 3.1}
			this.$emit('sendMessageData', obj)               
		},     
		getAvatarUrl (id) {
			let avatar = ''
			let objAvatar = JSON.parse(localStorage.getItem('__wpp_settings_user_photo_'+ id))
			if (objAvatar != null){
				if (objAvatar.userId == id){
					avatar = objAvatar.avatar
				} else {
					avatar = this.avtUrl + this.$route.params.companyId + '&imageId='+id 
				}
			} else {
				avatar = this.avtUrl + this.$route.params.companyId + '&imageId='+id 
			}                 
			return avatar
		},          
		getAttendantList () {
			this.$store.commit('setShowLoading', true)

			WAttendantsAPI.getAgentsList(this.$route.params.companyId, response => {
				let attendantList = response.data
				attendantList.forEach(element => {
					element.visible = true
					element.avatarUrl = this.getAvatarUrl(element.userId)
				})
				this.attendantList = attendantList                
				this.originalAttendantList = this.attendantList
				this.$store.commit('setShowLoading', false)
			})
		},  
               
		setPerPage () {
			this.perPage = this.selected
		},
		searchAttendants () {
			let attendantList = this.originalAttendantList
			this.attendantList = []            
			attendantList.forEach( el => {
				if ( el.name.toLowerCase().includes(this.filter.toLowerCase())
				|| el.email.toLowerCase().includes(this.filter.toLowerCase()) ){
					el.visible = true
					this.attendantList.push(el)
				} else {
					el.visible = false
				}
			})
		},     
		disableAttendant (userId) {  
			let objAgent = {
				userId: userId,
			}
			localStorage.removeItem('__wpp_settings_obj')
			localStorage.setItem('__wpp_settings_obj', JSON.stringify(objAgent))            
			let obj = {step: 3.5}
			this.$emit('sendMessageData', obj)   
		},

		reactivateAttendant (userId) {  
			WAttendantsAPI.postActive(this.$route.params.companyId, userId, response => {
				if (response){
					alert('Operação efetuada com sucesso!')
					this.getAttendantList() 
				}       
			})               
		},        

		removeAttendant (userId) {  
			let objAgent = {
				userId: userId,
			}
			localStorage.removeItem('__wpp_settings_obj')
			localStorage.setItem('__wpp_settings_obj', JSON.stringify(objAgent))            
			let obj = {step: 3.6}
			this.$emit('sendMessageData', obj)   
		},    
		exitActiveSessions (userId) {
			this.userId = userId
			this.msgSession = 'Ao continuar, o sistema irá enviar finalizar todas as sessões ativas em seus dispositivos'
			this.componentSessionModalKey ++
			this.showModalSession = true          
		},   
		removeSession (remove) {  
			this.showModalSession =false
			if (remove){
				WAttendantsAPI.deleteSessions(this.$route.params.companyId, this.userId, response => {
					if (response){
						this.getAttendantList()                      
					} else {
						alert('Falha ao excluir palavras chave!')
					}                                       
				})                
			}
		},                       
		timeToDate (time) {
			if (time ==0){
				return '-'
			}
			let t = new Date(Number(time))

			let fHourMinute = t.getHours() + ':'
			if(t.getMinutes()<10){
				fHourMinute+='0' 
			}
			fHourMinute+=t.getMinutes()  

			let d = new Date(Number(time))
			let day = d.getDate()        
			if(day < 10){
				day ='0' + d.getDate() 
			}
			let month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1)
			let sDate  = day  + '/' + month + '/' + d.getFullYear() 
			return sDate + ' ' + fHourMinute
		},    
		editAttendant (userId ) {
			let objAgent = {
				userId: userId,
			}
			localStorage.removeItem('__wpp_settings_obj')
			localStorage.setItem('__wpp_settings_obj', JSON.stringify(objAgent))            
			let obj = {step: 3.2}
			this.$emit('sendMessageData', obj)                
		},
		editDepartment (userId, all ) {
			let objAgent = {
				userId: userId,
				departmentAll: all
			}
			localStorage.removeItem('__wpp_settings_obj')
			localStorage.setItem('__wpp_settings_obj', JSON.stringify(objAgent))            
			let obj = {step: 3.4}
			this.$emit('sendMessageData', obj)                
		},        
		getPhonenumbers () {
			WPhoneNumberAPI.getPhonenumbers(this.$route.params.companyId, response => {
				this.phoneNumberList = response.data           
			})                
		},      
	},      
	mounted () {
		this.getPhonenumbers()
		this.getAttendantList()
	},
}
</script>

<style scoped>

u {
    cursor: pointer;
}

.attendant-status-color-1 {
    color: #128C7E;
    fill: #128C7E;
}
.attendant-status-color-2 {
    color: #DD4747;
    fill:#DD4747;
}

.attendant-status-color-3 {
    color: #999999;
    fill:#999999;
}  

th {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #999999 !important;    
}

td {
    margin: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #777777; 
    height: 50px;
    vertical-align: middle !important;
}
.td-attendant {
    margin-top: 20px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.td-phonenumber {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;  
}
.td-date {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-data {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-email {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}
.td-property {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}

.td-target {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-fail {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-deliver {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}
.td-status {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-readed {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.sidebar-right-attendants {
    display: flex !important;
    width: 100% !important;
    flex-direction: column !important;
    background: white !important;
    padding: 10px;
    margin-right: 50px;
}
.custom-pagination-input {
    width: 50px !important;
}
.custom-pagination-select {
    width: 60px !important;
}

.attendant-list-scrollable {
    height: 90vh;
    width: 100%;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
}
.lblPagination {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px; 
    color: #777777;   
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    min-height: 200px;
    -webkit-overflow-scrolling: touch;
}

.form-control:focus {
    background-color: #F5F5F5;
    box-shadow: none;
}

.description-ellipsis { 
    white-space: nowrap;  
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
}
</style>
