<template>
	<div style="margin-left: 30px; margin-right: 30px;" class="department-list-scrollable scrollbar">
		<b-col class="mt-2 ml-2">
			<b-row class="ml-1">
				<b-col class="mt-2">
					<h3 class="lbl-title">Gestão de Departamentos</h3>
				</b-col>
				<b-col>
					<b-col>
						<b-row class="justify-content-end mr-5">
							<b-button v-b-toggle.sidebar-right variant="secondary"
								@click="create()" >
								<span>
									<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M8 3V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M3 8H13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								</span>
								<span class="ml-1">
									Adicionar departamento
								</span>                        
							</b-button>                                                             
						</b-row>
					</b-col>                
				</b-col>
			</b-row>
		</b-col>
		<b-col md="12" sm="12" class="screen-template">
			<b-overlay :show="loading" variant="light"
				no-wrap>
				<template #overlay>
					<b-spinner style="width: 3rem; height: 3rem; color: var(--secondary)" label="Large Spinner"></b-spinner>
				</template>                    
			</b-overlay>      
			<b-row class="mt-1">   
				<div>
					<department-modal-delete
						:title = "'Excluir departamento'"
						:subTitle = "'Tem certeza que deseja excluir aaao departamento?'"
						:description = "departmentName"
						:description1= "''"
						:value = "''"
						:placeholder = "''"
						:button1 = "'Fechar'"
						:button2 = "'Excluir'"
						:start="showModalDelete"   
						v-on:sendModalData="deleteDepartment"
						:key="componentDeleteModalKey"                        
					/>
				</div>                                                  
				<b-col>
					<div>
						<tableT v-if="departmentList" 
							:list="departmentList" 
							:fields="fields" 
							:perPage="10" 
							:searchBar="true"
							:searchPlaceHolder = "'Busque pelo nome ou e-mail do usuário'"
							:sortBy ="'departmentName'"
							:selected="10"
							:dropdownOptionsSettingsDepartment="dropdownOptionsSettingsDepartment"
							v-on:editAttendant="editAttendant"
							v-on:editDepartment="editDepartment"
							v-on:editPermission="editPermission"
							v-on:setDepartmentDelete="setDepartmentDelete"
							v-on:exportResult="exportResult"
							@openNumberDetail="openNumberDetail"
						/>
					</div>
				</b-col>   
			</b-row>
		</b-col>
	</div>    
</template>

<script>
import WPhoneNumberAPI from '@/api/WPhoneNumberAPI'
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import Vue2Filters from 'vue2-filters'
import { avatarUrl, baseUrl} from '@/global'
import WModal from '@/views/WModal'
import WSortBy from '@/js/utils/WSortBy'
import Table from '@/components/global/TableComponent/Table'
export default {
	mixins: [Vue2Filters.mixin],
	components: {
		tableT:Table,
		'department-modal-delete': WModal,             
	},
	name: 'WDepartmentList',
	data: function () {
		return {
			loading: false,
			avtUrl: avatarUrl,  
			filter: '',
			departmentList: undefined,   
			originalDepartmentList: [],
			showModalDelete: false,                   
			perPage: 20,
			selected: 20,
			currentPage: 1,  
			totalPage: 1,  
			paginatedItems: [], 
			perPageOptions: [20, 50, 100, 200, 500],  
			phoneNumberList: [],   
			departmentName: '',  
			componentDeleteModalKey: 0,                                             
			popoverShow: false,
			userIndex: null,
			agentList: [],
			fields: [
				{
					key: 'departmentName',
					sortable: true,
					label:'Departamento'
					
				},
				{
					key: 'userCount',
					sortable: true,
					label:'	Usuário'
				},
				{
					key: 'phonenumberDescription',
					sortable: true,
					label:'	Número vinculado'
				},
				{
					key: 'inboxRuleDescription',
					sortable: true,
					label:'	Regra do atendimento'
				},
				{
					key:'dropdownOptionsSettingsDepartment',
					sortable: false,
					label:''	
				},
				
			],
			dropdownOptionsSettingsDepartment: {
				editAttendant: true,
				editDepartment:true,
				editPermission:true,
				setDepartmentDelete:true,
				exportData: true
			},
			companyDetail:{},
			phoneNumber:undefined
		}
	},
	computed: {
		rows () {
			return this.departmentList.length
		},
		orderDepartmentList () {
			const items = WSortBy.orderMyList('departmentName',this.departmentList)
			return items.slice(
				(this.currentPage - 1) * this.perPage,
				this.currentPage * this.perPage
			)
		},      
	},    
	methods: {    
		onClose (id) {
			this.$root.$emit('bv::hide::popover', id)   
		},
		openNumberDetail () {
			let objPhoneNumber = {
				phonenumberId: this.phoneNumber.phonenumberId,
				phoneNumber: this.phoneNumber.phonenumber,
				phonenumberName: this.phoneNumber.phonenumberName,
				backToDepartment: true
			}
			localStorage.removeItem('__wpp_settings_obj')
			localStorage.setItem('__wpp_settings_obj', JSON.stringify(objPhoneNumber))            
			let obj = {step: 2.1}
			this.$emit('sendMessageData', obj) 
		},
		openDepartmentList () {
			this.openArchived = false
			this.getDepartmentList()
		},  
		create () {
			let obj = {step: 4.1}
			this.$emit('sendMessageData', obj)               
		},    
		getAttendantList (departmentId) {
			this.loading = true
			WDepartmentsAPI.getUsersOfDepartment(this.$route.params.companyId, departmentId, response => {
				this.loading = false
				let agentList = response.data  
				this.agentList = this.sortByValue(agentList)
			})                             
		}, 
		sortByValue (jsObj) {
			let sortedArray = []
			for(let i in jsObj)
			{
				sortedArray.push(jsObj[i])
			}
			return sortedArray.sort()
		},    
		dynamicSort (property) {
			let sortOrder = 1
			if(property[0] === '-'){
				sortOrder = -1
				property = property.substr(1)
			}
			return function (a,b) {
				let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
				return result * sortOrder
			}
		},           
		getDepartmentList () {
			this.loading = true
			WDepartmentsAPI.getDepartmentsOfCompany(this.$route.params.companyId,async response => {
				let departmentList = response.data
				this.loading = false
				await this.getDetail()
				departmentList.forEach(element => {
					element.visible = true
				})
				this.departmentList = departmentList 
				this.originalDepartmentList = this.departmentList
			})
		}, 
		getDetail () {
			this.departmentList.forEach(element =>{
				element.default = element.departmentId == this.phoneNumber.defaultDepartmentId
			})
		},    
		setPerPage () {
			this.perPage = this.selected
		},
		searchDepartments () {
			let departmentList = this.originalDepartmentList
			this.departmentList = []            
			departmentList.forEach( el => {
				if ( el.departmentName.toLowerCase().includes(this.filter.toLowerCase()) ){
					el.visible = true
					this.departmentList.push(el)
				} else {
					el.visible = false
				}
			})
		},   
  
		setDepartmentDelete (departmentId, name) {  
			if(this.phoneNumber.defaultDepartmentId == departmentId ) {
				alert('Não é possível deletar um departamento padrão de um número!')
				return
			}
			this.departmentId = departmentId
			this.componentDeleteModalKey ++
			this.showModalDelete = true 
			this.departmentName = name
		},   
		exportResult (departmentId) {						
			let access_token = localStorage.getItem('__wpp_access_token')			
			window.open(baseUrl + '/departmentAssignReport/?companyId='
			+ this.$route.params.companyId + '&departmentId=' + departmentId
			+ '&accessToken='+ access_token + '&days=60')			
		},
		deleteDepartment (remove) {  
			this.showModalDelete =false
			if (remove){
				WDepartmentsAPI.deleteDepartment(this.$route.params.companyId, this.departmentId, response => {
					if (response){
						this.getDepartmentList()                     
					} else {
						alert('Falha ao excluir departamento!')
					}                                       
				})                
			}
		},   
               
		timeToDate (time) {
			if (time ==0){
				return '-'
			}
			let t = new Date(Number(time))

			let fHourMinute = t.getHours() + ':'
			if(t.getMinutes()<10){
				fHourMinute+='0' 
			}
			fHourMinute+=t.getMinutes()  

			let d = new Date(Number(time))
			let day = d.getDate()        
			if(day < 10){
				day ='0' + d.getDate() 
			}
			let month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1)
			let sDate  = day  + '/' + month + '/' + d.getFullYear() 
			return sDate + ' ' + fHourMinute
		},    
		editDepartment (departmentId, name ) {
			let objDepartment = {
				departmentId: departmentId,
				departmentName: name
			}
			localStorage.removeItem('__wpp_settings_obj')
			localStorage.setItem('__wpp_settings_obj', JSON.stringify(objDepartment))            
			let obj = {step: 4.2}
			this.$emit('sendMessageData', obj)                
		},
		editAttendant (departmentId, name ) {
			let objDepartment = {
				departmentId: departmentId,
				departmentName: name
			}
			localStorage.removeItem('__wpp_settings_obj')
			localStorage.setItem('__wpp_settings_obj', JSON.stringify(objDepartment))            
			let obj = {step: 4.4}
			this.$emit('sendMessageData', obj)                
		},      
		editPermission (departmentId, name ) {
			let objDepartment = {
				departmentId: departmentId,
				departmentName: name
			}
			localStorage.removeItem('__wpp_settings_obj')
			localStorage.setItem('__wpp_settings_obj', JSON.stringify(objDepartment))            
			let obj = {step: 4.6}
			this.$emit('sendMessageData', obj)                
		},           
		getPhonenumbers () {
			WPhoneNumberAPI.getPhonenumbers(this.$route.params.companyId, response => {
				this.phoneNumberList = response.data           
			})                
		},                   
	},      
	async mounted () {
		this.loading = true		
		await this.$store.dispatch('getPhoneNumberList')
		this.phoneNumber = this.$store.getters.getPhoneNumberList[0]
		await this.$store.dispatch('getDepartmentList')
		this.departmentList = this.$store.getters.getDepartmentList
		this.getDetail()
		this.loading = false
		// this.getPhonenumbers()
		// this.getDepartmentList()
	}
}
</script>

<style scoped>

u {
    cursor: pointer;
}
th {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #999999 !important;    
}

td {
    margin: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #777777; 
    height: 50px;
    vertical-align: middle !important;
}
.td-department {
    margin-top: 20px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.td-phonenumber {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;  
}
.td-date {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-data {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-email {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}
.td-property {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}

.td-target {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-fail {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-deliver {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.td-readed {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}



.sidebar-right-departments {
    display: flex !important;
    width: 100% !important;
    flex-direction: column !important;
    background: white !important;
    padding: 10px;
    margin-right: 50px;
}
.custom-pagination-input {
    width: 50px !important;
}
.custom-pagination-select {
    width: 60px !important;
}

.department-list-scrollable {
    height: 90vh;
    width: 98%;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
}
.lblPagination {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px; 
    color: #777777;   
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    min-height: 200px;
    -webkit-overflow-scrolling: touch;
}

.form-control:focus {
    background-color: #F5F5F5;
    box-shadow: none;
}
</style>
