<template>
	<div class="screen-template-custom" style="margin:20px">
		<b-col md="12" sm="12" class="ml-5" style="margin-top:40px">
			<h3 class="lbl-title pt-4">Senha redefinida com Sucesso!</h3>
			<span class="custom-font-open-14-gray ">
				Sua senha foi devidamente alterada!
			</span> 
			<hr>
			<b-row class="justify-content-center" >
				<label class="lbl-title">Você está sendo redirecionado<br> para a tela de Login, para testar a<br> nova senha!</label>
			</b-row>
			<b-row  class="justify-content-center" >
				<b-spinner style="width: 3rem; height: 3rem; color: var(--secondary)" label="Large Spinner"></b-spinner>   
			</b-row>
		</b-col>
	</div>
	
</template>

<script>

export default {
	name: 'user', 
	data: function () {
		return {
		}
	},
	methods: {
		wait () {
			setTimeout(() => {  this.$router.push({path: '/'}) }, 5000)	
		},
	},
	mounted () {
		this.wait()     
	},	
}
</script>
<style scoped>
</style>
