<template>
	<div style="display:flex">
		<!-- <b-row style="flex-wrap: nowrap;"> -->
		<side-bar-menu :multipleNumber="true" :key="reload" :loadedItem="item" :menuItems="menuItems" @openMenuItem="openMenuItem" @changeNumber="changeNumber" />
		<!-- <b-col md="auto" class="mt-2 aside-bar-custom"> 
				<aside>
					<div class="ml-4 mt-4">                    
						<b-nav vertical>	
							<b-nav-item :active="item == 1" @click="openDashboardRealTime()">Tempo Real</b-nav-item>						
							<b-nav-item :active="item == 2" @click="openDashboardAttendance()">Atendimentos</b-nav-item>   							
							<b-nav-item :active="item == 3" @click="openDashboardTeam()">Equipe</b-nav-item>								
							<b-nav-item :active="item == 4" @click="openDashboardPhoneNumber()">Número</b-nav-item>														                                                												
							<b-nav-item :active="item == 5" @click="openDashboardFinancial()">Financeiro</b-nav-item>
						</b-nav>
					</div>
				</aside>
			</b-col> -->
		<b-col md="10" class="mt-2">
			<loading v-if="!currentComponent || !currentPhoneNumber" />
			<component v-else-if="currentComponent && currentPhoneNumber" :is="currentComponent" :key="currentPhoneNumber" :currentPhoneNumber="currentPhoneNumber" 
				v-on:sendMessageData="getMessageData"
			/>
		</b-col>                                                                                                              
		<!-- </b-row>      -->
	</div>   
</template>
<script>
import WDashboardRealTime from './WDashboardRealTime'
import WDashboardFinancial from './WDashboardFinancial'
import WDashboardAttendance from './WDashboardAttendance'
import WDashboardTeam from './WDashboardTeam'
import WDashboardPhoneNumber from './WDashboardPhoneNumber'
import Loading from '@/views/Loading'
import SideBarMenu from '@/components/global/SideBarMenu'
export default {    
	components: 
	{   
		'dashboard-real-time': WDashboardRealTime,       
		'dashboard-financial': WDashboardFinancial, 
		'dashboard-attendance': WDashboardAttendance, 
		'dashboard-team': WDashboardTeam, 
		'dashboard-phonenumber': WDashboardPhoneNumber,
		'side-bar-menu':SideBarMenu,
		loading:Loading
	},
	name: 'WDashboardMain',  
	data: function () {
		return {
			step: 0,
			item: 0,
			loading: false,
			settingName: '',
			reload: 0,
			currentPhoneNumber: undefined,
			departmentName: '',
			templateName: '',
			settingStartAt: '',
			whocalled: '',
			currentComponent: undefined,
			menuItems:[
				{
					name:'Tempo Real',
					component:'dashboard-real-time'},
				{
					name:'Atendimentos',
					component:'dashboard-attendance'},
				{
					name:'Equipe',
					component:'dashboard-team'},
				{
					name:'Número',
					component:'dashboard-phonenumber'},
				{
					name:'Financeiro',
					component:'dashboard-financial'}
			]
		}
	},
	methods: { 
		changeNumber (obj) {
			this.currentPhoneNumber = obj.phonenumberId
		},
		openMenuItem (obj) {
			this.currentComponent = obj.component
			let objStep = {currentStep:  obj.index, currentMenuItem:  obj.index}
			localStorage.setItem('__wpp_dashboard_step', JSON.stringify(objStep))    
		},
		getMessageData (obj) {
			localStorage.removeItem('__wpp_dashboard_step')        
			let objStep = {currentStep: obj.step, currentMenuItem: obj.index}
			localStorage.setItem('__wpp_dashboard_step', JSON.stringify(objStep))            
			this.step = obj.step
		},       
	},
	mounted () {
		let step = JSON.parse(localStorage.getItem('__wpp_dashboard_step'))   
		if (step != null && step != undefined){
			if (step.currentMenuItem == 0) {
				this.currentComponent = 'dashboard-real-time'     
			}
			if (step.currentMenuItem == 1) {
				this.currentComponent = 'dashboard-attendance'     
			}	
			if (step.currentMenuItem == 2) {
				this.currentComponent = 'dashboard-team'     
			}			
			if (step.currentMenuItem == 3) {
				this.currentComponent = 'dashboard-phonenumber'     
			}				
			if (step.currentMenuItem == 4) {
				this.currentComponent = 'dashboard-financial'     
			}										
			this.step = step.currentStep   
			this.item = step.currentMenuItem
			this.reload++
		} else {
			this.currentComponent = 'dashboard-real-time'  
		}      
	},
	beforeDestroy () {
		this.item = 0            
		this.step = 0      
		localStorage.removeItem('__wpp_dashboard_obj') 
		localStorage.removeItem('__wpp_dashboard_step') 
		let objStep = {currentStep: this.step, currentMenuItem: this.item}
		localStorage.setItem('__wpp_dashboard_step', JSON.stringify(objStep))             
	}    	 
}
</script>


<style scoped>

.nav-link {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 20px !important;  
    color: #414141;
}

.active {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 20px !important;  
    color: var(--primary);
    background: #F5F5F5;    
    border-right: solid 3px var(--primary);    
}

.opacity {
	opacity: 0.6;
}

.nav-link.disabled {
    color: #999999;
    pointer-events: none;
    cursor: default;
}

.development {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 10px !important;
    color: var(--primary);
}
</style>

