<template>
	<div class="ml-4 mt-4 generic-scrollable scrollbar">
		<b-row id="header" class="mb-3">
			<b-col sm="auto">
				<span class="icon-clickable" @click="goBack">
					<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="40" height="40" rx="8" fill="#F5F5F5"/>
						<path d="M18.2622 18.7135L23.9969 13.0243C24.6018 12.4292 24.6097 11.4692 24.0146 10.8643C23.4433 10.2836 22.4594 10.2756 21.8547 10.8467L15.0549 17.6312C14.9825 17.6786 14.9339 17.7502 14.9095 17.798C14.8615 17.7977 14.8611 17.8457 14.8611 17.8457C14.8128 17.8933 14.788 17.9891 14.7394 18.0607C14.7392 18.0847 14.7152 18.0845 14.715 18.1085C14.6904 18.1803 14.69 18.2283 14.6654 18.3001C14.6652 18.3241 14.617 18.3477 14.6166 18.3957C14.6162 18.4437 14.6157 18.5157 14.6153 18.5637C14.6151 18.5877 14.5905 18.6595 14.5903 18.6835C14.5897 18.7555 14.6135 18.7797 14.6131 18.8277C14.6127 18.8757 14.6123 18.9237 14.6117 18.9956C14.6115 19.0196 14.6593 19.044 14.6587 19.116C14.6825 19.1402 14.682 19.2122 14.7056 19.2604C14.7054 19.2844 14.729 19.3326 14.7768 19.357C14.8002 19.4292 14.7998 19.4772 14.8472 19.5496C14.8472 19.5496 14.895 19.574 14.9188 19.5982C14.9422 19.6704 14.9658 19.7186 15.0376 19.7432L21.7254 26.6381C22.035 26.9287 22.4178 27.0758 22.8258 27.0792C23.2098 27.0823 23.571 26.9412 23.8853 26.6558C24.4902 26.0607 24.4979 25.1248 23.903 24.4959L18.2622 18.7135Z" fill="#414141"/>
					</svg>
				</span>
			</b-col>
			<div v-if="step == 1">
				<span class="custom-font-roboto-24-700-normal-black">Facebook Pixel</span>
				<br>
				<span class="custom-font-roboto-14-400-normal-black">Configure aqui todos os Pixels do Facebook que você pretende usar na sua conta da Becon.</span>
			</div>
			<div v-if="step == 2">
				<span class="custom-font-roboto-24-700-normal-black">Facebook Pixel</span>
				<br>
				<span class="custom-font-roboto-14-400-normal-black">Escolha e crie aqui os Eventos que poderão ser usados com este pixel.<b><span class="ml-4" style="font-size:20px">{{selectedActions.length}}/10</span></b></span>
			</div>
			<div v-if="step == 3"  style="width:70vw">
				<span class="custom-font-roboto-24-700-normal-black">Testar Eventos do Servidor</span>
				<br>
				<span class="custom-font-roboto-14-400-normal-black">Aqui vamos testar se o Pixel configurado está funcionando devidamente. Para tanto, acesso o link abaixo, copie o código fornecido pelo Facebook, copie e cole-o aqui, e é só testar. Trata-se de um código alfanumérico composto de 9 caracteres, por exemplo ”TEST43487".</span>
				
			</div>
		</b-row>
		<b-col sm="5" style="margin-left:-15px" v-if="step == 1">
			<div id="body">
				<div class="mb-3">
					<span class="custom-font-open-14-400-gray">Nome/Descrição do Pixel</span>
					<b-input v-model="pixelDescription"></b-input>
				</div>
				<div class="mb-3">
					<span class="custom-font-open-14-400-gray">ID do Pixel</span>
					<b-input v-model="pixelID"></b-input>
				</div>
				<div class="mb-5">
					<span class="custom-font-open-14-400-gray">Conta responsável</span>
					<br>
					<b-dropdown :text="(pixelAccount?pixelAccount.fbUsername:'Selecionar')">
						<b-dropdown-item v-for="x in faceBookAccountList" :key="x.facebookId" @click="pixelAccount = x,blurPage()">
							<span>{{x.fbUsername}}</span>
						</b-dropdown-item>
					</b-dropdown>
				</div>
				
			</div>
			<b-row style="margin-left:-15px">
				<b-col sm="auto">
					<b-button variant="outline-primary" @click="goBack">Cancelar</b-button>
				</b-col>
				<b-col sm=""></b-col>
				<b-col sm="auto">
					<b-button :disabled="(pixelDescription == '' || pixelID == '' || !pixelAccount)" variant="primary" @click="next(1)">Proximo passo</b-button>
				</b-col>
				
			</b-row>
			
		</b-col>
		<!--  -->
		<div v-if="step == 2">
			<div class="mb-5">
				
				<b-row class="align-items-center">
					<b-col class="mr-1" sm="2"><span class="custom-font-roboto-14-400-normal-black">Padrões do Facebook</span></b-col>
					<b-col class="box-shadow" sm="5">
						<b-form-checkbox-group
							id="checkbox-group-2"
							v-model="selectedActions"
						>
							<b-form-checkbox :id="x.name" @change="x.checked = !x.checked" :disabled="shouldDisableCheckBox && x.checked == false" style="width:150px" v-for="x in actionList" :key="x.name" class="mr-5" :value="x"><span class="custom-font-roboto-16-700-normal-black">{{x.name}}</span></b-form-checkbox>
						</b-form-checkbox-group>
					</b-col>
				</b-row>

				<b-row class="align-items-center">
					<b-col class="mr-1 mt-5" sm="2"><span class="custom-font-roboto-14-400-normal-black">Customizados</span></b-col>
					<b-col class="box-shadow" sm="5">
						<b-row>
							<b-input size="md" style="width:200px" id="customAction" :disabled="shouldDisableCheckBox" v-on:keyup.enter="addCustomAction" v-model="customAction" class="mt-5 mr-3 ml-3"></b-input>
							<b-button class="mt-5" style="height: calc(1.5em + 0.75rem + 2px);margin-top:3px" @click="addCustomAction(),blurPage()">Inserir</b-button>
						</b-row>
					
					</b-col>
				</b-row>
				<b-row style="margin-top:-7px" class="align-items-center">
					<b-col class="mr-1 mt-5" sm="2"><span class="custom-font-roboto-14-400-normal-black"></span></b-col>
					<b-col class="box-shadow" sm="5">
						<b-form-checkbox-group
							id="checkbox-group-2"
							v-model="selectedActions"
						>
							<b-form-checkbox :id="x.name" @change="x.checked = !x.checked" :disabled="shouldDisableCheckBox && x.checked == false" style="width:150px" v-for="x in customActionList" :key="x.name" class="mr-5" :value="x"><span class="custom-font-roboto-16-700-normal-black">{{x.name}}</span></b-form-checkbox>
						</b-form-checkbox-group>
					</b-col>
				</b-row>
			</div>
			<b-col style="margin-left:-15px" sm="7">
				<b-row>
					<b-col sm="auto">
						<b-button variant="outline-primary" @click="goBack(true)">Cancelar</b-button>
					</b-col>
					<b-col sm=""></b-col>
					<b-col sm="auto">
						<b-button :disabled="(selectedActions.length == 0)" variant="primary" @click="next(2)">{{this.editingPixel?'Salvar':'Proximo passo'}}</b-button>
					</b-col>
				</b-row>
			</b-col>
			
		</div>
		<!--  -->
		<div v-if="step == 3">
			<b-row class="mb-3">
				<b-col sm="5">
					<span class="custom-font-open-14-400-gray">Link do Gerenciador de Eventos:</span>
					<br>
					<a style="color:var(--primary)" href="https://www.facebook.com/events_manager2/list" target="_blank">https://www.facebook.com/events_manager2/list</a>
				</b-col>
				<b-col sm="5">
					<span class="custom-font-open-14-400-gray">Nome/Descrição do Pixel:</span>
					<br>
					<span class="custom-font-roboto-12-400">{{createdPixel.pixelName}}</span>
				</b-col>
			</b-row>
			<b-row class="mb-3">
				<b-col sm="5">
					<span class="custom-font-open-14-400-gray">Código do Teste:</span>
					<br>
					<b-input placeholder="exemplo: TEST43487" style="width:250px" v-model="testCode"></b-input>
				</b-col>
				<b-col sm="5">
					<span class="custom-font-open-14-400-gray">ID do Pixel:</span>
					<br>
					<span class="custom-font-roboto-12-400">{{createdPixel.pixelId}}</span>
				</b-col>
			</b-row>
			<div v-if="tested == false">
				<b-button variant="primary" @click="testPixel(),blurPage()">Teste seu novo Pixel</b-button>
			</div>
			<div v-else>
				<div class="mb-2">
					<span class="custom-font-roboto-14-400-normal-black"><b>Teste efetuado com sucesso!</b></span>
					<br>
					<span class="custom-font-roboto-14-400-normal-black">Verifique na sua plataforma do Facebook se o teste foi bem sucedido!</span>
				</div>
				
				<br>
				<b-button variant="outline-primary" class="mr-4" @click="testPixel">Testar novamente</b-button>
				<b-button variant="primary" @click="save">Finalizar configuração</b-button>
			</div>
		</div>
	</div>
</template>

<script>
import WFacebookAPI from '@/api/WFacebookAPI'
export default {
	props: [
		'faceBookAccountList',
		'pixelList',
		'editingPixel'
	],
	computed:{
		shouldDisableCheckBox () {
			if(this.selectedActions.length == 10){
				return true
			}else{
				return false
			}
		}
	},
	data () {
		return {	
			step: 1,
			pixelDescription:'',
			pixelID:'',
			pixelAccount:undefined,
			pixelAccountList:[],
			actionList:[{name:'AddPaymentInfo',checked:false},
				{name:'AddToCart',checked:false},
				{name:'AddToWishlist',checked:false},
				{name:'CompleteRegistration',checked:false},
				{name:'Contact',checked:false},
				{name:'CustomizeProduct',checked:false},
				{name:'Donate',checked:false},
				{name:'FindLocation',checked:false},
				{name:'InitiateCheckout',checked:false},
				{name:'Lead',checked:false},
				{name:'Purchase',checked:false},
				{name:'Schedule',checked:false},
				{name:'Search',checked:false},
				{name:'StartTrial',checked:false},
				{name:'SubmitApplication',checked:false},
				{name:'Subscribe',checked:false},
				{name:'ViewContent',checked:false}],
			customActionList:[],
			selectedActions:[],
			customAction:'',
			checked:[],
			createdPixel:undefined,
			testCode:'',
			tested: false,

		}
	},
	methods:{
		blurPage () {
			// console.log('blur')
			setTimeout(() => {
				document.activeElement.blur()
			}, 100)
		},
		goBack (quit) {
			if(this.editingPixel){
				this.$emit('save')
				return
			}
			if(quit == true){
				WFacebookAPI.removeApi(this.$route.params.companyId,this.createdPixel.pixelId,response=>{
					response
					this.$emit('save')
				})
			}
			// console.log('back')
			switch(this.step){
			case 1:
				this.$emit('save')
				break
			case 2:
				WFacebookAPI.removeApi(this.$route.params.companyId,this.createdPixel.pixelId,response=>{
					response
				})
				if(this.editingPixel){
					this.$emit('save')
				}else{
					this.step = 1
				}
					
				break
			case 3:
				this.step = 2
				break
			}
		},
		async check () {
			await new Promise(resolve =>{
				this.createdPixel.eventList.forEach(element => {
					if(document.getElementById(element)){
						document.getElementById(element).click()
					}else{
						this.customAction = element
						let obj = {name:this.customAction,checked:false}
						this.customActionList.push(obj)
						this.customAction = ''
					}
				})
				resolve()
			})
			
			if(this.customActionList.length > 0 ){
				this.customActionList.forEach(element => {
					// console.log(element.name)
					document.getElementById(element.name).click()
				})
			}
		},
		save () {
			this.$emit('save','kek')
		},
		addCustomAction () {
			if(this.actionList.some(element =>{return element.name == this.customAction}) || this.customActionList.some(element =>{return element.name == this.customAction})){
				alert('Evento já existe')
				return
			}
			let obj = {name:this.customAction,checked:false}
			this.customActionList.push(obj)
			setTimeout(() => {
				document.getElementById(this.customAction).click()
				this.customAction = ''
			}, 100)
		},
		next (step) {
			let aux =[]
			switch(step){
			case 1:
				if(!this.pixelAccount){
					alert('Selecione uma conta responsável')
					return
				}
				if(this.pixelDescription == ''){
					alert('Preencha a descrição do Pixel')
					return
				}
				if(this.pixelID == ''){
					alert('Preencha o ID do Pixel')
					return
				}
				if(this.pixelList.some(element =>{
					if(element.pixelConfigId == this.pixelID){
						alert('Este ID já está sendo usado por um Pixel cadastrado')
						return true
					}
					if(element.pixelName == this.pixelDescription){
						alert('Este NOME já está sendo usado por um Pixel cadastrado')
						return true
					}
				})){
					return
				}
				WFacebookAPI.postCreateApi(this.$route.params.companyId,this.pixelDescription,this.pixelID,this.pixelAccount.facebookId,response=>{
					// console.log(response.data)
					this.createdPixel = response.data
					this.step=2
				})
				break
			case 2:
				if(this.selectedActions.length == 0){
					alert('Selecione pelo menos um evento')
					return
				}
				if(this.selectedActions.length > 10){
					alert('Limite máximo de 10 eventos')
					return
				}
				this.selectedActions.forEach(element => {
					aux.push(element.name)
				})
				WFacebookAPI.postDepartmentAgents(this.$route.params.companyId,this.createdPixel.pixelId,JSON.stringify(aux),response=>{
					response
					// console.log(response.data)
					if(this.editingPixel){
						this.$emit('save')
					}else{
						this.step=3
					}
				})
				break
			case 3:
				break
			case 4:
				break
			}
			// console.log(this.selectedActions)
		},
		testPixel () {
			WFacebookAPI.postTest(this.$route.params.companyId,this.createdPixel.pixelId,this.testCode,response=>{
				response
				this.tested = true
			})
		},
	},
	mounted () {
		// console.log(this.editingPixel)
		if(this.editingPixel){
			this.createdPixel = this.editingPixel
			
			setTimeout(() => {
				this.check()
			}, 100)
			this.step = 2
		}
	},
	beforeDestroy () {
		if(this.step != 3 && this.createdPixel && !this.editingPixel){
			// console.log('destruir')
			WFacebookAPI.removeApi(this.$route.params.companyId,this.createdPixel.pixelId,response=>{
				response
				this.$emit('save')
			})
		}
	},
}
</script>

<style scoped>
.generic-scrollable {
    height: 86vh;
    width: 98%;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
}
.box-shadow{
	box-shadow: -1px 0px 0px 0px black;
}
</style>