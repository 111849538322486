<template>
	<b-col md="12" sm="12" class="ml-3">
		<b-row v-show="disable==true" class="ml-1">
			<span class="custom-bold-subtitle ">Endereço</span>
			<span class="ml-2 mt-1 icon-clickable" @click="edit">
				<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M7 12.333H13" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M10 1.33316C10.2652 1.06794 10.6249 0.918945 11 0.918945C11.1857 0.918945 11.3696 0.955525 11.5412 1.0266C11.7128 1.09767 11.8687 1.20184 12 1.33316C12.1313 1.46448 12.2355 1.62038 12.3066 1.79196C12.3776 1.96354 12.4142 2.14744 12.4142 2.33316C12.4142 2.51888 12.3776 2.70277 12.3066 2.87435C12.2355 3.04594 12.1313 3.20184 12 3.33316L3.66667 11.6665L1 12.3332L1.66667 9.66649L10 1.33316Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>              
			</span>
		</b-row>
		<b-row v-show="disable==false" class="ml-1">
			<span class="custom-bold-subtitle ">Editar endereço</span>
		</b-row>
		<Loading v-if="loading==true || this.$store.state.loading==true"/> 
		<b-row v-else class="ml-1">	
			<b-form md="10" sm="12" >
				<b-row>              
					<b-col md="5" sm="12">
						<b-form-group label="País" label-for="country">
							<b-form-input 
								list="list-country"
								v-model="adressObj.countryDescription" 
								debounce="500"  
								:disabled="disable"
								@change="getStates(adressObj.countryDescription)"
								autocomplete="off"></b-form-input>
							<datalist id="list-country">
								<option v-for="x in countries" :key="x.value">{{getFlags(x.icon)}} {{x.text}}</option>
							</datalist> 
						</b-form-group>
					</b-col>   
					<b-col md="5" sm="12">
						<b-form-group label="CEP" label-for="zipCode">
							<b-form-input id="zipCode" type="text"
								v-model="adressObj.zipCode" placeholder="00000-000" :disabled="disable"/>
						</b-form-group>                   
					</b-col>                               
				</b-row>  
				<b-row>   
					<b-col md="5" sm="12">
						<b-form-group label="Estado" label-for="state">
							<b-form-input list="list-state" v-model="adressObj.stateDescription" debounce="500" @change="getCities(adressObj.stateDescription)" :disabled="disable"></b-form-input>
							<datalist id="list-state">
								<option v-for="x in states" :key="x.value">{{x.text}}</option>
							</datalist>                         
						</b-form-group>
					</b-col>                            
					<b-col md="5" sm="12" v-if="cities.length > 0">
						<b-form-group label="Cidade" label-for="city">	
							<b-form-input list="list-city" v-model="adressObj.city" debounce="500" :disabled="disable"></b-form-input>
							<datalist id="list-city">
								<option v-for="x in cities" :key="x.value">{{x.text}}</option>
							</datalist>                       
						</b-form-group>
					
					</b-col> 
					<b-col md="5" v-else>
						<b-form-group label="Cidade" label-for="cityName">
							<b-form-input id="cityId" type="text"
								v-model="adressObj.city" :disabled="disable"/>
						</b-form-group>                
					</b-col>                                                          
				</b-row>             
				<b-row>              
					<b-col md="8" sm="12">
						<b-form-group label="Rua" label-for="street">
							<b-form-input id="street" type="text"
								v-model="adressObj.street" :disabled="disable"/>
						</b-form-group>
					</b-col>  
					<b-col md="2" sm="12">
						<b-form-group label="Número" label-for="number">
							<b-form-input id="number" type="number"
								v-model="adressObj.number" :disabled="disable"/>
						</b-form-group>
					</b-col>                                              
				</b-row> 
				<b-row>              
					<b-col md="10" sm="12">
						<b-form-group label="Complemento" label-for="complement">
							<b-form-input id="complement" type="text"
								v-model="adressObj.complement" :disabled="disable"/>
						</b-form-group>
					</b-col>                                               
				</b-row>                                               
				<b-row v-show="disable==false" class="mb-5">
					<b-col md="10" sm="12">
						<div class="d-flex justify-content-center">                    
							<b-button variant="outline-primary" class="mr-2"
								@click="cancel()">Cancelar
							</b-button>                     
							<b-button variant="primary" 
								@click="save()">Salvar endereço
							</b-button>                           
						</div>
					</b-col>
				</b-row>
			</b-form>
		</b-row>
		
	</b-col>
</template>

<script>
import WGeolocationAPI from '@/api/WGeolocationAPI'
import Loading from '@/views/Loading'
import WCompanyAPI from '@/api/WCompanyAPI'
export default {
	components: {Loading},    
	name: 'CompanyAddress',  
	props: ['companyId', 'addressId','testeProp','adressObj'],
	data: function () {
		return {
			loading: false,
			myCompanies: [],
			company: {},
			companyName: '', 
			selectFilter: '',
			countryId: this.adressObj.country,
			cityId: '',
			cityName: this.adressObj.city,
			stateId: this.adressObj.state,
			countries: [],
			states: [],
			cities: [],
			street: this.adressObj.street,
			zipCode: this.adressObj.zipCode,
			number: this.adressObj.number,
			complement: this.adressObj.complement,
			disable: true,
		}
	},
	methods: {
		edit () {
			this.disable = false
		},    
		cancel () {
			this.disable = true 
		},                       
		save () {         
			if (this.adressObj.countryDescription == '' || this.adressObj.countryDescription == undefined ){
				alert(this.$t('BillingPlanAddress.msgCountryRequired'))
				return
			}      
			if (this.adressObj.street == '' || this.adressObj.street == undefined ){
				alert(this.$t('BillingPlanAddress.msgStreetRequired'))
				return
			}      
			if (this.adressObj.state == '' || this.adressObj.state == undefined ){
				alert(this.$t('BillingPlanAddress.msgStateRequired'))
				return
			}      
			if (this.adressObj.city == '' || this.adressObj.city == undefined ){
				alert(this.$t('BillingPlanAddress.msgCityRequired'))
				return
			}  
			let countryId = this.adressObj.countryDescription
			let idCountry =''
			for (const key in this.countries){
				let countryObj = this.countries[key]
				if (countryId.indexOf(countryObj.text) > -1){
					idCountry = countryObj.value                    
				}               
			}    
			// states
			let stateId = this.adressObj.stateDescription
			let idState =''
			for (const key in this.states){
				let stateObj = this.states[key]
				if (stateId.indexOf(stateObj.text) > -1){
					idState = stateObj.value                    
				}               
			}                           
                                                                         
			WCompanyAPI.postAddress(this.$route.params.companyId,this.adressObj.zipCode,this.adressObj.neighborhood,this.adressObj.complement,this.adressObj.street, this.adressObj.city, idState, this.adressObj.number,idCountry,response => {
				response
				this.cancel()
			})   
			// alert('Configurações alteradas com sucesso!')
			// document.location.reload(true)
		},                     
		getStates (countryId) {   
			let id = ''
			
			for (const key in this.countries){
				let countryObj = this.countries[key]
				if (countryId.indexOf(countryObj.text) > -1){
					id = countryObj.value  
				} 
				if (id == countryObj.value){
					if (countryObj.text != 'Brazil'){
						this.customLabel = 'VAT'
					} else {
						this.customLabel = 'CNPJ/CPF'
					}                    
				}                
			}                              
			WGeolocationAPI.getStates (id, response => {
				let states = response.data 
				let output = []
				states.forEach(function (state) { 
					let obj = {value: state.stateId, text: state.stateName}
					output.push(obj)     
				})    
				this.states = output   
				if(this.adressObj.stateDescription){
					this.getCities(this.adressObj.stateDescription)
				}        
			})
		},  
		getCities (stateId) {   
			let id = ''
			for (const key in this.states){
				let statesObj = this.states[key]
				if (stateId.indexOf(statesObj.text) > -1){
					id = statesObj.value
				}              
			}        
			id           
			WGeolocationAPI.getCities (id, response => {
				let cities = response.data 
				let output = []
				cities.forEach(function (city) { 
					let obj = {value: city.cityId, text: city.cityName}
					output.push(obj)     
				}) 
				this.cities = output       
			})   
		},     
		getFlags (code) {
			if(code == 'AD') 
				return '🇦🇩'
			if(code == 'AE') 
				return '🇦🇪'
			if(code == 'AF') 
				return '🇦🇫'
			if(code == 'AG') 
				return '🇦🇬'
			if(code == 'AI') 
				return '🇦🇮'
			if(code == 'AL') 
				return '🇦🇱'
			if(code == 'AM') 
				return '🇦🇲'
			if(code == 'AO') 
				return '🇦🇴'
			if(code == 'AQ') 
				return '🇦🇶'
			if(code == 'AR') 
				return '🇦🇷'
			if(code == 'AS') 
				return '🇦🇸'
			if(code == 'AT') 
				return '🇦🇹'
			if(code == 'AU') 
				return '🇦🇺'
			if(code == 'AW') 
				return '🇦🇼'
			if(code == 'AX') 
				return '🇦🇽'
			if(code == 'AZ') 
				return '🇦🇿'
			if(code == 'BA') 
				return '🇧🇦'
			if(code == 'BB') 
				return '🇧🇧'
			if(code == 'BD') 
				return '🇧🇩'
			if(code == 'BE') 
				return '🇧🇪'
			if(code == 'BF') 
				return '🇧🇫'
			if(code == 'BG') 
				return '🇧🇬'
			if(code == 'BH') 
				return '🇧🇭'
			if(code == 'BI') 
				return '🇧🇮'
			if(code == 'BJ') 
				return '🇧🇯'
			if(code == 'BL') 
				return '🇧🇱'
			if(code == 'BM') 
				return '🇧🇲'
			if(code == 'BN') 
				return '🇧🇳'
			if(code == 'BO') 
				return '🇧🇴'
			if(code == 'BQ') 
				return '🇧🇶'
			if(code == 'BR') 
				return '🇧🇷'
			if(code == 'BS') 
				return '🇧🇸'
			if(code == 'BT') 
				return '🇧🇹'
			if(code == 'BV') 
				return '🇧🇻'
			if(code == 'BW') 
				return '🇧🇼'
			if(code == 'BY') 
				return '🇧🇾'
			if(code == 'BZ') 
				return '🇧🇿'
			if(code == 'CA') 
				return '🇨🇦'
			if(code == 'CC') 
				return '🇨🇨'
			if(code == 'CD') 
				return '🇨🇩'
			if(code == 'CF') 
				return '🇨🇫'
			if(code == 'CG') 
				return '🇨🇬'
			if(code == 'CH') 
				return '🇨🇭'
			if(code == 'CI') 
				return '🇨🇮'
			if(code == 'CK') 
				return '🇨🇰'
			if(code == 'CL') 
				return '🇨🇱'
			if(code == 'CM') 
				return '🇨🇲'
			if(code == 'CN') 
				return '🇨🇳'
			if(code == 'CO') 
				return '🇨🇴'
			if(code == 'CR') 
				return '🇨🇷'
			if(code == 'CU') 
				return '🇨🇺'
			if(code == 'CV') 
				return '🇨🇻'
			if(code == 'CW') 
				return '🇨🇼'
			if(code == 'CX') 
				return '🇨🇽'
			if(code == 'CY') 
				return '🇨🇾'
			if(code == 'CZ') 
				return '🇨🇿'
			if(code == 'DE') 
				return '🇩🇪'
			if(code == 'DJ') 
				return '🇩🇯'
			if(code == 'DK') 
				return '🇩🇰'
			if(code == 'DM') 
				return '🇩🇲'
			if(code == 'DO') 
				return '🇩🇴'
			if(code == 'DZ') 
				return '🇩🇿'
			if(code == 'EC') 
				return '🇪🇨'
			if(code == 'EE') 
				return '🇪🇪'
			if(code == 'EG') 
				return '🇪🇬'
			if(code == 'EH') 
				return '🇪🇭'
			if(code == 'ER') 
				return '🇪🇷'
			if(code == 'ES') 
				return '🇪🇸'
			if(code == 'ET') 
				return '🇪🇹'
			if(code == 'FI') 
				return '🇫🇮'
			if(code == 'FJ') 
				return '🇫🇯'
			if(code == 'FK') 
				return '🇫🇰'
			if(code == 'FM') 
				return '🇫🇲'
			if(code == 'FO') 
				return '🇫🇴'
			if(code == 'FR') 
				return '🇫🇷'
			if(code == 'GA') 
				return '🇬🇦'
			if(code == 'GB') 
				return '🇬🇧'
			if(code == 'GD') 
				return '🇬🇩'
			if(code == 'GE') 
				return '🇬🇪'
			if(code == 'GF') 
				return '🇬🇫'
			if(code == 'GG') 
				return '🇬🇬'
			if(code == 'GH') 
				return '🇬🇭'
			if(code == 'GI') 
				return '🇬🇮'
			if(code == 'GL') 
				return '🇬🇱'
			if(code == 'GM') 
				return '🇬🇲'
			if(code == 'GN') 
				return '🇬🇳'
			if(code == 'GP') 
				return '🇬🇵'
			if(code == 'GQ') 
				return '🇬🇶'
			if(code == 'GR') 
				return '🇬🇷'
			if(code == 'GS') 
				return '🇬🇸'
			if(code == 'GT') 
				return '🇬🇹'
			if(code == 'GU') 
				return '🇬🇺'
			if(code == 'GW') 
				return '🇬🇼'
			if(code == 'GY') 
				return '🇬🇾'
			if(code == 'HK') 
				return '🇭🇰'
			if(code == 'HM') 
				return '🇭🇲'
			if(code == 'HN') 
				return '🇭🇳'
			if(code == 'HR') 
				return '🇭🇷'
			if(code == 'HT') 
				return '🇭🇹'
			if(code == 'HU') 
				return '🇭🇺'
			if(code == 'ID') 
				return '🇮🇩'
			if(code == 'IE') 
				return '🇮🇪'
			if(code == 'IL') 
				return '🇮🇱'
			if(code == 'IM') 
				return '🇮🇲'
			if(code == 'IN') 
				return '🇮🇳'
			if(code == 'IO') 
				return '🇮🇴'
			if(code == 'IQ') 
				return '🇮🇶'
			if(code == 'IR') 
				return '🇮🇷'
			if(code == 'IS') 
				return '🇮🇸'
			if(code == 'IT') 
				return '🇮🇹'
			if(code == 'JE') 
				return '🇯🇪'
			if(code == 'JM') 
				return '🇯🇲'
			if(code == 'JO') 
				return '🇯🇴'
			if(code == 'JP') 
				return '🇯🇵'
			if(code == 'KE') 
				return '🇰🇪'
			if(code == 'KG') 
				return '🇰🇬'
			if(code == 'KH') 
				return '🇰🇭'
			if(code == 'KI') 
				return '🇰🇮'
			if(code == 'KM') 
				return '🇰🇲'
			if(code == 'KN') 
				return '🇰🇳'
			if(code == 'KP') 
				return '🇰🇵'
			if(code == 'KR') 
				return '🇰🇷'
			if(code == 'KW') 
				return '🇰🇼'
			if(code == 'KY') 
				return '🇰🇾'
			if(code == 'KZ') 
				return '🇰🇿'
			if(code == 'LA') 
				return '🇱🇦'
			if(code == 'LB') 
				return '🇱🇧'
			if(code == 'LC') 
				return '🇱🇨'
			if(code == 'LI') 
				return '🇱🇮'
			if(code == 'LK') 
				return '🇱🇰'
			if(code == 'LR') 
				return '🇱🇷'
			if(code == 'LS') 
				return '🇱🇸'
			if(code == 'LT') 
				return '🇱🇹'
			if(code == 'LU') 
				return '🇱🇺'
			if(code == 'LV') 
				return '🇱🇻'
			if(code == 'LY') 
				return '🇱🇾'
			if(code == 'MA') 
				return '🇲🇦'
			if(code == 'MC') 
				return '🇲🇨'
			if(code == 'MD') 
				return '🇲🇩'
			if(code == 'ME') 
				return '🇲🇪'
			if(code == 'MF') 
				return '🇲🇫'
			if(code == 'MG') 
				return '🇲🇬'
			if(code == 'MH') 
				return '🇲🇭'
			if(code == 'MK') 
				return '🇲🇰'
			if(code == 'ML') 
				return '🇲🇱'
			if(code == 'MM') 
				return '🇲🇲'
			if(code == 'MN') 
				return '🇲🇳'
			if(code == 'MO') 
				return '🇲🇴'
			if(code == 'MP') 
				return '🇲🇵'
			if(code == 'MQ') 
				return '🇲🇶'
			if(code == 'MR') 
				return '🇲🇷'
			if(code == 'MS') 
				return '🇲🇸'
			if(code == 'MT') 
				return '🇲🇹'
			if(code == 'MU') 
				return '🇲🇺'
			if(code == 'MV') 
				return '🇲🇻'
			if(code == 'MW') 
				return '🇲🇼'
			if(code == 'MX') 
				return '🇲🇽'
			if(code == 'MY') 
				return '🇲🇾'
			if(code == 'MZ') 
				return '🇲🇿'
			if(code == 'NA') 
				return '🇳🇦'
			if(code == 'NC') 
				return '🇳🇨'
			if(code == 'NE') 
				return '🇳🇪'
			if(code == 'NF') 
				return '🇳🇫'
			if(code == 'NG') 
				return '🇳🇬'
			if(code == 'NI') 
				return '🇳🇮'
			if(code == 'NL') 
				return '🇳🇱'
			if(code == 'NO') 
				return '🇳🇴'
			if(code == 'NP') 
				return '🇳🇵'
			if(code == 'NR') 
				return '🇳🇷'
			if(code == 'NU') 
				return '🇳🇺'
			if(code == 'NZ') 
				return '🇳🇿'
			if(code == 'OM') 
				return '🇴🇲'
			if(code == 'PA') 
				return '🇵🇦'
			if(code == 'PE') 
				return '🇵🇪'
			if(code == 'PF') 
				return '🇵🇫'
			if(code == 'PG') 
				return '🇵🇬'
			if(code == 'PH') 
				return '🇵🇭'
			if(code == 'PK') 
				return '🇵🇰'
			if(code == 'PL') 
				return '🇵🇱'
			if(code == 'PM') 
				return '🇵🇲'
			if(code == 'PN') 
				return '🇵🇳'
			if(code == 'PR') 
				return '🇵🇷'
			if(code == 'PS') 
				return '🇵🇸'
			if(code == 'PT') 
				return '🇵🇹'
			if(code == 'PW') 
				return '🇵🇼'
			if(code == 'PY') 
				return '🇵🇾'
			if(code == 'QA') 
				return '🇶🇦'
			if(code == 'RE') 
				return '🇷🇪'
			if(code == 'RO') 
				return '🇷🇴'
			if(code == 'RS') 
				return '🇷🇸'
			if(code == 'RU') 
				return '🇷🇺'
			if(code == 'RW') 
				return '🇷🇼'
			if(code == 'SA') 
				return '🇸🇦'
			if(code == 'SB') 
				return '🇸🇧'
			if(code == 'SC') 
				return '🇸🇨'
			if(code == 'SD') 
				return '🇸🇩'
			if(code == 'SE') 
				return '🇸🇪'
			if(code == 'SG') 
				return '🇸🇬'
			if(code == 'SH') 
				return '🇸🇭'
			if(code == 'SI') 
				return '🇸🇮'
			if(code == 'SJ') 
				return '🇸🇯'
			if(code == 'SK') 
				return '🇸🇰'
			if(code == 'SL') 
				return '🇸🇱'
			if(code == 'SM') 
				return '🇸🇲'
			if(code == 'SN') 
				return '🇸🇳'
			if(code == 'SO') 
				return '🇸🇴'
			if(code == 'SR') 
				return '🇸🇷'
			if(code == 'SS') 
				return '🇸🇸'
			if(code == 'ST') 
				return '🇸🇹'
			if(code == 'SV') 
				return '🇸🇻'
			if(code == 'SX') 
				return '🇸🇽'
			if(code == 'SY') 
				return '🇸🇾'
			if(code == 'SZ') 
				return '🇸🇿'
			if(code == 'TC') 
				return '🇹🇨'
			if(code == 'TD') 
				return '🇹🇩'
			if(code == 'TF') 
				return '🇹🇫'
			if(code == 'TG') 
				return '🇹🇬'
			if(code == 'TH') 
				return '🇹🇭'
			if(code == 'TJ') 
				return '🇹🇯'
			if(code == 'TK') 
				return '🇹🇰'
			if(code == 'TL') 
				return '🇹🇱'
			if(code == 'TM') 
				return '🇹🇲'
			if(code == 'TN') 
				return '🇹🇳'
			if(code == 'TO') 
				return '🇹🇴'
			if(code == 'TR') 
				return '🇹🇷'
			if(code == 'TT') 
				return '🇹🇹'
			if(code == 'TV') 
				return '🇹🇻'
			if(code == 'TW') 
				return '🇹🇼'
			if(code == 'TZ') 
				return '🇹🇿'
			if(code == 'UA') 
				return '🇺🇦'
			if(code == 'UG') 
				return '🇺🇬'
			if(code == 'UM') 
				return '🇺🇲'
			if(code == 'US') 
				return '🇺🇸'
			if(code == 'UY') 
				return '🇺🇾'
			if(code == 'UZ') 
				return '🇺🇿'
			if(code == 'VA') 
				return '🇻🇦'
			if(code == 'VC') 
				return '🇻🇨'
			if(code == 'VE') 
				return '🇻🇪'
			if(code == 'VG') 
				return '🇻🇬'
			if(code == 'VI') 
				return '🇻🇮'
			if(code == 'VN') 
				return '🇻🇳'
			if(code == 'VU') 
				return '🇻🇺'
			if(code == 'WF') 
				return '🇼🇫'
			if(code == 'WS') 
				return '🇼🇸'
			if(code == 'XK') 
				return '🇽🇰'
			if(code == 'YE') 
				return '🇾🇪'
			if(code == 'YT') 
				return '🇾🇹'
			if(code == 'ZA') 
				return '🇿🇦'
			if(code == 'ZM') 
				return '🇿🇲'
			return '🏳'
		},
		getCountries () {        
			WGeolocationAPI.getCountries (response => {
				let countries = response.data 
				this.loading = false   
				let output = []
				countries.forEach(function (country) { 
					let obj = {value: country.countryId, text: country.countryName, icon: country.shortname}
					output.push(obj)     
				})   
				this.countries = output       
				if(this.adressObj.countryDescription){
					this.getStates(this.adressObj.countryDescription)	
				}                                 
			})               
		},            
	},
	mounted () {
		this.getCountries()
	},	 
}
</script>

<style>
.checkPlan {
  font-size: 24px;
  color: #437bd1;
}
</style>

