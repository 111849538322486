<template>
	<b-col md="10" class="ml-3">
		<b-row class="mt-2 ml-1">  
			<h3 class="lbl-title">{{title}}</h3>                                              
		</b-row>
		<div class="w-100">
			<hr>
		</div>    
		<b-row>
			<b-avatar :src="avtUrl + this.$route.params.companyId + '&imageId='+this.agentId" size="4rem"></b-avatar>   
		</b-row>
		<b-row class="mt-2">
			<span class="custom-font-open-14-gray-normal">
				O usuário que será excluído pode ser responsável por atendimentos. 
				Neste caso, você deseja que esses atendimentos sejam atribuídos à:
			</span>          
		</b-row>
		<b-row class="mt-2">
			<span>Departamento</span>
		</b-row>
		<b-row>
			<div class="department-action-scrollable scrollbar">            
				<div v-for="(x, index) in orderDepartmentList" :key="index" v-show="x.visible == true">
					<label :for="x.departmentId" @change="setDepartmentId(index, x.departmentId)" style="width: 100%;" class="icon-clickable">                             
						<b-form-radio :id="x.departmentId" :name="'option-selected'" v-model="x.checked" value="x.checked" unchecked-value="0"
						>
							<b-row class="ml-1">
								<span v-if="x.departmentName != undefined" class="avatar" :style="x.avatar">{{ x.departmentName.substring(0,2).toUpperCase() }}</span>
								<span v-else class="avatar"></span>					      
								<span class="ml-2" style="margin-top:3px">{{x.departmentName}}</span>
							</b-row>
						</b-form-radio>                                                
					</label>               
				</div>  
			</div>             
		</b-row>
		<b-row class="mt-2">
			<span>Usuário</span>
		</b-row>
		<b-row>
			<div class="agent-action-scrollable scrollbar" >
				<div v-for="(x, index) in orderUserList" :key="index" v-show="x.visible == true && x.status != 3">            
					<label :for="x.userId" @change="setAgentId(x.userId)" style="margin-bottom: 0;">   
						<b-form-radio
							:id="x.userId"
							v-model="x.checked"
							value="x.checked"
							:name="'option-selected-agent'"
							unchecked-value="0"
							checked="1"                        
						>
							<span class="ml-2">
								<b-avatar :src="avtUrl + companyId + '&imageId='+x.userId" size="1.4em"></b-avatar>
							</span>
							<span class="mylabel ml-2">{{x.name}}</span> 
						</b-form-radio>    
					</label>                                                 
				</div>    
			</div>                  
		</b-row>
		<b-row class="mt-5">
			<b-col>
				<div class="d-flex justify-content-center">
					<b-button variant="outline-primary" class="mr-2"
						@click="cancel()">Cancelar
					</b-button>                     
					<b-button variant="primary" 
						@click="removeAttendant()" :disabled="btnDisable">{{title}}
					</b-button>                           
				</div>
			</b-col>
		</b-row>          
	</b-col>
</template>

<script>
import WAttendantsAPI from '@/api/WAttendantsAPI'
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import WSortBy from '@/js/utils/WSortBy'
import { avatarUrl} from '@/global'
export default {
	name: 'WAttendantsPhoto',
	props: [
		'step'
	],
	data () {
		return {
			companyId: this.$route.params.companyId,
			departments: [],
			departmentId: '',
			agentId: '',
			agents: [],
			btnDisable: true,
			avtUrl: avatarUrl,
			targetId: '',
			title: ''
		}
	},    
	computed: {
		orderUserList () {
			const items = WSortBy.orderMyList('name',this.agents)
			return items
		},
		orderDepartmentList () {
			const items = WSortBy.orderMyList('departmentName',this.departments)
			return items
		},	   			    	                  
	},  	  	
	methods: {
		setDepartmentId (id) {
			this.targetId = id
			this.btnDisable = false
			this.agents.forEach(el => {
				el.checked = 0
			})            
		},        
		setAgentId (id) {
			this.targetId = id
			this.btnDisable = false
			this.departments.forEach(el => {
				el.checked = 0
			})
		},
		getDepartmentList () {
			WDepartmentsAPI.getDepartmentsOfUser(this.$route.params.companyId, response => {
				let departments = response.data
				departments.forEach(dep => {                    
					dep.visible = true
					dep.checked = 0
				})
				this.departments = departments
			})
		}, 
		getAgentsList () {
			WAttendantsAPI.getAgentsList(this.$route.params.companyId, response => {
				let agents = response.data
				if (agents){                       
					agents.forEach(agent => {
						agent.visible = true
						if (agent.userId == this.agentId){
							agent.visible = false
						}
						agent.checked = 0
					})                               
				}
				this.agents = agents    
			})    
		}, 
		removeAttendant () {
			if (this.targetId == '' || this.targetId == undefined){
				alert('Selecione um departamento ou usuário!')
				return
			}
			if (this.step == 3.5){
				WAttendantsAPI.postDeactive(this.$route.params.companyId, this.agentId, this.targetId, response => {
					if (response){ 
						let obj = {step: 3}
						this.$emit('sendMessageData', obj)                                                          
					} else {
						this.show =true 
						alert('Falha ao desativar usuário!')
					}
				})                  
			} else {
				WAttendantsAPI.deleteAgent(this.$route.params.companyId, this.agentId, this.targetId, response => {
					if (response){ 
						let obj = {step: 3}
						this.$emit('sendMessageData', obj)                                                          
					} else {
						this.show =true 
						alert('Falha ao excluir usuário!')
					}
				})                  
			}
		},   
		cancel () {
			localStorage.removeItem('__wpp_settings_obj')
			let obj = {step: 3}
			this.$emit('sendMessageData', obj)             
		}         
	},
	mounted () {
		if (this.step == 3.5){
			this.title='Desativar usuário'
		} else {
			this.title='Excluir usuário'
		}
      
		let objAgent = JSON.parse(localStorage.getItem('__wpp_settings_obj'))
		if (objAgent != null){
			this.agentId = objAgent.userId
		}                    
		this.getDepartmentList()
		this.getAgentsList()
	}, 
}
</script>

<style scoped>
.department-action-scrollable {
    height: 15vh;
    width: 100%;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
}
.agent-action-scrollable {
    height: 15vh;
    width: 100%;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
}
.avatar {
	width: 23px;
	height: 23px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--primary);
	border-radius: 50%;
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 22px;
	text-align: center;
	color: #FFFFFF;
}   
</style>
