<template>
	<b-col style="margin-right: 30px;" class="property-list-scrollable scrollbar mt-3">
		<b-col class="mt-2 ml-4">
			<b-row class="ml-0">
				
				<h3 class="lbl-title">Propriedades de contato</h3>
				
				<b-col>
					
					<b-row class="justify-content-end mr-5">
						<b-button v-b-toggle.sidebar-right variant="secondary"
							@click="create()">
							<span>
								<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M8 3V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M3 8H13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</span>
							<span class="ml-1">
								Adicionar propriedade
							</span>                        
						</b-button>                                                             
					</b-row>
				</b-col>
			</b-row>
			<b-row class="ml-0 mr-2">
			
				<span class="custom-font-open-14-gray-normal">
					A principal função das propriedades é vincular valores variáveis por contatos, e com isso tornar os modelos de mensagens adequados e pessoais.                
				</span>
			</b-row>        
			<b-row class="mt-2">
				<b-button :style="btnPersonalized ? 'color: #fff; background-color: var(--primary); border-color:var(--primary);' : 'color: #fff; background-color: #C8C8C8; border-color:#C8C8C8'" class="ml-3"
					@click="openPersonalizedList()">Personalizadas
				</b-button>     
				<b-button :style="btnDefault ? 'color: #fff; background-color: var(--primary); border-color:var(--primary);' : 'color: #fff; background-color: #C8C8C8; border-color:#C8C8C8'" class="ml-3"
					@click="openDefaultList()">Padrão sistema
				</b-button>               
			</b-row>
		</b-col>
		<b-col md="12" sm="12" class="screen-template">
			<loading v-if="this.$store.state.loading ==true"/>     
			<b-row style="margin-rigth:30px;" v-if="this.$store.state.loading ==false">                     
			</b-row>
			<b-row class="mt-1">   
				<div>
					<property-modal-delete
						:title = "'Excluir propriedades'"
						:subTitle = "'Você realmente deseja remover essa propriedade?'"
						:description = "propertyName"
						:description1 = "'Ao remover esta propriedade, você também a removerá de todos os contatos que a usem, bem como o valor atribuído à ela nesses contatos.'"
						:value = "''"
						:placeholder = "''"
						:button1 = "'Fechar'"
						:button2 = "'Excluir'"
						:start="showModalDelete"   
						v-on:sendModalData="removeProperty"
						:key="componentDeleteModalKey"                        
					/>
				</div>                                                  
				<b-col class="ml-2" v-if="this.$store.state.loading ==false">
					<div>
						<tableT v-if="propertyList" 
							:list="propertyList" 
							:fields="fields" 
							:perPage="10" 
							:searchBar="true"
							:filterFields="['propertyName','propertyDescription']"
							:searchPlaceHolder = "'Busque pelo nome ou descrição.'"
							:dropdownOptionsSettingsProperty ="dropdownOptionsSettingsProperty"
							:sortBy = "'propertyName'"
							:selected="10"
							v-on:editProperty ="editProperty"
							v-on:setPropertyDelete ="setPropertyDelete"
						/>
						<!-- <b-table-simple id="my-table"
							:per-page="perPage"
							:current-page="currentPage" hover small responsive>
							<b-thead head-variant="ligth">
								<b-tr>
									<b-th v-if="btnPersonalized ==true">Nome personalizado</b-th>
									<b-th v-else>Nome padrão</b-th>
									<b-th>Propriedade utlizada em:</b-th> 
									<b-th>Propriedade utlizada em:</b-th>                
									<b-th>Descrição propriedade</b-th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr :key="x.propertyId" v-for="x in orderPropertyList"
									v-show="x.visible ==true">
									<b-td class="td-property">                                
										{{x.propertyName}}
									</b-td>
									<b-td class="td-target">                               
										<span :id="x.propertyId" @click="getTemplates(x.propertyId)"><u>{{x.templatesLinked}} Templates</u></span>                                                              
										<b-popover                       
											:target="x.propertyId" 
											placement="auto"
											triggers="click">
											<b-row v-if="loading ==true" class="justify-content-center">
												<b-spinner variant="info" label="Loading..."></b-spinner>
											</b-row>   
											<template #title>
												<b-button @click="onClose(x.propertyId)" class="close ml-3" aria-label="Close">
													<span class="d-inline-block" aria-hidden="true">&times;</span>
												</b-button>
												Templates
											</template>                                    
											<div :key="index_" v-for="(y, index_) in templateList">
												<span class="custom-font-open-12-400">{{y}}</span>
											</div>                                                                                  
										</b-popover>                                     
									</b-td> 
									<b-td class="td-target">                               
										<span :id="x.propertyName" @click="getQuickReply(x.propertyId)"><u>{{x.templatesLinked}} Resposta rápidas</u></span>                                                              
										<b-popover                       
											:target="x.propertyName" 
											placement="auto"
											triggers="click">
											<b-row v-if="loading ==true" class="justify-content-center">
												<b-spinner variant="info" label="Loading..."></b-spinner>
											</b-row>                                  
											<template #title>
												<b-button @click="onCloseQuickReply(x.propertyName)" class="close ml-3" aria-label="Close">
													<span class="d-inline-block" aria-hidden="true">&times;</span>
												</b-button>
												Repostas rápidas
											</template>                                    
											<div :key="index__" v-for="(q, index__) in quickReplyList">
												<span class="custom-font-open-12-400">{{q}}</span>
											</div>                                                                                  
										</b-popover>                                     
									</b-td>                             
									<b-td class="td-date">{{x.propertyDescription}}</b-td>                                                                                                                                                                            
									<b-td v-if="btnPersonalized ==true">
										<div>
											<b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" right text="Right align" no-caret>
												<template #button-content>
													<svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
														<circle cx="2" cy="2" r="2" fill="#C4C4C4"/>
														<circle cx="2" cy="11" r="2" fill="#C4C4C4"/>
														<circle cx="2" cy="20" r="2" fill="#C4C4C4"/>
													</svg>
												</template>
												<b-dropdown-item @click="editProperty(x.propertyId, x.propertyDescription)" >
													<span>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M10.0779 1.50136C10.2368 1.34241 10.4255 1.21632 10.6332 1.1303C10.8409 1.04428 11.0635 1 11.2883 1C11.513 1 11.7356 1.04428 11.9433 1.1303C12.151 1.21632 12.3397 1.34241 12.4986 1.50136C12.6576 1.66031 12.7837 1.84901 12.8697 2.05669C12.9557 2.26436 13 2.48695 13 2.71174C13 2.93653 12.9557 3.15912 12.8697 3.3668C12.7837 3.57447 12.6576 3.76317 12.4986 3.92212L4.32855 12.0922L1 13L1.90779 9.67145L10.0779 1.50136Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														</svg>
													</span>
													<span class="custom-font-open-12-400 ml-1">
														Editar descrição
													</span>                                        
												</b-dropdown-item>                                                                       
												<b-dropdown-item @click="setPropertyDelete(x.propertyId, x.propertyName)">
													<span>
														<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M3 4H4.33333H15" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M6.3335 4.00004V2.66671C6.3335 2.31309 6.47397 1.97395 6.72402 1.7239C6.97407 1.47385 7.31321 1.33337 7.66683 1.33337H10.3335C10.6871 1.33337 11.0263 1.47385 11.2763 1.7239C11.5264 1.97395 11.6668 2.31309 11.6668 2.66671V4.00004M13.6668 4.00004V13.3334C13.6668 13.687 13.5264 14.0261 13.2763 14.2762C13.0263 14.5262 12.6871 14.6667 12.3335 14.6667H5.66683C5.31321 14.6667 4.97407 14.5262 4.72402 14.2762C4.47397 14.0261 4.3335 13.687 4.3335 13.3334V4.00004H13.6668Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M7.6665 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M10.3335 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														</svg>
													</span>
													<span class="custom-font-open-12-400 ml-1">
														Remover atributo
													</span>                                        
												</b-dropdown-item>                                                                                                              
											</b-dropdown>
										</div>      
    
									</b-td>                           
								</b-tr>
							</b-tbody>
						</b-table-simple>   -->
					</div>
				</b-col>   
			</b-row>
			<!-- <b-row v-show="rows > perPage" class="pb-5">
				<b-col md="4" class="mt-2">
					<b-row>
						<b-col md="7" class="mt-1">
							<span class="mt-2 lblPagination">Visualizar por página:</span> 
						</b-col>
						<b-col md="4">                                                 
							<b-form-select v-model="selected" :options="perPageOptions" size="sm" @change="setPerPage()"></b-form-select>                
						</b-col>
					</b-row>
				</b-col>
				<b-col class="mr-5">
					<b-row class="d-flex justify-content-end">
						<b-pagination
							v-model="currentPage"
							:total-rows="rows"
							:per-page="perPage"
							pills
							size="sm"
							aria-controls="my-table"
						></b-pagination> 
					</b-row>
				</b-col>
        
			</b-row>            -->
		</b-col>
	</b-col>    
</template>

<script>
import Loading from '@/views/Loading'
import WPhoneNumberAPI from '@/api/WPhoneNumberAPI'
import WPropertiesAPI from '@/api/WPropertiesAPI'
import Vue2Filters from 'vue2-filters'
import { avatarUrl} from '@/global'
import WModal from '@/views/WModal'
import WSortBy from '@/js/utils/WSortBy'
import Table from '@/components/global/TableComponent/Table'
export default {
	mixins: [Vue2Filters.mixin],
	components: {
		tableT: Table,
		loading: Loading,  
		'property-modal-delete': WModal,             
	},
	name: 'WPropertyList',
	data: function () {
		return {
			loading: false,
			avtUrl: avatarUrl,  
			filter: '',
			propertyList: [],   
			originalPropertyList: [],
			showModalDelete: false,                   
			perPage: 20,
			selected: 20,
			currentPage: 1,  
			totalPage: 1,  
			paginatedItems: [], 
			perPageOptions: [20, 50, 100, 200, 500],  
			phoneNumberList: [],   
			propertyName: '',  
			componentDeleteModalKey: 0,                                             
			popoverShow: false,
			userIndex: null,
			btnPersonalized: true,
			btnDefault: false,
			templateList: [],
			quickReplyList: [],
			fields: [
				{
					key: 'propertyName',
					sortable: true,
					label:'Nome personalizado'
					
				},
				{
					key: 'templatesLinked',
					sortable: true,
					label:'Propriedade utlizada em:'
					
				},
				{
					key: 'quickReplyLinked',
					sortable: true,
					label:'Propriedade utlizada em:'
				},
				{
					key: 'propertyDescription',
					sortable: true,
					label:'	Descrição propriedade'
				},
				{
					key: 'dropdownOptionsSettingsProperty',
					label:''
				},
				
			],
			dropdownOptionsSettingsProperty: {
				editProperty:true,
				setPropertyDelete:true,

				
			}
		}
	},
	computed: {
		rows () {
			return this.propertyList.length
		},
		orderPropertyList () {
			const items = WSortBy.orderMyList('propertyName',this.propertyList)
			return items.slice(
				(this.currentPage - 1) * this.perPage,
				this.currentPage * this.perPage
			)
		},        
	},    
	methods: {    
		onClose (id) {
			this.$root.$emit('bv::hide::popover', id)   
		},
		onCloseQuickReply (name) {
			this.$root.$emit('bv::hide::popover', name)   
		},        
		openPersonalizedList () {
			this.btnPersonalized= true,
			this.btnDefault= false
			this.getPropertyList(false)
		},  
		openDefaultList () {
			this.btnPersonalized= false,
			this.btnDefault= true
			this.getPropertyList(true)
		},          
		create () {
			let obj = {step: 5.1}
			this.$emit('sendMessageData', obj)               
		}, 
		getTemplates (propertyId) {
			this.loading = true
			WPropertiesAPI.getPropertyListTemplates(this.$route.params.companyId, propertyId, response => {
				this.loading = false
				this.templateList = response.data
			})
		},  
		getQuickReply (propertyId) {
			this.loading = true
			WPropertiesAPI.getListQuickReply(this.$route.params.companyId, propertyId, response => {
				this.loading = false
				this.quickReplyList = response.data
			})                
		},           
		getPropertyList (defaultSystem) {
			this.$store.commit('setShowLoading', true)
			WPropertiesAPI.getPropertyList(this.$route.params.companyId, response => {
				let propertyList = response.data
				this.$store.commit('setShowLoading', false)
				let list = []
				propertyList.forEach(element => {
					if (element.defaultProperty == defaultSystem){
						element.visible = true  
						list.push(element)                      
					}
				})
				this.propertyList = list 
				this.originalPropertyList = this.propertyList
			})
		},         
		setPerPage () {
			this.perPage = this.selected
		},
		searchProperties () {
			let propertyList = this.originalPropertyList
			this.propertyList = []            
			propertyList.forEach( el => {
				if ( el.propertyName.toLowerCase().includes(this.filter.toLowerCase())
				&& el.defaultProperty == !this.btnPersonalized ){
					el.visible = true
					this.propertyList.push(el)
				} else {
					el.visible = false
				}
			})
		},   
  
		setPropertyDelete (propertyId, name) {  
			this.propertyId = propertyId
			this.componentDeleteModalKey ++
			this.showModalDelete = true 
			this.propertyName = name
		},   

		removeProperty (remove) {  
			this.showModalDelete =false
			if (remove){
				WPropertiesAPI.deleteProperty(this.$route.params.companyId, this.propertyId, response => {
					if (response){
						this.getPropertyList(false)                    
					} else {
						alert('Falha ao excluir a propriedade!')
					}                                       
				})                
			}            
		},   
                  
		timeToDate (time) {
			if (time ==0){
				return '-'
			}
			let t = new Date(Number(time))

			let fHourMinute = t.getHours() + ':'
			if(t.getMinutes()<10){
				fHourMinute+='0' 
			}
			fHourMinute+=t.getMinutes()  

			let d = new Date(Number(time))
			let day = d.getDate()        
			if(day < 10){
				day ='0' + d.getDate() 
			}
			let month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1)
			let sDate  = day  + '/' + month + '/' + d.getFullYear() 
			return sDate + ' ' + fHourMinute
		},    
		editProperty (propertyId, description ) {
			let objProperty = {
				propertyId: propertyId,
				propertyDescription: description
			}
			localStorage.removeItem('__wpp_settings_obj')
			localStorage.setItem('__wpp_settings_obj', JSON.stringify(objProperty))            
			let obj = {step: 5.2}
			this.$emit('sendMessageData', obj)                
		},
		getPhonenumbers () {
			WPhoneNumberAPI.getPhonenumbers(this.$route.params.companyId, response => {
				this.phoneNumberList = response.data           
			})                
		},                   
	},      
	mounted () {
		this.getPhonenumbers()
		this.getPropertyList(false)
	}
}
</script>

<style scoped>

u {
    cursor: pointer;
}
th {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #999999 !important;    
}

td {
    margin: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #777777; 
    height: 50px;
    vertical-align: middle !important;
}
.td-property {
    margin-top: 20px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.td-phonenumber {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;  
}
.td-date {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-data {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-email {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}
.td-property {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}

.td-target {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-fail {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-deliver {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.td-readed {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}



.sidebar-right-propertys {
    display: flex !important;
    width: 100% !important;
    flex-direction: column !important;
    background: white !important;
    padding: 10px;
    margin-right: 50px;
}
.custom-pagination-input {
    width: 50px !important;
}
.custom-pagination-select {
    width: 60px !important;
}

.property-list-scrollable {
    height: 90vh;
    width: 98%;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
}
.lblPagination {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px; 
    color: #777777;   
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    min-height: 200px;
    -webkit-overflow-scrolling: touch;
}

.form-control:focus {
    background-color: #F5F5F5;
    box-shadow: none;
}

</style>
