<template>
	<b-col md="10" sm="12">
		<b-row>
			<span @click="edit">
				<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g filter="url(#filter0_d)">
						<rect x="6" y="5" width="24" height="24" rx="4" fill="white"/>
						<path d="M21 11.4142C21.2652 11.149 21.6249 11 22 11C22.1857 11 22.3696 11.0366 22.5412 11.1077C22.7128 11.1787 22.8687 11.2829 23 11.4142C23.1313 11.5455 23.2355 11.7014 23.3066 11.873C23.3776 12.0446 23.4142 12.2285 23.4142 12.4142C23.4142 12.5999 23.3776 12.7838 23.3066 12.9554C23.2355 13.127 23.1313 13.2829 23 13.4142L14.6667 21.7475L12 22.4142L12.6667 19.7475L21 11.4142Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</g>
					<defs>
						<filter id="filter0_d" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
							<feFlood flood-opacity="0" result="BackgroundImageFix"/>
							<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
							<feOffset dy="1"/>
							<feGaussianBlur stdDeviation="3"/>
							<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
							<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
							<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
						</filter>
					</defs>
				</svg>
			</span>  
		</b-row>

		<Loading v-if="loading==true || this.$store.state.loading==true"/> 
		<b-modal v-model="show" title="Editar endereço" centered>        
			<b-form>
				<b-row>              
					<b-col md="6" sm="12">
						<b-form-group label="País" label-for="country">
							<b-form-input 
								type="search"
								list="list-country" 
								v-model="countryId" 
								debounce="500"  
								@change="getStates(countryId)"
								:disabled="disable"
								autocomplete="off"></b-form-input>
							<datalist id="list-country">
								<option v-for="x in countries" :key="x.value">{{getFlags(x.icon)}} {{x.text}}</option>
							</datalist>                    
						</b-form-group>
					</b-col>    
					<b-col md="6" sm="12">
						<b-form-group label="Estado" label-for="state">
							<b-form-input type="search" list="list-state" v-model="stateId" debounce="500" @change="getCities(stateId)" :disabled="disable"></b-form-input>
							<datalist id="list-state">
								<option v-for="x in states" :key="x.value">{{x.text}}</option>
							</datalist>                         
						</b-form-group>
					</b-col>                                                  
				</b-row>  
				<b-row>                            
					<b-col md="6" sm="12" v-if="cities.length > 0">
						<b-form-group label="Cidade" label-for="city">
							<b-form-input type="search" list="list-city" v-model="cityId" debounce="500" :disabled="disable"></b-form-input>
							<datalist id="list-city">
								<option v-for="x in cities" :key="x.value">{{x.text}}</option>
							</datalist>                       
						</b-form-group>
					</b-col>    
					<b-col md="6" sm="12" v-else>
						<b-form-group label="Cidade" label-for="cityName">
							<b-form-input id="cityId" type="text"
								v-model="cityId" :disabled="disable"/>
						</b-form-group>                
					</b-col>    
					<b-col md="6" sm="12">
						<b-form-group label="CEP" label-for="zipCode">
							<b-form-input id="zipCode" type="text"
								v-model="zipCode" placeholder="00000-000" :disabled="disable"/>
						</b-form-group>                   
					</b-col>                                                                                
				</b-row>             
				<b-row>  
					<b-col md="6" sm="12">
						<b-form-group label="Bairro" label-for="neighborhood">
							<b-form-input id="neighborhood" type="text"
								v-model="neighborhood" :disabled="disable"/>
						</b-form-group>
					</b-col>                                                                              
				</b-row> 
				<b-row>      
					<b-col md="8" sm="12">
						<b-form-group label="Rua" label-for="street">
							<b-form-input id="street" type="text"
								v-model="street" :disabled="disable"/>
						</b-form-group>
					</b-col>                      
					<b-col md="4" sm="12">
						<b-form-group label="Número" label-for="number">
							<b-form-input id="number" type="number"
								v-model="number" :disabled="disable"/>
						</b-form-group>
					</b-col>                                                                          
				</b-row>    
				<b-row>
					<b-col md="12" sm="12">
						<b-form-group label="Complemento" label-for="complement">
							<b-form-input id="complement" type="text"
								v-model="complement" :disabled="disable"/>
						</b-form-group>
					</b-col>                      
				</b-row>                                           
			</b-form>
			<template #modal-footer>
				<div class="w-100">
					<b-button variant="outline-primary" class="mr-2"
						@click="cancel()">Cancelar
					</b-button>  
					<b-button variant="primary" class="float-right"
						@click="save()">Salvar endereço           
					</b-button>                     
				</div>  
			</template>
		</b-modal>             
	</b-col>
</template>

<script>
import WGeolocationAPI from '@/api/WGeolocationAPI'
import WContactsAPI from '@/api/WContactsAPI'
import Loading from '@/views/Loading'
export default {
	components: {Loading},    
	name: 'WAddress',  
	props: ['contactId','type', 'address'],
	data: function () {
		return {
			loading: false,
			myCompanies: [],
			company: {},
			companyName: '', 
			selectFilter: '',
			countryId: '',
			cityId: '',
			cityName: '',
			stateId: '',
			countries: [],
			states: [],
			cities: [],
			street: '',
			zipCode: '',
			neighborhood: '',
			number: 0,
			complement: '',
			disable: false,
			show: false
		}
	},
	methods: {
		edit () {
			this.countryId = this.address.countryDescription
			this.stateId = this.address.stateDescription
			this.cityId = this.address.city
			this.zipCode = this.address.zipCode
			this.neighborhood = this.address.neighborhood
			this.street = this.address.street
			this.number = this.address.number
			this.complement =this.address.complement
			if (this.address.country != ''){
				this.getStates(this.address.country) 
			}
			if (this.address.state != ''){
				this.getCities(this.address.state)
			}            
			this.show = true
		},      
		cancel () {
			this.show = false 
		},                       
		save () {         
			if (this.countryId == '' || this.countryId == undefined ){
				alert('País campo obrigatório!')
				return
			}      
			if (this.street == '' || this.street == undefined ){
				alert('Rua campo obrigatório!')
				return
			}      
			if (this.stateId == '' || this.stateId == undefined ){
				alert('Estado campo obrigatório!')
				return
			}      
			if (this.cityId == '' || this.cityId == undefined ){
				alert('Cidade campo obrigatório!')
				return
			}  

			//countries
			let countryId = this.countryId
			let idCountry =''
			for (const key in this.countries){
				let countryObj = this.countries[key]
				if (countryId.indexOf(countryObj.text) > -1){
					idCountry = countryObj.value                    
				}               
			}      
			//states
			let stateId = this.stateId
			let idState =''
			for (const key in this.states){
				let stateObj = this.states[key]
				if (stateId.indexOf(stateObj.text) > -1){
					idState = stateObj.value                    
				}               
			}        
			let address = {        
				zipCode: this.zipCode,        
				neighborhood: this.neighborhood,  
				complement: this.complement,
				street: this.street,        
				city: this.cityId,   
				state: idState,
				number: this.number,        
				country: idCountry,                     
			}                   
                                                                         
			WContactsAPI.postAddress(this.$route.params.companyId, this.contactId, address, response => {
				if (response){
					this.show = false
					let obj = {file: null, mediaType: 'address', msg: '', visible: false}                        
					this.$emit('sendMessageData', obj)                      
				} else {
					alert('Falha ao atualizar endereço!')
					this.show = true
				}
			})    
		},
		getCountries () {        
			WGeolocationAPI.getCountries (response => {
				let countries = response.data 
				this.loading = false   
				let output = []
				countries.forEach(function (country) { 
					let obj = {value: country.countryId, text: country.countryName, icon: country.shortname}
					output.push(obj)     
				})   
				this.countries = output                                      
			})               
		},                        
		getStates (countryId) {   
			let id = countryId
			if (countryId != '' && countryId != undefined){
				for (const key in this.countries){
					let countryObj = this.countries[key]
					if (countryId.indexOf(countryObj.text) > -1){
						id = countryObj.value                    
					}            
				}   
			}       
                           
			WGeolocationAPI.getStates (id, response => {
				let states = response.data 
				let output = []
				states.forEach(function (state) { 
					let obj = {value: state.stateId, text: state.stateName}
					output.push(obj)     
				})    
				this.states = output         
			})   
		},  
		getCities (stateId) {   
			let id = stateId         
			for (const key in this.states){
				let statesObj = this.states[key]
				if (stateId.indexOf(statesObj.text) > -1){
					id = statesObj.value
				}              
			}                   
			WGeolocationAPI.getCities (id, response => {
				let cities = response.data 
				let output = []
				cities.forEach(function (city) { 
					let obj = {value: city.cityId, text: city.cityName}
					output.push(obj)     
				}) 
				this.cities = output            
			})   
		},     
		getFlags (code) {
			if(code == 'AD') 
				return '🇦🇩'
			if(code == 'AE') 
				return '🇦🇪'
			if(code == 'AF') 
				return '🇦🇫'
			if(code == 'AG') 
				return '🇦🇬'
			if(code == 'AI') 
				return '🇦🇮'
			if(code == 'AL') 
				return '🇦🇱'
			if(code == 'AM') 
				return '🇦🇲'
			if(code == 'AO') 
				return '🇦🇴'
			if(code == 'AQ') 
				return '🇦🇶'
			if(code == 'AR') 
				return '🇦🇷'
			if(code == 'AS') 
				return '🇦🇸'
			if(code == 'AT') 
				return '🇦🇹'
			if(code == 'AU') 
				return '🇦🇺'
			if(code == 'AW') 
				return '🇦🇼'
			if(code == 'AX') 
				return '🇦🇽'
			if(code == 'AZ') 
				return '🇦🇿'
			if(code == 'BA') 
				return '🇧🇦'
			if(code == 'BB') 
				return '🇧🇧'
			if(code == 'BD') 
				return '🇧🇩'
			if(code == 'BE') 
				return '🇧🇪'
			if(code == 'BF') 
				return '🇧🇫'
			if(code == 'BG') 
				return '🇧🇬'
			if(code == 'BH') 
				return '🇧🇭'
			if(code == 'BI') 
				return '🇧🇮'
			if(code == 'BJ') 
				return '🇧🇯'
			if(code == 'BL') 
				return '🇧🇱'
			if(code == 'BM') 
				return '🇧🇲'
			if(code == 'BN') 
				return '🇧🇳'
			if(code == 'BO') 
				return '🇧🇴'
			if(code == 'BQ') 
				return '🇧🇶'
			if(code == 'BR') 
				return '🇧🇷'
			if(code == 'BS') 
				return '🇧🇸'
			if(code == 'BT') 
				return '🇧🇹'
			if(code == 'BV') 
				return '🇧🇻'
			if(code == 'BW') 
				return '🇧🇼'
			if(code == 'BY') 
				return '🇧🇾'
			if(code == 'BZ') 
				return '🇧🇿'
			if(code == 'CA') 
				return '🇨🇦'
			if(code == 'CC') 
				return '🇨🇨'
			if(code == 'CD') 
				return '🇨🇩'
			if(code == 'CF') 
				return '🇨🇫'
			if(code == 'CG') 
				return '🇨🇬'
			if(code == 'CH') 
				return '🇨🇭'
			if(code == 'CI') 
				return '🇨🇮'
			if(code == 'CK') 
				return '🇨🇰'
			if(code == 'CL') 
				return '🇨🇱'
			if(code == 'CM') 
				return '🇨🇲'
			if(code == 'CN') 
				return '🇨🇳'
			if(code == 'CO') 
				return '🇨🇴'
			if(code == 'CR') 
				return '🇨🇷'
			if(code == 'CU') 
				return '🇨🇺'
			if(code == 'CV') 
				return '🇨🇻'
			if(code == 'CW') 
				return '🇨🇼'
			if(code == 'CX') 
				return '🇨🇽'
			if(code == 'CY') 
				return '🇨🇾'
			if(code == 'CZ') 
				return '🇨🇿'
			if(code == 'DE') 
				return '🇩🇪'
			if(code == 'DJ') 
				return '🇩🇯'
			if(code == 'DK') 
				return '🇩🇰'
			if(code == 'DM') 
				return '🇩🇲'
			if(code == 'DO') 
				return '🇩🇴'
			if(code == 'DZ') 
				return '🇩🇿'
			if(code == 'EC') 
				return '🇪🇨'
			if(code == 'EE') 
				return '🇪🇪'
			if(code == 'EG') 
				return '🇪🇬'
			if(code == 'EH') 
				return '🇪🇭'
			if(code == 'ER') 
				return '🇪🇷'
			if(code == 'ES') 
				return '🇪🇸'
			if(code == 'ET') 
				return '🇪🇹'
			if(code == 'FI') 
				return '🇫🇮'
			if(code == 'FJ') 
				return '🇫🇯'
			if(code == 'FK') 
				return '🇫🇰'
			if(code == 'FM') 
				return '🇫🇲'
			if(code == 'FO') 
				return '🇫🇴'
			if(code == 'FR') 
				return '🇫🇷'
			if(code == 'GA') 
				return '🇬🇦'
			if(code == 'GB') 
				return '🇬🇧'
			if(code == 'GD') 
				return '🇬🇩'
			if(code == 'GE') 
				return '🇬🇪'
			if(code == 'GF') 
				return '🇬🇫'
			if(code == 'GG') 
				return '🇬🇬'
			if(code == 'GH') 
				return '🇬🇭'
			if(code == 'GI') 
				return '🇬🇮'
			if(code == 'GL') 
				return '🇬🇱'
			if(code == 'GM') 
				return '🇬🇲'
			if(code == 'GN') 
				return '🇬🇳'
			if(code == 'GP') 
				return '🇬🇵'
			if(code == 'GQ') 
				return '🇬🇶'
			if(code == 'GR') 
				return '🇬🇷'
			if(code == 'GS') 
				return '🇬🇸'
			if(code == 'GT') 
				return '🇬🇹'
			if(code == 'GU') 
				return '🇬🇺'
			if(code == 'GW') 
				return '🇬🇼'
			if(code == 'GY') 
				return '🇬🇾'
			if(code == 'HK') 
				return '🇭🇰'
			if(code == 'HM') 
				return '🇭🇲'
			if(code == 'HN') 
				return '🇭🇳'
			if(code == 'HR') 
				return '🇭🇷'
			if(code == 'HT') 
				return '🇭🇹'
			if(code == 'HU') 
				return '🇭🇺'
			if(code == 'ID') 
				return '🇮🇩'
			if(code == 'IE') 
				return '🇮🇪'
			if(code == 'IL') 
				return '🇮🇱'
			if(code == 'IM') 
				return '🇮🇲'
			if(code == 'IN') 
				return '🇮🇳'
			if(code == 'IO') 
				return '🇮🇴'
			if(code == 'IQ') 
				return '🇮🇶'
			if(code == 'IR') 
				return '🇮🇷'
			if(code == 'IS') 
				return '🇮🇸'
			if(code == 'IT') 
				return '🇮🇹'
			if(code == 'JE') 
				return '🇯🇪'
			if(code == 'JM') 
				return '🇯🇲'
			if(code == 'JO') 
				return '🇯🇴'
			if(code == 'JP') 
				return '🇯🇵'
			if(code == 'KE') 
				return '🇰🇪'
			if(code == 'KG') 
				return '🇰🇬'
			if(code == 'KH') 
				return '🇰🇭'
			if(code == 'KI') 
				return '🇰🇮'
			if(code == 'KM') 
				return '🇰🇲'
			if(code == 'KN') 
				return '🇰🇳'
			if(code == 'KP') 
				return '🇰🇵'
			if(code == 'KR') 
				return '🇰🇷'
			if(code == 'KW') 
				return '🇰🇼'
			if(code == 'KY') 
				return '🇰🇾'
			if(code == 'KZ') 
				return '🇰🇿'
			if(code == 'LA') 
				return '🇱🇦'
			if(code == 'LB') 
				return '🇱🇧'
			if(code == 'LC') 
				return '🇱🇨'
			if(code == 'LI') 
				return '🇱🇮'
			if(code == 'LK') 
				return '🇱🇰'
			if(code == 'LR') 
				return '🇱🇷'
			if(code == 'LS') 
				return '🇱🇸'
			if(code == 'LT') 
				return '🇱🇹'
			if(code == 'LU') 
				return '🇱🇺'
			if(code == 'LV') 
				return '🇱🇻'
			if(code == 'LY') 
				return '🇱🇾'
			if(code == 'MA') 
				return '🇲🇦'
			if(code == 'MC') 
				return '🇲🇨'
			if(code == 'MD') 
				return '🇲🇩'
			if(code == 'ME') 
				return '🇲🇪'
			if(code == 'MF') 
				return '🇲🇫'
			if(code == 'MG') 
				return '🇲🇬'
			if(code == 'MH') 
				return '🇲🇭'
			if(code == 'MK') 
				return '🇲🇰'
			if(code == 'ML') 
				return '🇲🇱'
			if(code == 'MM') 
				return '🇲🇲'
			if(code == 'MN') 
				return '🇲🇳'
			if(code == 'MO') 
				return '🇲🇴'
			if(code == 'MP') 
				return '🇲🇵'
			if(code == 'MQ') 
				return '🇲🇶'
			if(code == 'MR') 
				return '🇲🇷'
			if(code == 'MS') 
				return '🇲🇸'
			if(code == 'MT') 
				return '🇲🇹'
			if(code == 'MU') 
				return '🇲🇺'
			if(code == 'MV') 
				return '🇲🇻'
			if(code == 'MW') 
				return '🇲🇼'
			if(code == 'MX') 
				return '🇲🇽'
			if(code == 'MY') 
				return '🇲🇾'
			if(code == 'MZ') 
				return '🇲🇿'
			if(code == 'NA') 
				return '🇳🇦'
			if(code == 'NC') 
				return '🇳🇨'
			if(code == 'NE') 
				return '🇳🇪'
			if(code == 'NF') 
				return '🇳🇫'
			if(code == 'NG') 
				return '🇳🇬'
			if(code == 'NI') 
				return '🇳🇮'
			if(code == 'NL') 
				return '🇳🇱'
			if(code == 'NO') 
				return '🇳🇴'
			if(code == 'NP') 
				return '🇳🇵'
			if(code == 'NR') 
				return '🇳🇷'
			if(code == 'NU') 
				return '🇳🇺'
			if(code == 'NZ') 
				return '🇳🇿'
			if(code == 'OM') 
				return '🇴🇲'
			if(code == 'PA') 
				return '🇵🇦'
			if(code == 'PE') 
				return '🇵🇪'
			if(code == 'PF') 
				return '🇵🇫'
			if(code == 'PG') 
				return '🇵🇬'
			if(code == 'PH') 
				return '🇵🇭'
			if(code == 'PK') 
				return '🇵🇰'
			if(code == 'PL') 
				return '🇵🇱'
			if(code == 'PM') 
				return '🇵🇲'
			if(code == 'PN') 
				return '🇵🇳'
			if(code == 'PR') 
				return '🇵🇷'
			if(code == 'PS') 
				return '🇵🇸'
			if(code == 'PT') 
				return '🇵🇹'
			if(code == 'PW') 
				return '🇵🇼'
			if(code == 'PY') 
				return '🇵🇾'
			if(code == 'QA') 
				return '🇶🇦'
			if(code == 'RE') 
				return '🇷🇪'
			if(code == 'RO') 
				return '🇷🇴'
			if(code == 'RS') 
				return '🇷🇸'
			if(code == 'RU') 
				return '🇷🇺'
			if(code == 'RW') 
				return '🇷🇼'
			if(code == 'SA') 
				return '🇸🇦'
			if(code == 'SB') 
				return '🇸🇧'
			if(code == 'SC') 
				return '🇸🇨'
			if(code == 'SD') 
				return '🇸🇩'
			if(code == 'SE') 
				return '🇸🇪'
			if(code == 'SG') 
				return '🇸🇬'
			if(code == 'SH') 
				return '🇸🇭'
			if(code == 'SI') 
				return '🇸🇮'
			if(code == 'SJ') 
				return '🇸🇯'
			if(code == 'SK') 
				return '🇸🇰'
			if(code == 'SL') 
				return '🇸🇱'
			if(code == 'SM') 
				return '🇸🇲'
			if(code == 'SN') 
				return '🇸🇳'
			if(code == 'SO') 
				return '🇸🇴'
			if(code == 'SR') 
				return '🇸🇷'
			if(code == 'SS') 
				return '🇸🇸'
			if(code == 'ST') 
				return '🇸🇹'
			if(code == 'SV') 
				return '🇸🇻'
			if(code == 'SX') 
				return '🇸🇽'
			if(code == 'SY') 
				return '🇸🇾'
			if(code == 'SZ') 
				return '🇸🇿'
			if(code == 'TC') 
				return '🇹🇨'
			if(code == 'TD') 
				return '🇹🇩'
			if(code == 'TF') 
				return '🇹🇫'
			if(code == 'TG') 
				return '🇹🇬'
			if(code == 'TH') 
				return '🇹🇭'
			if(code == 'TJ') 
				return '🇹🇯'
			if(code == 'TK') 
				return '🇹🇰'
			if(code == 'TL') 
				return '🇹🇱'
			if(code == 'TM') 
				return '🇹🇲'
			if(code == 'TN') 
				return '🇹🇳'
			if(code == 'TO') 
				return '🇹🇴'
			if(code == 'TR') 
				return '🇹🇷'
			if(code == 'TT') 
				return '🇹🇹'
			if(code == 'TV') 
				return '🇹🇻'
			if(code == 'TW') 
				return '🇹🇼'
			if(code == 'TZ') 
				return '🇹🇿'
			if(code == 'UA') 
				return '🇺🇦'
			if(code == 'UG') 
				return '🇺🇬'
			if(code == 'UM') 
				return '🇺🇲'
			if(code == 'US') 
				return '🇺🇸'
			if(code == 'UY') 
				return '🇺🇾'
			if(code == 'UZ') 
				return '🇺🇿'
			if(code == 'VA') 
				return '🇻🇦'
			if(code == 'VC') 
				return '🇻🇨'
			if(code == 'VE') 
				return '🇻🇪'
			if(code == 'VG') 
				return '🇻🇬'
			if(code == 'VI') 
				return '🇻🇮'
			if(code == 'VN') 
				return '🇻🇳'
			if(code == 'VU') 
				return '🇻🇺'
			if(code == 'WF') 
				return '🇼🇫'
			if(code == 'WS') 
				return '🇼🇸'
			if(code == 'XK') 
				return '🇽🇰'
			if(code == 'YE') 
				return '🇾🇪'
			if(code == 'YT') 
				return '🇾🇹'
			if(code == 'ZA') 
				return '🇿🇦'
			if(code == 'ZM') 
				return '🇿🇲'
			return '🏳'
		}                       
	},
	mounted () {
		this.getCountries()    
	}    
}
</script>

<style>
.checkPlan {
  font-size: 24px;
  color: #437bd1;
}
</style>

