<template>
	<b-col  md="10">
		<b-row class=" mt-3">  
			<div class="ml-4" v-if="step == 6.3" >
				<b-row>
					<h3 class="lbl-title ml-2">Nova Marcação - Escolha de departamento</h3>
				</b-row>
					
				<b-row>
					<label class="custom-font-open-14-gray ml-2">Escolha um ou mais departamentos que poderão usar essa marcação</label>
				</b-row>
			</div>    
			<h3 v-else class="lbl-title ml-4">Editar marcação</h3>
		</b-row>	
		<b-row class="ml-2">
			<div>
				<b-form-checkbox v-model="checked" @change="setAllDepartments" name="check-button" switch>
					Todos os Departamentos
				</b-form-checkbox>
			</div>               
		</b-row>
		<div class="ml-3 w-75" v-if="checked == false">
			<b-row>
				<b-col md="10" class="mb-2">
					<div class="search" style="margin-left: -15px;">  
						<b-input-group>
							<b-form-input 
								type="search"                     
								placeholder="Busque pelo nome do departamento"
								v-model="filter"
								@keyup="searchDepartment"
								class="mt-1"
								@update="clearFilter">
							</b-form-input>
							<b-input-group-prepend is-text class="mt-1">
								<b-icon icon="search"></b-icon>
							</b-input-group-prepend>                    
						</b-input-group>                                                                                       
					</div>
				</b-col>
			</b-row>
			<div class="department-scrollable scrollbar">
				<div v-for="x in orderedList" :key="x.departmentId" v-show="x.visible == true">
					<label :for="x.departmentId" style="width: 100%;margin: 8px;" class="icon-clickable">                            
						<b-form-checkbox
							:id="x.departmentId"
							checked="1"
							v-model="x.checked"
							unchecked-value="0"
							size="lg"
						>
							<b-row class="ml-0 mt-1">  
								<span class="ml-2 mr-2">
									<div v-if="x.departmentName != undefined" class="avatar" :style="x.avatar">{{ x.departmentName.substring(0,2).toUpperCase() }}</div>
									<div v-else class="avatar"></div>
								</span>         
								<span class="td-data ml-1" style="margin-top:3px">{{x.departmentName}}</span>   
							</b-row>                                
						</b-form-checkbox>                                           
					</label>               
				</div> 
			</div>                                                                                                                          
		</div>
		<b-row class="mt-5">
			<b-col>
				<div class="d-flex justify-content-center">
					<b-button variant="outline-primary" class="mr-2"
						@click="cancel()">Cancelar
					</b-button>                      
					<b-button variant="primary" 
						@click="next()">Avançar
					</b-button>                                          
				</div>
			</b-col>            
		</b-row>  
	</b-col>
</template>

<script>
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import WLabelsAPI from '@/api/WLabelsAPI'
export default {
	
	name: 'WLabelDescription',
	computed:{
		orderedList () {
			const items = this.departments
			items.sort(this.dynamicSort(['departmentName']))			
			return items
		}, 
	},
	props: [
		'step',
		'currentLabel'
	],
	data () {
		return { 
			departments: [],
			checked: false,
			filter: '',
		}
	}, 
	methods: {
		getDepartmentList () {
			WDepartmentsAPI.getDepartmentsOfUser(this.$route.params.companyId, response => {
				let departments = response.data
				if(this.currentLabel.departmentAll == true){
					this.checked = true
					departments.forEach(dep => {
						dep.checked = true
						dep.visible =  true
					}) 
				}else{
					departments.forEach(dep => {
						dep.checked = false
						if(this.currentLabel.departmentIdList){
							if(this.currentLabel.departmentIdList.length > 0){
								this.currentLabel.departmentIdList.forEach(element => {
									if(dep.departmentId == element){
										dep.checked = true
									}
								})
							}
						}
					
						dep.visible =  true
					}) 
				}
				this.departments = departments
			})
		},
		getDepartmentIdList () {
			let departmentIdList = []
			this.departments.forEach(element => {
				if(element.checked == true){
					departmentIdList.push(element.departmentId)
				}
			})
			return departmentIdList
		},
		searchDepartment () {
			this.departments.forEach( el => {
				if ( el.departmentName.toLowerCase().includes(this.filter.toLowerCase()) ){
					el.visible = true
				} else {
					el.visible = false
				}
			})        
		},
		dynamicSort (fields) {
			return function (a, b) {
				return fields
					.map(function (o) {
						let dir = 1
						if (o[0] === '-') {
							dir = -1
							o=o.substring(1)
						}
						if (a[o].toLowerCase() > b[o].toLowerCase()) 
							return dir
						if (a[o].toLowerCase() < b[o].toLowerCase()) 
							return -(dir)
						return 0
					})
					.reduce(function firstNonZeroValue (p,n) {
						return p ? p : n
					}, 0)
			}
		},
		next () {
			let departmentIdList = this.getDepartmentIdList()
			WLabelsAPI.postDepartment(this.$route.params.companyId,this.currentLabel.labelId,this.checked, departmentIdList, response => {
				response
				this.$emit('sendMessageData')
			}) 
		},
		setAllDepartments () {
			this.departments.forEach(element => {
				if(this.checked == true){
					element.checked = true
				}else{
					element.checked = false
				}
			})
		},
		cancel () {
			this.$emit('sendMessageData')                    
		},
		clearFilter () {
			if(this.filter == ''){
				this.searchDepartment()
			}
		}
	},
	mounted () {
		console.log(this.currentLabel)
		this.getDepartmentList()
	}

}
</script>

<style>
.department-scrollable {
        height: 42vh;
        width: 42vw;
        background: #fff;
        overflow-y: auto;
        overflow-x: hidden;
    }
</style>