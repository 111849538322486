
<template>
	<div class="success-content">
		<div v-if="logoPath" class="auth-logo">		
			<b-row class="mb-3">
				<img :src="logoPath">   
			</b-row>   
		</div>
		<div class="success-modal">
			<div class="forgot-title mb-2 custom-forgot">
				<p>
					Você receberá a sua nova senha temporária no e-mail <b>{{email}}</b><br>
					Por favor, providencie a alteração da senha assim que fizer o login.
				</p>
			</div>
			<button @click="sendNewPassword" class="mt-2 mb-2 again-button">Enviar novamente</button>  
			<button class="mt-2 mb-2" @click="logIn">Fazer o login</button> 
		</div>
	</div>
</template>

<script>
import WUserAPI from '@/api/WUserAPI'
export default {
	name: 'ForgotSucces',
	data () {
		return {
			email: '',
			logoPath:this.$store.getters.getIconMediaId,
		}
	},
	methods: {
		sendNewPassword () {
			localStorage.setItem('__mk_forgot_password_email',this.email)
			WUserAPI.resetPassword( this.email)
			this.$router.push({ path: '/forgot/success'})              
		},
		logIn () {
			this.$router.push({ path: '/'}) 
		}
	},
	mounted () {
		this.email = localStorage.getItem('__mk_forgot_password_email')
	}
}
</script>


<style scoped>
	.custom-forgot {
	/* 	align-content: center !important;	
		line-height: 21,79px !important;
		font-family: Open Sans !important;
		font-style: normal !important;
		font-weight: 400 !important;
		font-size: 16px !important;
		color: #000000 !important; */
		font-weight: normal !important;
    width: 400px;
		text-align: left !important;
	}
	.custom-forgot2 {
		align-content: center !important;	
		line-height: 21,79px !important;
		font-family: Open Sans !important;
		font-style: normal !important;
		font-weight: 600 !important;
		font-size: 16px !important;
		color: #000000 !important;
	}
	
	.auth-logo {
		width: 50%;
    padding: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
	}
  .success-content {
    margin: 0;
    height: 100vh;
    Width: 100vw;
    background-color: var(--secondary);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .success-modal {
    background-color: #F4F5F8;
    width: 50%;
    height: 100%;
    padding: 35px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
		justify-content: center;
		border-radius: 50px 0 0 50px;
  }

  .success-modal button {
    background-color: var(--primary);
    color: #FFF;
    width: 100%;
    max-width: 365px;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat','HelveticaNeue','Helvetica Neue','sans-serif';
    font-size:18px;
		color:white;    
    font-weight: 500;  
    cursor: pointer;
    padding: 8px 40px;
    border-radius: 30px;  
    border: none;
    margin-left: -3px;          
  }

	.again-button {
		background-color: #fff !important;
		border: 1px solid var(--primary) !important;
		color: var(--primary) !important;
	}

	.again-button:hover {
		background-color: var(--primary) !important;
		border: 1px solid var(--primary) !important;
		color: #fff !important;
	}

	.success-modal button:hover{
		opacity: .7;
	}

  .success-modal a {
    margin-top: 5px;
    font-size: 16px;
    font-weight: 500;
  }

  .email {
    color: gray;
    font-weight: 500; 
  }

  hr.forgot {
    border: 1px solid gray;
    width: 260px;
  }

	@media only screen and (max-width: 768px) {

		.auth-logo img {
			width: 280px;
		}

		.success-content {
			flex-direction: column;
		}

		.success-modal {
			width: 100%;
			height: 75%;
			border-radius: 50px 50px 0 0 ; 
		}

		.privacy {
			top: 95%;
		}
	}

	@media only screen and (max-width: 425px) {
		.custom-forgot {
			width: 275px;
			font-size: 16px;
		}

		.success-modal button {
			max-width: 275px;
		}
	}
</style>
