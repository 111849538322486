<template>
	<div class="template-data-scrollable scrollbar" style="margin-left:30px;"  >
		<b-row >		
			<b-col md="8">
				<b-overlay :show="loading" variant="light"
					no-wrap>
					<template #overlay>
						<b-spinner style="width: 3rem; height: 3rem; color: var(--secondary)" label="Large Spinner"></b-spinner>
					</template>                    
				</b-overlay>         				
				<b-col class="screen-template-custom2 mt-2">
					<b-row class="mb-0 mt-3">    
						<b-col md="0" class="ml-5">
							<span class="icon-clickable" @click="goBack">
								<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect width="40" height="40" rx="8" fill="#F5F5F5"/>
									<path d="M18.2622 18.7135L23.9969 13.0243C24.6018 12.4292 24.6097 11.4692 24.0146 10.8643C23.4433 10.2836 22.4594 10.2756 21.8547 10.8467L15.0549 17.6312C14.9825 17.6786 14.9339 17.7502 14.9095 17.798C14.8615 17.7977 14.8611 17.8457 14.8611 17.8457C14.8128 17.8933 14.788 17.9891 14.7394 18.0607C14.7392 18.0847 14.7152 18.0845 14.715 18.1085C14.6904 18.1803 14.69 18.2283 14.6654 18.3001C14.6652 18.3241 14.617 18.3477 14.6166 18.3957C14.6162 18.4437 14.6157 18.5157 14.6153 18.5637C14.6151 18.5877 14.5905 18.6595 14.5903 18.6835C14.5897 18.7555 14.6135 18.7797 14.6131 18.8277C14.6127 18.8757 14.6123 18.9237 14.6117 18.9956C14.6115 19.0196 14.6593 19.044 14.6587 19.116C14.6825 19.1402 14.682 19.2122 14.7056 19.2604C14.7054 19.2844 14.729 19.3326 14.7768 19.357C14.8002 19.4292 14.7998 19.4772 14.8472 19.5496C14.8472 19.5496 14.895 19.574 14.9188 19.5982C14.9422 19.6704 14.9658 19.7186 15.0376 19.7432L21.7254 26.6381C22.035 26.9287 22.4178 27.0758 22.8258 27.0792C23.2098 27.0823 23.571 26.9412 23.8853 26.6558C24.4902 26.0607 24.4979 25.1248 23.903 24.4959L18.2622 18.7135Z" fill="#414141"/>
								</svg>
							</span>
						</b-col>                     
						<b-col>
							<b-row class="ml-2">  
								<h3 class="lbl-title">{{title}}</h3>           
							</b-row>
						</b-col>
					</b-row>
				</b-col>
				<b-col class="screen-template-custom">
					<b-row class="ml-4">
						<span id="templateNameId" class="custom-font-open-16-gray-600-3">{{templateName}}<span class="icon-clickable" v-b-tooltip.hover title="Clique para copiar a chave" @click="copy('templateNameId')">&nbsp;<img src="@/assets/copy.png"/></span>   </span>
						<span class="custom-font-open-16-gray-600-3 ml-5">Idioma: <span class="custom-font-open-16-gray-normal">Português (BR)</span></span>
						<span class="custom-font-open-16-gray-600-3 ml-5">Categoria: <span class="custom-font-open-16-gray-normal">{{templateCategory}}</span></span>
					</b-row>
					<b-row class="mt-1 ml-4">
						<span class="mt-3 custom-font-open-16-gray-600-3">Departamentos vinculados:</span>
						<span v-if="departmentAll == true" class="department-custom-font ml-2 mt-2">
							Todos os Departamentos                         
						</span>
						<span v-else v-for="(x, index) in departmentNameList" :key="index" class="department-custom-font ml-2 mt-2">
							{{x}}	                         
						</span>
					</b-row>					
					<hr>
					<div class="mt-2 ml-3" :class="[ this.$route.params.mode == 'view' ? 'disable' : '' ]" >
						<b-row class="ml-1">          
							<span class="custom-font-open-16-gray-600-3 ">Cabeçalho</span>  
							<span class="custom-font-open-12-gray mt-1 ml-1">Opcional</span>                   
						</b-row>   
						<b-row class="ml-1">
							<span class="custom-font-open-14-400-gray ">Adicione um título ou escolha o tipo de mídia que você usará para este cabeçalho.</span>                
						</b-row>   
						<b-row>							
							<b-col md="9">
								<b-row>
									<b-col>
										<b-dropdown id="dropdown-grouped" variant="light" :text="currentFormat" class="custom-font-open-12 ml-2" >
											<b-dropdown-item @click="updateFormat(1)" > 	                 
												<span class="custom-font-open-12 ml-2" >Nenhum</span>                            
											</b-dropdown-item>
											<b-dropdown-item @click="updateFormat(2)" > 	                 
												<span class="custom-font-open-12 ml-2" >Texto</span>                            
											</b-dropdown-item>
											<b-dropdown-item @click="updateFormat(3)"> 	                 
												<span class="custom-font-open-12 ml-2" >Imagem</span>                            
											</b-dropdown-item> 
											<b-dropdown-item @click="updateFormat(4)"> 	                 
												<span class="custom-font-open-12 ml-2" >Vídeo</span>                            
											</b-dropdown-item> 
											<b-dropdown-item @click="updateFormat(5)"> 	                 
												<span class="custom-font-open-12 ml-2" >Documento</span>                            
											</b-dropdown-item>  
										</b-dropdown>												
									</b-col>
									<b-col>
										<b-row class="justify-content-end" v-if="titleText">
											<template-properties-modal v-on:sendMessageData="getMessageDataHeader" class="mr-2"/>	
										</b-row>
									</b-col>
								</b-row>										
								<b-row v-show="titleText" class="ml-0">
									<b-form-input v-model="previewTitle" v-on:keypress="stopKeypressEvent($event)" placeholder="Digite aqui" id="header" class="mt-2" 
										@keyup="updatePreviewTitle" 
										@keyup.delete="removeAttributeHeader()"
										@paste="onPasteHeader"
										@paste.prevent
									></b-form-input> 
									<span class="header-input-count">{{charactersHeaderCount}}/60</span>  
								</b-row>
								<b-row class="ml-0">
									<b-alert :show="warningHeader" variant="warning" class="mt-2 emoji-alert" dismissible @dismissed="warningHeader=false">Emojis não são permitidos em cabeçalhos!</b-alert>
								</b-row>
										
								<div v-show="titleImage">									
									<label for="uploadFileImage" style="cursor: pointer; display: inline;">	
										<input id="uploadFileImage" type="file" accept="image/*" @change="filesChange($event.target.files); fileCount = $event.target.files.length"
											class="input-file">
										
										<b-button variant="ligth" class="mr-2 mt-2">					
											<span class="mr-2">
												<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M13.9794 7.57039L7.85273 13.6971C7.10217 14.4476 6.08419 14.8693 5.02273 14.8693C3.96128 14.8693 2.94329 14.4476 2.19273 13.6971C1.44217 12.9465 1.02051 11.9285 1.02051 10.8671C1.02051 9.8056 1.44217 8.78762 2.19273 8.03706L8.3194 1.91039C8.81977 1.41001 9.49843 1.12891 10.2061 1.12891C10.9137 1.12891 11.5924 1.41001 12.0927 1.91039C12.5931 2.41076 12.8742 3.08942 12.8742 3.79706C12.8742 4.50469 12.5931 5.18335 12.0927 5.68372L5.9594 11.8104C5.70921 12.0606 5.36988 12.2011 5.01607 12.2011C4.66225 12.2011 4.32292 12.0606 4.07273 11.8104C3.82254 11.5602 3.68199 11.2209 3.68199 10.8671C3.68199 10.5132 3.82254 10.1739 4.07273 9.92372L9.73273 4.27039" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
												</svg>
											</span>
											{{this.mediaName}}
										</b-button>              
									</label>	
									
									<span style="z-index: 1;position:absolute; cursor:pointer;margin-top:12px" class="icon-clickable" @click="deleteMedia('uploadFileImage')" v-if="file != null">
										<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M3 4H4.33333H15" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M6.3335 4.00004V2.66671C6.3335 2.31309 6.47397 1.97395 6.72402 1.7239C6.97407 1.47385 7.31321 1.33337 7.66683 1.33337H10.3335C10.6871 1.33337 11.0263 1.47385 11.2763 1.7239C11.5264 1.97395 11.6668 2.31309 11.6668 2.66671V4.00004M13.6668 4.00004V13.3334C13.6668 13.687 13.5264 14.0261 13.2763 14.2762C13.0263 14.5262 12.6871 14.6667 12.3335 14.6667H5.66683C5.31321 14.6667 4.97407 14.5262 4.72402 14.2762C4.47397 14.0261 4.3335 13.687 4.3335 13.3334V4.00004H13.6668Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M7.6665 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M10.3335 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</span> 
									
								</div>
								<div v-show="titleVideo">
									<label for="uploadFileVideo" style="cursor: pointer; display: inline;">
										
										<b-button variant="ligth" class="mr-2 mt-2">
											<input id="uploadFileVideo" type="file" accept="video/*" @change="filesChange($event.target.files); fileCount = $event.target.files.length"
												class="input-file">
											<span class="mr-2">
												<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M13.9794 7.57039L7.85273 13.6971C7.10217 14.4476 6.08419 14.8693 5.02273 14.8693C3.96128 14.8693 2.94329 14.4476 2.19273 13.6971C1.44217 12.9465 1.02051 11.9285 1.02051 10.8671C1.02051 9.8056 1.44217 8.78762 2.19273 8.03706L8.3194 1.91039C8.81977 1.41001 9.49843 1.12891 10.2061 1.12891C10.9137 1.12891 11.5924 1.41001 12.0927 1.91039C12.5931 2.41076 12.8742 3.08942 12.8742 3.79706C12.8742 4.50469 12.5931 5.18335 12.0927 5.68372L5.9594 11.8104C5.70921 12.0606 5.36988 12.2011 5.01607 12.2011C4.66225 12.2011 4.32292 12.0606 4.07273 11.8104C3.82254 11.5602 3.68199 11.2209 3.68199 10.8671C3.68199 10.5132 3.82254 10.1739 4.07273 9.92372L9.73273 4.27039" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
												</svg>
											</span>
											{{this.mediaName}}
										</b-button>              
									</label>
									<span style="z-index: 1;position:absolute; cursor:pointer;margin-top:12px" class="icon-clickable" @click="deleteMedia('uploadFileVideo')" v-if="file != null">
										<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M3 4H4.33333H15" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M6.3335 4.00004V2.66671C6.3335 2.31309 6.47397 1.97395 6.72402 1.7239C6.97407 1.47385 7.31321 1.33337 7.66683 1.33337H10.3335C10.6871 1.33337 11.0263 1.47385 11.2763 1.7239C11.5264 1.97395 11.6668 2.31309 11.6668 2.66671V4.00004M13.6668 4.00004V13.3334C13.6668 13.687 13.5264 14.0261 13.2763 14.2762C13.0263 14.5262 12.6871 14.6667 12.3335 14.6667H5.66683C5.31321 14.6667 4.97407 14.5262 4.72402 14.2762C4.47397 14.0261 4.3335 13.687 4.3335 13.3334V4.00004H13.6668Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M7.6665 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M10.3335 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</span> 
								</div>
								<div v-show="titleDocument">
									<label for="uploadFileDocument" style="cursor: pointer; display: inline;">
										
										<b-button variant="ligth" class="mr-2 mt-2">
											<input id="uploadFileDocument" type="file" @change="filesChange($event.target.files); fileCount = $event.target.files.length"
												class="input-file">
											<span class="mr-2">
												<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M13.9794 7.57039L7.85273 13.6971C7.10217 14.4476 6.08419 14.8693 5.02273 14.8693C3.96128 14.8693 2.94329 14.4476 2.19273 13.6971C1.44217 12.9465 1.02051 11.9285 1.02051 10.8671C1.02051 9.8056 1.44217 8.78762 2.19273 8.03706L8.3194 1.91039C8.81977 1.41001 9.49843 1.12891 10.2061 1.12891C10.9137 1.12891 11.5924 1.41001 12.0927 1.91039C12.5931 2.41076 12.8742 3.08942 12.8742 3.79706C12.8742 4.50469 12.5931 5.18335 12.0927 5.68372L5.9594 11.8104C5.70921 12.0606 5.36988 12.2011 5.01607 12.2011C4.66225 12.2011 4.32292 12.0606 4.07273 11.8104C3.82254 11.5602 3.68199 11.2209 3.68199 10.8671C3.68199 10.5132 3.82254 10.1739 4.07273 9.92372L9.73273 4.27039" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
												</svg>
											</span>
											{{this.mediaName}}
										</b-button>              
									</label>
									<span style="z-index: 1;position:absolute; cursor:pointer;margin-top:12px" class="icon-clickable" @click="deleteMedia('uploadFileDocument')" v-if="file != null">
										<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M3 4H4.33333H15" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M6.3335 4.00004V2.66671C6.3335 2.31309 6.47397 1.97395 6.72402 1.7239C6.97407 1.47385 7.31321 1.33337 7.66683 1.33337H10.3335C10.6871 1.33337 11.0263 1.47385 11.2763 1.7239C11.5264 1.97395 11.6668 2.31309 11.6668 2.66671V4.00004M13.6668 4.00004V13.3334C13.6668 13.687 13.5264 14.0261 13.2763 14.2762C13.0263 14.5262 12.6871 14.6667 12.3335 14.6667H5.66683C5.31321 14.6667 4.97407 14.5262 4.72402 14.2762C4.47397 14.0261 4.3335 13.687 4.3335 13.3334V4.00004H13.6668Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M7.6665 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M10.3335 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</span> 
								</div>																	
							</b-col>										
						</b-row>			
						<div class="w-100">
							<hr style="border-color:#F5F5F5">
						</div>
						<b-row class="mt-3 ml-1">   
							<span v-if="actionType !=1" class="custom-font-open-16-gray-600-3" >Corpo da mensagem</span>                        
						</b-row>
						<b-row class="mt-1 ml-1">                        
							<span class="custom-font-open-14-400-gray">Insira o texto da sua mensagem no idioma selecionado.</span> 
						</b-row>
						<b-row>            
							<b-col md="4">
								<b-row >
									<b-button-toolbar var >
										<b-button-group> 
											<b-button title="Emoji" style="margin-top: -3px;" class="btn-text-style">             
												<b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
													<template #button-content>
														<span class="icon-clickable" @click="openEmojiPick()"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M6 10.5C6 10.5 7.125 12 9 12C10.875 12 12 10.5 12 10.5" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M6.75 6.75H6.7575" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M11.25 6.75H11.2575" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														</svg></span> 
													</template>
													<div style="z-index:99; position: absolute; top:100px;">
														<VEmojiPicker @select="selectEmoji" 
															:emojiSize="22" 
															:emojiWithBorder="false"
															:emojisByRow="10"/>                     
													</div>    
												</b-dropdown>                                                  
											</b-button>                    
											<b-button v-if="bold == false" title="Bold" @click="setBoldText" class="btn-text-style">
												<b-icon icon="type-bold" aria-hidden="true"></b-icon>
											</b-button>
											<b-button v-else title="Bold" ref="bold" @click="setUnBoldText" class="btn-text-style">
												<b-icon icon="type-bold" aria-hidden="true" variant="dark"></b-icon>
											</b-button>

											<b-button v-if="italic == false" title="Italic" @click="setItalicText" class="btn-text-style">
												<b-icon icon="type-italic" aria-hidden="true"></b-icon>
											</b-button>
											<b-button v-else title="Italic" ref="italic" @click="setUnItalicText" class="btn-text-style">
												<b-icon icon="type-italic" aria-hidden="true" variant="dark"></b-icon>
											</b-button>

											<b-button v-if="strike == false" title="Strikethrough"  @click="setStrikeText" class="btn-text-style">
												<b-icon icon="type-strikethrough" aria-hidden="true"></b-icon>
											</b-button>
											<b-button v-else title="Strikethrough" ref="strike" @click="setUnStrikeText" class="btn-text-style">
												<b-icon icon="type-strikethrough" aria-hidden="true" variant="dark"></b-icon>
											</b-button>   											
										</b-button-group>
									</b-button-toolbar>  
								</b-row>                             
							</b-col>    
							<b-col md=5>
								<b-row class="justify-content-end">
									<template-properties-modal v-on:sendMessageData="getMessageData" class=""/>	
								</b-row>			
							</b-col>       
						</b-row>  
						<b-row>
							<b-col md=9>
								<b-row class="justify-content-end">
									<span class="textarea-input-count">{{charactersBodyCount}}/1024</span>                     										
								</b-row>
							</b-col>
						</b-row>
						<b-row>
							<b-col md=9>						              
								<b-row style="margin-left: 0;">								
									<div         
										ref="textArea"         
										class="editable ml-1"
										id="textArea"
										:onfocus="updatePreviewBody"
										contenteditable="true"
										placeholder="Insira uma mensagem"
										style="white-space: pre-line;word-break: break-word;"
										@keydown="insertLineBreak"
										@keyup="updatePreviewBody"
										@paste="onPaste"
										@paste.prevent
										@click="onClick"
										@keyup.delete="removeAttribute()"	
										v-on:keypress="stopKeypressEventBody($event)"
									>   
									</div> 
									<b-alert  v-if="warningBody == true" variant="danger" class="ml-1 mt-1 emoji-alert" show>Foi excedido o limite de caracteres no Corpo da Mensagem!</b-alert>
								</b-row> 
							</b-col>
						</b-row>
						<div class="w-100">
							<hr style="border-color:#F5F5F5">
						</div>
						<b-row class="ml-1">
							<b-col md=9>
								<b-row class="">   
									<span class="custom-font-open-16-gray-600-3">Rodapé</span>    
									<span class="custom-font-open-12-gray mt-1 ml-1">Opcional</span>                      
								</b-row>		
								<b-row class="">                        
									<span class="custom-font-open-14-400-gray">Adicione uma linha curta de texto na parte inferior do seu modelo de mensagem.</span> 
								</b-row>
								<b-row class=" mt-1">
									<b-form-input v-model="previewFooter" maxlength="60" placeholder="Digite aqui" class="" @paste="onPasteFooter($event)" @paste.prevent></b-form-input>          
									<span class="footer-input-count">{{previewFooter.length}}/60</span>   
								</b-row>   
								<b-row>
									<b-alert :show="warningFooter" variant="warning" class="mt-2 emoji-alert" dismissible @dismissed="warningFooter=false">Emojis não são permitidos em rodapés!</b-alert>
								</b-row>  													
								
							</b-col>
						</b-row>
						<div class="w-100">
							<hr style="border-color:#F5F5F5"> 
						</div>
					
						
						<b-col>
							<b-row class="ml-1">   
								<span class="custom-font-open-16-gray-600-3">Botões</span>    
								<span class="custom-font-open-12-gray mt-1 ml-1">Opcional</span>                      
							</b-row>
							<b-row class="ml-1">      								
								<span class="custom-font-open-14-400-gray">{{buttonTittle}}</span> 
							</b-row>    
						</b-col> 
						<b-col>
							<div>
								<b-dropdown id="dropdown-grouped2" variant="light" :text="currentButton" class="custom-font-open-12" >
									<b-dropdown-item @click="updateButton(0)"> 	                 
										<span class="custom-font-open-12 ml-2" >Nenhum</span>                            
									</b-dropdown-item> 
									<b-dropdown-item @click="updateButton(1)"> 	                 
										<span class="custom-font-open-12 ml-2" >Resposta Pronta</span>                            
									</b-dropdown-item> 	
									<b-dropdown-item @click="updateButton(2)"> 	                 
										<span class="custom-font-open-12 ml-2" >Botão de link</span>                            
									</b-dropdown-item> 
									
								</b-dropdown>
							</div>							
							<b-collapse v-model="buttonSelectLink" class="mt-3">    
								<b-row>    
									<b-col md=3 class="">
										<b-dropdown variant="light" :text="currentButtonType" class="custom-font-open-12">   
											<b-dropdown-item @click="updateButton(4)"> 	                 
												<span class="custom-font-open-12 ml-2" >Visitar site</span>                            
											</b-dropdown-item> 	
											<b-dropdown-item @click="updateButton(5)"> 	                 
												<span class="custom-font-open-12 ml-2" >Ligar para número</span>                            
											</b-dropdown-item> 
										</b-dropdown>
									</b-col>
									<b-col md="6">
										<b-row>
											<b-form-input @paste="onPasteButton($event,undefined)" @paste.prevent v-model="buttonLinkText" maxlength="20" class="ml-2" placeholder="Digite aqui" ></b-form-input>  
											<b-collapse v-model="buttonSelectNumber">   
												<b-form-input v-model="buttonLinkData" maxlength="60" class="mt-2 ml-2"  placeholder="Exemplo: +5547984124491" ></b-form-input>   						
											</b-collapse> 
										</b-row>
									</b-col>
								</b-row>								
								<div v-if="buttonSelectSite">
									<b-row>
										<b-col md="3">
											<b-dropdown variant="light" :text="currentButtonLink" class="custom-font-open-12 mt-2" >   
												<b-dropdown-item @click="updateButton(6)"> 	                 
													<span class="custom-font-open-12 " >Link estático</span>                            
												</b-dropdown-item> 	
												<b-dropdown-item @click="updateButton(7)"> 	                 
													<span class="custom-font-open-12 " >Link dinâmico</span>                            
												</b-dropdown-item> 
											</b-dropdown>
										</b-col>
										<b-col md="6">
											<b-row class="mt-2 justify-content-end">
												<b-form-input v-if="!buttonSelectDynamic" :placeholder = textAreaPlaceHolder v-model="buttonLinkData" ref="linkData" class="input size19 field ml-2" maxlength="2000"></b-form-input>
												<b-form-input v-else :placeholder = textAreaPlaceHolder v-model="buttonLinkData" ref="linkData" @keyup="updateDataLink"  class="input size19 field ml-2" maxlength="2000"></b-form-input>
												<span class="button-link-input-count">{{charactersButtonLinkCount}}/2000</span>					
											</b-row>
										</b-col>
										<b-col md=3>
											<div v-if="buttonSelectDynamic == true" class="mt-2 " >
												<template-properties-modal v-on:sendMessageData="getAtributeLink" class="ml-2" />
											</div>
										</b-col>
									</b-row>	
								</div>                        
							</b-collapse>							
							<b-collapse v-model="buttonSelectReady">                           
								<div class="w-50 ml-1" style="margin-left: -15px;">
									<span class="custom-font-open-12-gray">Máximo 20 caracteres</span>
									<b-row v-for="(x, index) in buttonList" :key="index" class="mt-1">
										<b-col md="10">
											<b-form-input @paste="onPasteButton($event,x)" @paste.prevent v-model="x.text" maxlength="20" placeholder="Texto do botão"></b-form-input>
										</b-col>                    
										<b-col class="mt-2">
											<span class="icon-clickable" @click="removeItemButton(index)" v-show="index > 0">
												<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M3 4H4.33333H15" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M6.3335 4.00004V2.66671C6.3335 2.31309 6.47397 1.97395 6.72402 1.7239C6.97407 1.47385 7.31321 1.33337 7.66683 1.33337H10.3335C10.6871 1.33337 11.0263 1.47385 11.2763 1.7239C11.5264 1.97395 11.6668 2.31309 11.6668 2.66671V4.00004M13.6668 4.00004V13.3334C13.6668 13.687 13.5264 14.0261 13.2763 14.2762C13.0263 14.5262 12.6871 14.6667 12.3335 14.6667H5.66683C5.31321 14.6667 4.97407 14.5262 4.72402 14.2762C4.47397 14.0261 4.3335 13.687 4.3335 13.3334V4.00004H13.6668Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M7.6665 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M10.3335 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
												</svg>
											</span>
										</b-col>                    
									</b-row>                                    
								</div>   
								<b-row class="justify-content-start mt-2 ml-1">
									<b-button v-b-toggle.sidebar-right variant="secondary"
										@click="addNewButton()" 
										:disabled="buttonList.length > 2">
										<span class="ml-1">
											Adicionar outro botão
										</span>                        
									</b-button>                                                      
								</b-row>                                                    
							</b-collapse>
						</b-col>		
					</div>     
					<div class="w-100 ml-4 mt-3 pb-5">
						<b-button variant="outline-primary" class="mr-2"
							@click="cancelTemplate">{{modalCancelar}}
						</b-button>   
						<b-button v-if="this.$route.params.mode == 'ins'" variant="primary"  ref="finalize" class="float-right mr-5"
							@click="next" :disabled="btnDisable">Enviar           
						</b-button>  
					</div> 						                                                                                                                                                 
				</b-col>
			</b-col>
			<b-col >
				<b-row class="justify-content-center mt-5"  >
					<div class="template-preview" >
						<b-col >
							<b-row class="message-preview ml-0" :style="[(buttonSelectReady||buttonSelectLink) ? 'width:290px' : ' ' ]">	
								<div v-if="otherFileType == true">								    
									<b-row class="message-file-preview ml-2">
										<b-col class="d-flex justify-content-start">
											<span><img src="@/assets/file.png" height="25"></span> 
											<span class="ml-3 mt-1" 
												style="font-family: Roboto; font-style: normal; font-weight: normal; font-size: 14px;line-height: 19px;">
												{{mediaName}}</span>                            
										</b-col> 
										<b-col md="1" class="d-flex justify-content-end mt-1">                         
											<span><img src="@/assets/download.png" height="25"></span>
										</b-col>                                         
									</b-row>                                                   
								</div>   												
								<div v-else>	
									<span v-show="mediaType == 2" class="wpp-preview-media-text">{{previewTitle}}</span> 	
									<b-col v-show="mediaType > 2" class="wpp-preview-media">	
										<span v-show="mediaType == 3 && mediaLink == ''" >
											<svg  class="wpp-preview-media-size" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M3.44444 23H20.5555C21.9056 23 23 21.9056 23 20.5556V3.44444C23 2.09441 21.9056 1 20.5555 1H3.44444C2.09441 1 1 2.09441 1 3.44444V20.5556C1 21.9056 2.09441 23 3.44444 23ZM3.44444 23L16.8889 9.55548L23 15.6666M9.55551 7.72237C9.55551 8.73489 8.7347 9.55571 7.72218 9.55571C6.70966 9.55571 5.88885 8.73489 5.88885 7.72237C5.88885 6.70985 6.70966 5.88904 7.72218 5.88904C8.7347 5.88904 9.55551 6.70985 9.55551 7.72237Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											</svg>
										</span>
										<img v-show="mediaType == 3 && mediaLink != ''" class="wpp-preview-media-size" :src="this.mediaLink"> 
										<span v-show="mediaType == 4 && mediaLink == ''" >
											<svg width="33" height="32" viewBox="0 0 33 32" fill="none" class="wpp-preview-media-size"  xmlns="http://www.w3.org/2000/svg">
												<path d="M31.8149 6.6486C31.8149 6.18884 31.2466 5.97279 30.9412 6.31641L22.6286 15.668C22.4603 15.8574 22.4603 16.1429 22.6286 16.3323L30.9412 25.6839C31.2466 26.0275 31.8149 25.8115 31.8149 25.3517V6.6486Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
												<path d="M19.087 5.3335H3.78261C2.24582 5.3335 1 6.63594 1 8.24259V22.788C1 24.3947 2.24582 25.6971 3.78261 25.6971H19.087C20.6237 25.6971 21.8696 24.3947 21.8696 22.788V8.24259C21.8696 6.63594 20.6237 5.3335 19.087 5.3335Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											</svg>
										</span>
										<video v-show="mediaType == 4 && mediaLink != ''" controls :src="this.mediaLink" class="wpp-preview-media-size"> </video>
										<span v-show="mediaType == 5 && mediaLink == ''">
											<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="wpp-preview-media-size">
												<path d="M17.3334 2.6665H8.00004C7.2928 2.6665 6.61452 2.94746 6.11442 3.44755C5.61433 3.94765 5.33337 4.62593 5.33337 5.33317V26.6665C5.33337 27.3737 5.61433 28.052 6.11442 28.5521C6.61452 29.0522 7.2928 29.3332 8.00004 29.3332H24C24.7073 29.3332 25.3856 29.0522 25.8857 28.5521C26.3858 28.052 26.6667 27.3737 26.6667 26.6665V11.9998L17.3334 2.6665Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
												<path d="M17.3334 2.6665V11.9998H26.6667" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											</svg>
										</span>	
										<div v-show="mediaType == 5 && mediaLink != ''">
											<iframe id="iframePreview" src="" class="wpp-preview-media-size"></iframe>											
										</div>											
									</b-col>
								</div>					
								<div>
									<div class='' style="padding-left:5px;">
										<div >
											<article>
												<span id="previewBody" class="custom-font-open-14-400-gray" style="white-space: pre-line;word-break: break-word;">
													
												</span>								
											</article>							
										</div>       
									</div>
								</div>					
								<div>
									<span class="custom-font-open-12-gray ml-1" style="">{{previewFooter}}</span>
									<span class="footer-preview">3:49 PM</span>
								</div>
							</b-row>
						</b-col>
						<div v-if="buttonList.length > 0">
							<b-row class="justify-content-center" v-if="buttonsAmount == 1 && buttonType == 1">		
								<button v-show="true" class="template-button-preview2">{{buttonList[0].text}}</button>			
							</b-row>
							<b-row class="justify-content-center" v-if="buttonsAmount == 2 && buttonType == 1">		
								<button v-show="true" class="template-button-preview2">{{buttonList[0].text}}</button>	
								<button v-show="true" class="template-button-preview2">{{buttonList[1].text}}</button>			
							</b-row>
							<b-row  class="justify-content-center" v-if="buttonsAmount == 3 && buttonType == 1">
								<button  v-show="true" class="template-button-preview2">{{buttonList[0].text}}</button>	
								<button  v-show="true" class="template-button-preview2">{{buttonList[1].text}}</button>			
								<button  v-show="true" class="template-button-preview2">{{buttonList[2].text}}</button>	
							</b-row>
							<b-row class="justify-content-center" v-show="buttonLink">									
								<button class="template-button-preview2">
									<span v-if="buttonFormat == 4 || buttonFormat == 6 || buttonFormat == 7">
										<svg class="mr-1" width="15px" height="16px" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9.5 1H3C1.89543 1 1 1.89543 1 3V19.5C1 20.6046 1.89543 21.5 3 21.5H19C20.1046 21.5 21 20.6046 21 19.5V12.5M13 1H21M21 1V9M21 1L6 16" stroke="#34B7F1" stroke-width="2"/>
										</svg>
									</span>
									<img  v-if="buttonSelectNumber == true" class="mr-1" style="width:15px; height:16px;" src="@/assets/iconCall.png">
									{{buttonLinkText}}</button>			
							</b-row>
						</div>
					</div>
				</b-row>
			</b-col>
		</b-row>	
		<div>
			<template-modal-cancel
				:subTitle = "'Você deseja sair sem enviar seu template para aprovação?'"
				:description = "''"
				:description1= "''"
				:value = "''"
				:placeholder = "''"
				:button1 = "'Fechar'"
				:button2 = "'Sair'"
				:start="showModalCancel"   
				v-on:sendModalData="postCancel"
				:key="componentCancelModalKey"                        
			/>
		</div> 
	</div>
</template>
<script>
import WTemplatesAPI from '@/api/WTemplatesAPI'
import WTemplateProperties from './WTemplateProperties'
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import { baseMediaURL } from '@/global'	
import WModal from '@/views/WModal'
import WMessageAPI from '@/api/WMessageAPI'
export default {    
	name: 'WTemplateFinalize',   
	components: {
		'template-properties-modal': WTemplateProperties,
		'template-modal-cancel': WModal,
	},	
	data: function () {
		return {
			loading: false,
			currentFormat: 'Nenhum',
			currentButton: 'Nenhum',
			buttonTittle: 'Crie botões que permitem aos clientes responder à sua mensagem ou agir.',
			textAreaPlaceHolder: '',
			currentButtonType: '',
			currentButtonLink: '',
			previewBody:'',
			previewFooter:'',
			buttonSelectReady: false,
			buttonSelectDynamic: false,
			buttonSelectStatic: false,
			buttonSelectLink:false,
			buttonSelectSite:false,
			buttonSelectNumber:false,
			btnDisable: true,
			actionType: this.action,
			option: 0,
			templateId: '',
			templateDetail: {},
			showEmojiPicker: false,
			bodyMessage: '',
			buttonName: '',
			formattedText: [],
			titleText:false,
			titleImage:false,
			titleVideo:false,
			titleDocument:false,
			previewTitle:'',
			departmentIdList: [],
			mediaType: 1,
			mediaLink:'',
			height: 250,
			buttonsAmount: 0,
			buttonLink: false,
			buttonLinkData: '',
			buttonLinkText: '',			
			buttonList: [
				{buttonIndex:0 , text: ''}
			],			
			mediaName: 'Carregar Arquivo',
			file: null,
			emoji: false,
			bold: false,
			italic: false,
			strike: false,
			buttonType: 0,
			buttonFormat: 1,
			departmentId: '',
			templateName:'',
			templateCategory:'',
			title: 'Novo template',
			departmentNameList: [],
			mediaContent:'',
			departmentAll:false,
			isEmpty:false,
			propertyNameOld: '',
			buttonType2: 0,
			showModalCancel: false, 
			componentCancelModalKey: 0,
			modalCancelar: 'Cancelar',
			otherFileType: false,
			textAreaCounter:0,
			footerCounter: '',
			textAreaCount:0,
			currentAtribute: '',
			headerAreaCount: 0,	
			titlePropertyLength: 0,		
			bodyPropertyLength: 0,
			warningBody: false,
			buttonSiteCount: 0,
			buttonSitePropertyLength:0,
			warningHeader: false,
			warningFooter:false,
			focused:'',
			pos:0,
		}
	},
	computed: {
		charactersHeaderCount: function () {
			let count = this.previewTitle.length + (this.headerAreaCount*20) - this.titlePropertyLength
			return count
		},
		charactersBodyCount: function () {
			let count = this.previewBody.replace(/\s*\{.*?\}\s*/g, '').length + (this.textAreaCount*20)
			return count
		},
		charactersButtonLinkCount: function () {
			let count = this.buttonLinkData.length + (this.buttonSiteCount*20) - this.buttonSitePropertyLength
			return count
		},
	},        
	methods: {
		cursorPosition () {
			let sel = document.getSelection()
			this.focused = sel.focusNode
			this.pos = sel.focusOffset
			return this.pos
		},
		setCaretEditable (text) {
			let shouldSkip = false
			const el = document.getElementById('textArea')
			el.focus()
			let char = this.pos, sel // character at which to place caret

			if (document.selection) {
				sel = document.selection.createRange()
				sel.moveStart('character', char)
				sel.select()
			}
			else {
				sel = window.getSelection()
				let array = el.childNodes
				array.forEach((element) => {
					if(element.data == this.focused.nodeValue || element.innerText == this.focused.nodeValue && shouldSkip == false){
						if(element.data != null &&  element.data != undefined && element.data != 'undefined'){
							element.data = (element.data.slice(0, this.pos) + text + element.data.slice(this.pos))
							this.pos += 2
							this.focused= element
							sel.collapse(this.focused, this.pos)
							shouldSkip = true
							this.cursorPosition()
						}else if(element.innerText != null &&  element.innerText != undefined){
							element.innerText = (element.innerText.slice(0, this.pos) + text + element.innerText.slice(this.pos))
							this.pos += 2
							this.focused= element
							sel.collapse(this.focused.childNodes[0], this.pos)
							shouldSkip = true
							this.cursorPosition()
						}
					}
				})
				
				
				this.updatePreviewBody()	
			}
		},
		onPasteButton (event,field) {
			let regexExp = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi
			if(regexExp.test(event.clipboardData.getData('text/plain')) == true){
				alert('Emojis não podem ser usados em botões.')
				return
			}
			let text = event.clipboardData.getData('text/plain')
			if((this.previewFooter.length + text.length)> 20 ){
				alert('Botão possui um limite máximo de 20 caracteres.')
				return
			}else{
				if(!field){
					this.buttonLinkText = text
				}else{
					field.text = text
				}
			}
		},
		onPasteFooter (event) {
			let regexExp = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi
			if(regexExp.test(event.clipboardData.getData('text/plain')) == true){
				console.log('O conteudo da sua mensagem possui um emoji')
				this.warningFooter = true
				return
			}
			let text = event.clipboardData.getData('text/plain')
			if((this.previewFooter.length + text.length)> 60 ){
				alert('Rodapé possui um limite máximo de 60 caracteres.')
				return
			}else{
				this.previewFooter += text
			}
		},
		onPaste (event) {
			let text = event.clipboardData.getData('text/plain')
			document.execCommand('insertHTML', false, text)
			this.updatePreviewBody()
		},
		onPasteHeader (event) {
			let regexExp = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi
			if(regexExp.test(event.clipboardData.getData('text/plain')) == true){
				console.log('O conteudo da sua mensagem possui um emoji')
				this.warningHeader = true
				return
			}
			let text = event.clipboardData.getData('text/plain')
			if((text.match(/}/g) || []).length > 1 || ((text.match(/}/g) || []).length > 0 && this.headerAreaCount)){
				alert('Cabeçalho possui um limite máximo de 1 atributo!')
				return
			}else if(((this.previewTitle + text).length+((((this.previewTitle+text).match(/}/g) || []).length*20))-((this.previewTitle+text).replace(/.*\{|\}.*/g, '').length+2))> 60 ){
				alert('Cabeçalho possui um limite máximo de 60 caracteres. Lembrando que 1 atributo equivale a 20 caracteres')
				return
			}else{
				document.execCommand('insertHTML', false, text)
				this.updatePreviewTitle()	
			}
		},
		onClick () {
			this.cursorPosition()
			let isBold = document.queryCommandValue('bold')
			let isItalic = document.queryCommandValue('italic')
			let isStrike = document.queryCommandValue('strikethrough')
			if (isBold === 'true') {
				this.bold = true
			} else {
				this.bold = false
			}			
			if (isItalic === 'true') {
				this.italic = true
			} else {
				this.italic = false
			}	
			if (isStrike === 'true') {
				this.strike = true
			} else {
				this.strike = false
			}		
		},			
		stopKeypressEvent (event) {
			if (this.charactersHeaderCount == 60) {
				event.preventDefault()
			}			
		},
		stopKeypressEventBody (event) {
			if (this.charactersHeaderCount == 1024) {
				event.preventDefault()
			}			
		},
		getDepartmentList () {
			WDepartmentsAPI.getDepartmentsOfCompany(this.$route.params.companyId,response => {
				let departments = response.data
				if(departments.length != this.departmentIdList.length){
					departments.forEach(department =>{
						this.departmentIdList.forEach(departmentId =>{
							if (department.departmentId.includes(departmentId)){
								this.departmentNameList.push(department.departmentName)
							}
						})
					})     
				}else{
					this.departmentAll = true
				}                                    
			})
		},
		addNewButton () {
			this.buttonList.sort(this.dynamicSort('-interactionIndex'))
			let lastIndex = this.buttonList[0].buttonIndex           
			this.buttonList.push({buttonIndex: lastIndex +1, text: ''})
			this.buttonsAmount ++
		},
		addNewList () {
			this.list.sort(this.dynamicSort('-interactionIndex'))
			let lastIndex = this.list[0].buttonIndex
			this.list.push({buttonIndex: lastIndex +1, interactionTitle: '', interactionDescription: '', interactionSection: ''})
		},
		dynamicSort (property) {
			let sortOrder = 1
			if(property[0] === '-'){
				sortOrder = -1
				property = property.substr(1)
			}
			return function (a,b) {
				let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
				return result * sortOrder
			}
		},               
		removeItemButton (index) {
			this.buttonList.splice(index, 1)
			this.buttonsAmount --
		},           
		removeItemList (index) {
			this.list.splice(index, 1)
		},  
		updatePreviewBody () {
			this.cursorPosition()
			let isBold = document.queryCommandValue('bold')
			let isItalic = document.queryCommandValue('italic')
			let isStrike = document.queryCommandValue('strikethrough')
			if (isBold === 'true') {
				this.bold = true
			} else {
				this.bold = false
			}		
			if (isItalic === 'true') {
				this.italic = true
			} else {
				this.italic = false
			}	
			if (isStrike === 'true') {
				this.strike = true
			} else {
				this.strike = false
			}				
			//let teste = document.getElementById('textArea').innerText.replace(/\s*\{.*?\}\s*/g, '')
			this.btnDisable = false
			this.textAreaCount = (document.getElementById('textArea').innerText.match(/}/g) || []).length			
			this.textAreaCounter = document.getElementById('textArea').innerText
			if (this.textAreaCount > 0) {
				let propertyName = document.getElementById('textArea').innerHTML.replace(/\/^.*(([^)]*)).*$/g,'')
				propertyName = propertyName.replace(/.*\{|\}.*/g, '') 
				this.bodyPropertyLength = propertyName.length + 2
			}
			document.getElementById('previewBody').innerHTML = document.getElementById('textArea').innerHTML.replace(/\/^.*(([^)]*)).*$/g,'')
			this.previewBody = document.getElementById('textArea').innerText.replace(/\/^.*(([^)]*)).*$/g,'')
			if((this.previewBody.length + (this.textAreaCount*20) - this.bodyPropertyLength) > 1024){
				this.warningBody = true
			} else{
				this.warningBody = false
			}
		},
		updatePreviewTitle () {
			this.headerAreaCount = (this.previewTitle.match(/}/g) || []).length
			if(this.headerAreaCount > 1){
				alert('Cabeçalho possui um limite máximo de 1 atributo!')
				this.previewTitle = this.previewTitle.substr(0, this.previewTitle.length -1)
				this.updatePreviewTitle()
				return
			}
			if (this.headerAreaCount > 0) {
				let propertyName = this.previewTitle.replace(/.*\{|\}.*/g, '')
				this.titlePropertyLength = propertyName.length + 2
			}
		},
		getMessageDataHeader (obj) {	
			this.titlePropertyLength = obj.propertyName.length + 2
			if (this.previewTitle.includes('}')) {
				this.previewTitle = this.previewTitle.replace(/ *\{[^)]*\} */g, ' {'+ obj.propertyName+ '} ')
			} else {
				this.previewTitle = this.previewTitle + '{'+ obj.propertyName+ '}'												 											
			}	
			this.headerAreaCount = (this.previewTitle.match(/}/g) || []).length								
		},
		getMessageData (obj) {
			let el = document.getElementById('textArea')	
			let textValidation = el.innerText	
			if (textValidation.includes(obj.propertyName)) {
				alert('Propriedade informada em duplicidade!')
				return
			}	  
			el.innerHTML += '{'+ obj.propertyName+ '}' 
			this.textAreaCount = (document.getElementById('textArea').innerText.match(/}/g) || []).length		
			this.updatePreviewBody()   
		},
		removeAttribute () {
			if (!this.previewBody.includes('}')) { 
				this.bodyPropertyLength = 0				
			}
			this.textAreaCount = (this.previewBody.match(/}/g) || []).length
			if(this.previewBody.replace(/\s*\{.*?\}\s*/g, '').length + (this.textAreaCount*20) == 1){
				this.previewBody = ''
				document.getElementById('textArea').innerHTML = ''
			}	
		},	
		removeAttributeHeader () {
			if (!this.previewTitle.includes('}')) { 
				this.titlePropertyLength = 0				
			}
			this.headerAreaCount = (this.previewTitle.match(/}/g) || []).length				
		},
		removeAttributeButtonLink () {
			if (!this.previewBody.includes('}')) { 
				this.bodyPropertyLength = 0				
			}
			this.buttonSiteCount = (this.buttonLinkData.match(/}/g) || []).length	
		},	

		updateDataLink () {
			this.buttonSiteCount = (this.buttonLinkData.match(/}/g) || []).length	
			if (this.buttonSiteCount > 0) {
				let propertyName = this.buttonLinkData.replace(/.*\{|\}.*/g, '')
				this.buttonSitePropertyLength = propertyName.length + 2
			}
			if(this.currentAtribute != ''){
				if (this.buttonLinkData.includes('{')) {
					this.buttonLinkData = this.buttonLinkData.replace(/ *\{[^)]*\} */g, '')
					this.buttonLinkData = this.buttonLinkData + '{'+ this.currentAtribute.propertyName+ '}' 
				} else {
					this.buttonLinkData = this.buttonLinkData + '{'+ this.currentAtribute.propertyName+ '}' 
				}
			}
				
			this.$refs.linkData.focus() 
		},
		getAtributeLink (obj) {
			this.currentAtribute = obj
			if (this.buttonLinkData.includes('{')) {
				this.buttonLinkData = this.buttonLinkData.replace(/ *\{[^)]*\} */g, '')
				this.buttonLinkData = this.buttonLinkData + '{'+ obj.propertyName+ '}'
			} else {
				this.buttonLinkData = this.buttonLinkData + '{'+ obj.propertyName+ '}' 
			}
			this.updateDataLink()
			this.$refs.linkData.focus() 
		},
		setBoldText () {       
			this.bold = true
			document.execCommand('bold')                    
			if (this.italic == true){
				this.setUnItalicText()
			}          
			if (this.strike == true){
				this.setUnStrikeText()
			}       
			//	const textArea = this.$refs.textArea  
			this.$refs.textArea.focus() 
		},  
		setUnBoldText () {
			this.bold = false
			document.execCommand('bold')
			//const textArea = this.$refs.textArea  
			this.$refs.textArea.focus()            
		},
		setItalicText () {
			this.italic = true
			document.execCommand('italic')    
			if (this.bold == true){
				this.setUnBoldText()
			}          
			if (this.strike == true){
				this.setUnStrikeText()
			}                
			// const textArea = this.$refs.textArea  
			this.$refs.textArea.focus()                   
		},
		setUnItalicText () {
			this.italic = false
			document.execCommand('italic')
			// const textArea = this.$refs.textArea  
			this.$refs.textArea.focus()            
		},
		setStrikeText () {
			this.strike = true
			document.execCommand('strikeThrough')  
			if (this.bold == true){
				this.setUnBoldText()
			}                      
			if (this.italic == true){
				this.setUnItalicText()
			}            
			// const textArea = this.$refs.textArea  
			this.$refs.textArea.focus()                                  
		},
		setUnStrikeText () {
			this.strike = false
			document.execCommand('strikeThrough')  
			// const textArea = this.$refs.textArea  
			this.$refs.textArea.focus()            
		},
		selectEmoji (emoji) {
			this.setCaretEditable(emoji.data)
			if(this.bold == true){
				this.setUnBoldText()
			}
			if(this.italic == true){
				this.setUnItalicText()
			}
			if(this.strike == true){
				this.setUnStrikeText()
			}
			this.$refs.textArea.focus()
		},
		setCaret (el) {
			let range = document.createRange(),
				sel = window.getSelection(),
				lastKnownIndex = -1
			for (let i = 0; i < el.childNodes.length; i++) {
				if (this.isTextNodeAndContentNoEmpty(el.childNodes[i])) {
					lastKnownIndex = i
				}
			}
			if (lastKnownIndex === -1) {
				throw new Error('Could not find valid text content')
			}
			let row = el.childNodes[lastKnownIndex],
				col = row.textContent.length
			range.setStart(row, col)
			range.collapse(true)
			sel.removeAllRanges()
			sel.addRange(range)
			el.focus()
		},
		isTextNodeAndContentNoEmpty (node) {
			return node.nodeType == Node.TEXT_NODE && node.textContent.trim().length > 0
		},                 
		openEmojiPick () {     
			if (!this.showEmojiPicker){	
				this.showEmojiPicker = true
			} else {
				this.showEmojiPicker = false
			}            
		},   
		deleteMedia (id) {
			this.mediaName = 'Carregar Arquivo'
			this.mediaLink = ''
			this.file = null
			document.getElementById(id).value = null
			this.otherFileType = false
		},
		filesChange (file) {		
			// handle file changes
			if (!file.length) 
				return
			let size = file[0].size 
			if (size > 10485760){ 
				alert('Tamanho máximo do arquivo 10MB!')
				return
			}   
			this.mediaName = file[0].name 

			this.mediaLink = URL.createObjectURL(file[0])
			if (this.mediaType == 5) {
				let ext = this.mediaName.split('.').pop()	
				if (ext === 'pdf') {
					this.otherFileType = false
					let iframe = document.getElementById('iframePreview')			
					iframe.src = this.mediaLink							
				} else {
					this.otherFileType = true
				}			
			}					
			this.file = file[0]
			const formData = new FormData()  
			formData.append('body', 'Teste')                      
			formData.append('filename', this.mediaName)
			formData.append('file', this.file)  			                      
			WTemplatesAPI.postUploadMedia(this.$route.params.companyId, this.$route.params.templateId, formData, response => {
				if (response){                                                   
					console.log('Operação realizada com sucesso!')                                                               
				} else {                 
					alert('Falha no upload do arquivo!')
				}
			})                            																					
		},   
		copy (key) {             
			let element = document.getElementById(key)    
			this.selectText(element)            
			document.execCommand('copy')
			try {
				this.makeToast(true)
			} catch (err){
				alert('Erro ao copiar a chave')
			}         
		},
		selectText (element) {
			let range
			if (document.selection){
				range = document.body.createTextRange()
				range.moveToElementText(element)
				range.select()
			} else if (window.getSelection){
				range = document.createRange()
				range.selectNode(element)
				window.getSelection().removeAllRanges()
				window.getSelection().addRange(range)
			}
		},
		makeToast (append = false) {
			this.toastCount++
			const msg = 'Nome do template copiado para area de transferência'
			this.$bvToast.toast(msg, {
				title: 'Token da chave',
				autoHideDelay: 2000,
				appendToast: append,
				toaster: 'b-toaster-bottom-center',
				variant: 'default',
				solid: true
			})
		}, 
		convertHtmlToText (str) {
			str = str.toString()
			return str.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '')
		},      
		replaceMulti (haystack) {
			if (haystack != undefined){
				return haystack.split('\\n').join('<br>')                
			}
		},
		insertLineBreak (event) {
			if (event.key === 'Enter'){
				document.execCommand('insertLineBreak')
				event.preventDefault()
			}
		},
		getTemplateDetail () {
			this.loading = true
			WTemplatesAPI.getTemplateDetail(this.$route.params.companyId, this.$route.params.templateId, response => {
				this.templateDetail = response.data   
				if (this.$route.params.mode == 'view') {
					this.title = 'Visualizando template'
					this.updateFormat(this.templateDetail.headerType)
				}
				if (this.templateDetail.headerType != 5) {
					this.loading = false					
				}
				this.departmentIdList = this.templateDetail.departmentIdList
				this.previewTitle = this.templateDetail.header
				this.bodyMessage = this.templateDetail.body
				this.templateName = this.templateDetail.templateDescription
				this.templateCategory = this.templateDetail.typeDescription
				this.mediaName = this.templateDetail.mediaName
				this.mediaContent = ''
				if (!this.mediaName.includes('pdf')) {
					if (this.templateDetail.mediaId != '' && this.mediaName != '') {
						this.mediaContent = baseMediaURL + this.$route.params.companyId + '&mediaId='+ this.templateDetail.mediaId
					}					
				}				
				this.buttonsAmount = this.templateDetail.templateButtonList.length				
				let el = document.getElementById('textArea')
				el.innerHTML = this.replaceMulti(this.bodyMessage, '\\n', '<br>' )               				
				this.previewFooter = this.templateDetail.footer  
				this.updatePreviewBody()
				switch (this.templateDetail.templateButtonList.length) {
				case 0:
					this.updateButton(0)
					break
				case 1:
					if(this.templateDetail.templateButtonList[0].buttonType == 1){
						this.buttonList= []
						for (const key in this.templateDetail.templateButtonList){
							let obj = this.templateDetail.templateButtonList[key]
							this.buttonList.push({buttonIndex: obj.buttonIndex, text: obj.bodyText})
						}
						this.updateButton(1)
					}
					if(this.templateDetail.templateButtonList[0].buttonType == 2){
						this.updateButton(2)
						this.updateButton(5)
						this.buttonLinkText = this.templateDetail.templateButtonList[0].subcontentOne
						this.buttonLinkData = this.templateDetail.templateButtonList[0].subcontentTwo
					}
					if(this.templateDetail.templateButtonList[0].buttonType == 3){
						this.updateButton(2)
						if (this.templateDetail.templateButtonList[0].subcontentTwo.includes('{')) {
							this.updateButton(7)
							this.buttonSiteCount = 1
							this.buttonSitePropertyLength = (this.templateDetail.templateButtonList[0].subcontentTwo.replace(/.*\{|\}.*/g, '').length)+2
						} 
						this.buttonLinkText = this.templateDetail.templateButtonList[0].subcontentOne
						this.buttonLinkData = this.templateDetail.templateButtonList[0].subcontentTwo
					}
					break
				default:
					this.buttonList= []
					for (const key in this.templateDetail.templateButtonList){
						let obj = this.templateDetail.templateButtonList[key]
						this.buttonList.push({buttonIndex: obj.buttonIndex, text: obj.bodyText})
					}
					this.updateButton(1)
					break
				}  												           
				if (Array.isArray(this.templateDetail.templateButtonList) && this.templateDetail.templateButtonList.length){
					this.buttonList= []
					for (const key in this.templateDetail.templateButtonList){
						let obj = this.templateDetail.templateButtonList[key]
						this.buttonList.push({buttonIndex: obj.buttonIndex, text: obj.bodyText})
					}
				}
				
				this.bodyMessage = this.bodyMessage.replace(/\*(.*?)\*/g, '<b>$1</b>')
				this.bodyMessage = this.bodyMessage.replace(/_(.*?)_/g, '<strike>$1</strike>')
				this.bodyMessage = this.bodyMessage.replace(/~(.*?)~/g, '<i>$1</i>')
				this.bodyMessage = this.bodyMessage.replace(/-(.*?)-/g, '<del>$1</del>')
				this.getDepartmentList(this.departmentIdList)
				document.getElementById('previewBody').innerHTML = this.bodyMessage
				console.log(this.templateDetail)
				this.mediaLink = this.mediaContent
			})
			setTimeout(() => {
				this.loading = false
				if (this.mediaType == 5) {
					if (this.mediaName.includes('pdf')) {							
						this.otherFileType = false																								
					} else {
						this.otherFileType = true						
					}	
					let url = baseMediaURL + this.$route.params.companyId + '&mediaId='+ this.templateDetail.mediaId
					this.mediaLink = url					
					WMessageAPI.getArrayBufferMedia(url, function (result) {                                
						const options = {
							headers: {'Content-Type': 'text/plain'}
						}                                       
						let response = new Response(result, options)     
						response.text().then((s) => {
							let turl = 'data:' + 'application/pdf' +';base64,'  + s 
							let iframe = document.getElementById('iframePreview')				
							iframe.src = turl
						}) 						
					})  															
				}	
			}, 1000)
		},
		validateUrl () {
			const regex = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/
			return regex.test(this.buttonLinkData)
		},  
		
		next () { 
			if (this.buttonSelectDynamic == true || this.buttonSelectStatic == true) {
				if (!this.validateUrl()){
					alert('Url inválida! Seu endereço deve começar com https://')
					this.$refs.finalize.blur()
					return
				}  
			}
			if(this.textAreaCounter.length > 1024){
				alert('Seu corpo de mensagem possui '+this.textAreaCounter.length+' caracteres \n0 limite é de 1024!')
				this.$refs.finalize.blur()    
				return
			}
			// eslint-disable-next-line no-control-regex
			let regexOrdinal = /[\xAA]|[\xBA]|[\xB0]|[\xB2]|[\xB3]|[\xB9]/
			if(regexOrdinal.test(this.previewBody) == true){
				alert('Caracteres como º não são aceitos em Templates.')
				return
			}
			console.log(this.previewBody.charCodeAt([0]))
			if (this.buttonSelectSite == true || this.buttonSelectNumber == true){
				if(this.buttonLinkText == '' || this.buttonLinkData == ''){
					alert('Campo botão obrigatório!')
					this.$refs.finalize.blur()    
					return
				}
			}	   
			this.bodyMessage = document.getElementById('textArea').innerHTML  
			if (this.file ==null){
				if (this.bodyMessage == '' || this.bodyMessage == undefined){
					alert('Campo corpo da mensagem obrigatório!')
					return
				}                       
			}                  
			if (this.buttonSelectReady == true){
				this.buttonList.forEach((element) => {
					if(this.isEmpty == false){
						if (element.text == ''){
							alert('Campo título do botão obrigatório!')
							this.isEmpty = true
							return
						}
					}
					this.buttonList.forEach((el) => {
						if(this.isEmpty == false){
							if (element.text == el.text && element.buttonIndex != el.buttonIndex){
								alert('Campo botão duplicado!')
								this.isEmpty = true
								return
							}
						}	
					})
				})


				if(this.isEmpty == true ){
					this.isEmpty = false
					return
				}										
			}             
			let regex1 = /(<b[^>]+>|<b>|<\/b>)/g
			let regex2 = /(<i[^>]+>|<i>|<\/i>)/g
			let regex3 = /(<strike[^>]+>|<strike>|<\/strike>)/g
			this.bodyMessage = this.replaceMulti(this.bodyMessage, '<br>', '\n' )                                
			this.bodyMessage = this.bodyMessage.replace(regex1 , '*')
			this.bodyMessage = this.bodyMessage.replace(regex2 , '_')   
			this.bodyMessage = this.bodyMessage.replace(regex3 , '~')
			this.bodyMessage = this.bodyMessage.replaceAll('&nbsp;' , ' ')
			this.bodyMessage = this.convertHtmlToText(this.bodyMessage)
			let btnList = []
			this.buttonType2 = 1
			if (this.currentButton != 'Nenhum') {
				if (this.buttonType == 1 ) {
					this.buttonType2 = 3					
					this.buttonList.forEach(element => {
						let objButton =
					{
						buttonType: this.buttonType,
						bodyText: element.text,
						subcontentOne: '',
						subcontentTwo: ''
					}	
						btnList.push(objButton)		
					})					
				} 		
				if (this.buttonType == 2 || this.buttonType == 3 ) {
					this.buttonType2 = 2
					let objButton =
					{
						buttonType: this.buttonType,
						bodyText: '',
						subcontentOne: this.buttonLinkText,
						subcontentTwo: this.buttonLinkData
					}	
					btnList.push(objButton)
				}							
			}  																                                 
			let buttonList = JSON.stringify(btnList)
			WTemplatesAPI.postFinalizeDraft(this.$route.params.companyId, this.$route.params.templateId, this.mediaType, this.previewTitle, this.bodyMessage, this.previewFooter, this.buttonType2, buttonList, response => {
				if (response) {   
					let obj = {step: 13}
					this.$emit('sendMessageData', obj)  					    
					this.$router.push({ path: '/automation/'+ this.$route.params.companyId +'/main'})	
				} else {
					alert('Falha ao criar template!')
				}
			})                                                                                                  
		},                                                    
		goBack () {
			if (this.$route.params.mode == 'view') {
				let obj = {step: 13}
				this.$emit('sendMessageData', obj) 				
				this.$router.push({ path: '/automation/'+ this.$route.params.companyId +'/main'}) 
			} else {
				this.componentCancelModalKey ++
				this.showModalCancel = true
			}						
		},
		updateFormat (format) {
			this.mediaName = 'Carregar Arquivo'
			this.mediaType = format
			this.mediaLink = ''
			switch(format){
			case 2:
				this.titleText = true
				this.titleImage = false
				this.titleVideo = false
				this.titleDocument = false
				this.currentFormat = 'Texto'
				break
			case 3:
				this.titleText = false
				this.titleImage = true
				this.titleVideo = false
				this.titleDocument = false
				this.currentFormat = 'Imagem'
				break
			case 4:
				this.titleText = false
				this.titleImage = false
				this.titleVideo = true
				this.titleDocument = false
				this.currentFormat = 'Video'
				break
			case 5:
				this.titleText = false
				this.titleImage = false
				this.titleVideo = false
				this.titleDocument = true
				this.currentFormat = 'Documento'
				break
			default:
				this.titleText = false
				this.titleImage = false
				this.titleVideo = false
				this.titleDocument = false
				this.currentFormat = 'Nenhum'
				this.mediaType = 1
				break
			}
		},
		updateButton (format) {
			this.buttonFormat = format 
			this.buttonType = 0		
			this.buttonLink = false
			this.buttonSelectStatic = false
			this.buttonSelectDynamic = false
			this.buttonSelectReady = false
			this.buttonSelectLink = false
			this.buttonLinkData = ''
			this.buttonLinkText = ''
			if(this.buttonList.length <= 1){
				this.buttonsAmount = 1
			}else{
				this.buttonsAmount = this.buttonList.length
			}
			switch(format){
			case 0:
				this.currentButton = 'Nenhum'
				this.buttonSelectReady = false
				this.buttonSelectLink = false
				this.buttonSelectNumber = false
				this.buttonSelectSite = false 
				this.buttonTittle ='Crie botões que permitem aos clientes responder à sua mensagem ou agir.'
				this.buttonsAmount = 0
				this.buttonType = 1
				this.buttonType2 = 1
				break
			case 1:
				this.currentButton = 'Resposta Pronta'
				this.buttonSelectReady	 = true
				this.buttonSelectLink = false
				this.buttonTittle 	= 'Crie botões para seus clientes responderem à sua mensagem rapidamente ou interagir com você'
				this.buttonSelectSite = false
				this.buttonSelectNumber = false
				this.buttonLink = false 
				this.buttonType = 1
				break
			case 2:
				this.currentButton = 'Botão de link'
				this.buttonSelectReady = false
				this.buttonSelectLink = true
				this.buttonTittle = 'Crie poderosas CTA (Call To Actions) para estimular seus contatos à acessarem um site, ou fazerem uma ligação!'
				this.updateButton(4)
				this.updateButton(6)
				this.buttonLink = true
				this.buttonsAmount = 0
				
				break
			
			case 4:
				this.currentButtonType = 'Visitar site'
				this.buttonSelectSite = true
				this.buttonSelectNumber = false
				this.buttonLink = true
				this.buttonSelectLink = true
				this.buttonType = 3
				break
			case 5:
				this.currentButtonType = 'Ligar para número'
				this.buttonSelectSite = false
				this.buttonSelectNumber = true
				this.buttonLink = true
				this.buttonSelectLink = true
				this.buttonType = 2
				break
			case 6:
				this.currentButtonLink = 'Link estático'
				this.buttonSelectStatic = true
				this.buttonSelectLink = true
				this.buttonSelectDynamic = false
				this.textAreaPlaceHolder ='https://www.becon.com.br'
				this.buttonLink = true
				this.buttonType = 3
				this.buttonLinkData = '' 
				this.buttonSiteCount = 0
				this.buttonSitePropertyLength = 0
				this.currentAtribute = ''
				break
			case 7:
				this.currentButtonLink = 'Link dinâmico'
				this.buttonSelectStatic = false
				this.buttonSelectLink = true
				this.buttonSelectDynamic = true
				this.textAreaPlaceHolder ='https://www.becon.com.br/{{1}}'
				this.buttonLink = true
				this.buttonType = 3
				this.buttonLinkData = ''
				this.buttonSiteCount = 0
				this.buttonSitePropertyLength = 0
				this.currentAtribute = ''
				break
			}
		},  		
		postCancel () {
			this.showModalCancel = false
			this.$router.push({ path: '/automation/'+ this.$route.params.companyId +'/main' })	
		},
		cancelTemplate () {
			if (this.$route.params.mode != 'view') {
				this.componentCancelModalKey ++
				this.showModalCancel = true
			}else{
				this.postCancel()
			}  
		}, 		
	},    
	mounted () {
		this.setUnItalicText()
		this.updatePreviewBody()
		this.updateButton(0)
		if (this.$route.params.mode == 'view') {
			this.modalCancelar = 'Voltar'
			this.title = 'Visualizando Modelo'
		} else {
			setTimeout(() => {
				this.$refs.textArea.focus()
			}, 500)			
		}
		this.getTemplateDetail() 			   
	},      
}
</script>

<style scoped>
hr {
    border: 1px solid #C4C4C4;
}

.btn-text-style {
    color: #C4C4C4;
    background-color: #fff;
    border-color: transparent;
    font-size: 18px;
    font-weight: 700;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  height: 40%;
  width: 100%;
  position: absolute;
  cursor: pointer;
}
.editable {
    width: 100%;
    height: auto;
    min-height: 100px;
    border: 1px solid #ccc;
    padding: 5px;
    word-break: break-all;	
	outline: none;	
}
.custom-button {
    background-color: var(--secondary);
    border-color: var(--secondary); 
    color: #fff;
    padding: 5px 10px; 
    border-radius: 5px;  
    font-size: 14px; 
    font-weight: 500;
}
.bcn-box-shadow-check {
    background: #FFFFFF;
    border: 1px solid var(--primary);
    box-sizing: border-box;
    box-shadow: 0px 0px 0px rgba(187, 26, 94, 0.3);
}    
.list input::-webkit-input-placeholder {
    text-align: right;
    font-size: 14px;
    opacity: 0.7;
}
.screen-template-custom2 {
	background: rgb(255, 255, 255); 
    height: 83px;  
    border-radius: 10px;
}
.screen-template-custom {
    background: rgb(255, 255, 255); 
    min-height: 400px; 
    height: 80vh;  
    border-radius: 10px;
}
.template-data-scrollable {
	height: 90vh;
	width: 98%;
	background: #fff;
	overflow-y: auto;
	overflow-x: hidden;
}
.wpp-preview-scrollable {
	height: 30vh;
	width: 98%;
	background: #fff;
	overflow-y: auto;
	overflow-x: hidden;
}  
.template-preview{
	min-height: 500px;
	height: auto;
	width: 360px;
	background: #efe7dd url("~@/assets/wppBackground.jpg") repeat;
	top: 241px;
	left: 1040;
	padding: 30px;

}

.message-preview{
	min-width: 100px;
	min-height: 35px;
	max-width: 290px;
	background-color: white;
	border-radius: 8px;
	border-top-left-radius: 0px;
	padding-left: 5px;
	padding-top: 5px;
	padding-right: 5px;
    overflow-wrap: break-word;
	padding-bottom: 20px;
	position: relative;
    display: inline-block;
    white-space: wrap;
}

.message-preview::after {
    content: '';
    position: absolute;
    top: 0px;
    left: -6px;
    width: 0;
    height: 0;
    border-top: 0px solid transparent;
    border-right: 8px solid #fff !important;
    border-bottom: 12px solid transparent;
    border-top-left-radius: 3px;
}
.wppPreview-background{
	width: 293px;
	height: 155px;
	background: #C4C4C4;
	border-radius: 4px;
	margin-right: 10px;
	padding-top: 13px;
	padding-left: 13px;
}
.wpp-preview-media{
	background-color:#C4C4C4;
	max-width: 290px;
	max-height: 126.91px;
}
.wpp-preview-media-text {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #414141 !important;
	width: 50px;
	height: 20px;
}
.wpp-preview-media-size{
	width: 100%;
	height: 123px;
	min-width: 270px;
	justify-content: center;
	padding-top: 10px;
	padding-bottom: 10px;
}
.template-button-preview {
    font-size: 14px;
    color: #34B7F1;
    background-color:white;
    width: 148px;
	min-height: 35px;
	margin-top: 2px;
	margin-left: 10px;
    text-align: center;
    border-radius: 10px;    
}
.template-button-preview2 {
    font-size: 14px;
    color: #34B7F1;
    background-color:white;
    width: 290px;
	min-height: 35px;
    text-align: center;
    margin-top: 2px;
	margin-left: 0px;
    border-radius: 10px;    
	border: 0px;
}
.media-preview{
	width: auto;
	height: auto;
	min-height: 25px;
	background-color: white;
}
.message-preview .footer-preview {
	font-family: Open Sans;
    font-weight: 400;
    font-size: 12px;
    color: #999999;
	position: absolute;
	bottom: 0;
	right: 0;
	font-style: italic;
	padding: 8px;

}
.department-custom-font {
	font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #777777;
	background-color: #F5F5F5;
	border-radius: 100px;
	padding: 8px;
}

.message-file-preview {
	padding: 10px;
}
.textarea-input-count {
	font-size: 14px;    
	color: #999999;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;
}
.footer-input-count {
	margin: -40px;
	margin-top: 12px;
	font-size: 14px;    
	color: #999999;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;
}
.header-input-count {
	margin: -52px;
	margin-top: 18px;
	font-size: 14px;    
	color: #999999;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;
}
.button-link-input-count {
	margin: 2px;
	margin-top: -28px;
	font-size: 14px;    
	color: #999999;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;
}
.input {
	padding-right: 75px;
}
.emoji-alert {
        display: flex;
        background-color: var(--primary);
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
    } 

#caretposition {
  font-weight: bold;
}

</style>