import { render, staticRenderFns } from "./WChatBarActions.vue?vue&type=template&id=2603e340&scoped=true&"
import script from "./WChatBarActions.vue?vue&type=script&lang=js&"
export * from "./WChatBarActions.vue?vue&type=script&lang=js&"
import style0 from "./WChatBarActions.vue?vue&type=style&index=0&id=2603e340&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2603e340",
  null
  
)

export default component.exports