import Vue from 'vue'


let flowURL = 'https://flow.becon.com.br/flowbuild'
let baseUrl = 'https://' + document.location.host + '/wserver'
// let baseUrl = 'https://wadev.becon.com.br/wserver'

if(window.location.hostname.includes('atendimento.becon') || window.location.hostname.includes('mkcontroller.becon')) {
	flowURL = 'https://waflow.becon.com.br/flowbuild'
}
if(window.location.hostname.includes('wadev.becon')) {
	flowURL = 'https://devflow.becon.com.br/flowbuild'
}
if(window.location.hostname.includes('localhost')) {
	flowURL = 'http://localhost:3000/flowbuild'
}
if(window.location.hostname.includes('localhost')) {
	if(window.location.href.includes('8082')){
		baseUrl  = 'http://localhost:8383/wserver'
	}
	if(window.location.href.includes('8081')){
		baseUrl  = 'https://wadev.becon.com.br/wserver'
	}	
}

export {baseUrl}
export const baseApiUrl = baseUrl + '/rest/'
console.log('global baseApiUrl='+baseApiUrl)

export const chatFlowURL = flowURL

localStorage.removeItem('__wpp_base_api_url')
localStorage.setItem('__wpp_base_api_url', baseApiUrl)
export const avatarUrl = baseUrl + '/avatar/?companyId='
export const userKey = '__whatsapp_api_user'

export const config = {
	headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
}

export const access_token = localStorage.getItem('__wpp_access_token')
export const phonenumberId = localStorage.getItem('__wpp_phone_number_id')
export const companyId = localStorage.getItem('__wpp_company_id')
export const mediaMessageURL = baseUrl + '/wMedia/?messageId='
export const baseMediaURL = baseUrl + '/media/?companyId='

export function showError (e) {
	if(e && e.response && e.response.data){
		Vue.toasted.global.defaultError({ msg : e.response.data })
	} else if(typeof e === 'string'){
		Vue.toasted.global.defaultError({ msg : e })
	} else {
		Vue.toasted.global.defaultError()
	}
}

export default 
{
	baseApiUrl,
	showError, 
	userKey, 
	access_token, 
	config, 
	phonenumberId, 
	mediaMessageURL, 
	avatarUrl,
	baseMediaURL,
	chatFlowURL
}
