setInterval(function () {
	if(stop == false && store.getters.getFileQueue.length>0){
		handleFileQueue()
	}
}, 1000)
import WMessageAPI from '@/api/WMessageAPI'
import store from '@/config/store.js'
// import WChatMain from '@/components/chat/WChatMain'
import { companyId} from '@/global'
// import k from '@/components/chat/message/WMessages.vue'
// import {WChatMain} from '@/components/chat/WChatMain.vue'
export let fileQueue = []
let stop = false
console.log('ra')
// console.log(WChatMain.updateFileQueue(fileQueue))
function handleFileQueue () {
	fileQueue = store.getters.getFileQueue
	stop = true
	let media = fileQueue[0]
	console.log('tratei uma file')
	console.log(media)
	WMessageAPI.postUploadMedia(companyId, media.phonenumberId, media.formData,async response => {
		if(response){
			media.mediaId = response.data
			await sendMessageMedia(media.mediaId, media.message, media.mediaType)
		}else{
			store.commit('shiftFileQueue')
			stop = false 
		}
	})
}
function sendMessageMedia (mediaId, text, mediaType) {
	new Promise (resolve =>{
		let contact = fileQueue[0].waId   
		let phonenumberId = fileQueue[0].phonenumberId    
		let sendMediaType = ''     
		switch (mediaType) {             
		case 'video':
			sendMediaType = 'sendVideo'
			break         
		case 'audio':
			sendMediaType = 'sendAudio'
			break
		case 'voice':
			sendMediaType = 'sendAudio'
			break                    
		case 'image':
			sendMediaType = 'sendImage'
			break                          
		case 'pdf':
			sendMediaType = 'sendDocument'   
			break     
		case 'others':
			sendMediaType = 'sendDocument'
			break                                                                                                       
		}  
		if(!text){
			text = ''
		}
	
		WMessageAPI.postSendMedia(companyId, phonenumberId, mediaId, contact, text, sendMediaType, response => {
			response
			store.commit('shiftFileQueue')
			stop = false  
			resolve()               
		})
	})
}
export default{
	addFile (list) {
		fileQueue = fileQueue.concat(list)
	},
	getFileQueue () {
		return fileQueue
	}
}