
<template v-else>
	<small-mode v-if="this.$mq == 'xs' || this.$mq == 'sm'"/>
	<b-row v-else style="background: #777777; height: 100vh;">	
		<b-col :md="this.$mq == 'md' || this.$mq == 'lg' ? '2' : 'auto'">
			<div class="mt-5 ml-5">
				<img src="@/assets/spaceship.png">
			</div>			
		</b-col> 
		<b-col :md="this.$mq == 'md' || this.$mq == 'lg' ? '3' : 'auto'">
			<b-row v-if="this.$mq == 'md'" class="mt-5 pt-5 mr-5">
				<img src="@/assets/astronaut.png" height="250">
			</b-row>			
			<b-row v-else class="mt-5 pt-5">
				<img src="@/assets/astronaut.png">
			</b-row>										
		</b-col>        
		<b-col :md="this.$mq == 'md' || this.$mq == 'lg' ? '7' : '6'">
			<b-row v-if="this.$mq == 'md'" class="mt-3">
				<span class="custom-font-open-36-700">
					Oh não!
				</span>
			</b-row>
			<b-row v-else class="mt-5">
				<span class="custom-font-open-36-700">
					Oh não!
				</span>
			</b-row>			
			<b-row class="mr-5">
				<span class="custom-font-open-28-700">
					Sua equipe está à deriva no espaço, sem acesso à Becon!
				</span>				
			</b-row>
			<b-row class="mt-3 mr-5">
				<span class="custom-font-open-18-600">
					Seu acesso foi bloqueado porque não conseguimos registrar seu pagamento.
				</span>
			</b-row>	
			<b-row class="mt-3 mr-5">
				<div style="text-align: justify;  text-justify: inter-word;">
					<span class="custom-font-open-14-400">
						Para retomar seu acesso, basta acertar os valores em abertos com a Becon. <b>Caso não tenha acesso às faturas,</b> por favor, entre em contato com o nosso time de suporte imediatamente. <b>Caso já tenha pago os valores em aberto,</b> procure nosso suporte com o comprovante, para que possamos liberar seu acesso mais rápido.
					</span>
				</div>				
			</b-row>	
			<b-row class="mt-3">
				<w-support 
					:phoneNumber="5547984124491"
					:message="'Olá, preciso de ajuda com a fatura em aberto, podes me ajudar?'"
				/>
			</b-row>
			<b-row v-if="this.$mq == 'md'" class="mt-3">
				<span class="custom-font-open-11-800">
					IMPORTANTE:
				</span>
			</b-row>
			<b-row v-else class="mt-5">
				<span class="custom-font-open-11-800">
					IMPORTANTE:
				</span>
			</b-row>			
			<b-row class="mt-2 mr-5">
				<div style="text-align: justify;  text-justify: inter-word;">
					<span class="custom-font-open-11-400">
						O sistema está recebendo suas mensagens normalmente.
						Contudo, caso não recebamos nenhum contato seu, em 
						<b>30 (trinta)</b> dias sua conta terá sua <b>exclusão</b> 
						agendada, juntamente com o <b>histórico de mensagens,</b> 
						seus <b>contatos</b>, o <b>chatbot</b> da sua empresa, 
						<b>histórico de transmissões</b> e demais configurações.					
					</span>
				</div>
			</b-row>												
		</b-col>        				                
	</b-row>
</template>

<script>
import  InadimplencySmall from './InadimplencySmall'
import support from '@/components/global/Support'
export default {
	name: 'Inadimplency',
	components: {
		'small-mode': InadimplencySmall,
		'w-support': support
	},
	data () {
		return {            

		}
	},     
	methods: {

	},
	mounted () {

	}
}
</script>

<style>
.custom-font-open-11-400 {
    font-family: Open Sans;
    font-style: normal; 
    font-weight: 400; 
    font-size: 11px; 
    color: #fff;    
}
.custom-font-open-11-800 {
    font-family: Open Sans;
    font-style: normal; 
    font-weight: 800; 
    font-size: 11px; 
    color: #fff;    
}
.custom-font-open-14-400 {
    font-family: Open Sans;
    font-style: normal; 
    font-weight: 400; 
    font-size: 14px; 
    color: #fff;    
}
.custom-font-open-18-600 {
    font-family: Open Sans;
    font-style: normal; 
    font-weight: 600; 
    font-size: 18px; 
    color: #fff;    
}
.custom-font-open-28-700 {
    font-family: Open Sans;
    font-style: normal; 
    font-weight: 700; 
    font-size: 28px; 
    color: #fff;    
}
.custom-font-open-36-700 {
    font-family: Open Sans;
    font-style: normal; 
    font-weight: 700; 
    font-size: 36px; 
    color: #fff;    
}

</style>
