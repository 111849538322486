import axios from 'axios'
import i18n from '../plugins/i18n'
import { baseApiUrl, access_token} from '../global'


axios.defaults.headers.common['Authorization'] = `bearer ${access_token}`


export default {
   
	getBroadcastList: (companyId,  callback) => {
		const url = `${baseApiUrl}`+'broadcast/'+ companyId + '/list'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	}, 
	getArchivedByCompany: (companyId,  callback) => {
		const url = `${baseApiUrl}`+'broadcast/'+ companyId + '/archivedList'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},     
            
	postDraft (companyId, phonenumberId, broadcastName, departmentId, callback) {
		const params = new URLSearchParams()
		params.append('broadcastName', broadcastName)  
		params.append('departmentId', departmentId)              
		let url = `${baseApiUrl}` + 'broadcast/'+ companyId + '/' + phonenumberId+ '/draft'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},                
	postTemplate (companyId, broadcastId, templateId, callback) {
		const params = new URLSearchParams()
		params.append('templateId', templateId)              
		let url = `${baseApiUrl}` + 'broadcast/'+ companyId + '/' + broadcastId+ '/template'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},     
	postDatasource (companyId, broadcastId, datasourceType, callback) {
		const params = new URLSearchParams()
		params.append('datasourceType', datasourceType)              
		let url = `${baseApiUrl}` + 'broadcast/'+ companyId + '/' + broadcastId+ '/datasource'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},       
	postAcceptTerms (companyId, broadcastId, callback) {            
		let url = `${baseApiUrl}` + 'broadcast/'+ companyId + '/' + broadcastId+ '/acceptTerms'
		axios.post(url)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	}, 
	getCsvModelHeader (companyId, broadcastId,  callback) {          
		let url = `${baseApiUrl}` + 'broadcast/' + companyId + '/' + broadcastId + '/csvModelHeader'
		axios.get(url)
			.then(response => {
				if (response){                                
					callback(response.data)        
				}
			})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)                
			})    
	},            
	getCsvModel (companyId, broadcastId,  callback) {          
		let url = `${baseApiUrl}` + 'broadcast/' + companyId + '/' + broadcastId + '/csvModel'
		axios.get(url)
			.then(response => {
				if (response){                                
					callback(response.data)        
				}
			})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)                
			})    
	},    
	postValidateFileAndImport (companyId, broadcastId, importData, file,  callback) {  
		let config = { headers: { 'Content-Type': 'multipart/form-data'}}          
		let url = `${baseApiUrl}` + 'broadcast/' + companyId + '/' + broadcastId + '/' + importData + '/validateFileAndImport'
		axios.post(url, file, config)
			.then(response => {
				if (response){                                
					callback(response.data)        
				}
			})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)                
			})    
	},     
	postContactFilterResult (companyId, broadcastId, labelIdList, requestVOList, callback) {
		const params = new URLSearchParams()
		params.append('labelIdList', labelIdList)              
		params.append('requestVOList', requestVOList)   
		let url = `${baseApiUrl}` + 'broadcast/'+ companyId + '/' + broadcastId+ '/contactFilterResult'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},  
	postValidateFilter (companyId, broadcastId, contactIdList, callback) {
		const params = new URLSearchParams()
		params.append('contactIdList', contactIdList)               
		let url = `${baseApiUrl}` + 'broadcast/'+ companyId + '/' + broadcastId+ '/validateFilter'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},           
	postPersistContactList (companyId, broadcastId, contactIdList, valueVOList, persistData, callback) {
		const params = new URLSearchParams()
		params.append('contactIdList', contactIdList)               
		params.append('valueVOList', valueVOList)  
		params.append('persistData', persistData)   
		let url = `${baseApiUrl}` + 'broadcast/'+ companyId + '/' + broadcastId+ '/persistContactList'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},    
	postFinalizeCreation (companyId, broadcastId, startsAt, callback) {
		const params = new URLSearchParams()
		params.append('startsAt', startsAt)               
		let url = `${baseApiUrl}` + 'broadcast/'+ companyId + '/' + broadcastId+ '/finalizeCreation'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},           
	postChangeStartAt (companyId, broadcastId, startsAt, callback) {
		const params = new URLSearchParams()
		params.append('startsAt', startsAt)               
		let url = `${baseApiUrl}` + 'broadcast/'+ companyId + '/' + broadcastId+ '/changeStartAt'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},    
	postCancel (companyId, broadcastId, datasourceType, callback) {
		const params = new URLSearchParams()
		params.append('datasourceType', datasourceType)               
		let url = `${baseApiUrl}` + 'broadcast/'+ companyId + '/' + broadcastId+ '/cancel'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},  
	deleteDraft (companyId, broadcastId, callback) {            
		let url = `${baseApiUrl}` + 'broadcast/'+ companyId + '/' + broadcastId+ '/deleteDraft'
		axios.delete(url)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},  

	deleteBroadcast (companyId, broadcastId, callback) {            
		let url = `${baseApiUrl}` + 'broadcast/'+ companyId + '/' + broadcastId+ '/deleteBroadcast'
		axios.delete(url)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},  
    
        
	postArchivedBroadcast (companyId, broadcastId, callback) {           
		let url = `${baseApiUrl}` + 'broadcast/' + companyId + '/' + broadcastId + '/archivedBroadcast'
		axios.post(url)
			.then(response => {
				if (response){                                
					callback(true)        
				}
			})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)                
			})    
	},      
	postUnArchivedBroadcast (companyId, broadcastId, callback) {           
		let url = `${baseApiUrl}` + 'broadcast/' + companyId + '/' + broadcastId + '/unarchivedBroadcast'
		axios.post(url)
			.then(response => {
				if (response){                                
					callback(true)        
				}
			})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)                
			})    
	},       
	postBroadcastReport (companyId, broadcastId, callback) {  
		//let config = { headers: { 'Content-Type': 'multipart/form-data'}}          
		let url = `${baseApiUrl}` + 'broadcast/' + companyId + '/' + broadcastId + '/broadcastReport/'
		axios.post(url)
			.then(response => {
				if (response){                                
					callback(response.data)        
				}
			})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)                
			})    
	},
	postCreateAction (companyId, broadcastId, actionType, actionReferenceId, extra, callback) {
		const params = new URLSearchParams()
		params.append('actionType', actionType)               
		params.append('actionReferenceId', actionReferenceId)  
		params.append('extra', extra)   
		let url = `${baseApiUrl}` + 'broadcast/'+ companyId + '/' + broadcastId+ '/createAction'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},
	deleteAllBroadcastAction (companyId, broadcastId, callback) {
		let url = `${baseApiUrl}` + 'broadcast/'+ companyId + '/' + broadcastId+ '/deleteAllBroadcastAction'
		axios.delete(url)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},
	getListBroadcastActions (companyId, broadcastId, callback) {
		let url = `${baseApiUrl}` + 'broadcast/'+ companyId + '/' + broadcastId+ '/listBroadcastActions'
		axios.get(url)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},
	deleteBroadcastAction (companyId, broadcastId, brodcastActionId, callback) {
		let url = `${baseApiUrl}` + 'broadcast/'+ companyId + '/' + broadcastId+ '/' + brodcastActionId + '/deleteBroadcastAction'
		axios.delete(url)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},      
    
}
