<template>
	<div>
		<div class="flow-scrollbar scrollbar">
			<div style="min-height:300px">
				<tableT v-if="flowList" 
					:list="flowList" 
					:fields="fieldsFlow" 
					:perPage="10" 
					:searchBar="true"
					:filterFields="['flowName']"
					:searchPlaceHolder = "'Busque pelo nome'"
					:sortBy ="'flowName'"
					:selected="10"
					:disablePagination="false"
					@radioClicked="radioClicked"
				/>
			</div>     
		</div>
	</div>
</template>

<script>
import WChatFlowAPI from '@/api/WChatFlowAPI'
import Table from '@/components/global/TableComponent/Table'
export default {
	components:{
		tableT:Table,
		
	}, 
	props:[
		'departmentId'
	],
	data () {
		return {	
			flowList:undefined,
			fieldsFlow: [
				{
					key:'radio',
					label:''
				},
				{
					key: 'flowName',
					sortable: true,
					label:'Nome do Fluxo'
				},
				{
					key: 'stepCount',
					sortable: true,
					label:'Etapas'
				},
				{
					key: 'endCount',
					sortable: true,
					label:'Finalizado'
				},
				{
					key: 'createdTime',
					sortable: true,
					label:'Criado em'
				},
				{
					key: 'userCreatedName',
					sortable: true,
					label:'Criado por'
				},
			],
			selectedFlow:undefined,
			step: 1,
			eventList:[],
			selectedEvent:undefined,
			show:true
		}
	},
	watch:{
		selectedEvent () {
			let aux = {id: this.selectedFlow.pixelId,step: 1}
			this.$emit('sendMessageData', aux)
		}
	},
	methods: {
		hide () {
			this.$emit('hide')
		},
		next () {
			let flow = this.selectedFlow
			let obj = {file: null, mediaType: 'flow', flow}
			this.$emit('sendMessageData', obj) 
		},
		// emits a message when the radio button is clicked on the table
		radioClicked (obj) {
			this.selectedFlow = obj
			let aux = {id: this.selectedFlow.chatFlowId,step: 1}
			this.$emit('sendMessageData', aux)
			// this.step = 2
		},
		getChatFlowList () {
			this.loading = true
			this.pixelList = undefined
			if(this.departmentId != null && this.departmentId){
				WChatFlowAPI.getChatflowByDepartmentId(this.$route.params.companyId,this.departmentId,response=>{
					console.log(response.data)
					this.flowList = response.data
					// this.pixelList = response.data
					// for(let aux=0; aux < 30;aux ++){
					// 	this.pixelList.push(this.pixelList[0])
					// }
				
					this.loading = false
				})
			}else{
				WChatFlowAPI.getByCompany(this.$route.params.companyId,response=>{
					console.log(response.data)
					this.flowList = response.data
					// this.pixelList = response.data
					// for(let aux=0; aux < 30;aux ++){
					// 	this.pixelList.push(this.pixelList[0])
					// }
				
					this.loading = false
				})
			}
		},
	},
	mounted () {  
		this.getChatFlowList()  
	}, 
}
</script>

<style scoped>
.flow-scrollbar{
	height: 400px;
	overflow: scroll;

}
</style>