


import axios from 'axios'
import i18n from '../plugins/i18n'
import { baseApiUrl, access_token} from '../global'


axios.defaults.headers.common['Authorization'] = `bearer ${access_token}`


export default {
	getQuickReplyList: (companyId, callback) => {
		const url = `${baseApiUrl}`+'quickReply/'+ companyId + '/list'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},      
	postCreate (companyId, quickreply, callback) {
		const params = new URLSearchParams()
		params.append('type', quickreply.type)
		params.append('command', quickreply.command)        
		params.append('description', quickreply.description)         
		params.append('content', quickreply.content) 
		params.append('header', quickreply.header) 
		params.append('footer', quickreply.footer) 
		params.append('departmentAll', quickreply.departmentAll)  
		params.append('departmentIdList', quickreply.departmentIdList)                                
		let url = `${baseApiUrl}` + 'quickReply/'+ companyId + '/create'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},        
	postUploadMedia (companyId, quickReplyId, file, callback) {  
		let config = { headers: { 'Content-Type': 'multipart/form-data'}}          
		let url = `${baseApiUrl}` + 'quickReply/' + companyId + '/' + quickReplyId +'/uploadMedia'
		axios.post(url, file, config)
			.then(response => {
				if (response){                                
					callback(true)        
				}
			})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)                
			})    
	}, 
	deleteMedia (companyId, quickReplyId, callback) {            
		let url = `${baseApiUrl}` + 'quickReply/'+ companyId + '/' + quickReplyId + '/deleteMedia'
		axios.delete(url)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},         
	postUpdate (companyId, quickReplyId, quickreply, callback) {
		const params = new URLSearchParams()
		params.append('type', quickreply.type)
		params.append('command', quickreply.command)        
		params.append('description', quickreply.description)         
		params.append('content', quickreply.content)    
		params.append('header', quickreply.header)   
		params.append('footer', quickreply.footer)                   
		let url = `${baseApiUrl}` + 'quickReply/'+ companyId + '/' + quickReplyId+ '/update'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},     
	postDepartment (companyId, quickReplyId, departmentAll, departmentIdList, callback) {
		const params = new URLSearchParams()
		params.append('departmentAll', departmentAll)  
		params.append('departmentIdList', departmentIdList)                     
		let url = `${baseApiUrl}` + 'quickReply/'+ companyId + '/'+ quickReplyId+ '/department'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},        
	getDepartmentsOfQuickReply: (companyId, quickReplyId, callback) => {
		const url = `${baseApiUrl}`+'quickReply/'+ companyId + '/' + quickReplyId + '/departmentsOfQuickReply'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},       
	getQuickReplyByDepartmentId: (companyId, departmentId, callback) => {
		const url = `${baseApiUrl}`+'quickReply/'+ companyId + '/' + departmentId + '/quickReplyByDepartmentId'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},     
	deleteQuickReply (companyId, quickReplyId, callback) {            
		let url = `${baseApiUrl}` + 'quickReply/'+ companyId + '/' + quickReplyId + '/deleteQuickReply'
		axios.delete(url)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},       
	postInteractionButtons (companyId, quickReplyId, buttons, callback) {
		if (Array.isArray(buttons) && buttons.length){        
			const params = new URLSearchParams()
			params.append('buttons', JSON.stringify(buttons))                      
			let url = `${baseApiUrl}` + 'quickReply/'+ companyId + '/'+ quickReplyId+ '/interactionButtons'
			axios.post(url, params)
				.then(response => {
					if (response){                
						callback(true) 
					}
				})
				.catch(function (error) {
					console.log(i18n.t('Message.error') + error)                
				})    
		} else {
			callback(true) 
		}
	},    
	postInteractionList (companyId, quickReplyId, optionLabel, list, callback) {
		if (Array.isArray(list) && list.length){
			const params = new URLSearchParams()
			params.append('optionLabel', optionLabel)   
			params.append('list', JSON.stringify(list))                             
			let url = `${baseApiUrl}` + 'quickReply/'+ companyId + '/'+ quickReplyId+ '/interactionList'
			axios.post(url, params)
				.then(response => {
					if (response){                
						callback(true) 
					}
				})
				.catch(function (error) {
					console.log(i18n.t('Message.error') + error)                
				})   
		} else {
			callback(true) 
		} 
	},     
	getQuickReplyDetail: (companyId, quickReplyId, callback) => {
		const url = `${baseApiUrl}`+'quickReply/'+ companyId + '/' + quickReplyId + '/detail'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},                                                
}

