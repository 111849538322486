<template>
	<div>
		<b-list-group v-if="this.$mq == 'xs'">
			<b-list-group-item v-for="(x, index) in orderBy(labelList, 'labelDescription')" :key="index" class="custom-font-open-14-gray-normal"> 
				<span>                
					<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M0.647582 8.40511L0.702352 1.62951C0.703679 1.45388 0.737848 1.28025 0.802908 1.11854C0.867968 0.956839 0.962642 0.810232 1.08152 0.687103C1.20039 0.563974 1.34113 0.466737 1.4957 0.400955C1.65026 0.335172 1.81562 0.302134 1.98231 0.303728L9.27913 0.364722C9.53738 0.366881 9.78414 0.477055 9.96511 0.671L13.7878 4.76773C13.9762 4.96963 13.9734 5.29435 13.7816 5.49302L9.89396 9.51984C9.70964 9.71076 9.46092 9.81673 9.20257 9.81441L1.90148 9.7489C1.56638 9.74484 1.24643 9.60124 1.01149 9.34946C0.776552 9.09768 0.645725 8.75818 0.647582 8.40511Z" :fill="x.labelColor"/>
					</svg>
					{{x.labelDescription}}</span>
				<span class="ml-1 float-right mr-2">{{x.labelCount}}&nbsp;mensagens</span>									
			</b-list-group-item>
		</b-list-group>  
		<b-list-group v-else>
			<b-list-group-item v-for="(x,key, index) in orderBy(labelList, 'labelDescription')" :key="index" class="custom-font-open-14-gray-normal"> 
				<b-row class="ml-0">
					<div>
						<span>                
							<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M0.647582 8.40511L0.702352 1.62951C0.703679 1.45388 0.737848 1.28025 0.802908 1.11854C0.867968 0.956839 0.962642 0.810232 1.08152 0.687103C1.20039 0.563974 1.34113 0.466737 1.4957 0.400955C1.65026 0.335172 1.81562 0.302134 1.98231 0.303728L9.27913 0.364722C9.53738 0.366881 9.78414 0.477055 9.96511 0.671L13.7878 4.76773C13.9762 4.96963 13.9734 5.29435 13.7816 5.49302L9.89396 9.51984C9.70964 9.71076 9.46092 9.81673 9.20257 9.81441L1.90148 9.7489C1.56638 9.74484 1.24643 9.60124 1.01149 9.34946C0.776552 9.09768 0.645725 8.75818 0.647582 8.40511Z" :fill="x.labelColor"/>
							</svg>
						</span>	
					</div>
					<div class="description-ellipsis ml-2">
						<span :id="x.labelDescription">{{x.labelDescription}}</span>
						<b-tooltip :target="x.labelDescription" placement="bottom" triggers="hover">
							<span class="custom-font-open-14-white">
								{{x.labelDescription}}
							</span>
						</b-tooltip> 
					</div>
					<div class="ml-3" style="width:100px">
						<span class="ml-1 float-right mr-2" @click="openListMessage(x.labelDescription, x.labelColor,x.labelId)"><u style="cursor: pointer;">{{x.labelCount}}&nbsp;mensagens</u></span>
					</div>
				</b-row>
			</b-list-group-item>
		</b-list-group> 
	</div>                                          
</template>

<script>
import Vue2Filters from 'vue2-filters'
export default {
	name: 'WContactLabels',
	props: [
		'labelList'
	],
	mixins: [Vue2Filters.mixin],    
	data () {
		return {      
		}
	},    
	methods: {
		openListMessage (label, color,id) {
			let objLabel = {label: label, color: color,labelId:id}
			let obj = {file: null, mediaType: 'labelmessages', msg: objLabel, visible: true}
			this.$emit('sendMessageData', obj)            
		}          
	},
	mounted () {
	}, 
}
</script>

<style scoped>
.list-group-item {
    margin-bottom: 0 !important;
    padding: 2px !important;    
    background-color: white !important;
    border-radius: 100px !important;
    /* padding-left: 10px !important;  */
    border: none !important;   
}
.description-ellipsis { 
	width: 45%;
    white-space: nowrap;  
    overflow: hidden;
    text-overflow: ellipsis;
	/* margin-left: 15px!important; */
}
</style>

 
