


import axios from 'axios'
import i18n from '../plugins/i18n'
import { baseApiUrl, access_token} from '../global'


axios.defaults.headers.common['Authorization'] = `bearer ${access_token}`


export default {
   
	getCountries: (callback) => {
		const url = `${baseApiUrl}`+'geolocation/countries'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},    
    
	getStates: (countryId, callback) => {
		const url = `${baseApiUrl}`+'geolocation/states/'+ countryId 
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},    
        
	getCities: (stateId, callback) => {
		const url = `${baseApiUrl}`+'geolocation/cities/'+ stateId 
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},
	getLanguages: (callback) => {
		const url = `${baseApiUrl}`+'geolocation/languages'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},                
}




