<template>
	<div style="margin-left: 10px; margin-right: 30px;">
		<b-col class="mt-2 ml-2">
			<b-row class="ml-1">
				<b-col class="mt-2">
					<h3 class="lbl-title">Configurações de Proxy</h3>
				</b-col>
			</b-row>
			<b-row class="ml-2">
				<span class="custom-font-open-14-gray-normal">
					Selecione um número para alterar configurações de proxy
				</span>                       
			</b-row>          
		</b-col>
		<b-col md="12" sm="12" class="screen-template">        
			<loading v-if="this.$store.state.loading ==true"/>     
			<b-row class="mt-1">                                                                             
				<b-col class="ml-2" v-if="this.$store.state.loading ==false">
					<div class="contatc-list-scrollable scrollbar" style="margin-right: 30px;">
						<b-table-simple id="my-table"
							ref="agentTable"
							:per-page="perPage"
							:current-page="currentPage" hover small responsive>
							<b-thead head-variant="ligth">
								<b-tr>
									<b-th>Perfil comercial</b-th>
									<b-th>Número</b-th>                
									<b-th>Status</b-th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr :key="index" v-for="(x, index) in orderBy(lists, 'phonenumberName')"
									v-show="x.visible ==true">
									<b-td class="td-phonenumber">                                                          
										<u @click="openProxy(x.phonenumberId)" class="icon-clickable">{{x.phonenumberName}}</u>
									</b-td>                      
									<b-td class="td-phonenumber">{{x.phonenumber}}</b-td>   
									<b-td class="td-data">
										<svg :class=" `phonenumber-status-color-${x.status}`" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
											<circle cx="4" cy="4" r="4" fill="`contact-status-color-${x.status}`"/>
										</svg>								
										<span :class=" `ml-2 phonenumber-status-color-${x.status}`">{{x.statusDescription}}</span>
									</b-td>                                                                                                                                
									<b-td>
										<div>
											<b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" right text="Right align" no-caret>
												<template #button-content>
													<svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
														<circle cx="2" cy="2" r="2" fill="#C4C4C4"/>
														<circle cx="2" cy="11" r="2" fill="#C4C4C4"/>
														<circle cx="2" cy="20" r="2" fill="#C4C4C4"/>
													</svg>
												</template>
												<b-dropdown-item @click="openProxy(x.phonenumberId)" >
													<span>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M10.0779 1.50136C10.2368 1.34241 10.4255 1.21632 10.6332 1.1303C10.8409 1.04428 11.0635 1 11.2883 1C11.513 1 11.7356 1.04428 11.9433 1.1303C12.151 1.21632 12.3397 1.34241 12.4986 1.50136C12.6576 1.66031 12.7837 1.84901 12.8697 2.05669C12.9557 2.26436 13 2.48695 13 2.71174C13 2.93653 12.9557 3.15912 12.8697 3.3668C12.7837 3.57447 12.6576 3.76317 12.4986 3.92212L4.32855 12.0922L1 13L1.90779 9.67145L10.0779 1.50136Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														</svg>
													</span>
													<span class="custom-font-open-12-400 ml-1">
														Editar Proxy
													</span>                                        
												</b-dropdown-item>                                                                                                          
											</b-dropdown>
										</div>          
									</b-td>                           
								</b-tr>
							</b-tbody>
						</b-table-simple>  
					</div>
				</b-col>   
			</b-row>
			<b-row v-show="rows > perPage" class="pb-5">
				<b-col md="4" class="mt-2">
					<b-row>
						<b-col md="7" class="mt-1">
							<span class="mt-2 lblPagination">Visualizar por página:</span> 
						</b-col>
						<b-col md="4">                                                 
							<b-form-select v-model="selected" :options="perPageOptions" size="sm" @change="setPerPage()"></b-form-select>                
						</b-col>
					</b-row>
				</b-col>
				<b-col class="mr-5">
					<b-row class="d-flex justify-content-end">
						<b-pagination
							v-model="currentPage"
							:total-rows="rows"
							:per-page="perPage"
							pills
							size="sm"
							aria-controls="my-table"
						></b-pagination> 
					</b-row>
				</b-col>        
			</b-row>           
		</b-col>
	</div>    
</template>

<script>
import Loading from '@/views/Loading'
import WPhoneNumberAPI from '@/api/WPhoneNumberAPI'
import Vue2Filters from 'vue2-filters'
import { avatarUrl} from '@/global'
export default {
	name: 'WPhoneNumberList',
	mixins: [Vue2Filters.mixin],
	components: {
		loading: Loading,          
	},    
	data: function () {
		return {
			loading: false,
			avtUrl: avatarUrl,  
			filter: '',
			phoneNumberList: [],   
			originalPhoneNumberList: [],                                 
			perPage: 20,
			selected: 20,
			currentPage: 1,  
			perPageOptions: [20, 50, 100, 200, 500],  
		}
	},
	computed: {
		rows () {
			return this.phoneNumberList.length
		},
		lists () {
			const items = this.phoneNumberList
			if(this.phoneNumberList.length > 0){
				return items.slice(
					(this.currentPage - 1) * this.perPage,
					this.currentPage * this.perPage
				)
			}else{
				return {}
			}
		},                
	},    
	methods: {  
		openProxy (phonenumberId) {
			localStorage.removeItem('__wpp_phone_number_id_proxy')
			localStorage.setItem('__wpp_phone_number_id_proxy', phonenumberId)            
			let obj = {step: 6.1}
			this.$emit('sendMessageData', obj)                
		},
		getPhoneNumberList () {
			this.$store.commit('setShowLoading', true)
			WPhoneNumberAPI.getPhoneNumberList(this.$route.params.companyId, response => {
				let phoneNumberList = response.data
				this.$store.commit('setShowLoading', false)
				phoneNumberList.forEach(element => {
					element.visible = true
				})
				this.phoneNumberList = phoneNumberList                
				this.originalPhoneNumberList = this.phoneNumberList
			})
		},  
               
		setPerPage () {
			this.perPage = this.selected
		},    
		getPhonenumbers () {
			WPhoneNumberAPI.getPhonenumbers(this.$route.params.companyId, response => {
				this.phoneNumberList = response.data           
			})                
		},      
	},      
	mounted () {
		this.getPhonenumbers()
		this.getPhoneNumberList()
	},
}
</script>

<style scoped>

u {
    cursor: pointer;
}

.phonenumber-status-color-1 {
    color: #128C7E;
    fill: #128C7E;
}
.phonenumber-status-color-2 {
    color: #DD4747;
    fill:#DD4747;
}

.phonenumber-status-color-3 {
    color: #999999;
    fill:#999999;
}  

th {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #999999 !important;    
}

td {
    margin: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #777777; 
    height: 50px;
    vertical-align: middle !important;
}
.td-phonenumber {
    margin-top: 20px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.td-phonenumber {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;  
}
.td-date {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-data {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-email {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}
.td-property {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}

.td-target {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-fail {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-deliver {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}
.td-status {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-readed {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.sidebar-right-phonenumbers {
    display: flex !important;
    width: 100% !important;
    flex-direction: column !important;
    background: white !important;
    padding: 10px;
    margin-right: 50px;
}
.custom-pagination-input {
    width: 50px !important;
}
.custom-pagination-select {
    width: 60px !important;
}

.contatc-list-scrollable {
    height: 63vh;
    width: 100%;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
}
.lblPagination {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px; 
    color: #777777;   
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    min-height: 200px;
    -webkit-overflow-scrolling: touch;
}

.form-control:focus {
    background-color: #F5F5F5;
    box-shadow: none;
}

.description-ellipsis { 
    white-space: nowrap;  
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
}

.avatar {
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
</style>
