<template>

	<b-col>
		<b-col md="auto" style="margin-left: 10px; margin-right: 30px;" class="mt-4">
			<b-row>
				<span class="icon-clickable ml-2" @click="goBack" >
					<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="40" height="40" rx="8" fill="#F5F5F5"/>
						<path d="M18.2622 18.7135L23.9969 13.0243C24.6018 12.4292 24.6097 11.4692 24.0146 10.8643C23.4433 10.2836 22.4594 10.2756 21.8547 10.8467L15.0549 17.6312C14.9825 17.6786 14.9339 17.7502 14.9095 17.798C14.8615 17.7977 14.8611 17.8457 14.8611 17.8457C14.8128 17.8933 14.788 17.9891 14.7394 18.0607C14.7392 18.0847 14.7152 18.0845 14.715 18.1085C14.6904 18.1803 14.69 18.2283 14.6654 18.3001C14.6652 18.3241 14.617 18.3477 14.6166 18.3957C14.6162 18.4437 14.6157 18.5157 14.6153 18.5637C14.6151 18.5877 14.5905 18.6595 14.5903 18.6835C14.5897 18.7555 14.6135 18.7797 14.6131 18.8277C14.6127 18.8757 14.6123 18.9237 14.6117 18.9956C14.6115 19.0196 14.6593 19.044 14.6587 19.116C14.6825 19.1402 14.682 19.2122 14.7056 19.2604C14.7054 19.2844 14.729 19.3326 14.7768 19.357C14.8002 19.4292 14.7998 19.4772 14.8472 19.5496C14.8472 19.5496 14.895 19.574 14.9188 19.5982C14.9422 19.6704 14.9658 19.7186 15.0376 19.7432L21.7254 26.6381C22.035 26.9287 22.4178 27.0758 22.8258 27.0792C23.2098 27.0823 23.571 26.9412 23.8853 26.6558C24.4902 26.0607 24.4979 25.1248 23.903 24.4959L18.2622 18.7135Z" fill="#414141"/>
					</svg>
				</span>
				<span class="lbl-title ml-2 mt-1">Relatório de Envios</span>
			</b-row>
			<b-row class="mt-3 ml-0">
				<span class="custom-font-open-14-gray-normal">Veja aqui todas as mensagens proativas (notificações) disparadas por este número. Todo relatório será composto pelos seguintes dados:<br><br>
					I. Total de mensagens enviadas.<br>II. Para quem foi enviada (nome e número).<br>III. Quando foi enviada, entregue e lida.<br>IV. Se o envio foi por transmissão ou bate papo.<br>V. Qual usuário fez o envio.<br><br>
					<b>IMPORTANTE:</b> O WhatsApp e a Becon cobram por mensagens entregues. Então utilize a base ”Data de Entrega” para um relatório financeiro.</span>
			</b-row>
			
		</b-col>  
		<b-col class="ml-4">
			<b-row class="mt-2 mb-2">
				<b-form-radio
					:name="'option-selected'"
					value="1"
					class="mr-2"
					v-model="radioType">            
					<span class="td-data">Base data de Envio</span> 
				</b-form-radio>  
				<b-form-radio
					:name="'option-selected'"
					value="2"
					class="ml-2"
					v-model="radioType">                
					<span class="td-data">Base data de Entrega</span> 
				</b-form-radio>   					  					
			</b-row>
			<b-row class="mt-4">
				<b-col sm="auto">
					<b-row 
						:style="[this.$mq == 'md' ? 'width:12vw' : 'width:8vw' ]">
						<span class="mt-2" >Data início:</span>
					</b-row>
					
				</b-col>
				
			
				<b-row :style="[this.$mq == 'md' ? 'width:18vw' : 'width:14vw' ]">
					<b-form-datepicker 
						:date-format-options ="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
						id="example-datepicker"
						:locale="locales"  
						v-model="startDate"
						placeholder="Selecione uma data"
						label-help="Use as teclas de cursor para navegar pelas datas do calendário"
						class="mb-2 selected-color"></b-form-datepicker>
						
				</b-row>
				
				
				
			</b-row>
			<b-row class="mt-3">
				<b-col sm="auto">
					<b-row 
						:style="[this.$mq == 'md' ? 'width:12vw' : 'width:8vw' ]">
						<span class="mt-2" >Data fim:</span>
					</b-row>
					
				</b-col>
		
				<b-row class="" :style="[this.$mq == 'md' ? 'width:18vw' : 'width:14vw' ]">
					<b-form-datepicker 
						:date-format-options ="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
						id="example-datepicker2"  
						v-model="endDate"
						:locale="locales"
						placeholder="Selecione uma data"
						label-help="Use as teclas de cursor para navegar pelas datas do calendário"
						class="mb-2 teste"></b-form-datepicker>
						
				</b-row>
			

				
			</b-row>

			<b-row class="mt-4">
				<b-button :class="[validateDate == true ? 'disabled-button':'send-button']" @click="postReport" :disabled="validateDate">Emitir relatório</b-button>
				<span class="ml-1 mt-1" id="popover-help-sync" style="cursor: pointer;">
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M8.00004 14.6666C11.6819 14.6666 14.6667 11.6818 14.6667 7.99992C14.6667 4.31802 11.6819 1.33325 8.00004 1.33325C4.31814 1.33325 1.33337 4.31802 1.33337 7.99992C1.33337 11.6818 4.31814 14.6666 8.00004 14.6666Z" fill="#C4C4C4"/>
						<path d="M6.05994 6.00014C6.21667 5.55458 6.52604 5.17887 6.93324 4.93956C7.34044 4.70024 7.8192 4.61276 8.28472 4.69261C8.75024 4.77246 9.17248 5.01449 9.47666 5.37582C9.78083 5.73716 9.94731 6.19448 9.9466 6.6668C9.9466 8.00014 7.9466 8.6668 7.9466 8.6668" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M8 11.3333H8.00667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>							
				</span>
				<b-popover :show.sync="showPopHelp1" target="popover-help-sync" title="" placement="bottom">
					<div>
						A Data de início precisa ser anterior a data fim.<br>A Data fim precisa ser posterior a data início.
					</div>							
					<div>
						<a href="javascript:;" @click="showPopHelp1=false" class="float-right" style="color: var(--primary);"><b>x</b></a>
					</div>                            
				</b-popover>
			</b-row>
		</b-col>

	</b-col>
</template>

<script>
import ConverterDateTime from '@/mixins/ConverterDateTime'
import WPhoneNumberAPI from '@/api/WPhoneNumberAPI'
export default {
	mixins: [ConverterDateTime],
	data: function () {
		return {
			locales: 'pt-BR',
			startDate: '',
			endDate: '',
			startFormatted: '',
			startSelected: '',
			endFormatted: '',
			endSelected: '',
			duplicate: false,
			showPopHelp1: false,
			radioType:'1',
		}
	},
	computed: {
		validateDate () {
			// The date formatted in the locale, or the `label-no-date-selected` string
			let startSplit = this.startDate.split('-')
			let endSplit = this.endDate.split('-')
			// The following will be an empty string until a valid date is entered
			if(this.startDate == '' || this.endDate == ''){
				return true
			}else if(parseInt(startSplit[0]) > parseInt(endSplit[0])){
				return true
			}else if(parseInt(startSplit[1]) > parseInt(endSplit[1]) && parseInt(startSplit[0]) == parseInt(endSplit[0])){
				return true
			}else if(parseInt(startSplit[2]) > parseInt(endSplit[2])&& parseInt(startSplit[0]) == parseInt(endSplit[0]) && parseInt(startSplit[1]) == parseInt(endSplit[1])){
				return true
			}else{
				return false
			}
		},      
	}, 
	methods: {
		teste () {
		},
		startContext () {
			// The date formatted in the locale, or the `label-no-date-selected` string
			let startSplit = this.startDate.split('-')
			let endSplit = this.endDate.split('-')
			this.startSelected = startSplit[2]+'-'+startSplit[1]+'-'+startSplit[0]
			// The following will be an empty string until a valid date is entered
			if(this.startSelected == 'undefined-undefined-'){
				this.startSelected = ''
			}else if(parseInt(startSplit[0]) > parseInt(endSplit[0])){
				this.startDate = this.endDate
				alert('ano de start maior que end')	
			}else if(parseInt(startSplit[1]) > parseInt(endSplit[1]) && parseInt(startSplit[0]) == parseInt(endSplit[0])){
				this.startDate = this.endDate
				alert('mes de start maior que end')
			}else if(parseInt(startSplit[2]) > parseInt(endSplit[2])&& parseInt(startSplit[0]) == parseInt(endSplit[0]) && parseInt(startSplit[1]) == parseInt(endSplit[1])){
				this.startDate = this.endDate
				alert('dia de start maior que end')
			}
		},
		endContext (ctx) {
			// The date formatted in the locale, or the `label-no-date-selected` string
			this.endFormatted = ctx.selectedFormatted
			let startSplit = this.startDate.split('-')
			let endSplit = this.endDate.split('-')
			this.endSelected = endSplit[2]+'-'+endSplit[1]+'-'+endSplit[0]
			// The following will be an empty string until a valid date is entered
			if(this.endSelected == 'undefined-undefined-'){
				this.endSelected = ''
			}else if(parseInt(startSplit[0]) > parseInt(endSplit[0])){
				this.endDate = this.startDate 
				alert('ano de start maior que end')	
			}else if(parseInt(startSplit[1]) > parseInt(endSplit[1]) && parseInt(startSplit[0]) == parseInt(endSplit[0])){
				this.endDate = this.startDate 
				alert('mes de start maior que end')
			}else if(parseInt(startSplit[2]) > parseInt(endSplit[2])&& parseInt(startSplit[0]) == parseInt(endSplit[0]) && parseInt(startSplit[1]) == parseInt(endSplit[1])){
				this.endDate = this.startDate 
				alert('dia de start maior que end')
			}
		},
		postReport (phonenumberId, phonenumber, name) {
			let objPhoneNumber = {
				phonenumberId: phonenumberId,
				phoneNumber: phonenumber,
				phonenumberName: name
			}
			phonenumberId = localStorage.getItem('__wpp_phone_number_id')
			localStorage.removeItem('__wpp_settings_obj')
			localStorage.setItem('__wpp_settings_obj', JSON.stringify(objPhoneNumber))            
			let obj = {step: 2.3}
			obj
			let startDate = this.convertDate(this.startDate)
			let endDate = this.convertDate(this.endDate)
			startDate = startDate.getTime()
			endDate = endDate.getTime()

			WPhoneNumberAPI.postUsageReportEmail(this.$route.params.companyId, phonenumberId, this.radioType,startDate,endDate,response => {
				response
			}) 
			this.$emit('sendMessageData', obj) 
		},	
		goBack () {
			let obj = {step: 2}
			this.$emit('sendMessageData', obj)  			
		}     		
	},
	mounted () {
	},
}

</script>

<style scoped>
.today-variant{
	background-color: var(--primary);
}
.date-picker{
background: #FFFFFF;
border: 0.5px solid #777777;
box-sizing: border-box;
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
border-radius: 5px;
width: 4vw;
}
.send-button{
width: 200px;
height: 38px;
align-items: flex-start;
background: var(--primary);
border-radius: 8px;
color: white;
border-color: white;
font-family: Open Sans;
font-style: normal;
font-weight: 600;
font-size: 16px;
}
.disabled-button{
width: 200px;
height: 38px;
align-items: flex-start;
background: #777777;
border-radius: 8px;
color: white;
border-color: white;
font-family: Open Sans;
font-style: normal;
font-weight: 600;
font-size: 16px;
}

</style>