
<template>
	<div>
		<b-col md="12">
			<b-row>
				<b-modal 
					v-model="show"
					:title="title"
					centered
					scrollable>
					<b-col>                          
						<b-row class="ml-2 mr-2 mt-3">
							<b-input-group>
								<b-form-input 
									type="search"                     
									placeholder="Buscar propriedades"
									v-model="filter"
									@keyup="searchProperties" 
									class="mt-1"
									@update="clearFilter">
								</b-form-input>              
								<b-input-group-prepend is-text class="mt-1">
									<b-icon icon="search"></b-icon>
								</b-input-group-prepend>                    
							</b-input-group> 
						</b-row>    
						<loading v-if="this.$store.state.loading ==true"/>               
						<div v-for="(x, index) in orderBy(properties, 'propertyName')" :key="index" class="mt-2"
							v-show="x.visible ==true">
							<div class="m-1">    
								<label :for="x.propertyId" style="width: 250px; cursor: pointer;" @click="setProperty(x.propertyId, x.propertyName)">                       
									<b-row class="ml-1">
										<b-col md="1">
											<b-form-radio
												:id="x.propertyId"
												:name="'option-selected'"
												value="x.propertyId"
												size="lg"
											>
											</b-form-radio>                                                                                                                              
										</b-col>
										<b-col class="ml-1">
											<span class="ml-3 custom-font-open-14-gray">{{x.propertyName}}</span>         
										</b-col>
									</b-row>                                                                                                                                         
								</label>                                        
							</div>                                    
						</div>  
					</b-col>            
					<template #modal-footer>
						<div class="w-100">
							<b-button variant="primary" class="float-right" @click="add()">
								Adicionar
							</b-button>
							<b-button variant="outline-primary" class="float-left" @click="cancel">
								Cancelar
							</b-button>                                                    
						</div>
					</template>
				</b-modal>    
				<div class="w-100">
					<b-button variant="ligth" class="mr-2"
						@click="openProperties">Adicionar propriedade
					</b-button>                            
				</div>                                                  
			</b-row>                       
		</b-col>              
	</div>                                          
</template>

<script>
import Loading from '@/views/Loading'
import Vue2Filters from 'vue2-filters'
import WPropertiesAPI from '@/api/WPropertiesAPI'
export default {    
	name: 'WQuickReplyProperties',  
	mixins: [Vue2Filters.mixin],
	components: {
		loading: Loading,
	},
	data () {
		return { 
			show: false,  
			title: 'Escolha um atributo',
			filter: '',
			selected: '',
			propertyId: '',
			propertyName: '',
			properties: [],
		}
	},    
	methods: {
		openProperties () {
			this.show=true
			this.getPropertyList ()
		},
		setProperty (id, name) {
			this.propertyId = id
			this.propertyName = name
		},
		getPropertyList () {
			this.$store.commit('setShowLoading', true)
			WPropertiesAPI.getPropertyList(this.$route.params.companyId, response => {
				this.properties = response.data 
				this.$store.commit('setShowLoading', false)
				this.properties.forEach(el => {
					el.visible = true
					el.checked = 0
				})                                    
			})    
		},     
		searchProperties () {
			this.properties.forEach( el => {
				if ( el.propertyName.toLowerCase().includes(this.filter.toLowerCase()) ){
					el.visible = true
				} else {
					el.visible = false
				}
			})        
		},  

		add () { 
			let obj = {propertyName: this.propertyName}
			this.$emit('sendMessageData', obj) 
			this.show = false
		},  
		cancel () { 
			this.show = false
		},
		clearFilter () {
			if(this.filter == ''){
				this.searchProperties()
			}
		}                           
	},
	mounted () {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			console.log('Modal is about to be shown', bvEvent, modalId)
			this.show = false  
			this.title = 'Escolha um atributo'
			this.filter = ''
			this.properties = []
		})        
	}, 
}
</script>

<style scoped>
    .search input { 
        border: none !important;
        width: 50% !important;
    }    
    .btn-light {
        color: #212529;
        background-color:  #F5F5F5;
        border-color:  #F5F5F5;
    }  
    .mylabel {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #777777;
        cursor: pointer; 
    }
    .tempTitle {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: #777777;    
    }  
    .tempSubTitle {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #999999;            
    }
  
</style>

 
