import axios from 'axios'
import i18n from '../plugins/i18n'
import { baseApiUrl, access_token} from '../global'


axios.defaults.headers.common['Authorization'] = `bearer ${access_token}`


export default {
	getListByCompanyId: (companyId, callback) => {
		const url = `${baseApiUrl}`+'template/'+ companyId + '/listByCompany'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},    
	getTemplateDetail: (companyId,templateId , callback) => {
		const url = `${baseApiUrl}` + 'template/'+ companyId +'/'+ templateId+ '/detail'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},
	getIsCompanySyncedWithFacebook: (companyId,callback) => {
		const url = `${baseApiUrl}` + 'template/'+ companyId +'/isCompanySyncedWithFacebook'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	}, 
	getTemplateVOByDepartmentId: (companyId, departmentId, callback) => {
		const url = `${baseApiUrl}`+'template/'+ companyId + '/'+ departmentId +'/templateVOByDepartmentId'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},     
	getTemplatePropertyMap: (companyId, templateId, contactId,  callback) => {
		const url = `${baseApiUrl}`+'template/'+ companyId + '/'+ templateId +'/'+ contactId+ '/propertyMap'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},   
      
	getQuickReplyByDepartmentId: (companyId, departmentId, callback) => {
		const url = `${baseApiUrl}`+'template/'+ companyId + '/'+ departmentId +'/quickReplyByDepartmentId'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},
	postDraft (companyId, description ,language, templateType , callback) {
		const params = new URLSearchParams()
		params.append('description', description)
		params.append('language', language)  
		params.append('templateType', templateType)     
		let url = `${baseApiUrl}` + 'template/'+ companyId + '/draft'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},
	postDepartment (companyId, templateId, departmentAll, departmentIdList, callback) {
		const params = new URLSearchParams()
		params.append('departmentAll', departmentAll) 
		params.append('departmentIdList', departmentIdList)
		let url = `${baseApiUrl}` + 'template/'+ companyId+'/'+ templateId+'/department'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)   
				callback(false)              
			})    
	},
	syncWhatsappTemplates (companyId, callback) {
		let url = `${baseApiUrl}`+'template/'+ companyId+'/syncWhatsappTemplates'
		axios.post(url)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)   
				callback(false)              
			})    
	},  
	postFinalizeDraft (companyId, templateId, headerType, headerBody,bodyBody,footerBody, buttonType, buttonList, callback) {
		const params = new URLSearchParams()
		params.append('headerType', headerType) 
		params.append('headerBody', headerBody)				
		params.append('bodyBody', bodyBody)
		params.append('footerBody', footerBody)
		params.append('buttonsType', buttonType)
		params.append('buttonList', buttonList)		
		let url = `${baseApiUrl}` + 'template/'+ companyId +'/'+ templateId+ '/finalizeDraft'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error) 
				callback(false)               
			})    
	},      
	postUploadMedia (companyId, templateId, file, callback) {  
		let config = { headers: { 'Content-Type': 'multipart/form-data'}}          
		let url = `${baseApiUrl}` + 'template/' + companyId + '/' + templateId +'/uploadMedia'
		axios.post(url, file, config)
			.then(response => {
				if (response){                                
					callback(true)        
				}
			})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)                
			})    
	}, 	         
	deleteMedia (companyId, templateId, callback) {           
		console.log(companyId)
		let url = `${baseApiUrl}` + 'template/'+ companyId + '/' + templateId+ '/deleteMedia'
		axios.delete(url)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},  
	deleteTemplate (companyId, templateId, callback) {           
		console.log(companyId)
		let url = `${baseApiUrl}` + 'template/'+ companyId + '/' + templateId+ '/deleteTemplate'
		axios.delete(url)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)   
				callback(false)              
			})    
	},  
}

