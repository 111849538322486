<template>
	<div class="chat-footer" style="overflow:auto;display:block">
		<div class="ml-4" v-for="(file,index) in files" :key="index" style="display:flex">
			<br>
			<b-spinner style="width: 1rem; height: 1rem; color: var(--secondary)" ></b-spinner>
			{{file.name}}
		</div>
	</div>                                       
</template>
<script>
export default {
	name: 'WChatFileQueue',
	props:['files'],
	data () {
		return {
			
		}
	},    
	computed:{
		

	},
	methods: { 
	},    
	mounted () {
	}, 
}
</script>

<style scoped>
    
</style>



 