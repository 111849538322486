<template>
	<div>     
		<div v-show="messageInfo.mediaType == 'others' || messageInfo.mediaType == 'pdf'">
			<b-row v-if="messageInfo.quickreply==true" :class=" `message-file-quickreply ${messageInfo.status}`" @click="download(messageInfo.mediaName, messageInfo.mediaPath)">
				<b-col class="d-flex justify-content-start">                                                                    
					<span>
						<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M17.333 2.6665H7.99967C7.29243 2.6665 6.61415 2.94746 6.11406 3.44755C5.61396 3.94765 5.33301 4.62593 5.33301 5.33317V26.6665C5.33301 27.3737 5.61396 28.052 6.11406 28.5521C6.61415 29.0522 7.29243 29.3332 7.99967 29.3332H23.9997C24.7069 29.3332 25.3852 29.0522 25.8853 28.5521C26.3854 28.052 26.6663 27.3737 26.6663 26.6665V11.9998L17.333 2.6665Z" fill="white"/>
							<path d="M17.333 2.6665V11.9998H26.6663" fill="#F5F5F5"/>
						</svg>                              
					</span> 
					<span class="ml-2 mt-1" 
						style="font-family: Roboto; font-style: normal; font-weight: normal; font-size: 14px;line-height: 19px;">
						{{messageInfo.mediaName}}</span>        
					<span class="dateReceived ml-2 mt-2" style="color: white;" v-show="messageInfo.mediaSize > 0">{{bytesToSize(messageInfo.mediaSize )}}</span>                                                              
				</b-col> 
				<b-col md="1" class="d-flex justify-content-end">
					<span class="mt-1">
						<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M16.0003 29.3332C23.3641 29.3332 29.3337 23.3636 29.3337 15.9998C29.3337 8.63604 23.3641 2.6665 16.0003 2.6665C8.63653 2.6665 2.66699 8.63604 2.66699 15.9998C2.66699 23.3636 8.63653 29.3332 16.0003 29.3332Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M10.667 16L16.0003 21.3333L21.3337 16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M16 10.6665V21.3332" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>                                
					</span>                                                
				</b-col>   
							
			</b-row>      
			<b-row v-else :class=" `message-file ${messageInfo.status}`" @click="download(messageInfo.mediaName, messageInfo.mediaPath)">
				<b-col class="d-flex justify-content-start">
					<span><img src="@/assets/file.png" height="25"></span> 
					<span class="ml-2 mt-1" 
						style="font-family: Roboto; font-style: normal; font-weight: normal; font-size: 14px;line-height: 19px;">
						{{messageInfo.mediaName}}</span>                            
				</b-col> 
				<b-col md="1" class="d-flex justify-content-end">                         
					<span><img src="@/assets/download.png" height="25"></span>
				</b-col>                                         
			</b-row>                                                   
		</div>     
	</div>                                          
</template>

<script>

export default {
	name: 'WMessageMediaOthers',
	props: [
		'messageInfo'
	],
	data () {
		return {
                  
		}
	},    
	methods: {        
		bytesToSize (bytes) {
			let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
			if (bytes == 0) 
				return '0 Byte'
			let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
			return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
		},

		download (mediaName, mediaPath ) {
			let url = mediaPath
			let link = document.createElement('a')
			link.href = url
			link.setAttribute('download', mediaName)
			document.body.appendChild(link)
			link.click()        
		},    
                         
	},    
	mounted () {
	}, 
}
</script>

<style scoped>
    .dateReceived {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        color: #777777;
    }        
</style>



 