<template>
	<div class="signup-content">
		<div class="signup-modal">
			<img src="@/assets/logo.png" width="200px" height="150px" alt="Logo" class="mb-3"/>
			<div class="signup-title">{{$t("SignUp.title")}}</div>
			<input v-model="email" name="email" type="text" :placeholder="$t('login.enterEmail')">   
			<div class="mt-2 mb-2">
				<button @click="SignupStart">{{$t("global.btnNext")}}</button>  
			</div>     
             
			<hr class="signup">                                            
		</div>
	</div>
</template>

<script>
import { baseApiUrl, config } from '@/global'
import axios from 'axios'
export default {
	name: 'Signup',
	data () {
		return {
			email: '',
			url: '',
			parent: ''
		}
	},
	methods: {
		SignupStart () {
			if (this.email == undefined || this.email == ''){
				alert(this.$t('SignUp.msgEmail'))
				return
			}
			if (!this.validateEmail(this.email)){
				alert(this.$t('SignUp.msgInvalidEmail'))
				return
			}              
			let url = (window.location != window.parent.location)
				? document.referrer
				: document.location.href            
			const params = new URLSearchParams()
			params.append('email', this.email)
			params.append('url', url)      
			params.append('parent', url)          
			axios.post(`${baseApiUrl}signup/start`, params, config)
				.then(response => {
					let data = response.data
					let signupId = data.data
					if (signupId != null && signupId != '' && signupId != undefined){
						this.$router.push({ path: '/create/account/' + signupId + '?' + 'email=' + btoa(this.email)})    
					}       
				})
				.catch(function (error) {  
					alert(this.$t('Message.error') + error)
				})
		},
		validateEmail (email) {
			let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			return re.test(email)
		}        
	}
}
</script>


<style>
    .signup-content {
        margin-top: 100px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .signup-modal {
        background-color: #FFF;
        width: 350px;
        padding: 35px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .signup-title {
        font-size: 1.2rem;
        font-weight: 100;
        margin-top: 10px;
        margin-bottom: 15px;
    }

    .signup-modal input {
        border: 1px solid #BBB;
        width: 100%;
        margin-bottom: 15px;
        padding: 12px 17px;
        margin: 2px 0 11px;
        padding-left: 10px;
        height: 40px;
        outline: none;  
        border-radius: 10px;    
    }

    .signup-modal button {
        background-color: var(--primary);
        color: #FFF;
        padding: 5px 15px;
        width: 200px;
        justify-content: center;
        align-items: center;
        font-family: 'Montserrat','HelveticaNeue','Helvetica Neue','sans-serif';
        font-size:18px;
        color:white;    
        font-weight: 500;  
        cursor: pointer;
        padding: 8px 40px;
        border-radius: 10px;          
    }

    .signup-modal a {
        margin-top: 5px;
        font-size: 16px;
        font-weight: 500;
    }

    .remember {
        margin-top: 5px;
        font-size: 16px;
        color: gray;
    }

    hr.signup {
        border: 1px solid gray;
        width: 260px;
    }    
</style>
