<template>
	<div>
		<b-row>
			<span class="custom-font-open-14-400-gray">Escolha apenas um usuário para quem o sistema deverá atribuir este contato</span>        
		</b-row>        
		<b-row>
			<b-col md="10" class="mb-2">
				<div class="search" style="margin-left: -15px;">  
					<b-input-group>
						<b-form-input 
							type="search"                     
							placeholder="Busque pelo nome do usuário"
							v-model="filter"
							@keyup="searchAgents"
							class="mt-1"
							@update="clearFilter">
						</b-form-input>
						<b-input-group-prepend is-text class="mt-1">
							<b-icon icon="search"></b-icon>
						</b-input-group-prepend>                    
					</b-input-group>                                                                                       
				</div>
			</b-col>
		</b-row>
		<div class="automation-agents-scrollable scrollbar">
			<div v-for="(x, index) in orderUserList" :key="x.userId" v-show="x.visible == true" :class="[ '', index == currentIndex ? 'template-active' : '' ]">
				<label :for="x.userId" @change="setAgentId(index, x.userId)" style="width: 100%;margin: 8px;" class="icon-clickable">                            
					<b-form-radio
						:id="x.userId"
						:name="'option-selected'"
						value="x.userId"
						size="lg"
					>
						<span class="ml-2">
							<b-avatar :src="avtUrl + companyId + '&imageId='+x.userId" size="1.4em"></b-avatar>
						</span>                    
						<span class="td-data ml-2">{{x.name}}</span> 
					</b-form-radio>                                                
				</label>               
			</div> 
		</div>          

                                       
	</div>                                          
</template>

<script>
import WAttendantsAPI from '@/api/WAttendantsAPI'
import Vue2Filters from 'vue2-filters'
import { avatarUrl} from '@/global'
import WSortBy from '@/js/utils/WSortBy'
export default {
	mixins: [Vue2Filters.mixin],
	name: 'WAutomationAgents',    
	data () {
		return { 
			filter: '',
			companyId: this.$route.params.companyId,
			avtUrl: avatarUrl,
			agents: [],
			currentIndex: null
		}
	},
	computed: {	
		orderUserList () {					
			return WSortBy.orderMyList('name',this.agents)
		},      	
	},       
	methods: {
		getAgentsList () {
			WAttendantsAPI.getAgentsList(this.$route.params.companyId, response => {
				let agents = response.data
				if (agents){                       
					agents.forEach(agent => {
						agent.visible = true
					})                               
				}
				this.agents = agents    
			})    
		},     
		setAgentId (index, id) {
			this.currentIndex = index
			let obj = {id: id}
			this.$emit('sendMessageData', obj)               
		},            
		searchAgents () {
			this.agents.forEach( el => {
				if ( el.name.toLowerCase().includes(this.filter.toLowerCase()) ){
					el.visible = true
				} else {
					el.visible = false
				}
			})        
		},
		clearFilter () {
			if(this.filter == ''){
				this.searchAgents()
			}
		}                     
	},
	mounted () {    
		this.getAgentsList()                        
	}, 
}
</script>

<style scoped>
.automation-agents-scrollable {
    height: 35vh;
    width: 100%;
    min-height: 100px;
    overflow-y: auto;
    overflow-x: hidden; 
    margin-left: -15px;
}
.td-data {
    padding-top: 15px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px; 
    color: #777777; 
}
</style>

 
