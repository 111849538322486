<template>
	<div style="margin-left: 30px; margin-right: 30px;">
		<b-col class="mt-2 ml-2">
			<b-row class="ml-1">
				<b-col class="mt-2">
					<h3 class="lbl-title">Webhook</h3>
				</b-col>
				<b-col>
					<b-col>
						<b-row class="justify-content-end mr-5">
							<b-button v-b-toggle.sidebar-right variant="secondary"
								@click="create()">
								<span>
									<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M8 3V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M3 8H13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								</span>
								<span class="ml-1">
									Adicionar webhook
								</span>                        
							</b-button>                                                             
						</b-row>
					</b-col>                
				</b-col>
			</b-row>
			<b-row class="ml-2">
				<span class="custom-font-open-14-gray-normal">
					Integre outros sistemas com seu Whatsapp.                
				</span>                       
			</b-row>        
		</b-col>
		<b-col md="12" sm="12" class="screen-template">
			<loading v-if="this.$store.state.loading ==true"/>     
			<b-row style="margin-rigth:30px;" v-if="this.$store.state.loading ==false">
				<b-col md="6">
					<div class="search">  
						<b-input-group>
							<b-form-input 
								type="search"                     
								placeholder="Busque pelo evento do webhook"
								v-model="filter"
								@keyup="searchWebhook"
								@update="clearFilter"
								class="mt-1">
							</b-form-input>
							<b-input-group-prepend is-text class="mt-1">
								<b-icon icon="search"></b-icon>
							</b-input-group-prepend>                    
						</b-input-group>                                                                                       
					</div>
				</b-col>                            
			</b-row>
			<b-row class="mt-1">   
				<div>
					<webhook-modal-delete
						:title = "'Excluir webhook'"
						:subTitle = "'Você realmente deseja remover o webhook?'"
						:description = "webhookName"
						:description1 = "''"
						:value = "''"
						:placeholder = "''"
						:button1 = "'Fechar'"
						:button2 = "'Excluir'"
						:start="showModalDelete"   
						v-on:sendModalData="removeWebhook"
						:key="componentDeleteModalKey"                        
					/>
				</div>                                                  
				<b-col class="ml-2" v-if="this.$store.state.loading ==false">
					<div class="contatc-list-scrollable scrollbar">
						<b-table-simple id="my-table"
							:per-page="perPage"
							:current-page="currentPage" hover small responsive>
							<b-thead head-variant="ligth">
								<b-tr>
									<b-th>URL</b-th>
									<b-th>Tipo</b-th> 
									<b-th>Regra de sincronização</b-th>
									<b-th>Eventos</b-th>  
									<b-th>Requisições</b-th>                
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr :key="index" v-for="(x, index) in orderBy(list, 'name')"
									v-show="x.visible ==true">
									<b-td class="td-webhook"> 
										<div class="description-ellipsis" v-b-tooltip.hover.left="x.webhookUrl">
											<span class="mt-1" :id="x.webhookUrl">{{x.webhookUrl}}</span> 
										</div>
										<span class="icon-clickable" v-b-tooltip.hover title="Clique para copiar o URL" @click="copy(x.webhookUrl)">
											<img src="@/assets/copy.png">
										</span>                                                                
									</b-td> 
									<b-td class="td-data">{{x.webhookTypeDescription}}</b-td> 
									<b-td class="td-data" v-if="x.emailOnly == 1">Apenas com email</b-td> 
									<b-td class="td-data" v-else>Todos</b-td>                           
									<b-td class="td-data">
										<div :key="index_" v-for="(map, index_) in x.eventMap">
											<span class="td-data">{{map}}</span>
										</div>                                                                                                                                                                                                                                        
									</b-td>
									<b-td class="td-data">
										<span class="td-data">{{x.requestCount}}</span>
									</b-td>  
									<b-td class="td-data">
										<b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" right text="Right align" no-caret>
											<template #button-content>
												<svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
													<circle cx="2" cy="2" r="2" fill="#C4C4C4"/>
													<circle cx="2" cy="11" r="2" fill="#C4C4C4"/>
													<circle cx="2" cy="20" r="2" fill="#C4C4C4"/>
												</svg>
											</template>
											<b-dropdown-item @click="exportModel(x.webhookId)">
												<span>
													<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														<path d="M4.6665 6.6665L7.99984 9.99984L11.3332 6.6665" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														<path d="M8 10V2" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
													</svg>
												</span>
												<span class="custom-font-open-12-400">
													Exportar modelo
												</span>                                        
											</b-dropdown-item>  
											<b-dropdown-item @click="openDetail(x)">
												<span>
													<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M10.0779 1.50136C10.2368 1.34241 10.4255 1.21632 10.6332 1.1303C10.8409 1.04428 11.0635 1 11.2883 1C11.513 1 11.7356 1.04428 11.9433 1.1303C12.151 1.21632 12.3397 1.34241 12.4986 1.50136C12.6576 1.66031 12.7837 1.84901 12.8697 2.05669C12.9557 2.26436 13 2.48695 13 2.71174C13 2.93653 12.9557 3.15912 12.8697 3.3668C12.7837 3.57447 12.6576 3.76317 12.4986 3.92212L4.32855 12.0922L1 13L1.90779 9.67145L10.0779 1.50136Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
													</svg>
												</span>
												<span class="custom-font-open-12-400">
													Relatórios
												</span>                                        
											</b-dropdown-item>   

											<b-dropdown-item @click="setWebhookDelete(x.webhookId, x.webhookUrl)">
												<span>
													<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M3 4H4.33333H15" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														<path d="M6.3335 4.00016V2.66683C6.3335 2.31321 6.47397 1.97407 6.72402 1.72402C6.97407 1.47397 7.31321 1.3335 7.66683 1.3335H10.3335C10.6871 1.3335 11.0263 1.47397 11.2763 1.72402C11.5264 1.97407 11.6668 2.31321 11.6668 2.66683V4.00016M13.6668 4.00016V13.3335C13.6668 13.6871 13.5264 14.0263 13.2763 14.2763C13.0263 14.5264 12.6871 14.6668 12.3335 14.6668H5.66683C5.31321 14.6668 4.97407 14.5264 4.72402 14.2763C4.47397 14.0263 4.3335 13.6871 4.3335 13.3335V4.00016H13.6668Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														<path d="M7.6665 7.3335V11.3335" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														<path d="M10.3335 7.3335V11.3335" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
													</svg>
												</span>
												<span class="custom-font-open-12-400 ml-1">
													Excluir Webhook
												</span>                                        
											</b-dropdown-item>       	                                                       
										</b-dropdown>
									</b-td>                           
								</b-tr>
							</b-tbody>
						</b-table-simple>  
					</div>
				</b-col>   
			</b-row>
			<b-row v-show="rows > perPage" class="pb-5">
				<b-col md="4" class="mt-2">
					<b-row>
						<b-col md="7" class="mt-1">
							<span class="mt-2 lblPagination">Visualizar por página:</span> 
						</b-col>
						<b-col md="4">                                                 
							<b-form-select v-model="selected" :options="perPageOptions" size="sm" @change="setPerPage()"></b-form-select>                
						</b-col>
					</b-row>
				</b-col>
				<b-col class="mr-5">
					<b-row class="d-flex justify-content-end">
						<b-pagination
							v-model="currentPage"
							:total-rows="rows"
							:per-page="perPage"
							pills
							size="sm"
							aria-controls="my-table"
						></b-pagination> 
					</b-row>
				</b-col>
        
			</b-row>           
		</b-col>
	</div>    
</template>

<script>
import Loading from '@/views/Loading'
import WWebhookAPI from '@/api/WWebhookAPI'
import Vue2Filters from 'vue2-filters'
import WModal from '@/views/WModal'
import {baseUrl} from '@/global'
export default {
	mixins: [Vue2Filters.mixin],
	components: {
		loading: Loading,  
		'webhook-modal-delete': WModal,             
	},
	name: 'WWebhookList',
	data: function () {
		return {
			loading: false,
			filter: '',
			webhookList: [],   
			originalWebhookList: [],
			showModalDelete: false,                   
			perPage: 20,
			selected: 20,
			currentPage: 1,  
			totalPage: 1,  
			paginatedItems: [], 
			perPageOptions: [20, 50, 100, 200, 500],  
			phoneNumberList: [],   
			webhookName: '',  
			componentDeleteModalKey: 0,                                             
			popoverShow: false,
			userIndex: null,
			btnPersonalized: true,
			btnDefault: false,
		}
	},
	computed: {
		rows () {
			return this.webhookList.length
		},
		list () {
			const items = this.webhookList
			return items.slice(
				(this.currentPage - 1) * this.perPage,
				this.currentPage * this.perPage
			)
		},      
	},    
	methods: {
		openDetail (webHook) {
			localStorage.removeItem('__wpp_dev_webhook')
			localStorage.setItem('__wpp_dev_webhook',JSON.stringify(webHook))            
			let obj = {step: 2.5, webHook}
			this.$emit('sendMessageData', obj)                
		},
		selectText (element) {
			let range
			if (document.selection){
				range = document.body.createTextRange()
				range.moveToElementText(element)
				range.select()
			} else if (window.getSelection){
				range = document.createRange()
				range.selectNode(element)
				window.getSelection().removeAllRanges()
				window.getSelection().addRange(range)
			}
		} ,   
		makeToast (append = false) {
			this.toastCount++
			const msg = 'URL copiada para area de transferência'
			this.$bvToast.toast(msg, {
				title: 'URL',
				autoHideDelay: 2000,
				appendToast: append,
				toaster: 'b-toaster-bottom-center',
				variant: 'default',
				solid: true
			})
		}, 
		copy (key) {                
			let element = document.getElementById(key)    
			this.selectText(element)            
			document.execCommand('copy')
			try {
				this.makeToast(true)
			} catch (err){
				alert('Erro ao copiar a chave')
			}         
		}, 
		onClose (id) {
			this.$root.$emit('bv::hide::popover', id)   
		},
		onCloseQuickReply (name) {
			this.$root.$emit('bv::hide::popover', name)   
		},              
		create () {
			let obj = {step: 2.1}
			this.$emit('sendMessageData', obj)               
		},          
		getWebhookList () {
			this.$store.commit('setShowLoading', true)
			WWebhookAPI.getListByCompany(this.$route.params.companyId, response => {
				let webhookList = response.data
				this.$store.commit('setShowLoading', false)
				webhookList.forEach(element => {
					element.visible = true
				})
				this.webhookList = webhookList 
				this.originalWebhookList = this.webhookList
			})
		},         
		setPerPage () {
			this.perPage = this.selected
		},
		searchWebhook () {
			this.webhookList = this.originalWebhookList           
			this.webhookList.forEach( list => {
				for (const key in list.eventMap){
					let el = list.eventMap[key]
					if ( el.toLowerCase().includes(this.filter.toLowerCase()) ){
						list.visible = true
					} else {
						list.visible = false
					}    
				}
			})        
		},   
  
		setWebhookDelete (webhookId, name) {  
			this.webhookId = webhookId
			this.componentDeleteModalKey ++
			this.showModalDelete = true 
			this.webhookName = name
		},   

		removeWebhook (remove) {  
			this.showModalDelete =false
			if (remove){
				WWebhookAPI.deleteWebhook(this.$route.params.companyId, this.webhookId, response => {
					if (response){
						this.getWebhookList(false)                    
					} else {
						alert('Falha ao excluir a propriedade!')
					}                                       
				})                
			}            
		},   

		exportModel (webhookId) {
			let access_token = localStorage.getItem('__wpp_access_token')			
			window.open(baseUrl + '/webhookExample/?companyId='
				+ this.$route.params.companyId + '&webhookId=' + webhookId
				+ '&accessToken='+ access_token)
		},
                  
		timeToDate (time) {
			if (time ==0){
				return '-'
			}
			let t = new Date(Number(time))

			let fHourMinute = t.getHours() + ':'
			if(t.getMinutes()<10){
				fHourMinute+='0' 
			}
			fHourMinute+=t.getMinutes()  

			let d = new Date(Number(time))
			let day = d.getDate()        
			if(day < 10){
				day ='0' + d.getDate() 
			}
			let month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1)
			let sDate  = day  + '/' + month + '/' + d.getFullYear() 
			return sDate + ' ' + fHourMinute
		},    
		editWebhook (webhookId, description ) {
			let objWebhook = {
				webhookId: webhookId,
				webhookDescription: description
			}
			localStorage.removeItem('__wpp_developer_obj')
			localStorage.setItem('__wpp_developer_obj', JSON.stringify(objWebhook))            
			let obj = {step: 2.2}
			this.$emit('sendMessageData', obj)                
		},
		clearFilter () {
			if(this.filter == ''){
				this.searchWebhook()
			}
		}                
	},      
	mounted () {
		this.getWebhookList(false)
	}
}
</script>

<style scoped>

u {
    cursor: pointer;
}
th {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #999999 !important;    
}

td {
    margin: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #777777; 
    height: 50px;
    vertical-align: middle !important;
}
.td-webhook {
    margin-top: 20px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.td-phonenumber {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;  
}
.td-date {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-data {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-email {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}
.td-webhook {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}

.td-target {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-fail {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-deliver {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.td-readed {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}



.sidebar-right-webhooks {
    display: flex !important;
    width: 100% !important;
    flex-direction: column !important;
    background: white !important;
    padding: 10px;
    margin-right: 50px;
}
.custom-pagination-input {
    width: 50px !important;
}
.custom-pagination-select {
    width: 60px !important;
}

.contatc-list-scrollable {
    height: 63vh;
    width: 100%;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
}
.lblPagination {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px; 
    color: #777777;   
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    min-height: 200px;
    -webkit-overflow-scrolling: touch;
}

.form-control:focus {
    background-color: #F5F5F5;
    box-shadow: none;
}
.description-ellipsis { 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 150px;
	width: auto;
	max-width: 250px;
}

</style>
