
<template>
	<b-col id="main" class="webhook-scrollable scrollbar">

		<b-col class="mt-3 ml-4">
			<b-row>
				<b-col sm="9" class="custom-tittle-webhook-logs">
					<span id="url">{{webHook.webhookUrl}}</span>
				</b-col>
				
				<b-col sm="2" class="copy-button">
					<b-button >
						<span class="icon-clickable" v-b-tooltip.hover title="Copiar URL" @click="copy()"><img src="@/assets/copy.png"></span>
					</b-button>
				</b-col>
			</b-row>
		</b-col>
		
		
		<b-col class="ml-2">	
			<b-col sm="12" class="tables"> 
				<b-row>
					<b-col sm="6">
						<b-table-simple id="my-table"
							class="log-list scrollbar"
							hover small responsive>
							<b-thead head-variant="ligth">
								<b-tr>
									<b-th>
										<span class="header-custom ml-1">HTTP</span>
									</b-th>
									<b-th>
										<span class="header-custom">Evento</span>
									</b-th>
									<b-th>
										<span class="header-custom">Data</span>
									</b-th>
								</b-tr>
							</b-thead>
							<b-tbody >												
								<b-tr @click="onRowSelected(x)" :key="index" v-for="(x, index) in logList" :class="[x == selectedRow? 'selected-row-style':'']">											
									<b-td style="vertical-align:middle"> 
										<div class="ml-1" :class="httpClass(x.responseCode)">
											<span>
												{{x.responseCode}}
											</span>
										</div>
									</b-td>
									<b-td style="vertical-align:middle"> 
										<div>
											<span class="td-style">
												{{x.eventDescription}}
											</span>
										</div>
									</b-td>
									<b-td > 
										<div >
											<span class="td-style">
												{{timeFormatter(x.webhookTriggerTime)}}
											</span>
										</div>
									</b-td>
		
						
								</b-tr>
							</b-tbody>
						</b-table-simple> 
						<b-row  class="justify-content-center">
							<b-button variant="outline-primary" class="mr-2"
								@click="loadMore()">Carregar mais
							</b-button>
						</b-row>   
					</b-col>
					<b-col v-if="httpCode != '' && httpCode != undefined" sm="6" id="row-info" class="mt-1">
						<div class="log-list scrollbar">	
							<b-col sm="auto" class="td-style">					
								<b-row class="ml-0 mt-1">
									<span style="width:100px">Status:</span>
									<div :class="httpClass(httpCode)" style="width:30px">
										<span>
											{{httpCode}}
										</span>
									</div>
									<div class="ml-auto">
										<b-button @click="shouldRedo()" class="mr-5" variant="outline-primary">
											<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
												<g clip-path="url(#clip0_1309_415)">
													<path d="M9.91699 0.583984L12.2503 2.91732L9.91699 5.25065" stroke="var(--secondary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M1.75 6.41602V5.24935C1.75 4.63051 1.99583 4.03702 2.43342 3.59943C2.871 3.16185 3.46449 2.91602 4.08333 2.91602H12.25" stroke="var(--secondary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M4.08333 13.4167L1.75 11.0833L4.08333 8.75" stroke="var(--secondary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M12.25 7.58398V8.75065C12.25 9.36949 12.0042 9.96298 11.5666 10.4006C11.129 10.8382 10.5355 11.084 9.91667 11.084H1.75" stroke="var(--secondary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
												</g>
												<defs>
													<clipPath id="clip0_1309_415">
														<rect width="14" height="14" fill="white"/>
													</clipPath>
												</defs>
											</svg>

											Reenviar</b-button>
									</div>
								</b-row>		
								<b-row class="ml-0 mb-2">
									<span style="width:100px">Requisição:</span>
									<span>{{timeFormatter(webhookTriggerTime)}}</span>
								</b-row>
								<b-row class="ml-0 mb-2">
									<span style="width:100px">Processamento:</span>
									<span>{{timeFormatter(webhookProcessingTime)}}</span>
								</b-row>
								<b-row class="ml-0 mb-2">
									<span style="width:100px">Conclusão:</span>
									<span>{{timeFormatter(webhoohFinishTime)}}</span>
								</b-row>

							</b-col>
							
							<b-col v-if="httpRequest != null && httpRequest != '' && httpRequest != 'null'">
								<hr>
								<span>HTTP Request:</span>
								<b-row>
									<json-viewer
										
										theme="my-awesome-json-theme"
										:value="httpRequest"
										:expand-depth=5
										:copyable = copyable						
									></json-viewer>
								</b-row>
							</b-col>	
							<b-col v-if="httpResponse != null && httpResponse != '' && httpResponse != 'null'">
								<hr>
								<span>HTTP Response:</span>
								<b-row>
									<json-viewer
										theme="my-awesome-json-theme"
										:value="httpResponse"
										:expand-depth=5
										:copyable = copyable	
									></json-viewer>
								</b-row>
							</b-col>
							
						</div>
					
					</b-col> 
				
				</b-row>
			</b-col>
	
		</b-col>
		
		<template-modal-cancel
		
			:description = "'Você tem certeza que deseja reenviar este Webhook?'"
			:description1= "''"
			:value = "''"
			:title="''"
			:placeholder = "''"
			:button1 = "'Cancelar'"
			:button2 = "'Reenviar'"
			:start="showModalCancel"   
			v-on:sendModalData="redoWebhook"
			:key="componentCancelModalKey"                        
		/>
	</b-col>  
</template>

<script>
// import WDateTime from '@/js/utils/WDateTime'
import WKeyAPI from '@/api/WKeyAPI'
import WModal from '@/views/WModal'
export default {
	name: 'WWebhookLog',
	components: {
		'template-modal-cancel': WModal,
	}, 
	props: [
		'step'
	],   
	data () {
		return { 
			startDate: '',
			endDate: '',
			selectedRow:'',
			logList:[],
			httpCode:'',
			requestTime:'',
			transactionId:'',
			ipOrigin:'',
			endpoint:'',
			backupLogList:[],
			methodSelected:'',
			httpRequest:'',
			httpResponse:'',
			range:{end:'',start:''},
			copyable :{copyText: 'Copiar', copiedText: 'Copiado', timeout: 2000},
			key:'',
			userAgent:'',
			webHook: '',
			webhookTriggerTime:'',
			webhookProcessingTime:'',
			webhoohFinishTime:'',
			webhookHistoryId:'',
			showModalCancel:false,
			componentCancelModalKey: 0,

		}
	},    
	methods: {
		copy () {      
			navigator.clipboard.writeText(document.getElementById('url').innerText)  
		}, 
		shouldRedo () {
			this.componentCancelModalKey ++
			this.showModalCancel = true
		},
		httpClass (item) {
			if(item < 300){
				return 'table-cell-style-success'
			}else if(item < 400){
				return 'table-cell-style-success-yellow'
			}else{
				return 'table-cell-style-fail'
			}
		},

		timeFormatter (time) {
			let aux = new Date(time)
			aux = aux.toLocaleDateString('pt-BR') +'      '+ aux.toLocaleTimeString('pt-BR')	
			return aux
		},
		
		loadMore () {
			let endDate =  new Date(this.logList[this.logList.length - 1].webhookTriggerTime -1)
			endDate =Date.parse(endDate)
			WKeyAPI.postWebhookListHistory(this.$route.params.companyId,this.webHook.webhookId,endDate,response => {
				this.logList.push.apply(this.logList,response.data)
				this.backupLogList = this.logList
			}) 
			this.httpCode = '' //reset selected row
		},	
		redoWebhook () {
			WKeyAPI.postWebhookHistoryRedo(this.$route.params.companyId,this.webhookHistoryId,response => {
				response
			}) 
		},
		postLogs () {
			this.startDate = this.range.start
			this.endDate = this.range.end
			// let startDate = new Date(this.range.start).setHours(0,0,0,0)
			// let endDate =new Date(this.range.end).setHours(23,59,59,999)
			WKeyAPI.postWebhookListHistory(this.$route.params.companyId,this.webHook.webhookId,0,response => {
				this.logList = response.data
				this.backupLogList = response.data
			}) 
			this.httpCode = '' //reset selected row
		},	
		onRowSelected (items) {	
			this.selectedRow = items
			if(this.selectedRow.length != ''){
				this.httpCode = items.responseCode
				this.requestTime = new Date(items.requestTime)
				this.requestTime = this.requestTime.toLocaleDateString('pt-BR') +'  '+ this.requestTime.toLocaleTimeString('pt-BR')
				this.transactionId = items.transactionId
				this.ipOrigin = items.ipOrigin
				this.endpoint = items.endpoint
				this.methodSelected = items.httpMethod
				this.userAgent = items.userAgent
				this.webhookTriggerTime = items.webhookTriggerTime
				this.webhookProcessingTime = items.webhookProcessingTime
				this.webhoohFinishTime = items.webhoohFinishTime
				this.httpRequest = items.requestData
				this.httpResponse = items.responseMessage
				this.webhookHistoryId = items.webhookHistoryId
			}else{
				this.httpCode = ''
			}
		},
	},
	mounted () { 
		this.webHook =JSON.parse(localStorage.getItem('__wpp_dev_webhook'))
		this.postLogs()
	}, 
}
</script>

<style lang="scss">
	.webhook-scrollable {
			height: 87vh;
			width: 100%;
			background: #fff;
			overflow-y: scroll;
			overflow-x: hidden;
	}

	.tables{
		display: flex;
		flex-flow: column;
		height: 100%;
	}
	.log-list{
		max-height: 63vh;
	}
	.word-break{
		word-break: break-word;
	}

 .my-awesome-json-theme {
  background: #fff;
  white-space: nowrap;
  color: #525252;
  font-size: 14px;
  font-family: Consolas, Menlo, Courier, monospace;

  .jv-ellipsis {
    color: #999;
    background-color: #eee;
    display: inline-block;
    line-height: 0.9;
    font-size: 0.9em;
    padding: 0px 4px 2px 4px;
    border-radius: 3px;
    vertical-align: 2px;
    cursor: pointer;
    user-select: none;
  }
  .jv-button { color: var(--primary)}
  .jv-key { color: #111111 }
  .jv-item {
    &.jv-array { color: #111111 }
    &.jv-boolean { color: #fc1e70 }
    &.jv-function { color: #067bca }
    &.jv-number { color: #fc1e70 }
    &.jv-number-float { color: #fc1e70 }
    &.jv-number-integer { color: #fc1e70 }
    &.jv-object { color: #111111 }
    &.jv-undefined { color: #e08331 }
    &.jv-string {
      color: #3a03b1;
      word-break: break-word;
      white-space: normal;
    }
  }
  .jv-code {
    .jv-toggle {
      &:before {
        padding: 0px 2px;
        border-radius: 2px;
      }
      &:hover {
        &:before {
          background: #eee;
        }
      }
    }
  }
}
.custom-tittle-webhook-logs {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 20px !important;  
    color: #414141 !important;
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cell-style{
	.table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
}
}
.selected-row-style{
	box-shadow: inset 3px 0 0 0 var(--primary);
}

.header-custom {
	font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #999999;	
}
.td-style {
    margin-top: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px; 
    color: #777777;
}
.copy-button{
	
	.btn-secondary {
    color: #86017b;
    background-color: #fff;
    border-color: white;
    font-size: 14px;
    font-weight: 500;
}
}
</style>

 
