<template>
	<div>
		<div v-if="this.$store.getters.getIsUserOnline == false">
			<div class="no-internet">
				<svg width="225" height="225" viewBox="0 0 282 282" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="140" cy="142" r="140" fill="#C2C2C2" fill-opacity="0.2"/>
					<g filter="url(#filter0_d_243_34)">
						<path d="M127.976 179.534C121.469 186.04 121.469 196.613 127.976 203.12C134.483 209.627 145.056 209.627 151.562 203.12C158.069 196.613 158.069 186.04 151.562 179.534C145.056 173.027 134.483 173.027 127.976 179.534Z" fill="#777777"/>
						<path d="M139.769 139.843C126.024 139.843 113.092 145.211 103.413 154.89C99.5907 158.712 99.5907 164.894 103.413 168.716C107.236 172.539 113.417 172.539 117.24 168.716C123.259 162.698 131.31 159.363 139.769 159.363C148.309 159.363 156.28 162.698 162.298 168.716C164.169 170.587 166.69 171.563 169.212 171.563C171.733 171.563 174.173 170.587 176.125 168.716C179.947 164.894 179.947 158.712 176.125 154.89C166.365 145.211 153.514 139.843 139.769 139.843Z" fill="#777777"/>
						<path d="M139.769 107.717C117.484 107.717 96.419 116.419 80.6404 132.198C76.8178 136.02 76.8178 142.202 80.6404 146.024C84.4631 149.847 90.6443 149.847 94.467 146.024C106.586 133.906 122.689 127.236 139.769 127.236C156.849 127.236 172.953 133.906 185.072 146.024C186.942 147.895 189.464 148.871 191.985 148.871C194.506 148.871 196.946 147.895 198.898 146.024C202.721 142.202 202.721 136.02 198.898 132.198C183.038 116.419 162.054 107.717 139.769 107.717Z" fill="#777777"/>
						<path d="M221.59 109.425C199.711 87.5462 170.675 75.5089 139.769 75.5089C108.863 75.5089 79.7455 87.5462 57.867 109.425C54.0443 113.247 54.0443 119.429 57.867 123.251C59.7376 125.122 62.2589 126.098 64.7803 126.098C67.3016 126.098 69.7416 125.122 71.6935 123.251C89.8307 105.033 114.068 95.0288 139.769 95.0288C165.47 95.0288 189.626 105.033 207.845 123.251C211.667 127.074 217.848 127.074 221.671 123.251C225.412 119.429 225.412 113.247 221.59 109.425Z" fill="#777777"/>
					</g>
					<path d="M141 0C63.168 0 0 63.168 0 141C0 218.832 63.168 282 141 282C218.832 282 282 218.832 282 141C282 63.168 218.832 0 141 0ZM28.2 141C28.2 78.678 78.678 28.2 141 28.2C167.085 28.2 191.055 37.083 210.09 52.029L52.029 210.09C36.5439 190.394 28.1491 166.054 28.2 141V141ZM141 253.8C114.915 253.8 90.945 244.917 71.91 229.971L229.971 71.91C245.456 91.6058 253.851 115.946 253.8 141C253.8 203.322 203.322 253.8 141 253.8Z" fill="#D04848" fill-opacity="0.3"/>
					<defs>
						<filter id="filter0_d_243_34" x="45" y="69.5089" width="189.467" height="152.491" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
							<feFlood flood-opacity="0" result="BackgroundImageFix"/>
							<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
							<feOffset dy="4"/>
							<feGaussianBlur stdDeviation="5"/>
							<feComposite in2="hardAlpha" operator="out"/>
							<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
							<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_243_34"/>
							<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_243_34" result="shape"/>
						</filter>
					</defs>
				</svg>
				<h1>Sem internet</h1>
				<p>Parece que você não está conectado à internet.<br /> 
					Verifique sua conexão e tente novamente.</p>
			</div>
		</div>	
		<div v-else class="auth-content">
			<div class="auth-login" style="z-index:10;">
				<!-- <w-prompt/> -->
				<!-- <div v-if="logoValidate == true" class="auth-logo"> -->
				<div v-if="logoPath" class="auth-logo">		
					<b-row class="mb-3">
						<img :src="logoPath">   
					</b-row>
				</div>
				<div v-else class="auth-logo">
					<b-row class="mb-3 no-logo">
						<p>{{this.enterpriseName || 'Sua logo aqui'}}</p>
					</b-row>
				</div>
				<div class="auth-modal">   
					<h1 class="auth-title">Entre em sua conta</h1>                         
					<b-row class="mb-4">
						<label class="sr-only" for="inline-form-input-username"></label>
						<b-input-group class="mb-2 mr-sm-2 mb-sm-0 input-data">
							<b-input-group-prepend>
								<span class="icon">
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M6 5.68421C6 8.8181 8.692 11.3684 12 11.3684C15.308 11.3684 18 8.8181 18 5.68421C18 2.55032 15.308 0 12 0C8.692 0 6 2.55032 6 5.68421ZM22.6667 24H24V22.7368C24 17.8623 19.812 13.8947 14.6667 13.8947H9.33333C4.18667 13.8947 0 17.8623 0 22.7368V24H22.6667Z" fill="#838383"/>
									</svg>
								</span>
							</b-input-group-prepend>
							<b-form-input id="inline-form-input-username" v-model="username" placeholder="Digite seu e-mail"></b-form-input>
						</b-input-group> 
					</b-row>
					<b-row>
						<label class="sr-only" for="inline-form-input-password"></label>
						<b-input-group class="mb-2 mr-sm-2 mb-sm-0 input-data">
							<b-input-group-prepend>
								<span class="icon">
									<svg width="22" height="29" viewBox="0 0 22 29" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M15.125 9.6875V7C15.125 5.875 14.7083 4.91667 13.875 4.125C13.0833 3.29167 12.125 2.875 11 2.875C9.875 2.875 8.89583 3.29167 8.0625 4.125C7.27083 4.91667 6.875 5.875 6.875 7V9.6875H15.125ZM9.125 20.875C9.66667 21.4167 10.2917 21.6875 11 21.6875C11.7083 21.6875 12.3333 21.4167 12.875 20.875C13.4167 20.3333 13.6875 19.7083 13.6875 19C13.6875 18.2917 13.4167 17.6667 12.875 17.125C12.3333 16.5833 11.7083 16.3125 11 16.3125C10.2917 16.3125 9.66667 16.5833 9.125 17.125C8.58333 17.6667 8.3125 18.2917 8.3125 19C8.3125 19.7083 8.58333 20.3333 9.125 20.875ZM19 9.6875C19.7083 9.6875 20.3333 9.95833 20.875 10.5C21.4167 11 21.6875 11.6042 21.6875 12.3125V25.6875C21.6875 26.3958 21.4167 27.0208 20.875 27.5625C20.3333 28.0625 19.7083 28.3125 19 28.3125H3C2.29167 28.3125 1.66667 28.0625 1.125 27.5625C0.583333 27.0208 0.3125 26.3958 0.3125 25.6875V12.3125C0.3125 11.6042 0.583333 11 1.125 10.5C1.66667 9.95833 2.29167 9.6875 3 9.6875H4.3125V7C4.3125 5.16667 4.95833 3.60417 6.25 2.3125C7.58333 0.979167 9.16667 0.3125 11 0.3125C12.8333 0.3125 14.3958 0.979167 15.6875 2.3125C17.0208 3.60417 17.6875 5.16667 17.6875 7V9.6875H19Z" fill="#838383"/>	
									</svg>
								</span>
							</b-input-group-prepend>
							<b-form-input id="inline-form-input-password" v-on:keyup.enter="login" v-model="password" type="password" min="6" max="6" placeholder="Digite sua senha"></b-form-input>
						</b-input-group> 
					</b-row>                  
					<b-row class="mb-4 forgot-password">
						<router-link :to="{ path: '/forgot/password' }">
							<a>Esqueceu a senha?</a>                          
						</router-link>  
					</b-row>                                          
					<b-row>
						<button :disabled="disableLogin" @click="login">
							<span v-if="disableLogin == false">{{$t("login.login")}}</span>
							<b-spinner v-else style="width: 1rem; height: 1rem; color: var(--secondary)" label="Large Spinner"></b-spinner>
						</button> 
					</b-row>
					<b-row class="privacy" >
						<a href="https://becon.com.br/politica-de-privacidade/">Política de Privacidade</a>                          
					</b-row>  
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import WCompanyAPI from '@/api/WCompanyAPI'
// import i18n from '@/plugins/i18n'
import WLoginAPI from '@/api/WLoginAPI'
import store from '@/config/store'
/* import WCheckInternetConnection from '@/js/utils/WCheckInternetConnection.js'
 */export default {
	name: 'Login',
	data () {
		return {
			username: '',
			password: '',
			user: {},
			logoPath:this.$store.getters.getIconMediaId,
			disableLogin:false,
			enterpriseName: localStorage.getItem('__wpp_company_company_name')
		}
	},
	methods: {			
		login () {
			console.log('login')
			localStorage.clear()  
			let obj = {showFlowHeader: false, flowTitle: 'Título do Fluxo (e.g. Suporte Becon)'}			  
			localStorage.removeItem('__wpp_flow_header_obj')
			localStorage.setItem('__wpp_flow_header_obj', JSON.stringify(obj))				
			if (this.username == '' || this.username == undefined){
				alert('Campo e-mail obrigatório!')
				return
			}
			if (this.password == '' || this.password == undefined){
				alert('Campo senha obrigatório!')
				return
			}            
			WLoginAPI.postLogin(this.username, this.password, (response,error) => {
				if (response != null){
					store.commit('setUser', response.data)					
					let user = response.data
					let token = user.access_token
					let refreshToken = user.refresh_token
					localStorage.setItem('__wpp_access_token',token)  
					localStorage.setItem('__wpp_access_refresh_token',refreshToken)  
					this.$emit('userLogin')
					WCompanyAPI.getCompanyId() 
				} else {
					if(error < 2){
						console.log('disable')
						this.disableLogin = true
					}else{
						this.disableLogin = false
					}
					// alert(i18n.t('login.msgInvalidPassword')) 
				}              
			})                                                               
		}, 
	},
	mounted () {
		console.log(window)
	}      
}
</script>


<style scoped>
		.no-internet {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-top: 100px;
		}

		.no-internet p {
			text-align: center;
		}

		.auth-title {
        font-size: 34px;
        font-weight: bold;
        margin-bottom: 40px;
        color:  #333;
				text-align: left !important;
    }

    .auth-content {
        height: 100vh;
				width: 100vw;
				background-color: var(--secondary);
    }

    .auth-login {
        height: 100%;
				width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
 
    .auth-logo {
        width: 50%;
        padding: 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

		.no-logo {
			font-size: 32px;
			color: #FFF;
			font-weight: bold;
		}

    .auth-modal {
        background-color: #F4F5F8;
        width: 50%;
        height: 100%;
        padding: 35px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;
        align-items: center;
				justify-content: center;
				border-radius: 50px 0 0 50px;
    }

		.input-data {
			align-items: center;
			width: 350px;
			padding: 0 10px 0 10px;
			border-bottom: 1px solid #838383;
		}

		.input-data input {
			border: none;
			background: none;
		}
		
		.input-group-prepend {
			margin-right: 10px;
		}

		.forgot-password {
			width: 350px;
			justify-content: flex-end;
		}

		.forgot-password a {
			color: gray;
		}

		.forgot-password a:hover{
			color: #333333;
			text-decoration: none;
			font-weight: 700;
		}

		.privacy {
			position: absolute;
			top: 90%;
		}

		.privacy a {
			color: gray;
		}

    .auth-privacy-policy {
        background-color: #FFF;
        width: 370px;
        height: 100%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .auth-version-build {
        background-color: #FFF;
        width: 370px;
        height: 100%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .auth-modal button {
        background-color: var(--primary);
        color: #FFF;
        width: 100%;
        min-width: 365px;
        justify-content: center;
        align-items: center;
        font-family: 'Montserrat','HelveticaNeue','Helvetica Neue','sans-serif';
        font-size:18px;
        color:white;    
        font-weight: 500;  
        cursor: pointer;
        padding: 8px 40px;
        border-radius: 30px;  
        border: none;
        margin-left: -3px;
    }

		.auth-modal button:hover {
			opacity: .7;
		}

    .auth-modal a {
        margin-top: 5px;
        font-size: 16px;
        font-weight: 500;
    }

    .remember {
        margin-top: 5px;
        font-size: 16px;
        color: gray;
    }

    .input-group-text {
        color: #FFFFFF !important;
        background-color: var(--secondary) !important;
        border: 1px solid #ced4da !important;
        border-radius: 8px 0 0 8px !important; 
    }
		
    @media only screen and (max-width: 768px) {
				.auth-logo img {
					width: 280px;
				}

				.auth-title{
					font-size: 32px;
				}

				.auth-modal{
					width: 100%;
					height: 75%;
					border-radius: 50px 50px 0 0 ;
				}
			
				.auth-login{
					flex-direction: column;
				}

				.privacy {
					top: 95%;
				}
		}
		@media only screen and (max-width: 425px) {
			.auth-title {
				font-size: 24px;
			}

			.auth-modal{
				padding: 0;
			}

			.input-data{
				width: 250px;
			}

			.input-data input {
				font-size: 16px !important;
			}

			.forgot-password{
				width: 250px;
			}
			.forgot-password a {
				font-size: 16px;
			}

			.auth-modal button {
				min-width: 250px;
			}

			
		}
   
</style>
