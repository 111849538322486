<template>
	<b-row class="mt-1">
		<div class="dropdown-activity">
			<b-dropdown ref="dropdown" variant="ligth" :text="selectedValue" class="custom-font-open-12-400">
				<b-dropdown-item v-for="(x, index) in sortedList" :key="index" @click="selected(x.id, x.name)">{{x.name}}</b-dropdown-item>                                                          
			</b-dropdown> 
		</div>  					
	</b-row>  
</template>

<script>
export default {
	name: 'SelectDropdown',
	props: [
		'list',
		'defaultValue'
	],
	data () {
		return {
			selectedValue: 'Selecionar'
		}
	},
	computed: {
		sortedList () {
			const items = this.list
			items.sort(this.dynamicSort('name')) 
			return items
		},  		
	},
	methods: {
		selected (id, name) {
			this.selectedValue = name
			this.$emit('selectedItem', id)
			this.$refs.dropdown.hide(true)
		},   
		dynamicSort (property) {
			let sortOrder = 1
			if(property[0] === '-'){
				sortOrder = -1
				property = property.substr(1)
			}
			return function (a,b) {
				let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
				return result * sortOrder
			}
		},  		
	},
	mounted () {
		if (this.defaultValue != '') {
			this.selectedValue = this.defaultValue 
		}
	}

}
</script>

<style>

</style>