<template>
	<div>
		<b-modal 
			v-model="show"
			:title="title"
			centered>
			<b-container fluid class="ml-2" >
				<b-row v-show="subTitle != ''">
					<h5 class="modal-title">{{subTitle}}</h5>
				</b-row>                          
				<b-row v-show="value != ''" >
					<label for="input" class="custom-font-open-14-gray">Valor:</label>  
					<b-form-input id="input" type="text" 
						v-model="inputValue" class="custom-font-open-14-gray">
					</b-form-input>                  
				</b-row>                      
			</b-container>         
			<template #modal-footer>
				<b-col>
					<div class="w-100">
						<b-button variant="outline-primary" class="mr-2"
							@click="cancel()">{{button1}}
						</b-button>   
						<b-button variant="primary" class="float-right"
							@click="btnAction()">{{button2}}            
						</b-button>                     
					</div>  
				</b-col>
			</template>
		</b-modal>          
	</div>
                                       
</template>

<script>
export default {
	name: 'WModal',
	props: [
		'title',
		'subTitle',
		'value',
		'button1',
		'button2',
		'start',
	],   
	data () {
		return { 
			show: false,
			inputValue: ''
		}
	},    
	methods: {
		cancel () {
			this.show = false
			this.$store.commit('setShowFlowTitleModal', false)	
		},
		btnAction () {                       
			this.show = false 
			this.$store.commit('setShowFlowTitleModal', false)	
			this.$emit('sendShowModalTitle', this.inputValue) 
		},                          
	},
	mounted () {
		this.inputValue = this.value
		this.show = this.start   
	}, 
}
</script>

<style scoped>
.modal-title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #777777;
}
.modal-description {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #777777;
}
</style>

 
