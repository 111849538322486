<template>
	<div style="margin-left: 30px; margin-right: 30px; height: 100%;">
		<div class="screen-template scrollbar mt-5 mb-5" style="overflow-y: auto;">
			<b-col md="12">
				<b-row>
					<b-col md="5">
						<b-row class="mt-3">
							<b-col md="2">
								<span class="icon-clickable" @click="goBack">
									<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
										<rect width="40" height="40" rx="8" fill="#F5F5F5"/>
										<path d="M18.2622 18.7135L23.9969 13.0243C24.6018 12.4292 24.6097 11.4692 24.0146 10.8643C23.4433 10.2836 22.4594 10.2756 21.8547 10.8467L15.0549 17.6312C14.9825 17.6786 14.9339 17.7502 14.9095 17.798C14.8615 17.7977 14.8611 17.8457 14.8611 17.8457C14.8128 17.8933 14.788 17.9891 14.7394 18.0607C14.7392 18.0847 14.7152 18.0845 14.715 18.1085C14.6904 18.1803 14.69 18.2283 14.6654 18.3001C14.6652 18.3241 14.617 18.3477 14.6166 18.3957C14.6162 18.4437 14.6157 18.5157 14.6153 18.5637C14.6151 18.5877 14.5905 18.6595 14.5903 18.6835C14.5897 18.7555 14.6135 18.7797 14.6131 18.8277C14.6127 18.8757 14.6123 18.9237 14.6117 18.9956C14.6115 19.0196 14.6593 19.044 14.6587 19.116C14.6825 19.1402 14.682 19.2122 14.7056 19.2604C14.7054 19.2844 14.729 19.3326 14.7768 19.357C14.8002 19.4292 14.7998 19.4772 14.8472 19.5496C14.8472 19.5496 14.895 19.574 14.9188 19.5982C14.9422 19.6704 14.9658 19.7186 15.0376 19.7432L21.7254 26.6381C22.035 26.9287 22.4178 27.0758 22.8258 27.0792C23.2098 27.0823 23.571 26.9412 23.8853 26.6558C24.4902 26.0607 24.4979 25.1248 23.903 24.4959L18.2622 18.7135Z" fill="#414141"/>
									</svg>
								</span>

							</b-col>
							<b-col>
								<b-row>
									<h4 class="lbl-title">{{contactDetail.name}}</h4>                                 
									<span class="icon-clickable ml-2 mt-1">
										<contact-change-data 
											:contactId="contactDetail.contactId"
											:title="'Nome do contato'"
											:type="'Name'"
											:field="'Nome'"
											:defaultValue="contactDetail.name"
											:placeholder="'Informe o nome aqui'"
											:key="contactChangeKey"
											v-on:sendMessageData="getMessageData"/> 
									</span>                                   
								</b-row>
                            
							</b-col>                                                   
						</b-row>                    
						<b-row>
							<b-col md="2" class="mt-2">
								<div style="min-width: 64px;min-height: 64px;" >
									<b-avatar v-if="contactDetail.name == '' && contactDetail.name != undefined" class="avatar" size="5em"></b-avatar>
									<b-avatar v-else-if="contactDetail.name != undefined" class="avatar" size="5em">{{ contactDetail.name.substring(0,2).toUpperCase()}}</b-avatar>
									<!-- <b-avatar :src="avtUrl + companyId + '&imageId='+contactDetail.contactId" size="3.5em"></b-avatar> -->
								</div>      
							</b-col>
							<b-col md="9">
								<b-row class="mb-1 mt-2">
									<span class="custom-font-open-16-400">Número: <span class="custom-font-open-16-gray-normal">+ {{contactDetail.waId}}</span></span>
								</b-row>
								<b-row class="mb-1">
									<span class="custom-font-open-16-400">Status do número: <span :class=" `ml-2 contact-status-color-${contactDetail.status}`">
										<svg :class=" `mr-2 contact-status-color-${contactDetail.status}`" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
											<circle cx="4" cy="4" r="4" fill="`contact-status-color-${contactDetail.status}`"/>
										</svg>{{contactDetail.statusDescription}}</span></span>
								</b-row>
								<b-row >
									<span class="custom-font-open-16-400">E-mail: <span class="custom-font-open-16-gray-normal">{{contactDetail.email}}</span></span>
									<span class="icon-clickable ml-2">
										<contact-change-data 
											:contactId="contactDetail.contactId"
											:title="'E-mail do contato'"
											:type="'email'"
											:field="'e-mail'"
											:defaultValue="contactDetail.email"
											:placeholder="'Informe o e-mail aqui'"
											:key="contactChangeKey"
											v-on:sendMessageData="getMessageData"/>                                    
									</span>                                                   
								</b-row>
							</b-col>
						</b-row>   
						<hr>
						<b-row class="ml-1">
							<b-col>
								<h5 class="lbl-sub-title">Endereço</h5>
							</b-col>
							<b-col>
								<span class="float-right icon-clickable">
									<contact-address 
										:contactId="contactDetail.contactId" 
										:type="'contact'"
										:address="contactDetail.contactAddress"
										v-on:sendMessageData="getMessageData"/>
								</span>                            
							</b-col>
						</b-row>                            
						<b-form class="ml-4" v-if="contactDetail.contactAddress">
							<b-row class="mb-1">              
								<b-col md="5" sm="12">                                
									<b-row>
										<span class="custom-font-open-14">País: <span class="custom-font-open-14-gray-normal">{{contactDetail.contactAddress.countryDescription}}</span> </span>
									</b-row>
								</b-col>   
								<b-col md="5" sm="12">
									<b-row>
										<span class="custom-font-open-14">Estado: <span class="custom-font-open-14-gray-normal">{{contactDetail.contactAddress.stateDescription}}</span></span>
									</b-row>             
								</b-col>                               
							</b-row>  
							<b-row class="mb-1">                              
								<b-col md="5" sm="12">
									<b-row>
										<span class="custom-font-open-14">Cidade: <span class="custom-font-open-14-gray-normal">{{contactDetail.contactAddress.city}}</span></span>
									</b-row>
								</b-col>    
								<b-col md="5" sm="12">
									<b-row class="mb-1">
										<span class="custom-font-open-14">CEP: <span class="custom-font-open-14-gray-normal">{{contactDetail.contactAddress.zipCode}}</span></span>
									</b-row>
								</b-col>                             
								<b-col md="5" sm="12">
									<b-row>
										<span class="custom-font-open-14">Bairro: <span class="custom-font-open-14-gray-normal">{{contactDetail.contactAddress.neighborhood}}</span></span>
									</b-row>
								</b-col>                                                                                                                    
							</b-row>             
							<b-row class="mb-1">              
								<b-col md="5" sm="12">
									<b-row>
										<span class="custom-font-open-14">Rua: <span class="custom-font-open-14-gray-normal">{{contactDetail.contactAddress.street}}</span></span>
									</b-row>
								</b-col>  
								<b-col md="4" sm="12">
									<b-row>
										<span class="custom-font-open-14">Número: <span class="custom-font-open-14-gray-normal">{{contactDetail.contactAddress.number}}  </span></span>
									</b-row>
								</b-col>                                              
							</b-row> 
							<b-row>              
								<b-col md="10" sm="12">
									<b-row>
										<span class="custom-font-open-14">Complemento: <span class="custom-font-open-14-gray-normal">{{contactDetail.contactAddress.complement}}</span></span>
									</b-row>
								</b-col>                                                                          
							</b-row>  
							<div>
								<contact-modal-delete
									:title = "'Excluir contato'"
									:subTitle = "'Tem certeza que deseja excluir o contato?'"
									:description = "contactDetail.name"
									:description1= "''"
									:value = "''"
									:placeholder = "''"
									:button1 = "'Cancelar'"
									:button2 = "'Excluir'"
									:start="showModal"   
									v-on:sendModalData="deleteContact"
									:key="componentModalKey"                        
								/>
							</div>                                                                    
						</b-form>   
						<hr>
						<b-row class="mt-3 mb-3">                                                       
							<b-col md="10" sm="12">
								<div>
									<b-button variant="primary" 
										@click="showModalDelete()">Deletar contato
									</b-button>                                      
									<b-button v-show="contactDetail.status != 2" variant="outline-primary" class="float-right mr-2"
										@click="blockContact()">Bloquear contato
									</b-button>      
									<b-button v-show="contactDetail.status == 2" variant="outline-primary" class="float-right mr-2"
										@click="unBlockContact()">Desbloquear contato
									</b-button>                                                                              
								</div>
							</b-col>
						</b-row>                                                                           
					</b-col>
					<b-col md="7">
						<div class="mt-3">                        
							<b-tabs content-class="mt-3" fill active-nav-item-class="tab-active">
								<b-tab title="Dados" active>
									<div class="contact-data-scrollable scrollbar ">
										<b-list-group class="data-list">
											<b-list-group-item v-for="(x, index) in contactDetail.detailVOList" :key="index" 
												class="custom-font-open-14-gray-normal" style="border: none;"> 
												<hr v-if="index > 0 ">
												<b-row style="margin-left:15px">
													<b-col sm="3">  
														<b-row>
															<svg class="mt-1" width="16" height="16" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
																<circle cx="4" cy="4" r="4" :fill="x.primaryColor"/>
															</svg>	
															<span class="custom-font-open-14 float-right ml-2">{{(x.phonenumberName != ''?x.phonenumberName:x.phonenumberId)}}</span>
														</b-row>
														<b-row>
															<span class="float-right ml-4">{{(x.phonenumber != ''?'+ '+ x.phonenumber:'')}}</span>
														</b-row>                                                        
													</b-col>
													<b-col sm="5">
														<b-row>
															<span class="custom-font-open-14 float-right">Responsável pelo contato</span>
														</b-row>
														<b-row>
															<div v-if="x.agentOrDepartmentLeadDescription == '' && x.agentOrDepartmentLeadDescription != undefined" class="avatar"></div>
															<div v-else class="avatar">{{ x.agentOrDepartmentLeadDescription.substring(0,2).toUpperCase()}}</div>
															<!-- <b-avatar :src="avtUrl + companyId + '&imageId='+x.agentOrDepartmentLead" size="2.1em"></b-avatar> -->
															<span class="float-right ml-1 mt-2">{{x.agentOrDepartmentLeadDescription}}</span>
															<contact-responsible
																:contactId="x.contactId"
																:waId="contactDetail.waId"
																:phonenumberId="x.phonenumberId"
																:agentOrDepartmentLead="x.agentOrDepartmentLead" 
																:agentOrDepartmentLeadDescription="x.phonenumberDefaultDepartmentDescription"                                                        
																v-on:sendMessageData="getMessageData"/>                                                         
														</b-row>                                                    
													</b-col>
													<b-col>
														<b-row>
															<span class="custom-font-open-14 float-right">
																Último contato: {{timeToDate(x.lastContact)}}
															</span>
														</b-row>
														<b-row class="mt-2">
															<span class="custom-font-open-chat float-right ml-1" @click="openChatMessage(x.phonenumberId)">Abrir bate-papo
																<span>
																	<svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M3.33301 11H12.6663" stroke="var(--primary)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
																		<path d="M8 6.33337L12.6667 11L8 15.6667" stroke="var(--primary)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
																	</svg>
																</span>
															</span>
														</b-row>

													</b-col>                                                                                                
												</b-row>                                            
											</b-list-group-item>
										</b-list-group>   
									</div>                                
								</b-tab>                              
								<b-tab title="Propriedades">
									<div class="contact-properties-scrollable scrollbar">  
										<properties-list 
											:key="componentPropertyKey" 
											:propertyMap="contactDetail.propertyMap" 
											:propertyDescriptiopMap="contactDetail.propertyDescriptiopMap"
											:contactId="contactDetail.contactId"
											:propertyType="'contact'"
											v-on:sendMessageData="getMessageData"/>                                      
									</div> 
								</b-tab>
								<b-tab title="Marcações">
									<!-- <b-col class="ml-2">
										<tableT v-if="labelList" 
											:list="labelList" 
											:fields="fields" 
											:perPage="10" 
											:key="reload"
											:searchBar="true"
											:selected="5"
											:searchPlaceHolder = "'Busque pelo nome'"
											:sortBy ="'labelDescription'"
										/>
										<div>
										</div>
									</b-col> -->
									<div :class="[ 'label-data-scrollable scrollbar', this.$mq == 'xl' ? 'label-data-scrollable-xl scrollbar' : '' ]">
										<div v-if="phoneNumberList.length > 1">
											<span>Selecione um número para visualizar mensagens etiquetadas.</span><br>
											<b-dropdown :text="(selectedPhoneNumber.phonenumberName != ''?selectedPhoneNumber.phonenumberName:(selectedPhoneNumber.phonenumber != ''?selectedPhoneNumber.phonenumber:selectedPhoneNumber.phonenumberId))" variant="ligth">
												<b-dropdown-item @click="selectedPhoneNumber = x,$emit('changeNumber', x)" v-for="(x,index) in phoneNumberList" :key="index">
													{{(x.phonenumberName != ''?x.phonenumberName:(x.phonenumber != ''?x.phonenumber:x.phonenumberId))}}
												</b-dropdown-item>
											</b-dropdown>
											<hr>
										</div>
										
										
										<b-list-group>
											<b-list-group-item v-for="(x, index) in getSelectedPhoneNumberLabelList" :key="index" class="custom-font-open-14-gray-normal contact-label-list"> 
												<span>                
													<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M0.647582 8.40511L0.702352 1.62951C0.703679 1.45388 0.737848 1.28025 0.802908 1.11854C0.867968 0.956839 0.962642 0.810232 1.08152 0.687103C1.20039 0.563974 1.34113 0.466737 1.4957 0.400955C1.65026 0.335172 1.81562 0.302134 1.98231 0.303728L9.27913 0.364722C9.53738 0.366881 9.78414 0.477055 9.96511 0.671L13.7878 4.76773C13.9762 4.96963 13.9734 5.29435 13.7816 5.49302L9.89396 9.51984C9.70964 9.71076 9.46092 9.81673 9.20257 9.81441L1.90148 9.7489C1.56638 9.74484 1.24643 9.60124 1.01149 9.34946C0.776552 9.09768 0.645725 8.75818 0.647582 8.40511Z" :fill="x.labelColor"/>
													</svg>
													{{x.labelDescription}}</span>
												<span class="ml-1 float-right mr-2">{{x.labelCount}}&nbsp;mensagens</span>
											</b-list-group-item>
										</b-list-group>   
									</div>
								</b-tab>                          
							</b-tabs>
						</div>
					</b-col>
				</b-row>
			</b-col> 
			<hr>
			<b-col md="12" class="screen-template mt-3">
				<b-row class="ml-3">
					<h5 class="mt-3 lbl-sub-title">Atividades</h5>                    
				</b-row>   
				<b-row class="ml-2">
					<div class="dropdown-activity">
						<b-dropdown variant="ligth" :text="historyText" class="custom-font-open-12-400" style="min-width: 200px;">
							<b-dropdown-item @click="filterActionStatus(0, 'Histórico Completo')">Histórico Completo</b-dropdown-item>
							<b-dropdown-item @click="filterActionStatus(1, 'Alteração do responsável')">Alteração do responsável</b-dropdown-item>
							<b-dropdown-item @click="filterActionStatus(2, 'Alteração de usuário')">Alteração de usuário</b-dropdown-item>
							<b-dropdown-item @click="filterActionStatus(3, 'Adição de propriedade')">Adição de propriedade</b-dropdown-item>
							<b-dropdown-item @click="filterActionStatus(4, 'Estado do atendimento alterado')">Estado do atendimento alterado</b-dropdown-item>
							<b-dropdown-item @click="filterActionStatus(5, 'Contato bloqueado')">Contato bloqueado</b-dropdown-item>
							<b-dropdown-item @click="filterActionStatus(6, 'Contato desbloqueado')">Contato desbloqueado</b-dropdown-item>                                                            
						</b-dropdown> 
					</div>                  
				</b-row>   
				<b-row class="ml-2 mt-3">
					<b-list-group class="history-list">
						<b-list-group-item v-for="(x, index) in historyList" 
							:key="index"
							v-show="x.visible == true" 
							style="border: none;">
							<b-row>
								<div style="width: 80%">
									<span>
										<b-avatar :src="avtUrl + companyId + '&imageId='+x.userId" size="1.5em"></b-avatar>
									</span>   				 
									<span class="ml-2 mt-1 history-description">
										{{x.caption}}
									</span> 
									<span class="ml-3 mt-1 custom-font-open-12 float-right">
										{{timeToDate(x.actionTime)}} - {{getDateTimeFromTimestamp(x.actionTime)}}
									</span>     
								</div>                               
							</b-row>
							<b-row class="ml-4 mt-2" v-if="x.actionType < 4">
								<b-col md="10">
									<b-row v-if="x.actionType == 3" class="ml-4">
										<span class="custom-font-open-12">
											Nome:
										</span>  
										<span class="custom-font-roboto-14 ml-3">
											{{x.actionFromDescription}}
										</span>                                      
										<span class="custom-font-open-12 ml-3">                    
											Valor: 
										</span>                                           
										<span class="custom-font-roboto-14 ml-3">
											{{x.extra}}
										</span>                                         
									</b-row>
									<b-row v-else>
										<span>
											<div class="avatar">{{ x.actionFromDescription.substring(0,2).toUpperCase()}}</div>
										</span>  
										<span class="custom-font-roboto-14 mt-1 ml-3">
											{{x.actionFromDescription}}
										</span>                                      
										<span class="ml-3">                    
											<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M8.99951 13.5H24.7495" stroke="#C4C4C4" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
												<path d="M20.2495 9L24.7495 13.5L20.2495 18" stroke="#C4C4C4" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
											</svg>
										</span>                                           
    
										<span class="ml-5">																						
											<div class="avatar">{{ x.actionToDescription.substring(0,2).toUpperCase()}}</div>
										</span>  
										<span class="custom-font-roboto-14 mt-1 ml-3">
											{{x.actionToDescription}}
										</span>                                         
									</b-row>
								</b-col>
							</b-row>
						</b-list-group-item>
					</b-list-group>                
				</b-row> 
				<div v-if="contactDetail.historyVO" style="padding-bottom: 50px;">
					<b-col md="7" v-show="contactDetail.historyVO.historyListContainsMore">
						<b-row class="mt-3 d-flex justify-content-center">
							<b-button variant="primary"
								@click="loadMore(contactDetail.historyVO.firstHistoryTime)">Carregar mais...            
							</b-button>                             
						</b-row>
					</b-col>                   
				</div>                     
			</b-col> 
		</div>               
	</div>                                          
</template>

<script>
import WContactsAPI from '@/api/WContactsAPI'
import Vue2Filters from 'vue2-filters'
import WAddress from '../settings/WAddress'
import WContactData from './WContactData'
import WContactProperties from './WContactProperties'
import WContactResponsible from './WContactResponsible'
import WModal from '@/views/WModal'
// import Table from '@/components/global/TableComponent/Table'
import { avatarUrl} from '@/global'
export default {
	name: 'WContactDetail',
	mixins: [Vue2Filters.mixin], 
	components: {
		// tableT: Table,
		'contact-address': WAddress,
		'contact-change-data': WContactData,
		'properties-list': WContactProperties,
		'contact-modal-delete': WModal,
		'contact-responsible': WContactResponsible,        
	}, 
	computed:{
		getSelectedPhoneNumberLabelList () {
			return this.labelList[this.selectedPhoneNumber.phonenumberId]
		}
	},
	data () {
		return { 
			contactDetail: [],
			disable: true,
			labelList: [], 
			avtUrl: avatarUrl,
			companyId: this.$route.params.companyId,  
			componentPropertyKey: 0, 
			contactChangeKey: 0,
			componentModalKey: 0,
			defaultValue: '',
			showModal: false,
			historyList: [],
			historyText: 'Histórico completo',
			fields: [
				{
					key: 'labelDescription',
					sortable: true,
					label:'Descrição'
					
				},
				{
					key: 'labelCount',
					sortable: true,
					label:'Mensagens marcadas'
				},
				// {
				// 	key:'labelDepartment',
				// 	sortable:true,
				// 	label:'Departamentos'

				// },
				
				
			],
			reload: 0,
			selectedPhoneNumber: undefined,
			phoneNumberList:undefined
		}
	},    
	methods: {     
		getContactDetail () {                          
			WContactsAPI.getContactDetail(this.$route.params.companyId, this.$route.params.waId, response => {
				if (response){  
					this.contactDetail = response.data  
					this.labelList = response.data.phonenumberLabelMapList  
					console.log(this.contactDetail)
					this.reload++
					let historyList = this.contactDetail.historyVO.historyList
					historyList.forEach(element => {
						element.visible = true
					})
					this.historyList = historyList
				}
			})               
		}, 
		blockContact () {
			WContactsAPI.postBlockContact(this.$route.params.companyId, this.$route.params.waId, response => {
				if (response){
					alert('Operação realizada com sucesso!')
					this.getContactDetail()
				} else {
					alert('Falha ao bloquear contato!')
				}
			}) 
		},
		unBlockContact () {
			WContactsAPI.postUnblockContact(this.$route.params.companyId, this.$route.params.waId, response => {
				if (response){
					alert('Operação realizada com sucesso!')
					this.getContactDetail()
				} else {
					alert('Falha ao bloquear contato!')
				}
			}) 
		},        
		deleteContact (del) {
			if (del == true){
				WContactsAPI.deleteContact(this.$route.params.companyId, this.$route.params.waId, response => {
					if (response){
						this.$router.push({ path: '/contact/'+ this.$route.params.companyId +'/list' }) 
					}
				})                 
			}
		},
		showModalDelete () {
			this.componentModalKey ++
			this.showModal = true
		},        
		timeToDate (time) {
			if (time ==0){
				return '-'
			}
			let d = new Date(Number(time))
			let day = d.getDate()        
			if(day < 10){
				day ='0' + d.getDate() 
			}
			let month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1)
			let sDate  = day  + '/' + month + '/' + d.getFullYear() 
			return sDate
		},    
		getDateTimeFromTimestamp (time) {
			let date = new Date(time)
			return (('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2))
		},  
		getMessageData (obj) {
			console.log('type: ' + obj.mediaType + 'message: ' + obj.msg)
			this.getContactDetail()                                                                      
		},   
		openChatMessage (phonenumberId) { 
			let contactName = this.contactDetail.name
			console.log(this.contactDetail)
			if (contactName == '' || contactName == undefined) {
				contactName = 'Não informado'
			}
			localStorage.removeItem('__wpp_contact_user_open')
			let obj = { phonenumberId: phonenumberId, waId: this.$route.params.waId, name: contactName,contactId:this.contactDetail.contactId}
			this.$store.commit('setContactOpenId',this.contactDetail.contactId)
			localStorage.setItem('__wpp_contact_user_open', JSON.stringify(obj)) 
			let chatResumeOrder = localStorage.getItem('__wpp_company_chat_resume_order')
			if (chatResumeOrder == null || chatResumeOrder == undefined) {
				chatResumeOrder = 0
			}
			let route = this.$router.resolve({path: '/' + this.$route.params.companyId + '/' + chatResumeOrder+ '/chat'})            
			// window.open(route.href)
			route
			this.$router.push({ path: '/' + this.$route.params.companyId + '/' + chatResumeOrder+ '/chat'})
		}, 
		loadMore (time) {                    
			WContactsAPI.getLoadMoreHistory(this.$route.params.companyId, this.$route.params.contactId, time, response => {
				if (response){  
					let historyList = response.data.historyList  
					historyList.forEach(element => {
						element.visible = true
					})
					this.historyList.push(historyList)
				}
			})               
		},   
		filterActionStatus (action, text) {
			this.historyText = text
			this.historyList.forEach( el => {
				if ( el.actionType == action || action == 0){
					el.visible = true
				} else {
					el.visible = false
				}
			})
		},                      
		goBack () {
			if (this.$route.params.whocalled == 'chat'){
				// localStorage.removeItem('__wpp_contact_user_open')
				// let obj = { phonenumberId: this.$route.params.phonenumberId, waId: this.$route.params.waId}
				// localStorage.setItem('__wpp_contact_user_open', JSON.stringify(obj))  
				this.$store.commit('setContactOpenId',this.contactDetail.contactId)
				let chatResumeOrder = localStorage.getItem('__wpp_company_chat_resume_order')
				if (chatResumeOrder == null || chatResumeOrder == undefined) {
					chatResumeOrder = 0
				}            
				this.$router.push({path: '/' + this.$route.params.companyId + '/'+chatResumeOrder+'/chat'}) 
				// this.$router.push({path: '/automation/' + this.$route.params.companyId + '/main'})                            
			} else {
				if (this.$route.params.whocalled == 'dash') {
					localStorage.removeItem('__wpp_dashboard_step') 					
					let objStep = {currentStep: 1, currentMenuItem: 1}
					localStorage.setItem('__wpp_dashboard_step', JSON.stringify(objStep))      									
					this.$router.push({ path: '/dashboard/'+ this.$route.params.companyId +'/main' })                
				} else {
					this.$router.push({ path: '/contact/'+ this.$route.params.companyId +'/list' })                
				}				
			}
		}                     
	},
	async mounted () {
		await this.$store.dispatch('getPhoneNumberList')
		this.phoneNumberList = this.$store.getters.getPhoneNumberList
		this.selectedPhoneNumber = this.phoneNumberList[0]
		console.log(this.selectedPhoneNumber)
		if (this.waId != ''){
			this.getContactDetail()
		}              
	}, 
}
</script>

<style>
.dropdown-activity {
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    border-radius: 8px;  
    width: auto;  
}


.property-list {
    margin-bottom: 3px !important;
    padding: 3px !important;    
    background-color: #F5F5F5 !important;
    border-radius: 100px !important;
    padding-left: 10px !important;    
}
.property-list:first-child {
    border-top-left-radius: none !important;
    border-top-right-radius: none !important;
}

.contact-label-list {
    margin-bottom: 0 !important;
    padding: 2px !important;    
    background-color: white !important;
    border-radius: 100px !important;
    padding-left: 10px !important; 
    border: none !important;   
}

.history-list {
    width: 90% !important;
}

.data-list {
    width: 90% !important;    
}
.data-list:first-child {
    border-top-left-radius: none !important;
    border-top-right-radius: none !important;
}
.tab-active {
    color:#fff !important;
    background-color: var(--secondary) !important;
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 22px !important;
    border-bottom: solid 5px var(--secondary) !important;
}

.history-description { 
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #414141;
}

.label-data-scrollable {
    height: 50vh;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
}

.label-data-scrollable-xl {
    height: 35vh;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>

 
