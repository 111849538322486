<template>
	<div class="team-scrollable scrollbar mt-4">
		<b-col class="pb-5 ml-2">
			<b-overlay :show="loading" variant="light"
				no-wrap>
				<template #overlay>
					<b-spinner style="width: 3rem; height: 3rem; color: #21065c" label="Large Spinner"></b-spinner>
				</template>                    
			</b-overlay>    
			<b-col>
				<div style="display:flex">
					<h1 class="lbl-title-custom ml-1">
						Painel Geral - Desempenho da Equipe
					</h1>

					<div style="margin-left:auto;margin-right:15px">
						<export-button 
							:departmentIdList="this.departmentIdList" 
							:userIdList="this.userIdList"
							:fileName="`Revisao_${timeToDateAmerican(this.startTime)}_${timeToDateAmerican(this.endTime)}`"
							:startTime="this.startTime" 
							:endTime="this.endTime" 
							:currentPhoneNumber="currentPhoneNumber" 
							:dashboardTitle="'Desempenho da Equipe'"
							:cardResource="'team'"
							:reportType="1"
							:cardContrast="true"/>
					</div>
						
				</div>
				<!-- componente period0 -->	
				<b-row>
					<b-col md="3">
						<b-row class="mt-3">
							<span class="select-custom-font">
								Selecione usuário/departamento:
							</span>							
						</b-row>
						<b-row>
							<search-check-box-filter
								:agentFilter="true"
								:sortBy="'text'"	
								:departmentFilter="true"
								:splitReturn="true"
								:toggleClass="'pannel-toggle-dropdown-style'"
								:placeHolder="'Usuário/Departamento'"							
								v-on:checkedItems="setSelectedItems($event)"
							/>
						</b-row>
					</b-col>
					<b-col md="auto" class="ml-4">
						<b-row>
							<dashboard-period :key="componentKey" v-on:sendMessageData="getMessageData"/>
						</b-row>					
					</b-col>
					<b-col md="auto" v-show="showDataFilter == true">
						<b-row class="mt-3 ml-2">
							<!-- <span class="select-custom-font ml-1">
								Período máximo 3 meses:
							</span> -->
							<span class="select-custom-font ml-1">
								Período máximo 2 meses:
							</span>								
						</b-row>
						<b-row class="ml-2">
							<b-dropdown
								:text="selectedPeriod"
								ref="calendar"
								variant="ligth"
							>
								<v-date-picker
									v-model="range"
									mode="date"
									is-range																					
									:columns="$screens({ default: 1, lg: 3 })"
									:is-expanded="$screens({ default: true, lg: false })"
									:min-date='minTime'
									:max-date='maxTime'
									@input="setHide"
									@dayclick="setMinMaxTime"
									@popoverDidShow="clearTime"
									locale = "PT-br"
									color="blue"									
								/>							
							</b-dropdown>
							<b-icon v-show="showIcon == true" class="m-2 icon-clickable" icon="x-circle" scale="1.5" variant="danger" v-b-tooltip.hover title="Clique para limpar" @click="clearTime"></b-icon>							
						</b-row>					
					</b-col>
				</b-row>	
				<b-row class="mr-2">
					<b-col md="4">
						<div class="block-template-custom mt-2" style="margin-left: -15px;">
							<b-row class="ml-3">
								<span class="custom-font-open-14-gray mt-2">
									Até iniciar o atendimento
								</span>
								<popover class="mt-1 ml-2" :id="'showPopHelpContact'" :message="'Até iniciar o atendimento: Média do tempo que leva até um contato ser assumido por algum usuário da sua equipe. A plataforma considera apenas contatos reativos aqui, e calcula o tempo entre a mensagem do seu contato e o momento que seu usuário assumiu a conversa.'" :btnText="'Entendi!'"/>
							</b-row>
							<b-row class="ml-4" style="margin-right: 15px;">					
								<b-col>
									<b-overlay :show="loadingAverage" variant="light"
										no-wrap>
										<template #overlay>
											<b-spinner small style="color: #21065c" label="Large Spinner"></b-spinner>
										</template>                    
									</b-overlay>  									
									<b-row class="justify-content-center"> 
										<span :class="[ 'custom-font-roboto-38', $mq == 'md' ? 'custom-font-roboto-36' : '' ]">
											{{averageAssign}}
										</span>	
									</b-row>						
								</b-col>																			
							</b-row>					
						</div>
					</b-col>
					<b-col md="4">
						<div class="block-template-custom mt-2" style="margin-left: -15px;">
							<b-row class="ml-3 mt-1">
								<span class="custom-font-open-14-gray mt-2">
									Até fechar o atendimento
								</span>	
								<popover class="mt-1 ml-2" :id="'showPopHelpInBox'" :message="'Até resolver o atendimento: Média do tempo entre o atendimento entrar na caixa de algum usuário, até que ele seja fechado.'" :btnText="'Entendi!'"/>								
							</b-row>
							<b-row class="ml-4" style="margin-right: 15px;">					
								<b-col>
									<b-overlay :show="loadingAverage" variant="light"
										no-wrap>
										<template #overlay>
											<b-spinner small style="color: #21065c" label="Large Spinner"></b-spinner>
										</template>                    
									</b-overlay>  										
									<b-row class="justify-content-center">										
										<span :class="[ 'custom-font-roboto-38', $mq == 'md' ? 'custom-font-roboto-36' : '' ]">
											{{averageClose}}
										</span>	
									</b-row>						
								</b-col>																			
							</b-row>					
						</div>								
					</b-col>
				</b-row>								
				<b-row>
					<b-col md="8">
						<b-row class="block-template-custom mt-3">
							<div class="w-100" style="min-height:385px; max-height:385px;">
								<b-row class="ml-3 mt-2">
									<span class="custom-font-open-14-gray">
										VOLUME DE ATENDIMENTOS/SESSÕES POR <b class="custom-font-open-14-800">DATA</b>
									</span>
									<popover class="ml-2" :id="'showPopHelpDepartment'" :message="'No período selecionado, veja o volume de atendimentos por dia, tanto reativos quanto proativos. Assim você pode encontrar o dia do mês ou da semana com maior volume de atendimentos, por exemplo.'" :btnText="'Entendi!'" style="margin-top: -3px;"/>									
								</b-row>
								<b-row class="justify-content-start mt-2 ml-5">
									<b-col md="auto">
										<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
											<circle cx="4" cy="4" r="4" :fill="primaryLineColor"/>
										</svg>
										<span class="custom-font-open-12-gray-2 ml-1">Reativa</span>										
									</b-col>	
									<b-col md="auto">
										<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
											<circle cx="4" cy="4" r="4" :fill="secondaryLineColor"/>
										</svg>
										<span class="custom-font-open-12-gray-2 ml-1">Proativa</span>											
									</b-col>							
								</b-row>	
								<b-row v-if="hasChartData1 == true" class="ml-1">
									<div class="ml-1 chart-responsive">
										<GChart
											:key="componentChart1Key"
											:type="'LineChart'"
											:options="attendanceDateOptions"
											:collectionData="attendanceDateData"
										/> 									
									</div>										
								</b-row>							
								<b-row v-else class="m-5">
									<no-data-found/>
								</b-row>																				
							</div>
						</b-row>							
						<b-row class="block-template-custom mt-3">
							<div class="w-100" style="min-height:385px; max-height:385px">
								<b-row class="ml-3 mt-2">
									<span class="custom-font-open-14-gray">
										VOLUME DE ATENDIMENTOS/SESSÕES POR <b class="custom-font-open-14-800">HORA</b>
									</span>	
									<popover class="ml-2" :id="'showPopHelpUserChat'" :message="'No período selecionado, veja a média de atendimentos feitos durante um dia. Por exemplo, entre dia X e Y, hora Z é a que seus contatos mais procuram seu time.'" :btnText="'Entendi!'" style="margin-top: -3px;"/>																		
								</b-row>	
								<b-row class="justify-content-start mt-2 mb-0 ml-5">
									<b-col md="auto">
										<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
											<circle cx="4" cy="4" r="4" :fill="primaryLineColor"/>
										</svg>
										<span class="custom-font-open-12-gray-2 ml-1">Reativa</span>										
									</b-col>	
									<b-col md="auto">
										<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
											<circle cx="4" cy="4" r="4" :fill="secondaryLineColor"/>
										</svg>
										<span class="custom-font-open-12-gray-2 ml-1">Proativa</span>											
									</b-col>							
								</b-row>		
								<b-row v-if="hasChartData2 == true" class="ml-1">
									<div class="ml-1 chart-responsive">
										<GChart
											:key="componentChart2Key"
											:type="'LineChart'"
											:options="attendanceHourOptions"
											:collectionData="attendanceHourData"
										/>    							
									</div>										
								</b-row>						
								<b-row v-else class="m-5">
									<no-data-found/>
								</b-row>																	
							</div>							
						</b-row>												
					</b-col>
					<b-col md="4">						
						<b-row class="block-template-custom mt-3" style="margin-left: 5px; margin-right: 15px;">
							<div class="w-100" style="min-height:785px; max-height:785px">
								<b-row class="ml-3 mt-2" style="display:flex;align-items:flex-end;justify-content: space-around;align-content: center;">
									<span class="custom-font-open-14-gray" style="display:flex;align-items:center;">
										ATENDIMENTOS POR USUÁRIO
										<span v-if="this.$mq != 'md'">
											<popover class="ml-2" :id="'showPopHelpUserStatus'" :message="'Veja o histórico de todos os atendimentos feitos por usuário. Você pode filtrar esse dado por período ou departamento. Para o dia atual, utilize a aba <b>Tempo Real</b>.'" :btnText="'Entendi!'" style="margin-top: -3px;"/>										
										</span>																			
									</span>
									<div style="margin-right:15px">
										<export-button 
											:departmentIdList="this.departmentIdList" 
											:userIdList="this.userIdList"
											:fileName="`atendimentos_por_usuário_${timeToDateAmerican(this.startTime)}_${timeToDateAmerican(this.endTime)}`"
											:startTime="this.startTime" 
											:endTime="this.endTime" 
											:currentPhoneNumber="currentPhoneNumber" 
											:dashboardTitle="'Equipe'"
											:cardResource="'team'" 
											:selectedCard="'Atendimentos Por Usuário'"
											:reportType="2"/>
									</div>
								</b-row>
								<b-row v-if="this.$mq == 'md'" class="justify-content-end mr-2">
									<popover :id="'showPopHelpUserStatus'" :message="'Veja o histórico de todos os atendimentos feitos por usuário. Você pode filtrar esse dado por período ou departamento. Para o dia atual, utilize a aba <b>Tempo Real</b>.'" :btnText="'Entendi!'" style="margin-top: -3px;"/>										
								</b-row>	
								<div v-if="hasUserList == true" class="ml-2 mr-2" :style="this.$mq == 'md' ? 'margin-top: -15px;' : ''">
									<b-table 
										id="userStatusTable"
										responsive								
										class="mb-2"		
										style="min-height:705px; max-height:705px; overflow-x: hidden; overflow-y: hidden;"						
										:items="orderedUserStatusList"
										:fields="agentFields"
										:per-page="perPageUserStatus"
										:current-page="currentPageUserStatus"	
										:sort-by="sortByUser"
										:sort-desc="true"
										fixed																		
										@head-clicked="rowClickHandler">
										<template #cell(agentName)="data">
											<b-row class="ml-0">																	
												<span class="mt-1 ml-2 description-ellipsis" v-b-tooltip.hover :title="data.item.agentName">
													{{data.item.agentName}}
												</span>																							
											</b-row>	
										</template>																											
									</b-table>											
								</div>	
								<div v-else class="m-5">
									<no-data-found/>
								</div>																								
								<b-row v-show="userStatusRows > perPageUserStatus" class="d-flex justify-content-center mb-3">				
									<b-pagination
										v-model="currentPageUserStatus"
										:total-rows="userStatusRows"
										:per-page="perPageUserStatus"
										pills
										size="sm"
										aria-controls="userStatusTable"
									></b-pagination> 			       
								</b-row> 								  
							</div>
						</b-row>						
					</b-col>					
				</b-row>				
			</b-col>
		</b-col>
	</div>
</template>
<script>
import ConverterDateTime from '@/mixins/ConverterDateTime'
import WDashboardAPI from '@/api/WDashboardAPI'
import WAttendantsAPI from '@/api/WAttendantsAPI'
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import Popover from '@/components/global/Popover'
import GChart from './WDashboardChart'
import WDashboardExportButton from './WDashboardExportButton'
import WDashboardPeriod from './WDashboardPeriod'
import SearchCheckBoxFilterVue from '@/components/filters/SearchCheckBoxFilter'
import NoDataFound from '@/components/global/NoDataFound'
export default {
	name: 'WDashboardTeam',
	components: 
	{   
		GChart,		
		popover: Popover,  
		'dashboard-period': WDashboardPeriod,
		'search-check-box-filter': SearchCheckBoxFilterVue,
		'no-data-found': NoDataFound,
		// eslint-disable-next-line vue/no-unused-components
		'export-button':WDashboardExportButton	   
	},
	props:[
		'currentPhoneNumber'
	],
	mixins: [ConverterDateTime],	
	data () {
		return {            
			loading: false,
			loadingAverage: false,
			selectedDateTime: 'Últimos 7 dias',
			teamData: [],
			showIcon: false,
			minTime: '',
			maxTime: new Date(),						
			startTime: 0,
			endTime: 0,
			showDataFilter: false,
			selectedPeriod: 'Selecionar período',	
			range: {
				start: '',
				end: '',
			},	
			userIdList: [],	
			departmentIdList: [],	
			componentKey: 0,
			componentChart1Key: 0,
			componentChart2Key: 0,
			userList: [],
			agentList: [],
			perPageUserChat: 6,
			currentPageUserChat: 1,  
			perPageUserStatus: 16,
			currentPageUserStatus: 1,			
			attendanceDateData: [],
			attendanceDateOptions: {							
				titleTextStyle: {
					color: '#777777',    
					fontName: 'Open Sans',
					fontSize: 14,
					fontWeight: 600,
					paddingTop: 40
				},		
				focusTarget: 'category',
				curveType: 'function',
				crosshair: {orientation: 'vertical', trigger: 'focus'},
				tooltip: { isHtml: true,
					textStyle: {
						color: '#777777',    
						fontName: 'Open Sans',
						fontSize: 12,
						fontWeight: 700,					
					},								
				},		
				width: '100%',
				height: 320,	
				format: 'none',							
				colors: [this.primaryLineColor, this.secondaryLineColor],
				legend: 'none',
				chartArea:{left:35, right:20, bottom:30, top:15},
				hAxis: {textStyle: {
					color: '#777777',    
					fontName: 'Open Sans',
					fontSize: 12,
					fontWeight: 600,
					paddingTop: 40					
				},
				color: '#e3e3e3',
				baselineColor: '#f3f3f3',
				lineWidth: 2	
				},				
				vAxis: {textStyle: {
					color: '#777777',    
					fontName: 'Open Sans',
					fontSize: 12,
					fontWeight: 600,
					paddingTop: 40,	
				},					
				},				
			},
			attendanceHourData: [],
			attendanceHourOptions: {				
				titleTextStyle: {
					color: '#777777',    
					fontName: 'Open Sans',
					fontSize: 14,
					fontWeight: 600,
					paddingTop: 40
				},		
				focusTarget: 'category',
				curveType: 'function',
				crosshair: {orientation: 'vertical', trigger: 'focus'},
				tooltip: { isHtml: true,
					textStyle: {
						color: '#777777',    
						fontName: 'Open Sans',
						fontSize: 12,
						fontWeight: 700,					
					},								
				},			
				width: '100%',
				height: 320,	
				format: 'none',						
				colors: [this.primaryLineColor, this.secondaryLineColor],
				legend: 'none',
				chartArea:{left:35, right:20, bottom:30, top:15},
				hAxis: {textStyle: {
					color: '#777777',    
					fontName: 'Open Sans',
					fontSize: 12,
					fontWeight: 600,
					paddingTop: 40					
				},
				color: '#e3e3e3',
				baselineColor: '#f3f3f3',
				lineWidth: 2	
				},				
				vAxis: {textStyle: {
					color: '#777777',    
					fontName: 'Open Sans',
					fontSize: 12,
					fontWeight: 600,
					paddingTop: 40,	
				},					
				},				
			},	
			sortByUser: 'closeTime',
			agentFields: [
				{
					key: 'agentName',
					label: 'Usuário',
					sortable: true,
					thStyle: '',
					thClass: 'b-th-left b-th-data-left',
					tdClass: 'b-td-left b-td-data-left'					
				},
				{
					key: 'closeTime',
					label: 'Fechados',
					sortable: true,
					thStyle: '',
					thClass: 'b-th-left b-th-data-left',
					tdClass: 'b-td-left b-td-data-center'	
				},
				{
					key: 'expiredTime',
					label: 'Expirados',
					sortable: true,
					thStyle: '',
					thClass: 'b-th-left b-th-data-left',
					tdClass: 'b-td-left b-td-data-center'	
				},				
											
			],	
			hasChartData1: false,
			hasChartData2: false,
			hasUserList: false,
			departmentList: [],	
			teamAverageData: {},
			averageClose: 'Carregando...',
			averageAssign: 'Carregando...'		
		}
	},    
	computed: {			
		userStatusRows () {
			return this.agentList.length
		},			
		orderedUserStatusList () {
			const items = this.agentList
			return items
		},		
		primaryLineColor () {
			let docStyle = getComputedStyle(document.documentElement)
			let primaryVar = docStyle.getPropertyValue('--primary')	
			return 	primaryVar			
		},	
		secondaryLineColor () {
			let docStyle = getComputedStyle(document.documentElement)
			let secundaryVar = docStyle.getPropertyValue('--secondary')	
			return secundaryVar	
		}						 	                  
	},   	 
	methods: {
		onResize () {
			this.renderChartData(1)
			this.renderChartData(2)
			this.rowClickHandler(this.sortByUser)
		},	
		rowClickHandler (record) {
			this.agentFields.forEach(el => {
				if (el.key == record) {
					if (this.$mq == 'md') {
						el.thStyle = 'color: black !important; font-size: 11px !important;'
					} else {
						el.thStyle = 'color: black !important; font-size: 13px !important;'
					}						
				} else {
					el.thStyle = ''
				}
				if (this.$mq == 'md') {
					el.thClass = 'b-th-left b-th-data-left-custom'
				}
			})
		},			
		clearTime () {
			this.selectedPeriod = 'Selecionar período'
			this.minTime = ''
			this.maxTime = new Date()
			this.range = {}
			this.showIcon = false
			this.firstClick = true
		},
		setMinMaxTime (day) {
			let today = new Date().setHours(23,59,59,999)
			this.firstClick = false
			this.showIcon = true
			this.minTime = day.range.start		
			let startTime = new Date(day.range.start).setHours(0,0,0,0)	
			this.minTime = startTime
			//let endTime = startTime + 90*86400000	
			let endTime = startTime + 60*86400000	
			if (endTime > today) {
				this.maxTime = today
			} else {
				this.maxTime = endTime
			}												
		},
		setHide () {	
			this.firstClick = true		
			let startTime = new Date(this.range.start).setHours(0,0,0,0)
			let endTime = new Date(this.range.end).setHours(23,59,59,999)
			this.selectedPeriod = this.timeToDate(startTime) + '-' + this.timeToDate(endTime)
			this.getTeamData(startTime, endTime)			
			this.getTeamAverageData(startTime, endTime)			
			this.$refs.calendar.hide(true)	
			this.$nextTick(() => {
				this.minTime = ''
				this.maxTime = new Date()				
				// this.range = {}
			})
		},	
		setChartData (chart, header, data) {
			let collectionData = []
			let headerData = []			
			headerData.push('')													
			Object.entries(header).forEach(([key, value]) => {
				key
				// console.log(key + ' ' + value)
				headerData.push(value)							
			})			
			collectionData.push(headerData)			
			for (const item in data) {
				let itemData = []
				let objData = data[item]					
				Object.entries(objData).forEach(([key, value]) => {
					if (key == 'indexXLabel') {	
						if (chart == 1) {
							let year = value.substr(0,4)
							let month = value.substr(4,2)
							let day = value.substr(6,2)
							let dateString = day + '/' + month + '/' + year  						
							itemData.push(dateString)							
						} else {						
							itemData.push(value)							
						}		
					}	
					if (key.includes('columnA') || key.includes('columnB')) {
						itemData.push(value)						
					}							
				})			
				collectionData.push(itemData)
			}			
			return collectionData											
		},			
		renderChartData (chart) {
			if (chart == 1) {
				this.componentChart1Key ++
				this.attendanceDateData = null
				this.attendanceDateData = this.setChartData(chart, this.teamData.perDataHeader, this.teamData.perDataList)					
			} else {
				this.componentChart2Key ++
				this.attendanceHourData = null
				this.attendanceHourData = this.setChartData(chart, this.teamData.perHourHeader, this.teamData.perHourList)						
			}			
		},	
		setDepartmentSelectedItems (list) {
			this.departmentIdList = list	
			this.getTeamData(this.startTime, this.endTime)		
			this.getTeamAverageData(this.startTime, this.endTime)							
		},		
		setSelectedItems (list) {
			this.userIdList = list.agentIdList
			this.departmentIdList = list.departmentIdLIst
			this.getTeamData(this.startTime, this.endTime)				
			this.getTeamAverageData(this.startTime, this.endTime)								
		},				
		getMessageData (obj) {		
			this.range = {}
			if (obj == null) {
				this.showDataFilter = true				
			} else {
				this.showDataFilter = false
				this.startTime = obj.startTime
				this.endTime = obj.endTime
				this.getTeamData(this.startTime, this.endTime)				
				this.getTeamAverageData(this.startTime, this.endTime)				
			}				
		},  					
		getAgentsList () {
			WAttendantsAPI.getAgentsList(this.$route.params.companyId, response => {
				let agents = response.data
				if (agents){        
					let agentsList = []              
					agents.forEach(agent => {
						let agt = {
							text: agent.name,
							value: agent.userId
						}	
						agentsList.push(agt)					
					})     
					this.userList = agentsList                              
				}				
			})    
		},    
		getDepartmentList () {
			WDepartmentsAPI.getDepartmentsOfCompany (
				this.$route.params.companyId, response => {
					let resp = response.data
					resp.forEach(item => {
						let dpt = {
							text: item.departmentName,
							value: item.departmentId
						}
						this.departmentList.push(dpt)
					})
				}
			)
		},  				
		getTeamData (startTime, endTime) {
			console.log(this.range)
			if(this.range.start && this.range.end){
				startTime = new Date(this.range.start).getTime()
				endTime = new Date(this.range.end).getTime()
				startTime = new Date(startTime).setHours(0,0,0,0)
				endTime = new Date(endTime).setHours(23,59,59,59)
			}
			console.log(new Date(startTime))
			console.log(new Date(endTime))
			let phonenumberId = this.currentPhoneNumber
			let userIdList = JSON.stringify(this.userIdList)
			let departmentIdList = JSON.stringify(this.departmentIdList)
			this.loading = true
			WDashboardAPI.postTeamData(this.$route.params.companyId, phonenumberId, startTime, endTime, userIdList, departmentIdList, response => {				
				if (response.data) {
					this.teamData = response.data 
					this.agentList = this.teamData.agentList
					if (this.teamData.agentList.length > 0) {
						this.hasUserList = true
					} else {
						this.hasUserList = false
					}						
					if (this.teamData.perDataList.length > 0) {
						this.hasChartData1 = true
					} else {
						this.hasChartData1 = false
					}	
					if (this.teamData.perHourList.length > 0) {
						this.hasChartData2 = true
					} else {
						this.hasChartData2 = false
					}																				
					this.renderChartData(1)		
					this.renderChartData(2)									
					this.rowClickHandler(this.sortByUser) 						
					this.loading = false   						
				}								
			})   			
		},
		getTeamAverageData (startTime, endTime) {
			if(this.range.start && this.range.end){
				startTime = new Date(this.range.start).setHours(0, 0, 0, 0)
				endTime = new Date(this.range.end).setHours(23, 59, 59, 59)
			}
			this.averageClose = 'Carregando...'
			this.averageAssign= 'Carregando...'	
			this.loadingAverage	= true			
			let phonenumberId = this.currentPhoneNumber
			let userIdList = JSON.stringify(this.userIdList)
			let departmentIdList = JSON.stringify(this.departmentIdList)
			this.loading = true
			WDashboardAPI.postTeamAverageData(this.$route.params.companyId, phonenumberId, startTime, endTime, userIdList, departmentIdList, response => {				
				if (response.data) {
					this.loadingAverage = false
					this.teamAverageData = response.data  	
					this.averageAssign = this.teamAverageData.ASSIGN
					this.averageClose = this.teamAverageData.CLOSE					
				}								
			})   			
		},		
		
	},
	mounted () {
		window.addEventListener('resize', this.onResize)
		let colors = [this.primaryLineColor, this.secondaryLineColor]
		this.attendanceDateOptions.colors = colors			
		this.attendanceHourOptions.colors = colors
		let currentData = new Date()
		currentData.setHours(0,0,0,0)
		this.endTime = currentData.getTime()
		this.startTime = this.endTime - (7*86400000)
		this.getAgentsList()	
		this.getDepartmentList()	
		this.getTeamAverageData(this.startTime, this.endTime)			
		this.getTeamData(this.startTime, this.endTime)				
	}
}
</script>

<style scoped>

.team-scrollable {
    height: 90vh;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.select-custom-font {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #21065C;
}

.user-status-color-true {
    color: #128C7E;
    fill: #128C7E;
}
.user-status-color-false {
    color: #DD4747;
    fill:#DD4747;
}



th {
	border-top-style: none !important;
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #999999 !important;    
}
td {
    margin: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #777777; 
    height: 40px;
    vertical-align: middle !important;
	border-bottom-style: hidden !important;	
}
.td-data {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.header-custom {
	font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #21065C;		
	cursor: pointer;
}

.user-name-ellipsis { 
    white-space: nowrap !important;  
    overflow: hidden !important;
    text-overflow: ellipsis !important;
	width: 200px !important;
}

.chart-responsive {
	width: 96%;
}
</style>
