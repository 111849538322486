<template>
	<div class="m-3 user-list-row">        
		<b-row class="mb-2 ml-2">
			<span class="message-label-preview"> {{ message.body}} </span>
		</b-row>                                                                              
		<b-row>
			<b-col md="8">
				<b-row class="mt-2 ml-2">
					<span v-if="message.status ==4" class="small-font"> {{ getDateTimeFromTimestamp(message.sendReceiveTime)}}</span>
					<span v-else class="small-font"> {{ getDateTimeFromTimestamp(message.sendRequestTime)}}</span>
				</b-row>                                                                                
			</b-col>                   
		</b-row>                                          
            
	</div>                                          
</template>

<script>
export default {
	name: 'WContactLabelList',
	props: [
		'message'
	],
	data () {
		return {            
		}
	},    
	methods: {
		remove () {
			console.log(this.message)
			let obj = {file: null, mediaType: 'labelDelete', msg: {messageId:this.message.messageId,waId:this.message.waId, phonenumberId:this.message.phonenumberId, messageIndex:this.message.messageIndex}, visible: true}
			this.$emit('sendMessageData', obj) 
		},
		getDateTimeFromTimestamp (time) {
			let date = new Date(time)
			return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2)
		},                    
	},
	mounted () {
	}, 
}
</script>

<style scoped>

</style>




 
