<template>
	<div>
		<b-button @click="teste">teste</b-button>
	</div>
	
</template>

<script>

// import Table from '@/components/global/TableComponent/Table'
import WLoginAPI from '@/api/WLoginAPI'
import WAttendantsAPI from '@/api/WAttendantsAPI'
export default {
	components: 
	{   
		// tableT: Table
	},
	data () {
		return {	
			facebookStatus: ''
		}
	},
	computed:{
		getInternetConnection () {
			return window.navigator.onLine
		},
	},
	methods: {
		process () {
			const file = document.querySelector('#upload').files[0]
			console.log(file)
			if (!file) 
				return

			const reader = new FileReader()

			reader.readAsDataURL(file)

			reader.onload = function (event) {
				const imgElement = document.createElement('img')
				imgElement.src = event.target.result
				document.querySelector('#input').src = event.target.result

				imgElement.onload = function (e) {
					const canvas = document.createElement('canvas')
					const MAX_WIDTH = 400

					const scaleSize = MAX_WIDTH / e.target.width
					canvas.width = MAX_WIDTH
					canvas.height = e.target.height * scaleSize

					const ctx = canvas.getContext('2d')

					ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height)

					const srcEncoded = ctx.canvas.toDataURL(e.target, 'image/jpeg')

					// you can send srcEncoded to the server
					document.querySelector('#output').src = srcEncoded
				}
			}
		},
		teste () {
			WLoginAPI.resfreshLogin((response,error) => {
				response
				error
			})  
		},
		addTdClass (value, key, item) {
			console.log(value)
			console.log(key)
			console.log(item)
			return 'row-test'
		},
		getAttendantList () {
			this.$store.commit('setShowLoading', true)

			WAttendantsAPI.getAgentsList(this.$route.params.companyId, response => {
				this.attendantList = response.data
				this.$store.commit('setShowLoading', false)
			})
		}, 
		capture () {
			let canvas = document.getElementById('canvas')
			let video = document.getElementById('video')
			console.log(video)
			canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
			console.log(canvas)
			let img = new Image()
			img.setAttribute('crossorigin', 'anonymous') // works for me
			img.src = canvas.toDataURL()
			console.log(img)
		}
	},
	mounted () {
	}
}
</script>

<style scoped>
.main{
	/* overflow: scroll; */
	position: relative;
	height: 300px;
	width: 300px;
	border: 4px solid red;
}
.main:hover .teste{
	background-color: yellow;
	opacity: 1;
}
.teste{
	position: absolute;
    z-index: 100;
	opacity: 0;
	top: 1px;
	right: 1px;
	transition:ease-in 0.1s;
}

.bottom-content{
	border: 4px solid red;
	position: absolute;
	bottom: 0;
}
</style>