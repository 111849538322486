<template>
	<div>
		<b-row>
			<span class="custom-font-open-14-400-gray">Escolha uma propriedade que será atribuída ao contato que usou a palavra chave</span>        
		</b-row>           
		<!-- <b-row class="mt-2">
			<b-form-group>
				<label for="property-propertyId" class="custom-font-open-16-gray-600">Escolha uma propriedade</label> 
				<b-form-input 
					type="search" 
					id="property-propertyId" 
					list="property-list" 
					debounce="500"
					dropright 
					placeholder="Selecionar" 
					v-model="selectedProperty"
					@input="selectedOption" 
					:disabled="disable"
					autocomplete="off" class="custom-font-open-14-gray"></b-form-input>
				<datalist id="property-list">
					<option v-for="(key, index) in orderBy(propertyList, 'propertyName')" :key="index" :data-value="key.propertyId">{{key.propertyName}}</option>
				</datalist>                
			</b-form-group>  
		</b-row> -->
		<b-row class="ml-1 mb-2">
			<div class="search-checkbox-filter">
				<b-dropdown
					:text="(selectedProperty == ''|| selectedProperty == null) ? 'Selecionar': selectedProperty"
					v-model="selectedProperty"		
					class="custom-font-open-14-gray mt-2"
					placeholder="Selecionar"
					id="property-propertyId2"   
					block ref="dropdown" 
					menu-class="w-100 btn-custom-ligth" 
					toggle-class="dropdown-custom-toggle"
					variant="ligth"
					style="min-width: 250px;">
					<div class="filtered-list-scrollable scrollbar">
						<b-dropdown-item v-for="key in orderDepartmentList"
							@click="selectedOption(key)" 	
							:key="key.propertyId"	
							id="property-list"
							stacked
							name="search-checkbox"
							style="margin-left: 5%; word-break: break-word;"					
						>	
							<b-row class="ml-0 mt-1">  
								<span class="td-data ml-1" style="margin-top:3px" >{{key.propertyName}}</span>   
							</b-row>                                             
						</b-dropdown-item> 
					</div>
					
				</b-dropdown>
			</div>
		</b-row> 

		<div v-show="showValue==true">           
			<b-row>
				<span class="custom-font-open-16-gray-600">Descrição</span>
			</b-row>   
			<b-row>
				<span class="custom-font-open-14-gray">{{description}}:</span>
			</b-row>    
			<b-row class="mb-5 w-50">
				<label for="input" class="custom-font-open-14-gray">Valor:</label>  
				<b-form-input id="input" type="text" 
					v-model="value" class="custom-font-open-14-gray" @input="setProperty()">
				</b-form-input>                  
			</b-row>                      
		</div>                        
	</div>                                          
</template>

<script>
import WPropertiesAPI from '@/api/WPropertiesAPI'
import Vue2Filters from 'vue2-filters'
import WSortBy from '@/js/utils/WSortBy'
export default {
	mixins: [Vue2Filters.mixin],
	name: 'WAutomationAgents',    
	data () {
		return { 
			filter: '',
			description: '',
			value: '',
			showValue: false,
			selectedProperty: '',
			propertyName: '',
			propertyKey: '',
			propertyList: [],
			propertyId: ''
		}
	},
	computed: {	
		orderDepartmentList () {	
			return WSortBy.orderMyList('propertyName',this.propertyList)
		},      	
	},      
	methods: {
		selectedOption (key) {
			this.showValue = true
			this.selectedProperty = key.propertyName
			this.propertyId = key.propertyId
			this.description = key.propertyDescription                 
		},
		// selectedOption () {
		// 	this.showValue = true
		// 	let val = document.getElementById('property-propertyId')
		// 	let opts = document.getElementById('property-list').childNodes
		// 	console.log(val)
		// 	console.log(opts)
		// 	for (let i = 0; i < opts.length; i++){
		// 		if (opts[i].value === val){
		// 			this.propertyKey = opts[i].value 
		// 			let propertyId = opts[i].getAttribute('data-value')
		// 			this.propertyId = propertyId
		// 			this.propertyList.forEach(el => {
		// 				if (el.propertyId == propertyId){
		// 					this.description = el.propertyDescription
		// 				}
		// 			})
		// 			break
		// 		}
		// 	}                   
		// },  
		setProperty () {
			let obj = {id: this.propertyId, extra: this.value}
			this.$emit('sendMessageData', obj)                         
		},          
		getPropertyList () {
			WPropertiesAPI.getPropertyList(this.$route.params.companyId, response => {
				this.propertyList = response.data  
			})    
		},                       
	},
	mounted () {    
		this.getPropertyList()                        
	}, 
}
</script>

<style scoped>
.filtered-list-scrollable {
		height: 30vh;
		width: 100%;
		background: #fff;
		overflow-y: auto;
		overflow-x: hidden;
	}

.search-checkbox-filter .dropdown-custom-toggle {
        display: inline-block;
        margin-left: 320px;
        vertical-align: 10px;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        right: 10px;
        position: absolute;
        margin-top: 10px;		
    }
</style>

 
