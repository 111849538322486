export default {  	
	methods: {	
		timeToDate (time) {
			if (time ==0){
				return '-'
			}
			let d = new Date(Number(time))
			let day = d.getDate()        
			if(day < 10){
				day ='0' + d.getDate() 
			}
			let month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1)
			let sDate  = day  + '/' + month + '/' + d.getFullYear() 
			return sDate
		},
		timeToDateAmerican (time) {
			if (time ==0){
				return '-'
			}
			let d = new Date(Number(time))
			let day = d.getDate()        
			if(day < 10){
				day ='0' + d.getDate() 
			}
			let month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1)
			let sDate  = d.getFullYear() + month + day  
			return sDate
		},
		convertDate (date) {
			let converted = date.replace(/(..)\/(..)\/(....) (..):(..)/, '$3-$2-$1 $4:$5')
			return new Date(converted)
		},   

		timeToHour (time) {
			if( time == null){
				return ''
			}
			let d = new Date(Number(time))

			let fHourMinute = d.getHours() + ':'
			if(d.getMinutes()<10){
				fHourMinute+='0' 
			}
			fHourMinute+=d.getMinutes()
			return fHourMinute
		},    
		
		timeToDateOrHour (time) {
			if( time == null){
				return ''
			}
			let currentData = new Date() //your date object
			currentData.setHours(23,59,59,999)
			let endTime = currentData.getTime()
			let startTime = endTime - (1 * 86400000) 
	
			let d = new Date(Number(time)) 
			let day = d.getDay()           
			if (startTime <= time){
				let fHourMinute = d.getHours() + ':'
				if(d.getMinutes()<10){
					fHourMinute+='0' 
				}
				fHourMinute+=d.getMinutes()
				return fHourMinute            
			} else {
				day = d.getDate()        
				if(day < 10){
					day ='0' + d.getDate() 
				}
				let month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1)
				let sDate  = day  + '/' + month + '/' + d.getFullYear() 
				return sDate            
			}
		},       

		dayOfWeekAsString (time) {
			let d = new Date(Number(time))
			let day = d.getDay() 
			let td = new Date()
			if (day == td.getDay() ){
				day = 7
			}
			if (day == td.getDay() -1 ){
				day = 8
			}                                  
			let weekday = new Array(8)
			weekday[0] = 'DOMINGO'
			weekday[1] = 'SEGUNDA-FEIRA'
			weekday[2] = 'TERÇA-FEIRA'
			weekday[3] = 'QUARTA-FEIRA'
			weekday[4] = 'QUINTA-FEIRA'
			weekday[5] = 'SEXTA-FEIRA'
			weekday[6] = 'SÁBADO'
			weekday[7] = 'HOJE'  
			weekday[8] = 'ONTEM'  
			let dayStr = weekday[day]
			return dayStr                                  
		},
		getDateTimeFromTimestamp (time) {
			let date = new Date(time)
			return (('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2))
		},   
		getCurrentTime () {
			let date = new Date()		
			return this.timeToDate(date) + ' ' + this.timeToHour(date)
		},
		formatDate (date) {
			const dob = new Date(date)
			
			const monthNames = [
				'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
				'Agosto', 'Setembro', 'Outubo', 'Novembro', 'Dezembro'
			]
			
			const day = dob.getDate()
			const monthIndex = dob.getMonth()
			const year = dob.getFullYear()
			
			// return day + ' ' + monthNames[monthIndex] + ' ' + year;
			return `${day} ${monthNames[monthIndex]} ${year}`
		}		
	}	       
}