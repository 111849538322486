<template>
	<b-col md="12" class="phonenumber-scrollable scrollbar">
		<b-row class="mb-0 mt-4">    
			<b-col md="1" v-show="step ==2.1" class="mt-1">
				<span class="icon-clickable" @click="goBack">
					<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="40" height="40" rx="8" fill="#F5F5F5"/>
						<path d="M18.2622 18.7135L23.9969 13.0243C24.6018 12.4292 24.6097 11.4692 24.0146 10.8643C23.4433 10.2836 22.4594 10.2756 21.8547 10.8467L15.0549 17.6312C14.9825 17.6786 14.9339 17.7502 14.9095 17.798C14.8615 17.7977 14.8611 17.8457 14.8611 17.8457C14.8128 17.8933 14.788 17.9891 14.7394 18.0607C14.7392 18.0847 14.7152 18.0845 14.715 18.1085C14.6904 18.1803 14.69 18.2283 14.6654 18.3001C14.6652 18.3241 14.617 18.3477 14.6166 18.3957C14.6162 18.4437 14.6157 18.5157 14.6153 18.5637C14.6151 18.5877 14.5905 18.6595 14.5903 18.6835C14.5897 18.7555 14.6135 18.7797 14.6131 18.8277C14.6127 18.8757 14.6123 18.9237 14.6117 18.9956C14.6115 19.0196 14.6593 19.044 14.6587 19.116C14.6825 19.1402 14.682 19.2122 14.7056 19.2604C14.7054 19.2844 14.729 19.3326 14.7768 19.357C14.8002 19.4292 14.7998 19.4772 14.8472 19.5496C14.8472 19.5496 14.895 19.574 14.9188 19.5982C14.9422 19.6704 14.9658 19.7186 15.0376 19.7432L21.7254 26.6381C22.035 26.9287 22.4178 27.0758 22.8258 27.0792C23.2098 27.0823 23.571 26.9412 23.8853 26.6558C24.4902 26.0607 24.4979 25.1248 23.903 24.4959L18.2622 18.7135Z" fill="#414141"/>
					</svg>
				</span>
			</b-col>             
			<b-col>
				<b-row>  
					<span v-if="step==2.1"> <span class="lbl-title mr-2">Detalhes do número:</span> <span class="custom-font-open-24-400">{{phoneNumber}}</span></span>   
					<span v-else class="lbl-title">Editar número</span>                           
				</b-row>
			</b-col>
		</b-row>  
		<div class="w-100">
			<hr>
		</div>          
		<b-row>
			<b-col md="6">
				<div class="ml-3">
					<b-row>
						<span class="custom-font-open-16-700">Configurações de atendimento</span>					
					</b-row>
					<b-row class="mt-2">
						<span>Departamento padrão para receber atendimento</span>					
					</b-row>
					<b-row class="mt-1 ml-1">
						<select-dropdown
							:key="componentDepartmentKey"
							:list="departments"
							:defaultValue="selectValueDepartment"
							v-on:selectedItem="setSelectedItemDepartment"
						/>
					</b-row>
					<b-row class="mt-3">
						<span class="custom-font-open-16-700">Dias de atendimento </span>					
					</b-row>
					<b-row class="mt-2 mb-2">
						<b-form-radio
							:name="'option-selected'"
							v-model="openingHourRule"
							value="0"
							class="mr-2">              
							<span class="td-data">Sempre aberto</span> 
						</b-form-radio>  
						<b-form-radio
							:name="'option-selected'"
							v-model="openingHourRule"
							value="1"
							class="ml-2"
						>                 
							<span class="td-data">Aberto no horário selecionado</span> 
						</b-form-radio>    					  					
					</b-row>
					<div v-show="openingHourRule ==1" class="mt-3">
						<b-row>
							<span class="custom-font-open-14"><i>Mantenha 00:00 para dias que seu negócio estiver fechado.</i></span>							
						</b-row>							
						<b-row class="mt-2">					
							<span class="checkbox-custom-width custom-font-open-14">Segunda-Feira:</span>
							<b-form-input
								type="text"
								placeholder="00:00"
								v-model="mondayStartHour"
								class="input-custom-width ml-2"
								v-mask="'##:##'"
							></b-form-input>
							<span class="mt-2 ml-2 mr-3 custom-font-open-12-normal">até</span>
							<b-form-input
								type="text"
								placeholder="00:00"
								v-model="mondayEndHour"
								class="input-custom-width"
								v-mask="'##:##'"
							></b-form-input>	
							<b-link href="javascript:void(0);" class="custom-font-open-12-normal ml-2 mt-2" @click="setInputTime(mondayStartHour, mondayEndHour)">Aplicar p/ todos</b-link>									         
						</b-row>
						<b-row class="mt-1">					
							<span class="checkbox-custom-width custom-font-open-14">Terça-Feira:</span>   
							<b-form-input
								type="text"
								placeholder="00:00"
								v-model="tuesdayStartHour"
								class="input-custom-width ml-2"
								v-mask="'##:##'"
							></b-form-input>
							<span class="mt-2 ml-2 mr-3 custom-font-open-12-normal">até</span>
							<b-form-input
								type="text"
								placeholder="00:00"
								v-model="tuesdayEndHour"
								class="input-custom-width"
								v-mask="'##:##'"
							></b-form-input>	
							<b-link href="javascript:void(0);" class="custom-font-open-12-normal ml-2 mt-2" @click="setInputTime(tuesdayStartHour, tuesdayEndHour)">Aplicar p/ todos</b-link>												   					
						</b-row>
						<b-row class="mt-1">						
							<span class="checkbox-custom-width custom-font-open-14">Quarta-Feira:</span>   						 
							<b-form-input
								type="text"
								placeholder="00:00"
								v-model="wednesdayStartHour"
								class="input-custom-width ml-2"
								v-mask="'##:##'"
							></b-form-input>
							<span class="mt-2 ml-2 mr-3 custom-font-open-12-normal">até</span>
							<b-form-input
								type="text"
								placeholder="00:00"
								v-model="wednesdayEndHour"
								class="input-custom-width"
								v-mask="'##:##'"
							></b-form-input>	
							<b-link href="javascript:void(0);" class="custom-font-open-12-normal ml-2 mt-2" @click="setInputTime(wednesdayStartHour, wednesdayEndHour)">Aplicar p/ todos</b-link>																		    					
						</b-row>	
						<b-row class="mt-1">
							<span class="checkbox-custom-width custom-font-open-14">Quinta-Feira:</span> 					
							<b-form-input
								type="text"
								placeholder="00:00"
								v-model="thursdayStartHour"
								class="input-custom-width ml-2"
								v-mask="'##:##'"
							></b-form-input>
							<span class="mt-2 ml-2 mr-3 custom-font-open-12-normal">até</span>
							<b-form-input
								type="text"
								placeholder="00:00"
								v-model="thursdayEndHour"
								class="input-custom-width"
								v-mask="'##:##'"
							></b-form-input>
							<b-link href="javascript:void(0);" class="custom-font-open-12-normal ml-2 mt-2" @click="setInputTime(thursdayStartHour, thursdayEndHour)">Aplicar p/ todos</b-link>													 					
						</b-row>	
						<b-row class="mt-1">
							<span class="checkbox-custom-width custom-font-open-14">Sexta-Feira:</span> 					
							<b-form-input
								type="text"
								placeholder="00:00"
								v-model="fridayStartHour"
								class="input-custom-width ml-2"
								v-mask="'##:##'"
							></b-form-input>
							<span class="mt-2 ml-2 mr-3 custom-font-open-12-normal">até</span>
							<b-form-input
								type="text"
								placeholder="00:00"
								v-model="fridayEndHour"
								class="input-custom-width"
								v-mask="'##:##'"
							></b-form-input>	
							<b-link href="javascript:void(0);" class="custom-font-open-12-normal ml-2 mt-2" @click="setInputTime(fridayStartHour, fridayEndHour)">Aplicar p/ todos</b-link>												    				
						</b-row>
						<b-row class="mt-1">
							<span class="checkbox-custom-width custom-font-open-14">Sábado:</span> 					
							<b-form-input
								type="text"
								placeholder="00:00"
								v-model="saturdayStartHour"
								class="input-custom-width ml-2"
								v-mask="'##:##'"
							></b-form-input>
							<span class="mt-2 ml-2 mr-3 custom-font-open-12-normal">até</span>
							<b-form-input
								type="text"
								placeholder="00:00"
								v-model="saturdayEndHour"
								class="input-custom-width"
								v-mask="'##:##'"
							></b-form-input>	
							<b-link href="javascript:void(0);" class="custom-font-open-12-normal ml-2 mt-2" @click="setInputTime(saturdayStartHour, saturdayEndHour)">Aplicar p/ todos</b-link>												    					
						</b-row>	
						<b-row class="mt-1">
							<span class="checkbox-custom-width custom-font-open-14">Domingo:</span>					
							<b-form-input
								type="text"
								placeholder="00:00"
								v-model="sundayStartHour"
								class="input-custom-width ml-2"
								v-mask="'##:##'"
							></b-form-input>
							<span class="mt-2 ml-2 mr-3 custom-font-open-12-normal">até</span>
							<b-form-input
								type="text"
								placeholder="00:00"
								v-model="sundayEndHour"
								class="input-custom-width"
								v-mask="'##:##'"
							></b-form-input>	
							<b-link href="javascript:void(0);" class="custom-font-open-12-normal ml-2 mt-2" @click="setInputTime(sundayStartHour, sundayEndHour)">Aplicar p/ todos</b-link>																	
						</b-row>					
					</div>									
					<b-row class="mt-4">
						<div class="mt-2">
							<span>Confirmação de leitura</span>
						</div>
						<div class="ml-2">
							<b-form-checkbox v-model="readConfirmation" name="check-button" switch>							
							</b-form-checkbox>
						</div>  					
					</b-row>	
					<b-row class="mt-1">
						<span class="custom-font-open-10-400">Ao ativar essa opção irá aparecer um ícone de confirmação de leitura nas mensagens <br> de todos os chats </span>
					</b-row>
					<b-row class="mt-4" style="margin-left:0px;">
						<b-col sm="7" style="padding:0px;">
							<b-row class="">
								<div class="">
									<span>Cor do número</span>
								</div>
							</b-row>	
							<b-row class="mt-1">
								<span class="custom-font-open-10-400">Essa cor será usada para individualizar o número no sistema, por exemplo, os atendimentos em Bate-papo. Só aplicável para quem possui mais de um número na plataforma.</span>
							</b-row>
						</b-col>
						<b-col class="ml-3" sm="4" style="padding:0px">
							<b-row style="margin-left:0px">
								<b-form-input class="ml-2 mt-3" style="width:35px;" type="color" v-model="numberColor" :value="numberColor"></b-form-input>
								<b-form-input class="ml-2 mt-3" style="width:100px;" placeholder="#21267F" v-model="numberColor"></b-form-input>
							</b-row>
							
						</b-col>
					</b-row>
					
				
				</div>				
			</b-col>

			<b-col md="6">
				<b-row style="margin-left: 1px;">
					<b-col>
						<b-row>
							<h6 class="custom-font-open-16-700">Perfil comercial</h6>
						</b-row>
						<b-row>
							<span>{{phonenumberName}}</span>
						</b-row>
						<b-row>
							<span class="custom-font-open-14"><i>Nome do seu negócio, conforme sua página do Facebook.</i></span>	
						</b-row>
					</b-col>
					<b-col>
						<phonenumber-photo :mode="'upd'" :phonenumberId="phonenumberId" v-on:sendMessageData="getMessageData"/>                    
					</b-col>
				</b-row>    
				<div class="mr-3">
					<b-row>           
						<label>Categoria/segmento</label>                             
					</b-row> 	
					<b-row class="ml-1">
						<select-dropdown
							:key="componentSegmentKey"
							:list="vertical"					
							:defaultValue="selectValueSegment"
							v-on:selectedItem="setSelectedItemSegment"
						/>  					
					</b-row>				                 				                     
					<b-row class="mt-2">              
						<label for="about">Sobre/Bio</label>   
						<b-form-input 
							id="about" 
							type="text"
							v-model="about" 
						/>                     
					</b-row>    
					<b-row class="mt-2">              
						<label for="companyDescription">Descrição da empresa</label>   
						<b-form-input 
							id="companyDescription" 
							type="text"
							v-model="companyDescription" 
						/>                     
					</b-row>    
					<b-row class="mt-2">              
						<label for="address">Endereço da empresa</label>   
						<b-form-input 
							id="address" 
							type="text"
							v-model="address" 
						/>                     
					</b-row>    
					<b-row class="mt-2">              
						<label for="site">Site</label>   
						<b-form-input 
							id="site" 
							type="text"
							v-model="site" 
						/>                     
					</b-row>  
					<b-row class="mt-2">              
						<label for="email">Email para contato comercial</label>   
						<b-form-input 
							id="email" 
							type="text"
							v-model="email" 
						/>                     
					</b-row>  						
				</div> 													
			</b-col>               
		</b-row>       
		<b-row class="mt-4">
			<b-col>
				<div class="d-flex justify-content-center">
					<b-button variant="outline-primary" class="mr-2"
						@click="cancel()">Cancelar
					</b-button>                         
					<b-button variant="primary" 
						@click="save()">Salvar alteração
					</b-button>                                               
				</div>
			</b-col>            
		</b-row>  		     
	</b-col>
</template>

<script>
import WPhoneNumberPhoto from './WPhoneNumberPhoto'
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import WBusinessAPI from '@/api/WBusinessAPI'
import SelectDropdown from '../../global/SelectDropdown'
export default {
	name: 'WPhoneNumber',
	props: ['step'],
	components: {   
		'select-dropdown': SelectDropdown,
		'phonenumber-photo': WPhoneNumberPhoto
	},	
	data () {
		return {
			phonenumberId: '',
			numberColor: '#21267F',
			departmentId: '',
			phoneNumberDetail: {},
			departments: [],        
			vertical: [],   
			phoneNumber: '',
			btnDisable: true,
			openingHourRule: 0,
			readConfirmation: false,
			selectValueDepartment: '',
			selectValueSegment: '',
			mondayStartHour: 0,
			mondayEndHour: 0,  
			tuesdayStartHour: 0,
			tuesdayEndHour: 0,  
			wednesdayStartHour: 0,
			wednesdayEndHour: 0,  
			thursdayStartHour: 0,
			thursdayEndHour: 0,  
			fridayStartHour: 0,
			fridayEndHour: 0,  
			saturdayStartHour: 0,
			saturdayEndHour: 0,  
			sundayStartHour: 0,
			sundayEndHour: 0, 
			sunday: false,
			monday: false,
			tuesday: false,
			wednesday: false,
			thursday: false,
			friday: false,
			saturday: false,
			about: '',
			companyDescription: '',
			address: '',
			site: '',
			email: '',
			alwaysOpen: '',
			componentDepartmentKey: 0,
			componentSegmentKey: 0,
			phonenumberName: '',
			file: null																                                      
		}
	},    
	methods: {
		setInputTime (startTime, endTime) {
			this.sundayStartHour = startTime     
			this.sundayEndHour = endTime     
			this.mondayStartHour = startTime        
			this.mondayEndHour = endTime     
			this.tuesdayStartHour = startTime     
			this.tuesdayEndHour = endTime
			this.wednesdayStartHour = startTime     
			this.wednesdayEndHour = endTime     
			this.thursdayStartHour = startTime     
			this.thursdayEndHour = endTime   
			this.fridayStartHour = startTime        
			this.fridayEndHour = endTime 		
			this.saturdayStartHour = startTime 
			this.saturdayEndHour = endTime 				
		},
		setBtnDisable () {
			this.btnDisable = false
		},
		setSelectedItemDepartment (id) {
			this.departmentId = id
		},
		setSelectedItemSegment (id) {
			this.selectValueSegment = id
		},		
		getMessageData (obj) {
			this.file = obj
			if (this.file != null) {
				WBusinessAPI.postUploadLogo(this.$route.params.companyId, this.phonenumberId, this.file, response => {
					if (response == true) {
						alert('Logo adicionado com sucesso!')
					} else {
						alert('Falha ao adicionar logo!')						
					}	
				})  		
			} else {
				alert('Falha ao adicionar logo!')
			}
		},    
          
		getPhoneNumberDetail () {
			WBusinessAPI.getDetail(this.$route.params.companyId, this.phonenumberId, response => {
				this.phoneNumberDetail = response.data
				this.mondayStartHour = this.phoneNumberDetail.mondayStartHour
				this.mondayEndHour = this.phoneNumberDetail.mondayEndHour
				this.tuesdayStartHour = this.phoneNumberDetail.tuesdayStartHour
				this.tuesdayEndHour = this.phoneNumberDetail.tuesdayEndHour
				this.wednesdayStartHour = this.phoneNumberDetail.wednesdayStartHour
				this.wednesdayEndHour = this.phoneNumberDetail.wednesdayEndHour                                                                                                                                      
				this.thursdayStartHour = this.phoneNumberDetail.thursdayStartHour
				this.thursdayEndHour = this.phoneNumberDetail.thursdayEndHour
				this.fridayStartHour = this.phoneNumberDetail.fridayStartHour
				this.fridayEndHour = this.phoneNumberDetail.fridayEndHour
				this.saturdayStartHour = this.phoneNumberDetail.saturdayStartHour
				this.saturdayEndHour = this.phoneNumberDetail.saturdayEndHour
				this.sundayStartHour = this.phoneNumberDetail.sundayStartHour
				this.sundayEndHour = this.phoneNumberDetail.sundayEndHour
				this.about = this.phoneNumberDetail.about
				this.companyDescription = this.phoneNumberDetail.description
				this.address = this.phoneNumberDetail.address
				this.site = this.phoneNumberDetail.site	
				this.email = this.phoneNumberDetail.email
				this.numberColor = this.phoneNumberDetail.primaryColor
				if (this.phoneNumberDetail.vertical != '') {
					this.componentSegmentKey ++
					this.selectValueSegment = this.phoneNumberDetail.vertical					
				}	
				if (this.phoneNumberDetail.departmentNameDefault != '') {
					this.componentDepartmentKey ++
					this.selectValueDepartment = this.phoneNumberDetail.departmentNameDefault
					this.departmentId = this.phoneNumberDetail.departmentIdDefault					
				}				
				this.openingHourRule = this.phoneNumberDetail.openingHourRule
				if (this.phoneNumberDetail.readConfirmation == '0') {
					this.readConfirmation = false
				} else {
					this.readConfirmation = true
				}							
			})            
		},            
		save () {
			let readConfirmation = 0
			if (this.site.search(/^http[s]?:\/\//) == -1) {
				this.site = 'https://' + this.site
			}
			if(this.email.includes('@') == false && this.email != ''){
				alert('email inválido')
				return
			}
			if (this.readConfirmation == true) {
				readConfirmation = 1
			}
			this.alwaysOpen = 0
			if (this.openingHourRule == 0) {
				this.alwaysOpen = 1
				this.sundayStartHour = 0     
				this.sundayEndHour = 0     
				this.mondayStartHour = 0        
				this.mondayEndHour = 0     
				this.tuesdayStartHour = 0     
				this.tuesdayEndHour = 0
				this.wednesdayStartHour = 0     
				this.wednesdayEndHour = 0     
				this.thursdayStartHour = 0     
				this.thursdayEndHour = 0   
				this.fridayStartHour = 0        
				this.fridayEndHour = 0 		
				this.saturdayStartHour = 0 
				this.saturdayEndHour = 0 				
			}
			let objBusiness = {
				departmentId: this.departmentId,
				alwaysOpen: this.alwaysOpen,    
				sundayStartHour: this.sundayStartHour,     
				sundayEndHour: this.sundayEndHour,     
				mondayStartHour: this.mondayStartHour,        
				mondayEndHour: this.mondayEndHour,     
				tuesdayStartHour: this.tuesdayStartHour,     
				tuesdayEndHour: this.tuesdayEndHour,
				wednesdayStartHour: this.wednesdayStartHour,     
				wednesdayEndHour: this.wednesdayEndHour,     
				thursdayStartHour: this.thursdayStartHour,     
				thursdayEndHour: this.thursdayEndHour,   
				fridayStartHour: this.fridayStartHour,        
				fridayEndHour: this.fridayEndHour, 		
				saturdayStartHour: this.saturdayStartHour, 
				saturdayEndHour: this.saturdayEndHour, 
				readConfirmation: readConfirmation, 
				vertical: this.selectValueSegment, 
				about: this.about, 	
				description: this.companyDescription, 	
				site: this.site, 												
				email: this.email, 		
				address: this.address, 
				primaryColor: this.numberColor

			}
			WBusinessAPI.postUpdateBusiness(this.$route.params.companyId, this.phonenumberId, objBusiness, response => {
				if (response == true) {
					alert('Alterações salvas com sucesso! Elas podem levar de 5 a 10 minutos para serem aplicadas.')
					this.getPhoneNumberDetail() 
				} else {
					alert('Falha ao atualizar número!')
				}	
			})            
		},  
		getVertical () {
			WBusinessAPI.getVertical(this.$route.params.companyId, this.phonenumberId, response => {
				let vertical = response.data
				let list = []
				for (const key in vertical) {
					let obj = {id: key, name: vertical[key]}
					list.push(obj)
				}		
				this.vertical = list                                                                                                                                                     
			})            
		},  

		cancel () {
			let obj = {step: 2}
			this.$emit('sendMessageData', obj)                    
		},      
		getDepartmentList () {
			WDepartmentsAPI.getDepartmentsOfCompany(this.$route.params.companyId, response => {
				let departments = response.data
				let list = []
				departments.forEach(el => {
					let obj = {id: el.departmentId, name: el.departmentName}
					list.push(obj)
				})				
				this.departments = list
			})
		},   	 		
		goBack () {
			if(this.backToDepartment){
				let obj = {step: 4}
				this.$emit('sendMessageData', obj)  			
			} else {
				let obj = {step: 2}
				this.$emit('sendMessageData', obj)
			}
		}     		
	},
	mounted () {
		this.getDepartmentList()
		let objPhoneNumber = JSON.parse(localStorage.getItem('__wpp_settings_obj'))
		if (objPhoneNumber != null){
			this.phonenumberId = objPhoneNumber.phonenumberId 
			this.phoneNumber = objPhoneNumber.phoneNumber
			this.phonenumberName = objPhoneNumber.phonenumberName
			this.backToDepartment = objPhoneNumber.backToDepartment
			this.getPhoneNumberDetail()
			this.getVertical()
		}         
	}, 
}
</script>

<style scoped>

.custom-control {
    margin-top: 10px !important;
}

.phonenumber-scrollable {
    height: 90vh;
    width: 100%;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
}

.input-custom-width {
	width: 80px !important;
	border-radius: 8px !important;
}

.checkbox-custom-width {
	margin-top: 10px;
	width: 110px !important;	
}

.tooltip-inner >>> .custom-tooltip {
	max-width: 500px !important;
	width: 400px !important;
}  
</style>
