<template>
	<div>
		<b-col v-if="innerStep == 6.1 || innerStep == 6.2" md="10">
			<b-row class="ml-3 mt-3">  
				<div v-if="step == 6.1" >
					<b-row>
						<h3 class="lbl-title">Nova Marcação</h3>
					</b-row>
					
					<b-row>
						<label class="custom-font-open-14-gray">Crie um nome e uma descrição para sua nova marcação. Na descrição, utilize frases claras para espicificar como aquela marcação deve ser usada.</label>
					</b-row>
				</div>    
				<h3 v-else class="lbl-title">Editar marcação</h3>                                                           
			</b-row>
			<div class="w-100">
				<hr>
			</div>   
			<b-row class="ml-1">
				<label class="custom-font-open-16-gray-600">Criar nova marcação</label>
			</b-row>
			<b-row class="my-1">
				
				<b-col md="10">
					<b-form-input id="input" v-model="labelName" class="custom-font-open-14" placeholder="Descrição"></b-form-input>
				</b-col>
			</b-row>     
			<b-row class="ml-1 mt-4">
				<label class="custom-font-open-16-gray-600">Descrição da Marcação</label>
			</b-row>
			<b-row class="my-">
				<b-col md="10">
					<b-form-textarea rows="6" id="input2" v-model="labelDescription" class="custom-font-open-14" placeholder="Descrição"></b-form-textarea>
				</b-col>
			</b-row>  
			

			<b-row class="mt-5">
				<b-col>
					<div class="d-flex justify-content-center">
						<b-button variant="outline-primary" class="mr-2"
							@click="cancel()">Cancelar
						</b-button>                      
						<b-button variant="primary" 
							@click="next()">Avançar
						</b-button>                                          
					</div>
				</b-col>            
			</b-row>          
		</b-col>
	</div>
</template>

<script>
import WLabelsAPI from '@/api/WLabelsAPI'
export default {
	name: 'WLabel',
	components:{
	},
	props: [
		'step',
		'loadedLabel'
	],
	data () {
		return {
			labelId: '',
			phonenumberId: '',
			labelName: '',
			labelDescription:'',
			innerStep: 6.1,
			currentLabel: {}
		}
	},
	methods: {          
		next () {
			if (this.labelName == '' || this.labelName == undefined){
				alert('Campo descrição obrigatório!')
				return
			}     
			if (this.step == 6.1){
				WLabelsAPI.postCreate(this.$route.params.companyId, this.labelName,this.labelDescription, response => {
					if (response){       
						this.currentLabel = response.data
						this.$emit('editLabelDepartment', {label:this.currentLabel, step:6.3})
					} else {
						alert('Falha ao criar marcação!')
					}
				})                      
			} else {
				WLabelsAPI.postDescription(this.$route.params.companyId, this.labelId, this.labelName,this.labelDescription, response => {
					if (response){  
						this.currentLabel = response.data
						let obj = {step: 6}
						this.$emit('sendMessageData', obj)
					} else {
						alert('Falha ao editar marcação!')
					}
				})                      
			}
		},
		cancel () {
			let obj = {step: 6}
			this.$emit('sendMessageData', obj)                    
		}
	},
	mounted () {
		if (this.loadedLabel != null){
			this.currentLabel = this.loadedLabel
			this.labelId = this.currentLabel.labelId 
			this.labelName = this.currentLabel.labelDescription
			this.labelDescription = this.currentLabel.labelDetail
		}   
	}        
}
</script>         
<style>

</style>       