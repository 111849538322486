<template>
	<b-col>
		<b-row class="justify-content-center ml-0" v-show="manualTemplate ==false">
			<h4 v-if="chatStatus == 1 || chatStatus == 4">Iniciando bate-papo</h4>  
			<h4 v-else>Aguardando resposta</h4>             
		</b-row>
		<b-row class="justify-content-center ml-0" v-show="manualTemplate ==false">
			<span v-if="chatStatus == 1 || chatStatus == 4" class="small-custom-font">Para enviar uma mensagem proativa, você precisa utilizar um template pré aprovado</span>
			<span v-else class="small-custom-font">Até que o contato lhe responda você só poderá enviar templates pré-aprovados</span>                        
		</b-row>
		<b-row>
			<b-modal 
				v-model="show"
				:title="title"
				centered
				scrollable>
				<b-col v-show="step != 3">
					<b-input-group>
						<b-form-input 
							type="search"                     
							:placeholder="placeholder"
							v-model="filter"
							@keyup="searchDepartment" 
							class="mt-1"
							v-show="step ==1 && manualTemplate == false"
							@update="clearFilter('departament')">
						</b-form-input>
						<b-form-input 
							type="search"                     
							:placeholder="placeholder"
							v-model="filterTemplate"
							@keyup="searchTemplate" 
							class="mt-1"
							v-show="step ==2"
							@update="clearFilter('template')">
						</b-form-input>                    
						<b-input-group-prepend is-text class="mt-1">
							<b-icon icon="search"></b-icon>
						</b-input-group-prepend>                    
					</b-input-group> 
				</b-col>
                
				<b-container fluid v-if="step ==1">           
					<div v-for="(x, index) in departments" :key="index" v-show="x.visible == true" :class="[ '', index == currentDepartmentIndex ? 'template-active' : '' ]">
						<b-col class="m-1 icon-clickable" @click="setDepartmentId(index, x.id)">
							<b-row class="ml-1">
								<label :for="x.id" @change="setDepartmentId(index, x.id)">   
									<b-form-checkbox
										:id="x.id"
										v-model="departmentId"
										:value="x.id"
										size="lg"
										unchecked-value="0"
									>
										<span class="mylabel">{{x.name}}</span> 
									</b-form-checkbox>    
								</label>                             

							</b-row>                     
						</b-col>
					</div>                                            
				</b-container>
				<b-container fluid v-if="step ==2">
					<div v-for="(x, index) in templates" :key="index" v-show="x.visible == true" :class="[ 'custom-box-shadow', index == currentIndex ? 'selected-box' : '' ]">
						<b-col class="m-3" @click="setCurrentIndex(index, x.templateId, x.templateName, x.body, x.footer, x.media, x.mediaName, x.buttonList)">
							<b-row class="mb-1">
								<strong>{{x.templateName}}</strong>
							</b-row>
							<b-row class="mr-2 text-justify">
								<span class="custom-font-open-12-400" style="font-w">{{x.body}}</span>
							</b-row>
							<b-row class="mr-2">
								<span class="custom-font-open-12">{{x.footer}}</span>
							</b-row>
							<b-row v-show="x.button == true || x.media == true">
								<b-button variant="light" v-for="(b, b_index) in x.buttonList" :key="b_index" class="mr-2">
									{{b}}
								</b-button>
								<b-badge v-show="x.mediaName != ''">{{x.mediaName}}</b-badge>
							</b-row>                        
						</b-col>
					</div>
				</b-container>
				<b-container fluid v-if="step ==3">
					<div>
						<b-col class="m-3">
							<b-row class="mb-3">
								<span class="tempTitle">{{templateTitle}}</span>
							</b-row>
							<b-row class="mr-2 mb-2">
								<span class="tempSubTitle">Conteúdo</span>
							</b-row>
							<b-row>
								<span class="custom-font-open-12-400">{{templateContent}}</span>                            
							</b-row>
							<b-row>
								<span class="custom-font-open-12">{{templateFooter}}</span>
							</b-row>    
							<b-row class="mt-2 mb-2">
								<span class="tempSubTitle">Propriedades do contato</span>
							</b-row>  
							<b-row>
								<label for="input" v-for="(item, index) in properties" :key="index">
									{{item.key}}
									<b-form-input class="custom-font-open-14" id="input" type="text" 
										v-model="item.value">
									</b-form-input>    
								</label>                          
							</b-row> 
							<div v-show="templateMediaName != ''">
								<b-row >
									<h6>Mídia do template</h6>
								</b-row>   
								<b-row>
									<span>Baixe o arquivo para visualizar a mídia</span>
								</b-row>                           
								<b-row>
									<span>{{templateMediaName}}</span>
									<span>
										<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
											<g filter="url(#filter0_d)">
												<rect x="6" y="4" width="24" height="24" rx="4" fill="var(--secondary)"/>
											</g>
											<path d="M24 18V20.6667C24 21.0203 23.8595 21.3594 23.6095 21.6095C23.3594 21.8595 23.0203 22 22.6667 22H13.3333C12.9797 22 12.6406 21.8595 12.3905 21.6095C12.1405 21.3594 12 21.0203 12 20.6667V18" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M14.666 14.6667L17.9993 18L21.3327 14.6667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M18 18V10" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											<defs>
												<filter id="filter0_d" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
													<feFlood flood-opacity="0" result="BackgroundImageFix"/>
													<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
													<feOffset dy="2"/>
													<feGaussianBlur stdDeviation="3"/>
													<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
													<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
													<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
												</filter>
											</defs>
										</svg>
									</span>
								</b-row>  
								<b-row>
									<span class="small-font">Para alterar acesse esse <u>template no menu automação</u></span>
								</b-row>                             
							</div>
							<b-row class="mt-1">
								<span class="tempSubTitle">Texto do botão</span>
							</b-row>
							<b-row>
								<b-button variant="light"  v-for="(t, t_index) in templateButtons" :key="t_index" class="mr-2">
									{{t}}
								</b-button>                            
							</b-row>                                                                                    
						</b-col>
					</div>
				</b-container>            
				<template #modal-footer>
					<div class="w-100">
						<b-button v-show="step != 3" variant="primary" class="float-right" @click="nextStep()">
							Avançar
						</b-button>
						<b-button variant="outline-primary" class="float-left" @click="goBack()">
							Voltar
						</b-button>  
						<b-button v-show="step == 3" variant="primary" class="float-right" @click="send()"> 
							Enviar
						</b-button>                                                   
					</div>
				</template>
			</b-modal>    
			<b-row :class="[ 'w-100', this.$mq == 'xs' ? 'w-100 mb-3 mr-3' : '' ]" class="justify-content-center mt-2 ml-0" v-show="manualTemplate == false">
				<b-button pill variant="primary" class="float-right"
					@click="openTemplates()">
					<svg class="mr-2" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M16.5 1.5L8.25 9.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M16.5 1.5L11.25 16.5L8.25 9.75L1.5 6.75L16.5 1.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
					Escolher template
				</b-button>                 
			</b-row>                                                  
		</b-row>                       
	</b-col>              
</template>

<script>
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import WTemplatesAPI from '@/api/WTemplatesAPI'
export default {
	name: 'WChatTemplate',
	props: ['contactId', 'departmentParmId', 'manualTemplate', 'chatStatus'],
	data () {
		return { 
			show: false,  
			step: 1,
			title: 'Selecione um departamento',
			placeholder: 'Buscar departamento',
			filter: '',
			filterTemplate: '',
			selected: '',
			departmentId: null,
			templateId: null,
			departments: [],
			templates: [],
			properties: [],
			currentIndex: null,
			templateContent: '',
			templateTitle : '',
			templateFooter: '',
			templateMedia: null,
			templateMediaName: '',
			templateButtons: [],    
			currentDepartmentIndex: null,
			testeTemplates:[],
		}
	},    
	methods: {
		openTemplates () {
			this.show=true
			this.getDepartmentList ()
		},
		setDepartmentId (index, id) {
			this.departmentId = id
			this.currentDepartmentIndex = index                                         
		},
		setCurrentIndex (index, id, title, body, footer, media, mediaName, buttons) {
			this.templateId = id
			this.templateTitle = title
			this.templateContent = body
			this.templateFooter = footer
			this.templateMedia = media
			this.templateMediaName = mediaName
			this.templateButtons = buttons                             
			this.currentIndex = index
		},
		getDepartmentList () {
			WDepartmentsAPI.getDepartmentsOfUser(this.$route.params.companyId, response => {
				let departments = response.data
				departments.forEach(dep => {
					let obj = {selectd: 0, id: dep.departmentId, name: dep.departmentName, visible: true}
					this.departments.push(obj)
				})
			})
		},
		getTemplateVOList () {
			WTemplatesAPI.getTemplateVOByDepartmentId(this.$route.params.companyId, this.departmentId, response => {
				let template = response.data
				template.forEach(temp => {
					let obj ={
						visible: true, 
						body: temp.body,
						button: temp.button,
						buttonList: temp.buttonList, 
						footer: temp.footer,
						header: temp.header, 
						media: temp.media, 
						mediaName: temp.mediaName,
						templateId: temp.templateId, 
						templateName: temp.templateName   
					}                
					this.templates.push(obj)
				})
				if(this.templates[0] != null){
					this.setCurrentIndex(0, this.templates[0].templateId, this.templates[0].templateName, this.templates[0].body, this.templates[0].footer, this.templates[0].media, this.templates[0].mediaName, this.templates[0].buttonList)
				}  
			})	
		},  
		getTemplatePropertyMap () {
			WTemplatesAPI.getTemplatePropertyMap(this.$route.params.companyId, this.templateId, this.contactId, response => {
				let properties = response.data
				let list = []
				for (const key in properties){
					let val = properties[key]
					let obj = {key: key, value: val }
					list.push(obj)
				}
				this.properties = list
			})
		},               
		searchDepartment () {
			this.departments.forEach( el => {
				if ( el.name.toLowerCase().includes(this.filter.toLowerCase()) ){
					el.visible = true
				} else {
					el.visible = false
				}
			})        
		},  
		searchTemplate () {
			this.templates.forEach( el => {
				if ( el.templateName.toLowerCase().includes(this.filterTemplate.toLowerCase()) ){
					el.visible = true
				} else {
					el.visible = false
				}
			})        
		},          
		nextStep () { 
			if (this.departmentId == null){
				if(this.currentIndex == null&&this.manualTemplate ==false){
					alert('Pelo menos um item deve ser selecionado!')
					event.target.blur()
					return
				}
			}
			this.step++
			if (this.step == 2){
				this.title = 'Selecione um dos seus templates para continuar'
				this.placeholder = 'Buscar template'  
				this.getTemplateVOList()                                 
			}
			if (this.step == 3){
				this.title = 'Confira o template e envie'      
				this.getTemplatePropertyMap()            
			}
		},  
		send () {
			let propertyMap = []
			for (const [key, value] of Object.entries(this.properties)){
				key
				propertyMap.push(value)                                                             
			}            
			let objMap = new Object()
			objMap = {keyValueList: propertyMap}
			let template = 
			{ 
				templateId: this.templateId,
				departmentId: this.departmentId,
				header: this.templateTitle,
				body: this.templateContent,
				footer: this.templateFooter,
				media: this.templateMedia,
				mediaName: this.templateMediaName,
				buttons: this.templateButtons,
				propertyMap: objMap
			}
			let obj = {file: null, mediaType: 'template', msg: template, visible: this.manualTemplate}
			this.$emit('sendMessageData', obj) 
			this.show =false           
		},
		goBack () {
			this.step--   
			if (this.step == 0){
				this.show = false
			}      
			if (this.step == 1){
				this.show = true                                 
			}               
			if (this.step == 2){
				this.title = 'Selecione um dos seus templates para continuar'
				this.placeholder = 'Buscar template'                                 
			}
		},
		clearFilter (type) {
			if(this.filter == '' && type == 'departament'){
				this.searchDepartment()
			} else if(this.filterTemplate == '' && type == 'template'){
				this.searchTemplate()
			}
		}                            
	},
	mounted () {
		if (this.manualTemplate == true){
			this.title = 'Selecione um dos seus templates para continuar'
			this.placeholder = 'Buscar template'              
			this.show = true
			this.step = 2
			this.departmentId = this.departmentParmId
			this.getTemplateVOList()               
		}
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			console.log('Modal is about to be shown', bvEvent, modalId)
			this.show = false  
			this.step = 1
			this.title = 'Selecione um departamento'
			this.placeholder = 'Buscar departamento'
			this.filter = ''
			this.filterTemplate = ''
			this.selected = ''
			this.departmentId = ''
			this.templateId = null
			this.departments = []
			this.templates = []
			this.properties = []
			this.currentIndex = null
			this.templateContent = ''
			this.templateTitle  = ''
			this.templateFooter = ''
			this.templateMedia = null
			this.templateMediaName = '' 
			this.templateButtons = []
			this.currentIndex = null
			this.currentDepartmentIndex = null
			let obj = {file: null, mediaType: 'template', msg: 'close_modal', visible: this.manualTemplate}
			this.$emit('sendMessageData', obj) 
		}) 
	}, 
	
}
</script>

<style scoped>
    .search input { 
        border: none !important;
        width: 50% !important;
    }    
    .btn-light {
        color: #212529;
        background-color:  #F5F5F5;
        border-color:  #F5F5F5;
    }  
    .mylabel {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #777777;
        cursor: pointer; 
    }
    .tempTitle {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: #777777;    
    }  
    .tempSubTitle {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #999999;            
    }
	.selected-box{
		border: var(--primary);
		border-style: solid;
	}
</style>

 
