<template>	
	<div style="margin: 30px;">
		<b-col class="broadcast-list-scrollable scrollbar">
			<b-col>
				<b-row class="ml-1">
					<b-col class="mt-2">
						<h3 class="lbl-title" v-if="openArchived==false">Lista Transmissão</h3>
						<h3 class="lbl-title" v-else>Lista Arquivados</h3>
					</b-col>
					<b-col>
						<b-col>
							<b-row class="justify-content-end mr-5">
								<broadcast-create v-on:sendMessageData="getMessageData"/>        
								<b-button variant="primary" class="ml-3"
									@click="openArchivedList()" v-show="openArchived ==false">Arquivados
								</b-button>     
								<b-button variant="primary" class="ml-3"
									@click="openBroadcastList()" v-show="openArchived ==true">Transmissão
								</b-button>                                                           
							</b-row>
						</b-col>                
					</b-col>
				</b-row>
			</b-col>
			<b-col md="12" sm="12">
				<loading v-if="this.$store.state.loading ==true"/>     
				<b-row style="justify-content:space-between;align-items:center;" v-if="this.$store.state.loading ==false">
					<b-col md="4">
						<div class="search">  
							<b-input-group>
								<b-form-input 
									type="search"                     
									placeholder="Busque pelo nome de transmissão"
									v-model="filter"
									@keyup="searchBroadcasts"
									class="mt-1"
									@update="clearFilter">
								</b-form-input>
								<b-input-group-prepend is-text class="mt-1">
									<b-icon icon="search"></b-icon>
								</b-input-group-prepend>                    
							</b-input-group>                                                                                       
						</div>
					</b-col>
					<div>
						<!-- <div class="info-conversations">
							<p class="data">0/1000 conversas abertas<span>?</span></p>
							<span>Limite renovado a cada 24 horas</span>	
						</div>	 -->
					</div>                          
				</b-row>
				<b-row class="mt-1">   
					<div>
						<broadcast-modal-cancel
							:title = "'Cancelar transmissão'"
							:subTitle = "'Tem certeza que deseja cancelar a transmissão?'"
							:description = "broadcastName"
							:description1= "''"
							:value = "''"
							:placeholder = "''"
							:button1 = "'Fechar'"
							:button2 = "'Cancelar'"
							:start="showModalCancel"   
							v-on:sendModalData="postCancel"
							:key="componentCancelModalKey"                        
						/>
					</div>     
					<div>
						<broadcast-modal-archived
							:title = "'Arquivar transmissão'"
							:subTitle = "'Tem certeza que deseja arquivar a transmissão?'"
							:description = "broadcastName"
							:description1= "''"
							:value = "''"
							:placeholder = "''"
							:button1 = "'Fechar'"
							:button2 = "'Arquivar'"
							:start="showModalArchived"   
							v-on:sendModalData="postArchivedBroadcast"
							:key="componentArchivedModalKey"                        
						/>
					</div>  
					<div>
						<broadcast-modal-delete
							:title = "'Excluir transmissão'"
							:subTitle = "'Tem certeza que deseja excluir a transmissão?'"
							:description = "broadcastName"
							:description1= "''"
							:value = "''"
							:placeholder = "''"
							:button1 = "'Fechar'"
							:button2 = "'Excluir'"
							:start="showModalDelete"   
							v-on:sendModalData="deleteBroadcast"
							:key="componentDeleteModalKey"                        
						/>
					</div>     
					<div>
						<broadcast-modal-unarchived
							:title = "'Desarquivar transmissão'"
							:subTitle = "'Tem certeza que deseja desarquivar a transmissão?'"
							:description = "broadcastName"
							:description1= "''"
							:value = "''"
							:placeholder = "''"
							:button1 = "'Fechar'"
							:button2 = "'Desarquivar'"
							:start="showModalUnArchived"   
							v-on:sendModalData="postUnArchivedBroadcast"
							:key="componentUnArchivedModalKey"                        
						/>
					</div>                                              
					<b-col class="ml-2" v-if="this.$store.state.loading ==false">
						<div>
							<b-table-simple id="brodcastTable"
								:per-page="perPage"
								:current-page="currentPage" hover small responsive>
								<b-thead head-variant="ligth">
									<b-tr>
										<b-th v-if="clicked == false">
											<a href="javascript:;" @click="sortByAsc('broadcastName')">
												<span class="header-custom">Nome</span>
												<span :class="activeCol1"></span>
											</a>
										</b-th>
										<b-th v-if="clicked == true">
											<a href="javascript:;" @click="sortByDesc('-broadcastName', 1)">
												<span class="header-custom">Nome</span>
												<span id="active1" :class="activeCol1"></span>
											</a>
										</b-th>    									
										<b-th>Número</b-th>                
										<b-th v-if="clicked == false">
											<a href="javascript:;" @click="sortByAsc('startsAt')">
												<span class="header-custom">Data do envio</span>
												<span :class="activeCol2"></span>
											</a>
										</b-th>
										<b-th v-if="clicked == true">
											<a href="javascript:;" @click="sortByDesc('-startsAt', 2)">
												<span class="header-custom">Data do envio</span>
												<span id="active2" :class="activeCol2"></span>
											</a>
										</b-th> 							      
										<b-th v-if="clicked == false">
											<a href="javascript:;" @click="sortByAsc('userDescription')">									
												<span class="header-custom">Criador</span>
												<span :class="activeCol3"></span>
											</a>
										</b-th>
										<b-th v-if="clicked == true">
											<a href="javascript:;" @click="sortByDesc('-userDescription', 3)">
												<span class="header-custom">Criador</span>
												<span id="active3" :class="activeCol3"></span>
											</a>
										</b-th> 							
										<b-th>Template enviado</b-th>
										<b-th>Lista de Envio</b-th>
										<!-- <b-th>Enviados</b-th> -->
										<b-th>Entregues</b-th>
										<b-th>Lidos</b-th>
										<b-th>Falhas</b-th>
										<!-- <b-th>Reações</b-th> -->
										<b-th v-if="clicked == false">
											<a href="javascript:;" @click="sortByAsc('status')">									
												<span class="header-custom">Status</span>
												<span :class="activeCol4"></span>
											</a>
										</b-th>
										<b-th v-if="clicked == true">
											<a href="javascript:;" @click="sortByDesc('-status', 3)">
												<span class="header-custom">Status</span>
												<span id="active3" :class="activeCol4"></span>
											</a>
										</b-th> 		
															
										<b-th>Ação</b-th>
									</b-tr>
								</b-thead>
								<b-tbody>
									<b-tr v-for="x in orderedList" 
										:key="x.broadcastId" 
										v-show="x.visible ==true">				
										<b-td class="td-broadcast">{{x.broadcastName}}</b-td>
										<b-td class="td-phonenumber">{{x.phonenumber}}</b-td>                     
										<b-td class="td-date">{{timeToDate(x.startsAt)}}</b-td> 
										<b-td class="td-data">
											<!-- <b-avatar :src="avtUrl + x.companyId + '&imageId='+x.userId" size="1.7em" class="mr-1"></b-avatar> -->
											<b-row>
												{{x.userDescription}}
											</b-row>
											
										</b-td>
										<b-td class="td-data">{{x.templateDescription}}</b-td>
										<b-td class="td-target">{{x.targetCount}}</b-td>  
										<!-- <b-td>1</b-td>    -->
										<b-td class="td-deliver" v-if="x.status == 1">-</b-td>  
										<b-td class="td-deliver" v-if="x.status != 1">{{x.deliverCount}}</b-td>  
										<b-td class="td-readed">{{x.readedCount}}</b-td> 
										<b-td class="td-fail">{{x.failCount}}</b-td> 
										<!-- <b-td>Sim</b-td>  -->                                   
										<b-td class="td-data"><div :class=" `broadcast-status-color-${x.status}`"> <span>{{x.statusDescription}}</span></div></b-td>  
										<b-td>
											<div v-if="openArchived==false">
												<b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" right text="Right align" no-caret>
													<template #button-content>
														<svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
															<circle cx="2" cy="2" r="2" fill="#C4C4C4"/>
															<circle cx="2" cy="11" r="2" fill="#C4C4C4"/>
															<circle cx="2" cy="20" r="2" fill="#C4C4C4"/>
														</svg>
													</template>
													<b-dropdown-item @click="cancelBroadcast (x.broadcastId, x.datasourceType, x.broadcastName)" v-show="x.status == 1 || x.status == 3 || x.status == 6">
														<span>
															<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M12.6667 2H3.33333C2.59695 2 2 2.59695 2 3.33333V12.6667C2 13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V3.33333C14 2.59695 13.403 2 12.6667 2Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
																<path d="M6 6L10 10" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
																<path d="M10 6L6 10" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															</svg>
														</span>
														<span class="custom-font-open-12-400 ml-1">
															Cancelar envio
														</span>                                        
													</b-dropdown-item>
													<b-dropdown-item @click="editBroadcast(x.phonenumberId, x.departmentId, x.broadcastId, x.broadcastName, x.phonenumber, x.startsAt)" v-show="x.status != 4 && x.status != 5">
														<span>
															<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M10.0779 1.50136C10.2368 1.34241 10.4255 1.21632 10.6332 1.1303C10.8409 1.04428 11.0635 1 11.2883 1C11.513 1 11.7356 1.04428 11.9433 1.1303C12.151 1.21632 12.3397 1.34241 12.4986 1.50136C12.6576 1.66031 12.7837 1.84901 12.8697 2.05669C12.9557 2.26436 13 2.48695 13 2.71174C13 2.93653 12.9557 3.15912 12.8697 3.3668C12.7837 3.57447 12.6576 3.76317 12.4986 3.92212L4.32855 12.0922L1 13L1.90779 9.67145L10.0779 1.50136Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															</svg>
														</span>
														<span class="custom-font-open-12-400 ml-1">
															Editar agendamento
														</span>                                        
													</b-dropdown-item>  
													<b-dropdown-item @click="archivedBroadcast(x.broadcastId, x.broadcastName)" v-show="x.status == 4 || x.status == 5">
														<span>
															<svg data-v-41be6633="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="archive" xmlns="http://www.w3.org/2000/svg" fill="#777777" class="bi-archive mx-auto b-icon bi"><g data-v-41be6633=""><path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"></path></g></svg>
														</span>
														<span class="custom-font-open-12-400 ml-1">
															Arquivar campanha
														</span>                                        
													</b-dropdown-item>
													<b-dropdown-item @click="exportResult(x.broadcastId)" v-show="x.status == 4 || x.status == 5">
														<span>
															<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
																<path d="M4.6665 6.6665L7.99984 9.99984L11.3332 6.6665" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
																<path d="M8 10V2" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															</svg>
														</span>
														<span class="custom-font-open-12-400">
															Exportar resultados
														</span>                                        
													</b-dropdown-item>                                                                                                              
												</b-dropdown>
											</div>      
											<div v-else>
												<b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" right text="Right align" no-caret>
													<template #button-content>
														<svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
															<circle cx="2" cy="2" r="2" fill="#C4C4C4"/>
															<circle cx="2" cy="11" r="2" fill="#C4C4C4"/>
															<circle cx="2" cy="20" r="2" fill="#C4C4C4"/>
														</svg>
													</template>
													<b-dropdown-item @click="unArchivedBroadcast(x.broadcastId, x.broadcastName)">
														<span>
															<svg data-v-41be6633="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="archive" xmlns="http://www.w3.org/2000/svg" fill="#777777" class="bi-archive mx-auto b-icon bi"><g data-v-41be6633=""><path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"></path></g></svg>
														</span>
														<span class="custom-font-open-12-400 ml-1">
															Desarquivar campanha
														</span>                                        
													</b-dropdown-item>                                    
													<b-dropdown-item @click="setBroadcastDelete (x.broadcastId, x.broadcastName)">
														<span>
															<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M3 4H4.33333H15" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
																<path d="M6.3335 4.00016V2.66683C6.3335 2.31321 6.47397 1.97407 6.72402 1.72402C6.97407 1.47397 7.31321 1.3335 7.66683 1.3335H10.3335C10.6871 1.3335 11.0263 1.47397 11.2763 1.72402C11.5264 1.97407 11.6668 2.31321 11.6668 2.66683V4.00016M13.6668 4.00016V13.3335C13.6668 13.6871 13.5264 14.0263 13.2763 14.2763C13.0263 14.5264 12.6871 14.6668 12.3335 14.6668H5.66683C5.31321 14.6668 4.97407 14.5264 4.72402 14.2763C4.47397 14.0263 4.3335 13.6871 4.3335 13.3335V4.00016H13.6668Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
																<path d="M7.6665 7.3335V11.3335" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
																<path d="M10.3335 7.3335V11.3335" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															</svg>
														</span>
														<span class="custom-font-open-12-400 ml-1">
															Excluir campanha
														</span>                                        
													</b-dropdown-item>                                                                                                             
												</b-dropdown>
											</div>    
										</b-td>                           
									</b-tr>
								</b-tbody>
							</b-table-simple>   						
						</div>  					
					</b-col>  			 
				</b-row> 
				<b-row v-show="rows > perPage" class="pb-5">
					<b-col md="4" class="mt-2">
						<b-row>
							<b-col md="auto" class="mt-1">
								<span class="mt-2 lblPagination">Visualizar por página:</span> 
							</b-col>
							<b-col md="auto">                                                 
								<b-form-select v-model="selected" :options="perPageOptions" size="sm" @change="setPerPage()"></b-form-select>                
							</b-col>
						</b-row>
					</b-col>
					<b-col class="mr-5">
						<b-row class="d-flex justify-content-end">
							<b-pagination
								v-model="currentPage"
								:total-rows="rows"
								:per-page="perPage"
								pills
								size="sm"
								aria-controls="my-table"
							></b-pagination> 
						</b-row>
					</b-col>        
				</b-row>				       
			</b-col>
		</b-col>    
	</div>
</template>

<script>
import Loading from '@/views/Loading'
import WPhoneNumberAPI from '@/api/WPhoneNumberAPI'
import WBroadcastAPI from '@/api/WBroadcastAPI'
import WBroadcast from './WBroadcast'
import { avatarUrl, baseUrl} from '@/global'
import WModal from '@/views/WModal'
export default {
	components: {
		loading: Loading,
		'broadcast-create': WBroadcast,
		'broadcast-modal-cancel': WModal,
		'broadcast-modal-archived': WModal,        
		'broadcast-modal-delete': WModal,
		'broadcast-modal-unarchived': WModal,              
	},
	name: 'WBroadcastList',
	data: function () {
		return {
			loading: false,
			avtUrl: avatarUrl,  
			filter: '',
			broadcastList: [],   
			originalBroadcastList: [],
			showModalCancel: false,            
			showModalArchived: false,
			showModalDelete: false,            
			showModalUnArchived: false,            
			perPage: 20,
			selected: 20,
			currentPage: 1,  
			totalPage: 1,  
			paginatedItems: [], 
			perPageOptions: [20, 50, 100, 200, 500],  
			phoneNumberList: [],   
			broadcastName: '',  
			componentCancelModalKey: 0,                                             
			componentArchivedModalKey: 0,
			componentDeleteModalKey: 0,                                             
			componentUnArchivedModalKey: 0,
			openArchived: false,
			activeCol1: 'sortIcon up',
			activeCol2: 'sortIcon up',
			activeCol3: 'sortIcon up',
			activeCol4: 'sortIcon up',			
			clicked: false,	
			clickedCol: 0		
		}
	},
	computed: {
		rows () {
			return this.broadcastList.length
		},
		orderedList () {			
			if (this.clickedCol == 0) {
				let items = this.broadcastList
				items.sort(this.dynamicSort(['status', '-createdTime'])) 			
				return items.slice(
					(this.currentPage - 1) * this.perPage,
					this.currentPage * this.perPage
				)					
			} else {
				let items = this.broadcastList							
				return items.slice(
					(this.currentPage - 1) * this.perPage,
					this.currentPage * this.perPage
				)								
			}
		}, 	     
	},    
	methods: {      
		openArchivedList () {
			this.openArchived = true
			this.clickedCol = 0
			this.getArchivedList()
		},    
		openBroadcastList () {
			this.openArchived = false
			this.clickedCol = 0
			this.getBroadcastList()
		},   
		getMessageData () {
			this.clickedCol = 0
		}, 
		sortByAsc (colName) {
			let list = this.broadcastList 			
			this.clicked = true            
			if (colName == 'broadcastName') {
				this.activeCol1 = 'sortIcon down'
				this.activeCol2 = 'sortIcon up'
				this.activeCol3 = 'sortIcon up'
				this.activeCol4 = 'sortIcon up'				
				this.clickedCol = 1                                       
			}
			if (colName == 'startAt') {
				this.activeCol1 = 'sortIcon up'
				this.activeCol2 = 'sortIcon down'
				this.activeCol3 = 'sortIcon up'
				this.activeCol4 = 'sortIcon up'				
  
				this.clickedCol = 2                       
			}   
			if (colName == 'userDescription') {
				this.activeCol1 = 'sortIcon up'
				this.activeCol2 = 'sortIcon up'
				this.activeCol3 = 'sortIcon down' 
				this.activeCol4 = 'sortIcon up'				
				this.clickedCol = 3              
			}       
			if (colName == 'status') {
				this.activeCol1 = 'sortIcon up'
				this.activeCol2 = 'sortIcon up'
				this.activeCol3 = 'sortIcon up' 
				this.activeCol4 = 'sortIcon down'				
				this.clickedCol = 4             
			} 			                                  
			this.broadcastList = list.sort(this.dynamicSort([colName]))			     
		},
		sortByDesc (colName, clickCol) {   
			let list = this.broadcastList 	
			if (this.clickedCol > 0  && this.clickedCol != clickCol) {
				this.activeCol1 = 'sortIcon up'
				this.activeCol2 = 'sortIcon up'
				this.activeCol3 = 'sortIcon up'             
				this.activeCol4 = 'sortIcon up'				
				if (clickCol ==1) {
					this.activeCol1 = 'sortIcon down'
				}  
				if (clickCol ==2) {
					this.activeCol2 = 'sortIcon down'
				}  
				if (clickCol ==3) {
					this.activeCol3 = 'sortIcon down'					
				}       
				if (clickCol ==4) {
					this.activeCol4 = 'sortIcon down'					
				}  				                                                          
			} else {
				this.activeCol1 = 'sortIcon up'
				this.activeCol2 = 'sortIcon up'
				this.activeCol3 = 'sortIcon up'  
				this.activeCol4 = 'sortIcon up'				
			}
			
			this.clicked = false  
			this.broadcastList = list.sort(this.dynamicSort([colName]))         
		},
		dynamicSort (fields) {
			return function (a, b) {
				return fields
					.map(function (o) {
						let dir = 1
						if (o[0] === '-') {
							dir = -1
							o=o.substring(1)
						}
						if (a[o] > b[o]) 
							return dir
						if (a[o] < b[o]) 
							return -(dir)
						return 0
					})
					.reduce(function firstNonZeroValue (p,n) {
						return p ? p : n
					}, 0)
			}
		},		       
		getBroadcastList () {
			this.$store.commit('setShowLoading', true)
			WBroadcastAPI.getBroadcastList(this.$route.params.companyId, response => {
				let broadcastList = response.data
				this.$store.commit('setShowLoading', false)
				broadcastList.forEach(element => {
					element.visible = true
				})
				this.broadcastList = broadcastList 
				this.originalBroadcastList = this.broadcastList
			})
		},  
		getArchivedList () {
			this.$store.commit('setShowLoading', true)
			WBroadcastAPI.getArchivedByCompany(this.$route.params.companyId, response => {
				let broadcastList = response.data
				this.$store.commit('setShowLoading', false)
				broadcastList.forEach(element => {
					element.visible = true
				})
				this.broadcastList = broadcastList 
				this.originalBroadcastList = this.broadcastList
			})
		},          
		setPerPage () {
			this.perPage = this.selected
		},
		searchBroadcasts () {
			let broadcastList = this.originalBroadcastList
			this.broadcastList = []            
			broadcastList.forEach( el => {
				if ( el.broadcastName.toLowerCase().includes(this.filter.toLowerCase()) ){
					el.visible = true
					this.broadcastList.push(el)
				} else {
					el.visible = false
				}
			})
		},   
		searchBroadcastsStatus (status) {
			let broadcastList = this.originalBroadcastList
			this.broadcastList = []
			broadcastList.forEach( el => {
				if ( el.status == status || status == 'all'){
					el.visible = true
					this.broadcastList.push(el)
				} else {
					el.visible = false
				}
			})        
		},    
		cancelBroadcast (broadcastId, datasourceType, name) {  
			this.broadcastId = broadcastId
			this.datasourceType = datasourceType
			this.componentCancelModalKey ++
			this.showModalCancel = true 
			this.broadcastName = name
		},   

		setBroadcastDelete (broadcastId, name) {  
			this.broadcastId = broadcastId
			this.componentDeleteModalKey ++
			this.showModalDelete = true 
			this.broadcastName = name
		},   

		archivedBroadcast (broadcastId, name) {  
			this.broadcastId = broadcastId
			this.componentArchivedModalKey ++
			this.showModalArchived = true   
			this.broadcastName = name  
		},   

		postCancel (cancel) {
			this.showModalCancel = false
			if (cancel){
				WBroadcastAPI.postCancel(this.$route.params.companyId, this.broadcastId, this.datasourceType , response => {
					if (response){
						this.getBroadcastList()                    
					} else {
						alert('Falha ao cancelar transmissão!')
					}                                       
				})                
			}
		},      
		postArchivedBroadcast (archived) {
			this.showModalArchived = false
			if (archived){
				WBroadcastAPI.postArchivedBroadcast(this.$route.params.companyId, this.broadcastId, response => {
					if (response){
						this.getBroadcastList()                    
					} else {
						alert('Falha ao arquivar transmissão!')
					}                                       
				})                
			}
		},    
        
		// ----------unArchivedBroadcast--------------------

		unArchivedBroadcast (broadcastId, name) {  
			this.broadcastId = broadcastId
			this.componentUnArchivedModalKey ++
			this.showModalUnArchived = true   
			this.broadcastName = name  
		},   

		deleteBroadcast (deleteCampaign) {
			this.showModalDelete = false
			if (deleteCampaign){
				WBroadcastAPI.deleteBroadcast(this.$route.params.companyId, this.broadcastId, response => {
					if (response){
						this.getArchivedList()                      
					} else {
						alert('Falha ao excluir transmissão!')
					}                                       
				})                
			}
		},      
		postUnArchivedBroadcast (archived) {
			this.showModalUnArchived = false
			if (archived){
				WBroadcastAPI.postUnArchivedBroadcast(this.$route.params.companyId, this.broadcastId, response => {
					if (response){
						this.getArchivedList()                  
					} else {
						alert('Falha ao arquivar transmissão!')
					}                                       
				})                
			}
		},  

		exportResult (broadcastId) {
			let access_token = localStorage.getItem('__wpp_access_token')			
			window.open(baseUrl + '/broadcastReport/?companyId='
			+ this.$route.params.companyId + '&broadcastId=' + broadcastId
			+ '&accessToken='+ access_token)
		},                
		timeToDate (time) {
			if (time ==0){
				return '-'
			}
			let t = new Date(Number(time))

			let fHourMinute = t.getHours() + ':'
			if(t.getMinutes()<10){
				fHourMinute+='0' 
			}
			fHourMinute+=t.getMinutes()  

			let d = new Date(Number(time))
			let day = d.getDate()        
			if(day < 10){
				day ='0' + d.getDate() 
			}
			let month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1)
			let sDate  = day  + '/' + month + '/' + d.getFullYear() 
			return sDate + ' ' + fHourMinute
		},    
		editBroadcast (phonenumberId, departmentId, broadcastId, name, phoneNumber, startAt ) {
			localStorage.removeItem('__wpp_broadcast_step')                        
			let objStep = {currentStep: 8}
			localStorage.setItem('__wpp_broadcast_step', JSON.stringify(objStep))  
            
			let broadcast = 
				{
					broadcastName: name,
					broadcastPhoneNumber: phoneNumber,
					broadcastDepartament: '',
					broadcastStartAt: startAt
				}
			localStorage.removeItem('__wpp_broadcast_name')
			localStorage.setItem('__wpp_broadcast_name', JSON.stringify(broadcast))            

			this.$router.push({ path: '/broadcast/'+ this.$route.params.companyId +'/'+ phonenumberId+ '/' +departmentId+ '/' + broadcastId + '/new' })
		},
		getPhonenumbers () {
			WPhoneNumberAPI.getPhonenumbers(this.$route.params.companyId, response => {
				this.phoneNumberList = response.data           
			})                
		},    
		clearFilter () {
			if(this.filter == ''){
				this.searchBroadcasts()
			}
		}               
	},      
	mounted () {
		this.getPhonenumbers()
		this.getBroadcastList()
	}
}
</script>

<style scoped>

.info-conversations {
	font-size: 12px;
	font-weight: 400;
	line-height: 0.5rem;
	text-align: right;
}

.info-conversations .data {
	font-size: 18px;
	font-weight: 700;
}

u {
    cursor: pointer;
}
th {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #999999 !important;    
}

td {
    margin: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #777777; 
    height: 50px;
    vertical-align: middle !important;
}
.td-broadcast {
    margin-top: 20px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.td-phonenumber {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;  
}
.td-date {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-data {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-email {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}
.td-property {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}

.td-target {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-fail {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-deliver {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.td-readed {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}
.sidebar-right-broadcasts {
    display: flex !important;
    width: 100% !important;
    flex-direction: column !important;
    background: white !important;
    padding: 10px;
    margin-right: 50px;
}
.custom-pagination-input {
    width: 50px !important;
}
.custom-pagination-select {
    width: 60px !important;
}

.broadcast-list-scrollable {
    height: 90vh;
    width: 100%;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
	border-radius: 10px;
}
.lblPagination {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px; 
    color: #777777;   
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    min-height: 200px;
    -webkit-overflow-scrolling: touch;
}

.form-control:focus {
    background-color: #F5F5F5;
    box-shadow: none;
}

.sortIcon{
	margin-left: 3px;
	width:0;
	height:0;
	border:7px solid transparent;
	display:block;
}  

.sortIcon.up {
	border-bottom-color:#C0C0C0; 
}

.sortIcon.down {
	border-top-color:#C0C0C0; 
	margin-top: 7px;
} 

.header-custom {
	font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #999999;		
}
.avatar {
    width: 20px;
    height: 20px;
    background-color: var(--primary);
    border-radius: 50%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    color: #FFFFFF;
}  
</style>
