import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/WLogin'
import ForgotPassword from '../views/ForgotPassword'
import ForgotSuccess from '../views/ForgotSuccess'
import SignUp from '../views/SignUp'
import CreateAccount from '../views/CreateAccount'
import SignupSuccess from '../views/SignupSuccess'
//Admin
import WAdmin from '../components/admin/WAdmin'
import WTeste from '../components/admin/WTeste'
//Panel 
import WDashboardMain from '../components/dashboard/WDashboardMain'
//chat
import ChatMain from '../components/chat/WChatMain'
//Automation
import WAutomationMain from '../components/automation/WAutomationMain'
import WTemplate from '../components/automation/template/WTemplate'
import WTemplateDepartment from '../components/automation/template/WTemplateDepartment'
import WTemplateFinalize from '../components/automation/template/WTemplateFinalize'
import WChatFlowDepartment from '../components/automation/chatflowbot/WChatFlowDepartment'
import WChatFlowBuild from '../components/automation/chatflowbot/WChatFlowBuild'
//Broadcast
import WBroadcastList from '../components/broadcast/WBroadcastList'
import WBroadcastNew from '../components/broadcast/WBroadcastNew'
//contacts
import WContactList from '../components/contacts/WContactList'
import WContactDetail from '../components/contacts/WContactDetail'
import WSettingsMain from '../components/settings/WSettingsMain'
import WDeveloperMain from '../components/developer/WDeveloperMain'
import WProfile from '../components/user/WProfile'
import WPasswordSucess from '../components/user/WPasswordSucess'
import Inadimplency from '../views/Inadimplency'
import RedirectTo from '../components/external/RedirectTo'
Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Login',
		component: Login,
		meta: {
			allowAnonymous: true
		}
	}, 
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			allowAnonymous: true
		}
	},   	
	{
		path: '/forgot/password',
		name: 'Forgot Password',
		component: ForgotPassword,
		meta: {
			allowAnonymous: true
		}
	},   
	{
		path: '/forgot/success',
		name: 'Forgot Success',
		component: ForgotSuccess,
		meta: {
			allowAnonymous: true
		}
	},   
	//--------SignUp Start------------------  
	{
		path: '/signup/start',
		name: 'SignUp',
		component: SignUp,
		meta: {
			allowAnonymous: true
		}
	},     
	{
		path: '/create/account/:signupId',
		name: 'Create Account',
		component: CreateAccount,
		meta: {
			allowAnonymous: true
		}
	},       
	{
		path: '/billing/support',
		name: 'Inadimplency',
		component: Inadimplency,
	},    
	{
		path: '/signup/:signupId/success',
		name: 'Signup Success',
		component: SignupSuccess,
		meta: {
			allowAnonymous: true
		}
	},     	 
	//--------SignUp End------------------
	//--------Dashboard ----------------------- 
	{
		path: '/dashboard/:companyId/main',
		name: 'WDashboardMain',
		component: WDashboardMain,
	},   	  
	//--------Chat ----------------------- 
	{
		path: '/:companyId/:chatResumeOrder/chat',
		name: 'ChatMain',
		component: ChatMain,
	},   
	{
		path: '/:companyId/:phonenumberId/:waId/:chatResumeOrder/chat',
		name: 'ChatMain',
		component: ChatMain,
	},
	//--------admin------------------------
	{
		path: '/:companyId/admin',
		name: 'WAdmin',
		component: WAdmin,
		meta: {
			allowAnonymous: true
		}
	},
	{
		path: '/:companyId/teste',
		name: 'WTeste',
		component: WTeste,
		meta: {
			allowAnonymous: true
		}
	},
	//--------developer -------------------
	{
		path: '/developer/:companyId/main',
		name: 'WDeveloperMain',
		component: WDeveloperMain,
	},     
	//--------settings -------------------
	{
		path: '/settings/:companyId/main',
		name: 'WSettingsMain',
		component: WSettingsMain,
	},   

	//--------Automation ------------------  
	{
		path: '/automation/:companyId/main',
		name: 'WAutomationMain',
		component: WAutomationMain,
	},   
	{
		path: '/automation/template/:companyId/create',
		name: 'WTemplate',
		component: WTemplate,

	},
	{
		path: '/automation/template/:companyId/:templateId/create',
		name: 'WTemplate',
		component: WTemplate,

	},	
	{
		path: '/automation/template/:companyId/:templateId/:mode/department',
		name: 'WTemplateDepartment',
		component: WTemplateDepartment,

	},
	{
		path: '/automation/template/:companyId/:templateId/:mode/finalize',
		name: 'WTemplateFinalize',
		component: WTemplateFinalize,
	},
	{
		path: '/automation/chat/flow/:companyId/create',
		name: 'WChatFlowDepartment',
		component: WChatFlowDepartment,
	},	
	{
		path: '/automation/chat/flow/:companyId/:chatflowId/department/edit',
		name: 'WChatFlowDepartment',
		component: WChatFlowDepartment,
	},		
	{
		path: '/automation/chat/flow/:companyId/build',
		name: 'WChatFlowBuild',
		component: WChatFlowBuild,
	},	
	{
		path: '/automation/chat/flow/:companyId/:chatFlowId/build/edit',
		name: 'WChatFlowBuild',
		component: WChatFlowBuild,
	},				
	//--------Broadcast ------------------  
	{
		path: '/broadcast/:companyId/list',
		name: 'WBroadcastList',
		component: WBroadcastList,
	},   
	{
		path: '/broadcast/:companyId/:phonenumberId/:departmentId/:broadcastId/new',
		name: 'WBroadcastNew',
		component: WBroadcastNew,
	},  
      
	//--------contacts -------------------   
	{
		path: '/contact/:companyId/list',
		name: 'WContactList',
		component: WContactList,
	},   
	{
		path: '/contact/:companyId/:contactId/:phonenumberId/:waId/detail',
		name: 'WContactDetail',
		component: WContactDetail,
	},    
	{
		path: '/contact/:companyId/:contactId/:phonenumberId/:waId/:whocalled/detail',
		name: 'WContactDetail',
		component: WContactDetail,
	},   	

	//--------user -------------------   
	{
		path: '/user/:companyId/profile',
		name: 'WProfile',
		component: WProfile,
	},   
	//-------password------------
	{
		path: '/user/PasswordSucess',
		name: 'WPasswordSucess',
		component: WPasswordSucess,
	}, 
	//----External redirect------
	{
		path: '/redirect',
		name: 'RedirectTo',
		component: RedirectTo,
		meta: {
			allowAnonymous: true
		}
	},  	
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router
