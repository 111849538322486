

<template>
	<div>
		<div>
			<i @click="openLabelView" style="display:flex">
				<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M12.4231 7.83392L7.83886 12.4181C7.7201 12.537 7.57907 12.6313 7.42383 12.6957C7.2686 12.7601 7.1022 12.7932 6.93416 12.7932C6.76612 12.7932 6.59972 12.7601 6.44449 12.6957C6.28925 12.6313 6.14822 12.537 6.02946 12.4181L0.830659 7.22539C0.642871 7.03782 0.537354 6.78329 0.537354 6.51787L0.537354 1.03882C0.537354 0.762675 0.761211 0.538818 1.03735 0.538818H6.51675C6.78197 0.538818 7.03632 0.644175 7.22386 0.831711L12.4231 6.03093C12.6612 6.27051 12.7949 6.59461 12.7949 6.93243C12.7949 7.27025 12.6612 7.59434 12.4231 7.83392Z" fill="var(--primary)"/>
				</svg>
			</i>  
		</div>
		<b-modal 
			:key="update"
			v-model="show"
			title="Marcação de mensagem"
			centered
			size="lg"
			style="overflow-x:hidden;"
			scrollable>
			<b-container fluid class="ml-3">
				<b-row class="">
					<b-col sm="7" class="" >
						<b-row>
							<search-department-filter
								:list="departmentList"
								:sortBy="'text'"
								class="mt-1"
								:scrollTag="true"
								v-on:checkedItems="setSelectedItems($event)"/>
						</b-row>
					</b-col>
					<b-col class="mr-5">						
						<b-row class="justify-content-end">
							<b-input-group >
								<b-form-input
									class=""
									id="filter-input"
									v-model="filter"
									type="search"
									:placeholder="'Título da marcação'"
									@keyup="search()"
									@update="clearFilter"
								></b-form-input> 
								<b-input-group-prepend is-text>
									<b-icon icon="search"></b-icon>
								</b-input-group-prepend>                    
							</b-input-group> 							
						</b-row>
					</b-col>
				</b-row>


				<!-- <b-row >
					<search-department-filter
						:list="departmentList"
						:sortBy="'text'"
						v-on:checkedItems="setSelectedItems($event)"/>
					
					<b-input-group class="mr-5">
						<b-form-input
							class=""
							id="filter-input"
							v-model="filter"
							type="search"
							:placeholder="'Título da marcação'"
							@keyup="search()"
						></b-form-input>  
			
						<b-input-group-prepend is-text class="mt-1">
							<b-icon icon="search"></b-icon>
						</b-input-group-prepend>                    
					</b-input-group> 
					
				</b-row> -->
				<b-row class="mt-2">
					<span v-if="userPermission.adminPermission" class="custom-font-label" @click="openCreateLabel">+ Criar nova marcação</span>
				</b-row> 
				<hr>
				<b-row class="filtered-list-scrollable scrollbar">
					<div v-for="(x, index) in orderBy(labelList, 'labelDescription')" :key="index" :class="[ 'custom-row-label', index == currentIndex ? 'label-select-active' : '' ]">
						<div class="m-1" @click="setCurrentIndex(index, x.labelId)">    
							<label :for="x.labelId" style="width: 100%; cursor: pointer;">   
								<b-row>
									<b-col sm="auto" style="padding:0;">
										<b-row class="ml-2">
											<span class="ml-1 mt-3">
												<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M0.647582 8.40511L0.702352 1.62951C0.703679 1.45388 0.737848 1.28025 0.802908 1.11854C0.867968 0.956839 0.962642 0.810232 1.08152 0.687103C1.20039 0.563974 1.34113 0.466737 1.4957 0.400955C1.65026 0.335172 1.81562 0.302134 1.98231 0.303728L9.27913 0.364722C9.53738 0.366881 9.78414 0.477055 9.96511 0.671L13.7878 4.76773C13.9762 4.96963 13.9734 5.29435 13.7816 5.49302L9.89396 9.51984C9.70964 9.71076 9.46092 9.81673 9.20257 9.81441L1.90148 9.7489C1.56638 9.74484 1.24643 9.60124 1.01149 9.34946C0.776552 9.09768 0.645725 8.75818 0.647582 8.40511Z" :fill="x.labelColor"/>
												</svg>
											</span>
											<span class="ml-3 mt-3 custom-font-open-14-gray">{{x.labelDescription}}</span>  
										</b-row>
									</b-col>
									<b-col>
										<b-row class="justify-content-end mr-2">
											<b-form-checkbox
												:id="x.labelId"
												:checked="x.checked"
												v-model="x.checked"
												size="md"
											></b-form-checkbox>                                                                      
										</b-row>
									</b-col>  
								</b-row>                               
							</label>                                        
						</div>
					</div>                
				</b-row>   
				<b-modal 
					v-model="showCreate"
					title="Marcação de mensagem"
					centered
					scrollable>
					<b-container>
						<b-row class="ml-1">
							<label class="custom-font-open-16-gray-600">Criar nova marcação</label>
						</b-row>
						<b-row class="my-1">
							<b-col md="1" class="mt-1">
								<label for="input">
									<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M0.712035 8.40511L0.766805 1.62951C0.768132 1.45388 0.802302 1.28025 0.867362 1.11855C0.932422 0.956841 1.0271 0.810234 1.14597 0.687105C1.26484 0.563976 1.40559 0.46674 1.56015 0.400957C1.71472 0.335175 1.88007 0.302137 2.04677 0.30373L9.34359 0.364725C9.60183 0.366884 9.84859 0.477058 10.0296 0.671003L13.8522 4.76773C14.0406 4.96963 14.0379 5.29435 13.8461 5.49302L9.95841 9.51984C9.77409 9.71076 9.52537 9.81672 9.26703 9.81441L1.96593 9.74889C1.63084 9.74484 1.31088 9.60124 1.07594 9.34946C0.841005 9.09768 0.710178 8.75818 0.712035 8.40511Z" fill="#0015FF"/>
									</svg>
								</label>
							</b-col>
							<b-col md="10">
								<b-form-input id="input" v-model="text" class="custom-font-open-14"></b-form-input>
							</b-col>
						</b-row>                    
					</b-container>
					<template #modal-footer>
						<div class="w-100">
							<b-button variant="outline-primary" class="mr-2"
								@click="showCreate=false">Cancelar
							</b-button>   
							<b-button variant="primary" class="float-right mr-5"
								@click="save">Salvar           
							</b-button>                     
						</div>  
					</template>                
				</b-modal>                              
			</b-container>         
			<template #modal-footer>
				<div class="w-100">
					<b-button variant="outline-primary" class="mr-2"
						@click="show=false">Cancelar
					</b-button>   
					<b-button variant="primary" class="float-right mr-5"
						@click="link">Salvar           
					</b-button>                     
				</div>  
			</template>
		</b-modal>                                   
	</div>                                          
</template>

<script>
import WLabelsAPI from '@/api/WLabelsAPI'
import SearchCheckBoxFilterVue from '@/components/filters/SearchCheckBoxFilter.vue'
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import Vue2Filters from 'vue2-filters'
export default {
	name: 'WChatLabel',
	components:{
		'search-department-filter': SearchCheckBoxFilterVue,
	},
	props: [
		'messageId',
		'contactActive'
	],
	mixins: [Vue2Filters.mixin],
	data () {
		return { 
			show: false,
			labelId: '',
			currentIndex: null,
			showCreate: false,
			text: '',
			labelList: [],
			originalLabelList:[],  
			filter:'',
			userPermission: JSON.parse(localStorage.getItem('__wpp_user_permission_object')),
			departmentList: [],
			selectedDepartment:[],
			departmentIdChat: this.contactActive.departmentId,
			update: 0
		}
	},    
   
	methods: { 
		search () {
			// this.labelList = this.originalLabelList.filter(element =>{
			// 	return element.labelDescription.toLowerCase().includes(this.filter)
			// })
			let list = this.originalLabelList
			this.labelList = []
			if(this.filter == '' && this.selectedDepartment.length == 0){
				this.labelList = this.originalLabelList
				return
			}else if(this.filter==''){
				this.labelList = list.filter(el =>{
					let shouldFilter = false
					if(!el.departmentIdList){
						return false
					}
					this.selectedDepartment.forEach(element => {
						if(el.departmentIdList.includes(element)){
							shouldFilter = true
							return
						}
					})
					return shouldFilter
				})
			}
			this.labelList = list.filter(el =>{
				let shouldFilter = false
				if(this.selectedDepartment.length > 0){
					this.selectedDepartment.forEach(element => {
						if(el.departmentIdList.includes(element)){
							if(el.labelDescription.toLowerCase().includes(this.filter.toLowerCase())){
								shouldFilter = true
								return
							}
						}
					})
					return shouldFilter
				}else{
					return el.labelDescription.toLowerCase().includes(this.filter.toLowerCase())
				}
			})
		},
		setSelectedItems (list) {
			this.selectedDepartment = list
			this.search()
		},
		openCreateLabel () {
			let step = {currentStep: 6.1, currentMenuItem:6}
			localStorage.setItem('__wpp_setting_step',JSON.stringify(step))
			this.$router.push({ path: '/settings/'+ this.$route.params.companyId +'/main' })
		},
		async openLabelView () {
			this.departmentList=[]
			// this.labelList = []
			// this.originalLabelList = []
			if(this.userPermission.adminPermission == true){	
				WDepartmentsAPI.getDepartmentsOfCompany(this.$route.params.companyId, response =>{
					let resp = response.data
					resp.forEach(item => {
						let dpt = {
							text: item.departmentName,
							value: item.departmentId
						}
						this.departmentList.push(dpt)
					})
				})
			}else{
				WDepartmentsAPI.getDepartmentsOfUser(this.$route.params.companyId, response =>{
					let resp = response.data
					resp.forEach(item => {
						let dpt = {
							text: item.departmentName,
							value: item.departmentId
						}
						this.departmentList.push(dpt)
					})
				})
			}
			await this.getLabelsList()  
			console.log(this.labelList)
			this.update ++ 
			this.show = true         
		},         
		getLabelsList () {
			return new Promise(resolve =>{
				this.labelList = []
				//-------------antigo
				// let list = []
				// WLabelsAPI.getListByCompanyIdAndMessage(this.$route.params.companyId, this.messageId, response => {
				// 	console.log(response.data)
				// 	let labelList = response.data
				// 	labelList.forEach(lbl => {
				// 		let obj = {labelId: lbl.labelId, labelColor: lbl.labelColor, labelDescription: lbl.labelDescription, checked: lbl.inMessage}
				// 		list.push(obj)
				// 	})     
				// 	this.labelList = list     
				// 	this.originalLabelList = list                   
				// }) 
				// -----------antigo
				this.$store.commit('setShowLoading', true)
				WLabelsAPI.getListByCompanyIdAndDepartmentId(this.$route.params.companyId,this.departmentIdChat, response => {
					let labelList = response.data
					this.labelList = labelList
					// this.labelList = labelList.filter(el =>{
					// 	let shouldFilter = false
					// 	if(!el.departmentIdList){
					// 		return false
					// 	}
					// 	this.departmentList.forEach(element => {
					// 		if(el.departmentIdList.includes(element.value)){
					// 			shouldFilter = true
					// 			return
					// 		}
					// 	})
					// 	return shouldFilter
					// })
					this.originalLabelList = this.labelList
					this.$store.commit('setShowLoading', false)
					resolve()
				})  
			})
		},
		setCurrentIndex (index, id) {
			this.labelId = id                         
			this.currentIndex = index
		},        
		link () {
			let labelSelectedList = ''  
			let lblIdList = []        
			this.labelList.forEach(lbl => {
				if (lbl.checked == true){
					lblIdList.push(lbl.labelId)
					labelSelectedList += lbl.labelId + ','
				}
			})
			labelSelectedList = labelSelectedList.substring(0,labelSelectedList.length -1 )
			if (this.labelSelectedList == ''){
				alert('Pelo menos um item deve ser selecionado!')
				return
			}            
			WLabelsAPI.postPersist(this.$route.params.companyId, this.messageId, labelSelectedList, response => {
				if (response){
					let obj = {file: null, mediaType: 'labellink', msg: '', visible: false}
					this.$emit('sendLabelData', obj)
					this.show = false                    
				} else {
					this.show = true
				}
			})  
		},
		save () {
			if (this.text == '' || this.text == undefined ){
				alert('Campo nova marcação obrigatório!')
				return
			}               
			WLabelsAPI.postCreate(this.$route.params.companyId, this.text, response => {
				if (response){
					this.getLabelsList()
					this.showCreate = false
				} else {
					this.showCreate = true
				}
			})  
		},
		clearFilter () {
			if(this.filter == ''){
				this.search()
			}
		}  
               
	},
	mounted () {
		// console.log(this.contactActive)
	}, 
}
</script>

<style>
.list-group-item {
    margin-bottom: 0 !important;
    padding: 2px !important;    
    background-color: white !important;
    border-radius: 100px !important;
    padding-left: 10px !important; 
    border: none !important;   
}
.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
    overflow-x: hidden;
}
.filtered-list-scrollable {
		height: 30vh;	
		width: 100%;
		background: #fff;
		overflow-y: scroll;
		overflow-x: hidden;
	}
</style>

 
