import { render, staticRenderFns } from "./WCompanyView.vue?vue&type=template&id=74a32c1b&"
import script from "./WCompanyView.vue?vue&type=script&lang=js&"
export * from "./WCompanyView.vue?vue&type=script&lang=js&"
import style0 from "./WCompanyView.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports