<template>
	<div style="margin-left: 10px; margin-right: 30px;">
		<b-col class="mt-2 ml-2">
			<b-row class="ml-1">
				<b-col class="mt-2">
					<h3 class="lbl-title">Configurações de Número</h3>
				</b-col>
				<b-col>
					<b-col>
						<b-row class="justify-content-end mr-5">
							<b-button v-b-toggle.sidebar-right variant="secondary"
								@click="create()" >
								<span>
									<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M8 3V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M3 8H13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								</span>
								<span class="ml-1">
									Adicionar Número
								</span>                        
							</b-button>                                                             
						</b-row>
					</b-col>                
				</b-col>
			</b-row>
			<b-row class="ml-2">
				<span class="custom-font-open-14-gray-normal">
					Aqui você encontrará configurações do perfil comercial do WhatsApp Business de cada número
				</span>                       
			</b-row>          
		</b-col>
		<b-col md="12" sm="12" class="screen-template">        
			<loading v-if="this.$store.state.loading ==true"/>     
			<b-row class="mt-1">                                                                             
				<b-col class="ml-2" v-if="this.$store.state.loading ==false">
					<div class="contatc-list-scrollable scrollbar" style="margin-right: 30px;">
						<b-table-simple id="my-table"
							ref="agentTable"
							:per-page="perPage"
							:current-page="currentPage" hover small responsive>
							<b-thead head-variant="ligth">
								<b-tr>
									<b-th>Perfil comercial</b-th>
									<b-th>Número</b-th>                
									<b-th>Status</b-th>
									<b-th>Atendimento padrão</b-th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr :key="index" v-for="(x, index) in orderBy(lists, 'phonenumberName')"
									v-show="x.visible ==true">
									<b-td class="td-phonenumber">                                                          
										<u @click="openDetail(x.phonenumberId, x.phonenumber, x.phonenumberName)" class="icon-clickable">{{x.phonenumberName}}</u>
									</b-td>                      
									<b-td class="td-phonenumber">{{x.phonenumber}}</b-td>   
									<b-td class="td-data">
										<svg :class=" `phonenumber-status-color-${x.status}`" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
											<circle cx="4" cy="4" r="4" fill="`contact-status-color-${x.status}`"/>
										</svg>								
										<span :class=" `ml-2 phonenumber-status-color-${x.status}`">{{x.statusDescription}}</span>
									</b-td>                                                                                                   
									<b-td class="td-data">{{x.defaultDepartmentDescription}}</b-td>                               
									<b-td>
										<div>
											<b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" right text="Right align" no-caret>
												<template #button-content>
													<svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
														<circle cx="2" cy="2" r="2" fill="#C4C4C4"/>
														<circle cx="2" cy="11" r="2" fill="#C4C4C4"/>
														<circle cx="2" cy="20" r="2" fill="#C4C4C4"/>
													</svg>
												</template>
												<b-dropdown-item @click="openDetail(x.phonenumberId, x.phonenumber, x.phonenumberName)" >
													<span>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M10.0779 1.50136C10.2368 1.34241 10.4255 1.21632 10.6332 1.1303C10.8409 1.04428 11.0635 1 11.2883 1C11.513 1 11.7356 1.04428 11.9433 1.1303C12.151 1.21632 12.3397 1.34241 12.4986 1.50136C12.6576 1.66031 12.7837 1.84901 12.8697 2.05669C12.9557 2.26436 13 2.48695 13 2.71174C13 2.93653 12.9557 3.15912 12.8697 3.3668C12.7837 3.57447 12.6576 3.76317 12.4986 3.92212L4.32855 12.0922L1 13L1.90779 9.67145L10.0779 1.50136Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														</svg>
													</span>
													<span class="custom-font-open-12-400 ml-1">
														Editar informações
													</span>                                        
												</b-dropdown-item>
												<!-- <b-dropdown-item @click="openReport(x.phonenumberId, x.phonenumber, x.phonenumberName)">
													<span>
														<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M10 2H14V6" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M6 14H2V10" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M14 2L9.33334 6.66667" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M2 14L6.66667 9.33337" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														</svg>

													</span>
													< <span class="custom-font-open-12-400 ml-1">
														Abrir relatório de envios
													</span>                                     
												</b-dropdown-item>    -->                                                                        
												<b-dropdown-item @click="openLinkWhastapp(false)">
													<span>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M9.04158 4.37496L11.0832 2.33329M12.2499 1.16663L11.0832 2.33329L12.2499 1.16663ZM6.64408 6.77246C6.94528 7.06965 7.18472 7.42348 7.34861 7.81359C7.51249 8.20369 7.5976 8.62236 7.59902 9.04549C7.60043 9.46863 7.51814 9.88786 7.35687 10.2791C7.19559 10.6702 6.95853 11.0257 6.65933 11.3249C6.36013 11.6241 6.0047 11.8611 5.61351 12.0224C5.22231 12.1837 4.80308 12.266 4.37995 12.2646C3.95682 12.2631 3.53815 12.178 3.14804 12.0141C2.75794 11.8503 2.4041 11.6108 2.10692 11.3096C1.52249 10.7045 1.19911 9.8941 1.20642 9.05288C1.21373 8.21167 1.55115 7.40698 2.146 6.81212C2.74085 6.21727 3.54554 5.87986 4.38675 5.87255C5.22797 5.86524 6.0384 6.18862 6.6435 6.77304L6.64408 6.77246ZM6.64408 6.77246L9.04158 4.37496L6.64408 6.77246ZM9.04158 4.37496L10.7916 6.12496L12.8332 4.08329L11.0832 2.33329L9.04158 4.37496Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														</svg>
													</span>
													<span class="custom-font-open-12-400 ml-1">
														Remover número
													</span>                                        
												</b-dropdown-item>                                                                                                             
											</b-dropdown>
										</div>          
									</b-td>                           
								</b-tr>
							</b-tbody>
						</b-table-simple>  
					</div>
				</b-col>   
			</b-row>
			<b-row v-show="rows > perPage" class="pb-5">
				<b-col md="4" class="mt-2">
					<b-row>
						<b-col md="7" class="mt-1">
							<span class="mt-2 lblPagination">Visualizar por página:</span> 
						</b-col>
						<b-col md="4">                                                 
							<b-form-select v-model="selected" :options="perPageOptions" size="sm" @change="setPerPage()"></b-form-select>                
						</b-col>
					</b-row>
				</b-col>
				<b-col class="mr-5">
					<b-row class="d-flex justify-content-end">
						<b-pagination
							v-model="currentPage"
							:total-rows="rows"
							:per-page="perPage"
							pills
							size="sm"
							aria-controls="my-table"
						></b-pagination> 
					</b-row>
				</b-col>        
			</b-row>           
		</b-col>
	</div>    
</template>

<script>
import Loading from '@/views/Loading'
import WPhoneNumberAPI from '@/api/WPhoneNumberAPI'
import Vue2Filters from 'vue2-filters'
import { avatarUrl} from '@/global'
export default {
	name: 'WPhoneNumberList',
	mixins: [Vue2Filters.mixin],
	components: {
		loading: Loading,          
	},    
	data: function () {
		return {
			loading: false,
			avtUrl: avatarUrl,  
			filter: '',
			phoneNumberList: [],   
			originalPhoneNumberList: [],                                 
			perPage: 20,
			selected: 20,
			currentPage: 1,  
			totalPage: 1,  
			paginatedItems: [], 
			perPageOptions: [20, 50, 100, 200, 500],  
			showModalPassword: false,  
			componentPasswordModalKey: 0,    
			showModalSession: false,  
			componentSessionModalKey: 0,                                                       
			userIndex: null,
			msgResetPassword: '',
			msgSession: ''
		}
	},
	computed: {
		rows () {
			return this.phoneNumberList.length
		},
		lists () {
			const items = this.phoneNumberList
			return items.slice(
				(this.currentPage - 1) * this.perPage,
				this.currentPage * this.perPage
			)
		},                
	},    
	methods: {  
		openDetail (phonenumberId, phonenumber, name) {
			let objPhoneNumber = {
				phonenumberId: phonenumberId,
				phoneNumber: phonenumber,
				phonenumberName: name
			}
			localStorage.removeItem('__wpp_settings_obj')
			localStorage.setItem('__wpp_settings_obj', JSON.stringify(objPhoneNumber))            
			let obj = {step: 2.1}
			this.$emit('sendMessageData', obj)                
		},
		openReport (phonenumberId, phonenumber, name) {
			let objPhoneNumber = {
				phonenumberId: phonenumberId,
				phoneNumber: phonenumber,
				phonenumberName: name
			}
			localStorage.removeItem('__wpp_settings_obj')
			localStorage.setItem('__wpp_settings_obj', JSON.stringify(objPhoneNumber))            
			let obj = {step: 2.2}
			this.$emit('sendMessageData', obj)                
		},        		  
		openLinkWhastapp (add) {
			let number = '5547984124491'
			let wmessage = ''
			if (add == true) {
				wmessage = 'Olá, quero adicionar um novo número no whatsapp, podes me ajudar?'
			} else {
				wmessage = 'Olá, quero remover um número no whatsapp, podes me ajudar?'
			}
			let message = wmessage.split(' ').join('%20')
			window.open('https://api.whatsapp.com/send?phone=' + number + '&text=%20' + message, '_blank')
		},     		
		create () {
			this.openLinkWhastapp(true)
		},            
		getPhoneNumberList () {
			this.$store.commit('setShowLoading', true)
			WPhoneNumberAPI.getPhoneNumberList(this.$route.params.companyId, response => {
				let phoneNumberList = response.data
				this.$store.commit('setShowLoading', false)
				phoneNumberList.forEach(element => {
					element.visible = true
				})
				this.phoneNumberList = phoneNumberList                
				this.originalPhoneNumberList = this.phoneNumberList
			})
		},  
               
		setPerPage () {
			this.perPage = this.selected
		}, 
        
		timeToDate (time) {
			if (time ==0){
				return '-'
			}
			let t = new Date(Number(time))

			let fHourMinute = t.getHours() + ':'
			if(t.getMinutes()<10){
				fHourMinute+='0' 
			}
			fHourMinute+=t.getMinutes()  

			let d = new Date(Number(time))
			let day = d.getDate()        
			if(day < 10){
				day ='0' + d.getDate() 
			}
			let month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1)
			let sDate  = day  + '/' + month + '/' + d.getFullYear() 
			return sDate + ' ' + fHourMinute
		},    
		editPhoneNumber (phonenumberId, phonenumber, name) {
			let objPhoneNumber = {
				phonenumberId: phonenumberId,
				phoneNumber: phonenumber,
				phonenumberName: name
			}
			localStorage.removeItem('__wpp_settings_obj')
			localStorage.setItem('__wpp_settings_obj', JSON.stringify(objPhoneNumber))            
			let obj = {step: 2.1}
			this.$emit('sendMessageData', obj)               
		},   
		getPhonenumbers () {
			WPhoneNumberAPI.getPhonenumbers(this.$route.params.companyId, response => {
				this.phoneNumberList = response.data           
			})                
		},      
	},      
	mounted () {
		this.getPhonenumbers()
		this.getPhoneNumberList()
	},
}
</script>

<style scoped>

u {
    cursor: pointer;
}

.phonenumber-status-color-1 {
    color: #128C7E;
    fill: #128C7E;
}
.phonenumber-status-color-2 {
    color: #DD4747;
    fill:#DD4747;
}

.phonenumber-status-color-3 {
    color: #999999;
    fill:#999999;
}  

th {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #999999 !important;    
}

td {
    margin: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #777777; 
    height: 50px;
    vertical-align: middle !important;
}
.td-phonenumber {
    margin-top: 20px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.td-phonenumber {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;  
}
.td-date {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-data {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-email {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}
.td-property {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}

.td-target {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-fail {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-deliver {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}
.td-status {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-readed {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.sidebar-right-phonenumbers {
    display: flex !important;
    width: 100% !important;
    flex-direction: column !important;
    background: white !important;
    padding: 10px;
    margin-right: 50px;
}
.custom-pagination-input {
    width: 50px !important;
}
.custom-pagination-select {
    width: 60px !important;
}

.contatc-list-scrollable {
    height: 63vh;
    width: 100%;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
}
.lblPagination {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px; 
    color: #777777;   
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    min-height: 200px;
    -webkit-overflow-scrolling: touch;
}

.form-control:focus {
    background-color: #F5F5F5;
    box-shadow: none;
}

.description-ellipsis { 
    white-space: nowrap;  
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
}

.avatar {
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
</style>
