<template>	
	<div class="chatflow-list-scrollable scrollbar">
		<b-col>
			<b-col>				
				<b-row>
					<b-col md="8" class="mt-2">
						<h3 class="lbl-title">Fluxos para Chatbot</h3>
						<b-row class="ml-1">
							<span class="custom-font-open-14-gray-normal">
								Crie aqui fluxos incríveis, recheados de mensagens, perguntas, condições e etc., tudo para guiar seus contatos pela jornada deles com a sua empresa.					
							</span>  										
						</b-row>						
					</b-col>
					<b-col>
						<b-col>
							<b-row class="justify-content-end mr-5">		
								<div>
									<!-- <b-dropdown id="dropdown-1" text="Criar novo fluxo">
										<b-dropdown-item @click="openCreateFlow()">
											<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M6.1 1H2.2C1.53726 1 1 1.52415 1 2.17073V11.8293C1 12.4758 1.53726 13 2.2 13H11.8C12.4627 13 13 12.4758 13 11.8293V7.73171M8.2 1H13M13 1V5.68293M13 1L4 9.78049" stroke="#21065C" stroke-width="2"/>
											</svg>
											Criar novo fluxo   
										</b-dropdown-item>
										<b-dropdown-item @click="openChoiseTemplate()">
											<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M6.1 1H2.2C1.53726 1 1 1.52415 1 2.17073V11.8293C1 12.4758 1.53726 13 2.2 13H11.8C12.4627 13 13 12.4758 13 11.8293V7.73171M8.2 1H13M13 1V5.68293M13 1L4 9.78049" stroke="#21065C" stroke-width="2"/>
											</svg>											
											Escolher um Modelo											
										</b-dropdown-item>
									</b-dropdown> -->
									<b-button class="mr-2" v-b-toggle.sidebar-right variant="secondary"
										@click="openCreateFlow()">
										<span>
											<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M8 3V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
												<path d="M3 8H13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											</svg>
										</span>
										<span class="ml-1">
											Criar novo fluxo 
										</span>                        
									</b-button> 									
								</div>														                                                      
							</b-row>
						</b-col>                
					</b-col>
				</b-row>
			</b-col>
			<b-col>
				<loading v-if="this.$store.state.loading ==true"/>     
				<b-row>
					<b-col>
						<div class="search" style="margin-top: 4px;">  
							<b-input-group>
								<b-form-input 
									type="search"                     
									placeholder="Buscar pelo nome"
									v-model="filter"
									class="mt-1 ml-3"
									@update="clearFilter">
								</b-form-input>
								<b-input-group-prepend is-text class="mt-1">
									<b-icon icon="search"></b-icon>
								</b-input-group-prepend>
							</b-input-group>
						</div>	
					</b-col>
					<b-col class="mt-3">
						<b-dropdown size="lg" variant="ligth" :text="searchStatus">
							<b-dropdown-item @click="setSearchStatus(undefined, 'Todos')">Todos</b-dropdown-item>							
							<b-dropdown-item @click="setSearchStatus(0, 'Rascunho')">Rascunho</b-dropdown-item>
							<b-dropdown-item @click="setSearchStatus(1, 'Espelho')">Espelho</b-dropdown-item>
							<b-dropdown-item @click="setSearchStatus(2, 'Ativo')">Ativo</b-dropdown-item>
							<b-dropdown-item @click="setSearchStatus(3, 'Desativado')">Desativado</b-dropdown-item>                    
							<!-- <b-dropdown-item @click="searchChatStatus(4)">Excluído</b-dropdown-item> -->
						</b-dropdown>  												
					</b-col>
				
				</b-row>
				<b-row class="mt-1">   
					<div>
						<chatflow-modal-delete
							:title = "'Excluir fluxo'"
							:subTitle = "'Tem certeza que deseja este excluir fluxo?'"
							:description= "flowName"
							:description1= "''"
							:value = "''"
							:placeholder = "''"
							:button1 = "'Fechar'"
							:button2 = "'Excluir'"
							:start="showModalDelete"   
							v-on:sendModalData="deleteChatFlow"
							:key="componentDeleteModalKey"                        
						/>
					</div>                                               	
					<div v-if="filteredList" class="ml-3 mr-2">
						<b-table 
							id="my-table"
							class="mb-2"								
							:items="filteredList"
							:fields="fields"
							:per-page="perPage"
							:current-page="currentPage"	
							:sort-by="sortBy"
							:sort-asc="true"	
							fixed																	
							@head-clicked="rowClickHandler">
							
							<template #cell(statusDescription)="data">																		
								<div>
									<svg :class=" `flow-status-color-${data.item.flowStatus}`" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
										<circle cx="4" cy="4" r="4" fill="`flow-status-color-${data.item.flowStatus}`"/>
									</svg>									
									<span :class=" `ml-2 flow-status-color-${data.item.flowStatus}`">{{data.item.statusDescription}}</span>
								</div>																																							
							</template>	
							<template #cell(createdTime)="data">																		
								<div>
									{{timeToDate(data.item.createdTime)}}
								</div>																																							
							</template>		
							<template #cell(editedTime)="data">																		
								<div>
									{{timeToDate(data.item.editedTime)}}
								</div>																																							
							</template>															
							<template #cell(actions)="data">																		
								<div>
									<b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" right text="Right align" no-caret>
										<template #button-content>
											<svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
												<circle cx="2" cy="2" r="2" fill="#C4C4C4"/>
												<circle cx="2" cy="11" r="2" fill="#C4C4C4"/>
												<circle cx="2" cy="20" r="2" fill="#C4C4C4"/>
											</svg>
										</template>
										<b-dropdown-item @click="editChatflow(data.item.chatFlowId, data.item.flowName, data.item.flowStatus)">
											<span>
												<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M10.0779 1.50136C10.2368 1.34241 10.4255 1.21632 10.6332 1.1303C10.8409 1.04428 11.0635 1 11.2883 1C11.513 1 11.7356 1.04428 11.9433 1.1303C12.151 1.21632 12.3397 1.34241 12.4986 1.50136C12.6576 1.66031 12.7837 1.84901 12.8697 2.05669C12.9557 2.26436 13 2.48695 13 2.71174C13 2.93653 12.9557 3.15912 12.8697 3.3668C12.7837 3.57447 12.6576 3.76317 12.4986 3.92212L4.32855 12.0922L1 13L1.90779 9.67145L10.0779 1.50136Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
												</svg>
											</span>
											<span class="custom-font-open-12-400">
												Editar fluxo
											</span>                                        
										</b-dropdown-item>

										<b-dropdown-item @click="editDepartmentChatflow(data.item.chatFlowId, data.item.departmentIdList, data.item.departmentAll)" >
											<span>
												<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M10.0779 1.50136C10.2368 1.34241 10.4255 1.21632 10.6332 1.1303C10.8409 1.04428 11.0635 1 11.2883 1C11.513 1 11.7356 1.04428 11.9433 1.1303C12.151 1.21632 12.3397 1.34241 12.4986 1.50136C12.6576 1.66031 12.7837 1.84901 12.8697 2.05669C12.9557 2.26436 13 2.48695 13 2.71174C13 2.93653 12.9557 3.15912 12.8697 3.3668C12.7837 3.57447 12.6576 3.76317 12.4986 3.92212L4.32855 12.0922L1 13L1.90779 9.67145L10.0779 1.50136Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
												</svg>
											</span>
											<span class="custom-font-open-12-400 ml-1">
												Departamentos
											</span>                                        
										</b-dropdown-item>  
										<b-dropdown-item  @click="enable(data.item.chatFlowId)" v-show="data.item.flowStatus == 3"> 
											<span>
												<img src="@/assets/play.png"> 
											</span>
											<span class="custom-font-open-12-400 ml-1">
												Ativar fluxo
											</span>                                       
										</b-dropdown-item>   
										<b-dropdown-item @click="disable(data.item.chatFlowId)" v-show="data.item.flowStatus != 3">
											<span>
												<img src="@/assets/pause.png"> 
											</span>
											<span class="custom-font-open-12-400 ml-1">
												Desativar fluxo
											</span>                                        
										</b-dropdown-item>  
										<b-dropdown-item @click="removeChatFlowItem(data.item.chatFlowId, data.item.flowName)">
											<span>
												<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M3 4H4.33333H15" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M6.3335 4.00004V2.66671C6.3335 2.31309 6.47397 1.97395 6.72402 1.7239C6.97407 1.47385 7.31321 1.33337 7.66683 1.33337H10.3335C10.6871 1.33337 11.0263 1.47385 11.2763 1.7239C11.5264 1.97395 11.6668 2.31309 11.6668 2.66671V4.00004M13.6668 4.00004V13.3334C13.6668 13.687 13.5264 14.0261 13.2763 14.2762C13.0263 14.5262 12.6871 14.6667 12.3335 14.6667H5.66683C5.31321 14.6667 4.97407 14.5262 4.72402 14.2762C4.47397 14.0261 4.3335 13.687 4.3335 13.3334V4.00004H13.6668Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M7.6665 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M10.3335 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
												</svg>
											</span>
											<span class="custom-font-open-12-400 ml-1">
												Excluir fluxo
											</span>                                        
										</b-dropdown-item>   										
									</b-dropdown>									
								</div>																														
							</template>		
							<template #cell(departmentCount)="data">	
								<span :id="data.item.chatFlowId">
									<u v-if="data.item.departmentAll == true">Todos departamentos</u>
									<u v-else>{{data.item.departmentCount}} Departamentos</u>
								</span>																		
								<b-popover                       
									:target="data.item.chatFlowId" 								
									triggers="hover">									
									<template #title>
										<b-button @click="onClose(data.item.chatFlowId)" class="close ml-3" aria-label="Close">
											<span class="d-inline-block" aria-hidden="true">&times;</span>
										</b-button>
										Departamentos
									</template>                                    
									<div :key="index_" v-for="(y, index_) in data.item.departmentIdDescrMap">
										<span class="custom-font-open-12-400">{{y}}</span>
									</div>                                                                                  
								</b-popover>  																																													
							</template>																																								
						</b-table>																								
						<b-row v-show="rows > perPage" class="d-flex justify-content-center mb-3">				
							<b-pagination
								v-model="currentPage"
								:total-rows="rows"
								:per-page="perPage"
								pills
								size="sm"
								aria-controls="my-table"
							></b-pagination> 			       
						</b-row> 	
					</div>
					<div v-else class="m-5">
						<no-data-found/>
					</div>							
				</b-row> 
			</b-col>
		</b-col>    
	</div>
</template>

<script>
import ConverterDateTime from '@/mixins/ConverterDateTime'
import Loading from '@/views/Loading'
import WChatFlowAPI from '@/api/WChatFlowAPI'
import WModal from '@/views/WModal'
import NoDataFound from '@/components/global/NoDataFound'
export default {
	components: {
		loading: Loading,
		'chatflow-modal-delete': WModal,
		'no-data-found': NoDataFound,
	},
	name: 'WChatFlowList',
	data: function () {
		return {
			loading: false,			
			filter: '',
			searchStatus: 'Filtrar status',
			selectedStatus: undefined,
			chatFlowId: '',
			flowName: '',
			sortBy: '',
			chatFlowList: [],           
			perPage: 50,
			selected: 20,
			currentPage: 1,  
			totalPage: 1,  
			showModalDelete: false,   				
			componentDeleteModalKey: 0,
			paginatedItems: [], 
			perPageOptions: [20, 50, 100, 200, 500],  	
			fields: [
				{
					key: 'flowName',
					label: 'Nome do fluxo',
					sortable: true,					
					thStyle: '',
					thClass: 'b-th-data-center',
					tdClass: 'b-td-data-center'
				},
				{					
					key: 'statusDescription',
					label: 'Status',					
					sortable: true,					
					thStyle: '',
					thClass: 'b-th-data-center',
					tdClass: 'b-td-data-center'			
				},						
				{					
					key: 'stepCount',
					label: 'Etapas',					
					sortable: true,					
					thStyle: '',
					thClass: 'b-th-data-center',
					tdClass: 'b-td-data-center'			
				},				
				{					
					key: 'startCount',
					label: 'Iniciado',					
					sortable: true,					
					thStyle: '',
					thClass: 'b-th-data-center',
					tdClass: 'b-td-data-center'			
				},
				{
					key: 'endCount',
					label: 'Finalizado',
					sortable: true,
					thStyle: '',
					thClass: 'b-th-data-center',
					tdClass: 'b-td-data-center'
				},
				{					
					key: 'createdTime',
					label: 'Criado em',					
					sortable: true,
					thStyle: '',
					thClass: 'b-th-data-center',
					tdClass: 'b-td-data-center',
				},	
				{					
					key: 'editedTime',
					label: 'Atualizado em',					
					sortable: true,
					thStyle: '',
					thClass: 'b-th-data-center',
					tdClass: 'b-td-data-center',
				},
				{					
					key: 'userEditedName',
					label: 'Atualizado por',					
					sortable: true,
					thStyle: '',
					thClass: 'b-th-data-center',
					tdClass: 'b-td-data-center'
				},		
				{					
					key: 'departmentCount',
					label: 'Departamentos',					
					sortable: true,
					thStyle: '',
					thClass: 'b-th-data-center',
					tdClass: 'b-td-data-center'
				},						
				{					
					key: 'actions',
					label: '',					
					thStyle: '',
					thClass: 'b-th-data-center',
					tdClass: 'b-td-data-center'
				},
				
			],	
		}
	},    
	mixins: [ConverterDateTime],	
	computed: {		
		rows () {
			return this.chatFlowList.length
		},		
		filteredList () {	
			let items = this.chatFlowList
			if (this.selectedStatus != undefined || this.filter != '') {					
				return items.filter(item => {
					return item.flowStatus == this.selectedStatus 
					|| item.flowName.toLowerCase().includes(this.filter.toLowerCase())  
				})					
			} else {
				items.sort(this.dynamicSort(['statusDescription', '-createdTime'])) 									
				return items
			}	
		}										 	                  
	},  	
	methods: {   
		rowClickHandler (record) {
			this.fields.forEach(el => {
				if (el.key == record) {
					el.thStyle = 'color: black !important; font-size: 13px !important;'
				} else {
					el.thStyle = ''
				}
			})
		},
		dynamicSort (fields) {
			return function (a, b) {
				return fields
					.map(function (o) {
						let dir = 1
						if (o[0] === '-') {
							dir = -1
							o=o.substring(1)
						}
						if (a[o] > b[o]) 
							return dir
						if (a[o] < b[o]) 
							return -(dir)
						return 0
					})
					.reduce(function firstNonZeroValue (p,n) {
						return p ? p : n
					}, 0)
			}
		},			
		setSearchStatus	(status, description) {
			this.searchStatus = description
			this.selectedStatus = status
		},
		onClose (id) {
			this.$root.$emit('bv::hide::popover', id)   
		},		
		openCreateFlow () {
			this.$router.push({ path: '/automation/chat/flow/'+ this.$route.params.companyId + '/create' })			
		},    		  
		getChatFlowList () {			
			WChatFlowAPI.getByCompany(this.$route.params.companyId, response => {
				this.chatFlowList = response.data
				this.originalChatFlowList = response.data						
			})
		},  			  
		deleteChatFlow (deleteChatFlow) {
			if (deleteChatFlow){
				WChatFlowAPI.deleteChatflow(this.$route.params.companyId, this.chatFlowId, response => {
					if (response){
						this.getChatFlowList()                      
					} else {
						alert('Falha ao excluir fluxo!')
					}                                       
				})                
			}
		},    
		removeChatFlowItem  (id, name) {
			this.chatFlowId = id
			this.showModalDelete = true
			this.flowName = name  
			this.componentDeleteModalKey ++
		}, 
		enable (id) {			
			WChatFlowAPI.postEnableChatflow(this.$route.params.companyId, id, response => {
				if (response){
					this.getChatFlowList()                      
				} else {
					alert('Falha ao desativar fluxo!')
				}                                       
			})    			
		},			
		disable (id) {			
			WChatFlowAPI.postDisableChatflow(this.$route.params.companyId, id, response => {
				if (response){
					this.getChatFlowList()                      
				} else {
					alert('Falha ao desativar fluxo!')
				}                                       
			})    			
		},				       		  
		editChatflow (id, title, status) {			
			if (status == 2) {
				WChatFlowAPI.postEditChatflow(this.$route.params.companyId, id, response => {
					if (response){
						let newId = id
						if (response.data != id) {
							newId = response.data
						}
						let obj = {chatFlowId: newId, showFlowHeader: true, flowTitle: title}
						localStorage.removeItem('__wpp_flow_header_obj')
						localStorage.setItem('__wpp_flow_header_obj', JSON.stringify(obj))
						this.$store.commit('setFlowHeaderObj')					
						this.$router.push({ path: '/automation/chat/flow/'+ this.$route.params.companyId + '/' + newId + '/build/edit' })		                    
					} else {
						alert('Falha ao editar fluxo!')
					}                                       
				})  				
			} else {
				let obj = {chatFlowId: id, showFlowHeader: true, flowTitle: title}
				localStorage.removeItem('__wpp_flow_header_obj')
				localStorage.setItem('__wpp_flow_header_obj', JSON.stringify(obj))
				this.$store.commit('setFlowHeaderObj')					
				this.$router.push({ path: '/automation/chat/flow/'+ this.$route.params.companyId + '/' + id + '/build/edit' })						
			}				
		},
		editDepartmentChatflow (id, departmentIdList, departmentAll) {
			let obj = {departmentIdList: departmentIdList, departmentAll: departmentAll }
			localStorage.removeItem('__wpp_flow_department_obj')
			localStorage.setItem('__wpp_flow_department_obj', JSON.stringify(obj))			
			this.$router.push({ path: '/automation/chat/flow/'+ this.$route.params.companyId + '/'+ id + '/department/edit' })
		},
		clearFilter () {
			if(this.filter == ''){
				this.filteredList()
			}
		}
	},      
	mounted () {		
		this.getChatFlowList()
	}
}
</script>

<style scoped>
.chatflow-list-scrollable {
    height: 90vh;
	width: 100%;
	background: #fff;
	overflow-y: auto;
	overflow-x: hidden;
}
u {
    cursor: pointer;
}
th {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #999999 !important;    
}

td {
    margin: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #777777; 
    height: 50px;
    vertical-align: middle !important;
}

</style>
