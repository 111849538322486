import { render, staticRenderFns } from "./WWebhookAction.vue?vue&type=template&id=24490132&scoped=true&"
import script from "./WWebhookAction.vue?vue&type=script&lang=js&"
export * from "./WWebhookAction.vue?vue&type=script&lang=js&"
import style0 from "./WWebhookAction.vue?vue&type=style&index=0&id=24490132&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24490132",
  null
  
)

export default component.exports