<template>
	<div style="margin: 30px;">
		<b-row style="flex-wrap: nowrap; margin-right: 20px;">
			<b-col md="3" class="mt-2 screen-template-custom">
				<aside>
					<b-col class="ml-2">
						<b-row v-if="step ==9">
							<span class="custom-font-open-24-400">Editar transmissão</span>
						</b-row>                        
						<b-row v-else>
							<span class="custom-font-open-24-400">Nova transmissão</span>
						</b-row>
						<b-row class="mt-4">
							<span class="custom-font-open-14-gray-2">Nome</span>
						</b-row>
						<b-row class="mt-1">
							<span class="custom-font-open-16-gray-600">{{broadcastName}}</span>
						</b-row>                
						<b-row class="mt-3">
							<span class="custom-font-open-14-gray-2">Número</span>
						</b-row>    
						<b-row class="mt-1">
							<span class="custom-font-open-16-gray-600">{{phoneNumber}}</span>
						</b-row>     
						<b-row class="mt-3" v-show="step != 9">
							<span class="custom-font-open-14-gray-2">Departamento</span>
						</b-row>    
						<b-row class="mt-1" v-show="step != 9">
							<span class="custom-font-open-16-gray-600">{{departmentName}}</span>
						</b-row>  
						<b-row class="mt-3" v-show="templateName != '' && step != 9">
							<span class="custom-font-open-14-gray-2">Template</span>
						</b-row>    
						<b-row class="mt-1" v-show="templateName != '' && step != 9">
							<div style="word-wrap: break-word; max-width: 280px; ">
								<span class="custom-font-open-16-gray-600">{{templateName}}</span>
							</div>                            
						</b-row>                          
						<b-row class="mt-3">
							<b-col>
								<b-button variant="outline-primary" class="mr-2"
									@click="cancel">Cancelar
								</b-button> 
							</b-col>                            
						</b-row>                                             
					</b-col>                           
				</aside>
			</b-col>
			<b-col md="9" class="mt-2 screen-template-custom" v-if="step==1">
				<broadcast-template 
					:broadcastId="this.$route.params.broadcastId"
					:departmentId="this.$route.params.departmentId"
					v-on:sendMessageData="getMessageData"
				/>
			</b-col>     
			<b-col md="9" class="mt-2 screen-template-custom" v-if="step==2">
				<broadcast-datasource 
					:broadcastId="this.$route.params.broadcastId"
					:departmentId="this.$route.params.departmentId"
					v-on:sendMessageData="getMessageData"
				/>
			</b-col>  
			<b-col md="9" class="mt-2 screen-template-custom" v-if="step==3">
				<broadcast-terms 
					:broadcastId="this.$route.params.broadcastId"
					:departmentId="this.$route.params.departmentId"
					v-on:sendMessageData="getMessageData"
				/>
			</b-col>                                     
			<b-col md="9" class="mt-2 screen-template-custom" v-if="step==4">
				<broadcast-upload 
					:broadcastId="this.$route.params.broadcastId"
					:departmentId="this.$route.params.departmentId"
					v-on:sendMessageData="getMessageData"
				/>
			</b-col>      
			<b-col md="9" class="mt-2 screen-template-custom" v-if="step==5">
				<broadcast-contacts 
					:broadcastId="this.$route.params.broadcastId"
					:departmentId="this.$route.params.departmentId"
					v-on:sendMessageData="getMessageData"
				/>
			</b-col>   
			<b-col md="9" class="mt-2 screen-template-custom" v-if="step==6">
				<broadcast-property 
					:broadcastId="this.$route.params.broadcastId"
					:departmentId="this.$route.params.departmentId"
					v-on:sendMessageData="getMessageData"
				/>
			</b-col>
			<b-col md="9" class="mt-2 screen-template-custom" v-if="step==7">
				<broadcast-actions 
					v-on:sendMessageData="getMessageData"
				/>
			</b-col>
			<b-col md="9" class="mt-2 screen-template-custom" v-if="step==7.1">
				<broadcast-action-select
					:broadcastId="this.$route.params.broadcastId"
					:actionType="actionType"
					v-on:sendMessageData="getMessageData"
				/>
			</b-col>
			<b-col md="9" class="mt-2 screen-template-custom" v-if="step==7.2">
				<broadcast-action-list
					:broadcastId="this.$route.params.broadcastId"
					:actionType="actionType"
					v-on:sendMessageData="getMessageData"
				/>
			</b-col> 
			<b-col md="9" class="mt-2 screen-template-custom" v-if="step == 8 || step == 9">
				<broadcast-send 
					:broadcastId="this.$route.params.broadcastId"
					:departmentId="this.$route.params.departmentId"
					:step="step"
					:whocalled="whocalled"
					:startAt="broadcastStartAt"
					v-on:sendMessageData="getMessageData"
				/>
			</b-col>                                                                  
		</b-row> 
    
	</div>
   
</template>

<script>
import WBroadcastTemplate from './WBroadcastTemplate'
import WBroadcastDatasource from './WBroadcastDatasource'
import WBroadcastTerms from './WBroadcastTerms'
import WBroadcastUpload from './WBroadcastUpload'
import WBroadcastContacts from './WBroadcastContacts'
import WBroadcastProperty from './WBroadcastProperty'
import WBroadcastSend from './WBroadcastSend'
import WBroadcastActions from './WBroadcastActions.vue'
import WBroadcastActionSelect from './WBroadcastActionSelect.vue'
import WBroadcastActionList from './WBroadcastActionList.vue'
export default {    
	components: 
	{
		'broadcast-template': WBroadcastTemplate,
		'broadcast-datasource': WBroadcastDatasource,
		'broadcast-terms': WBroadcastTerms,
		'broadcast-actions': WBroadcastActions,
		'broadcast-upload': WBroadcastUpload,
		'broadcast-contacts': WBroadcastContacts,
		'broadcast-property': WBroadcastProperty,
		'broadcast-send': WBroadcastSend,
		'broadcast-action-select': WBroadcastActionSelect,
		'broadcast-action-list' : WBroadcastActionList
	},
	name: 'WBroadcastNew',  
	data: function () {
		return {
			step: 1,
			loading: false,
			broadcastName: '',
			phoneNumber: '',
			departmentName: '',
			templateName: '',
			broadcastStartAt: '',
			whocalled: '',
			actionType: 0
		}
	},
	methods: { 
		getMessageData (obj) {
			localStorage.removeItem('__wpp_broadcast_step')  
			if (obj.templateName){
				this.templateName = obj.templateName 
			} 
                      
			let objStep = {currentStep: obj.step, templateName: this.templateName}
			localStorage.setItem('__wpp_broadcast_step', JSON.stringify(objStep))            
			this.step = obj.step
			if (obj.step == 8){
				this.whocalled = obj.whocalled
			}

			if(obj.actionType){
				localStorage.setItem('__wpp_broadcast_action', JSON.stringify(obj.actionType))
				this.actionType = obj.actionType
			}
		},
		cancel () {
			localStorage.removeItem('__wpp_broadcast_step')
			localStorage.removeItem('__wpp_broadcast_data_validate')
			localStorage.removeItem('__wpp_broadcast_name')
			localStorage.removeItem('__wpp_broadcast_action')
			this.$router.push({ path: '/broadcast/'+ this.$route.params.companyId +'/list'})
		}          
	},
	mounted () {
		let broadcast = JSON.parse(localStorage.getItem('__wpp_broadcast_name'))   
		if (broadcast != null && broadcast != undefined){
			this.broadcastName = broadcast.broadcastName
			this.phoneNumber = broadcast.broadcastPhoneNumber
			this.departmentName = broadcast.broadcastDepartament
			this.broadcastStartAt = broadcast.broadcastStartAt
		}
		let step = JSON.parse(localStorage.getItem('__wpp_broadcast_step'))   
		if (step != null && step != undefined){
			this.step = step.currentStep
			this.templateName = step.templateName        
		}        
	}    
}
</script>

<style>

</style>

