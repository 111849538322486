<template>
	<div>
		<span>{{ timeFormatter(date)}}</span>
	</div>
</template>

<script>
export default {
	props: [
		'date',
	],
	methods:{
		timeFormatter (time) {
			if(time == 0){
				return ''
			} else {
				let aux = new Date(time)
				aux = aux.toLocaleDateString('pt-BR')
				return aux
			}
		},
	},
}

</script>

<style>

</style>