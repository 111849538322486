
import axios from 'axios'
import i18n from '../plugins/i18n'
import { baseApiUrl, access_token} from '../global'


axios.defaults.headers.common['Authorization'] = `bearer ${access_token}`


export default {
   
	getListByCompany: (companyId, callback) => {
		const url = `${baseApiUrl}`+'webhook/'+ companyId +'/listByCompany'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},    

	getListEvents: (companyId, type, callback) => {
		const url = `${baseApiUrl}`+'webhook/'+ companyId + '/' + type +'/listEvents'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},    

	create (companyId, webhookType, webhookUrl, emailOnly, eventNameList, callback) {  
		const params = new URLSearchParams()
		params.append('webhookType', webhookType)
		params.append('webhookUrl', webhookUrl)
		params.append('emailOnly', emailOnly)
		params.append('eventNameList', eventNameList)        
		let url = `${baseApiUrl}` + 'webhook/'+ companyId + '/create'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(true)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},        
  
	deleteWebhook (companyId, webhookId, callback) {            
		let url = `${baseApiUrl}` + 'webhook/'+ companyId + '/' + webhookId + '/deleteWebhook'
		axios.delete(url)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},                             
}
