<template>
	<div>
		<b-row>
			<span class="custom-font-open-14-400-gray">Escolha uma marcação para ser enviada como resposta à palavra chave</span>        
		</b-row>   
		<b-row class="mb-1">
			<b-col md="10">
				<div class="search" style="margin-left: -15px;">  
					<b-input-group>
						<b-form-input 
							type="search"                     
							placeholder="Busque pelo nome da marcação"
							v-model="filter"
							@keyup="searchLabels"
							class="mt-1"
							@update="clearFilter">
						</b-form-input>
						<b-input-group-prepend is-text class="mt-1">
							<b-icon icon="search"></b-icon>
						</b-input-group-prepend>                    
					</b-input-group>                                                                                       
				</div>
			</b-col>
		</b-row>
		<div class="automation-labels-scrollable scrollbar">
			<div v-for="(x, index) in orderBy(labelList, 'labelDescription')" :key="index" :class="[ 'automation-row-label', index == currentIndex ? 'label-select-active' : '' ]"
				v-show="x.visible == true">
				<div class="m-1" @click="setCurrentIndex(index, x.labelId)">    
					<label :for="x.labelId" style="width: 100%; cursor: pointer; margin-top: 5px;">                       
						<b-form-radio
							:id="x.labelId"
							:name="'option-selected'"
							value="x.labelId"
							size="lg"
						>                                                                      
							<span class="ml-1 icon-clickable" style="line-height: 30px;">
								<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0.647582 8.40511L0.702352 1.62951C0.703679 1.45388 0.737848 1.28025 0.802908 1.11854C0.867968 0.956839 0.962642 0.810232 1.08152 0.687103C1.20039 0.563974 1.34113 0.466737 1.4957 0.400955C1.65026 0.335172 1.81562 0.302134 1.98231 0.303728L9.27913 0.364722C9.53738 0.366881 9.78414 0.477055 9.96511 0.671L13.7878 4.76773C13.9762 4.96963 13.9734 5.29435 13.7816 5.49302L9.89396 9.51984C9.70964 9.71076 9.46092 9.81673 9.20257 9.81441L1.90148 9.7489C1.56638 9.74484 1.24643 9.60124 1.01149 9.34946C0.776552 9.09768 0.645725 8.75818 0.647582 8.40511Z" :fill="x.labelColor"/>
								</svg>
							</span>
							<span class="ml-3 icon-clickable custom-font-open-14-gray" style="line-height: 30px;">{{x.labelDescription}}</span>                            
						</b-form-radio>
					</label>                                        
				</div>
			</div>  
		</div>                                           
	</div>                                          
</template>

<script>
import WLabelsAPI from '@/api/WLabelsAPI'
import Vue2Filters from 'vue2-filters'
import { avatarUrl} from '@/global'
export default {
	mixins: [Vue2Filters.mixin],
	name: 'WAutomationLabels',    
	data () {
		return { 
			filter: '',
			avtUrl: avatarUrl,
			labelList: [],
			currentIndex: null
		}
	},    
	methods: {
		getLabelsList () {
			this.labelList = []
			WLabelsAPI.getListByCompanyId(this.$route.params.companyId, response => {
				let labelList = response.data
				labelList.forEach(lbl => {
					lbl.visible = true
				})     
				this.labelList = labelList                        
			})    
		},
		setCurrentIndex (index, id) {       
			this.currentIndex = index
			let obj = {id: id}
			this.$emit('sendMessageData', obj)                
		},       
		searchLabels () {
			this.labelList.forEach( el => {
				if ( el.labelDescription.toLowerCase().includes(this.filter.toLowerCase()) ){
					el.visible = true
				} else {
					el.visible = false
				}
			})        
		},
		clearFilter () {
			if(this.filter == ''){
				this.searchLabels()
			}
		}                     
	},
	mounted () {    
		this.getLabelsList()                        
	}, 
}
</script>

<style scoped>
.automation-labels-scrollable {
    height: 40vh;
    width: 100%;
    min-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: -15px; 
}
.automation-row-label {
    width: 100%;
    cursor: pointer;
}

</style>

 
