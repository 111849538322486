
<template>
	<b-col id="main" class="webhook-scrollable scrollbar">

		<b-col class="mt-3 ml-4">
			<span class="custom-tittle-webhook-logs">Log da API</span>
	
	
			<b-form-group>
				<b-form-radio-group
					class="mt-2 radio-style"
					id="btn-radios-1"
					v-model="selectedOption"
					:options="options"
					@change="postLogs"
					name="radios-btn-default"
					buttons
				></b-form-radio-group>
			</b-form-group>
		</b-col>
		
		
		
		<b-col class="ml-2">
			<b-row>
				<b-col sm="3" class="search-log mt-1">
					<b-input-group >
						<b-form-input 
							v-model="key"
							type="search"                     
							placeholder="Buscar palavra"
							@keyup="searchKey()"
							class="ml-3"
							@update="clearFilter"
						>
						</b-form-input>
						<b-input-group-prepend is-text class="">
							<b-icon icon="search"></b-icon>
						</b-input-group-prepend>                    
					</b-input-group>
					<b-row v-if="startDate != '' && startDate != undefined " class="ml-3" >
						<span style="text-decoration:underline rgb(33 6 92);" class="td-style">{{startDate.toLocaleDateString('pt-BR')}} -> {{endDate.toLocaleDateString('pt-BR')}}</span>
					</b-row>
					
				</b-col>
				<b-col  id="filters">
					<b-row class="dropdown-hover-style">
						<b-col sm="auto">
							<b-dropdown
								class="filter-dropdown"
								text="Data"
								ref="calendar"
							>
								<v-date-picker v-model="range"
									mode="date"
									is-range
									@input="teste"
									locale = "PT-br"
									color="blue">
							
							
								</v-date-picker>
							</b-dropdown>
						
						</b-col>
			
						<b-col sm="auto" class="dropdown-option-style">
							
							<b-dropdown
								text="Método"
								class="filter-dropdown"
							>
								<b-form-checkbox-group
									id="checkbox-group-2"
									v-model="checkboxMethodSelected"
									:options="checkboxMethodOptions"
									stacked
								></b-form-checkbox-group>
							</b-dropdown>
						</b-col>
						<b-col sm="auto">
							<b-dropdown
								text="Endpoint da API"
								class="filter-dropdown"
							>	
								<b-input v-model="endpointFilter" placeholder="/v1/customers" style="margin-left: 8px;width: 90%;"></b-input>	
								<!-- <b-button @click="postLogs()">Aplicar</b-button>
								<b-button @click="endpointFilter ='', postLogs()">Limpar</b-button> -->
							</b-dropdown>
						</b-col>
						<b-col sm="auto" :class="[(this.$mq == 'md') ? 'mt-2' : '' ]">
							<b-dropdown
								text="Endereço IP"
								class="filter-dropdown"
							>
								<b-input v-model="ipFilter" placeholder="192.168.0.1" style="margin-left: 8px;width: 90%;"></b-input>
								<!-- <b-button @click="postLogs()">Aplicar</b-button>
								<b-button @click="ipFilter ='', postLogs()">Limpar</b-button> -->
							</b-dropdown>
						</b-col>
					
					</b-row>
					<b-row class="mt-2 button-hover-style">
						<b-col sm="auto" >
							<b-button class="filter-button" @click="emptyFilter()">Limpar Filtros</b-button>
						</b-col>
						<b-col  sm="auto">
							<b-button :disabled="validateFilter" class="filter-button" @click="postLogs()" >Aplicar Filtros</b-button>
						</b-col>
					</b-row>

				</b-col>                                                                    
			</b-row>
			<b-col sm="12" class="tables"> 
				<b-row>
					<b-col sm="6">
						<b-table-simple id="my-table"
							class="log-list scrollbar"
							hover small responsive>
							<b-thead head-variant="ligth">
								<b-tr>
									<b-th>
										<span class="header-custom ml-1">HTTP</span>
									</b-th>
									<b-th>
										<span class="header-custom">Método</span>
									</b-th>
									<b-th>
										<span class="header-custom">Horário</span>
									</b-th>
									<b-th>
										<span class="header-custom">Endpoint</span>
									</b-th>
								</b-tr>
							</b-thead>
							<b-tbody >												
								<b-tr @click="onRowSelected(x)" :key="index" v-for="(x, index) in logList" :class="[x == selectedRow? 'selected-row-style':'']">											
									<b-td style="vertical-align:middle"> 
										<div class="ml-1" :class="httpClass(x.httpCode)">
											<span>
												{{x.httpCode}}
											</span>
										</div>
									</b-td>
									<b-td style="vertical-align:middle"> 
										<div>
											<span class="td-style">
												{{x.httpMethod}}
											</span>
										</div>
									</b-td>
									<b-td > 
										<div >
											<span class="td-style">
												{{timeFormatter(x.requestTime)}}
											</span>
										</div>
									</b-td>
									<b-td style="vertical-align:middle"> 
										<div>
											<span class="td-style">
												{{x.endpoint}}
											</span>
										</div>
									</b-td>
									
								</b-tr>
							</b-tbody>
						</b-table-simple> 
						<b-row  class="justify-content-center">
							<b-button :disabled="toLoadMore" variant="outline-primary" class="mr-2"
								@click="loadMore()">Carregar mais
							</b-button>
						</b-row>   
					</b-col>
					<b-col v-if="httpCode != '' && httpCode != undefined" sm="6" id="row-info" class="mt-1">
						<div class="log-list scrollbar">
							<b-row class="ml-0 mt-1">
								<span class="custom-font-open-16-b-b word-break">{{endpoint}}</span>
							</b-row>
							<b-row>
								<b-col sm="auto" class="td-style">
									<b-row class="ml-0 mt-1 mb-2">
										<span style="width:100px">Status:</span>
										<div :class="httpClass(httpCode)" style="width:30px">
											<span>
												{{httpCode}}
											</span>
										</div>
										
									</b-row>
									<b-row class="ml-0 mb-2">
										<span style="width:100px">ID:</span>
										<span>{{transactionId}}</span>
									</b-row>
									<b-row class="ml-0 mb-2">
										<span style="width:100px">Horário:</span>
										<span>{{requestTime}}</span>
									</b-row>
									<b-row class="ml-0 mb-2">
										<span style="width:100px">Ip:</span>
										<span>{{ipOrigin}}</span>
									</b-row>
									<b-row class="ml-0 mb-2">
										<span style="width:100px">Navegador:</span>
										<span>{{userAgent}}</span>
									</b-row>
								</b-col>
							</b-row>
							<b-col v-if="httpRequest != null && httpRequest != '' && httpRequest != 'null'">
								<hr>
								<span>HTTP Request:</span>
								<b-row>
									<json-viewer
										
										theme="my-awesome-json-theme"
										:value="httpRequest"
										:expand-depth=5
										:copyable = copyable						
									></json-viewer>
								</b-row>
							</b-col>	
							<b-col v-if="httpResponse != null && httpResponse != '' && httpResponse != 'null'">
								<hr>
								<span>HTTP Response:</span>
								<b-row>
									<json-viewer
										theme="my-awesome-json-theme"
										:value="httpResponse"
										:expand-depth=5
										:copyable = copyable	
									></json-viewer>
								</b-row>
							</b-col>
							
						</div>
					
					</b-col> 
				
				</b-row>
			</b-col>
	
		</b-col>
	

	</b-col>  
</template>

<script>
import WKeyAPI from '@/api/WKeyAPI'
export default {
	name: 'WWebhookLog',
	computed: {
		
		validateFilter () {
			if(this.confirmEndpointFilter == this.endpointFilter){
				if(this.confirmIpFilter == this.ipFilter){
					if(this.confirmCheckboxMethodSelected == this.checkboxMethodSelected){
						if(this.confirmStartDate == this.range.start){
							if(this.confirmEndDate == this.range.end){
								return true
							}
						}
					}
				}
			}
			return false
		},    	
	},  
	props: [
		'step'
	],   
	data () {
		return { 
			fields:
				[{key:'httpCode',label: 'HTTP', tdClass:'httpClass'},
					{key:'httpMethod',label: 'Método'},
					{key:'requestTime',label: 'Horário',formatter: 'timeFormatter'},
					'endpoint',],
			selectedOption: 1,
			options: [
				{ text: 'Todas', value: 1 },
				{ text: 'Concluído', value: 2 },
				{ text: 'Malsucedido', value: 3 }
			],
			startDate: '',
			endDate: '',
			selectedRow:'',
			checkboxSelected: [], // Must be an array reference!
			checkboxOptions: [
				'Concluido',
				'Malsucedido',
			],
			checkboxMethodSelected: [], // Must be an array reference!
			checkboxMethodOptions: [
				
				{ text: 'GET', value: 'GET' },
				{ text: 'POST', value: 'POST' },
				{ text: 'DELETE', value: 'DELETE' },
				{ text: 'PUT', value: 'PUT' },
				{ text: 'OPTIONS', value: 'OPTIONS' },
			],
			endpointFilter:'',
			ipFilter:'',
			phonenumberFilter:'',
			userAgentFilter:'',
			logList:[],
			httpCode:'',
			requestTime:'',
			transactionId:'',
			ipOrigin:'',
			endpoint:'',
			backupLogList:[],
			methodSelected:'',
			httpRequest:'',
			httpResponse:'',
			range:{end:'',start:''},
			selectMode:'single',
			copyable :{copyText: 'Copiar', copiedText: 'Copiado', timeout: 2000},
			key:'',
			confirmEndpointFilter:'',
			confirmIpFilter:'',
			confirmCheckboxMethodSelected:'',
			confirmStartDate:'',
			confirmEndDate:'',
			userAgent:'',
			toLoadMore: false

		}
	},    
	methods: {
		teste () {
			this.$refs.calendar.hide(true)
		},
		searchKey () {
			let aux = []
			this.logList = this.backupLogList
			this.logList.forEach((element) => {
				if(element.companyId.includes(this.key)){
					aux.push(element)
				}else if(element.userId.includes(this.key)){
					aux.push(element)
				}else if(element.endpoint.includes(this.key)){
					aux.push(element)
				}else if(element.httpCode.toString().includes(this.key)){
					aux.push(element)
				}else if(element.httpMethod.includes(this.key)){
					aux.push(element)
				}else if(element.ipOrigin.includes(this.key)){
					aux.push(element)
				}else if(element.phonenumberId.includes(this.key)){
					aux.push(element)
				}else if(new Date(element.requestTime).toLocaleDateString('pt-BR').includes(this.key)){
					aux.push(element)
				}else if(new Date(element.requestTime).getSeconds().toString().includes(this.key)){
					aux.push(element)
				}else if(new Date(element.requestTime).getMinutes().toString().includes(this.key)){
					aux.push(element)
				}
				else if(new Date(element.requestTime).getHours().toString().includes(this.key)){
					aux.push(element)
				}
				else if(element.transactionId.includes(this.key)){
					aux.push(element)
				}else if(element.userAgent.includes(this.key)){
					aux.push(element)
				}
			})
			this.logList = aux
			this.shouldLoadMore()
		},
		emptyFilter () {
			this.startDate = ''
			this.endDate = ''
			this.selectedRow=''
			this.range = []
			this.selectedOption = 1
			this.ipFilter =''
			this.endpointFilter =''
			this.checkboxMethodSelected=[]
			this.httpCode = '' //reset selected row
			this.postLogs()
		},
		httpClass (item) {
			if(item < 300){
				return 'table-cell-style-success'
			}else if(item < 400){
				return 'table-cell-style-success-yellow'
			}else{
				return 'table-cell-style-fail'
			}
		},

		timeFormatter (time) {
			let aux = new Date(time)
			aux = aux.toLocaleDateString('pt-BR') +'      '+ aux.toLocaleTimeString('pt-BR')	
			return aux
		},
		
		filter () {
			this.confirmEndpointFilter = this.endpointFilter
			this.confirmIpFilter = this.ipFilter
			this.confirmCheckboxMethodSelected = this.checkboxMethodSelected
			this.confirmStartDate = this.startDate
			this.confirmEndDate = this.endDate
			let aux = []
			
			this.logList.forEach((element) => {
				if(element.endpoint.includes(this.endpointFilter) || this.endpointFilter ==''){
					if(element.ipOrigin.includes(this.ipFilter) || this.ipFilter ==''){
						if(this.checkboxMethodSelected.length > 1){
							this.checkboxMethodSelected.forEach(e => {
								if(element.httpMethod == e){
									aux.push(element)
								}
							})
						}else if(element.httpMethod == this.checkboxMethodSelected || this.checkboxMethodSelected ==''){
							aux.push(element)
						}
					}
				}
			})
			let aux2 = []
			if(this.selectedOption == 1){
				this.logList = aux
			}else if(this.selectedOption == 2){
				aux.forEach((element) => {
					if(element.httpCode == 200 || element.httpCode == 201 ){
						aux2.push(element)
					}
				})
				this.logList = aux2
			}else if(this.selectedOption == 3){
				aux.forEach((element) => {
					if(element.httpCode != 200 && element.httpCode != 201 ){
						aux2.push(element)
					}
				})
				this.logList = aux2
			}
			this.backupLogList = this.logList
			this.searchKey()
			this.httpCode = '' //reset selected row
		},  
		loadMore () {
			if(this.startDate != undefined && this.startDate != '' ){
				if(this.logList[this.logList.length - 1].requestTime -1 < this.startDate.getTime() ){
					this.toLoadMore ==true
					return
				}else{
					let endDate =  new Date(this.logList[this.logList.length - 1].requestTime -1)
					endDate =Date.parse(endDate)
					let startDate = new Date(this.range.start).setHours(0,0,0,0)
					WKeyAPI.postListApiLog(this.$route.params.companyId,startDate, endDate, this.phonenumberFilter,this.ipFilter,this.userAgentFilter,this.endpointFilter,response => {
						this.logList.push.apply(this.logList,response.data)
						this.filter()
						this.backupLogList = this.logList
					}) 
					this.httpCode = '' //reset selected row
				}
			}else{
				let endDate =  new Date(this.logList[this.logList.length - 1].requestTime -1)
				let startDate = new Date(this.range.start).setHours(0,0,0,0)
				endDate =Date.parse(endDate)
				WKeyAPI.postListApiLog(this.$route.params.companyId,startDate, endDate, this.phonenumberFilter,this.ipFilter,this.userAgentFilter,this.endpointFilter,response => {
					this.logList.push.apply(this.logList,response.data)
					this.filter()
					this.backupLogList = this.logList
				}) 
				this.httpCode = '' //reset selected row
			}
		},
		getLogDetail (id) {	         
			WKeyAPI.getApiLogDetail(this.$route.params.companyId,id,response => {
				this.httpRequest = response.data.httpRequest
				this.httpResponse = response.data.httpResponse
			}) 
		},
			
		shouldLoadMore () {
			let auxLogList = []
			if(this.startDate == '' || this.startDate == undefined){
				this.toLoadMore = false
			}
			
			if(this.logList.length == 0){
				this.toLoadMore = true
			}else{
				let endDate =  new Date(this.logList.at(-1).requestTime -1000)
				endDate = Date.parse(endDate)
				let startDate = new Date(this.range.start).setHours(0,0,0,0)
				WKeyAPI.postListApiLog(this.$route.params.companyId,startDate, endDate, this.phonenumberFilter,this.ipFilter,this.userAgentFilter,this.endpointFilter,response => {
					let aux=[]
					if(response.data.length > 0){
						response.data.forEach((element) => {
							if(element.endpoint.includes(this.endpointFilter) || this.endpointFilter ==''){
								if(element.ipOrigin.includes(this.ipFilter) || this.ipFilter ==''){
									if(this.checkboxMethodSelected.length > 1){
										this.checkboxMethodSelected.forEach(e => {
											if(element.httpMethod == e){
												aux.push(element)
											}
										})
									}else if(element.httpMethod == this.checkboxMethodSelected || this.checkboxMethodSelected ==''){
										aux.push(element)
									}
								}
							}
						})
						let aux2 = []
						if(this.selectedOption == 1){
							auxLogList = aux
						}else if(this.selectedOption == 2){
							aux.forEach((element) => {
								if(element.httpCode == 200 || element.httpCode == 201 ){
									aux2.push(element)
								}
							})
							auxLogList = aux2
						}else if(this.selectedOption == 3){
							aux.forEach((element) => {
								if(element.httpCode != 200 && element.httpCode != 201 ){
									aux2.push(element)
								}
							})
							auxLogList = aux2
						}
						aux=[]
						auxLogList.forEach((element) => {
							if(element.companyId.includes(this.key)){
								aux.push(element)
							}else if(element.userId.includes(this.key)){
								aux.push(element)
							}else if(element.endpoint.includes(this.key)){
								aux.push(element)
							}else if(element.httpCode.toString().includes(this.key)){
								aux.push(element)
							}else if(element.httpMethod.includes(this.key)){
								aux.push(element)
							}else if(element.ipOrigin.includes(this.key)){
								aux.push(element)
							}else if(element.phonenumberId.includes(this.key)){
								aux.push(element)
							}else if(new Date(element.requestTime).toLocaleDateString('pt-BR').includes(this.key)){
								aux.push(element)
							}else if(new Date(element.requestTime).getSeconds().toString().includes(this.key)){
								aux.push(element)
							}else if(new Date(element.requestTime).getMinutes().toString().includes(this.key)){
								aux.push(element)
							}
							else if(new Date(element.requestTime).getHours().toString().includes(this.key)){
								aux.push(element)
							}
							else if(element.transactionId.includes(this.key)){
								aux.push(element)
							}else if(element.userAgent.includes(this.key)){
								aux.push(element)
							}
						})
						auxLogList = aux
					}
					if(auxLogList.length == 0){
						this.toLoadMore = true
					}else{
						this.toLoadMore = false
					}
				})
			}
		},

		postLogs () {
			this.startDate = this.range.start
			this.endDate = this.range.end
			let startDate = new Date(this.range.start).setHours(0,0,0,0)
			let endDate =new Date(this.range.end).setHours(23,59,59,999)
			WKeyAPI.postListApiLog(this.$route.params.companyId,startDate, endDate, this.phonenumberFilter,this.ipFilter,this.userAgentFilter,this.endpointFilter,response => {
				this.logList = response.data
				this.backupLogList = response.data
				this.filter()
			}) 
			this.httpCode = '' //reset selected row
		},	
		onRowSelected (items) {	
			this.getLogDetail(items.transactionId)
			this.selectedRow = items
			if(this.selectedRow.length != ''){
				this.httpCode = items.httpCode
				this.requestTime = new Date(items.requestTime)
				this.requestTime = this.requestTime.toLocaleDateString('pt-BR') +'  '+ this.requestTime.toLocaleTimeString('pt-BR')
				this.transactionId = items.transactionId
				this.ipOrigin = items.ipOrigin
				this.endpoint = items.endpoint
				this.methodSelected = items.httpMethod
				this.userAgent = items.userAgent
			}else{
				this.httpCode = ''
			}
		},
		clearFilter () {
			if(this.key == ''){
				this.searchKey()
			}
		}
	},
	mounted () { 
		this.postLogs()
	}, 
}
</script>

<style lang="scss">
	.webhook-scrollable {
			height: 87vh;
			width: 100%;
			background: #fff;
			overflow-y: scroll;
			overflow-x: hidden;
	}
	.main{
		display: flex;
		flex-flow: column;
		height: 100%;
	}
	.tables{
		display: flex;
		flex-flow: column;
		height: 100%;
	}
	.log-list{
		max-height: 63vh;
	}

    .search-log {
    background: #fff;
	}
	.table-selected{
		color:purple
	}
	.selected-options{
		padding: 5px;
	}
	.word-break{
		word-break: break-word;
	}

 .my-awesome-json-theme {
  background: #fff;
  white-space: nowrap;
  color: #525252;
  font-size: 14px;
  font-family: Consolas, Menlo, Courier, monospace;

  .jv-ellipsis {
    color: #999;
    background-color: #eee;
    display: inline-block;
    line-height: 0.9;
    font-size: 0.9em;
    padding: 0px 4px 2px 4px;
    border-radius: 3px;
    vertical-align: 2px;
    cursor: pointer;
    user-select: none;
  }
  .jv-button { color: var(--primary)}
  .jv-key { color: #111111 }
  .jv-item {
    &.jv-array { color: #111111 }
    &.jv-boolean { color: #fc1e70 }
    &.jv-function { color: #067bca }
    &.jv-number { color: #fc1e70 }
    &.jv-number-float { color: #fc1e70 }
    &.jv-number-integer { color: #fc1e70 }
    &.jv-object { color: #111111 }
    &.jv-undefined { color: #e08331 }
    &.jv-string {
      color: #3a03b1;
      word-break: break-word;
      white-space: normal;
    }
  }
  .jv-code {
    .jv-toggle {
      &:before {
        padding: 0px 2px;
        border-radius: 2px;
      }
      &:hover {
        &:before {
          background: #eee;
        }
      }
    }
  }
}
.custom-tittle-webhook-logs {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 20px !important;  
    color: #414141 !important;
}

.radio-style{
	.btn-secondary{
		background-color: white;
		color: black;
		border-color:white;
	}
	

	.btn-secondary:not(:disabled):not(.disabled).active{
		background-color: var(--secondary);
		border-radius: 5px;
		font-weight: bold;
	}
	.btn-secondary:hover{
    color: #fff;
	background-color: var(--primary);
	border-radius: 5px;
}
}
.button-hover-style{
	
	.btn-secondary:hover{
    color: #fff;
	background-color: var(--primary);
	border-radius: 5px;
	}
	.btn-secondary:focus{
		color: white;
    background-color: var(--secondary);
    border-color: var(--secondary);
	box-shadow: none

	}
}
.dropdown-hover-style{
		.btn-secondary {
		color: #fff;
		background-color: white;
		border-color: var(--secondary);
		padding: 8px 20px;
		font-size: 14px;
		font-weight: 500;
	}
		.btn-secondary:hover{
		color: #fff;
		background-color: var(--primary);
		border-radius: 25px;
		}
		.show > .btn-secondary.dropdown-toggle{
		color: #fff;
		background-color: var(--primary);
		border-radius: 25px;
		}
		.btn:focus{
		background-color: white;
		color: #777777;
		border-color:#777777;
		border-radius: 25px;
		box-shadow: none;
	}
}
.dropdown-option-style{
	.custom-control{
		margin-left: 10%;
		
	}
	.custom-control-label{
		font-size: 14px;
		font-weight: 500;
	}
}
.button-load-style{

	.btn-secondary{
		background-color: white;
		color: var(--primary);
		font-weight: bold;
		border-color:white;
	}
	.btn-secondary:hover{
    color: var(--primary);
	background-color: #fff;
	border-color: white;
	text-shadow: 1px 1px 10px;
	}
	.btn-secondary:focus{
		background-color: white;
		color: var(--primary);
		font-weight: bold;
		border-color:white;
		box-shadow: none;

	}
	
}

.cell-style{
	.table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
}
}
.selected-row-style{
	box-shadow: inset 3px 0 0 0 var(--primary);
}

.header-custom {
	font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #999999;	
}
.td-style {
    margin-top: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px; 
    color: #777777;
}
</style>

 
