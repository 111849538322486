
<template>
	<div class="mr-5 mb-5">
		<b-row class="mt-2">
			<span class="custom-font-open-16-400">
				Atenção!						
			</span>
		</b-row>
		<b-row class="mt-3">
			<span class="custom-font-open-14-400-gray">
				Ao criar esta reação, o sistema da Becon irá fechar o atendimento do contato que clicou nesta palavra chave.
			</span>					
		</b-row>
		<b-row class="mt-3">
			<span class="custom-font-open-14-400-gray">
				Vale lembrar que:
			</span>					
		</b-row>
		<b-row class="mt-3">
			<span class="custom-font-open-14-400-gray">
				&#8226; Um atendimento fechado sairá da caixa que estiver (seja do usuário, seja do departamento);					
			</span>					
		</b-row>	
		<b-row>
			<span class="custom-font-open-14-400-gray">						
				&#8226; Quando o atendimento estiver fechado, sua equipe só poderá enviar templates para o contato;				
			</span>					
		</b-row>	
		<b-row>
			<span class="custom-font-open-14-400-gray">						
				&#8226; O sistema fechará a sessão do atendimento. Logo, o mesmo pode ser aberto sem custo caso a <u @click="openHelpSite('https://becon.com.br/conversas-e-sessoes-no-whatsapp/')" style="color: var(--primary); cursor: pointer;">conversa ainda esteja dentro das 24 horas;</u>						
			</span>					
		</b-row>
		<b-row>
			<span class="custom-font-open-14-400-gray">
				&#8226; Mensagens livres só poderão ser enviadas quando o contato responder um template, reabrindo assim uma sessão.					
			</span>					
		</b-row>													          
	</div>                                          
</template>


<script>

export default {
	name: 'WKeywordsActionClose',  
	props: ['keywordId'],    	
	data () {
		return { 

		}
	},
  
	methods: {
		openHelpSite (site) {
			window.open(site, '_blank')
		},                    
	},
	mounted () {      
		let obj = {id: this.keywordId}
		this.$emit('sendMessageData', obj)                          
	}, 
}
</script>

<style scoped>

</style>

 
