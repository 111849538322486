<template>
	<div>
		<svg v-if="item[data.index].status" :class="`attendant-status-color-${item[data.index].status}`" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="4" cy="4" r="4" fill="`attendant-status-color-${item[data.index].status}`"/>
		</svg>
		<span class="ml-2 custom-body">{{ data.value}}</span>
	</div>
</template>

<script>
export default {
	name: 'TableCellDepartment',
	props: [
		'item',
		'data',
	],
	methods:{

	},
}

</script>

<style>

</style>