<template>
	<div> 
		<!--context  -->
		<div @click="goToContextMessage" class="context-message icon-clickable">
			<div @click="goToContextMessage" v-show="contextMessage.template == true"> 
				<div style="padding-right: 20px;" v-if="contextMessage.contextMessageBody.body != ''">
					<div class="message-template">					
						<header v-show="contextMessage.contextMessageBody.header != ''">
							<h5 style="color: white;">{{contextMessage.contextMessageBody.header}}</h5>
						</header>
						<article>
							<b-row v-html="replaceMulti(contextMessage.contextMessageBody.body)">
							</b-row>                            
						</article>
						<footer>
							<b-row>
								{{contextMessage.contextMessageBody.footer}}
							</b-row>
							<b-row class="d-flex justify-content-center">
								<span v-for="(b, b_index) in contextMessage.message.buttons" :key="b_index" v-show="contextMessage.message.buttons != ''" class="template-button mr-2">{{b}}</span>           
							</b-row>                    
						</footer>                 
					</div>
				</div>
			</div>
			<div @click="goToContextMessage" v-show="contextMessage.quickreply==true">
				<div style="padding-right: 20px;" v-if="contextMessage.contextMessageBody.body != ''">
					<div class="message-quickreply">
						<header v-show="contextMessage.contextMessageBody.header != '' && contextMessage.contextMessageBody.header != undefined">
							<h5 style="color: white;">{{contextMessage.contextMessageBody.header}}</h5>
						</header>
						<article>
							<b-row v-html="replaceMulti(contextMessage.contextMessageBody.body)">
							</b-row>                            
						</article>
						<footer v-show="contextMessage.message.footer != '' && contextMessage.contextMessageBody.footer != undefined">
							<b-row>
								{{contextMessage.contextMessageBody.footer}}
							</b-row>
							<b-row class="d-flex justify-content-center">
								<span v-for="(b, b_index) in contextMessage.contextMessageBody.buttons" :key="b_index" v-show="contextMessage.contextMessageBody.buttons != ''" class="template-button mr-2">{{b}}</span>           
							</b-row>                    
						</footer>                 
					</div>   
				</div>
			</div>
			<div v-if="(contextMessage.contextMediaType == 'video' || contextMessage.contextMediaType == 'image')  && (messageInfo.sendReceiveTime> 1663642800000 || messageInfo.sendRequestTime > 1663642800000) ">
				<div v-if="contextMessage.contextMediaType == 'video'" :style="`background:url(${contextMessage.contextMediaPath+'&thumbnail=1'})`" class="thumb-class">
					<div>
						<div style="position:absolute;top:100px;left:80px;">
							<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle opacity="0.5" cx="20" cy="20" r="20" fill="#414141"/>
								<path d="M15 10.8317C15 10.0405 15.8752 9.56266 16.5408 9.99049L29.6915 18.4445C30.3038 18.8382 30.3038 19.7333 29.6915 20.1269L16.5408 28.5809C15.8752 29.0088 15 28.5309 15 27.7398V10.8317Z" fill="white"/>
							</svg>		
						</div>	
						<div style="position:absolute;bottom:0;left:5px;">
							<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M13.9163 4.08325L9.83301 6.99992L13.9163 9.91659V4.08325Z" fill="white"/>
								<path d="M8.66634 2.91675H2.24967C1.60534 2.91675 1.08301 3.43908 1.08301 4.08341V9.91675C1.08301 10.5611 1.60534 11.0834 2.24967 11.0834H8.66634C9.31067 11.0834 9.83301 10.5611 9.83301 9.91675V4.08341C9.83301 3.43908 9.31067 2.91675 8.66634 2.91675Z" fill="white"/>
							</svg>
						</div>		
					<!-- <div style="position:absolute;bottom:0;right:5px;color:white">
						{{contextMessage.timeSending}}
					</div>	 -->
					</div>
				</div>
				<img v-if="contextMessage.contextMediaType == 'image'" style="max-height:300px;max-width:100%" :src="contextMessage.contextMediaPath+'&thumbnail=1'" alt="">
			</div>
			<div v-else-if="(contextMessage.contextMediaType == 'video' || contextMessage.contextMediaType == 'image')">
				<b-img v-if="contextMessage.contextMediaType == 'image'" :src="contextMessage.contextMediaPath" alt="imagem" class="responsive"></b-img>  
				<div v-else-if="contextMessage.contextMediaType == 'video'" class="icon-clickable">   
					<w-video :type="1" :time="contextMessage.timeSending" :name="contextMessage.mediaName" context/>		               
				</div>   
			</div>
			<audio v-else-if="contextMessage.contextMediaType == 'audio'" :src="contextMessage.contextMediaPath" controls>
				<source :src="contextMessage.contextMediaPath" type="audio/ogg">
			</audio>
			<audio v-else-if="contextMessage.contextMediaType == 'voice'" :src="contextMessage.contextMediaPath" controls>
				<source :src="contextMessage.contextMediaPath" type="audio/ogg">
			</audio>
			<div v-else-if="contextMessage.contextMediaType == 'others'">
				<svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M13 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V9L13 2Z" stroke="var(--secondary)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M13 2V9H20" stroke="var(--secondary)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
				</svg> 
				<span>{{contextMessage.contextMessageBody}}</span>
			</div>
			<div v-else-if="contextMessage.contextMediaType == 'text' && contextMessage.contextMessageBody != ''" class="icon-clickable"> 
				<div class="custom-font-open-14" style="white-space: pre-line;word-break: break-word;" v-html="replaceMulti(contextMessage.contextMessageBody)"></div>
			</div>  
		</div>  
		<!--context  -->
		<!-- message -->
		<!-- <div>
			
			<div v-if="(contextMessage.mediaType == 'video' || contextMessage.mediaType == 'image')  && (messageInfo.sendReceiveTime> 1663642800000 || messageInfo.sendRequestTime > 1663642800000) ">
				<div @click="openMediaView" v-if="contextMessage.mediaType == 'video'" :style="`background:url(${contextMessage.mediaPath+'&thumbnail=1'})`" class="thumb-class">
					<div>
						<div style="position:absolute;top:120px;left:80px;">
							<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle opacity="0.5" cx="20" cy="20" r="20" fill="#414141"/>
								<path d="M15 10.8317C15 10.0405 15.8752 9.56266 16.5408 9.99049L29.6915 18.4445C30.3038 18.8382 30.3038 19.7333 29.6915 20.1269L16.5408 28.5809C15.8752 29.0088 15 28.5309 15 27.7398V10.8317Z" fill="white"/>
							</svg>		
						</div>	
						<div style="position:absolute;bottom:0;left:5px;">
							<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M13.9163 4.08325L9.83301 6.99992L13.9163 9.91659V4.08325Z" fill="white"/>
								<path d="M8.66634 2.91675H2.24967C1.60534 2.91675 1.08301 3.43908 1.08301 4.08341V9.91675C1.08301 10.5611 1.60534 11.0834 2.24967 11.0834H8.66634C9.31067 11.0834 9.83301 10.5611 9.83301 9.91675V4.08341C9.83301 3.43908 9.31067 2.91675 8.66634 2.91675Z" fill="white"/>
							</svg>
						</div>		
					</div>
				</div>
				<img @click="openMediaView" v-else-if="contextMessage.mediaType == 'image'" style="height:300px;max-width:300px" :src="contextMessage.mediaPath+'&thumbnail=1'" alt="">
			</div>
			<div v-else-if="(contextMessage.mediaType == 'video' || contextMessage.mediaType == 'image')">
				<div @click="openMediaView" v-if="contextMessage.mediaType == 'video'" style="background-color:grey" class="thumb-class">
					<div>
						<div style="position:absolute;top:120px;left:80px;">
							<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle opacity="0.5" cx="20" cy="20" r="20" fill="#414141"/>
								<path d="M15 10.8317C15 10.0405 15.8752 9.56266 16.5408 9.99049L29.6915 18.4445C30.3038 18.8382 30.3038 19.7333 29.6915 20.1269L16.5408 28.5809C15.8752 29.0088 15 28.5309 15 27.7398V10.8317Z" fill="white"/>
							</svg>		
						</div>	
						<div style="position:absolute;bottom:0;left:5px;">
							<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M13.9163 4.08325L9.83301 6.99992L13.9163 9.91659V4.08325Z" fill="white"/>
								<path d="M8.66634 2.91675H2.24967C1.60534 2.91675 1.08301 3.43908 1.08301 4.08341V9.91675C1.08301 10.5611 1.60534 11.0834 2.24967 11.0834H8.66634C9.31067 11.0834 9.83301 10.5611 9.83301 9.91675V4.08341C9.83301 3.43908 9.31067 2.91675 8.66634 2.91675Z" fill="white"/>
							</svg>
						</div>		
					</div>
				</div>
				<img @click="openMediaView" v-else-if="contextMessage.mediaType == 'image'" style="height:300px;max-width:300px" :src="contextMessage.mediaPath" alt="">
			</div>
			<audio v-else-if="contextMessage.mediaType == 'voice'" :src="contextMessage.mediaPath" controls>
				<source :src="contextMessage.mediaPath" type="audio/ogg">
			</audio> 
			<audio v-else-if="contextMessage.mediaType == 'audio'" :src="contextMessage.mediaPath" controls>
				<source :src="contextMessage.mediaPath" type="audio/ogg">
			</audio> 
			<div v-else-if="contextMessage.mediaType == 'others' && contextMessage.mediaName != ''" @click="download(contextMessage.mediaName, contextMessage.mediaPath)" style="display:flex;">
				<img src="@/assets/file.png" height="25">                        
				<span class="ml-2 mt-1" 
					style="font-family: Roboto; font-style: normal; font-weight: normal; font-size: 14px;line-height: 19px;">
					{{contextMessage.message}}</span>        
				<img class="icon-clickable" style="margin-left: auto;order: 2;" src="@/assets/download.png">
			</div>
			<div class="custom-font-open-14" v-if="contextMessage.hasURL == true" style="white-space: pre-line; word-break: break-word;" v-html="replaceMulti(contextMessage.message)" v-linkified:options="{ className: 'b' }"></div>
			<div class="custom-font-open-14" v-else-if="contextMessage.mediaType != 'others'" style="white-space: pre-line;word-break: break-word;" v-html="replaceMulti(contextMessage.message)"></div>    
			<b-row>
				<b-col v-if="contextMessage.mediaSize != 0" class="d-flex justify-content-start ml-3">
					<span class="dateReceived" v-show="contextMessage.mediaSize > 0">{{bytesToSize(contextMessage.mediaSize )}}</span>
				</b-col>
				<b-col class="d-flex justify-content-end mr-2">
					<span class="dateReceived">
						{{contextMessage.timeSending}}                                                       
					</span>
				</b-col>
			</b-row>                                                              
		</div> -->
		<!-- <span class="dateReceived">
				{{ contextMessage.timeSending}}                                                                                         
			</span>   
			<span class="dateReceived" v-show="contextMessage.mediaSize > 0 && contextMessage.quickreply ==false">{{bytesToSize(contextMessage.mediaSize )}}</span> -->
		<!-- message -->

		<!-- old -->
		<!-- <div>
				<div class="context-message" v-if="contextMessage.contextMediaType == 'text' || contextMessage.mediaType == 'text'">
					<div class="custom-font-open-14" v-if="contextMessage.hasURL == true && contextMessage.contextMessageBody != ''" style="white-space: pre-line; word-break: break-word;" v-html="replaceMulti(contextMessage.contextMessageBody)" v-linkified:options="{ className: 'b' }"></div>
					<div class="custom-font-open-14" v-else-if="contextMessage.contextMessageBody != ''" style="white-space: pre-line;word-break: break-word;" v-html="replaceMulti(contextMessage.contextMessageBody)"></div>                                                                        
				</div>    
				<b-row>
					<b-col class="d-flex justify-content-start ml-3">
						<span class="dateReceived" v-show="contextMessage.mediaSize > 0 && contextMessage.quickreply ==false">{{bytesToSize(contextMessage.mediaSize )}}</span>
					</b-col>
					<b-col class="d-flex justify-content-end mr-2">
						<span class="dateReceived" v-if="contextMessage.status=='received' && contextMessage.contextMessageId == ''">
							{{contextMessage.timeSending}}                                                       
							<span v-show="contextMessage.tick == 0"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" fill="none"><g id="clock"><path id="Vector" d="M7 5V8L9 9" stroke="#999999" stroke-linecap="round" stroke-linejoin="round"/><path id="Rectangle 93" d="M2.5 6C2.5 4.067 4.067 2.5 6 2.5H7.5H9C10.933 2.5 12.5 4.067 12.5 6V9C12.5 10.933 10.933 12.5 9 12.5H6C4.067 12.5 2.5 10.933 2.5 9V6Z" stroke="#999999"/></g></svg></span>
							<span v-show="contextMessage.tick == 1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck" x="2047" y="2061"><path d="M15.01 3.316l .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#92a58c"/></svg></span>                               
							<span v-show="contextMessage.tick == 2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck" x="2047" y="2061"><path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#92a58c"/></svg></span>                                    
							<span v-show="contextMessage.tick == 3"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck-ack" x="2063" y="2076"><path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#4fc3f7"/></svg></span> 
						</span>
					</b-col>
				</b-row>               
			</div>   -->
		<!-- image -->
		<!-- <div v-if="contextMessage.mediaType == 'text' && contextMessage.contextMediaType == 'image'" :class=" `message-file ${contextMessage.status}`">
				<b-img-lazy :src="contextMessage.mediaPath" alt="imagem" class="responsive"></b-img-lazy>      
				<div class="custom-font-open-14" v-if="contextMessage.hasURL == true" style="white-space: pre-line; word-break: break-word;" v-html="replaceMulti(contextMessage.message)" v-linkified:options="{ className: 'b' }"></div>
				<div class="custom-font-open-14" v-else style="white-space: pre-line;word-break: break-word;" v-html="replaceMulti(contextMessage.message)"></div>                                                               
			</div>   -->
		<!-- audio and image -->
		<!-- <div v-else-if="contextMessage.mediaType == 'audio' && contextMessage.contextMediaType == 'image'" :class=" `message-file ${contextMessage.status}`">                                                       
				<div class="context-message">
					<audio controls>
						<source :src="contextMessage.contextMediaPath" type="audio/ogg">
					</audio>                   
				</div>
				<div>
					<b-img :src="contextMessage.mediaPath" alt="imagem" class="responsive"></b-img>      
					<div class="custom-font-open-14" v-if="contextMessage.hasURL == true" style="white-space: pre-line; word-break: break-word;" v-html="replaceMulti(contextMessage.message)" v-linkified:options="{ className: 'b' }"></div>
					<div class="custom-font-open-14" v-else style="white-space: pre-line;word-break: break-word;" v-html="replaceMulti(contextMessage.message)"></div> 
				</div>     
			</div>          -->
		<!-- audio -->
		<!-- <div v-else>          
				<div v-show="contextMessage.contextMediaPath != ''">        
					<div v-show="contextMessage.mediaType == 'audio' || contextMessage.mediaType == 'voice'" :class=" `message-file ${contextMessage.status}`" class="context-message">                
						<audio controls>
							<source :src="contextMessage.contextMediaPath" type="audio/ogg">
						</audio>                     								
					</div>   
				</div>          
				<div v-show="contextMessage.contextMediaType == 'audio' || contextMessage.contextMediaType == 'voice'"        
					:class=" `message-file ${contextMessage.status}`">
					<audio controls>
						<source :src="contextMessage.mediaPath" type="audio/ogg">
					</audio>                     
				</div>  
			</div> -->
        
		<!-- video -->
		<!-- <div v-show="contextMessage.contextMediaType == 'video'" class="icon-clickable" @click="openMediaView">      
				<w-video :type="1" :time="contextMessage.timeSending" :name="contextMessage.mediaName" context/>		               
				<div class="custom-font-open-14" v-if="contextMessage.hasURL == true" style="white-space: pre-line; word-break: break-word;" v-html="replaceMulti(contextMessage.message)" v-linkified:options="{ className: 'b' }"></div>
				<div class="custom-font-open-14" v-else style="white-space: pre-line;word-break: break-word;" v-html="replaceMulti(contextMessage.message)"></div>                                    
			</div>             
			<div v-show="contextMessage.contextMediaType == 'others' || contextMessage.contextMediaType == 'pdf'">
				<b-row v-if="contextMessage.quickreply==true" :class=" `message-file-quickreply ${contextMessage.status}`" @click="download(contextMessage.mediaName, contextMessage.mediaPath)">
					<b-col class="d-flex justify-content-start ml-2">                                                                    
						<span>
							<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M17.333 2.6665H7.99967C7.29243 2.6665 6.61415 2.94746 6.11406 3.44755C5.61396 3.94765 5.33301 4.62593 5.33301 5.33317V26.6665C5.33301 27.3737 5.61396 28.052 6.11406 28.5521C6.61415 29.0522 7.29243 29.3332 7.99967 29.3332H23.9997C24.7069 29.3332 25.3852 29.0522 25.8853 28.5521C26.3854 28.052 26.6663 27.3737 26.6663 26.6665V11.9998L17.333 2.6665Z" fill="white"/>
								<path d="M17.333 2.6665V11.9998H26.6663" fill="#F5F5F5"/>
							</svg>                              
						</span> 
						<span class="ml-2 mt-1" 
							style="font-family: Roboto; font-style: normal; font-weight: normal; font-size: 14px;line-height: 19px;">
							{{contextMessage.mediaName}}</span>        
						<span class="dateReceived ml-2 mt-2" style="color: white;" v-show="contextMessage.mediaSize > 0">{{bytesToSize(contextMessage.mediaSize )}}</span>                                                              
					</b-col> 
					<b-col md="1" class="d-flex justify-content-end">
						<span class="mt-1">
							<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M16.0003 29.3332C23.3641 29.3332 29.3337 23.3636 29.3337 15.9998C29.3337 8.63604 23.3641 2.6665 16.0003 2.6665C8.63653 2.6665 2.66699 8.63604 2.66699 15.9998C2.66699 23.3636 8.63653 29.3332 16.0003 29.3332Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M10.667 16L16.0003 21.3333L21.3337 16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M16 10.6665V21.3332" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>                                
						</span>                                                
					</b-col>   
				</b-row>      
				<b-row v-else :class=" `message-file ${contextMessage.status}`" @click="download(contextMessage.mediaName, contextMessage.mediaPath)">
					<b-col class="d-flex justify-content-start">
						<span class="ml-2"><img src="@/assets/file.png" height="25"></span> 
						<span class="ml-2 mt-1" 
							style="font-family: Roboto; font-style: normal; font-weight: normal; font-size: 14px;line-height: 19px;">
							{{contextMessage.mediaName}}</span>                            
					</b-col> 
					<b-col md="1" class="d-flex justify-content-end">                         
						<span><img src="@/assets/download.png" height="25"></span>
					</b-col>                                         
				</b-row>                                                            
			</div>  
      -->
		<message-media-view v-if="showMediaView==true" :key="componenteMediaView" 
			:mediaId="contextMessage.mediaId"
			:mediaName="contextMessage.mediaName"
			:mediaPath="contextMessage.mediaPath" 
			:mediaType="contextMessage.mediaType"
			:mimeType="contextMessage.mimeType"
			:status="contextMessage.status"/>						            
	</div>                                          
</template>
<script>
import WMessageMediaView from './WMessageMediaView'
import WVideo from './media/WVideo'
export default {
	name: 'WChatContextMedia',
	components: {
		'w-video': WVideo,
		'message-media-view': WMessageMediaView,
	},  	
	props: [
		'contextMessage',
		'messageInfo'
	],
	data () {
		return {     
			show: true,
			showMediaView: false,
			componenteMediaView: 0,  			          
		}
	},    
	methods: {    
		goToContextMessage () {
			this.$emit('goToContextMessage')
		}, 
		bytesToSize (bytes) {
			let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
			if (bytes == 0) 
				return '0 Byte'
			let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
			return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
		},  
		replaceMulti (haystack) {
			try {
				if (typeof haystack === 'string' || haystack instanceof String) {				
					if (haystack != undefined){
						return haystack.split('\\n').join('<br>')
					}   
				}                 
			} catch (error){
				console.log(error)
			}
		},    
		openMediaView () { 
			this.componenteMediaView ++
			this.showMediaView = true
		}, 		
		download (mediaName, mediaPath ) {
			let url = mediaPath
			let link = document.createElement('a')
			link.href = url
			link.setAttribute('download', mediaName)
			document.body.appendChild(link)
			link.click()        
		},                         
	},    

	mounted () {
	}, 

}

</script>
<style scoped>
    header {
        height: 30px;
        text-align: center;
        margin-bottom: 10px;
        padding: 20px;
    }
    article {
        margin-top: 10px;
        padding: 5px;
        text-align: center;
        padding: 20px;
    }
    footer {
        margin-top: 10px;
        height: auto;
        text-align: center; 
        padding: 10px;  
    }
    .default-message {
        width: 100%; 
        margin: 10px 10px 0px;
        padding: 10px; 
        display: flex;
        background-color: var(--secondary);
        height: auto; 
        min-height:20px;
        border-radius: 8px; 
        color: white; 
        align-items: center; 
        justify-content: center; 
        font-family: 'Roboto'; 
        font-size: 12px;  
    }  
    .dateReceived {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        color: #777777;
    }    
    .dateSent {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        color: #777777;
    }
    .a {
        display: flex;
    }          
    .a >>> .b {
        display: contents !important;
    }     
	.thumb-class{
		min-width: 200px;
		min-height: 250px;
		background-repeat: no-repeat !important;
		background-size: 100% 100% !important;
		position: relative !important;
		box-shadow: inset 0 -35px 20px -20px grey;
	}   
</style>