<template>
	<div>
		<b-dropdown id="dropdown-form" 
			:text="selected" v-b-tooltip.hover class="custom-font-open-12 mb-2 ml-1" :class="this.$mq != 'xs' ? 'my-dropdown' : 'my-dropdown-mobile'"
			menu-class="menu-style"
			:toggle-class="toggleClass"
			ref="dropdown"
			variant="ligth"
		>
			<template #button-content>
				<span> 
					<svg style="margin-top: -4px;" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M14.6 1H1L6.44 7.30667V11.6667L9.16 13V7.30667L14.6 1Z" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</span>	
				<span class="ml-1">{{selected}}</span>
			</template>
			<div class="search">  
				<b-input-group>
					<b-form-input 
						type="search"                     
						:placeholder="placeHolder"
						v-model="search"
						class="mt-1 ml-3"
						@update="clearFilter">
					</b-form-input>
					<b-input-group-prepend is-text class="mt-1">
						<b-icon icon="search"></b-icon>
					</b-input-group-prepend>
				</b-input-group>
			</div>
			<!-- <div class="filtered-list-scrollable scrollbar">
				<b-form-group>
					<b-form-checkbox-group
						id="search-checkbox"
						v-model="checkedItems"
						:options="departmentFilteredList"
						stacked
						name="search-checkbox"
						style="margin-left: 5%; word-break: break-word;">
					</b-form-checkbox-group>
				</b-form-group>
			</div> -->
			<div class="filtered-list-scrollable scrollbar mt-3">
				<div v-if="departmentFilter == true" >
					<span class="ml-3 custom-font-open-12-400" style="font-size:14px">Departamentos:</span>
					<div v-for="x in departmentFilteredList" :key="x.value">
						<label :for="x.value" class="ml-2">                            
							<b-form-checkbox
								class="ml-2"
								:id="x.value"
								checked="1"
								v-model="x.checked"
								unchecked-value="0"
								size="lg"
								style="margin-left: 5%; word-break: break-word;"
							>
								<div style="margin-top: 5px">
									<span class="custom-font-open-14-600">{{x.text}}</span>
								</div>
							</b-form-checkbox>                                           
						</label>               
					</div>
				</div>
				
				
				<div v-if="agentFilter">
					<hr v-if="departmentFilter == true">
					<span class="ml-3 custom-font-open-12-400" style="font-size:14px">Usuários:</span>
					<div v-for="x in agentFilteredList" :key="x.value" >
						<label :for="x.value" class="ml-2">                            
							<b-form-checkbox
								class="ml-2"
								:id="x.value"
								checked="1"
								v-model="x.checked"
								unchecked-value="0"
								size="lg"
								style="margin-left: 5%; word-break: break-word;"
							>
								<div style="margin-top: 5px">
									<span class="custom-font-open-14-600">{{x.text}}</span>
								</div>
							</b-form-checkbox>                                           
						</label>               
					</div>
				</div>
				
			</div> 
			<b-row class="justify-content-center mt-2">
				<b-button id="advancedFilter" variant="primary"
					@click="apply()" style="background-color: var(--primary); border-color: var(--primary); width: 80%, margin-left: 10%">
					<span class="ml-1">
						Aplicar
					</span>                        
				</b-button>
			</b-row>
		</b-dropdown>
		<b-col v-if="departmentFilter" id="checked-items" class="mt-1">
			<b-row :style="((this.scrollTag && checkedItems.length >0) ? 'width:745px':'width:60vw')" :class="((this.scrollTag && checkedItems.length >0) ? 'scroll-tag scrollbar':'')">
				<span class="ml-3 mt-1" v-if="agentFilter && checkedItems.departmentList.length >0">Departamentos:</span>
				<div class="mb-2" v-for="x in checkedItems.departmentList" :key="x.value">
					<div class="filter-tag">
						<span>{{x.text}}</span>
						<span  style="z-index: 1;position:relative; cursor:pointer; font-size:13px" @click="removetFilter(x)" class="ml-2">X</span>
					</div>
					
				</div>
			</b-row>
		</b-col>
		<b-col v-if="agentFilter" id="checked-items2" class="mb-2" style="padding-top:0px">
			<hr v-if="departmentFilter && checkedItems.departmentList.length >0 && checkedItems.agentList.length > 0">
			<b-row class="" :style="((this.scrollTag && checkedItems.length >0) ? 'width:745px':'width:60vw')" :class="((this.scrollTag && checkedItems.length >0) ? 'scroll-tag scrollbar':'')">
				<span class="ml-3 mt-1" v-if="departmentFilter && checkedItems.agentList.length >0">Usuários:</span>
				<div class="mb-2" v-for="x in checkedItems.agentList" :key="x.value">
					<div class="filter-tag">
						<span>{{x.text}}</span>
						<span  style="z-index: 1;position:relative; cursor:pointer; font-size:13px" @click="removetFilter(x)" class="ml-2">X</span>
					</div>
					
				</div>
			</b-row>
		</b-col>
	</div>
</template>


<script>
export default {
	name: 'SearchCheckBox',
	props: [
		
		'departmentFilter',
		'agentFilter',
		'listTittle',
		'list2Tittle',
		'sortBy',
		'scrollTag',
		'placeHolder',
		'toggleClass',
		'splitReturn'
	],
	data () {
		return {
			checkedItems: {departmentList:[], agentList:[]},
			checkedItemstoShow:[],
			search: '',
			selected: 'Filtro',
			departmentList: [],
			agentList: [],

		}
	},
	computed: {
		departmentFilteredList () {
			let teste = this.departmentOrderedList			
			return teste.filter(item => {
				return item[this.sortBy].toLowerCase().includes(this.search.toLowerCase())
			})
		},
		departmentOrderedList () {
			const items = this.departmentList		
				
			if(items){
				items.sort(this.dynamicSort([this.sortBy]))	
			}										
			return items
		},  
		agentFilteredList () {
			let teste = this.agentOrderedList			
			return teste.filter(item => {
				return item[this.sortBy].toLowerCase().includes(this.search.toLowerCase())
			})
		},
		agentOrderedList () {
			const items = this.agentList
			if(items){
				items.sort(this.dynamicSort([this.sortBy]))		
			}	
													
			return items
		},
	},
	methods: {
		removetFilter (item) {
			item.checked = false
			this.apply()
		},
		getCheckedItemsId () {
			let aux = []
			if(this.splitReturn == true){
				aux = {departmentIdLIst:[], agentIdList:[]}
				if(this.departmentFilter){
					this.checkedItems.departmentList.forEach(element => {
						aux.departmentIdLIst.push(element.value)
					})
				}
				if(this.agentFilter){
					this.checkedItems.agentList.forEach(element => {
						aux.agentIdList.push(element.value)
					})
				}
			}else{
				if(this.departmentFilter){
					this.checkedItems.departmentList.forEach(element => {
						aux.push(element.value)
					})
				}
				if(this.agentFilter){
					this.checkedItems.agentList.forEach(element => {
						aux.push(element.value)
					})
				}
			}
			
			
			
			return aux
		},
		async apply () {
			if(this.search != ''){
				this.search = ''
				this.apply()
				return
			}
			if(this.departmentFilter){
				this.checkedItems.departmentList = this.departmentFilteredList.filter(element =>{
					if(element.checked == true){
						return true
					}else{
						return false
					}
				})
			}
			if(this.agentFilter){
				this.checkedItems.agentList = this.agentFilteredList.filter(element =>{
					if(element.checked == true){
						return true
					}else{
						return false
					}
				})
			}
			// --------------antigo
			
			this.$emit('checkedItems', this.getCheckedItemsId())
			this.$refs.dropdown.hide(true)
			setTimeout(() => {
				document.activeElement.blur()
			}, 100)
		},
		dynamicSort (fields) {
			return function (a, b) {
				return fields
					.map(function (o) {
						let dir = 1
						if (o[0] === '-') {
							dir = -1
							o=o.substring(1)
						}
						if (a[o].toLowerCase() > b[o].toLowerCase()) 
							return dir
						if (a[o].toLowerCase() < b[o].toLowerCase()) 
							return -(dir)
						return 0
					})
					.reduce(function firstNonZeroValue (p,n) {
						return p ? p : n
					}, 0)
			}
		},
		clearFilter () {
			if(this.search == ''){
				this.agentFilteredList()
			}
		}    
		
	},
	async mounted () {
		if(this.departmentFilter){
			let departmentList = []
			await this.$store.dispatch('getDepartmentList')
			this.departmentList = this.$store.getters.getDepartmentList
			this.departmentList.forEach(item => {
				let dpt = {
					text: item.departmentName,
					value: item.departmentId,
					checked: false
				}
				departmentList.push(dpt)
			})
			console.log('populei departmentlist')
			console.log(departmentList)
			this.departmentList = departmentList
		}
		if(this.agentFilter){
			await this.$store.dispatch('getAgentList')
			this.agentList = this.$store.getters.getAgentList
			let agentList = []
			this.agentList.forEach(item => {
				let dpt = {
					text: item.name,
					value: item.userId,
					checked: false
				}
				agentList.push(dpt)
			})
			this.agentList = agentList
		}
	}
}
</script>

<style lang="scss">
	.filtered-list-scrollable {
		height: 30vh;	
		width: 100%;
		background: #fff;
		overflow-y: scroll;
		overflow-x: hidden;
	}
	.menu-style{
		width: 25vw;
		// height: 40vh;
	}
	.custom-checkbox-toggle{
		display: inline-block;
		background-color: white !important;
		color: #999999!important;
		border-color: #999999!important;
	}
	.scroll-tag{
		height: 10vh;
		overflow-x: hidden;
		overflow-y: scroll;
	}
</style>