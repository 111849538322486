<template>
	<div>
		<b-col md="12">
			<b-row>
				<b-modal 
					v-model="show"
					:title="title"
					centered
					scrollable>
					<b-col v-show="step == 1">
						<b-row class="ml-2">
							<div>
								<b-form-checkbox v-model="checked" name="check-button" switch>
									Departamento padrão do sistema:  {{agentOrDepartmentLeadDescription}}
								</b-form-checkbox>
							</div>                    
						</b-row>
						<div v-show="checked == false">
							<b-row class="ml-2 mt-3">
								<b>Atribuir outro responsável</b>
							</b-row>
							<b-row class="ml-2">
								<span class="custom-font-open-14">Escolha um dos departamentos abaixo para continuar</span>                    
							</b-row>
							<b-row class="ml-2 mr-2 mt-3">
								<b-input-group>
									<b-form-input 
										type="search"                     
										:placeholder="placeholder"
										v-model="filter"
										@keyup="searchDepartment" 
										class="mt-1"
										@update="clearFilter('departament')">
									</b-form-input>              
									<b-input-group-prepend is-text class="mt-1">
										<b-icon icon="search"></b-icon>
									</b-input-group-prepend>                    
								</b-input-group> 
							</b-row>
							<b-container fluid class="mt-3">           
								<div v-for="(x, index) in departments" :key="index" v-show="x.visible == true" :class="[ '', index == currentDepartmentIndex ? 'template-active' : '' ]">
									<b-col class="m-1 icon-clickable" @click="setDepartmentId(index, x.departmentId, x.phonenumberId, x.departmentName)">
										<b-row class="ml-1">
											<label :for="x.departmentId" @change="setDepartmentId(index, x.departmentId, x.phonenumberId, x.departmentName)">   
												<b-form-radio
													:id="x.departmentId"
													v-model="departmentId"
													:value="x.departmentId"          
													unchecked-value="0"
												>
													<span class="mylabel">{{x.departmentName}}</span> 
												</b-form-radio>    
											</label>                             

										</b-row>                     
									</b-col>
								</div>                                            
							</b-container>                    
						</div>                

					</b-col>
            
					<b-col v-show="step == 2">
						<b-row class="ml-2 mb-2">
							<div>
								<b-form-checkbox v-model="checked2" name="check-button" switch>
									Manter o departamento: {{departmentSelected}}
								</b-form-checkbox>
							</div>                    
						</b-row>
						<div v-show="checked2 == false">
							<b-row class="ml-2 mt-2">
								<span class="custom-font-open-14">Ou escolher usuário dentro desse departamento</span>                    
							</b-row>
							<b-row class="ml-2 mr-2 mt-2">   
								<b-input-group>
									<b-form-input 
										type="search"                     
										:placeholder="placeholder"
										v-model="filterAgents"
										@keyup="searchAgents" 
										class="mt-1"
										@update="clearFilter('agents')">
									</b-form-input>                  
									<b-input-group-prepend is-text class="mt-1">
										<b-icon icon="search"></b-icon>
									</b-input-group-prepend>                    
								</b-input-group> 
							</b-row>
							<b-container fluid class="mt-3">           
								<div v-for="(x, index) in agents" :key="index" v-show="x.visible == true" :class="[ '', index == currentIndex ? 'template-active' : '' ]">
									<b-col class="m-1 icon-clickable" @click="setAgentId(index, x.agentId)">
										<b-row class="ml-1">
											<label :for="x.agentId" @change="setAgentId(index, x.agentId)">   
												<b-form-radio
													:id="x.agentId"
													v-model="agentId"
													:value="x.agentId"

													unchecked-value="0"
												>
													<span class="ml-2">
														<b-avatar :src="avtUrl + companyId + '&imageId='+x.agentId" size="1.4em"></b-avatar>
													</span>
													<span class="mylabel ml-2">{{x.name}}</span> 
												</b-form-radio>    
											</label>                             

										</b-row>                     
									</b-col>
								</div>                                            
							</b-container>
						</div>

					</b-col>            

					<template #modal-footer>
						<div class="w-100">
							<b-button v-show="step != 2" variant="primary" class="float-right" @click="nextStep()">
								Avançar
							</b-button>
							<b-button variant="outline-primary" class="float-left" @click="goBack()">
								Voltar
							</b-button>  
							<b-button v-show="step == 2" variant="primary" class="float-right" @click="save()"> 
								Salvar
							</b-button>                                                   
						</div>
					</template>
				</b-modal>    
				<div class="w-100">
					<span @click="openContactResponsible" class="icon-clickable">
						<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g filter="url(#filter0_d)">
								<rect x="6" y="5" width="24" height="24" rx="4" fill="white"/>
								<path d="M21 11.4142C21.2652 11.149 21.6249 11 22 11C22.1857 11 22.3696 11.0366 22.5412 11.1077C22.7128 11.1787 22.8687 11.2829 23 11.4142C23.1313 11.5455 23.2355 11.7014 23.3066 11.873C23.3776 12.0446 23.4142 12.2285 23.4142 12.4142C23.4142 12.5999 23.3776 12.7838 23.3066 12.9554C23.2355 13.127 23.1313 13.2829 23 13.4142L14.6667 21.7475L12 22.4142L12.6667 19.7475L21 11.4142Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							</g>
							<defs>
								<filter id="filter0_d" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix"/>
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
									<feOffset dy="1"/>
									<feGaussianBlur stdDeviation="3"/>
									<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
								</filter>
							</defs>
						</svg>
					</span>                                
				</div>                                                  
			</b-row>                       
		</b-col>              
	</div>                                          
</template>

<script>
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import WPhoneNumberAPI from '@/api/WPhoneNumberAPI'
import { avatarUrl} from '@/global'
export default {
	name: 'WContactResponsible',
	props: ['contactId', 'waId', 'phonenumberId', 'agentOrDepartmentLead', 'agentOrDepartmentLeadDescription'],
	data () {
		return { 
			show: false,  
			step: 1,
			checked: false,
			checked2: false,
			title: 'Responsável por este contato',
			placeholder: 'Buscar departamento',
			filter: '',
			filterAgents: '',
			selected: '',
			departmentId: null,
			departments: [],
			agentId: '',
			agents: [],
			currentIndex: null,
			currentDepartmentIndex: null, 
			avtUrl: avatarUrl,
			companyId: this.$route.params.companyId,
			departmentSelected: this.agentOrDepartmentLeadDescription                   
		}
	},    
	methods: {
		openContactResponsible () {
			this.show=true
			this.getDepartmentList ()
		},
		setDepartmentId (index, id, phoneId, name) {
			this.departmentId = id
			this.phonenumberId = phoneId      
			this.departmentSelected = name      
			this.currentDepartmentIndex = index                                         
		},
		setAgentId (index, id) {
			this.agentId = id                
			this.currentIndex = index
		},
		getDepartmentList () {
			WDepartmentsAPI.getDepartmentsOfUser(this.$route.params.companyId, response => {
				let departments = response.data
				departments.forEach(dep => {                    
					dep.visible = true
				})
				this.departments = departments
			})
		},       
		getUsersOfDepartment (departmentId) {
			WDepartmentsAPI.getUsersOfDepartment(this.$route.params.companyId, departmentId, response => {
				let agents = response.data
				let list = []
				for (const key in agents){                    
					list.push({agentId: key, name: agents[key], visible: true})
				}
				this.agents = list
			})
		},  
		searchDepartment () {
			this.departments.forEach( el => {
				if ( el.departmentName.toLowerCase().includes(this.filter.toLowerCase()) ){
					el.visible = true
				} else {
					el.visible = false
				}
			})        
		},  
		searchAgents () {
			this.agents.forEach( el => {
				if ( el.name.toLowerCase().includes(this.filterAgents.toLowerCase()) ){
					el.visible = true
				} else {
					el.visible = false
				}
			})        
		},      
		nextStep () { 
			if (this.departmentId == null && this.checked == false){
				alert('Pelo menos um item deve ser selecionado!')
				return
			}
			if (this.checked){
				WPhoneNumberAPI.postAssignLeadIsSystem(this.$route.params.companyId, this.phonenumberId, this.waId,response => {
					if (response){ 
						let obj = {file: null, mediaType: 'assign', msg: 'lead', visible: false}
						this.$emit('sendMessageData', obj) 
						this.show =false                                                            
					} else {
						alert('Falha ao trocar o departamento!')
					}
				})                   
			} else {
				this.step++
				if (this.step == 2){
					this.title = 'Responsável por esse contato'
					this.placeholder = 'Buscar usuário' 
					if (this.checked){
						this.getUsersOfDepartment(this.agentOrDepartmentLead)
					} else {
						this.getUsersOfDepartment(this.departmentId) 
					}                                                                           
				}                
			}
		},  
		save () {
			let agentId = this.agentId
			if (this.checked2){
				agentId =  this.departmentId 
			} 
			if (agentId == '' && this.checked2 == false){
				alert('Pelo menos um item deve ser selecionado!')
				return
			}            
			WPhoneNumberAPI.postAssignLead(this.$route.params.companyId, this.phonenumberId, this.waId, agentId, response => {
				if (response){ 
					let obj = {file: null, mediaType: 'assign', msg: 'lead', visible: false}
					this.$emit('sendMessageData', obj) 
					this.show =false                                                            
				} else {
					alert('Falha ao trocar o usuário!')
				}
			})                                  
		},
		goBack () { 
			this.step--   
			if (this.step == 0){
				this.show = false
			}      
			if (this.step == 1){
				this.title = 'Responsável por esse contato'
				this.placeholder = 'Buscar departamento'                                 
			}               
			if (this.step == 2){
				this.title = 'Responsável por esse contato'
				this.placeholder = 'Buscar usuário'                                 
			}
		},
		clearFilter (type) {
			if(this.filter == '' && type == 'departament'){
				this.searchDepartment()
			} else if(this.filterAgents == '' && type == 'agents'){
				this.searchAgents()
			}
		}                           
	},
	mounted () {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			console.log('Modal is about to be shown', bvEvent, modalId)
			this.show = false  
			this.step = 1
			this.title = 'Responsável por esse contato'
			this.placeholder = 'Buscar departamento'
			this.filter = ''
			this.filterAgents = ''
			this.selected = ''
			this.departmentId = ''
			this.departments = []
			this.agents = []
			this.currentIndex = null
			this.currentDepartmentIndex = null
			this.checked = false
			this.checked2 = false
		})        
	}, 
}
</script>

<style scoped>
    .search input { 
        border: none !important;
        width: 50% !important;
    }    
    .btn-light {
        color: #212529;
        background-color:  #F5F5F5;
        border-color:  #F5F5F5;
    }  
    .mylabel {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #777777;
        cursor: pointer; 
    }
    .tempTitle {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: #777777;    
    }  
    .tempSubTitle {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #999999;            
    }
  
</style>

 
