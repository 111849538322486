export default{

	orderMyList (prop,list) {
		return list.sort(this.dynamicSort([prop]))
	},
	dynamicSort (fields) {
		return function (a, b) {
			return fields
				.map(function (o) {
					let dir = 1
					if (o[0] === '-') {
						dir = -1
						o=o.substring(1)
					}
					if (typeof a[o] === 'string' || a[o] instanceof String) {				
						if (a[o].toLowerCase() > b[o].toLowerCase()) 
							return dir
						if (a[o].toLowerCase() < b[o].toLowerCase()) 
							return -(dir)
					} else {
						if (a[o] > b[o]) 
							return dir
						if (a[o] < b[o]) 
							return -(dir)						
					}
					return 0
				})
				.reduce(function firstNonZeroValue (p,n) {
					return p ? p : n
				}, 0)
		}
	}
}
