<template>
	<div class="text-center" v-if="this.$store.state.loading ==true">
		<b-spinner class="m-5" style="width: 3rem; height: 3rem;" label="Large Spinner" variant="info"></b-spinner>
	</div>
</template>

<script>
export default {
	name: 'Loading',
}
</script>

<style>

</style>
