<template>
	<div class="mr-3">
		<b-row class="mt-3">
			<span class="select-custom-font">
				Selecione o período:
			</span>
		</b-row>
		<b-row>	
			<b-dropdown toggle-class="pannel-toggle-dropdown-style" id="departments" :text="selectedPediod" variant="ligth">            
				<b-dropdown-item @click="setPeriod(1)">Últimos 7 dias</b-dropdown-item>  				                  							
				<b-dropdown-item @click="setPeriod(2)">Esta semana</b-dropdown-item>                    							
				<b-dropdown-item @click="setPeriod(3)">Este mês</b-dropdown-item>
				<b-dropdown-item @click="setPeriod(4)">Mês passado</b-dropdown-item>				
				<!-- <b-dropdown-item @click="setPeriod(5)">Últimos 3 meses</b-dropdown-item> -->
				<b-dropdown-item @click="setPeriod(5)">Últimos 2 meses</b-dropdown-item>
				<b-dropdown-item @click="setPeriod(6)">Selecionar o período</b-dropdown-item>						
			</b-dropdown>   							
		</b-row>
	</div>
</template>
 
<script>

export default {
	name: 'WDashboardPeriod',
	data () {
		return {
			selectedPediod: 'Últimos 7 dias'
		}
	},
	methods: {
		setPeriod (type) {
			let periodObj = {}
			let startTime = 0
			let endTime = 0
			let currentData = new Date()
			if (type == 1) {
				this.selectedPediod = 'Últimos 7 dias'
				currentData.setHours(0,0,0,0)
				endTime = currentData.getTime()
				startTime = endTime - (7*86400000)
				// endTime = currentData.setHours(23,59,59,999)
				periodObj = {startTime: startTime, endTime: endTime, period: type}
				this.$emit('sendMessageData', periodObj)
			}
			if (type == 2) {		
				this.selectedPediod = 'Esta semana'	
				let dt = new Date()
				let currentWeekDay = dt.getDay()
				let lessDays = currentWeekDay == 0 ? 6 : currentWeekDay - 1
				let wkStart = new Date(new Date(dt).setDate(dt.getDate() - lessDays))				
				startTime = wkStart.setHours(0,0,0,0)
				endTime = new Date().setHours(23,59,59,999) 
				// console.log(new Date(endTime))
				periodObj = {startTime: startTime, endTime: endTime, period: type}
				this.$emit('sendMessageData', periodObj)	
			}
			if (type == 3) {
				this.selectedPediod = 'Este mês'	
				let firstDay = new Date(currentData.getFullYear(), currentData.getMonth(), 1)				
				startTime = firstDay.getTime()
				endTime = new Date(currentData.getFullYear(), currentData.getMonth() + 1, 0) 
				endTime.setHours(23,59,59,999)
				endTime = endTime.getTime()
				periodObj = {startTime: startTime, endTime: endTime, period: type}
				this.$emit('sendMessageData', periodObj)					
			}	
			if (type == 4) {
				this.selectedPediod = 'Mês passado'	
				let firstDay = new Date(currentData.getFullYear(), currentData.getMonth()-1, 1,0)
				let lastDay = new Date(currentData.getFullYear(), currentData.getMonth(), 0,23,59,59)
				startTime = firstDay.getTime()
				endTime = lastDay.getTime()
				periodObj = {startTime: startTime, endTime: endTime, period: type}
				this.$emit('sendMessageData', periodObj)					
			}	
						
			
			if (type == 5) {
				//this.selectedPediod = 'Últimos 3 meses'	
				this.selectedPediod = 'Últimos 2 meses'	
				currentData.setHours(0,0,0,0)
				endTime = currentData.getTime() 
				//startTime = endTime - 90*86400000
				startTime = endTime - 60*86400000
				endTime = currentData.setHours(23,59,59,999)
				periodObj = {startTime: startTime, endTime: endTime, period: type}
				this.$emit('sendMessageData', periodObj)					
			}
			if (type == 6) {
				this.selectedPediod = 'Selecionar o período'	
				this.$emit('sendMessageData', null)					
			}		
			// console.log('startTime: '+new Date(startTime))
			// console.log('endTime: '+ new Date(endTime))	
		}	
	}
}
</script>

<style scoped>

.select-custom-font {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #21065C;
}

</style>