import axios from 'axios'
import i18n from '@/plugins/i18n'
import router from '@/router'
import WLoginAPI from '@/api/WLoginAPI'
let firstTime = true
let refresh = 0
const success = res => res

const error = err => {
	console.log('erro axios')
	console.log(err.response)
	switch(err.response.status){
	case 401:
		if(refresh <2){
			WLoginAPI.resfreshLogin((response) => {
				let user = response.data
				let token = user.access_token
				let refreshToken = user.refresh_token
				localStorage.setItem('__wpp_access_token',token)  
				localStorage.setItem('__wpp_access_refresh_token',refreshToken)
			})  
		}else{
			if (firstTime){
				alert(i18n.t('Message.warning'))
				firstTime = false
				router.push({ path: '/'})  
			}
		}
		break
	default:
		return Promise.reject(err)
	}
}

axios.interceptors.response.use(success, error)
// axios.defaults.headers.common['development'] = true