<template>
	<div class="main">
		<div class="message-quickreply" v-if="error == true" style="padding:10px;height:200px">
			<span @click="reloadMidia" v-if="loading == false">
				<svg width="30" height="30" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g clip-path="url(#clip0_1309_415)">
						<path d="M9.91699 0.583984L12.2503 2.91732L9.91699 5.25065" stroke="var(--primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M1.75 6.41602V5.24935C1.75 4.63051 1.99583 4.03702 2.43342 3.59943C2.871 3.16185 3.46449 2.91602 4.08333 2.91602H12.25" stroke="var(--primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M4.08333 13.4167L1.75 11.0833L4.08333 8.75" stroke="var(--primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M12.25 7.58398V8.75065C12.25 9.36949 12.0042 9.96298 11.5666 10.4006C11.129 10.8382 10.5355 11.084 9.91667 11.084H1.75" stroke="var(--primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</g>
					<defs>
						<clipPath id="clip0_1309_415">
							<rect width="14" height="14" fill="white"/>
						</clipPath>
					</defs>
				</svg>
			</span>
			<b-spinner v-else-if="loading == true" style="width: 3rem; height: 3rem; color: var(--secondary)" label="Large Spinner"></b-spinner>
		</div>
		<div v-show="error == false" :key="updateMedia">  
			<b-spinner v-if="loading == true" style="width: 3rem; height: 3rem; color: var(--secondary)" label="Large Spinner"></b-spinner>   
			<div v-else>
				<div v-show="messageInfo.forwarded == true" style="margin-bottom:5px; margin-left: 5px;">
					<img src="@/assets/forwarded.png" height="20"> <span class="medium-font">Encaminhada</span>
				</div>
				<div v-show="messageInfo.forwardedFrequently == true" style="margin-bottom:5px">
					<img src="@/assets/forwardedFrequently.png" height="20"> <span class="medium-font">Encaminhada com frequência</span>
				</div>
				<!-- thumb -->
				<div class="thumbnail" v-if="messageInfo.thumbnail == true" @click="openMediaView" style="cursor:pointer">
					<div v-if="messageInfo.mediaType == 'video'" style="width:150px;height:250px" :style="`background:url(${messageInfo.thumbPath})`">
						<div>
							<div style="position:absolute;top:40%;left:40%;">
								<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle opacity="0.5" cx="20" cy="20" r="20" fill="#414141"/>
									<path d="M15 10.8317C15 10.0405 15.8752 9.56266 16.5408 9.99049L29.6915 18.4445C30.3038 18.8382 30.3038 19.7333 29.6915 20.1269L16.5408 28.5809C15.8752 29.0088 15 28.5309 15 27.7398V10.8317Z" fill="white"/>
								</svg>		
							</div>	
							<div style="position:absolute;bottom:11%;left:82%;">
								<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M13.9163 4.08325L9.83301 6.99992L13.9163 9.91659V4.08325Z" fill="white"/>
									<path d="M8.66634 2.91675H2.24967C1.60534 2.91675 1.08301 3.43908 1.08301 4.08341V9.91675C1.08301 10.5611 1.60534 11.0834 2.24967 11.0834H8.66634C9.31067 11.0834 9.83301 10.5611 9.83301 9.91675V4.08341C9.83301 3.43908 9.31067 2.91675 8.66634 2.91675Z" fill="white"/>
								</svg>
							</div>		
							<div style="position:absolute;bottom:11%;right:68%;color:white">
								{{messageInfo.timeSending}}
							</div>	
						</div>
					</div>
					<img style="max-height:300px;max-width:100%" v-else @error="mediaError" :src="messageInfo.thumbPath" alt="">
				</div>
				<!-- thumb -->
				<div v-else-if="(messageInfo.type == 'video' || messageInfo.type == 'image') && noThumb == false">
					<div style="background-color:grey;width:100%;display:flex;justify-content: center;align-items: center;" class="thumb-class">
						<b-spinner style="width: 4rem; height: 4rem; color: var(--secondary)" ></b-spinner>	
					</div>
				</div>
				<div v-else>
					<!-- no thumb image -->
					<div v-if="messageInfo.type == 'image'" :class=" `message-file ${messageInfo.status}`" @click="openMediaView">                   
						<img :id="messageInfo.mediaPath" @load="error = false, loading = false" @error="error = true" :src="messageInfo.mediaPath" alt="imagem" class="responsive">                  
					</div> 
					<!-- no thumb image -->
					<!-- no thumb video -->
					<div v-show="messageInfo.type == 'video'" class="icon-clickable" @click="openMediaView">                                            
						<w-video :type="1" :time="messageInfo.timeSending"/>
					</div> 
					<!-- no thumb video -->
					<!-- audio -->
					<div v-if="messageInfo.type == 'audio' || messageInfo.type == 'voice'" :class=" `message-file ${messageInfo.status}`">                  		
						<audio controls>
							<source @error="mediaError" :src="messageInfo.mediaPath" type="audio/ogg">
						</audio>  			
					</div>   
					<!-- video in quick reply-->  
					<div v-show="messageInfo.type == 'video' && messageInfo.quickreply==true" :class=" `message-file-quickreply ${messageInfo.status}`" @click="openMediaView">                     
						<w-video :type="2" :time="messageInfo.timeSending"/>      
						<article v-show="messageInfo.message.body != ''" style="margin-top: 0;">
							<b-row v-html="replaceMulti(messageInfo.message.body)">
							</b-row>                            
						</article>                           
					</div>  
				</div>
				<!-- </div> -->
			</div>
			<message-media-view v-if="showMediaView==true" :key="componenteMediaView" 
				:mediaId="messageInfo.mediaId"
				:mediaName="messageInfo.mediaName"
				:mediaPath="messageInfo.mediaPath" 
				:mediaType="messageInfo.type"
				:mimeType="messageInfo.mimeType"
				:status="messageInfo.status"/>		        
		</div>   
	</div>                                       
</template>
<script>
import WMessageMediaView from './WMessageMediaView'
import WVideo from './media/WVideo'
export default {
	name: 'WMessageMedia',
	components: {
		'message-media-view': WMessageMediaView,
		'w-video': WVideo,
	},  
	props: [
		'messageInfo',
		'noThumb'
	],
	data () {
		return {
			showMediaView: false,
			componenteMediaView: 0,  
			messageError: '',
			showPopMessageError: false,
			show: true,    
			currentTime: '00:00',
			reloaded:false,  
			updateMedia:0,
			error:false,
			loading:false,
			cache:undefined
		}
	},    
	computed:{
		aux () {
			console.log(this.cache)
			return this.cache
		}

	},
	methods: { 
		mediaError () {
			this.$emit('reload')
		},
		reloadMidia () {
			this.loading = true
			this.updateMedia++
			setTimeout(() => {
				this.updateMedia++
				setTimeout(() => {
					if(this.error == true){
						this,this.updateMedia++
						this.loading = false
					}
				}, 5000)
			}, 5000)
		},
		teste () {
			console.log(this.cache)
		},
		teste2 () {
			console.log('loaded')
		},
		bytesToSize (bytes) {
			let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
			if (bytes == 0) 
				return '0 Byte'
			let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
			return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
		},
		replaceMulti (haystack) {
			if (typeof haystack === 'string' || haystack instanceof String) {
				if (haystack != undefined){
					return haystack.split('\\n').join('<br>')                
				}
			}
		},        
		openMediaView () { 
			this.componenteMediaView ++
			this.showMediaView = true
		},       
		playAudio () {
			this.$refs.audio.play()
		},  
		onTimeUpdateListener () {
			// Update current time
			this.currentTime = Math.floor(this.$refs.audio.currentTime)
			//this.currentTime = this.currentTime.toFixed(2)
		},			                         
	},    
	mounted () {
		if((this.messageInfo.type == 'video' || this.messageInfo.type == 'image') &&!this.messageInfo.thumbnail){
			this.$emit('noThumb')
		}else if((this.messageInfo.type == 'video' || this.messageInfo.type == 'image') && this.messageInfo.thumbnail == false){
			this.$emit('reload')
		}
	}, 
}
</script>

<style scoped>
	main audio{
	max-width:100%;
		
	}
    header {
        height: 30px;
        text-align: center;
        margin-bottom: 10px;
        padding: 20px;
    }
    article {
        margin-top: 10px;
        padding: 5px;
        text-align: center;
        padding: 20px;
    }
    footer {
        margin-top: 10px;
        height: auto;
        text-align: center; 
        padding: 10px;  
    }
    .default-message {
        width: 100%; 
        margin: 10px 10px 0px;
        padding: 10px; 
        display: flex;
        background-color: var(--secondary);
        height: auto; 
        min-height:20px;
        border-radius: 8px; 
        color: white; 
        align-items: center; 
        justify-content: center; 
        font-family: 'Roboto'; 
        font-size: 12px;  
    }  
    .dateReceived {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        color: #777777;
    }    
    .dateSent {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        color: #777777;
    }
    video {
        pointer-events: none;
    }  
    .a {
        display: flex;
    }          
    .a >>> .b {
        display: contents !important;
    }
	.thumb-class{
		min-width: 200px;
		min-height: 250px;
		background-repeat: no-repeat !important;
		background-size: 100% 100% !important;
		position: relative !important;
		box-shadow: inset 0 -35px 20px -20px grey;
	}
</style>



 