import axios from 'axios'
import { baseApiUrl, config} from '../global'
let aux = 0
export default {    
	postLogin (name, password, callback ) {
		let that = this
		aux
		const params = new URLSearchParams()
		params.append('username', name)
		params.append('password', password)    
		axios.post(`${baseApiUrl}oauth2/token`, params, config)
			.then(response => {
				if (response){
					aux = 0
					callback(response.data)  
				}
			})
			.catch(function (error) { 
				if(error.response.status == 500) {
					callback(null) 
					alert('Usuário ou senha incorretos!')
				} else if(error.response.status == 504) {
					callback(null,aux)
					aux++
					// console.log(aux)
					if(aux == 3){
						alert('Serviço indisponível no momento.')
						aux = 0
					}else{
						setTimeout(() => {
							that.postLogin(name,password,callback)
						}, 10000)
					}
				}
				// console.log(error.response.status)                    
			})  
	},
	resfreshLogin ( callback ) {
		const params = new URLSearchParams()
		params.append('refresh_token',localStorage.getItem('__wpp_access_refresh_token'))
		params.append('grant_type','refresh_token')
		axios.post(`${baseApiUrl}oauth2/token`, params, config)
			.then(response => {
				callback(response.data)  
			})
			.catch(function (error) { 
				error
				// console.log(error.response.status)                    
			})  
	},
	
}