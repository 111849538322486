<template>
	<div class="w-75">
		<b-row class="mb-0 mt-2">    
			<b-col md="1" class="ml-3" v-show="step ==10">
				<span class="icon-clickable" @click="goBack">
					<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="40" height="40" rx="8" fill="#F5F5F5"/>
						<path d="M18.2622 18.7135L23.9969 13.0243C24.6018 12.4292 24.6097 11.4692 24.0146 10.8643C23.4433 10.2836 22.4594 10.2756 21.8547 10.8467L15.0549 17.6312C14.9825 17.6786 14.9339 17.7502 14.9095 17.798C14.8615 17.7977 14.8611 17.8457 14.8611 17.8457C14.8128 17.8933 14.788 17.9891 14.7394 18.0607C14.7392 18.0847 14.7152 18.0845 14.715 18.1085C14.6904 18.1803 14.69 18.2283 14.6654 18.3001C14.6652 18.3241 14.617 18.3477 14.6166 18.3957C14.6162 18.4437 14.6157 18.5157 14.6153 18.5637C14.6151 18.5877 14.5905 18.6595 14.5903 18.6835C14.5897 18.7555 14.6135 18.7797 14.6131 18.8277C14.6127 18.8757 14.6123 18.9237 14.6117 18.9956C14.6115 19.0196 14.6593 19.044 14.6587 19.116C14.6825 19.1402 14.682 19.2122 14.7056 19.2604C14.7054 19.2844 14.729 19.3326 14.7768 19.357C14.8002 19.4292 14.7998 19.4772 14.8472 19.5496C14.8472 19.5496 14.895 19.574 14.9188 19.5982C14.9422 19.6704 14.9658 19.7186 15.0376 19.7432L21.7254 26.6381C22.035 26.9287 22.4178 27.0758 22.8258 27.0792C23.2098 27.0823 23.571 26.9412 23.8853 26.6558C24.4902 26.0607 24.4979 25.1248 23.903 24.4959L18.2622 18.7135Z" fill="#414141"/>
					</svg>
				</span>
			</b-col>                     
			<b-col>
				<b-row class="ml-2">  
					<h3 v-if="step==10" class="lbl-title">Nova Resposta Rápida</h3>   
					<h3 v-else class="lbl-title">Editar resposta rápida</h3>                           
				</b-row>
			</b-col>
		</b-row>  
		<div class="w-100">
			<hr>
		</div>  
		<div class="quickreply-scrollable scrollbar">
			<div v-show="actionType !=1">
				<b-row>          
					<span class="custom-font-open-16-gray-600-3">Cabeçalho</span>  
					<span class="custom-font-open-12-gray mt-1 ml-2">Opcional - Máximo 60 caracteres</span>                   
				</b-row>   
				<b-row>
					<span class="custom-font-open-14-400-gray">Adicione um título ou escolha o tipo de mídia que você usará para este cabeçalho.</span>                
				</b-row>   
				<b-row class="w-100">
					<b-form-input v-model="quickreplyHeader" maxlength="60" placeholder="Digite aqui"></b-form-input>            
				</b-row>   
			</div>
         
			<b-row class="mt-2">   
				<h3 v-if="actionType !=1" class="custom-font-open-16-gray-600-3">Corpo da mensagem</h3>               
				<span v-else class="custom-font-open-14-400-gray">Insira uma mensagem como resposta rápida</span>            
				<span class="custom-font-open-12-gray ml-3 mt-1" v-if="actionType ==1">Uma mídia por mensagem apenas</span>
				<span class="custom-font-open-12-gray ml-3" v-else>Uma mídia por mensagem apenas</span>
			</b-row>  
			<b-row class="mr-3">            
				<b-col class="">
					<b-row class="ml-1">
						<b-button-toolbar var>
							<b-button-group>
								<b-button title="Emoji" style="margin-top: -3px;" class="body-style">             
									<b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
										<template #button-content>
											<span class="icon-clickable" @click="openEmojiPick()"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
												<path d="M6 10.5C6 10.5 7.125 12 9 12C10.875 12 12 10.5 12 10.5" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
												<path d="M6.75 6.75H6.7575" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
												<path d="M11.25 6.75H11.2575" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											</svg></span> 
										</template>
										<div style="z-index:99; position: absolute; top:100px;">
											<VEmojiPicker @select="selectEmoji" 
												:emojiSize="22" 
												:emojiWithBorder="false"
												:emojisByRow="10"/>                     
										</div>    
									</b-dropdown>                                                  
								</b-button>                    
								<b-button v-if="bold == false" title="Bold" @click="setBoldText" class="body-style">
									<b-icon icon="type-bold" aria-hidden="true"></b-icon>
								</b-button>
								<b-button v-else title="Bold" ref="bold" @click="setUnBoldText" class="body-style">
									<b-icon icon="type-bold" aria-hidden="true" variant="dark"></b-icon>
								</b-button>

								<b-button v-if="italic == false" title="Italic" @click="setItalicText" class="body-style">
									<b-icon icon="type-italic" aria-hidden="true"></b-icon>
								</b-button>
								<b-button v-else title="Italic" ref="italic" @click="setUnItalicText" class="body-style">
									<b-icon icon="type-italic" aria-hidden="true" variant="dark"></b-icon>
								</b-button>

								<b-button v-if="strike == false" title="Strikethrough"  @click="setStrikeText" class="body-style">
									<b-icon icon="type-strikethrough" aria-hidden="true"></b-icon>
								</b-button>
								<b-button v-else title="Strikethrough" ref="strike" @click="setUnStrikeText" class="body-style">
									<b-icon icon="type-strikethrough" aria-hidden="true" variant="dark"></b-icon>
								</b-button>                        
							</b-button-group>
						</b-button-toolbar>  
					</b-row>                             
				</b-col> 
				<b-col>
					<b-row> 
						<b-col sm="auto" class="mt-1">
							<quickreply-properties-modal
								v-on:sendMessageData="getMessageData"/>
							<!-- <label for="uploadFile" style="cursor: pointer; display: inline;">
								<input id="uploadFile" type="file" @change="filesChange($event.target.files); fileCount = $event.target.files.length"
									class="input-file">
							</label> -->
						</b-col>
						<b-col sm="auto" class="mt-1">
							<!-- <b-button variant="ligth" class="mr-2"
								@change="filesChange($event.target.files)" id="uploadFileImage" type="file" accept="image/*">
								<span class="mr-2">
									<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M13.9794 7.57039L7.85273 13.6971C7.10217 14.4476 6.08419 14.8693 5.02273 14.8693C3.96128 14.8693 2.94329 14.4476 2.19273 13.6971C1.44217 12.9465 1.02051 11.9285 1.02051 10.8671C1.02051 9.8056 1.44217 8.78762 2.19273 8.03706L8.3194 1.91039C8.81977 1.41001 9.49843 1.12891 10.2061 1.12891C10.9137 1.12891 11.5924 1.41001 12.0927 1.91039C12.5931 2.41076 12.8742 3.08942 12.8742 3.79706C12.8742 4.50469 12.5931 5.18335 12.0927 5.68372L5.9594 11.8104C5.70921 12.0606 5.36988 12.2011 5.01607 12.2011C4.66225 12.2011 4.32292 12.0606 4.07273 11.8104C3.82254 11.5602 3.68199 11.2209 3.68199 10.8671C3.68199 10.5132 3.82254 10.1739 4.07273 9.92372L9.73273 4.27039" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								</span>
								Adicionar mídia
							</b-button>  -->
							<label for="uploadFileImage" style="cursor: pointer; display: inline;">	
								<input id="uploadFileImage" type="file" accept="image/pdf*" @change="filesChange($event.target.files); fileCount = $event.target.files.length"
									class="input-file">
										
								<b-button variant="ligth" class="mr-2">					
									<span class="mr-2">
										<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M13.9794 7.57039L7.85273 13.6971C7.10217 14.4476 6.08419 14.8693 5.02273 14.8693C3.96128 14.8693 2.94329 14.4476 2.19273 13.6971C1.44217 12.9465 1.02051 11.9285 1.02051 10.8671C1.02051 9.8056 1.44217 8.78762 2.19273 8.03706L8.3194 1.91039C8.81977 1.41001 9.49843 1.12891 10.2061 1.12891C10.9137 1.12891 11.5924 1.41001 12.0927 1.91039C12.5931 2.41076 12.8742 3.08942 12.8742 3.79706C12.8742 4.50469 12.5931 5.18335 12.0927 5.68372L5.9594 11.8104C5.70921 12.0606 5.36988 12.2011 5.01607 12.2011C4.66225 12.2011 4.32292 12.0606 4.07273 11.8104C3.82254 11.5602 3.68199 11.2209 3.68199 10.8671C3.68199 10.5132 3.82254 10.1739 4.07273 9.92372L9.73273 4.27039" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</span>
									Adicionar midia
								</b-button>              
							</label>
						</b-col>                                      
					</b-row>
				</b-col>            
			</b-row> 
			<b-row>
				<div class="w-100 mr-5">                  
					<div           
						ref="textArea"         
						class="editable"
						contenteditable="true"
						id="textArea"
						placeholder="Insira uma mensagem"
						@keydown="insertLineBreak"
						@click="onClick"
						@keyup="cursorPosition "
					></div>
				</div>   
				<div v-if="showEmojiPicker == true" >
                                                       
				</div>      
                          
			</b-row>
			<b-row v-show="mediaName != ''" class="mt-1">
				<span class="custom-button">{{mediaName}} 
					<b-badge pill variant="secondary"
						class="ml-1"
						@click="removeMedia">
						X
					</b-badge>
				</span>
			</b-row>
			<div v-show="actionType !=1">
				<b-row class="mt-2">          
					<span class="custom-font-open-16-gray-600-3">Rodapé</span>
					<span class="custom-font-open-12-gray mt-1 ml-2">Opcional - Máximo 60 caracteres</span>                      
				</b-row> 
				<b-row>
					<span class="custom-font-open-14-400-gray">Adicione uma linha curta de texto na parte inferior do seu modelo de mensagem.</span>                
				</b-row>     
				<b-row class="w-100">
					<b-form-input v-model="quickreplyFooter" maxlength="60" placeholder="Digite aqui"></b-form-input>            
				</b-row>   
			</div>        
			<b-row class="mb-1 mt-3 w-100">           
				<span class="custom-font-open-14">Insira uma descrição dessa resposta rápida</span>
			</b-row> 
			<b-row class="w-100">
				<b-form-input v-model="quickreplyDescription" placeholder="Digite aqui"></b-form-input>            
			</b-row>
			<b-row class="mt-3">
				<span class="custom-font-open-14">Insira uma palavra como Atalho</span>
			</b-row>
			<b-row class="w-100">
				<b-form-input v-model="quickreplyCommand" placeholder="/Atalho"></b-form-input>
			</b-row>
			<div v-show="actionType ==2">
				<b-row class="mt-2">          
					<h3 class="custom-font-open-16-gray-600-3">Botões</h3>                                     
				</b-row>
				<b-row>
					<span class="custom-font-open-14-400-gray">Crie botões para seus clientes responderem à sua mensagem rapidamente ou interagir com você.</span>
				</b-row> 
				<b-row>
                 
				</b-row>     
				<div class="w-50 mt-2" style="margin-left: -15px;">
					<span class="custom-font-open-12-gray">Máximo 20 caracteres</span>
					<b-row v-for="(x, index) in orderBy(buttons, 'interactionIndex')" :key="index" class="mt-1">
						<b-col md="10">
							<b-form-input v-model="x.text" maxlength="20" placeholder="Texto do botão"></b-form-input>
						</b-col>                    
						<b-col class="mt-2">
							<span class="icon-clickable" @click="removeItemButton(index)" v-show="index > 0">
								<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M3 4H4.33333H15" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M6.3335 4.00004V2.66671C6.3335 2.31309 6.47397 1.97395 6.72402 1.7239C6.97407 1.47385 7.31321 1.33337 7.66683 1.33337H10.3335C10.6871 1.33337 11.0263 1.47385 11.2763 1.7239C11.5264 1.97395 11.6668 2.31309 11.6668 2.66671V4.00004M13.6668 4.00004V13.3334C13.6668 13.687 13.5264 14.0261 13.2763 14.2762C13.0263 14.5262 12.6871 14.6667 12.3335 14.6667H5.66683C5.31321 14.6667 4.97407 14.5262 4.72402 14.2762C4.47397 14.0261 4.3335 13.687 4.3335 13.3334V4.00004H13.6668Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M7.6665 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M10.3335 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</span>
						</b-col>                    
					</b-row>                                    
				</div>   
				<b-row class="justify-content-start mt-2">
					<b-button v-b-toggle.sidebar-right variant="secondary"
						@click="addNewButton()" 
						:disabled="buttons.length > 2">
						<span class="ml-1">
							Adicionar outro botão
						</span>                        
					</b-button>                                                      
				</b-row>            
			</div>       
			<div v-show="actionType ==3" class="list">
				<b-row class="mt-2">          
					<h3 class="custom-font-open-16-gray-600-3">Lista</h3>
					<span class="custom-font-open-14-400-gray">Abaixo de sua mensagem, o sistema irá apresentar para o seu cliente a lista de opções que você criar abaixo, as quais poderão
						ter uma descrição e/ou serem divididas em seções.</span>                      
				</b-row>
				<b-row class="mt-1">
					<h3 class="custom-font-open-16-gray-600-3">Nome do botão da lista</h3>
				</b-row>  
				<b-row class="w-25">
					<b-form-input id="button" type="text" v-model="buttonName" maxlength="20" placeholder="0/20"/>
				</b-row>                
				<b-row class="mt-2">            
					<b-row v-for="(x, index) in orderBy(list, 'interactionIndex')" :key="index" class="mt-1 mr-3">
						<b-col sm="auto">
							<span v-show="index == 0" class="custom-font-open-16-gray-600-3">Opção</span>
							<b-form-input style="min-width:140px;max-width:200px" :class="[ 'form-control', x.interactionTitle == '' && hasError ?  'bcn-box-shadow-check' : '' ]" id="option" type="text" maxlength="20" placeholder="0/20" v-model="x.interactionTitle"/>                             
						</b-col>         
						<b-col sm="auto">
							<span v-show="index == 0" class="custom-font-open-16-gray-600-3">Descrição <span class="custom-font-open-12-gray ml-2">Opcional</span></span>                            
							<b-form-input style="min-width:140px;max-width:200px" id="description" type="text" maxlength="72" placeholder="0/72" v-model="x.interactionDescription"/>                             
						</b-col>   
						<b-col sm="auto">
							<span v-show="index == 0" class="custom-font-open-16-gray-600-3">Seção <span class="custom-font-open-12-gray ml-2">Opcional</span></span>
							<b-form-input style="min-width:140px;max-width:200px" :class="[ 'form-control', x.interactionSection == '' && hasError ?  'bcn-box-shadow-check' : '' ]" id="session" placeholder="0/20" maxlength="20" type="text" v-model="x.interactionSection"/>                            
						</b-col>     
						<b-col md="1" class="mt-2">
							<span v-show="index == 0">
								<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								</svg>
							</span>                            
							<span class="icon-clickable" @click="removeItemList(index)" v-show="index > 0">
								<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M3 4H4.33333H15" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M6.3335 4.00004V2.66671C6.3335 2.31309 6.47397 1.97395 6.72402 1.7239C6.97407 1.47385 7.31321 1.33337 7.66683 1.33337H10.3335C10.6871 1.33337 11.0263 1.47385 11.2763 1.7239C11.5264 1.97395 11.6668 2.31309 11.6668 2.66671V4.00004M13.6668 4.00004V13.3334C13.6668 13.687 13.5264 14.0261 13.2763 14.2762C13.0263 14.5262 12.6871 14.6667 12.3335 14.6667H5.66683C5.31321 14.6667 4.97407 14.5262 4.72402 14.2762C4.47397 14.0261 4.3335 13.687 4.3335 13.3334V4.00004H13.6668Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M7.6665 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M10.3335 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</span>
						</b-col>       
					</b-row>                                                                            
				</b-row>    
				<b-col md="11">
					<b-row class="justify-content-center mt-2">
						<b-button class="mr-3" v-b-toggle.sidebar-right variant="secondary"
							@click="addNewList()" 
							:disabled="list.length > 9">
							<span class="ml-1">
								Adicionar outra linha
							</span>                        
						</b-button>                                                      
					</b-row>                   
				</b-col>         
			</div>                
			<b-row v-if="step == 10.1" class="mt-3">
				<div class="w-100">
					<b-button variant="outline-primary" class="mr-2"
						@click="cancel">Cancelar
					</b-button>   
					<b-button variant="primary" class="float-right mr-5"
						@click="save">Salvar           
					</b-button>  
				</div>                   
			</b-row> 
			<b-row v-else class="mt-3">
				<div class="w-100">
					<b-button variant="outline-primary" class="mr-2"
						@click="cancel">Cancelar
					</b-button>   
					<b-button variant="primary" class="float-right mr-5"
						@click="next">Avançar           
					</b-button>  
				</div>                   
			</b-row>    
			<br>     
		</div>               
	</div>   
</template>

<script>
import WQuickReplyAPI from '@/api/WQuickReplyAPI'
import WQuickReplyProperties from './WQuickReplyProperties'
import Vue2Filters from 'vue2-filters'
export default {    
	name: 'WQuickReply',   
	props: [
		'step',
		'action'
	],
	mixins: [Vue2Filters.mixin],    
	components: {
		'quickreply-properties-modal': WQuickReplyProperties
	},
	data: function () {
		return {
			loading: false,
			checked: false,
			campaignTime: '',
			btnDisable: true,
			actionType: this.action,
			option: 0,
			quickreplyId: '',
			quickReplyDetail: {},
			showEmojiPicker: false,
			quickreplyMessage: '',
			quickreplyDescription: '',
			quickreplyCommand: '',
			quickreplyHeader: '',
			quickreplyFooter: '',
			buttonName: '',
			formattedText: [],
			buttons: [
				{interactionIndex:0 , text: ''}
			],
			list: [
				{
					interactionIndex: 0,
					interactionTitle: '',
					interactionDescription: '',
					interactionSection: ''
				}
			],
			mediaName: '',
			file: null,
			emoji: false,
			bold: false,
			italic: false,
			strike: false,
			hasError: false,
			focused:'',
			pos:0,
		}
	},
        
	methods: {
		onClick () {
			this.cursorPosition()
			let isBold = document.queryCommandValue('bold')
			let isItalic = document.queryCommandValue('italic')
			let isStrike = document.queryCommandValue('strikethrough')
			if (isBold === 'true') {
				this.bold = true
			} else {
				this.bold = false
			}			
			if (isItalic === 'true') {
				this.italic = true
			} else {
				this.italic = false
			}	
			if (isStrike === 'true') {
				this.strike = true
			} else {
				this.strike = false
			}		
		},
		cursorPosition () {
			let sel = document.getSelection()
			this.focused = sel.focusNode
			this.pos = sel.focusOffset
			return this.pos
		},
		setCaretEditable (text) {
			let shouldSkip = false
			const el = document.getElementById('textArea')
			el.focus()
			let char = this.pos, sel // character at which to place caret

			if (document.selection) {
				sel = document.selection.createRange()
				sel.moveStart('character', char)
				sel.select()
			}
			else {
				sel = window.getSelection()
				let array = el.childNodes
				array.forEach((element) => {
					if(element.data == this.focused.nodeValue || element.innerText == this.focused.nodeValue && shouldSkip == false){
						if(element.data != null &&  element.data != undefined && element.data != 'undefined'){
							element.data = (element.data.slice(0, this.pos) + text + element.data.slice(this.pos))
							this.pos += 2
							this.focused= element
							sel.collapse(this.focused, this.pos)
							shouldSkip = true
							this.cursorPosition()
						}else if(element.innerText != null &&  element.innerText != undefined){
							element.innerText = (element.innerText.slice(0, this.pos) + text + element.innerText.slice(this.pos))
							this.pos += 2
							this.focused= element
							sel.collapse(this.focused.childNodes[0], this.pos)
							shouldSkip = true
							this.cursorPosition()
						}
					}
				})
				
				
				this.updatePreviewBody()	
			}
		},
		addNewButton () {
			this.buttons.sort(this.dynamicSort('-interactionIndex'))
			let lastIndex = this.buttons[0].interactionIndex           
			this.buttons.push({interactionIndex: lastIndex +1, text: ''})
		},
		addNewList () {
			this.list.sort(this.dynamicSort('-interactionIndex'))
			let lastIndex = this.list[0].interactionIndex
			this.list.push({interactionIndex: lastIndex +1, interactionTitle: '', interactionDescription: '', interactionSection: ''})
		},
		dynamicSort (property) {
			let sortOrder = 1
			if(property[0] === '-'){
				sortOrder = -1
				property = property.substr(1)
			}
			return function (a,b) {
				let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
				return result * sortOrder
			}
		},               
		removeItemButton (index) {
			this.buttons.splice(index, 1)
		},           
		removeItemList (index) {
			this.list.splice(index, 1)
		},        
		getQuickReplyDetail () {
			WQuickReplyAPI.getQuickReplyDetail(this.$route.params.companyId, this.quickreplyId, response => {
				this.quickReplyDetail = response.data       
				this.quickreplyMessage = this.quickReplyDetail.quickReplyContent
				let el = document.getElementById('textArea')
				el.innerHTML = this.replaceMulti(this.quickreplyMessage, '\\n', '<br>' )               
				this.quickreplyDescription = this.quickReplyDetail.quickReplyDescription
				this.quickreplyCommand = this.quickReplyDetail.quickReplyCommand
				this.quickreplyHeader = this.quickReplyDetail.quickReplyHeader
				this.quickreplyFooter = this.quickReplyDetail.quickReplyFooter
				this.buttonName = this.quickReplyDetail.quickReplyListButton                
				if (Array.isArray(this.quickReplyDetail.buttonList) && this.quickReplyDetail.buttonList.length){
					this.buttons = []
					for (const key in this.quickReplyDetail.buttonList){
						let obj = this.quickReplyDetail.buttonList[key]
						this.buttons.push({interactionIndex: obj.interactionIndex, text: obj.interactionTitle})
					}
				}
				if (Array.isArray(this.quickReplyDetail.listList) && this.quickReplyDetail.listList.length){
					this.list = this.quickReplyDetail.listList
				}
				this.mediaName = this.quickReplyDetail.mediaName    
			})
		},     
		removeMedia () {
			this.file = null
			this.mediaName = ''
			if (this.step == 10.1){
				WQuickReplyAPI.deleteMedia(this.$route.params.companyId, this.quickreplyId, response => {
					if (response){     
						alert('Operação realizada com sucesso!')                                                                                                           
					} else {                 
						alert('Falha ao excluir mídia!')
					}
				})                  
			}
		},     
		getMessageData (obj) {
			let el = document.getElementById('textArea')
			el.innerHTML += '{'+ obj.propertyName+ '}'           
		},
		setBoldText () {        
			this.bold = true
			document.execCommand('bold')                    
			if (this.italic == true){
				this.setUnItalicText()
			}          
			if (this.strike == true){
				this.setUnStrikeText()
			}       
			const textArea = this.$refs.textArea  
			textArea.focus()
		},  
		setUnBoldText () {
			this.bold = false
			document.execCommand('bold')
			const textArea = this.$refs.textArea  
			textArea.focus()            
		},
		setItalicText () {
			this.italic = true
			document.execCommand('italic')    
			if (this.bold == true){
				this.setUnBoldText()
			}          
			if (this.strike == true){
				this.setUnStrikeText()
			}                
			const textArea = this.$refs.textArea  
			textArea.focus()                   
		},
		setUnItalicText () {
			this.italic = false
			document.execCommand('italic')
			const textArea = this.$refs.textArea  
			textArea.focus()            
		},
		setStrikeText () {
			this.strike = true
			document.execCommand('strikeThrough')  
			if (this.bold == true){
				this.setUnBoldText()
			}                      
			if (this.italic == true){
				this.setUnItalicText()
			}            
			const textArea = this.$refs.textArea  
			textArea.focus()                                 
		},
		setUnStrikeText () {
			this.strike = false
			document.execCommand('strikeThrough')  
			const textArea = this.$refs.textArea  
			textArea.focus()            
		},        
		selectEmoji (emoji) {
			this.setCaretEditable(emoji.data)
			// let el = document.getElementById('textArea')
			// el.innerHTML += emoji.data
		},                   
		openEmojiPick () {     
			if (!this.showEmojiPicker){
				this.showEmojiPicker = true
			} else {
				this.showEmojiPicker = false
			}            
		},     
		filesChange (file) {
			// handle file changes
			if (!file.length) 
				return
			let size = file[0].size 
			if (size > 10485760){ 
				alert('Tamanho máximo do arquivo 10MB!')
				return
			}    
			// append the files to FormData
			this.mediaName = file[0].name
			this.file = file[0]
		},   
		convertHtmlToText (str) {
			str = str.toString()
			return str.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '')
		},      
		replaceMulti (haystack, needle, replacement) {
			haystack = haystack.toString()
			let result = haystack.split(needle).join(replacement)            
			return result
		}, 
		insertLineBreak (event) {
			if (event.key === 'Enter'){
				document.execCommand('insertLineBreak')
				event.preventDefault()
			}
		},
		next () { 
			this.quickreplyMessage = document.getElementById('textArea').innerHTML  
			if (this.file ==null){
				if (this.quickreplyMessage == '' || this.quickreplyMessage == undefined){
					alert('Campo mensagem obrigatório!')
					return
				}                       
			}                
			if (this.quickreplyDescription == '' || this.quickreplyDescription == undefined){
				alert('Campo descrição obrigatório!')
				return
			}  
			if (this.quickreplyCommand == '' || this.quickreplyCommand == undefined){
				alert('Campo atalho obrigatório!')
				return
			}   
			if (this.actionType == 2){
				let hasButtonText = false
				this.buttons.forEach(element => {
					if (element.text != ''){
						hasButtonText = true
					}
				})
				if (!hasButtonText){
					alert('Pelo menos um texto do botão deve ser informado!')
					return
				}
			}
			if (this.actionType == 3){
				if (this.buttonName == '' || this.buttonName == undefined){
					alert('Campo nome do botão da lista obrigatório!')
					return                    
				}
				let hasLineEmpty = false
				let listLength = this.list.length
				this.hasError = false
				let allSectionEmpty = true
				this.list.forEach(element => {
					if (element.interactionTitle == '' && listLength == 1){
						hasLineEmpty = true
					}
					if (listLength > 1 && element.interactionSection == ''){
						this.hasError = true
					}       
					if (element.interactionTitle == ''){
						this.hasError = true
					}             
					if (element.interactionSection != ''){
						allSectionEmpty = false 
					}                                      
				})
				if (allSectionEmpty ==true){
					this.hasError = false
				}
				if (hasLineEmpty){
					alert('Pelo menos uma linha deve ser informada!')
					return
				}
				if (this.hasError){
					return
				}
			}                  

			let regex1 = /(<b[^>]+>|<b>|<\/b>)/g
			let regex2 = /(<i[^>]+>|<i>|<\/i>)/g
			let regex3 = /(<strike[^>]+>|<strike>|<\/strike>)/g
			this.quickreplyMessage = this.replaceMulti(this.quickreplyMessage, '<br>', '\n' )                                
			this.quickreplyMessage = this.quickreplyMessage.replace(regex1 , '*')
			this.quickreplyMessage = this.quickreplyMessage.replace(regex2 , '_')   
			this.quickreplyMessage = this.quickreplyMessage.replace(regex3 , '~')   
			this.quickreplyMessage = this.convertHtmlToText(this.quickreplyMessage)
			let objQuickReply = {
				quickreplyId: '',
				type: this.actionType,
				command: this.quickreplyCommand,
				description: this.quickreplyDescription,
				content: this.quickreplyMessage,
				header: this.quickreplyHeader,
				footer: this.quickreplyFooter,
			}                                                
			WQuickReplyAPI.postCreate(this.$route.params.companyId, objQuickReply, response => {
				if (response){       
					let quickreplyId = response.data
					objQuickReply.quickreplyId = quickreplyId
					localStorage.removeItem('__wpp_automation_quickreply_obj')                        
					localStorage.setItem('__wpp_automation_quickreply_obj', JSON.stringify(objQuickReply))                                 
					if (this.actionType == 1){                                      
						if (this.file != null){
							const formData = new FormData()                        
							formData.append('filename', this.mediaName)
							formData.append('file', this.file)                        
							WQuickReplyAPI.postUploadMedia(this.$route.params.companyId, quickreplyId, formData, response => {
								if (response){                                                   
									let obj = {step: 11}
									this.$emit('sendMessageData', obj)                                                                                      
								} else {                 
									alert('Falha no upload do arquivo!')
								}
							})                            
						} else {
							let obj = {step: 11}
							this.$emit('sendMessageData', obj)   
						}                                                                                    
					}                        
					if (this.actionType == 2){
						let buttonList = []
						this.buttons.forEach(el => {
							if (el.text != ''){
								buttonList.push(el.text)
							}                            
						})
						WQuickReplyAPI.postInteractionButtons(this.$route.params.companyId, quickreplyId, buttonList, response => {
							if (response){     
								if (this.file != null){
									const formData = new FormData()                        
									formData.append('filename', this.mediaName)
									formData.append('file', this.file)                        
									WQuickReplyAPI.postUploadMedia(this.$route.params.companyId, quickreplyId, formData, response => {
										if (response){                                                   
											let obj = {step: 11}
											this.$emit('sendMessageData', obj)                                                                                      
										} else {                 
											alert('Falha no upload do arquivo!')
										}
									})                            
								} else {
									let obj = {step: 11}
									this.$emit('sendMessageData', obj)   
								}                                                                                                                       
							} else {                 
								alert('Falha ao adicionar botões!')
							}
						})                                      
					}                                                                 
					if (this.actionType == 3){                   
						WQuickReplyAPI.postInteractionList(this.$route.params.companyId, quickreplyId, this.buttonName, this.list, response => {
							if (response){     
								if (this.file != null){
									const formData = new FormData()                        
									formData.append('filename', this.mediaName)
									formData.append('file', this.file)                        
									WQuickReplyAPI.postUploadMedia(this.$route.params.companyId, quickreplyId, formData, response => {
										if (response){                                                   
											let obj = {step: 11}
											this.$emit('sendMessageData', obj)                                                                                      
										} else {                 
											alert('Falha no upload do arquivo!')
										}
									})                            
								} else {
									let obj = {step: 11}
									this.$emit('sendMessageData', obj)   
								}                                                                                                                       
							} else {                 
								alert('Falha ao adicionar botões!')
							}
						})                                      
					}                                                                           
				} else {
					alert('Falha ao criar resposta rápida!')
				}
			})                                                                                                      
		},   
		save () { 
			this.quickreplyMessage = document.getElementById('textArea').innerHTML            
			if (this.quickreplyMessage == '' || this.quickreplyMessage == undefined){
				alert('Campo mensagem obrigatório!')
				return
			}       
			if (this.quickreplyDescription == '' || this.quickreplyDescription == undefined){
				alert('Campo descrição obrigatório!')
				return
			}  
			if (this.quickreplyCommand == '' || this.quickreplyCommand == undefined){
				alert('Campo mensagem obrigatório!')
				return
			}     
			if (this.actionType == 2){
				let hasButtonText = false
				this.buttons.forEach(element => {
					if (element.text != ''){
						hasButtonText = true
					}
				})
				if (!hasButtonText){
					alert('Pelo menos um texto do botão deve ser informado!')
					return
				}
			}
			if (this.actionType == 3){
				if (this.buttonName == '' || this.buttonName == undefined){
					alert('Campo nome do botão da lista obrigatório!')
					return                    
				}
				let hasLineEmpty = false
				let listLength = this.list.length
				this.hasError = false
				let allSectionEmpty = true
				this.list.forEach(element => {
					if (element.interactionTitle == '' && listLength == 1){
						hasLineEmpty = true
					}
					if (listLength > 1 && element.interactionSection == ''){
						this.hasError = true
					}  
					if (element.interactionSection != ''){
						allSectionEmpty = false 
					}                                      
				})
				if (allSectionEmpty ==true){
					this.hasError = false
				}
				if (hasLineEmpty){
					alert('Pelo menos uma linha deve ser informada!')
					return
				}
				if (this.hasError){
					return
				}
			}                 
			let regex1 = /(<b[^>]+>|<b>|<\/b>)/g
			let regex2 = /(<i[^>]+>|<i>|<\/i>)/g
			let regex3 = /(<strike[^>]+>|<strike>|<\/strike>)/g
			this.quickreplyMessage = this.replaceMulti(this.quickreplyMessage, '<br>', '\n' )
			this.quickreplyMessage = this.quickreplyMessage.replace(regex1 , '*')
			this.quickreplyMessage = this.quickreplyMessage.replace(regex2 , '_')   
			this.quickreplyMessage = this.quickreplyMessage.replace(regex3 , '~')
			this.quickreplyMessage = this.convertHtmlToText(this.quickreplyMessage)    
			let objQuickReply = {
				quickreplyId: '',
				type: this.actionType,
				command: this.quickreplyCommand,
				description: this.quickreplyDescription,
				content: this.quickreplyMessage,
				header: this.quickreplyHeader,
				footer: this.quickreplyFooter,
			}                                                
			WQuickReplyAPI.postUpdate(this.$route.params.companyId, this.quickreplyId, objQuickReply, response => {
				if (response){       
					objQuickReply.quickreplyId = this.quickreplyId
					localStorage.removeItem('__wpp_automation_quickreply_obj')                        
					localStorage.setItem('__wpp_automation_quickreply_obj', JSON.stringify(objQuickReply))                                 
					if (this.actionType == 1){                                      
						if (this.file != null){
							const formData = new FormData()                        
							formData.append('filename', this.mediaName)
							formData.append('file', this.file)                        
							WQuickReplyAPI.postUploadMedia(this.$route.params.companyId, this.quickreplyId, formData, response => {
								if (response){                                                   
									let obj = {step: 8}
									this.$emit('sendMessageData', obj)                                                                                      
								} else {                 
									alert('Falha no upload do arquivo!')
								}
							})                            
						} else {
							let obj = {step: 8}
							this.$emit('sendMessageData', obj)   
						}                                                                                    
					}                        
					if (this.actionType == 2){
						let buttonList = []
						this.buttons.forEach(el => {
							buttonList.push(el.text)
						})                        
						WQuickReplyAPI.postInteractionButtons(this.$route.params.companyId, this.quickreplyId, buttonList, response => {
							if (response){     
								if (this.file != null){
									const formData = new FormData()                        
									formData.append('filename', this.mediaName)
									formData.append('file', this.file)                        
									WQuickReplyAPI.postUploadMedia(this.$route.params.companyId, this.quickreplyId, formData, response => {
										if (response){                                                   
											let obj = {step: 8}
											this.$emit('sendMessageData', obj)                                                                                      
										} else {                 
											alert('Falha no upload do arquivo!')
										}
									})                            
								} else {
									let obj = {step: 8}
									this.$emit('sendMessageData', obj)   
								}                                                                                                                       
							} else {                 
								alert('Falha ao adicionar botões!')
							}
						})                                      
					}                                                                 
					if (this.actionType == 3){
						WQuickReplyAPI.postInteractionList(this.$route.params.companyId, this.quickreplyId, this.buttonName, this.list, response => {
							if (response){     
								if (this.file != null){
									const formData = new FormData()                        
									formData.append('filename', this.mediaName)
									formData.append('file', this.file)                        
									WQuickReplyAPI.postUploadMedia(this.$route.params.companyId, this.quickreplyId, formData, response => {
										if (response){                                                   
											let obj = {step: 8}
											this.$emit('sendMessageData', obj)                                                                                      
										} else {                 
											alert('Falha no upload do arquivo!')
										}
									})                            
								} else {
									let obj = {step: 8}
									this.$emit('sendMessageData', obj)   
								}                                                                                                                       
							} else {                 
								alert('Falha ao atualizar botões!')
							}
						})                                      
					}                                                                           
				} else {
					alert('Falha ao atualizar resposta rápida!')
				}
			})                                                                                                      
		},                                       
		cancel () {      
			let obj = {step: 8}
			this.$emit('sendMessageData', obj)             
		},             
		goBack () {
			let obj = {step: 9}
			this.$emit('sendMessageData', obj) 
		}          
	},    
	mounted () {
		let objQuickReply = JSON.parse(localStorage.getItem('__wpp_automation_quickreply_obj'))
		if (objQuickReply != null){
			this.actionType = objQuickReply.type       
			this.quickreplyId = objQuickReply.quickreplyId
			if (this.step == 10.1){
				this.getQuickReplyDetail()
			}
		}                     
	},      
}
</script>

<style scoped>
hr {
    border: 1px solid #C4C4C4;
}

.body-style {
    color: #C4C4C4;
    background-color: #fff;
    border-color: transparent;
    font-size: 18px;
    font-weight: 700;
}

.quickreply-scrollable {
    height: 78vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 15px;
}
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 40px;
  position: absolute;
  cursor: pointer;
}
.editable {
    width: 100%;
    height: auto;
    min-height: 100px;
    border: 1px solid #ccc;
    padding: 5px;
}
.custom-button {
    background-color: var(--secondary);
    border-color:  var(--secondary); 
    color: #fff;
    padding: 5px 10px; 
    border-radius: 5px;  
    font-size: 14px; 
    font-weight: 500;
}
.bcn-box-shadow-check {
    background: #FFFFFF;
    border: 1px solid var(--primary);
    box-sizing: border-box;
    box-shadow: 0px 0px 0px rgba(187, 26, 94, 0.3);
}    
.list input::-webkit-input-placeholder {
    text-align: right;
    font-size: 14px;
    opacity: 0.7;
}
</style>

