import axios from 'axios'
import i18n from '@/plugins/i18n'
import { baseApiUrl} from '@/global'

export default {    
  
	getAgentsList: (companyId, callback) => {
		const url = `${baseApiUrl}`+'agent/'+ companyId + '/list'
		axios.get(url).then(response => {
			if (response){
				callback(response.data)                        
			}        
		})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)
			})  
	}, 

	getDetail: (companyId, userId, callback) => {
		const url = `${baseApiUrl}`+'agent/'+ companyId + '/' + userId + '/detail'
		axios.get(url).then(response => {
			if (response){
				callback(response.data)                        
			}        
		})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)
			})  
	}, 
    
	postDeactive (companyId, userId, targetId, callback) {
		let url = `${baseApiUrl}` + 'agent/'+ companyId + '/' + userId + '/'+ targetId+ '/deactive'
		axios.post(url)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				if(error.response.status == 406){
					window.alert('Único administrador não pode ser removido')                
				}
				console.log(i18n.t('Message.error') + error)                
			})    
	},         
    
	postActive (companyId, userId, callback) {
		let url = `${baseApiUrl}` + 'agent/'+ companyId + '/' + userId + '/active'
		axios.post(url)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},       

	postCreateAgent (companyId, agent, callback) {
		const params = new URLSearchParams()
		params.append('name', agent.name)    
		params.append('countryNumber', agent.countryNumber)     
		params.append('phonenumber', agent.phonenumber)     
		params.append('email', agent.email)     
		params.append('password', agent.password)        
		params.append('admin', agent.admin)     
		params.append('agent', agent.agent)     
		params.append('dashboard', agent.dashboard)
		params.append('developer', agent.developer)     
		params.append('contact', agent.contact)     
		params.append('broadcast', agent.broadcast)     
		params.append('automation', agent.automation)   
		params.append('billing', agent.billing)        
		let url = `${baseApiUrl}` + 'agent/'+ companyId + '/createAgent'
		axios.post(url, params)
			.then(response => {
				if (response){                                 
					callback(response.data) 
				}
			})
			.catch(function (error) {
				if(error.response.status == 406){
					window.alert('Este e-mail já está em uso!')                
				}
				console.log(i18n.t('Message.error') + error)
			})    
	},       
        
	postUpdateAgent (companyId, userId, agent, callback) {
		const params = new URLSearchParams()
		params.append('name', agent.name)    
		params.append('countryNumber', agent.countryNumber)     
		params.append('phonenumber', agent.phonenumber)     
		params.append('email', agent.email)      
		params.append('admin', agent.admin)     
		params.append('agent', agent.agent)     
		params.append('dashboard', agent.dashboard)
		params.append('developer', agent.developer)     
		params.append('contact', agent.contact)     
		params.append('broadcast', agent.broadcast)     
		params.append('automation', agent.automation)   
		params.append('template', agent.template)  
		params.append('billing', agent.billing)        
		let url = `${baseApiUrl}` + 'agent/'+ companyId + '/' + userId + '/updateAgent'
		axios.post(url, params)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},    
	postDepartment (companyId, userId, departmentAll, departmentIdList, callback) {
		const params = new URLSearchParams() 
		params.append('departmentAll', departmentAll)     
		params.append('departmentIdList', departmentIdList)           
		let url = `${baseApiUrl}` + 'agent/'+ companyId + '/' + userId + '/department'
		axios.post(url, params)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},       
     
	postResetPassword (companyId, agentId, callback) {            
		let url = `${baseApiUrl}` + 'agent/'+ companyId + '/' + agentId + '/resetPassword'
		axios.post(url)
			.then(response => {
				if (response){                                 
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},  

	deleteAgent (companyId, agentId, targetId, callback) {            
		let url = `${baseApiUrl}` + 'agent/'+ companyId + '/' + agentId + '/' + targetId+ '/deleteAgent'
		axios.delete(url)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				if(error.response.status == 406){
					window.alert('Único administrador não pode ser removido')                
				}
				console.log(i18n.t('Message.error') + error)                
			})    
	},    
	deleteSessions (companyId, agentId, callback) {            
		let url = `${baseApiUrl}` + 'agent/'+ companyId + '/' + agentId + '/deleteSessions'
		axios.delete(url)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)
			})    
	},                       
}
