<template>
	<b-col class="keyword-list-scrollable scrollbar">
		<b-row class="ml-1">
			<b-col class="mt-2">
				<b-row class="ml-1">
					<span class="lbl-title">Palavras chaves</span>
				</b-row>
				<b-row class="ml-1 mb-1">
					<span class="custom-font-open-18-400">Quando elas aparecerem, o sistema responderá conforme você programou!</span>
				</b-row>                
			</b-col>
			<b-col class="mt-4">
				<b-row class="justify-content-end mr-5">
					<b-button class="mr-2" v-b-toggle.sidebar-right variant="secondary"
						@click="create()">
						<span>
							<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M8 3V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M3 8H13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
						</span>
						<span class="ml-1">
							Adicionar palavra chave
						</span>                        
					</b-button>                                                      
				</b-row>
			</b-col>                
		</b-row>
		<loading v-if="this.$store.state.loading ==true"/>     
		<b-row v-if="this.$store.state.loading ==false">
			<b-col class="mt-2" md="8">
				<b-row class="ml-1">
					<div class="ml-2">
						<search-check-box-filter
							:departmentFilter="true"
							:sortBy="'text'"
							v-on:checkedItems="setSelectedItems($event)"
						/>
					</div>
				</b-row>
				
			</b-col>
			<b-col md="4">
				<b-row class="justify-content-end mt-1 mr-5">
					<b-input-group>
						<b-form-input 
							type="search"                     
							placeholder="Buscar palavra"
							v-model="filter"
							@keyup="searchAutomations"
							class="mt-1"
							@update="clearFilter">
						</b-form-input>
						<b-input-group-prepend is-text class="mt-1">
							<b-icon icon="search"></b-icon>
						</b-input-group-prepend>                    
					</b-input-group>                                                                                       
				</b-row>  
			</b-col>                  
		</b-row>
		<b-row class="mt-1">      
			<div>
				<automation-modal-delete
					:title = "'Excluir palavras chave'"
					:subTitle = "'Tem certeza que deseja excluir a(s) palavra(s) chave?'"
					:description = "automationName"
					:description1= "''"
					:value = "''"
					:placeholder = "''"
					:button1 = "'Fechar'"
					:button2 = "'Excluir'"
					:start="showModalDelete"   
					v-on:sendModalData="deleteAutomation"
					:key="componentDeleteModalKey"                        
				/>
			</div>                                                 
			<b-col class="ml-2" v-if="this.$store.state.loading ==false" >
				<div>
					<b-table-simple id="my-table"
						style="min-height:200px"
						class="ml-3"
						:per-page="perPage"
						:current-page="currentPage" hover small responsive>
							
						<b-thead head-variant="ligth">
							<b-tr>
								<b-th>Palavras chave</b-th>
								<b-th>Precisão</b-th>                
								<b-th>Reações do sistema</b-th>
								<b-th>Ativações</b-th>
								<b-th>Departamentos</b-th>
							</b-tr>
						</b-thead>
						<b-tbody >
							<b-tr :key="key.keywordId" v-for="key in lists"
								v-show="key.visible ==true">									
								<b-td class="td-automation"><span :key="_index"  v-for="(x, _index) in key.keywordsList" class="keyword-tag mr-2">{{x}}</span></b-td>
								<b-td class="td-phonenumber">{{key.matchingFuzzy}}%</b-td>                     
								<b-td class="td-date">{{key.matchingMethodDescription}}</b-td> 
								<b-td class="td-data">{{key.triggered}} vezes</b-td>
								<b-td class="td-data" v-show="key.departmentCount < 2 && key.departmentAll == false">{{key.departmentCount}} departamento</b-td> 
								<b-td class="td-data" v-show="key.departmentCount > 1 && key.departmentAll == false">{{key.departmentCount}} departamentos</b-td>      
								<b-td class="td-data" v-show="key.departmentAll == true">Todos departamentos</b-td>      
								<b-td>
                  
									<b-dropdown size="sm"  variant="link" dropleft toggle-class="text-decoration-none" right text="Right align" no-caret>
										<template #button-content>
											<svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
												<circle cx="2" cy="2" r="2" fill="#C4C4C4"/>
												<circle cx="2" cy="11" r="2" fill="#C4C4C4"/>
												<circle cx="2" cy="20" r="2" fill="#C4C4C4"/>
											</svg>
										</template>
										<b-dropdown-item @click="editKeywords(key.keywordId)">
											<span>
												<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M10.0779 1.50136C10.2368 1.34241 10.4255 1.21632 10.6332 1.1303C10.8409 1.04428 11.0635 1 11.2883 1C11.513 1 11.7356 1.04428 11.9433 1.1303C12.151 1.21632 12.3397 1.34241 12.4986 1.50136C12.6576 1.66031 12.7837 1.84901 12.8697 2.05669C12.9557 2.26436 13 2.48695 13 2.71174C13 2.93653 12.9557 3.15912 12.8697 3.3668C12.7837 3.57447 12.6576 3.76317 12.4986 3.92212L4.32855 12.0922L1 13L1.90779 9.67145L10.0779 1.50136Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
												</svg>
											</span>
											<span class="custom-font-open-12-400 ml-1">
												Editar palavras chave
											</span>                                        
										</b-dropdown-item>  
										<b-dropdown-item @click="editReaction(key.keywordId)">
											<span>
												<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M10.0779 1.50136C10.2368 1.34241 10.4255 1.21632 10.6332 1.1303C10.8409 1.04428 11.0635 1 11.2883 1C11.513 1 11.7356 1.04428 11.9433 1.1303C12.151 1.21632 12.3397 1.34241 12.4986 1.50136C12.6576 1.66031 12.7837 1.84901 12.8697 2.05669C12.9557 2.26436 13 2.48695 13 2.71174C13 2.93653 12.9557 3.15912 12.8697 3.3668C12.7837 3.57447 12.6576 3.76317 12.4986 3.92212L4.32855 12.0922L1 13L1.90779 9.67145L10.0779 1.50136Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
												</svg>
											</span>
											<span class="custom-font-open-12-400 ml-1">
												Editar (Re)Ações
											</span>                                        
										</b-dropdown-item>                                      
										<b-dropdown-item  @click="editDepartments(key.keywordId, key.departmentAll)">
											<span>
												<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M10.0779 1.50136C10.2368 1.34241 10.4255 1.21632 10.6332 1.1303C10.8409 1.04428 11.0635 1 11.2883 1C11.513 1 11.7356 1.04428 11.9433 1.1303C12.151 1.21632 12.3397 1.34241 12.4986 1.50136C12.6576 1.66031 12.7837 1.84901 12.8697 2.05669C12.9557 2.26436 13 2.48695 13 2.71174C13 2.93653 12.9557 3.15912 12.8697 3.3668C12.7837 3.57447 12.6576 3.76317 12.4986 3.92212L4.32855 12.0922L1 13L1.90779 9.67145L10.0779 1.50136Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
												</svg>
											</span>
											<span class="custom-font-open-12-400 ml-1">
												Editar departamentos
											</span>                                        
										</b-dropdown-item> 
										<b-dropdown-item @click="setAutomationDelete (key.keywordId, key.keywordsList)">
											<span>
												<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M3 4H4.33333H15" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M6.3335 4.00004V2.66671C6.3335 2.31309 6.47397 1.97395 6.72402 1.7239C6.97407 1.47385 7.31321 1.33337 7.66683 1.33337H10.3335C10.6871 1.33337 11.0263 1.47385 11.2763 1.7239C11.5264 1.97395 11.6668 2.31309 11.6668 2.66671V4.00004M13.6668 4.00004V13.3334C13.6668 13.687 13.5264 14.0261 13.2763 14.2762C13.0263 14.5262 12.6871 14.6667 12.3335 14.6667H5.66683C5.31321 14.6667 4.97407 14.5262 4.72402 14.2762C4.47397 14.0261 4.3335 13.687 4.3335 13.3334V4.00004H13.6668Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M7.6665 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M10.3335 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
												</svg>
											</span>
											<span class="custom-font-open-12-400 ml-1">
												Excluir palavras chave
											</span>                                        
										</b-dropdown-item>                                                                                                                                                
									</b-dropdown>           
								</b-td>                           
							</b-tr>
						</b-tbody>
					</b-table-simple>  
				</div>
			</b-col>   
		</b-row>
		<b-row v-show="rows > perPage" class="pb-5">
			<b-col md="4" class="mt-2">
				<b-row>
					<b-col md="7" class="mt-1">
						<span class="mt-2 lblPagination">Visualizar por página:</span> 
					</b-col>
					<b-col md="4">                                                 
						<b-form-select v-model="selected" :options="perPageOptions" size="sm" @change="setPerPage()"></b-form-select>                
					</b-col>
				</b-row>
			</b-col>
			<b-col class="mr-5">
				<b-row class="d-flex justify-content-end">
					<b-pagination
						v-model="currentPage"
						:total-rows="rows"
						:per-page="perPage"
						pills
						size="sm"
						aria-controls="my-table"
					></b-pagination> 
				</b-row>
			</b-col>
        
		</b-row>           
	</b-col>    
</template>

<script>
import Loading from '@/views/Loading'
import WAutomationAPI from '@/api/WAutomationAPI'
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import Vue2Filters from 'vue2-filters'
import WModal from '@/views/WModal'
import SearchCheckBoxFilterVue from '../../filters/SearchCheckBoxFilter.vue'
export default {
	mixins: [Vue2Filters.mixin],
	components: {
		loading: Loading,    
		'automation-modal-delete': WModal,
		'search-check-box-filter': SearchCheckBoxFilterVue
	},
	name: 'WAutomationList',
	data: function () {
		return {
			loading: false,
			filter: '',
			keywordId: '',
			automationList: [],   
			originalAutomationList: [],
			filteredList: [],
			showModalDelete: false,                    
			perPage: 20,
			selected: 20,
			currentPage: 1,  
			totalPage: 1,  
			paginatedItems: [], 
			perPageOptions: [20, 50, 100, 200, 500],  
			automationName: '',  
			componentDeleteModalKey: 0,
			departmentList: []                                          
		}
	},
	computed: {
		rows () {
			return this.automationList.length
		},
		lists () {
			const items = this.automationList
			return items.slice(
				(this.currentPage - 1) * this.perPage,
				this.currentPage * this.perPage
			)
		},      
	},    
	methods: {                      
		getAutomationList () {
			this.$store.commit('setShowLoading', true)
			WAutomationAPI.getListKeywords(this.$route.params.companyId, response => {
				let automationList = response.data
				this.$store.commit('setShowLoading', false)
				automationList.forEach(element => {
					element.visible = true
				})
				this.automationList = automationList 
				this.originalAutomationList = this.automationList				
			})
		},
		getDepartmentsList () {
			WDepartmentsAPI.getDepartmentsOfCompany (
				this.$route.params.companyId, response => {
					let resp = response.data
					resp.forEach(item => {
						let dpt = {
							text: item.departmentName,
							value: item.departmentId
						}
						this.departmentList.push(dpt)
					})
				}
			)
		},    
		create () {
			let obj = {step: 2}
			this.$emit('sendMessageData', obj)    
		},
		setPerPage () {
			this.perPage = this.selected
		},
		searchAutomations () {
			let automationList = this.originalAutomationList
			if (this.filteredList.length !== 0){
				automationList = this.filteredList
			}
			this.automationList = []            
			automationList.forEach( list => {
				list
				if(!list){
					console.log('sem keywordid')
				}
				list.keywordsList.forEach(el => {
					if ( el.toLowerCase().includes(this.filter.toLowerCase()) ){
						if(this.automationList.includes(list)){
							return
						}
						list.visible = true
						this.automationList.push(list)
					} else {
						list.visible = false
					}                    
				})
			})
		},   

		setAutomationDelete (keywordId, name) {  
			this.automationName = ''
			this.keywordId = keywordId
			this.componentDeleteModalKey ++
			this.showModalDelete = true 
			name.forEach(el => {
				this.automationName += el + ','
			})
			this.automationName = this.automationName.substring(0, this.automationName.length -1)
		},   

		deleteAutomation (deleteKeywords) {
			this.showModalDelete = false
			if (deleteKeywords){
				WAutomationAPI.deleteKeyword(this.$route.params.companyId, this.keywordId, response => {
					if (response){
						this.getAutomationList()                      
					} else {
						alert('Falha ao excluir palavras chave!')
					}                                       
				})                
			}
		},                     
		editKeywords (keywordId) {
			let objKeyword = {keywordId: keywordId}
			localStorage.removeItem('__wpp_automation_keyword_obj')
			localStorage.setItem('__wpp_automation_keyword_obj', JSON.stringify(objKeyword))                     
			let obj = {step: 2.1}
			this.$emit('sendMessageData', obj)    
		}, 
		editReaction (keywordId) {
			let objKeyword = {keywordId: keywordId, step: 5.1}
			localStorage.removeItem('__wpp_automation_keyword_obj')
			localStorage.setItem('__wpp_automation_keyword_obj', JSON.stringify(objKeyword))                                  
			let obj = {step: 5.1}
			this.$emit('sendMessageData', obj)    
		},               
		editDepartments (keywordId, departmentAll ) {
			let objKeyword = {keywordId: keywordId, checked: departmentAll }
			localStorage.removeItem('__wpp_automation_keyword_obj')
			localStorage.setItem('__wpp_automation_keyword_obj', JSON.stringify(objKeyword))                     
			let obj = {step: 7}
			this.$emit('sendMessageData', obj) 
		},

		setSelectedItems (list) {
			if (list != '' && list != undefined) {
				this.automationList = []
				let automationList = []
				this.originalAutomationList.forEach(element => {
					console.log(element.departmentId)
					let departmentIdList = element.departmentIdList
					for (const key in departmentIdList) {					
						let departmentId = departmentIdList[key]
						for (const index in list) {
							let id = list[index]
							if (id == departmentId) {
								automationList.push(element) 													
							}					
						}
					}
				})		
				const seen = new Set()
				const filteredList = automationList.filter(el => {
					const duplicate = seen.has(el.keywordId)
					seen.add(el.keywordId)
					return !duplicate
				})	
				this.automationList = filteredList					
			}	
		},
		clearFilter () {
			if(this.filter == ''){
				this.searchAutomations()
			}
		}
	},      
	mounted () {
		this.getAutomationList()
		this.getDepartmentsList()
	}
}
</script>

<style scoped>
.custom-select {
    width: auto;
}
u {
    cursor: pointer;
}
th {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #999999 !important;    
}

td {
    margin: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #777777; 
    height: 50px;
    vertical-align: middle !important;
}
.td-automation {
    margin-top: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px; 
    color: #777777;
}

.td-phonenumber {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;  
}
.td-date {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-data {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-email {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}
.td-property {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}

.td-target {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-fail {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-deliver {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.td-readed {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}
.sidebar-right-automations {
    display: flex !important;
    width: 100% !important;
    flex-direction: column !important;
    background: white !important;
    padding: 10px;
    margin-right: 50px;
}
.custom-pagination-input {
    width: 50px !important;
}
.custom-pagination-select {
    width: 60px !important;
}

.keyword-list-scrollable {
    height: 90vh;
	width: 98%;
	background: #fff;
	overflow-y: auto;
	overflow-x: hidden;
}
.lblPagination {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px; 
    color: #777777;   
}

.keyword-tag {
    padding: 5px;
    background: #F5F5F5;
    border-radius: 100px;    
}

.form-control:focus {
    background-color: #F5F5F5;
    box-shadow: none;
}
</style>
