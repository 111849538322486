<template>
	<div>
		<b-modal 
			v-model="show"
			:title="title"
			centered
			scrollable>
			<template #modal-title>
				<span v-if="step == 1">Selecione um pixel, configurado na tela do Facebook, lá em Configurações.</span>
				<span v-if="step == 2">Selecione um evento para o Pixel configurado.</span>
			</template>
			<div class="scrollbar">
				<div v-if="step == 1" style="min-height:300px">
					<tableT v-if="pixelList" 
						:list="pixelList" 
						:fields="fieldsPixel" 
						:perPage="100" 
						:searchBar="true"
						:filterFields="['pixelName']"
						:searchPlaceHolder = "'Busque pelo nome'"
						:sortBy ="''"
						:selected="10"
						:disablePagination="true"
						@radioClicked="radioClicked"
					/>
				</div>     
				<div class="ml-3" v-else-if="step == 2">
					<div class="mb-1">
						<span class="custom-font-open-14-400-gray">Nome/Descrição do Pixel:</span>
						<br>
						<span class="custom-font-open-12-400">{{selectedPixel.pixelName}}</span>
					</div>
					<div class="mb-1">
						<span class="custom-font-open-14-400-gray">ID do Pixel:</span>
						<br>
						<span class="custom-font-open-12-400">{{selectedPixel.pixelId}}</span>
					</div>
					<div class="mb-5">
						<span class="custom-font-open-14-400-gray">Nome do Evento:</span>
						<br>
						<b-form-select v-model="selectedEvent" :options="eventList" style="width:200px"></b-form-select>
					</div>
				</div>  
			</div>
			<template #modal-footer>
				<div class="w-100">
					
					<b-button variant="outline-primary" class="float-left" @click="hide">
						Cancelar
					</b-button>  
					<b-button variant="primary" class="float-right" @click="next" :disabled="((step == 1 && !selectedPixel||step == 2 && !selectedEvent) )">
						{{step==1?'Avançar':'Escolher'}}
					</b-button>
				</div>
			</template>
		</b-modal> 
	</div>
</template>

<script>
import WFacebookAPI from '@/api/WFacebookAPI'
import Table from '@/components/global/TableComponent/Table'
export default {
	components:{
		tableT:Table,
		
	}, 
	data () {
		return {	
			pixelList:undefined,
			fieldsPixel: [
				{
					key:'radio',
					label:''
				},
				{
					key: 'pixelName',
					sortable: true,
					label:'Nome/Descrição do Pixel'
					
				},
				{
					key: 'pixelId',
					sortable: true,
					label:'Pixel ID'
				},
			],
			selectedPixel:undefined,
			step: 1,
			eventList:[],
			selectedEvent:undefined,
			show:true
		}
	},
	watch:{
		selectedEvent () {
			let aux = {id: this.selectedPixel.pixelId,step: 1}
			this.$emit('sendMessageData', aux)
		}
	},
	methods: {
		hide () {
			this.$emit('hide')
		},
		next () {
			if(this.step == 1){
				this.step = 2
			}else{
				// let propertyMap = []
				// for (const [key, value] of Object.entries(this.properties)){
				// 	key
				// 	propertyMap.push(value)                                                             
				// }            
				// let objMap = new Object()
				// objMap = {keyValueList: propertyMap}
				let pixel = 
				{ 
					pixelId: this.selectedPixel.pixelId,
					eventName: this.selectedEvent,
				}
				let obj = {file: null, mediaType: 'pixel', pixel}
				this.$emit('sendMessageData', obj) 
				// this.show =false  
			}
		},
		radioClicked (obj) {
			this.selectedPixel = obj
			this.eventList = this.selectedPixel.eventList
			// this.step = 2
		},
		getPixelList () {
			this.loading = true
			this.pixelList = undefined
			WFacebookAPI.getPixelListApi(this.$route.params.companyId,response=>{
				this.pixelList = response.data
				// for(let aux=0; aux < 30;aux ++){
				// 	this.pixelList.push(this.pixelList[0])
				// }
				
				this.loading = false
			})
		},
	},
	mounted () {  
		this.getPixelList()  
	}, 
}
</script>

<style>

</style>