<template>
	<div class="attendance-scrollable scrollbar mt-4">
		<b-col md="12" class="pb-5 ml-1">
			<b-overlay po :show="loading" variant="light"
				no-wrap>
				<template #overlay>
					<b-spinner style="width: 3rem; height: 3rem; color: #21065c" label="Large Spinner"></b-spinner>
				</template>                    
			</b-overlay>    
			<b-col>
				<b-row>		
					<h1 class="lbl-title-custom mt-1">
						Painel Geral - Estatísticas dos Atendimentos
					</h1>	
				</b-row>				
				<!-- componente period0 -->	
				<b-row>
					<b-col md="2">
						<b-row class="mt-3">
							<span class="select-custom-font">
								Selecione departamento:
							</span>							
						</b-row>
						<b-row>
							<search-check-box-filter
								:departmentFilter="true"
								:sortBy="'text'"
								:toggleClass="'pannel-toggle-dropdown-style'"
								:placeHolder="'Departamentos'"
								v-on:checkedItems="setSelectedItems($event)"
							/>
						</b-row>
					</b-col>					
					<b-col md="auto" class="ml-4">
						<dashboard-period :key="componentKey" v-on:sendMessageData="getMessageData"/>
					</b-col>
					<b-col md="auto" v-show="showDataFilter == true">
						<b-row class="mt-3">
							<!-- <span class="select-custom-font ml-1">
								Período máximo 3 meses:
							</span> -->
							<span class="select-custom-font ml-1">
								Período máximo 2 meses:
							</span>							
						</b-row>
						<b-row>
							<b-dropdown
								:text="selectedPeriod"
								ref="calendar"
								variant="ligth"
							>
								<v-date-picker
									v-model="range"
									mode="date"
									is-range																					
									:columns="$screens({ default: 1, lg: 3 })"
									:is-expanded="$screens({ default: true, lg: false })"
									:min-date='minTime'
									:max-date='maxTime'
									@input="setHide"
									@dayclick="setMinMaxTime"
									@popoverDidShow="clearTime"
									locale = "PT-br"
									color="blue"									
								/>							
							</b-dropdown>
							<b-icon v-show="showIcon == true" class="m-2 icon-clickable" icon="x-circle" scale="1.5" variant="danger" v-b-tooltip.hover title="Clique para limpar" @click="clearTime"></b-icon>							
						</b-row>					
					</b-col>
				</b-row>							
				<b-row  class="block-template-custom mt-3 mr-2">
					<b-col>
						<b-row class="ml-2">
							<span class="custom-font-open-14-gray mt-2">
								HISTÓRICO DE ATENDIMENTOS
							</span>
							<popover class="mt-1 ml-2" :id="'showPopHelpHistory'" :message="'Todos os atendimentos feitos no período selecionado. Os dados referentes ao dia de hoje não estão sendo considerados. <br> <br> Para o dia atual, utilize a aba <b>Tempo Real.</b>'" :btnText="'Entendi!'"/>
						</b-row>
						<b-row style="margin-right: 15px; margin-left: 17px;">
							<b-col>
								<b-row :class="['justify-content-end mr-1', this.$mq != 'md' ? 'justify-content-end mr-4' : '' ]">
									<span class="custom-font-open-12-gray-2">
										Abertos
									</span>	
								</b-row>
								<b-row :class="['justify-content-end mr-1', this.$mq != 'md' ? 'justify-content-end mr-4' : '' ]">
									<span :class="[ 'custom-font-roboto-36', $mq == 'md' ? 'custom-font-roboto-34' : '' ]">
										{{attendanceData.openCount}}
									</span>	
								</b-row>					
							</b-col>												
							<b-col>
								<b-row :class="['justify-content-end mr-1', this.$mq != 'md' ? 'justify-content-end mr-4' : '' ]">
									<span class="custom-font-open-12-gray-2">
										Pendentes
									</span>	
								</b-row>
								<b-row :class="['justify-content-end mr-1', this.$mq != 'md' ? 'justify-content-end mr-4' : '' ]">
									<span :class="[ 'custom-font-roboto-36', $mq == 'md' ? 'custom-font-roboto-34' : '' ]">
										{{attendanceData.pendingCount}}
									</span>	
								</b-row>						
							</b-col>
							<b-col>
								<b-row :class="['justify-content-end mr-1', this.$mq != 'md' ? 'justify-content-end mr-4' : '' ]">
									<span class="custom-font-open-12-gray-2">
										Fechados
									</span>	
								</b-row>
								<b-row :class="['justify-content-end mr-1', this.$mq != 'md' ? 'justify-content-end mr-4' : '' ]">
									<span :class="[ 'custom-font-roboto-36', $mq == 'md' ? 'custom-font-roboto-34' : '' ]">
										{{attendanceData.closedCount}}
									</span>	
								</b-row>					
							</b-col>
							<b-col>
								<b-row :class="['justify-content-end mr-1', this.$mq != 'md' ? 'justify-content-end mr-4' : '' ]">
									<span class="custom-font-open-12-gray-2">
										Expirados
									</span>	
								</b-row>
								<b-row :class="['justify-content-end mr-1', this.$mq != 'md' ? 'justify-content-end mr-4' : '' ]">
									<span :class="[ 'custom-font-roboto-36', $mq == 'md' ? 'custom-font-roboto-34' : '' ]">
										{{attendanceData.expiredCount}}
									</span>	
								</b-row>					
							</b-col>
							<b-col>
								<b-row :class="['justify-content-end mr-1', this.$mq != 'md' ? 'justify-content-end mr-4' : '' ]">
									<span class="custom-font-open-12-gray-2">
										Fechado pelo Bot
									</span>	
								</b-row>
								<b-row :class="['justify-content-end mr-1', this.$mq != 'md' ? 'justify-content-end mr-4' : '' ]">
									<span :class="[ 'custom-font-roboto-36', $mq == 'md' ? 'custom-font-roboto-34' : '' ]">
										{{attendanceData.closedByBotCount}}
									</span>	
								</b-row>					
							</b-col>																												
						</b-row>									
					</b-col>								
				</b-row>	
				<b-row class="block-template-custom mt-3 mr-2">
					<b-col>
						<b-row class="ml-2" style="display:flex;align-items:flex-end">
							<span class="custom-font-open-14-gray mt-2">
								TOTAL DE MARCAÇÕES
							</span>
							<popover class="mt-1 ml-2" :id="'showPopHelpTotalLabel'" :message="'Todas as marcações e como elas estão sendo utilizadas na plataforma no período selecionado. <br> <br> <b>Dica:</b> Utilize as marcações para controlar os assuntos pelos quais seus contatos te procuram. Assim, um só atendimento pode ter vários assuntos sendo tratados.'" :btnText="'Entendi!'"/>							
							<export-button
								:departmentIdList="this.departmentIdList"
								:fileName="`marcacoes_${timeToDateAmerican(this.startTime)}-${timeToDateAmerican(this.endTime)}`"
								:startTime="this.startTime" 
								:endTime="this.endTime" 
								:currentPhoneNumber="currentPhoneNumber" 
								:dashboardTitle="'Atendimentos'"
								:cardResource="'attendance'" 
								:selectedCard="'Total De Marcações'"
								:reportType="1"/>
						</b-row>
						<b-row class="ml-2">
							<span class="custom-font-open-12-gray-2 mt-2">
								Veja abaixo suas marcações e como elas estão distribuidas entre seus contatos.
							</span>
						</b-row>						
						<b-row style="min-height: 300px;">
							<b-col md="5" class="mt-2">
								<div v-if="hasTotalLabel == true">
									<GChart
										:key="componentTotalLabelKey"
										:type="'PieChart'"
										:options="labelOptions"
										:collectionData="labelData"
									/> 
								</div>
								<div v-else class="m-5">
									<no-data-found/>
								</div>								
							</b-col>   	
							<b-col class="mt-3 mr-2">					
								<b-table-simple id="my-table" hover small responsive>
									<b-tbody>
										<b-tr :key="index" v-for="(x, index) in orderBy(attendanceData.labelTotalVOList, 'messageCount', -1)" v-show="x.index <7">
											<b-td class="td-data">
												<span class="ml-1 mr-1">
													<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M0.647582 8.40511L0.702352 1.62951C0.703679 1.45388 0.737848 1.28025 0.802908 1.11854C0.867968 0.956839 0.962642 0.810232 1.08152 0.687103C1.20039 0.563974 1.34113 0.466737 1.4957 0.400955C1.65026 0.335172 1.81562 0.302134 1.98231 0.303728L9.27913 0.364722C9.53738 0.366881 9.78414 0.477055 9.96511 0.671L13.7878 4.76773C13.9762 4.96963 13.9734 5.29435 13.7816 5.49302L9.89396 9.51984C9.70964 9.71076 9.46092 9.81673 9.20257 9.81441L1.90148 9.7489C1.56638 9.74484 1.24643 9.60124 1.01149 9.34946C0.776552 9.09768 0.645725 8.75818 0.647582 8.40511Z" :fill="x.labelColor"/>
													</svg>
												</span>
												{{x.labelName}}
											</b-td>                    
											<b-td class="td-data">{{x.messageCount}} mensagens</b-td>                     
											<b-td class="td-data">{{x.contactCount}} contatos</b-td>  
										</b-tr>
									</b-tbody>
								</b-table-simple>  
							</b-col>	
						</b-row>
					</b-col>
				</b-row>
				<b-row class="mt-3 mr-1 block-template-custom-gray">
					<b-col>
						<div class="block-template-custom mr-2" :style="cardStyle1">
							<b-row style="margin-left: 15px;display:flex;align-items:flex-end">
								<span class="custom-font-open-14-gray mt-2" >
									TOP 10 MARCAÇÕES
								</span>			
								<popover class="mt-1 ml-2" :id="'showPopHelpTopLabel'" :message="'As 10 principais marcações da sua conta, que podem ser filtradas por departamentos ou período. <br> <br> <b>Dica:</b> Para uma análise aprofundada e de todas as marcações, exporte a lista.'" :btnText="'Entendi!'"/>																			
								<export-button
									:departmentIdList="this.departmentIdList"
									:fileName="`marcacoes_${timeToDateAmerican(this.startTime)}-${timeToDateAmerican(this.endTime)}`"
									:startTime="this.startTime" 
									:endTime="this.endTime" 
									:currentPhoneNumber="currentPhoneNumber" 
									:dashboardTitle="'Atendimentos'"
									:cardResource="'attendance'"
									:selectedCard="'Top 10 Marcações'"
									:reportType="1"/>
							</b-row>
							<b-row v-if="hasTopLabel == true" style="margin-left: 15px;">
								<div class="chart-responsive">
									<GChart
										:key="componentLabelKey"
										:type="'BarChart'"
										:options="labelTopOptions"
										:collectionData="labelTopData"
									/> 
								</div>
							</b-row>
							<b-row v-else class="m-5">
								<no-data-found/>
							</b-row>
						</div>

					</b-col>  				
					<b-col>
						<div class="block-template-custom" :style="cardStyle2">
							<b-row style="margin-left: 15px;display:flex;align-items:flex-end">
								<span class="custom-font-open-14-gray mt-2" >
									TOP 10 PROPRIEDADES
								</span>		
								<popover class="mt-1 ml-2" :id="'showPopHelpTopProperties'" :message="'As 10 principais propriedades da sua conta, que podem ser filtradas por departamentos ou período. <br> <br> <b>Dica:</b> Para uma análise aprofundada e de todas as propriedades e os valores atribuídos, exporte a lista.'" :btnText="'Entendi!'"/>						
								<export-button
									:departmentIdList="this.departmentIdList"
									:fileName="`propriedades_${timeToDateAmerican(this.startTime)}-${timeToDateAmerican(this.endTime)}`"
									:startTime="this.startTime" 
									:endTime="this.endTime" 
									:currentPhoneNumber="currentPhoneNumber" 
									:dashboardTitle="'Atendimentos'"
									:cardResource="'attendance'"
									:selectedCard="'Top 10 propriedes'"
									:reportType="2"/>
							</b-row>	
							<b-row v-if="hasTopProperty == true" style="margin-left: 15px;">
								<div class="chart-responsive">
									<GChart
										:key="componentPropertyKey"
										:type="'BarChart'"
										:options="propertyTopOptions"
										:collectionData="propertyTopData"
									/> 		
								</div>				
							</b-row>	
							<b-row v-else class="m-5">
								<no-data-found/>
							</b-row>
						</div>
				
					</b-col>   	
				</b-row>

				<b-row class="block-template-custom mt-3 mr-2" style="min-height:380px; max-height:380px">
					<b-col>
						<b-row class="ml-2 mt-2" style="display:flex;align-items:flex-end">
							<span class="custom-font-open-14-gray">
								TOTAL DE ATENDIMENTOS
							</span>											
							<popover class="ml-2" :id="'showPopHelpAttendance'" :message="'Veja aqui o total de atendimentos realizados na plataforma e podendo filtrá-los por departamento ou período. <br> <br> Para um controle mais eficiente, crie agrupamentos dos contatos por empresa, ou produto o afins, tudo em Configurações.'" :btnText="'Entendi!'" style="margin-top: -3px;"/>
							<export-button
								:departmentIdList="this.departmentIdList"
								:fileName="`atendimentos_contato_${timeToDateAmerican(this.startTime)}-${timeToDateAmerican(this.endTime)}`"
								:startTime="this.startTime" 
								:endTime="this.endTime" 
								:currentPhoneNumber="currentPhoneNumber" 
								:dashboardTitle="'Atendimentos'"
								:cardResource="'attendance'" 
								:selectedCard="'Total De Atendimentos'"
								:reportType="3"/>						
						</b-row>
						<div v-if="hasAttendanceList == true" class="ml-2 mr-2">
							<b-table 
								id="attendanceTable"
								responsive								
								class="mb-2"	
								style="min-height:300px; max-height:300px; overflow-x: hidden; overflow-y: hidden;"						
								:items="orderedAttendanceList"
								:fields="attendanceFields"
								:per-page="perPage"
								:current-page="currentPage"	
								:sort-by="sortBy"
								:sort-desc="true"	
								fixed																	
								@head-clicked="rowClickHandler">
								<template #cell(contactName)="data">																		
									<div class="contact-name-ellipsis" v-b-tooltip.hover :title="data.item.contactName" @click="openContactDetail(data.item.contactId, data.item.waId)">
										<u class="icon-clickable">{{data.item.contactName}}</u>
									</div>																														
								</template>																																	
							</b-table>																								
							<b-row v-show="rows > perPage" class="d-flex justify-content-center mb-3">				
								<b-pagination
									v-model="currentPage"
									:total-rows="rows"
									:per-page="perPage"
									pills
									size="sm"
									aria-controls="attendanceTable"
								></b-pagination> 			       
							</b-row> 	
						</div>
						<div v-else class="m-5">
							<no-data-found/>
						</div>														  
					</b-col>
				</b-row>		
			</b-col>	
		</b-col>
	</div>
</template>
<script>
import ConverterDateTime from '@/mixins/ConverterDateTime'
import WDashboardAPI from '@/api/WDashboardAPI'
import GChart from './WDashboardChart'
import WDashboardPeriod from './WDashboardPeriod'
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import SearchCheckBoxFilterVue from '@/components/filters/SearchCheckBoxFilter'
import NoDataFound from '@/components/global/NoDataFound'
import Popover from '@/components/global/Popover'
import Vue2Filters from 'vue2-filters'
import WDashboardExportButton from './WDashboardExportButton'
export default {
	name: 'WDashboardAttendance',
	components: {
		GChart,
		'dashboard-period': WDashboardPeriod,
		'search-check-box-filter': SearchCheckBoxFilterVue,	
		'no-data-found': NoDataFound,
		popover: Popover,
		'export-button': WDashboardExportButton
	},	
	props:[
		'currentPhoneNumber'
	],
	mixins: [ConverterDateTime, Vue2Filters.mixin],	
	data () {
		return {            
			loading: false,
			showIcon: false,
			cardActive: 1,
			selectedDateTime: 'Últimos 7 dias',
			attendanceData: [],
			startTime: 0,
			endTime: 0,
			showDataFilter: false,
			selectedPeriod: 'Selecionar período',	
			range: {
				start: '',
				end: '',
			},
			attendanceList: [],
			perPage: 6,
			currentPage: 1,  	
			labelTotalVOList: [],
			departmentList: [],
			labelOptions: {
				height: 300,	
				legend: 'none',
				chartArea:{left:0, right:0, bottom:20, top:0, width:50, width_units: '%'},				
				tooltip: { isHtml: true,
					textStyle: {
						color: '#777777',    
						fontName: 'Open Sans',
						fontSize: 12,
						fontWeight: 700,					
					},								
				},																						
			},
			labelData: [],
			labelTopData: [],			
			labelTopOptions: {
				width: '100%',
				height: '100%',				
				legend: 'none',	
				chartArea:{left:0, bottom:20, top:20},				
				tooltip: { isHtml: true,
					textStyle: {
						color: '#777777',    
						fontName: 'Open Sans',
						fontSize: 12,
						fontWeight: 700,					
					},								
				},		
				bar: { groupWidth: '80%' },
				hAxis: {
					gridlines: {
						interval: 0
					},      
					baselineColor: 'transparent',  
					textPosition: 'none'
				},	 
				vAxis:{
					textPosition: 'none'
				},			
				annotations: {
					alwaysOutside: true,
					textStyle: {
						color: '#777777',    
						fontName: 'Open Sans',
						fontSize: 10,
						fontWeight: 700,	
					},				
				}							
			},	
			propertyTopData: [],	
			propertyTopOptions: {				
				width: '100%',
				height: '100%',										
				legend: 'none',
				chartArea:{left:0, bottom:20, top:20},				
				tooltip: { isHtml: true,
					textStyle: {
						color: '#777777',    
						fontName: 'Open Sans',
						fontSize: 12,
						fontWeight: 700,					
					},								
				},		
				bar: { groupWidth: '80%' },
				hAxis: {
					gridlines: {
						interval: 0
					},      
					baselineColor: 'transparent', 
				},	 
				vAxis:{
					textPosition: 'none',
				},				
				annotations: {
					alwaysOutside: true,
					textStyle: {
						color: '#777777',    
						fontName: 'Open Sans',
						fontSize: 10,
						fontWeight: 700,	
					}
				}	  					
			},					
			sortBy: 'totalCount',
			attendanceFields: [
				{
					key: 'contactName',
					label: 'Contatos',
					sortable: true,					
					thStyle: '',
					thClass: 'b-th-data-center',
					tdClass: 'b-td-data-center'
				},
				{					
					key: 'totalCount',
					label: 'Total',					
					sortable: true,					
					thStyle: '',
					thClass: 'b-th-data-center',
					tdClass: 'b-td-data-center'			
				},				
				{					
					key: 'closedCount',
					label: 'Fechados',					
					sortable: true,					
					thStyle: '',
					thClass: 'b-th-data-center',
					tdClass: 'b-td-data-center'			
				},
				{
					key: 'openCount',
					label: 'Abertos',
					sortable: true,
					thStyle: '',
					thClass: 'b-th-data-center',
					tdClass: 'b-td-data-center'
				},
				{					
					key: 'expiredCount',
					label: 'Expirados',					
					sortable: true,
					thStyle: '',
					thClass: 'b-th-data-center',
					tdClass: 'b-td-data-center'
				}								
			],			
			minTime: '',
			maxTime: new Date(),	
			firstClick: true,
			componentKey: 0,
			departmentIdList: [],
			hasTotalLabel: false,
			hasTopLabel: false,
			hasTopProperty: false,
			hasAttendanceList: false,
			componentLabelKey: 0,
			componentPropertyKey: 0,
			componentTotalLabelKey: 0,
			heightCardBar1: 320,	
			heightCardBar2: 320	
		}
	},  
	computed: {		
		rows () {
			return this.attendanceList.length
		},		
		orderedAttendanceList () {
			let items = this.attendanceList							
			return items						
		},	
		cardStyle1 () {
			return {
				'margin-left': '-6px',
				'min-height': `${this.heightCardBar1}px`
			}
		},	
		cardStyle2 () {
			return {
				'min-height': `${this.heightCardBar2}px`
			}
		}								 	                  
	},  	 
	methods: {
		onResize () {
			this.renderChartData(2)
			this.renderChartData(3)	
		},		
		rowClickHandler (record) {
			this.attendanceFields.forEach(el => {
				if (el.key == record) {
					el.thStyle = 'color: black !important; font-size: 13px !important;'
				} else {
					el.thStyle = ''
				}
			})
		},					
		clearTime () {
			this.selectedPeriod = 'Selecionar período'
			this.minTime = ''
			this.maxTime = new Date()
			this.range = []
			this.showIcon = false
			this.firstClick = true
		},
		setMinMaxTime (day) {
			let today = new Date().setHours(23,59,59,999)
			this.firstClick = false
			this.showIcon = true
			this.minTime = day.range.start		
			let startTime = new Date(day.range.start).setHours(0,0,0,0)	
			this.minTime = startTime
			//let endTime = startTime + 90*86400000	
			let endTime = startTime + 60*86400000	
			if (endTime > today) {
				this.maxTime = today
			} else {
				this.maxTime = endTime
			}												
		},
		setHide () {	
			this.firstClick = true		
			let startTime = new Date(this.range.start).setHours(0,0,0,0)
			let endTime = new Date(this.range.end).setHours(23,59,59,999)
			this.selectedPeriod = this.timeToDate(startTime) + '-' + this.timeToDate(endTime)
			this.getAttendanceData (startTime, endTime)	
			this.$refs.calendar.hide(true)	
			this.$nextTick(() => {
				this.minTime = ''
				this.maxTime = new Date()				
				this.range = []					
			})
		},	
		openContactDetail (contactId, waId) {
			let phonenumberId = this.currentPhoneNumber
			this.$router.push({ path: '/contact/'+ this.$route.params.companyId +'/'+contactId+'/' + phonenumberId + '/' +waId +'/dash/detail' })
		},		
		setPieChartData (data) {
			let collectionData = []
			let headerData = []			
			headerData.push('Marcação')													
			headerData.push('Total')					
			collectionData.push(headerData)		
			
			let colors = []
			data.forEach(el => {
				if (el.index < 7) {
					let item = []
					item.push(el.labelName )
					item.push(el.messageCount)					
					colors.push(el.labelColor)	
					collectionData.push(item)					
				}
			})	
			this.labelOptions.colors = colors
			return collectionData											
		},	
		setBarChartData (data, chart) {
			let collectionData = []
			let headerData = []		
			if (chart == 2) {		
				headerData.push('Marcação')													
				headerData.push('Total %')				
				headerData.push({ role: 'annotation' })
				headerData.push({ role: 'style' })
				headerData.push({ role: 'tooltip',p: {html: true}})										
			} else {				
				headerData.push('Propriedade')													
				headerData.push('Total %')	
				headerData.push({ role: 'annotation'})
				headerData.push({ role: 'style'})
				headerData.push({ role: 'tooltip', p: {html: true}})							
			}	
			collectionData.push(headerData)	
			let opacity = 1
			data.forEach(el => {
				if (el.index < 11) {				
					let item = []									
					item.push(el.label)
					item.push(el.value)							
					item.push(el.label)					
					let customColor = ''
					if (el.index <= 3) {
						let docStyle = getComputedStyle(document.documentElement)
						let primaryVar = docStyle.getPropertyValue('--primary')
						customColor = 'color: '+ primaryVar + ';opacity: 1;' 
						if (el.index > 1) {
							opacity = opacity -0.25
							customColor = 'color: '+ primaryVar + ';opacity:' + opacity + ';'
						} else {
							opacity = 1
						}											
					} 
					if (el.index > 3 && el.index <= 6) {
						let docStyle = getComputedStyle(document.documentElement)
						let secundaryVar = docStyle.getPropertyValue('--secondary')								
						customColor = 'color: '+ secundaryVar + ';opacity: 1;'		
						if (el.index > 4) {
							opacity = opacity -0.25
							customColor = 'color: '+ secundaryVar + ';opacity:' + opacity + '; width: 100%;'
						} else {
							opacity = 1
						}						
					}
					if (el.index > 6) {
						customColor = 'color: #414141; opacity: 1;'
						if (el.index > 7) {
							opacity = opacity -0.25
							customColor = 'color: #414141' + ';opacity:' + opacity + ';'
						} else {
							opacity = 1
						}							
					}										
					item.push(customColor)
					if (chart == 2) {
						item.push('<span style="line-height: 40px;">'
						+el.label+ '</span>' +
						'<br><hr style="height:1px;border-width:0;margin-top: 0em;margin-bottom: 0em;color:gray;background-color:gray"><span style="line-height: 40px;color: #777777; font-family: Open Sans;font-size: 12px,font-weight: 700"><strong>'
						+ el.value + '% (' + el.messageCount+ ' mensagens, '+
						el.contactCount+ ' contatos)</strong></span>')						
					} else {
						item.push('<span style="line-height: 40px;">'
						+el.label+ '</span>' +
						'<br><hr style="height:1px;border-width:0;margin-top: 0em;margin-bottom: 0em;color:gray;background-color:gray"><span style="line-height: 40px;color: #777777; font-family: Open Sans;font-size: 12px,font-weight: 700"><strong>'+ el.value + ' %</strong></span>')						
					}
					collectionData.push(item)
					// console.log(JSON.stringify(collectionData))
				}
			})	
			let chatHeight = collectionData.length * 33
			if (chart == 2) {
				this.labelTopOptions.height = chatHeight
				if (chatHeight < 320) {
					this.heightCardBar1 = 320
					this.heightCardBar2 = 320						
				} else {
					this.heightCardBar1 = chatHeight + 30
					this.heightCardBar2 = chatHeight + 30						
				}	
			} else {
				this.propertyTopOptions.height = chatHeight
			}			
			return collectionData											
		},			
		renderChartData (chart) {
			if (chart == 1) {
				this.componentTotalLabelKey ++
				this.labelData = null
				this.labelData = this.setPieChartData(this.attendanceData.labelTotalVOList)
			}
			if (chart == 2) {
				this.componentLabelKey ++
				this.labelTopData = null
				this.labelTopData = this.setBarChartData(this.attendanceData.labelTopVOList, chart)
			}
			if (chart == 3) {
				this.componentPropertyKey ++
				this.propertyTopData = null
				this.propertyTopData = this.setBarChartData(this.attendanceData.propertyTopVOList, chart)
			}	
		},		
		getMessageData (obj) {		
			if (obj == null) {
				this.showDataFilter = true				
			} else {
				this.showDataFilter = false
				this.getAttendanceData(obj.startTime, obj.endTime)
			}				
		},  	

		getDepartmentsList () {
			WDepartmentsAPI.getDepartmentsOfCompany (
				this.$route.params.companyId, response => {
					let resp = response.data
					resp.forEach(item => {
						let dpt = {
							text: item.departmentName,
							value: item.departmentId
						}
						this.departmentList.push(dpt)
					})
				}
			)
		},  
		setSelectedItems (list) {
			this.departmentIdList = list	
			this.getAttendanceData(this.startTime, this.endTime)						
		},						
		getAttendanceData (startTime, endTime) {
			this.startTime = startTime
			this.endTime = endTime
			console.log(this.timeToDate(startTime) + ' -- ' +this.timeToDate(endTime))	
			let phonenumberId = this.currentPhoneNumber
			this.loading = true
			let departmentIdList = JSON.stringify(this.departmentIdList)			
			WDashboardAPI.postAttendanceData(this.$route.params.companyId, phonenumberId, startTime, endTime, departmentIdList,  response => {				
				this.loading = false
				if (response.data) {
					this.attendanceData = response.data 
					this.attendanceList = this.attendanceData.attendanceList
					if (this.attendanceData.attendanceList.length > 0) {
						this.hasAttendanceList = true
					} else {
						this.hasAttendanceList = false
					}
					if (this.attendanceData.labelTopVOList.length > 0) {
						this.hasTopLabel = true
					} else {
						this.hasTopLabel = false
					}					
					if (this.attendanceData.labelTotalVOList.length > 0) {
						this.hasTotalLabel = true
					} else {
						this.hasTotalLabel = false
					}	
					if (this.attendanceData.propertyTopVOList.length > 0) {
						this.hasTopProperty = true
					} else {
						this.hasTopProperty = false
					}											
					this.renderChartData(1)
					this.renderChartData(2)
					this.renderChartData(3)		
					this.rowClickHandler(this.sortBy)								
				}								
			})   			
		},		
	},
	mounted () {
		console.log('this.currentPhoneNumber')
		console.log(this.currentPhoneNumber)
		window.addEventListener('resize', this.onResize)		
		let currentData = new Date()
		currentData.setHours(0,0,0,0)
		this.endTime = currentData.getTime()
		this.startTime = this.endTime - (7*86400000)			
		this.getAttendanceData(this.startTime, this.endTime)		
		this.getDepartmentsList()		
	}
}
</script>

<style scoped>
.attendance-scrollable {
    height: 90vh;
    width: 100%;
    overflow-y: 9;
    overflow-x: hidden;
}
.select-custom-font {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #21065C;
}

.table > tbody > tr:first-child > td {
    border: none;
}

td {
    margin: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #777777; 
    height: 42px !important;
    vertical-align: middle !important;

}
.td-data {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.contact-name-ellipsis { 
    white-space: nowrap !important;  
    overflow: hidden !important;
    text-overflow: ellipsis !important;
	width: 250px !important;
}

.block-template-custom-gray {
    min-height: 100px; 
	background: #f4f4f5;
}

.chart-responsive {
	width: 90%;
}
</style>
