<template>
	<b-col class="ml-5">  
		<div class="justify-content-center">			
			<b-row class="ml-1">
				<div class="image-upload">
					<label for="file-input" class="icon-clickable">
						<svg v-if="file == null && logoValidate == true"  width="92" height="87" viewBox="0 0 92 87" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="42" cy="42" r="42" fill="#C4C4C4"/>
							<path d="M26.5001 42.2114L13.9867 51.8752C13.2317 52.4583 13.644 53.6667 14.5979 53.6667H66.7853C67.6181 53.6667 68.086 52.7082 67.5738 52.0516L54.8905 35.7925C54.5496 35.3555 53.9183 35.2791 53.483 35.6222L34.9822 50.2057C34.5395 50.5547 33.8959 50.469 33.56 50.0162L27.9144 42.407C27.5808 41.9574 26.9431 41.8692 26.5001 42.2114Z" fill="#777777"/>
							<circle cx="36.1663" cy="31.4998" r="5.83333" fill="#777777"/>
							<g opacity="0.7" filter="url(#filter0_d)">
								<circle cx="73.5" cy="67.6665" r="14" fill="#F5F5F5"/>
							</g>
							<path d="M73.7295 73.333H79.7295" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M76.7295 62.3332C76.9947 62.0679 77.3544 61.9189 77.7295 61.9189C77.9152 61.9189 78.0991 61.9555 78.2707 62.0266C78.4423 62.0977 78.5982 62.2018 78.7295 62.3332C78.8608 62.4645 78.965 62.6204 79.0361 62.792C79.1071 62.9635 79.1437 63.1474 79.1437 63.3332C79.1437 63.5189 79.1071 63.7028 79.0361 63.8744C78.965 64.0459 78.8608 64.2018 78.7295 64.3332L70.3962 72.6665L67.7295 73.3332L68.3962 70.6665L76.7295 62.3332Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							<defs>
								<filter id="filter0_d" x="55.5" y="50.6665" width="36" height="36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix"/>
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
									<feOffset dy="1"/>
									<feGaussianBlur stdDeviation="2"/>
									<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
								</filter>
							</defs>
						</svg>  
						<div v-else>
							<b-avatar :src="logoPath" size="6rem"  title="saturate" class="saturate"></b-avatar>                            
							<svg class="mt-5" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M7 12.333H13" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M10 1.33316C10.2652 1.06794 10.6249 0.918945 11 0.918945C11.1857 0.918945 11.3696 0.955525 11.5412 1.0266C11.7128 1.09767 11.8687 1.20184 12 1.33316C12.1313 1.46448 12.2355 1.62038 12.3066 1.79196C12.3776 1.96354 12.4142 2.14744 12.4142 2.33316C12.4142 2.51888 12.3776 2.70277 12.3066 2.87435C12.2355 3.04594 12.1313 3.20184 12 3.33316L3.66667 11.6665L1 12.3332L1.66667 9.66649L10 1.33316Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>                                                  
						</div>                       
					</label>                
					<b-form-file id="file-input" accept=".jpg,.png" v-model="file" class="mt-3" @change="handleFileChange($event.target.files)" plain></b-form-file>
				</div>            
			</b-row>
			<b-row class="d-flex justify-content-start" >
				<label for="file-input">             
				</label>                
			</b-row>    
			<b-row>
				<span class="small-font">Máximo 5MB 640x640</span>
			</b-row>  			         
		</div>
	</b-col>
</template>

<script>
import { avatarUrl} from '@/global'
const MAX_SIZE = 5242880
export default {
	name: 'WPhoneNumberPhoto',
	props: ['mode'],
	data () {
		return {
			companyId: this.$route.params.companyId,
			phonenumberId: '',
			file: null,
			logoPath: '',
			mediaName: '',
			avtUrl: avatarUrl,   
			logoValidate:undefined,   
		}
	},    
	methods: {
		handleFileChange (file) {
			this.file =  file[0]   
			if(!this.file || this.file.type.indexOf('image/') !== 0) {
				alert('Formato inválido!')
				return
			} 	
			if(this.file.size > MAX_SIZE) {
				alert('Tamanho da imagem excede o limite máximo permitido de 5MB!')
				return
			}	
			let url = URL.createObjectURL(file[0])
			this.addImageProcess(url).then(img => {
				if(img.width < 192 || img.height < 192) {						
					alert('Altura e largura da imagem não pode ser inferior a 192px!')
					return
				} 
				if(img.width != img.height) {						
					alert('Altura e largura da imagem devem ser iguais!')
					return
				} 								
				this.mediaName = file[0].name    
				this.logoPath = url   
				if (this.mode == 'upd'){
					let obj = {userId: this.phonenumberId, avatar: this.logoPath}                    
					localStorage.removeItem('__wpp_settings_user_photo_'+ this.phonenumberId)
					localStorage.setItem('__wpp_settings_user_photo_'+ this.phonenumberId, JSON.stringify(obj))                 
				}                             
				const formData = new FormData()                        
				formData.append('filename', this.mediaName)
				formData.append('file', this.file)                        
				this.$emit('sendMessageData', formData)   				
			})								    
		},    
		addImageProcess (src) {
			return new Promise((resolve, reject) => {
				let img = new Image()
				img.onload = () => resolve({width: img.width, height: img.height})
				img.onerror = reject
				img.src = src
			})
		}			 	 
	},
	mounted () {
		if (this.mode == 'upd'){
			let objPhoneNumber = JSON.parse(localStorage.getItem('__wpp_settings_obj'))
			if (objPhoneNumber != null){
				this.phonenumberId = objPhoneNumber.phonenumberId 
			}              
			this.logoPath = this.avtUrl + this.companyId + '&imageId='+ this.phonenumberId
		}
		this.logoValidate = this.logoPath.includes('undefined')
	}, 
}
</script>

<style>

</style>
