<template>
	<div>
		<b-button v-b-toggle.sidebar-right variant="secondary"
			@click="create()">
			<span>
				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g clip-path="url(#clip0)">
						<path d="M10.6666 14V12.6667C10.6666 11.9594 10.3857 11.2811 9.88558 10.781C9.38548 10.281 8.7072 10 7.99996 10H3.33329C2.62605 10 1.94777 10.281 1.44767 10.781C0.947578 11.2811 0.666626 11.9594 0.666626 12.6667V14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M5.66667 7.33333C7.13943 7.33333 8.33333 6.13943 8.33333 4.66667C8.33333 3.19391 7.13943 2 5.66667 2C4.19391 2 3 3.19391 3 4.66667C3 6.13943 4.19391 7.33333 5.66667 7.33333Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M13.3334 5.33337V9.33337" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M15.3334 7.33337H11.3334" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</g>
					<defs>
						<clipPath id="clip0">
							<rect width="16" height="16" fill="white"/>
						</clipPath>
					</defs>
				</svg>
			</span>
			<span class="ml-1">
				Adicionar contato    
			</span>
        
		</b-button> 
		<b-sidebar 
			id="sidebar-right" 
			title="Adicionar Contato"
			width="380px"
			right
			shadow>
			<template #default="{ hide }">            
				<div class="m-3">
					<b-col md="12" sm="12" class="mt-3">
						<b-row>
							<b-col >
								<h4 class="lbl-title">Novo contato</h4>
							</b-col>                
						</b-row>
						<loading v-if="loading==true"/> 
						<b-form v-else>
							<b-row>  
								<b-col >
									<b-form-group label="Nome do contato" label-for="contact">
										<b-form-input id="contact" type="text"
											v-model="contactName" placeholder="Nome" 
											:autofocus="true"/>
									</b-form-group>                      
								</b-col>                                        
							</b-row>         
							<b-row>
								<b-col>
									<b-row>
										<b-col md="5">
											<country-code :key="componentCountryKey" :countryNumber="countryNumber" v-on:setCountryCode="getCountryCode"/>
										</b-col>                        
										<b-col md="7">
											<b-form-input
												id="dropdown-form-phoneNumber"
												type="text"
												placeholder="(47) 0000-00000"
												v-model="phoneNumber"
											></b-form-input>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
							<b-row class="mt-2">
								<b-col>
									<b-row class="ml-2">
										<b-col>
											<b-button variant="outline-primary" class="mr-2"
												@click="hide">Cancelar
											</b-button> 
										</b-col>
										<b-col>
											<b-button variant="primary" 
												@click="next()">Continuar
											</b-button> 
										</b-col>                          
									</b-row>
								</b-col>
							</b-row>                       
						</b-form>
					</b-col>
				</div>
			</template>
		</b-sidebar>                 
	</div>    
</template>

<script>
import Loading from '@/views/Loading'
import WCountryCode from '../settings/WCountryCode'
import WContactsAPI from '@/api/WContactsAPI'
export default {    
	name: 'WContact',  
	components: {
		loading: Loading,
		'country-code': WCountryCode                            
	},    
	data: function () {
		return {
			loading: false,
			contactName: '',
			countryNumber: '+55',
			phoneNumber: '',
			componentCountryKey: 0
		}
	},
	methods: {
		getCountryCode (code) {
			this.countryNumber = code
		},          
		next () {
			let phoneNumber = this.phoneNumber
			WContactsAPI.postContactWaIdOrRegister(this.$route.params.companyId, this.countryNumber.replace('+', ''), phoneNumber, this.contactName, response => {
				let waId = response.data    
				WContactsAPI.getContactDetail(this.$route.params.companyId, waId, response => {
					if (response){  
						let contactId = response.data.contactId  
						this.$router.push({ path: '/contact/'+ this.$route.params.companyId +'/'+contactId+'/'+ '0/' + waId +'/detail' })
					}
				})
			})               
		}, 
		cancel () {
			this.disable = true 
		}          
	},
	mounted () {
           
	}    
}
</script>

<style>

</style>

