<template>
	<div>
		<div class="mb-2">
			<div >
				<span class="mt-1 custom-font-open-14">Departamento atual:</span>
			</div>
			<div class="scrollbar mt-1">  
				<b-tooltip target="departmentName" triggers="hover" placement="bottom" noninteractive>
					<span class="custom-font-open-14-white">
						{{contactActive.departmentName}}
					</span>
                
				</b-tooltip> 
				<b-row style="width:300px;">
					<div style="width:185px" class="propertie-tag description-ellipsis" >
						<span id="departmentName" class="custom-font-open-14-gray-normal">{{contactActive.departmentName}}</span>   
					</div>
					<div style="background-color:#F5F5F5;border-top-right-radius: 25px;border-bottom-right-radius: 25px;padding: 4px 8px 4px 8px;">
					</div>
					<div v-show="disabled" class="ml-1" style="padding: 4px 8px 4px 8px;background-color:#F5F5F5;border-radius: 50%">
						<span style="cursor: pointer;" @click="show2 = true,getDepartmentList(false)">
							<svg class="float-right mt-1" width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M7 11.6667H12.25" stroke="#999999" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M9.625 2.04164C9.85706 1.80957 10.1718 1.6792 10.5 1.6792C10.6625 1.6792 10.8234 1.71121 10.9735 1.77339C11.1237 1.83558 11.2601 1.92673 11.375 2.04164C11.4899 2.15654 11.5811 2.29296 11.6432 2.44309C11.7054 2.59322 11.7374 2.75413 11.7374 2.91664C11.7374 3.07914 11.7054 3.24005 11.6432 3.39018C11.5811 3.54032 11.4899 3.67673 11.375 3.79164L4.08333 11.0833L1.75 11.6666L2.33333 9.3333L9.625 2.04164Z" stroke="#999999" stroke-linecap="round" stroke-linejoin="round"/>
							</svg> 
						</span> 
					</div>  
				</b-row>   
			</div>   
		</div>
		<!-- responsible -->
		<div class="mb-2">
			<div>
				<span class="mt-1 custom-font-open-14">Responsável Padrão:</span>
				<span class="ml-1" id="popover-help-sync" style="cursor: pointer;">
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M8.00004 14.6666C11.6819 14.6666 14.6667 11.6818 14.6667 7.99992C14.6667 4.31802 11.6819 1.33325 8.00004 1.33325C4.31814 1.33325 1.33337 4.31802 1.33337 7.99992C1.33337 11.6818 4.31814 14.6666 8.00004 14.6666Z" fill="#C4C4C4"/>
						<path d="M6.05994 6.00014C6.21667 5.55458 6.52604 5.17887 6.93324 4.93956C7.34044 4.70024 7.8192 4.61276 8.28472 4.69261C8.75024 4.77246 9.17248 5.01449 9.47666 5.37582C9.78083 5.73716 9.94731 6.19448 9.9466 6.6668C9.9466 8.00014 7.9466 8.6668 7.9466 8.6668" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M8 11.3333H8.00667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
                                
				</span>
				<b-popover :show.sync="showPopHelp" target="popover-help-sync" title="" placement="bottom">
					<div>
						Todas as conversas iniciadas por este contato serão redirecionadas por padrão para o usuário ou departamento selecionado
					</div>
                                
					<div>
						<a href="javascript:;" @click="showPopHelp=false" class="float-right" style="color: var(--primary);"><b>X</b></a>
					</div>                            
				</b-popover>                        
			</div>
			<div class="scrollbar mt-1">  
				<b-tooltip target="departmentCustom" triggers="hover" placement="bottom" noninteractive>
					<span class="custom-font-open-14-white">
						{{getDepartmentCustom}}
					</span>
                
				</b-tooltip> 
				<b-row style="width:300px;">
					<div style="width:185px" class="propertie-tag description-ellipsis" >
						<span id="departmentCustom" class="custom-font-open-14-gray-normal">{{getDepartmentCustom}}</span>   
					</div>
					<div style="background-color:#F5F5F5;border-top-right-radius: 25px;border-bottom-right-radius: 25px;padding: 4px 8px 4px 8px;">
						
					</div>
					<div v-show="disabled" class="ml-1" style="padding: 4px 8px 4px 8px;background-color:#F5F5F5;border-radius: 50%">
						<span style="cursor: pointer;" @click="show = true,getDepartmentList(true)">
							<svg class="float-right mt-1" width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M7 11.6667H12.25" stroke="#999999" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M9.625 2.04164C9.85706 1.80957 10.1718 1.6792 10.5 1.6792C10.6625 1.6792 10.8234 1.71121 10.9735 1.77339C11.1237 1.83558 11.2601 1.92673 11.375 2.04164C11.4899 2.15654 11.5811 2.29296 11.6432 2.44309C11.7054 2.59322 11.7374 2.75413 11.7374 2.91664C11.7374 3.07914 11.7054 3.24005 11.6432 3.39018C11.5811 3.54032 11.4899 3.67673 11.375 3.79164L4.08333 11.0833L1.75 11.6666L2.33333 9.3333L9.625 2.04164Z" stroke="#999999" stroke-linecap="round" stroke-linejoin="round"/>
							</svg> 
						</span>  
					</div>  
				</b-row>   
			</div>   
		</div>
		<!-- responsible -->
		<!-- modal -->
		<div class="department-default">
			<b-modal 
				v-model="show"
				:title="title"
				centered
				scrollable>
				<b-col v-show="step == 1">
					<b-row class="ml-2">
						<div>
							<b-form-checkbox v-model="checked" name="check-button" switch>
								Departamento padrão do sistema:  {{contactActive.agentOrDepartmentLeadDescr}}
							</b-form-checkbox>
						</div>                    
					</b-row>
					<div v-show="checked == false">
						<b-row class="ml-2 mt-3">
							<b>Atribuir outro responsável</b>
						</b-row>
						<b-row class="ml-2">
							<span class="custom-font-open-14">Escolha um dos departamentos abaixo para continuar</span>                    
						</b-row>
						<b-row class="ml-2 mr-2 mt-3">
							<b-input-group>
								<b-form-input 
									type="search"                     
									:placeholder="placeholder"
									v-model="filter"
									@keyup="searchDepartment" 
									class="mt-1"
									@update="clearFilter('departament')">
								</b-form-input>              
								<b-input-group-prepend is-text class="mt-1">
									<b-icon icon="search"></b-icon>
								</b-input-group-prepend>                    
							</b-input-group> 
						</b-row>
						<b-container fluid class="mt-3">           
							<div v-for="(x, index) in departments" :key="index" v-show="x.visible == true" :class="[ '', index == currentDepartmentIndex ? 'template-active' : '' ]">
								<b-col class="m-1 icon-clickable" @click="setDepartmentId2(index, x.departmentId, x.phonenumberId, x.departmentName)">
									<b-row class="ml-1">
										<label :for="x.departmentId" @change="setDepartmentId2(index, x.departmentId, x.phonenumberId, x.departmentName)">   
											<b-form-radio
												:id="x.departmentId"
												v-model="departmentId"
												:value="x.departmentId"          
												unchecked-value="0"
											>
												<span class="mylabel">{{x.departmentName}}</span> 
											</b-form-radio>    
										</label>                             

									</b-row>                     
								</b-col>
							</div>                                            
						</b-container>                    
					</div>                

				</b-col>
            
				<b-col v-show="step == 2">
					<b-row class="ml-2 mb-2">
						<div>
							<b-form-checkbox v-model="checked2" name="check-button" switch>
								Manter o departamento: {{departmentSelected}}
							</b-form-checkbox>
						</div>                    
					</b-row>
					<div v-show="checked2 == false">
						<b-row class="ml-2 mt-2">
							<span class="custom-font-open-14">Ou escolher usuário dentro desse departamento</span>                    
						</b-row>
						<b-row class="ml-2 mr-2 mt-2">   
							<b-input-group>
								<b-form-input 
									type="search"                     
									:placeholder="placeholder"
									v-model="filterAgents"
									@keyup="searchAgents" 
									class="mt-1"
									@update="clearFilter('agents')">
								</b-form-input>                  
								<b-input-group-prepend is-text class="mt-1">
									<b-icon icon="search"></b-icon>
								</b-input-group-prepend>                    
							</b-input-group> 
						</b-row>
						<b-container fluid class="mt-3">           
							<div v-for="(x, index) in agents" :key="index" v-show="x.visible == true" :class="[ '', index == currentIndex ? 'template-active' : '' ]">
								<b-col class="m-1 icon-clickable" @click="setAgentId(index, x.agentId)">
									<label :for="x.agentId" @change="setAgentId(index, x.agentId)"> 
										<div style="display:flex;align-items: center;">
											<b-form-radio
												class="mb-2"
												:id="x.agentId"
												v-model="agentId"
												:value="x.agentId"

												unchecked-value="0"
											>
											</b-form-radio>  
											<span class="avatar" :style="contactActive.avatar">
												{{x.name.substring(0,2).toUpperCase()}}
											</span> 
											<span class="mylabel ml-2">{{x.name}}</span>
										</div>  
									</label>                             

								</b-col>
							</div>                                            
						</b-container>
					</div>

				</b-col>            

				<template #modal-footer>
					<div class="w-100">
						<b-button v-show="step != 2" variant="primary" class="float-right" @click="nextStep()">
							Avançar
						</b-button>
						<b-button variant="outline-primary" class="float-left" @click="goBack()">
							Voltar
						</b-button>  
						<b-button v-show="step == 2" variant="primary" class="float-right" @click="save()"> 
							Salvar
						</b-button>                                                   
					</div>
				</template>
			</b-modal>  
		</div>
		<div class="department-stay-inbox">
			<b-modal 
				v-model="show2"
				title="Troca de departamento."
				centered
				scrollable>
				<b-col>
					<b-row class="ml-2">
						<span>Departamento atual:  {{contactActive.departmentName}}</span>
					</b-row>
					<div v-show="checked == false">
						<b-row class="ml-2 mt-3">
							<b>Atribuir outro responsável</b>
						</b-row>
						<b-row class="ml-2">
							<span class="custom-font-open-14">Escolha um dos departamentos abaixo.</span>                    
						</b-row>
						<b-row class="ml-2 mr-2 mt-3">
							<b-input-group>
								<b-form-input 
									type="search"                     
									:placeholder="placeholder"
									v-model="filter"
									@keyup="searchDepartment2" 
									class="mt-1"
									@update="clearFilter('departament')">
								</b-form-input>              
								<b-input-group-prepend is-text class="mt-1">
									<b-icon icon="search"></b-icon>
								</b-input-group-prepend>                    
							</b-input-group> 
						</b-row>
						<b-container fluid class="mt-3">           
							<div v-for="(x, index) in departments" :key="index" v-show="x.visible == true" :class="[ '', index == currentDepartmentIndex ? 'template-active' : '' ]">
								<b-col class="m-1 icon-clickable" @click="setDepartmentId(index, x.id)">
									<b-row class="ml-1">
										<label :for="x.id" @change="setDepartmentId(index, x.id)">   
											<b-form-radio
												:id="x.id"
												v-model="departmentId"
												:value="x.id"          
												unchecked-value="0"
											>
												<span class="mylabel">{{x.description}}</span> 
											</b-form-radio>    
										</label>                             

									</b-row>                     
								</b-col>
							</div>                                            
						</b-container>                    
					</div>                

				</b-col>
				<template #modal-footer>
					<div class="w-100">
						<b-button variant="primary" class="float-right" @click="save2()">
							Salvar
						</b-button>
						<b-button variant="outline-primary" class="float-left" @click="goBack()">
							Voltar
						</b-button>  
					</div>
				</template>
			</b-modal>  
		</div>
		<!-- modal -->
	</div>                                          
</template>

<script>
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import WSortBy from '@/js/utils/WSortBy'
import WPhoneNumberAPI from '@/api/WPhoneNumberAPI'
export default {
	name: 'WChatContactInformation',
	components:{
	},
	props: [
		'contactActive',
		'disabled'
	],
	computed:{
		disable () {
			if(this.disabled == true){
				return 'disable-component'
			}else{
				return ''
			}
		},
		getDepartmentCustom () {
			if(this.contactActive.agentOrDepartmentLeadCustom != 1){
				return this.contactActive.agentOrDepartmentLeadDescr
			}else{
				return this.contactActive.departmentName
			}
		}
	},
	data () {
		return { 
			show: false,
			showPopHelp: false,
			checked:false,
			step: 1,
			checked2: false,
			title: 'Responsável por este contato',
			placeholder: 'Buscar departamento',
			filter: '',
			filterAgents: '',
			selected: '',
			departmentId: null,
			departments: [],
			agentId: '',
			agents: [],
			currentIndex: null,
			currentDepartmentIndex: null, 
			companyId: this.$route.params.companyId,
			departmentSelected: this.agentOrDepartmentLeadDescription,
			departmentTransfer: false,
			show2:false
		}
	},    
   
	methods: { 
		save2 () {
			if(!this.departmentId){
				alert('Selecione uma opção!')
				return
			}
			WPhoneNumberAPI.postTransferDepartmentInsideInbox(this.$route.params.companyId, this.contactActive.phonenumberId, this.contactActive.waId, this.departmentId, response => {
				response
				if (response){ 
					// let obj = {file: null, mediaType: 'updateChatDetail', msg: 'lead', visible: false}
					// this.$emit('sendMessageData', obj) 
					this.show2 =false   
					this.departmentId = null                                                         
				} else {
					alert('Falha ao trocar o usuário!')
				}
			})  
			this.show2 = false
		},
		setDepartmentId (index, id) {
			this.departmentId = id
			this.currentDepartmentIndex = index                                         
		},
		setDepartmentId2 (index, id,phoneId,name) {
			this.departmentId = id
			this.phonenumberId = phoneId      
			this.departmentSelected = name     
			this.currentDepartmentIndex = index                                         
		},
		setAgentId (index, id) {
			this.agentId = id                
			this.currentIndex = index
		},
		getDepartmentList (def) {
			if(def == true){
				WDepartmentsAPI.getDepartmentsOfUser(this.$route.params.companyId, response => {
					let departments = response.data
					departments.forEach(dep => {   
						dep.visible = this.getDepartmentCustom != dep.agentOrDepartmentLeadDescr && this.getDepartmentCustom != dep.departmentName
					})
					this.departments =WSortBy.orderMyList('departmentName',departments)
				})
			}else{
				WPhoneNumberAPI.getTransferOptions(this.$route.params.companyId, this.contactActive.phonenumberId, this.contactActive.waId, response => {
					let departments = response.data
					departments.forEach(dep => {   
						dep.visible = (this.contactActive.departmentName != dep.description && dep.department == true)
					})
					this.departments =WSortBy.orderMyList('description',departments)
				})  
			}
		},       
		getUsersOfDepartment (departmentId) {
			WDepartmentsAPI.getUsersOfDepartment(this.$route.params.companyId, departmentId, response => {
				let agents = response.data
				let list = []
				for (const key in agents){                    
					list.push({agentId: key, name: agents[key], visible: true})
				}
				// this.agents = list
				this.agents =WSortBy.orderMyList('name',list)
			})
		},  
		searchDepartment () {
			this.departments.forEach( el => {
				if ( el.departmentName.toLowerCase().includes(this.filter.toLowerCase()) ){
					el.visible = true
				} else {
					el.visible = false
				}
			})        
		},  
		searchDepartment2 () {
			this.departments.forEach( el => {
				if ( el.description.toLowerCase().includes(this.filter.toLowerCase()) && el.department == true ){
					el.visible = true
				} else {
					el.visible = false
				}
			})        
		},  
		searchAgents () {
			this.agents.forEach( el => {
				if ( el.name.toLowerCase().includes(this.filterAgents.toLowerCase()) ){
					el.visible = true
				} else {
					el.visible = false
				}
			})        
		},      
		nextStep () { 
			if (this.departmentId == null && this.checked == false){
				alert('Pelo menos um item deve ser selecionado!')
				return
			}
			if (this.checked){
				WPhoneNumberAPI.postAssignLeadIsSystem(this.$route.params.companyId, this.contactActive.phonenumberId, this.contactActive.waId,response => {
					if (response){ 
						let obj = {file: null, mediaType: 'updateChatDetail', msg: 'lead', visible: false}
						this.$emit('sendMessageData', obj) 
						this.show =false                                                            
					} else {
						alert('Falha ao trocar o departamento!')
					}
				})                   
			} else {
				this.step++
				if (this.step == 2){
					this.title = 'Responsável por esse contato'
					this.placeholder = 'Buscar usuário' 
					if (this.checked){
						this.getUsersOfDepartment(this.agentOrDepartmentLead)
					} else {
						this.getUsersOfDepartment(this.departmentId) 
					}                                                                           
				}                
			}
		},  
		save () {
			let agentId = this.agentId
			if (this.checked2){
				agentId =  this.departmentId 
			} 
			if (agentId == '' && this.checked2 == false){
				alert('Pelo menos um item deve ser selecionado!')
				return
			}            
			WPhoneNumberAPI.postAssignLead(this.$route.params.companyId, this.contactActive.phonenumberId, this.contactActive.waId, agentId, response => {
				if (response){ 
					let obj = {file: null, mediaType: 'updateChatDetail', msg: 'lead', visible: false}
					this.$emit('sendMessageData', obj) 
					this.show =false       
					this.departmentId = null                                                      
				} else {
					alert('Falha ao trocar o usuário!')
				}
			})                                  
		},
		goBack () { 
			this.step--   
			if (this.step == 0){
				this.show2 =false
				this.show = false
			}      
			if (this.step == 1){
				this.title = 'Responsável por esse contato'
				this.placeholder = 'Buscar departamento'                                 
			}               
			if (this.step == 2){
				this.title = 'Responsável por esse contato'
				this.placeholder = 'Buscar usuário'                                 
			}
		},
		clearFilter (type) {
			if(this.filter == '' && type == 'departament'){
				this.searchDepartment()
			} else if(this.filterAgents == '' && type == 'agents'){
				this.searchAgents()
			}
		}      
	},
	mounted () {
		this.$root.$on('bv::modal::hide', () => {
			this.show = false  
			this.step = 1
			this.title = 'Responsável por esse contato'
			this.placeholder = 'Buscar departamento'
			this.filter = ''
			this.filterAgents = ''
			this.selected = ''
			this.departmentId = ''
			this.departments = []
			this.agents = []
			this.currentIndex = null
			this.currentDepartmentIndex = null
			this.checked = false
			this.checked2 = false
		}) 
	}, 
}
</script>

<style>
.disable-component{
	position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color:#000;
    opacity: .75;
    z-index: 9999999;
}
.propertie-tag{
	background-color:#F5F5F5;
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;
	padding: 4px 8px 4px 8px;
	
}
.description-ellipsis { 
	/* width: 50%; */
    white-space: nowrap;  
    overflow: hidden;
    text-overflow: ellipsis;
	margin-left: 15px!important;
}
</style>

 
