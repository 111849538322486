<template>
	<div>
		<template v-if="!$route.meta.allowAnonymous">
			<!-- <w-login v-if="shouldLoadHeader == true"/> -->
			<div :id="getClass">
				<w-header v-if="shouldLoadHeader == true && (getClass == 'app')" />
				<w-content v-if="shouldLoadHeader == true"/>	
			</div>         
		</template>
		<template v-else>
			<transition>
				<keep-alive>
					<router-view v-if="shouldLoadHeader == true" v-on:userLogin="getStyleColors"></router-view>
				</keep-alive>
			</transition>
		</template>
	</div>

</template>

<script>
import {access_token} from '@/global'
import Header from '@/views/Header'
import Content from '@/views/Content'
import WCompanyAPI from '@/api/WCompanyAPI'
import i18n from '@/plugins/i18n'
import { avatarUrl} from '@/global'
import WCheckInternetConnection from '@/js/utils/WCheckInternetConnection.js'
import WFileQueue from '@/js/utils/WFileQueue.js'
export default {
	components: {
		'w-header': Header, 
		'w-content': Content,
	},
	data () {
		return {
			appId: 'app',
			shouldLoadHeader: false
		}
	},   
	computed: {
		getClass () {
			if(this.$store.getters.getMobileClass == true){
				return 'app-mobile'
			}else{
				return 'app'
			}
		}
	} ,
	methods: {
		validateToken () { 
			if (access_token == undefined || access_token == '' || access_token == null){
				if (!this.$route.meta.allowAnonymous){
					this.$router.push({ path: '/' })
				}          
			} 
		},
		getStyleColors () {
			if(this.$route.params.companyId){
				WCompanyAPI.getDataValue(this.$route.params.companyId,1,response => {
					this.$store.commit('setCompressValue',response.data)
				})
			}
			WCompanyAPI.getStyleColors(response => {
				console.log(response)
				this.$store.commit('setCompanyId',response.data.companyId)
				localStorage.setItem('__wpp_company_company_name', response.data.companyName)
				localStorage.setItem('__wpp_company_id_login', response.data.companyId)
				document.documentElement.style.setProperty('--primary', response.data.primaryColor)
				document.documentElement.style.setProperty('--secondary', response.data.secundaryColor)
				localStorage.setItem('__wpp_iconMediaId', response.data.iconMediaId)
				localStorage.setItem('__wpp_iconCustom', response.data.iconCustom)
				localStorage.setItem('__wpp_logoMediaId', response.data.logoMediaId) 	
				localStorage.setItem('__wpp_logoCustom', response.data.logoCustom)
				if(response.data.iconCustom == true){
					this.$store.commit('setIconMediaId', avatarUrl + response.data.companyId + '&imageId='+response.data.iconMediaId)
				}
				if(response.data.logoCustom == true){
					this.$store.commit('setLogoMediaId', avatarUrl + response.data.companyId + '&imageId='+response.data.logoMediaId)
				}				
				if(response.data.logoCustom == true){
					let logoPath = avatarUrl + response.data.companyId + '&imageId='+localStorage.getItem('__wpp_logoMediaId')
					const favicon = document.querySelector('link[sizes=\'16x16\']')
					favicon.setAttribute('href', logoPath) 
				}
				this.shouldLoadHeader = true
			})
		},
	
		loadI18n () {
			const userLang = navigator.language || navigator.userLanguage
			if (userLang.indexOf('es') > -1){
				this.setI18nLocale('es')
			} else if (userLang.indexOf('pt') > -1){
				this.setI18nLocale('pt')
			} else {
				this.setI18nLocale('en')
			}            
		},
		setI18nLocale (locale) {
			i18n.locale = locale
			i18n.fallbackLocale = locale
		},      	    
		
	},
	mounted () {
		try {
			let userguiding = document.getElementById('userguiding')
			if (typeof(userguiding) != 'undefined' && userguiding != null) {
				if (this.$mq == 'xs' || this.$mq == 'sm') {				
					userguiding.style.display = 'none'				
				} else {
					userguiding.style.display = 'block'					
				}
			}			
		} catch (error) {
			console.log(error)
		}
		WFileQueue
		WCheckInternetConnection
		this.getStyleColors()
	},
}
</script>

<style>
	* {
		font-family: "Montserrat", sans-serif;
	}
  #app, body, html {
      height: 100%;
      width: 100%;
      overflow: hidden;
      padding: 0;
      margin: 0;
  }

	#app {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		height: 100vh;
		display: grid;
		grid-template-rows: 60px 1fr;
		grid-template-columns: 1fr;
		grid-template-areas:
			"header"
      "content"
			;
	}
	#app-mobile {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		/* height: 100vh; */
		/* display: grid; */
		/* grid-template-rows: 60px 1fr;
		grid-template-columns: 1fr;
		grid-template-areas:
      "content"
			; */
	}

	#app-side-bar {
    height: 100vh;
		display: grid;  
    grid-template-rows: 60px 1fr;
		grid-template-columns: 280px 1fr;      
		grid-template-areas:
			"header header"
      "menu content"
      "menu content";
			;
	}
  .img {
    cursor: pointer;
  }

</style>