<template>
	<div class="mt-2 aside-bar-custom">
		<div>                    
			<b-nav vertical class="mt-4">
				<div v-for="(x,index) in menuItems" :key="index">				
					<span class="custom-font-version ml-4" v-if="x.name === 'Fluxos para Chatbot'">
						VERSÃO BETA
					</span>
					<b-nav-item class="menu-ellipsis" :active="item == index" @click="openMenuItem(x,index)"><span class="ml-2">{{x.name}}</span></b-nav-item>
				</div>	
			</b-nav>
		</div>
		<div style="width:200px" v-if="phoneNumberList.length >1">
			<div v-if="selectedPhoneNumber && multipleNumber" class="bottom-content">
				<!-- <span>bottom content</span> -->
				<hr>
				<b-row class="justify-content-center">
					<span style="font-size:12px">Número analisado:</span>
				</b-row>
				<b-row class="justify-content-center mt-1 ml-3 mr-3">
					<span style="font-size:14px" id="phoneNumberName" class="menu-ellipsis" >{{(selectedPhoneNumber.phonenumberName != ''?selectedPhoneNumber.phonenumberName:(selectedPhoneNumber.phonenumber != ''?selectedPhoneNumber.phonenumber:selectedPhoneNumber.phonenumberId))}}</span>
					<b-tooltip target="phoneNumberName" triggers="hover">
						{{(selectedPhoneNumber.phonenumberName != ''?selectedPhoneNumber.phonenumberName:(selectedPhoneNumber.phonenumber != ''?selectedPhoneNumber.phonenumber:selectedPhoneNumber.phonenumberId))}}
					</b-tooltip>
				</b-row>
				<div style="padding:6px;justify-content: center;display:flex">
					<b-dropdown toggle-class="white-space-normal" :text="(selectedPhoneNumber.phonenumberName != ''?selectedPhoneNumber.phonenumberName:(selectedPhoneNumber.phonenumber != ''?selectedPhoneNumber.phonenumber:selectedPhoneNumber.phonenumberId))" variant="ligth">
						<b-dropdown-item @click="selectDropdownOption(x)" v-for="(x,index) in phoneNumberList" :key="index">
							{{(x.phonenumberName != ''?x.phonenumberName:(x.phonenumber != ''?x.phonenumber:x.phonenumberId))}}
						</b-dropdown-item>
					</b-dropdown>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
export default {
	props:[
		'menuItems',
		'loadedItem',
		'multipleNumber'
	],
	computed:{
		
	},
	data:function () {
		return {
			item : this.loadedItem,
			selectedPhoneNumber: undefined,
			phoneNumberList:[]
		}
	},
	methods:{
		openMenuItem (x,index) {
			this.item = index
			x.index = index
			this.$emit('openMenuItem', x)
		},
		selectDropdownOption (obj) {
			this.selectedPhoneNumber = obj
			this.$emit('changeNumber', obj)
			this.$store.commit('setDashboardStep',this.selectedPhoneNumber)
		}
	},
	async mounted () {
		await this.$store.dispatch('getPhoneNumberList')
		this.phoneNumberList = this.$store.getters.getPhoneNumberList
		this.selectedPhoneNumber = this.$store.getters.getDashboardStep.phoneNumber
		if(!this.selectedPhoneNumber){
			this.selectedPhoneNumber = this.phoneNumberList[0]
		}
		this.$emit('changeNumber', this.selectedPhoneNumber)
	}

}
</script>

<style scoped lang="scss">
.active {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 15px !important;
    line-height: 20px !important;  
    color: var(--primary);
    background: #F5F5F5;    
    border-right: solid 3px var(--primary);    
}
.multiple-number-select-style .row{
	margin-left: 15px;
	margin-right: 0px;
	display: flex;
	width: 22vh;
}
.menu-ellipsis{
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	// padding-left: 15px;
	// padding-right: 10px;
	width: inherit;
}
.bottom-content{
	position: absolute;
	bottom: 0;
	width: 100%;
	margin-bottom: 5px;
}
</style>