<template>
	<b-col class="mt-4 ml-4">
		<b-row class="mt-2">
			<b-col sm="1">
				<span>Servidor:</span>
			</b-col>	
			<b-col sm="2">
				<b-input v-model="server"></b-input>
			</b-col>
			
		</b-row>
		<b-row class="mt-2">
			<b-col sm="1">
				<span>Api Key:</span>
			</b-col>
			<b-col sm="2">
				<b-input v-model="apiKey"></b-input>
			</b-col>
		</b-row>
		<b-row class="mt-2">
			
			<b-col sm="1">
				<span>Namespace:</span>
			</b-col>
			
			<b-col sm="2">
				<b-input v-model="namespace"></b-input>
			</b-col>
		</b-row>
		<b-row class="mt-2">
			<b-col sm="1">
				<span>wabaId:</span>
			</b-col>
			
			<b-col sm="2">
				<b-input v-model="wabaId"></b-input>
			</b-col>
		</b-row>
		<b-row class="mt-2">
			<b-col sm="1">
				<span>Numero do telefone:</span>
			</b-col>
			
			<b-col sm="2 mt-2">
				<b-input v-model="phonenumber"></b-input>
			</b-col>
		</b-row>
		<b-row class="mt-2">
			<b-col sm="1">
				<span>Nome do telefone:</span>
			</b-col>
			
			<b-col sm="2 mt-2">
				<b-input v-model="phonenumberName"></b-input>
			</b-col>
		</b-row>
		<b-row class="mt-2 ml-0">
			<b-button @click="post">Salvar</b-button>
		</b-row>
		
	</b-col>
	
</template>

<script>
import WSystemResourceAPI from '@/api/WSystemResourceAPI'
export default {
	data () {
		return { 
			server:'',
			apiKey:'',
			phonenumber:'',
			phonenumberName:'',
			namespace:'',
			wabaId:'',
		}
	}, 
	methods: {

		post () {
			WSystemResourceAPI.postLinkType1Server(this.$route.params.companyId, this.server, this.apiKey, this.phonenumber,this.phonenumberName,this.namespace,this.wabaId,response => {
				response
			}) 
		},	
	
	},
}
</script>

<style>

</style>