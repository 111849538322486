<template>
	<div> 
		<b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" right text="Right align" no-caret>
			<template #button-content>
				<svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="2" cy="2" r="2" fill="#C4C4C4"/>
					<circle cx="2" cy="11" r="2" fill="#C4C4C4"/>
					<circle cx="2" cy="20" r="2" fill="#C4C4C4"/>
				</svg>
			</template>
			<!-- GENERIC OPTIONS -->
			<div v-if="Array.isArray(dropdownOptions) == true">
				<b-dropdown-item v-for="x in dropdownOptions" :key="x.icon" @click="emitEvent(x)">
					<span v-if="x.icon == 'delete'">
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M3 4H4.33333H15" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M6.3335 4.00004V2.66671C6.3335 2.31309 6.47397 1.97395 6.72402 1.7239C6.97407 1.47385 7.31321 1.33337 7.66683 1.33337H10.3335C10.6871 1.33337 11.0263 1.47385 11.2763 1.7239C11.5264 1.97395 11.6668 2.31309 11.6668 2.66671V4.00004M13.6668 4.00004V13.3334C13.6668 13.687 13.5264 14.0261 13.2763 14.2762C13.0263 14.5262 12.6871 14.6667 12.3335 14.6667H5.66683C5.31321 14.6667 4.97407 14.5262 4.72402 14.2762C4.47397 14.0261 4.3335 13.687 4.3335 13.3334V4.00004H13.6668Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M7.6665 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M10.3335 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</span>
					<span v-else-if="x.icon == 'edit'">
						<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M10.0779 1.50136C10.2368 1.34241 10.4255 1.21632 10.6332 1.1303C10.8409 1.04428 11.0635 1 11.2883 1C11.513 1 11.7356 1.04428 11.9433 1.1303C12.151 1.21632 12.3397 1.34241 12.4986 1.50136C12.6576 1.66031 12.7837 1.84901 12.8697 2.05669C12.9557 2.26436 13 2.48695 13 2.71174C13 2.93653 12.9557 3.15912 12.8697 3.3668C12.7837 3.57447 12.6576 3.76317 12.4986 3.92212L4.32855 12.0922L1 13L1.90779 9.67145L10.0779 1.50136Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</span>
					<span v-else-if="x.icon == 'download'">
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M4.6665 6.66669L7.99984 10L11.3332 6.66669" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M8 10V2" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</span>
					<span class="custom-font-open-12-400 ml-1">
						{{x.content}}
					</span>                                        
				</b-dropdown-item> 
			</div>
			<!-- GENERIC OPTIONS -->
			<b-dropdown-item v-if="dropdownOptions.editPermission" @click="editPermission(id)">
				<span>
					<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M10.0779 1.50136C10.2368 1.34241 10.4255 1.21632 10.6332 1.1303C10.8409 1.04428 11.0635 1 11.2883 1C11.513 1 11.7356 1.04428 11.9433 1.1303C12.151 1.21632 12.3397 1.34241 12.4986 1.50136C12.6576 1.66031 12.7837 1.84901 12.8697 2.05669C12.9557 2.26436 13 2.48695 13 2.71174C13 2.93653 12.9557 3.15912 12.8697 3.3668C12.7837 3.57447 12.6576 3.76317 12.4986 3.92212L4.32855 12.0922L1 13L1.90779 9.67145L10.0779 1.50136Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</span>
				<span class="custom-font-open-12-400 ml-1">
					Editar permissões
				</span>                                        
			</b-dropdown-item>  
			<b-dropdown-item v-if="dropdownOptions.editAttendant" @click="editAttendant(id)">
				<span>
					<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M10.0779 1.50136C10.2368 1.34241 10.4255 1.21632 10.6332 1.1303C10.8409 1.04428 11.0635 1 11.2883 1C11.513 1 11.7356 1.04428 11.9433 1.1303C12.151 1.21632 12.3397 1.34241 12.4986 1.50136C12.6576 1.66031 12.7837 1.84901 12.8697 2.05669C12.9557 2.26436 13 2.48695 13 2.71174C13 2.93653 12.9557 3.15912 12.8697 3.3668C12.7837 3.57447 12.6576 3.76317 12.4986 3.92212L4.32855 12.0922L1 13L1.90779 9.67145L10.0779 1.50136Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</span>
				<span class="custom-font-open-12-400 ml-1">
					Editar usuário
				</span>                                        
			</b-dropdown-item>     
			<b-dropdown-item  v-if="dropdownOptions.editDepartment" @click="editDepartment(id, attributeAll)" >
				<span>
					<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M10.0779 1.50136C10.2368 1.34241 10.4255 1.21632 10.6332 1.1303C10.8409 1.04428 11.0635 1 11.2883 1C11.513 1 11.7356 1.04428 11.9433 1.1303C12.151 1.21632 12.3397 1.34241 12.4986 1.50136C12.6576 1.66031 12.7837 1.84901 12.8697 2.05669C12.9557 2.26436 13 2.48695 13 2.71174C13 2.93653 12.9557 3.15912 12.8697 3.3668C12.7837 3.57447 12.6576 3.76317 12.4986 3.92212L4.32855 12.0922L1 13L1.90779 9.67145L10.0779 1.50136Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</span>
				<span class="custom-font-open-12-400 ml-1">
					{{editDepartmentTittle}}
				</span>                                        
			</b-dropdown-item>  
			<b-dropdown-item v-if="dropdownOptions.reactivateAttendant" @click="reactivateAttendant(id)" v-show="item.status == 3">
				<span>
					<img src="@/assets/play.png"> 
				</span>
				<span class="custom-font-open-12-400 ml-1">
					Reativar usuário
				</span>                                       
			</b-dropdown-item>   
			<b-dropdown-item v-if="dropdownOptions.disableAttendant" @click="disableAttendant(id)" v-show="item.status != 3">
				<span>
					<img src="@/assets/pause.png"> 
				</span>
				<span class="custom-font-open-12-400 ml-1">
					Desativar usuário
				</span>                                        
			</b-dropdown-item>  
			<b-dropdown-item v-if="dropdownOptions.removeAttendant" @click="removeAttendant(id, name)">
				<span>
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M3 4H4.33333H15" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M6.3335 4.00004V2.66671C6.3335 2.31309 6.47397 1.97395 6.72402 1.7239C6.97407 1.47385 7.31321 1.33337 7.66683 1.33337H10.3335C10.6871 1.33337 11.0263 1.47385 11.2763 1.7239C11.5264 1.97395 11.6668 2.31309 11.6668 2.66671V4.00004M13.6668 4.00004V13.3334C13.6668 13.687 13.5264 14.0261 13.2763 14.2762C13.0263 14.5262 12.6871 14.6667 12.3335 14.6667H5.66683C5.31321 14.6667 4.97407 14.5262 4.72402 14.2762C4.47397 14.0261 4.3335 13.687 4.3335 13.3334V4.00004H13.6668Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M7.6665 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M10.3335 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</span>
				<span class="custom-font-open-12-400 ml-1">
					Remover usuário
				</span>                                        
			</b-dropdown-item>
			<b-dropdown-item v-if="dropdownOptions.exitActiveSessions" @click="exitActiveSessions(id)">
				<span>
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M12.6667 2H3.33333C2.59695 2 2 2.59695 2 3.33333V12.6667C2 13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V3.33333C14 2.59695 13.403 2 12.6667 2Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M6 6L10 10" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M10 6L6 10" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</span>
				<span class="custom-font-open-12-400">
					Sair das sessões ativas 
				</span>                                        
			</b-dropdown-item>     
			<b-dropdown-item v-if="dropdownOptions.setDepartmentDelete" @click="setDepartmentDelete(id, name)">
				<span>
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M3 4H4.33333H15" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M6.3335 4.00004V2.66671C6.3335 2.31309 6.47397 1.97395 6.72402 1.7239C6.97407 1.47385 7.31321 1.33337 7.66683 1.33337H10.3335C10.6871 1.33337 11.0263 1.47385 11.2763 1.7239C11.5264 1.97395 11.6668 2.31309 11.6668 2.66671V4.00004M13.6668 4.00004V13.3334C13.6668 13.687 13.5264 14.0261 13.2763 14.2762C13.0263 14.5262 12.6871 14.6667 12.3335 14.6667H5.66683C5.31321 14.6667 4.97407 14.5262 4.72402 14.2762C4.47397 14.0261 4.3335 13.687 4.3335 13.3334V4.00004H13.6668Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M7.6665 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M10.3335 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</span>
				<span class="custom-font-open-12-400 ml-1">
					Deletar
				</span>                                        
			</b-dropdown-item>     
			<b-dropdown-item v-if="dropdownOptions.editProperty" @click="editProperty(id,description)">
				<span>
					<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M10.0779 1.50136C10.2368 1.34241 10.4255 1.21632 10.6332 1.1303C10.8409 1.04428 11.0635 1 11.2883 1C11.513 1 11.7356 1.04428 11.9433 1.1303C12.151 1.21632 12.3397 1.34241 12.4986 1.50136C12.6576 1.66031 12.7837 1.84901 12.8697 2.05669C12.9557 2.26436 13 2.48695 13 2.71174C13 2.93653 12.9557 3.15912 12.8697 3.3668C12.7837 3.57447 12.6576 3.76317 12.4986 3.92212L4.32855 12.0922L1 13L1.90779 9.67145L10.0779 1.50136Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</span>
				<span class="custom-font-open-12-400">
					Editar descrição 
				</span>                                        
			</b-dropdown-item>                                                                                                      
			<b-dropdown-item v-if="dropdownOptions.setPropertyDelete" @click="setPropertyDelete(id,name)">
				<span>
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M3 4H4.33333H15" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M6.3335 4.00004V2.66671C6.3335 2.31309 6.47397 1.97395 6.72402 1.7239C6.97407 1.47385 7.31321 1.33337 7.66683 1.33337H10.3335C10.6871 1.33337 11.0263 1.47385 11.2763 1.7239C11.5264 1.97395 11.6668 2.31309 11.6668 2.66671V4.00004M13.6668 4.00004V13.3334C13.6668 13.687 13.5264 14.0261 13.2763 14.2762C13.0263 14.5262 12.6871 14.6667 12.3335 14.6667H5.66683C5.31321 14.6667 4.97407 14.5262 4.72402 14.2762C4.47397 14.0261 4.3335 13.687 4.3335 13.3334V4.00004H13.6668Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M7.6665 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M10.3335 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</span>
				<span class="custom-font-open-12-400">
					Remover atributo 
				</span>                                        
			</b-dropdown-item>
			<b-dropdown-item v-if="dropdownOptions.editLabelDescription" @click="editLabelDescription(item,description)">
				<span>
					<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M10.0779 1.50136C10.2368 1.34241 10.4255 1.21632 10.6332 1.1303C10.8409 1.04428 11.0635 1 11.2883 1C11.513 1 11.7356 1.04428 11.9433 1.1303C12.151 1.21632 12.3397 1.34241 12.4986 1.50136C12.6576 1.66031 12.7837 1.84901 12.8697 2.05669C12.9557 2.26436 13 2.48695 13 2.71174C13 2.93653 12.9557 3.15912 12.8697 3.3668C12.7837 3.57447 12.6576 3.76317 12.4986 3.92212L4.32855 12.0922L1 13L1.90779 9.67145L10.0779 1.50136Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</span>
				<span class="custom-font-open-12-400">
					Editar nome e descrição  
				</span>                                        
			</b-dropdown-item>  
			<b-dropdown-item v-if="dropdownOptions.editLabelDepartment" @click="editLabelDepartment(item,description)">
				<span>
					<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M10.0779 1.50136C10.2368 1.34241 10.4255 1.21632 10.6332 1.1303C10.8409 1.04428 11.0635 1 11.2883 1C11.513 1 11.7356 1.04428 11.9433 1.1303C12.151 1.21632 12.3397 1.34241 12.4986 1.50136C12.6576 1.66031 12.7837 1.84901 12.8697 2.05669C12.9557 2.26436 13 2.48695 13 2.71174C13 2.93653 12.9557 3.15912 12.8697 3.3668C12.7837 3.57447 12.6576 3.76317 12.4986 3.92212L4.32855 12.0922L1 13L1.90779 9.67145L10.0779 1.50136Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</span>
				<span class="custom-font-open-12-400">
					Editar departamentos
				</span>                                        
			</b-dropdown-item>
			<b-dropdown-item v-if="dropdownOptions.setLabelDelete" @click="setLabelDelete(item)">
				<span>
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M3 4H4.33333H15" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M6.3335 4.00004V2.66671C6.3335 2.31309 6.47397 1.97395 6.72402 1.7239C6.97407 1.47385 7.31321 1.33337 7.66683 1.33337H10.3335C10.6871 1.33337 11.0263 1.47385 11.2763 1.7239C11.5264 1.97395 11.6668 2.31309 11.6668 2.66671V4.00004M13.6668 4.00004V13.3334C13.6668 13.687 13.5264 14.0261 13.2763 14.2762C13.0263 14.5262 12.6871 14.6667 12.3335 14.6667H5.66683C5.31321 14.6667 4.97407 14.5262 4.72402 14.2762C4.47397 14.0261 4.3335 13.687 4.3335 13.3334V4.00004H13.6668Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M7.6665 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M10.3335 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</span>
				<span class="custom-font-open-12-400">
					Deletar 
				</span>                                        
			</b-dropdown-item>
			<b-dropdown-item v-if="dropdownOptions.setLabelArchive" @click="setLabelArchive(item,dropdownOptions.setLabelArchive.emit)">
				<span>
					<svg data-v-41be6633="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="archive" xmlns="http://www.w3.org/2000/svg" fill="#777777" class="bi-archive mx-auto b-icon bi"><g data-v-41be6633=""><path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"></path></g></svg>
				</span>
				<span class="custom-font-open-12-400">
					{{dropdownOptions.setLabelArchive.content}}
				</span>                                        
			</b-dropdown-item>
			<b-dropdown-item v-if="dropdownOptions.exportData && item.inboxRule == 1" @click="exportResult(id)">
				<span>
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M4.6665 6.6665L7.99984 9.99984L11.3332 6.6665" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M8 10V2" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</span>
				<span class="custom-font-open-12-400">
					Exportar dados
				</span>                                        
			</b-dropdown-item>  			
		</b-dropdown>
	</div>
</template>

<script>
export default {
	name: 'TableCellDepartment',
	props: [
		'dropdownOptions',
		'item',
		'id',
		'name',
		'attributeAll',
		'editDepartmentTittle',
		'description',
	],
	methods:{
		emitEvent (x) {
			let obj ={item:this.item,action:x.icon  }
			this.$emit('tableDropdownEmit', obj)
		},
		editAttendant (id) {
			this.$emit('editAttendant',id)
		},
		editDepartment (id,departmentAll) {
			this.$emit('editDepartment',id,departmentAll)
		},
		reactivateAttendant (id) {
			this.$emit('reactivateAttendant',id)
		},
		disableAttendant (id,) {
			this.$emit('disableAttendant',id)
		},
		removeAttendant (id) {
			this.$emit('removeAttendant',id)
		},
		exitActiveSessions (id) {
			this.$emit('exitActiveSessions',id)
		},
		setDepartmentDelete (id) {
			this.$emit('setDepartmentDelete',id)
		},
		editPermission (id) {
			this.$emit('editPermission',id)
		},
		editProperty (id,description) {
			this.$emit('editProperty',id,description)
		},
		setPropertyDelete (id,name) {
			this.$emit('setPropertyDelete',id,name)
		},
		editLabelDescription (item) {
			this.$emit('editLabelDescription',item)
		},
		editLabelDepartment (item) {
			this.$emit('editLabelDepartment',item)
		},
		setLabelArchive (item,emit) {
			this.$emit(emit,item)
		},
		setLabelDelete (item) {
			this.$emit('setLabelDelete',item)
		},
		exportResult (id) {
			this.$emit('exportResult', id)
		},		
	},
	mounted () {
	}
}

</script>

<style>

</style>