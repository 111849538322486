<template>
	<b-button variant="primary"
		@click="openLinkWhastapp()">Suporte         
	</b-button>	 					 
</template>

<script>
export default {
	name: 'Support',
	props: [
		'phoneNumber',
		'message'
	],
	data () {
		return {			
		}
	},
	methods: { 
		openLinkWhastapp () {				
			this.showModal = false
			let wmessage = this.message			
			let message = wmessage.split(' ').join('%20')
			window.open('https://api.whatsapp.com/send?phone=' + this.phoneNumber + '&text=%20' + message, '_blank')
		},  
	},
	mounted () {
		
	}

}
</script>

<style>

</style>