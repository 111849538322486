<template>
	<b-container fluid class="company-scrollable scrollbar">
		<b-col class="mt-4">
			<span class="lbl-title tittle-test">Configurações da plataforma</span>
			<br>
			<span class="custom-font-open-14-gray-normal">Configure aqui a aparência da sua empresa, personalize suas cores e logo, e deixe seu cadastro completo <b> apenas para uso interno da sua equipe e da Becon. <br/> Para personalizar o número da empresa no whatsapp, acesso o submenu "Número".</b></span>
		</b-col>
		<b-row :key="reload">
			<b-col md="3">
				<company-logo  :companyId="companyId" v-on:saveResumeOption="saveResumeOption"/>
			</b-col> 
			<b-col md="8">
				<company-data v-if="dataObj" :companyId="companyId" :dataObj="dataObj" v-on:changeResumeOption="changeResumeOption"/>
				<company-address v-if="adressObj" :companyId="companyId" :addressId="addressId" :testeProp="testeProp" :adressObj="adressObj"/>
			</b-col>           
		</b-row>
		<br>
	</b-container>
</template>

<script>
import CompanyLogo from '@/components/settings/company/WCompanyLogo'
import CompanyData from '@/components/settings/company/WCompanyData'
import CompanyAddress from '@/components/settings/company/WCompanyAddress'
import WCompanyAPI from '@/api/WCompanyAPI'

export default {
	name: 'Company',
	components: {
		'company-logo': CompanyLogo,
		'company-data': CompanyData,
		'company-address': CompanyAddress
	},
	data () {
		return {
			companyId: '',
			addressId: '',
			testeProp:'teste',
			adressObj:undefined,
			dataObj:undefined,
			reload:0
		}
	},    
	methods: {
		getDetail () {
			WCompanyAPI.getDetail (this.$route.params.companyId,response => {
				this.adressObj = response.data.companyAddress
				this.dataObj = response.data
			})   
		},
		changeResumeOption (item) {
			localStorage.removeItem('__wpp_company_chat_resume_order') 
			localStorage.setItem('__wpp_company_chat_resume_order', item) 			
			this.dataObj.chatResumeOrder = item
			console.log(this.dataObj.chatResumeOrder)
		},
		saveResumeOption () {
			WCompanyAPI.posChatResumeOrder(this.$route.params.companyId,this.dataObj.chatResumeOrder, response =>{
				response
				this.reload++
			})
		}
         
	},
	mounted () {
		this.getDetail()
	}, 
}
</script>

<style>
.company-scrollable {
    height: 90vh;
    width: 98%;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>
