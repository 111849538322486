import axios from 'axios'
import { baseApiUrl, showError} from '@/global'
import store from '@/config/store'
import WUserAPI from '@/api/WUserAPI'
import WPhoneNumberAPI from '@/api/WPhoneNumberAPI'
import WAttendantsAPI from '@/api/WAttendantsAPI'
import i18n from '@/plugins/i18n'
import router from '@/router'
let country = ''
let city = ''
let state = ''
let userName = ''
let userEmail = ''
let paymentOverdue = false
let chatResumeOrder = 0
let aux = 0
export default {
	
	getCompanyId () {
		let access_token = localStorage.getItem('__wpp_access_token')         
		if (access_token != undefined && access_token != ''){
			this.getCompanyList( access_token, response => {
				let companyId = response.data[0].companyId            
				if (companyId != null){	
					country = response.data[0].companyAddress.country
					city = response.data[0].companyAddress.city    
					state = response.data[0].companyAddress.state
					paymentOverdue = response.data[0].paymentOverdue
					this.getUserPreferences()  
					chatResumeOrder = response.data[0].chatResumeOrder
					localStorage.removeItem('__wpp_company_id') 
					localStorage.setItem('__wpp_company_id', companyId) 
					localStorage.removeItem('__wpp_company_chat_resume_order') 
					localStorage.setItem('__wpp_company_chat_resume_order', response.data[0].chatResumeOrder) 					
					localStorage.removeItem('__wpp_base_api_url')
					localStorage.setItem('__wpp_base_api_url', baseApiUrl)					
					store.commit('setCompanyId', companyId) 					
					this.getUserProfile(companyId)                           
				}
			})      
		}
	},    

	getCompanyPayment: (access_token, callback) => {
		axios.defaults.headers.common['Authorization'] = `bearer ${access_token}`
		const url = `${baseApiUrl}`+'company/myCompanies'
		axios.get(url).then(response => {
			if (callback){
				callback(response.data)
			}                
		})
			.catch(showError)
	},
	loadI18nAfter () {
		WUserAPI.getUserLanguage(response => {
			let userLang = response.data
			if (userLang.indexOf('es') > -1){
				this.setI18nLocale('es')
			} else if (userLang.indexOf('pt') > -1){
				this.setI18nLocale('pt')
			} else {
				this.setI18nLocale('en')
			}                  
		})           
	},  
    
	getAgentDetail (companyId, userId) {
		WAttendantsAPI.getDetail(companyId,userId,response => {	
			let agent = response.data	
			let admin = agent.adminPermission
			userName = agent.name
			userEmail = agent.email
			let conpass = {country: country, city: city, state: state, userName: userName, userEmail: userEmail, admin: admin}
			localStorage.removeItem('__wpp_conpass_object')
			localStorage.setItem('__wpp_conpass_object', JSON.stringify(conpass))
			let permission = {
				adminPermission: agent.adminPermission,
				agentPermission: agent.agentPermission,
				billingPermission: agent.billingPermission,
				broadcastPermission: agent.broadcastPermission,
				contactPermission: agent.contactPermission,
				dashboardPermission: agent.dashboardPermission,
				developerPermission: agent.developerPermission,
				automationPermission: agent.automationPermission
			}
			try {
				const currentDate = new Date()
				const timestamp = currentDate.getTime()
				window.userGuiding.identify(userId, {
					name: userName,
					email: userEmail,
					created_at: timestamp,
				})					
			} catch (error) {
				console.log(error)
			}	
			localStorage.removeItem('__wpp_user_permission_object')
			localStorage.setItem('__wpp_user_permission_object', JSON.stringify(permission))			
			this.getPhoneNumber(companyId, permission)			
		})
	},

	getPhoneNumber (companyId, permission) {
		WPhoneNumberAPI.getPhonenumbers(companyId,response => {	
			let phonenumberId = Object.keys(response.data)[0]
			localStorage.removeItem('__wpp_phone_number_id')
			localStorage.setItem('__wpp_phone_number_id', phonenumberId)
			if (paymentOverdue == false) {
				let routerOpen = '/' + companyId+ '/'+ chatResumeOrder+ '/chat'
				if (permission.agentPermission == false && permission.adminPermission == false) {
					if (permission.automationPermission == true) {
						routerOpen = '/automation/' + companyId+ '/main'
						router.push({ path: routerOpen}) 
					}	
					if (permission.broadcastPermission == true) {
						routerOpen = '/broadcast/' + companyId+ '/list'
						router.push({ path: routerOpen}) 
					}		
					if (permission.contactPermission == true) {
						routerOpen = '/contact/' + companyId+ '/list'
						router.push({ path: routerOpen}) 
					}	
					if (permission.developerPermission == true) {
						routerOpen = '/developer/' + companyId+ '/main'
						router.push({ path: routerOpen})  
					}	
					if (permission.billingPermission == true) {
						routerOpen = '/dashboard/' + companyId+ '/main'
						router.push({ path: routerOpen})  
					}																			
				} else {
					router.push({ path: routerOpen})  
				}				 
			} else {
				router.push({ path: '/billing/support'})   
			}			
		})
	},

	getUserProfile (companyId) {
		WUserAPI.getUserProfile(response => {			
			let profile = response.data
			localStorage.removeItem('__whatsapp_api_user')	
			localStorage.setItem('__whatsapp_api_user', JSON.stringify(profile))		
			this.getAgentDetail(companyId,profile.userId)
		})
	},

	getUserPreferences () {
		WUserAPI.getUserPreferences(response => {
			let preferences = response.data
			localStorage.removeItem('__wpp_preferences_upload_limit')       
			let objUploadLimit = {
				fileUploadLimitAudio: preferences.fileUploadLimitAudio, 
				fileUploadLimitDocument: preferences.fileUploadLimitDocument,
				fileUploadLimitImage: preferences.fileUploadLimitImage,
				fileUploadLimitVideo: preferences.fileUploadLimitVideo
			}
			localStorage.setItem('__wpp_preferences_upload_limit', JSON.stringify(objUploadLimit))
		})
	},
	setI18nLocale (locale) {
		i18n.locale = locale
		i18n.fallbackLocale = locale
	},
	getCompanyList: (access_token, callback) => {
		axios.defaults.headers.common['Authorization'] = `bearer ${access_token}`
		const url = `${baseApiUrl}`+'company/myCompanies'
		axios.get(url).then(response => {
			if (callback){
				callback(response.data)
			}                
		})
			.catch(showError)
	},
	getStyleColors (callback) {
		let that = this
		aux
		const url = `${baseApiUrl}`+'company/style'
		axios.get(url)
			.then(response => {
				if (callback){
					aux = 0
					callback(response.data)
				}                
			})
			.catch(function (error) { 
				console.log(error.response.status)
				if(error.response.status == 503) {
					aux++
					// console.log(aux)
					if(aux == 3){
						alert('Serviço indisponível no momento.')
						aux = 0
					}else{
						setTimeout(() => {
							that.getStyleColors(callback)
						}, 10000)
					}
					// 
				} else{
					console.log('error')
					console.log(showError)
				}
			// console.log(error.response.status)                    
			})  
	},
	
	getDetail: (companyId,callback) => {
		const url = `${baseApiUrl}`+'company/'+companyId+'/detail'
		axios.get(url).then(response => {
			if (callback){
				callback(response.data)
			}                
		})
			.catch(showError)
	},
	postAddress (companyId,zipCode,neighborhood,complement,street,city,state,number,country,callback) {
		let url = `${baseApiUrl}`+'company/'+companyId+'/address'
		const params = new URLSearchParams()
		params.append('zipCode',zipCode)
		params.append('neighborhood', neighborhood)   
		params.append('complement', complement)   
		params.append('street', street)  
		params.append('city', city)  
		params.append('state', state)  
		params.append('number', number)  
		params.append('country', country)  
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},
	postProfile (companyId,companyName,site,email,callback) {
		let url = `${baseApiUrl}`+'company/'+companyId+'/profile'
		const params = new URLSearchParams()
		params.append('companyName',companyName)
		params.append('site', site)   
		params.append('email', email)   
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},
	postStyleColors (companyId,primaryColor,secundaryColor,backgroundColor,fontColor,borderColor,callback) {
		let url = `${baseApiUrl}`+'company/'+companyId+'/styleColors'
		const params = new URLSearchParams()
		params.append('primaryColor',primaryColor)
		params.append('secundaryColor', secundaryColor)   
		params.append('backgroundColor', backgroundColor)   
		params.append('fontColor', fontColor)   
		params.append('borderColor', borderColor)   
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},
	posChatResumeOrder (companyId,chatResumeOrder,callback) {
		let url = `${baseApiUrl}`+'company/'+companyId+'/chatResumeOrder'
		const params = new URLSearchParams()
		params.append('chatResumeOrder',chatResumeOrder)
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},
	getDataValue (companyId,dataKey,callback) {
		let url = `${baseApiUrl}`+'company/'+companyId+'/'+dataKey+'/dataValue'
		axios.get(url)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},
}
