<template>
	<div style="height: 100%; display: flex;">
		<b-overlay :show="loading" variant="light"
			no-wrap>
			<template #overlay>
				<b-spinner style="width: 3rem; height: 3rem; color: var(--secondary)" label="Large Spinner"></b-spinner>
			</template>                    
		</b-overlay>  		
		<div class="ml-0">
			<iframe		
				id="iframeFlowBuild"
				:src="flowURL"
				scrolling="yes"
				@load="iframeIsLoaded"
			></iframe>
		</div>
	</div>
</template>
<script>
import {chatFlowURL} from '@/global'
export default {
	name: 'WChatFlowBuild',
	data () {
		return {	
			loading: false,
			show: false,
			flowURL: chatFlowURL
		}
	},

	methods: {  
		iframeIsLoaded () {
			//alert('iframe loaded')
			this.loading = false			
			this.flowBuildURL()
		},
		flowBuildURL () {
			const frame = document.getElementById('iframeFlowBuild')
			let params = '&companyId=' +localStorage.getItem('__wpp_company_id')+
			'&accessToken=' + localStorage.getItem('__wpp_access_token') +
			'&baseApiUrl=' + localStorage.getItem('__wpp_base_api_url') +
			'&chatFlowId=' + JSON.parse(localStorage.getItem('__wpp_flow_header_obj')).chatFlowId					
			//return 'http://localhost:3000/#/flowbuild' + '?' + 'params=' + this.$CryptoJS.AES.encrypt(JSON.stringify(params), '').toString()
			let message = 'MwdjDZ' + window.btoa(params) 	
			frame.contentWindow.postMessage(message, this.flowURL)
		}			
	},
	mounted () {
		let obj = JSON.parse(localStorage.getItem('__wpp_flow_header_obj'))
		localStorage.removeItem('__wpp_flow_header_obj')
		localStorage.setItem('__wpp_flow_header_obj', JSON.stringify(obj))
		this.$store.commit('setFlowHeaderObj')			
		this.loading = true
		window.onbeforeunload = function (e) {
			console.log(e)
			return 'As alterações feitas podem não ser salvas.'  
		}
	}
}
</script>
<style scoped>
iframe {
  height: calc(100vh - 55px);
  width:100vw;
  box-sizing: border-box;
}

</style>