import axios from 'axios'                       
export default { 
	getArrayBufferMedia (url, callback) {
		axios.get(url, {
			responseType: 'arraybuffer'
		})
			.then(response => {
				const buffer = Buffer.from(response.data, 'binary').toString('base64')
				callback(buffer)
			})
			.catch(ex => {
				console.error(ex)
			})                
	}
}