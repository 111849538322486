import axios from 'axios'
import { baseApiUrl} from '../global'
import i18n from '../plugins/i18n'

export default {
	
	postRealtimeReportContact (companyId, phonenumberId, reportType, callback) {
		const url = `${baseApiUrl}`+'dashboardExport/'+ companyId + '/' +phonenumberId + '/realtimeReport/' + reportType                                  		
		axios.post(url)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {				
				console.log(i18n.t('Message.error') + error)       
				callback(false)         
			})    
	},
	
	postFinancialReportContact (companyId, phonenumberId, startTime, endTime, reportType, callback) {
		const url = `${baseApiUrl}`+'dashboardExport/'+ companyId + '/' +phonenumberId  + '/financialReport/' + startTime + '/' + endTime + '/' + reportType
		axios.post(url)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {				
				console.log(i18n.t('Message.error') + error)       
				callback(false)         
			})    
	},
	postPhonenumberDashboardExport (companyId, phonenumberId, startTime, endTime, reportType, departmentIdList, callback) {
		const params = new URLSearchParams()
		params.append('departmentIdList', departmentIdList)
		const url = `${baseApiUrl}`+'dashboardExport/'+ companyId + '/' +phonenumberId + '/phonenumber/' + startTime + '/' + endTime + '/' + reportType                                  		
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {				
				console.log(i18n.t('Message.error') + error)       
				callback(false)         
			})    
	},	
	postTeamDashboardExport (companyId, phonenumberId, startTime, endTime, reportType, userIdList, departmentIdList, callback) {
		const params = new URLSearchParams()
		params.append('userIdList', userIdList)
		params.append('departmentIdList', departmentIdList)
		const url = `${baseApiUrl}`+'dashboardExport/'+ companyId + '/' +phonenumberId  + '/team/' +startTime + '/' + endTime + '/' + reportType                                		
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {				
				console.log(i18n.t('Message.error') + error)       
				callback(false)         
			})    
	},
	postAttendanceDashboardExport (companyId, phonenumberId, startTime, endTime, reportType, departmentIdList, callback) {
		const params = new URLSearchParams()
		params.append('departmentIdList', departmentIdList)
		const url = `${baseApiUrl}`+'dashboardExport/'+ companyId + '/' +phonenumberId  +'/attendance/' + startTime + '/' + endTime + '/' + reportType                                		
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {				
				console.log(i18n.t('Message.error') + error)       
				callback(false)         
			})    
	},											
}