<template>
	
	<div class="forgot-content">
		<div class="forgot-modal">
			<div v-if="logoPath" class="auth-logo">		
				<b-row class="mb-3">
					<img :src="logoPath">   
				</b-row>
				<!-- <b-row>
					<img src="@/assets/whatsapp_logo.png"> 
				</b-row>                             -->
			</div>
			<div class="forgot-request">
				<div class="custom-forgot">
					<b-row class='mt-4'>
						<label class='custom-forgot'>Por segurança, enviaremos uma senha provisória para o seu e-mail. Assim que realizar seu login, proceda com a alteração da sua senha.</label>
					</b-row>
					<b-row class="mb-4 mt-4">
						<label class="sr-only" for="inline-form-input-username"></label>
						<!-- <b-input-group class="mb-2 mr-sm-2 mb-sm-0 input-data">
                            
                            <b-form-input id="inline-form-input-username" v-model="username" placeholder="Digitar seu e-mail"></b-form-input>
                        </b-input-group>  -->
						<b-input-group class="mb-2 mr-sm-2 mb-sm-0 input-data">
							<b-input-group-prepend>
								<span class="icon">
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M6 5.68421C6 8.8181 8.692 11.3684 12 11.3684C15.308 11.3684 18 8.8181 18 5.68421C18 2.55032 15.308 0 12 0C8.692 0 6 2.55032 6 5.68421ZM22.6667 24H24V22.7368C24 17.8623 19.812 13.8947 14.6667 13.8947H9.33333C4.18667 13.8947 0 17.8623 0 22.7368V24H22.6667Z" fill="#838383"/>
									</svg>
								</span>
							</b-input-group-prepend>
							<input-email
								ref='teste'
								type='email'
								placeHolder="Digite seu e-mail"
								label = 'email'
								v-on:sendMessageData="getMessageData"
								styleInput="background:none;border:none;"
							/>
						</b-input-group> 
					</b-row>
                        
					<b-button variant='primary' ref='password' :disabled="isEmpty" @click="sendNewPassword" class="mt-2 mb-2" style="width:100%;max-width:365px">Enviar nova senha</b-button>  
				</div>
			</div>                               
		</div>
	</div>
	
		
</template>

<script>
import WUserAPI from '@/api/WUserAPI'
import InputEmail from '../components/global/InputType.vue'
export default {
	components: {
		'input-email':InputEmail },
	name: 'ForgotPassword',
	data () {
		return {
			email: '',
			backgroundColor: 'background-color:#777777',
			isEmpty: true,
			isValid: false,
			messageError:'',
			logoPath:this.$store.getters.getIconMediaId,
		}
	},
	methods: {
		sendNewPassword () {
			this.$refs.password.focus()
			if(this.isValid == false){
				alert(this.messageError)
			}else{
				localStorage.setItem('__mk_forgot_password_email',this.email)
				WUserAPI.resetPassword( this.email)
				this.$router.push({ path: '/forgot/success'})
			}	  
			this.$refs.password.blur()
		},
		updateButton (empty) {
			if(empty === true){
				this.isEmpty = true
			}else{
				this.isEmpty = false
			}
		},
		confirmEmail (email, validated) {
			this.email = email
			this.isValid = validated
		},
		getMessageData (message) {
			this.updateButton(message.empty)
			this.isValid = message.validated	
			this.email = message.value
			this.messageError = message.messageError
		},
	}
}
</script>


<style scoped>

    .forgot-content {
        height: 100vh;
		width: 100vw;
		background-color: var(--secondary);
    }
	.custom-forgot {
      /*   text-align: center !important;	
        line-height: 21,79px !important;
        font-family: Open Sans !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        color: #000000 !important; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 400px;
	}
    
    .custom-forgot button {
        background-color: var(--primary);
        color: #FFF;
        width: 100%;
        min-width: 365px;
        justify-content: center;
        align-items: center;
        font-family: 'Montserrat','HelveticaNeue','Helvetica Neue','sans-serif';
        font-size:18px;
        color:white;    
        font-weight: 500;  
        cursor: pointer;
        padding: 8px 40px;
        border-radius: 30px;  
        border: none;
        margin-left: -3px;
    }

    .custom-forgot button:hover {
        opacity: .7;
    }

    .forgot-modal{
        height: 100%;
		width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .forgot-request {
        background-color: #F4F5F8;
        width: 50%;
        height: 100%;
        padding: 35px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;
        align-items: center;
		justify-content: center;
		border-radius: 50px 0 0 50px;
    }

    .auth-logo {
        width: 50%;
        padding: 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .input-data {
		align-items: center;
        flex-wrap: nowrap;
		width: 350px;
		padding: 0 10px 0 10px;			
        border-bottom: 1px solid #838383;
	}

		
	.input-group-prepend {
		margin-right: 10px;
	}

    /* .forgot-modal input {
        border: 1px solid #BBB;
        width: 100%;
        margin-bottom: 15px;
        padding: 12px 17px;
        margin: 2px 0 11px;
        padding-left: 10px;
        height: 40px;
        outline: none;  
        border-radius: 10px;    
    } */

    /* .forgot-modal button {
        background-color: var(--primary);
        color: #FFF;
        padding: 5px 15px;
        width: 200px;
        justify-content: center;
        align-items: center;
        font-family: 'Montserrat','HelveticaNeue','Helvetica Neue','sans-serif';
        font-size:16px;
        color:white;    
        font-weight: 500;  
        cursor: pointer;
        padding: 8px;
        border-radius: 10px;          
    } */


    .forgot-modal a {
        margin-top: 5px;
        font-size: 16px;
        font-weight: 500;
    }

    .remember {
        margin-top: 5px;
        font-size: 16px;
        color: gray;
    }

    hr.forgot {
        border: 1px solid gray;
        width: 260px;
    } 
	.template-button {
        font-size: 14px;
        color: rgb(70, 70, 70);
        background-color:#000000;
        width: 150px;
        text-align: center;
        padding: 5px;
        margin: 15px 5px 5px;   
    }   
	.input-group-text {
        color: #FFFFFF !important;
        background-color: var(--secondary) !important;
        border: 1px solid #ced4da !important;
        border-radius: 8px 0 0 8px !important; 
    }

	.form-control {
		border: 10px solid black !important;
		background: none !important;
	}
	@media only screen and (max-width: 768px) {
        .forgot-modal {
            flex-direction: column;
        }
        .forgot-request{
            width: 100%;
			height: 75%;
			border-radius: 50px 50px 0 0 ;   
        }

        .auth-logo img {
			width: 280px;
		}

        .privacy {
			top: 95%;
		}
    }

    @media only screen and (max-width: 425px) {
        .custom-forgot{
            width: 275px;
        }

        .input-data {
            width: 275px;
        }

        .custom-forgot button {
            min-width: 275px;
        }
    }
</style>
