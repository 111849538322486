<template>
	<div>
		<b-row>
			<span class="custom-font-open-14-400-gray">Escolha uma resposta rápida para ser enviada como resposta à palavra chave</span>        
		</b-row>
		<b-row>
			<b-col md="10">
				<div class="search" style="margin-left: -15px;">  
					<b-input-group>
						<b-form-input 
							type="search"                     
							placeholder="Busque pela  descrição da resposta rápida"
							v-model="filter"
							@keyup="searchQuickReply"
							class="mt-1"
							@update="clearFilter">
						</b-form-input>
						<b-input-group-prepend is-text class="mt-1">
							<b-icon icon="search"></b-icon>
						</b-input-group-prepend>                    
					</b-input-group>                                                                                       
				</div>
			</b-col>
		</b-row>
		<b-col id="list" class="automation-quickreply-scrollable scrollbar">
			<b-table-simple id="my-table" hover small responsive>
				<b-thead head-variant="ligth">
					<b-tr>
						<b-th>Descrição</b-th>
						<b-th>Tipo</b-th>                
						<b-th>Prévia</b-th>
						<b-th>Mídia</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr :key="index" v-for="(x, index) in orderBy(quickReply, 'quickReplyDescription')"
						v-show="x.visible == true">
						<b-td class="td-automation">
							<label :for="x.quickReplyId" @change="setQuickReply(x.quickReplyId)">   
								<b-form-radio
									:id="x.quickReplyId"
									:name="'option-selected'"
									value="x.quickReplyId"    
									size="lg"
								>	
									<span style="line-height: 30px">{{x.quickReplyDescription}}</span> 
									
									
								</b-form-radio>    
							</label>                       
						</b-td>
						<b-td class="td-date">{{x.quickReplyTypeDescription}}</b-td>                     
						<b-td class="td-date">
							<div class="description-ellipsis" v-b-tooltip.hover.left="x.quickReplyContent"> 
								{{x.quickReplyContent}}
							</div>
						</b-td> 
						<b-td class="td-data">												
							<div class="description-ellipsis" v-b-tooltip.hover.left="x.mediaName"  >
								{{x.mediaName}}
							</div>
								
						</b-td>      
					</b-tr>        
				</b-tbody>
			</b-table-simple>  
		</b-col>                                         
	</div>                                          
</template>

<script>
import WQuickReplyAPI from '@/api/WQuickReplyAPI'
import Vue2Filters from 'vue2-filters'
export default {
	mixins: [Vue2Filters.mixin],
	name: 'WAutomationQuickReply',
	data () {
		return { 
			filter: '',
			quickReply: []
		}
	},    
	methods: {
		getListByCompanyId () {
			WQuickReplyAPI.getQuickReplyList(this.$route.params.companyId, response => {
				let quickReply = response.data
				quickReply.forEach(quick => {
					quick.visible = true
				})
				this.quickReply = quickReply
			})
		},     
		setQuickReply (id) {
			let obj = {id: id}
			this.$emit('sendMessageData', obj)              
		},                       
		searchQuickReply () {
			this.quickReply.forEach( el => {
				if ( el.quickReplyDescription.toLowerCase().includes(this.filter.toLowerCase()) ){
					el.visible = true
				} else {
					el.visible = false
				}
			})        
		},
		clearFilter () {
			if(this.filter == ''){
				this.searchQuickReply()
			}
		}              
	},
	mounted () {  
		this.getListByCompanyId()                          
	}, 
}
</script>

<style scoped>
.automation-quickreply-scrollable {
    height: 40vh;
    width: 100%;
    min-height: 100px;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden; 
}
th {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #999999 !important;    
}

td {
    margin: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #777777; 
    height: 50px;
    vertical-align: middle !important;
}
.td-automation {
	width: 1px;
    padding-top: 15px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px; 
    color: #777777;
}
.td-data {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.description-ellipsis { 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
}
#list {
  height: 43vh;
}

@media only screen and (max-width: 1024px) {
  #list {
	height: 37vh;
  }
}
</style>

 
