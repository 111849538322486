<template>
	<b-col md="12" class="mb-3">
		<b-row v-if="contactActiveDetail.actions.title != ''" class="ml-1 mt-2">
			<h4>{{contactActiveDetail.actions.title}}</h4>            
		</b-row>
		<b-row v-if="contactActiveDetail.actions.status == 3" class="mb-1 ml-1">
			<b-col>
				<b-row>
					<b-avatar v-if="contactActiveDetail.actions.avatarCustom == true" :src="contactActiveDetail.actions.text1"></b-avatar>
					<b-avatar v-else class="avatar40">{{ contactActiveDetail.name.substring(0,2).toUpperCase()}}</b-avatar>
					<span class="medium-font ml-2 mt-2"><b>{{contactActiveDetail.actions.text2}}</b></span>
				</b-row>
			</b-col>
			<b-col>
				<b-row class="d-flex justify-content-end">
					<b-button variant="primary" class="float-right mr-5"
						@click="assignChatToMe()" >{{contactActiveDetail.actions.button2}}           
					</b-button>  
				</b-row>

			</b-col>       
		</b-row> 
		<b-row v-else-if="contactActiveDetail.actions.status == 6 || contactActiveDetail.actions.status == 7" class="mb-1 ml-2">
			<b-col>
				<b-row>
					<span class="medium-font ml-2 mt-2"><b>{{contactActiveDetail.actions.text1}}</b></span>
				</b-row>
			</b-col>
			<b-col>
				<b-row class="d-flex justify-content-end">
					<b-button variant="primary" class="float-right mr-5"
						@click="contactActiveDetail.actions.status == 6 ? assignChatToMe():show = true" >{{contactActiveDetail.actions.button2}}           
					</b-button>  
				</b-row>

			</b-col>       
		</b-row>  
		<b-row v-else class="mb-1 ml-1" >
			<div v-if="contactActiveDetail.actions.vhtml">
				<span class="medium-font" v-html="contactActiveDetail.actions.vhtml"></span>
			</div>
			<div v-else>
				<span class="medium-font">{{contactActiveDetail.actions.text1}}</span>
				<span class="medium-font"><b>{{contactActiveDetail.actions.text2}}</b></span>
			</div>
		</b-row>
		<div  v-if="contactActiveDetail.actions.status != 3 && contactActiveDetail.actions.status != 6 && contactActiveDetail.actions.status != 7 && contactActiveDetail.actions.status != 8" class="w-100">
			<b-row v-if="contactActiveDetail.actions.status == 4 || contactActiveDetail.actions.status == 5" class="d-flex justify-content-center">
				<b-button variant="light"
					@click="assignChatToMe()" :disabled="contactActiveDetail.actions.btnDisable">{{contactActiveDetail.actions.button2}}            
				</b-button>  
			</b-row>
			<div v-else>
				<b-button v-if="!contactActiveDetail.actions.btnDisable" variant="outline-primary" class="mr-2"
					@click="action()">{{contactActiveDetail.actions.button1}}
				</b-button>   
				<b-button v-else variant="primary" class="mr-2"
					@click="action()">{{contactActiveDetail.actions.button1}}
				</b-button>          
				<b-button v-if="!contactActiveDetail.actions.btnDisable" variant="primary" class="float-right mr-5"
					@click="action()" :disabled="contactActiveDetail.actions.btnDisable">{{contactActiveDetail.actions.button2}}            
				</b-button>        
				<b-button v-else class="float-right mr-5"
					@click="action()" :disabled="contactActiveDetail.actions.btnDisable">{{contactActiveDetail.actions.button2}}            
				</b-button>     
			</div>
		</div>   
		<b-modal 
			v-model="show"
			centered
			scrollable>
			<template #modal-title>
				<span class="custom-font-open-24-600">
					Você realmente deseja assumir esse atendimento?	
				</span> 
				<!-- <span >Assumir conversa.</span> -->
			</template>
			<div class="scrollbar">
				<br>
				<span class="custom-font-open-16-gray-400" style="border-radius:100px;background-color:#F5F5F5;padding: 4px 16px">
					Fluxo: {{contactActiveDetail.chatflowName}}
				</span>
				<br>
				<br>
				<span class="custom-font-open-12-400">Ao assumir um atendimento durante um fluxo, o mesmo será interrompido. Ou seja, será necessário começa-lo do zero, se quiser enviá-lo novamente para o contato.</span>
			</div>
			<template #modal-footer>
				<div class="w-100">
					
					<b-button variant="outline-primary" class="float-left" @click="show = false">
						Cancelar
					</b-button>  
					<b-button variant="primary" class="float-right" @click="endChatFlow">
						Assumir
					</b-button>
				</div>
			</template>
		</b-modal>                        
	</b-col>                                                                                                           
</template>

<script>
import WPhoneNumberAPI from '@/api/WPhoneNumberAPI'
import WMessageAPI from '@/api/WMessageAPI'
export default {
	name: 'WBarActios',
	props: ['contactActiveDetail'],
	data () {
		return { 
			show:false
		}                          
	},
	methods: {
		teste () {

		},
		hide () {
			this.$emit('hide')
		},
		action () {
			console.log('action')
			let obj = null
			if (this.contactActiveDetail.contactActiveDetail.actions.status == 2){
				let contact = {
					action: 2,
					phonenumberId: this.contactActiveDetail.phonenumberId,
					waId: this.contactActiveDetail.waId,
					name: this.contactActiveDetail.name
				}
				obj = {file: null, mediaType: 'assign', msg: contact, visible: false}      
				this.$emit('sendMessageData', obj)          
			} else {
				let contact = {
					action: 0,
					phonenumberId: this.contactActiveDetail.phonenumberId,
					waId: this.contactActiveDetail.waId,
					name: this.contactActiveDetail.name
				}				
				obj = {file: null, mediaType: 'assign', msg: contact, visible: false} 
				this.$emit('sendMessageData', obj)                               
			}
		},
		endChatFlow () {
			// console.log(this.contactActiveDetail)
			WMessageAPI.postChatflowStop(this.$route.params.companyId,this.contactActiveDetail.phonenumberId, this.contactActiveDetail.waId,this.contactActiveDetail.chatflowId,response =>{
				response
				let contact = {
					action: 0,
					phonenumberId: this.contactActiveDetail.phonenumberId,
					waId: this.contactActiveDetail.waId,
					name: this.contactActiveDetail.name
				}						
				let obj = {file: null, mediaType: 'updateChatBoxAndChat', msg: contact, visible: false}
				this.$emit('sendMessageData', obj)
				this.show = false
			})
		},
		assignChatToMe () {
			console.log('chatToMe')
			WPhoneNumberAPI.getChatDetail(this.$route.params.companyId, this.contactActiveDetail.phonenumberId, this.contactActiveDetail.waId, response => {
				this.$store.commit('setContactOpenId',response.data.contactId)
				let chatDetail = response.data
				if (chatDetail.agentId != chatDetail.departmentId && this.contactActiveDetail.agentId == this.contactActiveDetail.departmentId) {
					alert('Esta conversa foi assumida por um atendente.')
					let contact = {
						action: 0,
						phonenumberId: this.contactActiveDetail.phonenumberId,
						waId: this.contactActiveDetail.waId,
						name: this.contactActiveDetail.name
					}						
					let obj = {file: null, mediaType: 'assign', msg: contact, visible: false,chatDetail: chatDetail,}
					this.$emit('sendMessageData', obj)
				}else{
					WPhoneNumberAPI.postAssignChatToMe(this.$route.params.companyId, this.contactActiveDetail.phonenumberId, this.contactActiveDetail.waId, response => {
						if (response){
							let contact = {
								action: 0,
								phonenumberId: this.contactActiveDetail.phonenumberId,
								waId: this.contactActiveDetail.waId,
								name: this.contactActiveDetail.name
							}						
							let obj = {file: null, mediaType: 'assign', msg: contact, visible: false}
							this.$emit('sendMessageData', obj)
						} else {
							alert('Erro ao assumir a conversa!')
						}
					})
				}
			})
		}
	},    
	mounted () {
	}, 
}
</script>

<style scoped>
    .search input { 
        border: none !important;
        width: 50% !important;
    }    
 
    .btn-light {
        color: var(--primary) !important;
        background-color: white !important;
        border-color: #f8f9fa;
        font-family: Open Sans !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important; 
    }    
</style>

 
