<template>
	<b-row style="background: #777777; height: 100%;">		    
		<b-col xs="auto" sm="auto">
			<b-row class="m-2">
				<span class="custom-font-open-28-700">
					Oh não!
				</span>
			</b-row>			
			<b-row class="m-2">
				<span class="custom-font-open-20-700">
					Sua equipe está à deriva no espaço, sem acesso à Becon!
				</span>				
			</b-row>
			<b-row class="m-2">
				<span class="custom-font-open-16-600">
					Seu acesso foi bloqueado porque não conseguimos registrar seu pagamento.
				</span>
			</b-row>	
			<b-row class="m-2">
				<div style="text-align: justify;  text-justify: inter-word;">
					<span class="custom-font-open-12-400">
						Para retomar seu acesso, basta acertar os valores em abertos com a Becon. <b>Caso não tenha acesso às faturas,</b> por favor, entre em contato com o nosso time de suporte imediatamente. <b>Caso já tenha pago os valores em aberto,</b> procure nosso suporte com o comprovante, para que possamos liberar seu acesso mais rápido.
					</span>
				</div>				
			</b-row>	
			<b-row class="m-2">
				<w-support 
					:phoneNumber="5547984124491"
					:message="'Olá, preciso de ajuda com a fatura em aberto, podes me ajudar?'"
				/>
			</b-row>
			<b-row class="ml-2 mr-2">
				<span class="custom-font-open-10-800">
					IMPORTANTE:
				</span>
			</b-row>			
			<b-row class="ml-2 mr-2">
				<div style="text-align: justify;  text-justify: inter-word;">
					<span class="custom-font-open-10-400">
						O sistema está recebendo suas mensagens normalmente.
						Contudo, caso não recebamos nenhum contato seu, em 
						<b>30 (trinta)</b> dias sua conta terá sua <b>exclusão</b> 
						agendada, juntamente com o <b>histórico de mensagens,</b> 
						seus <b>contatos</b>, o <b>chatbot</b> da sua empresa, 
						<b>histórico de transmissões</b> e demais configurações.					
					</span>
				</div>
			</b-row>												
		</b-col>        				                
	</b-row>
</template>

<script>
import support from '@/components/global/Support.vue'
export default {
	name: 'InadimplencySmall',
	components: {
		'w-support': support
	},
	data () {
		return {            

		}
	},     
	methods: {

	},
	mounted () {

	}
}
</script>

<style>
.custom-font-open-10-400 {
    font-family: Open Sans;
    font-style: normal; 
    font-weight: 400; 
    font-size: 10px; 
    color: #fff;    
}
.custom-font-open-10-800 {
    font-family: Open Sans;
    font-style: normal; 
    font-weight: 800; 
    font-size: 10px; 
    color: #fff;    
}
.custom-font-open-12-400 {
    font-family: Open Sans;
    font-style: normal; 
    font-weight: 400; 
    font-size: 12px; 
    color: #fff;    
}
.custom-font-open-16-600 {
    font-family: Open Sans;
    font-style: normal; 
    font-weight: 600; 
    font-size: 16px; 
    color: #fff;    
}
.custom-font-open-20-700 {
    font-family: Open Sans;
    font-style: normal; 
    font-weight: 700; 
    font-size: 20px; 
    color: #fff;    
}
.custom-font-open-28-700 {
    font-family: Open Sans;
    font-style: normal; 
    font-weight: 700; 
    font-size: 28px; 
    color: #fff;    
}

</style>
