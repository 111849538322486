<template>    	
	<div>
		<b-row class="d-flex justify-content-center" :class="pixel == true ?'default-message-pixel':'default-message'">
			<span class="custom-font-open-14-white" v-html="replaceMulti(message)"></span>
		</b-row>                
	</div>                                                    								
</template>

<script>

export default {
	name: 'WMessageDefault',
	props: [
		'message'
	],  
	data () {
		return {	
			pixel:false
		}
	},
	methods: {        
		replaceMulti (haystack) {
			if (typeof haystack === 'string' || haystack instanceof String) {
				if (haystack != undefined){
					return haystack.split('\\n').join('<br>')                
				}
			}
		},
                    
	},    	
	mounted () {
		if(this.message.includes('Pixel acionado')){
			this.pixel = true
		}
	}
}
</script>

<style scoped>
    .default-message {
        width: 90%; 
        /* margin: 10px 10px 0px; */
		margin-left:5%;
		margin-top: 10px;
        padding: 10px; 
        display: flex;
        background-color: var(--secondary);
        height: auto; 
        min-height:20px;
        border-radius: 8px; 
        color: white; 
        align-items: center; 
        justify-content: center; 
        font-family: 'Roboto'; 
        font-size: 12px;  
    }    
	.default-message-pixel {
        width: 100%; 
        margin: 10px 10px 0px;
        padding: 10px; 
        display: flex;
        background-color: #4367B1;
        height: auto; 
        min-height:20px;
        border-radius: 8px; 
        color: white; 
        align-items: center; 
        justify-content: center; 
        font-family: 'Roboto'; 
        font-size: 12px;  
    }     
</style>



 