<template>
	<div>
		<b-col md="8">
			<div v-if="step == 1.1">
				<b-row class="ml-3 mt-3">  
					<h3 class="lbl-title">Criando uma chave de API</h3>                                              
				</b-row>
				<b-row class="ml-3">
					<span class="custom-font-open-14-gray-normal">
						Escolha uma nome bem específico, que ajude você a facilmente identificar que chave é essa nos Logs. Por exemplo, uma mensagem enviada pela API terá esse nome como autor daquela mensagem. Após finalizar, o sistema lhe dará uma chave aleatória.
					</span>                       
				</b-row>  
			</div>
			<div v-else>
				<b-row class="ml-3 mt-3">  
					<h3 class="lbl-title">Editar dados da chave</h3>                                              
				</b-row>
				<b-row class="ml-3">
					<span class="custom-font-open-14-gray-normal">
						Como essa propriedade pode estar sendo usada por contatos e templates
						não é possível alterar o nome da mesma, apenas suas descrição.
					</span>                       
				</b-row>  
			</div>
			<div class="w-100">
				<hr>
			</div>          
			<b-row class="ml-3 mt-3">
				<label for="inputName">Nome da chave</label>
				<b-form-input
					id="inputName"
					v-model="name"
					placeholder="Informe um nome"
				></b-form-input>
			</b-row>
			<b-row class="ml-3 mt-3">
				<label for="textarea">Descrição da chave</label>
				<b-form-textarea
					id="textarea"
					v-model="description"
					placeholder="Informe uma descrição"
					rows="1"
					@input="setBtnDisabe"
				></b-form-textarea>         
			</b-row> 
			<b-row class="mt-3">
				<b-col>
					<div class="d-flex justify-content-center">
						<b-button variant="outline-primary" class="mr-2"
							@click="cancel()">Cancelar
						</b-button>                      
						<b-button variant="primary" 
							@click="save()" :disabled="btnDisable" v-if="step == 1.1">Criar chave
						</b-button>      
						<b-button variant="primary" 
							@click="save()" v-else>Salvar
						</b-button>                                               
					</div>
				</b-col>            
			</b-row>          
		</b-col> 
	</div>
</template>

<script>
import WKeyAPI from '@/api/WKeyAPI'
export default {
	name: 'WKeyAPI',
	props: [
		'step'
	],
	data () {
		return {
			apiKeyId: '',            
			name: '',
			description: '',
			btnDisable: true
		}
	},
	methods: {
		setBtnDisabe () {
			this.btnDisable = false
		},
		save () {
			if (this.step == 1.1){
				if (this.name == '' || this.name == undefined){
					alert('Campo nome obrigatório!')
					return
				}                        
			}
   
			if (this.description == '' || this.description == undefined){
				alert('Campo descrição obrigatório!')
				return
			}   
			if (this.step == 1.1){
				WKeyAPI.createApi(this.$route.params.companyId, this.name, this.description, response => {
					if (response){  
						let obj = {step: 1}
						this.$emit('sendMessageData', obj)                                                           
					} else {
						alert('Falha ao criar chave da api!')
					}
				})                        
			} else {
				WKeyAPI.postUpdateApi(this.$route.params.companyId, this.apiKeyId, this.name, this.description, response => {
					if (response){  
						localStorage.removeItem('__wpp_developer_obj') 
						let obj = {step: 1}
						this.$emit('sendMessageData', obj)                                                           
					} else {
						alert('Falha ao editar chave da api!')
					}
				})                        
			}                               
		},  
		cancel () {      
			let obj = {step: 1}
			this.$emit('sendMessageData', obj)             
		},                               
	},
	mounted () {
		if (this.step == 1.2){                
			let objKeyAPI = JSON.parse(localStorage.getItem('__wpp_developer_obj'))
			if (objKeyAPI != null){
				this.apiKeyId = objKeyAPI.apiKeyId 
				this.name = objKeyAPI.keyApiName
				this.description = objKeyAPI.keyApiDescription
			}         
		}        
	},         
}
</script>