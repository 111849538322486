<template>
	<b-col class="mt-4" >
		<b-row class="d-flex justify-content-center">    
			<span class="custom-bold-subtitle">Logo da empresa</span>     
		</b-row>
		<div>
			<b-row class="justify-content-center mt-3">
				<span>Icone da empresa</span>	
			</b-row>
			<b-row class="justify-content-center">
				<span>Imagem interna</span>		
			</b-row>
			<b-row class="justify-content-center">
				
				<div class="image-upload mt-3">
					
					<label for="file-input" class="icon-clickable">
						<svg v-if="iconCustom == false && file == undefined"  width="92" height="87" viewBox="0 0 92 87" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="42" cy="42" r="42" fill="#C4C4C4"/>
							<path d="M26.5001 42.2114L13.9867 51.8752C13.2317 52.4583 13.644 53.6667 14.5979 53.6667H66.7853C67.6181 53.6667 68.086 52.7082 67.5738 52.0516L54.8905 35.7925C54.5496 35.3555 53.9183 35.2791 53.483 35.6222L34.9822 50.2057C34.5395 50.5547 33.8959 50.469 33.56 50.0162L27.9144 42.407C27.5808 41.9574 26.9431 41.8692 26.5001 42.2114Z" fill="#777777"/>
							<circle cx="36.1663" cy="31.4998" r="5.83333" fill="#777777"/>
							<g opacity="0.7" filter="url(#filter0_d)">
								<circle cx="73.5" cy="67.6665" r="14" fill="#F5F5F5"/>
							</g>
							<path d="M73.7295 73.333H79.7295" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M76.7295 62.3332C76.9947 62.0679 77.3544 61.9189 77.7295 61.9189C77.9152 61.9189 78.0991 61.9555 78.2707 62.0266C78.4423 62.0977 78.5982 62.2018 78.7295 62.3332C78.8608 62.4645 78.965 62.6204 79.0361 62.792C79.1071 62.9635 79.1437 63.1474 79.1437 63.3332C79.1437 63.5189 79.1071 63.7028 79.0361 63.8744C78.965 64.0459 78.8608 64.2018 78.7295 64.3332L70.3962 72.6665L67.7295 73.3332L68.3962 70.6665L76.7295 62.3332Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							<defs>
								<filter id="filter0_d" x="55.5" y="50.6665" width="36" height="36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix"/>
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
									<feOffset dy="1"/>
									<feGaussianBlur stdDeviation="2"/>
									<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
								</filter>
							</defs>
						</svg>  
						<div v-else>
							<b-avatar :src="filePath" size="6rem" style="background-color:white"></b-avatar>                            
							<svg class="mt-5" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M7 12.333H13" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M10 1.33316C10.2652 1.06794 10.6249 0.918945 11 0.918945C11.1857 0.918945 11.3696 0.955525 11.5412 1.0266C11.7128 1.09767 11.8687 1.20184 12 1.33316C12.1313 1.46448 12.2355 1.62038 12.3066 1.79196C12.3776 1.96354 12.4142 2.14744 12.4142 2.33316C12.4142 2.51888 12.3776 2.70277 12.3066 2.87435C12.2355 3.04594 12.1313 3.20184 12 3.33316L3.66667 11.6665L1 12.3332L1.66667 9.66649L10 1.33316Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>                                                  
						</div>
                       
					</label>                
					<b-form-file id="file-input" accept="image/jpg,image/png" v-model="file" class="mt-3" @change="handleFileChange($event.target.files,0)" plain></b-form-file>
				</div>            
			</b-row>
			<b-row class="d-flex justify-content-center" >

				<label for="file-input">             
				</label>                
			</b-row>             
			<b-row class="d-flex justify-content-center mt-2">
				<span class="small-font">Especificação de tamanho</span>
			</b-row>
			<b-row class="d-flex justify-content-center">
				<span class="small-font">Máximo 1MB 50x50</span>
			</b-row>  
		</div>
		<hr>
		<!-- second icon -->
		<div class="mt-3">
			<b-row class="justify-content-center">
				<span class="custom-bold-subtitle">Imagem da empresa</span>	
			</b-row>
			<b-row class="justify-content-center mt-3">
				<span>Tela de Login</span>		
			</b-row>
			<b-row class="justify-content-center">
				
				<div class="image-upload mt-1">
					
					<label for="file-input-logo" class="icon-clickable">
						<svg v-if="logoCustom == false && loginLogo == undefined"  width="92" height="87" viewBox="0 0 92 87" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="42" cy="42" r="42" fill="#C4C4C4"/>
							<path d="M26.5001 42.2114L13.9867 51.8752C13.2317 52.4583 13.644 53.6667 14.5979 53.6667H66.7853C67.6181 53.6667 68.086 52.7082 67.5738 52.0516L54.8905 35.7925C54.5496 35.3555 53.9183 35.2791 53.483 35.6222L34.9822 50.2057C34.5395 50.5547 33.8959 50.469 33.56 50.0162L27.9144 42.407C27.5808 41.9574 26.9431 41.8692 26.5001 42.2114Z" fill="#777777"/>
							<circle cx="36.1663" cy="31.4998" r="5.83333" fill="#777777"/>
							<g opacity="0.7" filter="url(#filter0_d)">
								<circle cx="73.5" cy="67.6665" r="14" fill="#F5F5F5"/>
							</g>
							<path d="M73.7295 73.333H79.7295" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M76.7295 62.3332C76.9947 62.0679 77.3544 61.9189 77.7295 61.9189C77.9152 61.9189 78.0991 61.9555 78.2707 62.0266C78.4423 62.0977 78.5982 62.2018 78.7295 62.3332C78.8608 62.4645 78.965 62.6204 79.0361 62.792C79.1071 62.9635 79.1437 63.1474 79.1437 63.3332C79.1437 63.5189 79.1071 63.7028 79.0361 63.8744C78.965 64.0459 78.8608 64.2018 78.7295 64.3332L70.3962 72.6665L67.7295 73.3332L68.3962 70.6665L76.7295 62.3332Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							<defs>
								<filter id="filter0_d" x="55.5" y="50.6665" width="36" height="36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix"/>
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
									<feOffset dy="1"/>
									<feGaussianBlur stdDeviation="2"/>
									<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
								</filter>
							</defs>
						</svg>  
						<div v-else>
							<b-row>
								<img class="avatarLogo" :src="loginLogoPath" />                           
								<svg class="mt-5" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M7 12.333H13" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M10 1.33316C10.2652 1.06794 10.6249 0.918945 11 0.918945C11.1857 0.918945 11.3696 0.955525 11.5412 1.0266C11.7128 1.09767 11.8687 1.20184 12 1.33316C12.1313 1.46448 12.2355 1.62038 12.3066 1.79196C12.3776 1.96354 12.4142 2.14744 12.4142 2.33316C12.4142 2.51888 12.3776 2.70277 12.3066 2.87435C12.2355 3.04594 12.1313 3.20184 12 3.33316L3.66667 11.6665L1 12.3332L1.66667 9.66649L10 1.33316Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>  
							</b-row>
						</div>
                       
					</label>                
					<b-form-file id="file-input-logo" accept="image/jpg,image/png" v-model="loginLogo" class="mt-3" @change="handleFileChange($event.target.files,1)" plain></b-form-file>
				</div>            
			</b-row>
			<b-row class="d-flex justify-content-center" >

				<label for="file-input-logo">             
				</label>                
			</b-row>             
			<b-row class="d-flex justify-content-center mt-2">
				<span class="small-font">Especificação de tamanho</span>
			</b-row>
			<b-row class="d-flex justify-content-center">
				<span class="small-font">Máximo 1MB 500x160</span>
			</b-row>  
		</div>
		<!-- second icon -->
		<hr>
		<b-col>
			<b-row class="justify-content-center">
				<span class="custom-bold-subtitle">Cores da empresa</span>	
			</b-row>
			<b-row class="justify-content-center mt-3">
				<b-col sm="auto">
					<b-row class="justify-content-center">
						<span>Cor Principal</span>
					</b-row>
					
					<b-row class="ml-0">
						<b-form-input style="width:35px;" type="color" v-model="primaryColor" :value="primaryColor"></b-form-input>
						<b-form-input class="ml-2" style="width:120px;" placeholder="Cor Primária" v-model="primaryColor"></b-form-input>
					</b-row>		
				</b-col>
			</b-row>
			<b-row class="justify-content-center mt-3">
				<b-col sm="auto">
					<b-row class="justify-content-center">
						<span>Cor Secundária</span>
					</b-row>
					
					<b-row class="ml-0">
						<b-form-input style="width:35px;" type="color" v-model="secondaryColor" :value="secondaryColor"></b-form-input>
						<b-form-input class="ml-2" style="width:120px;" placeholder="Cor Secundária" v-model="secondaryColor"></b-form-input>
					</b-row>		
				</b-col>
			</b-row>
			<b-row style="width:60vw" class="justify-content-end mt-3 ml-0">
				<b-button style="z-index: 1;position:relative; cursor:pointer; width:210px" variant="primary" @click="saveColors()">Salvar Alterações</b-button>	
			</b-row>
		</b-col>
	</b-col>
</template>

<script>
import WCompanyAPI from '@/api/WCompanyAPI'
import WAvatarAPI from '@/api/WAvatarAPI'
import { avatarUrl} from '@/global'
export default {
	
	name: 'CompanyLogo',

	data () {
		return {
			companyId: '',
			file: undefined,
			loginLogo: undefined,
			filePath: undefined,
			newIcon:false,
			newLogo:false,
			primaryColor:document.documentElement.style.getPropertyValue('--primary'),
			secondaryColor:document.documentElement.style.getPropertyValue('--secondary'),
			loginLogoPath:undefined,
			iconCustom: undefined,
			logoCustom:undefined,
		}
	},    
	methods: {
		countDownChanged (dismissCountDown) {
			this.dismissCountDown = dismissCountDown
		},
		showAlert () {
			this.dismissCountDown = this.dismissSecs
		},
		handleFileChange (file,type) {
			if (!file.length){
				return
			}
			if(file.size > 1000000){
				alert('Imagem maior que 1 mb')
				return
			}
			if(type == 0){
				let url = URL.createObjectURL(file[0])
				this.addImageProcess(url).then(img => {
					if(img.width > 50 || img.height > 50) {						
						alert('Altura e largura não respeitam o limite de 50!')
						return
					}else{
						this.newIcon = true
						this.showMediaPreview = true        
						this.file =  file[0]
						this.filePath = window.URL.createObjectURL(this.file) 
					}
				})
			}else if(type == 1){
				let url = URL.createObjectURL(file[0])
				this.addImageProcess(url).then(img => {
					if(img.width > 500 || img.height > 160) {						
						alert('Altura e largura não respeitam o limite de 500x160!')
						return
					}else{
						this.newLogo = true
						this.showMediaPreview = true        
						this.loginLogo =  file[0]
						this.loginLogoPath = window.URL.createObjectURL(this.loginLogo) 
					}		
				})
			}
		},
		saveColors () {
			let iconId = localStorage.getItem('__wpp_iconMediaId')
			let logoId = localStorage.getItem('__wpp_logoMediaId')
			if(this.isColor(this.primaryColor) == false){
				alert('Cor primária inválida')
				return
			}else if(this.isColor(this.secondaryColor) == false){
				alert('Cor secundária inválida')
				return
			}else{
				WCompanyAPI.postStyleColors (this.$route.params.companyId,this.primaryColor,this.secondaryColor,'#111b21','#111b21','#111b21', response => {
					response      
				}) 
			}			
			if(this.newIcon){
				let formDataFile = new FormData()  
				formDataFile.append('filename', this.file.name)
				formDataFile.append('file', this.file)
				WAvatarAPI.postUploadMedia (this.$route.params.companyId,logoId,formDataFile, response => {
					response      
				})
			}
			
			if(this.newLogo){
				let formDataLogo = new FormData()  
				formDataLogo.append('filename', this.loginLogo.name)
				formDataLogo.append('file', this.loginLogo)
				WAvatarAPI.postUploadMedia (this.$route.params.companyId,iconId,formDataLogo, response => {
					response      
				})
			}
			this.$emit('saveResumeOption')
			alert('Configurações alteradas com sucesso!')
			document.location.reload(true)
		},
		isColor (strColor) {
			let s = new Option().style
			s.color = strColor
			return s.color != ''
		},
		addImageProcess (src) {
			return new Promise((resolve, reject) => {
				let img = new Image()
				img.onload = () => resolve({width: img.width, height: img.height})
				img.onerror = reject
				img.src = src
			})
		}	
	},
	mounted () {
		this.loginLogoPath = avatarUrl + this.$route.params.companyId + '&imageId='+localStorage.getItem('__wpp_iconMediaId')
		this.filePath = avatarUrl + this.$route.params.companyId + '&imageId='+localStorage.getItem('__wpp_logoMediaId')
		this.iconCustom = JSON.parse(localStorage.getItem('__wpp_logoCustom'))
		
		this.logoCustom = JSON.parse(localStorage.getItem('__wpp_iconCustom'))
	}, 
}
</script>

<style>

</style>
