<template>
	<div style="margin-left: 30px; margin-right: 30px;" class="dictionary-list-scrollable scrollbar">
		<b-col class="mt-2 ml-2">
			<b-row class="ml-1">
				<b-col class="mt-2">
					<h3 class="lbl-title">Dicionário de IDs</h3>
				</b-col>
			</b-row>
			<b-row class="ml-3">
				<span class="custom-font-open-14-gray-normal">
					Visualize aqui o ID de todos os itens que você pode usar nas suas integrações.                
				</span>                       
			</b-row>        
		</b-col>
		<b-col md="12" sm="12" class="screen-template">
			<loading v-if="this.$store.state.loading ==true"/>     
			<b-row style="margin-rigth:30px;" v-if="this.$store.state.loading ==false">
				<b-col md="6">
					<div class="search">  
						<b-input-group>
							<b-form-input 
								type="search"                     
								placeholder="Busque pela descrição do ID"
								v-model="filter"
								@keyup="searchDictionaryId"
								@update="clearFilter"
								class="mt-1"
								@search="resetFilter">
							</b-form-input>
							<b-input-group-prepend is-text class="mt-1">
								<b-icon icon="search"></b-icon>
							</b-input-group-prepend>                    
						</b-input-group>  
						<b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
							<template #button-content size="lg">
								<svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect width="40" height="50" rx="8" fill="#F5F5F5"/>
									<path d="M28.7778 9H11L18.1111 17.4089V23.2222L21.6667 25V17.4089L28.7778 9Z" stroke="var(--secondary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</template>
							<b-dropdown-item @click="filterByDescription('all')">&nbsp;&nbsp;Todos</b-dropdown-item>                        
							<b-dropdown-item v-for="(x, index) in filterList"
								:key="index"
								:value="x" @click="filterByDescription(x)">                              
								<span class="custom-font-open-12 ml-2">{{x}}</span>                            
							</b-dropdown-item>                        
						</b-dropdown>                                                                                                             
					</div>
				</b-col>                            
			</b-row>
			<b-row class="mt-1">   
                                                
				<b-col class="ml-2" v-if="this.$store.state.loading ==false">
					<div>
						<b-table-simple id="my-table"
							:per-page="perPage"
							:current-page="currentPage" hover small responsive>
							<b-thead head-variant="ligth">
								<b-tr>
									<b-th>Tipo do ID</b-th>
									<b-th>Descrição do ID</b-th> 
									<b-th>Variável</b-th>                
									<b-th>Token do ID</b-th>
									<b-th>Data de Criação</b-th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr :key="index" v-for="(x, index) in list"
									v-show="x.visible ==true">
									<b-td class="td-keyapi">{{x.typeDescription}}</b-td>
									<b-td class="td-date">{{x.objectDescription}}</b-td> 
									<b-td class="td-date">{{x.variableId}}</b-td> 
									<b-td class="td-date" align="right" width="300px"> <span class="mt-1" :id="x.keyId">{{x.keyId}}</span> <span class="icon-clickable" v-b-tooltip.hover title="Clique para copiar a chave" @click="copy(x.keyId)"><img src="@/assets/copy.png"></span>                                                                
									</b-td>                             
									<b-td class="td-date" align="center">{{timeToDate(x.actionTime)}}</b-td>                                                                                                                                                                                                      
								</b-tr>
							</b-tbody>
						</b-table-simple>  
					</div>
				</b-col>   
			</b-row>
			<b-row v-show="rows > perPage" class="pb-5">
				<b-col md="4" class="mt-2">
					<b-row>
						<b-col sm="auto" class="mt-1">
							<span class="mt-2 lblPagination">Visualizar por página:</span> 
						</b-col>
						<b-col sm="auto">                                                 
							<b-form-select v-model="selected" :options="perPageOptions" size="sm" @change="setPerPage()"></b-form-select>                
						</b-col>
					</b-row>
				</b-col>
				<b-col class="mr-5">
					<b-row class="d-flex justify-content-end">
						<b-pagination
							v-model="currentPage"
							:total-rows="rows"
							:per-page="perPage"
							pills
							size="sm"
							aria-controls="my-table"
						></b-pagination> 
					</b-row>
				</b-col>
        
			</b-row>           
		</b-col>
	</div>    
</template>

<script>
import Loading from '@/views/Loading'
import WDictionaryAPI from '@/api/WDictionaryAPI'
import Vue2Filters from 'vue2-filters'
export default {
	mixins: [Vue2Filters.mixin],
	components: {
		loading: Loading,               
	},
	name: 'WDictionaryIDList',
	data: function () {
		return {
			loading: false,
			filter: '',
			filterList: [],
			dictionaryIdList: [],   
			originaldictionaryIdList: [],
			showModalDelete: false,                   
			perPage: 20,
			selected: 20,
			currentPage: 1,  
			totalPage: 1,  
			paginatedItems: [], 
			perPageOptions: [20, 50, 100, 200, 500],    
			keyapiName: '',  
			componentDeleteModalKey: 0,                                             
			popoverShow: false,
			userIndex: null,
			btnPersonalized: true,
			btnDefault: false,
			filteredType: false
		}
	},
	computed: {
		rows () {
			return this.dictionaryIdList.length
		},
		list () {
			const items = this.dictionaryIdList
			items.sort(this.dynamicSort('order')) 
			return items.slice(
				(this.currentPage - 1) * this.perPage,
				this.currentPage * this.perPage
			)
		},      
	},    
  
	methods: {   
		resetFilter () {
			this.dictionaryIdList = []
			if(!this.filter){
				this.originaldictionaryIdList.forEach( el => {
					el.visible = true
					this.dictionaryIdList.push(el)
				})      
			}
		},                       
		dynamicSort (property) {
			let sortOrder = 1
			if(property[0] === '-'){
				sortOrder = -1
				property = property.substr(1)
			}
			return function (a,b) {
				let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
				return result * sortOrder
			}
		},              
		getListDictionaryId () {
			this.$store.commit('setShowLoading', true)
			WDictionaryAPI.getListDictionaryId(this.$route.params.companyId, response => {
				let dictionaryIdList = response.data
				this.$store.commit('setShowLoading', false)
				dictionaryIdList.sort(this.dynamicSort('typeDescription')) 
				dictionaryIdList.forEach(element => {
					element.visible = true     
					if(this.filterList.indexOf(element.typeDescription) == -1){
						this.filterList.push(element.typeDescription)    
					}                                                       
				})
				this.dictionaryIdList = dictionaryIdList 
				this.originaldictionaryIdList = this.dictionaryIdList
			})
		},         
		setPerPage () {
			this.perPage = this.selected
		},
		filterByDescription (typeDescription) {
			if (typeDescription != 'all'){
				this.filteredType = true
				let dictionaryIdList = this.originaldictionaryIdList
				this.dictionaryIdList = []            
				dictionaryIdList.forEach( el => {
					if ( el.typeDescription.toLowerCase().includes(typeDescription.toLowerCase()) ){
						el.visible = true                    
						this.dictionaryIdList.push(el)
					} else {
						el.visible = false
					}
				})                  
			} else {
				let dictionaryIdList = this.originaldictionaryIdList
				this.dictionaryIdList = []            
				dictionaryIdList.forEach( el => {                   
					el.visible = true                    
					this.dictionaryIdList.push(el)
				})                  
			}
		},     
		searchDictionaryId () {            
			if (this.filter != ''){
				if (this.filteredType == true){
					let dictionaryIdList = this.dictionaryIdList
					this.dictionaryIdList = []            
					dictionaryIdList.forEach( el => {
						if ( el.objectDescription.toLowerCase().includes(this.filter.toLowerCase()) ){
							el.visible = true
							this.dictionaryIdList.push(el)
						} else {
							el.visible = false
						}
					})                
				} else {
					let dictionaryIdList = this.originaldictionaryIdList
					this.dictionaryIdList = []            
					dictionaryIdList.forEach( el => {
						if ( el.objectDescription.toLowerCase().includes(this.filter.toLowerCase()) ){
							el.visible = true
							this.dictionaryIdList.push(el)
						} else {
							el.visible = false
						}
					})                   
				}                
			} else {
				this.originaldictionaryIdList.forEach( el => {
					el.visible = true
					this.dictionaryIdList.push(el)
				})                  
			}
		},   
    
		copy (key) {                
			let element = document.getElementById(key)    
			this.selectText(element)            
			document.execCommand('copy')
			try {
				this.makeToast(true)
			} catch (err){
				alert('Erro ao copiar a chave')
			}         
		},   
		selectText (element) {
			let range
			if (document.selection){
				range = document.body.createTextRange()
				range.moveToElementText(element)
				range.select()
			} else if (window.getSelection){
				range = document.createRange()
				range.selectNode(element)
				window.getSelection().removeAllRanges()
				window.getSelection().addRange(range)
			}
		} ,        
		makeToast (append = false) {
			this.toastCount++
			const msg = 'Chave copiada para area de transferência'
			this.$bvToast.toast(msg, {
				title: 'Token da chave',
				autoHideDelay: 2000,
				appendToast: append,
				toaster: 'b-toaster-bottom-center',
				variant: 'default',
				solid: true
			})
		},            
                  
		timeToDate (time) {
			if (time ==0){
				return '-'
			}
			let t = new Date(Number(time))

			let fHourMinute = t.getHours() + ':'
			if(t.getMinutes()<10){
				fHourMinute+='0' 
			}
			fHourMinute+=t.getMinutes()  

			let d = new Date(Number(time))
			let day = d.getDate()        
			if(day < 10){
				day ='0' + d.getDate() 
			}
			let month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1)
			let sDate  = day  + '/' + month + '/' + d.getFullYear() 
			return sDate + ' ' + fHourMinute
		},
		clearFilter () {
			if(this.filter == ''){
				this.searchDictionaryId()
			} 
		}                
	},      
	mounted () {
		this.getListDictionaryId()
	}
}
</script>

<style scoped>

u {
    cursor: pointer;
}
th {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #999999 !important;    
}

td {
    margin: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #777777; 
    height: 50px;
    vertical-align: middle !important;
}
.td-keyapi {
    margin-top: 20px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.td-phonenumber {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;  
}
.td-date {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-data {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-email {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}
.td-keyapi {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}

.td-target {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-fail {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-deliver {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.td-readed {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}



.sidebar-right-keyapis {
    display: flex !important;
    width: 100% !important;
    flex-direction: column !important;
    background: white !important;
    padding: 10px;
    margin-right: 50px;
}
.custom-pagination-input {
    width: 50px !important;
}
.custom-pagination-select {
    width: 60px !important;
}

.dictionary-list-scrollable {
    height: 90vh;
    width: 95%;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
}
.lblPagination {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px; 
    color: #777777;   
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    min-height: 200px;
    -webkit-overflow-scrolling: touch;
}

.form-control:focus {
    background-color: #F5F5F5;
    box-shadow: none;
}
</style>
