import axios from 'axios'
import i18n from '../plugins/i18n'
import { baseApiUrl, access_token} from '../global'


axios.defaults.headers.common['Authorization'] = `bearer ${access_token}`


export default {
   
	getDetail: (companyId, phonenumberId , callback) => {
		const url = `${baseApiUrl}`+'business/'+ companyId + '/' +phonenumberId +'/detail'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},    
	getVertical: (companyId, phonenumberId , callback) => {
		const url = `${baseApiUrl}`+'business/'+ companyId + '/' +phonenumberId +'/vertical'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},   
	postUpdateBusiness (companyId, phonenumberId, business, callback) {
		const params = new URLSearchParams()
		params.append('departmentId', business.departmentId)    
		params.append('alwaysOpen', business.alwaysOpen)     
		params.append('sundayStartHour', business.sundayStartHour)     
		params.append('sundayEndHour', business.sundayEndHour)     
		params.append('mondayStartHour', business.mondayStartHour)        
		params.append('mondayEndHour', business.mondayEndHour)     
		params.append('tuesdayStartHour', business.tuesdayStartHour)     
		params.append('tuesdayEndHour', business.tuesdayEndHour)
		params.append('wednesdayStartHour', business.wednesdayStartHour)     
		params.append('wednesdayEndHour', business.wednesdayEndHour)     
		params.append('thursdayStartHour', business.thursdayStartHour)     
		params.append('thursdayEndHour', business.thursdayEndHour)   
		params.append('fridayStartHour', business.fridayStartHour)        
		params.append('fridayEndHour', business.fridayEndHour) 		
		params.append('saturdayStartHour', business.saturdayStartHour) 
		params.append('saturdayEndHour', business.saturdayEndHour) 
		params.append('readConfirmation', business.readConfirmation) 
		params.append('vertical', business.vertical) 
		params.append('about', business.about) 	
		params.append('description', business.description) 	
		params.append('site', business.site) 												
		params.append('email', business.email) 		
		params.append('address', business.address) 		
		params.append('primaryColor', business.primaryColor) 												
		let url = `${baseApiUrl}` + 'business/'+ companyId + '/' + phonenumberId + '/updateBusiness'
		axios.post(url, params)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)    
				callback(false)            
			})    
	},      	
	postUploadLogo (companyId, phonenumberId, file, callback) {  
		let config = { headers: { 'Content-Type': 'multipart/form-data'}}          
		let url = `${baseApiUrl}` + 'business/' + companyId + '/' + phonenumberId + '/uploadLogo' 
		axios.post(url, file, config)
			.then(response => {
				if (response){                                
					callback(true)        
				}
			})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)                
			})    
	},  	                        
}
