<template>
	<div>
		<b-row>
			<span class="custom-font-open-14-400-gray">Escolha um template para ser enviada como resposta à palavra chave</span>        
		</b-row>    
		<b-row>
			<b-col md="10">
				<div class="search" style="margin-left: -15px;">  
					<b-input-group>
						<b-form-input 
							type="search"                     
							placeholder="Busque pelo nome do template"
							v-model="filter"
							@keyup="searchTemplate"
							class="mt-1"
							@update="clearFilter">
						</b-form-input>
						<b-input-group-prepend is-text class="mt-1">
							<b-icon icon="search"></b-icon>
						</b-input-group-prepend>                    
					</b-input-group>                                                                                       
				</div>
			</b-col>
		</b-row>
		<b-row class="mt-1 mr-2">
			<b-col class="automation-template-scrollable scrollbar">
				<b-table-simple id="my-table" hover small responsive>
					<b-thead head-variant="ligth">
						<b-tr>
							<b-th>Nome do template</b-th>
							<b-th>Categoria</b-th>                
							<b-th>Prévia</b-th>
							<b-th>Mídia</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr :key="index" v-for="(x, index) in orderBy(templates, 'tamplateName')" v-show="x.visible == true">
							<b-td class="td-automation">
								<label :for="x.templateId" @change="setTemplate(x.templateId)">   
									<b-form-radio
										:id="index"
										:name="'option-selected'"
										value="x.value"        
										size="lg">                
										<span style="line-height: 30px">{{x.templateName}}</span>                                                         
									</b-form-radio>                              
								</label>                       
							</b-td>                    
							<b-td class="td-date">{{x.typeDescription}}</b-td>                     
							<b-td class="td-date"><div class="description-ellipsis" v-b-tooltip.hover.left="x.body">{{x.body}}</div></b-td> 
							<b-td class="td-data">												
								<div class="description-ellipsis" v-b-tooltip.hover.left="x.mediaName"  >
									{{x.mediaName}}
								</div>
								
							</b-td>   
						</b-tr>
					</b-tbody>
				</b-table-simple>  
			</b-col>  
		</b-row>
                                       
	</div>                                          
</template>

<script>
import WTemplatesAPI from '@/api/WTemplatesAPI'
import Vue2Filters from 'vue2-filters'
export default {
	mixins: [Vue2Filters.mixin],
	name: 'WAutomationTemplate',    
	data () {
		return { 
			filter: '',
			templates: [],
			templateId: ''
		}
	},    
	methods: {
		getListByCompanyId () {
			WTemplatesAPI.getListByCompanyId(this.$route.params.companyId, response => {
				let templates = response.data
				templates.forEach(temp => {
					temp.visible = true
					temp.value = false
				})
				this.templates = templates
			})
		},     
		searchTemplate () {
			this.templates.forEach( el => {
				if ( el.templateName.toLowerCase().includes(this.filter.toLowerCase()) ){
					el.visible = true
				} else {
					el.visible = false
				}
			})        
		},  
		setTemplate (id) {            
			let obj = {id: id}
			this.$emit('sendMessageData', obj)              
		},
		clearFilter () {
			if(this.filter == ''){
				this.searchTemplate()
			}
		}                         
	},
	mounted () {     
		this.getListByCompanyId()                       
	}, 
}
</script>

<style scoped>
.automation-template-scrollable {
    height: 40vh;
    width: 100%;
    min-height: 100px;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden; 
}
th {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #999999 !important;    
}

td {
    margin: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #777777; 
    height: 50px;
    vertical-align: middle !important;
}
.td-automation {
    padding-top: 15px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px; 
    color: #777777;
}
.td-data {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.description-ellipsis { 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
}
.description-ellipsis { 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
}
</style>

 
