<template>
	<div style="display:flex">
		<side-bar-menu :key="reload" :loadedItem="item" :menuItems="menuItems" @openMenuItem="openMenuItem" />
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==1">
			<setting-company 
				v-on:sendMessageData="getMessageData"
			/>
		</b-col>  
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==2">
			<setting-phonenumber-list 
				v-on:sendMessageData="getMessageData"
			/>
		</b-col>        
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==2.1">
			<setting-phonenumber 
				:step="step" v-on:sendMessageData="getMessageData"
			/>
		</b-col>
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==2.2">
			<setting-phonenumber-report 
				:step="step" v-on:sendMessageData="getMessageData"
			/>
		</b-col> 	
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==2.3">
			<setting-phonenumber-finalize 
				:step="step" v-on:sendMessageData="getMessageData"
			/>
		</b-col> 		       
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==3">
			<setting-attendants-list 
				v-on:sendMessageData="getMessageData"
			/>
		</b-col>                                     
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==3.1 || step==3.2">
			<setting-attendants-data
				:step="step" v-on:sendMessageData="getMessageData"
			/>
		</b-col>   
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==3.3 || step==3.4">
			<setting-attendant-departments
				:step="step" v-on:sendMessageData="getMessageData"
			/>
		</b-col>                                    
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==3.5 || step==3.6">
			<setting-attendant-action 
				:step="step" v-on:sendMessageData="getMessageData"
			/>
		</b-col>  
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==4">
			<setting-department-list 
				v-on:sendMessageData="getMessageData"
			/>
		</b-col> 
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==4.1 || step==4.2">
			<setting-department
				:step="step" v-on:sendMessageData="getMessageData"
			/>
		</b-col>   
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==4.3 || step==4.4">
			<setting-department-attendants
				:step="step" v-on:sendMessageData="getMessageData"
			/>
		</b-col>    
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==4.5 || step==4.6">
			<setting-department-permission
				:step="step" v-on:sendMessageData="getMessageData"
			/>
		</b-col>                                      
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==5">
			<setting-property-list 
				v-on:sendMessageData="getMessageData"
			/>
		</b-col> 
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==5.1 || step == 5.2">
			<setting-property
				:step="step" v-on:sendMessageData="getMessageData"
			/>
		</b-col>       
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==6 || step == 6.1">
			<setting-label-list 
				:step="step" v-on:sendMessageData="getMessageData"
			/>
		</b-col> 
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==7">
			<setting-facebook 
				v-on:sendMessageData="getMessageData"
			/>
		</b-col>
	</div>   
</template>

<script>
import WPhoneNumberList from './numbers/WPhoneNumberList'
import WPhoneNumber from './numbers/WPhoneNumber'
import WPhoneNumberFinalize from './numbers/WPhoneNumberFinalize'
import WPhoneNumberReport from './numbers/WPhoneNumberReport'
import WAttendantList from './attendants/WAttendantList'
import WAttendant from './attendants/WAttendant'
import WAttendantDepartments from './attendants/WAttendantDepartments'
import WAttendantAction from './attendants/WAttendantAction'
import WDepartmentList from './departments/WDepartmentList'
import WDepartment from './departments/WDepartment'
import WDepartmentAttendants from './departments/WDepartmentAttendants'
import WDepartmentPermission from './departments/WDepartmentPermission'
import WPropertyList from './properties/WPropertyList'
import WProperty from './properties/WProperty'
import WLabelList from './labels/WLabelList'
import WCompanyView from './company/WCompanyView'
import SideBarMenu from '@/components/global/SideBarMenu'
import WFaceBookMain from './facebook/WFaceBookMain'

export default {    
	components: 
	{   
		'setting-phonenumber-list': WPhoneNumberList,
		'setting-phonenumber': WPhoneNumber,
		'setting-phonenumber-finalize': WPhoneNumberFinalize,
		'setting-phonenumber-report': WPhoneNumberReport,
		'setting-attendants-list': WAttendantList,
		'setting-attendants-data': WAttendant,
		'setting-attendant-departments': WAttendantDepartments,
		'setting-attendant-action': WAttendantAction, 
		'setting-department-list': WDepartmentList,     
		'setting-department': WDepartment,  
		'setting-department-attendants': WDepartmentAttendants,     
		'setting-department-permission': WDepartmentPermission,                       
		'setting-property-list': WPropertyList,           
		'setting-property': WProperty,      
		'setting-label-list': WLabelList,           
		'setting-company':WCompanyView,
		'side-bar-menu':SideBarMenu,               
		'setting-facebook':WFaceBookMain  
	},
	name: 'WSettingsMain',  
	data: function () {
		return {
			step: 1,
			item: 0,
			loading: false,
			settingName: '',
			phoneNumber: '',
			departmentName: '',
			templateName: '',
			settingStartAt: '',
			whocalled: '',
			reload:0,
			menuItems:[
				{
					name:'Empresa',
				},
				{
					name:'Número'
				},
				{
					name:'Usuários',
				},
				{
					name:'Departamentos'
				},
				{
					name:'Propriedades'
				},
				{
					name:'Marcações'
				},
				{
					name:'Facebook'
				},
			]
		}
	},
	methods: { 
		openMenuItem (obj) {
			switch(obj.index){
			case 0:
				this.step =1
				break
			case 1:
				this.step = 2
				break
			case 2:
				this.step = 3	
				break
			case 3:
				this.step = 4	
				break
			case 4:
				this.step = 5	
				break
			case 5:
				this.step = 6
				break
			case 6:
				this.step = 7
				break

			}

			localStorage.removeItem('__wpp_settings_obj') 
			localStorage.removeItem('__wpp_setting_step') 
			let objStep = {currentStep: this.step, currentMenuItem: obj.index}
			localStorage.setItem('__wpp_setting_step', JSON.stringify(objStep)) 
		},
		openCompany () {
			this.item = 1            
			this.step = 1            
			localStorage.removeItem('__wpp_settings_obj') 
			localStorage.removeItem('__wpp_setting_step') 
			let objStep = {currentStep: this.step, currentMenuItem: this.item}
			localStorage.setItem('__wpp_setting_step', JSON.stringify(objStep))             
		},     
		openPhoneNumberList () {
			this.item = 2             
			this.step = 2            
			localStorage.removeItem('__wpp_settings_obj') 
			localStorage.removeItem('__wpp_setting_step') 
			let objStep = {currentStep: this.step, currentMenuItem: this.item}
			localStorage.setItem('__wpp_setting_step', JSON.stringify(objStep))             
		},        
		openAttendentsList () {
			this.item = 3             
			this.step =3            
			localStorage.removeItem('__wpp_settings_obj') 
			localStorage.removeItem('__wpp_setting_step') 
			let objStep = {currentStep: this.step, currentMenuItem: this.item}
			localStorage.setItem('__wpp_setting_step', JSON.stringify(objStep))             
		},
		openDepartmentList () {
			this.item = 4            
			this.step =4
			localStorage.removeItem('__wpp_settings_obj') 
			localStorage.removeItem('__wpp_setting_step') 
			let objStep = {currentStep: this.step, currentMenuItem: this.item}
			localStorage.setItem('__wpp_setting_step', JSON.stringify(objStep))             
		},        
		openPropertiesList () {
			this.item = 5
			this.step = 5
			localStorage.removeItem('__wpp_settings_obj') 
			localStorage.removeItem('__wpp_setting_step') 
			let objStep = {currentStep: this.step, currentMenuItem: this.item}
			localStorage.setItem('__wpp_setting_step', JSON.stringify(objStep))              
		},      
		openLabelList () {
			this.item = 6
			this.step = 6
			localStorage.removeItem('__wpp_settings_obj') 
			localStorage.removeItem('__wpp_setting_step') 
			let objStep = {currentStep: this.step, currentMenuItem: this.item}
			localStorage.setItem('__wpp_setting_step', JSON.stringify(objStep))              
		},              
		getMessageData (obj) {
			localStorage.removeItem('__wpp_setting_step')        
			let objStep = {currentStep: obj.step, currentMenuItem: this.item}
			localStorage.setItem('__wpp_setting_step', JSON.stringify(objStep))            
			this.step = obj.step
		},       
	},
	mounted () {
		let step = JSON.parse(localStorage.getItem('__wpp_setting_step')) 
		console.log(step)
		if (step != null && step != undefined){
			this.step = step.currentStep   
			this.item = step.currentMenuItem
			this.reload++
		}        
	}    
}
</script>


<style scoped>

.nav-link {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 20px !important;  
    color: #414141;
}

.active {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 20px !important;  
    color: var(--primary);
    background: #F5F5F5;    
    border-right: solid 3px var(--primary);    
}

.opacity {
	opacity: 0.6;
}
</style>

