<template>
	<div class="account-content">
		<div class="account-modal">    
			<img src="@/assets/logo.png" width="200px" height="150px" alt="Logo" class="mb-3"/>         
			<Loading v-if="loading===true" class="ml-2 mt-1" style="background-color: white; width: 300px;height: 200px;"/>   
			<label v-show="loading===false" for="user-name">{{$t("CreateAccount.name")}}
				<input id="user-name" v-model="user.name" name="name" type="text" :placeholder="$t('CreateAccount.enterName')">
			</label>
			<label v-show="loading===false" for="user-phoneNumber">{{$t("CreateAccount.phoneNumber")}}
				<input id="user-name" v-model="user.phoneNumber" name="phoneNumber" type="text" :placeholder="$t('CreateAccount.enterPhoneNumber')">
			</label>            
			<label v-show="loading===false" for="user-password">{{$t("CreateAccount.password")}}
				<input id="user-password" v-model="user.password" name="password" type="password" :placeholder="$t('CreateAccount.enterPassword')">
			</label>
			<label v-show="loading===false" for="user-confirmpassword">{{$t("CreateAccount.passwordConfirm")}}
				<input id="user-confirmpassword" v-model="user.confirmpassword" name="passwordConfirm" type="password" :placeholder="$t('CreateAccount.enterPasswordConfirm')">    
			</label>    
			<button v-show="loading===false" @click="createAccount">{{$t("CreateAccount.createAccount")}}</button>   
			<h5 v-show="loading===true">Criando a conta...</h5>                                
		</div>
	</div>
</template>

<script>
import Loading from '@/views/Loading'
import { baseApiUrl, config } from '@/global'
import axios from 'axios'
export default {
	components: {Loading},
	name: 'CreateAccount',
	data () {
		return {
			loading: false,
			user: {}
		}
	},
	methods: {
		createAccount () {     
			if (this.user.name === '' || this.user.name === undefined ){
				alert(this.$t('CreateAccount.msgFieldName'))
				return
			}             
			if (this.user.confirmpassword != this.user.password ){
				alert(this.$t('CreateAccount.msgFieldConfirm'))
				return
			} 

			if (this.user.phoneNumber.length < 10){
				alert(this.$t('CreateAccount.msgFieldPhoneNumber'))
				return
			}  

			if (this.user.phoneNumber.length >= 13){
				alert(this.$t('CreateAccount.msgFieldPhoneNumber'))
				return
			} 

			if (this.user.password.length < 6){
				alert(this.$t('CreateAccount.msgFieldPassword6Digits'))
				return
			}            
			if (this.user.password === '' || this.user.password === undefined){
				alert(this.$t('CreateAccount.msgFieldPassword'))
				return
			} 
			if (this.user.confirmpassword === '' || this.user.confirmpassword === undefined){
				alert(this.$t('CreateAccount.msgFieldPasswordConfirm'))
				return
			}             
			const params = new URLSearchParams()
			params.append('signupId', this.$route.params.signupId)
			params.append('fullname', this.user.name)      
			params.append('apsManaged', '')      
			params.append('password', this.user.confirmpassword)   
			params.append('phoneNumber', this.user.phoneNumber)  
			this.loading = true    
			axios.post(`${baseApiUrl}signup/` + this.$route.params.signupId + '/userDataWithPhoneNumber', params, config)
				.then(response => {
					this.loading = false    
					let signupId = response.data
					if (signupId != null || signupId != '' || signupId != undefined){
						this.$router.push({ path: '/signup/' + this.$route.params.signupId + '/success'})    
					}       
				})
				.catch(function (error) {  
					alert(this.$t('Message.error') + error)
				})
		}        
	}
}
</script>


<style>
    .account-content {
        margin-top: 100px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .account-modal {
        background-color: #FFF;
        width: 350px;
        padding: 35px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .forgot-title {
        font-size: 1.2rem;
        font-weight: 200;
        margin-top: 10px;
        margin-bottom: 15px;
        justify-content: center;  
        margin-left: 15px;   
    }

    .account-modal input {
        border: 1px solid #BBB;
        width: 100%;
        margin-bottom: 15px;
        padding: 12px 17px;
        margin: 2px 0 11px;
        padding-left: 10px;
        height: 40px;
        outline: none;  
        border-radius: 10px;    
    }

    .account-modal button {
        background-color: var(--primary);
        color: #FFF;
        padding: 5px 15px;
        width: 200px;
        justify-content: center;
        align-items: center;
        font-family: 'Montserrat','HelveticaNeue','Helvetica Neue','sans-serif';
        font-size:16px;
        color:white;    
        font-weight: 500;  
        cursor: pointer;
        padding: 8px;
        border-radius: 10px;          
    }

    .account-modal a {
        margin-top: 5px;
        font-size: 16px;
        font-weight: 500;
    }

    .remember {
        margin-top: 5px;
        font-size: 16px;
        color: gray;
    }

    hr.forgot {
        border: 1px solid gray;
        width: 260px;
    }    
 
</style>
