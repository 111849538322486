<template>
	<div>
		<slot></slot>
	</div>
</template>
<script>
import WArrayBufferToBase64 from '../js/WArrayBufferToBase64'
export default {
	name: 'WMediaLoad',
	props: [
		'mediaId',
		'mediaPath', 
		'mediaType',
		'mimeType'
	],
	data () {
		return {         
		}
	},    
	methods: {        
		getCacheStorageMedia () {
			let mediaId = this.mediaId
			if ('caches' in window) {				
				caches.open('__wpp_cache_media').then((cache) => {
					let request = '/'+ mediaId					
					cache.match(request).then((response)=> {   
						if (response != undefined) {                                        
							response.text().then((s) => {								
								let mediaUrl = 'data:' + this.mimeType +';base64,'  + s                                                                                                                                            																				
								document.getElementById('media').src = mediaUrl
							}) 
						} else {
							let url = this.mediaPath
							document.getElementById('media').src = this.mediaPath
							let blobResponse = null					
							WArrayBufferToBase64.getArrayBufferMedia(url, function (result) {                                
								const options = {
									headers: {'Content-Type': 'text/plain'}
								}                                       
								blobResponse = new Response(result, options)                           
								if ('caches' in window) {
									caches.open('__wpp_cache_media').then(cache => {
										cache.put('/' + mediaId , blobResponse)
									})
								}                                                                                                                          
							})   		                                     							
						}      						               
					})
				}).catch((err) => {
					console.log(err)
				})
			}  		
		},				
	},    
	mounted () {
		this.getCacheStorageMedia()
	}, 
}
</script>

<style scoped>   

</style>