<template>
	<div class="content">
		<router-view :key="$route.fullPath"/>
	</div>  
</template>

<script>
export default {
	name: 'Content',
	methods: {
     
	},
	mounted () {		
		if (this.$route.fullPath.includes('/undefined/')) {
			this.$router.push({ path: '/'}) 
		}		
	}
}
</script>

<style>


.content {
    grid-area: content;
    background-color:#f4f4f5;
}
</style>