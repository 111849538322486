<template>
	<div class="message-template">
		<header v-show="messageInfo.message.header != ''">
			<h5 style="color: white;">{{messageInfo.message.header}}</h5>
		</header>
		<article>
			<div v-html="replaceMulti(messageInfo.message.body)" style="max-width:300px">
			</div>                            
		</article>
		<footer>
			<b-row>
				{{messageInfo.message.footer}}
			</b-row>
			<b-row class="d-flex justify-content-center">
				<span v-for="(b, b_index) in messageInfo.message.buttons" :key="b_index" v-show="messageInfo.message.buttons != ''" class="template-button mr-2">{{b}}</span>           
			</b-row>                    
		</footer>                 
	</div>                                                                                                                                                                                                     
</template>

<script>

export default {
	name: 'WMessageTemplates',   
	props: [
		'messageInfo'
	],
	data () {
		return {
             
		}
	},    
	methods: {        
		replaceMulti (haystack) {
			if (typeof haystack === 'string' || haystack instanceof String) {
				if (haystack != undefined){
					return haystack.split('\\n').join('<br>')                
				}
			}
		},        
                    
	},    
	mounted () {
	}, 
}
</script>

<style scoped>
    header {
        height: 30px;
        text-align: center;
        margin-bottom: 10px;
        padding: 20px;
    }
    article {
        margin-top: 10px;
        padding: 5px;
        text-align: center;
        padding: 20px;
    }
    footer {
        margin-top: 10px;
        height: auto;
        text-align: center; 
        padding: 10px;  
    }
    .default-message {
        width: 100%; 
        margin: 10px 10px 0px;
        padding: 10px; 
        display: flex;
        background-color: var(--secondary);
        height: auto; 
        min-height:20px;
        border-radius: 8px; 
        color: white; 
        align-items: center; 
        justify-content: center; 
        font-family: 'Roboto'; 
        font-size: 12px;  
    }  
    .dateReceived {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        color: #777777;
    }    
    .dateSent {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        color: #777777;
    }
    video {
        pointer-events: none;
    }  
    .a {
        display: flex;
    }          
    .a >>> .b {
        display: contents !important;
    }       
</style>



 