<template>
	<div>
		<b-row class="user-list-row">
			<b-col md="3" class="user-list-col mt-1" :style="this.$mq == 'xs' ? 'width: 20%; padding-left:0;' : 'width: 100%;padding-left:15px;'">
				<div class="avatar" style="background-color:var(--primary)">{{ contact.name.substring(0,2).toUpperCase() }}</div>
			</b-col>
			<b-col md="8" :style="this.$mq == 'xs' ? 'width: 75%;' : 'width: 100%;'">
				<b-row>
					<span :class=" `users-list-name-${$mq}`" v-b-tooltip.hover :title="contact.name"> {{ contact.name }}</span>
					<span class="last-access"> {{ contact.lastAccess }} </span>
					<span class="opened-message" v-if="contact.opened == 0">
						<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="4" cy="4" r="4" fill="var(--secondary)"/>
						</svg>
					</span>
				</b-row>
				<b-row class="mb-2">                                                                      
					<span class="message-preview ml-1 mt-1" v-b-tooltip.hover :title="contact.lastMessage">
						<span v-show="contact.messageType =='audio'" >
							<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1.5 9V6C1.5 4.80653 1.97411 3.66193 2.81802 2.81802C3.66193 1.97411 4.80653 1.5 6 1.5C7.19347 1.5 8.33807 1.97411 9.18198 2.81802C10.0259 3.66193 10.5 4.80653 10.5 6V9" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M10.5 9.5C10.5 9.76522 10.3946 10.0196 10.2071 10.2071C10.0196 10.3946 9.76522 10.5 9.5 10.5H9C8.73478 10.5 8.48043 10.3946 8.29289 10.2071C8.10536 10.0196 8 9.76522 8 9.5V8C8 7.73478 8.10536 7.48043 8.29289 7.29289C8.48043 7.10536 8.73478 7 9 7H10.5V9.5ZM1.5 9.5C1.5 9.76522 1.60536 10.0196 1.79289 10.2071C1.98043 10.3946 2.23478 10.5 2.5 10.5H3C3.26522 10.5 3.51957 10.3946 3.70711 10.2071C3.89464 10.0196 4 9.76522 4 9.5V8C4 7.73478 3.89464 7.48043 3.70711 7.29289C3.51957 7.10536 3.26522 7 3 7H1.5V9.5Z" fill="#999999" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
						</span>
						<span v-show="contact.messageType =='video'" >
							<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M11.5556 3.3151C11.5556 2.85534 10.9873 2.63929 10.6819 2.98292L8.29527 5.66782C8.12688 5.85726 8.12688 6.14274 8.29527 6.33218L10.6819 9.01708C10.9873 9.36071 11.5556 9.14466 11.5556 8.6849V3.3151Z" fill="#999999"/>
								<path d="M6.78261 2H1.04348C0.467181 2 0 2.48842 0 3.09091V8.54545C0 9.14795 0.467181 9.63636 1.04348 9.63636H6.78261C7.3589 9.63636 7.82609 9.14795 7.82609 8.54545V3.09091C7.82609 2.48842 7.3589 2 6.78261 2Z" fill="#999999"/>
							</svg>
						</span>
						<span v-show="contact.messageType =='image'" >
							<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M11.2071 10.2071C11.3946 10.0196 11.5 9.76522 11.5 9.5V4C11.5 3.73478 11.3946 3.48043 11.2071 3.29289C11.0196 3.10536 10.7652 3 10.5 3H8.5L7.5 1.5H4.5L3.5 3H1.5C1.23478 3 0.98043 3.10536 0.792893 3.29289C0.605357 3.48043 0.5 3.73478 0.5 4V9.5C0.5 9.76522 0.605357 10.0196 0.792893 10.2071C0.98043 10.3946 1.23478 10.5 1.5 10.5H10.5C10.7652 10.5 11.0196 10.3946 11.2071 10.2071ZM8 6.5C8 7.60457 7.10457 8.5 6 8.5C4.89543 8.5 4 7.60457 4 6.5C4 5.39543 4.89543 4.5 6 4.5C7.10457 4.5 8 5.39543 8 6.5Z" fill="#999999"/>
							</svg>
						</span>
						<span v-show="contact.messageType =='document'" >
							<svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M4.5 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V9C0 9.26522 0.105357 9.51957 0.292893 9.70711C0.48043 9.89464 0.734784 10 1 10H7C7.26522 10 7.51957 9.89464 7.70711 9.70711C7.89464 9.51957 8 9.26522 8 9V3.5L4.5 0Z" fill="#999999"/>
							</svg>
						</span>                     
						<span v-if="contact.messageType =='contacts'" > 
							<svg v-show="contact.messageType =='contacts'"  width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M6 0C4.25534 0 2.83594 1.4194 2.83594 3.16406C2.83594 4.90873 4.25534 6.32812 6 6.32812C7.74466 6.32812 9.16406 4.90873 9.16406 3.16406C9.16406 1.4194 7.74466 0 6 0Z" fill="#999999"/>
								<path d="M9.9367 8.3952C9.07045 7.51563 7.92209 7.03125 6.70312 7.03125H5.29688C4.07794 7.03125 2.92955 7.51563 2.0633 8.3952C1.20129 9.27045 0.726562 10.4258 0.726562 11.6484C0.726562 11.8426 0.883969 12 1.07812 12H10.9219C11.116 12 11.2734 11.8426 11.2734 11.6484C11.2734 10.4258 10.7987 9.27045 9.9367 8.3952Z" fill="#999999"/>
							</svg>
						</span>                      
						{{ contact.lastMessage}}						
					</span>
				</b-row>  
				<b-row>  
					<b-col md="9">
						<b-row class="d-flex justify-content-start">
							<div class="user-list-department">
								<b-badge pill variant="secondary" :style="'max-width:105px;background-color:'+contact.primaryColor">
									<div v-b-tooltip.hover :title="contact.departmentName" :style="this.$mq != 'xl'? 'white-space: nowrap;overflow: hidden; text-overflow: ellipsis; max-width: 100px;' : 'white-space: nowrap;overflow: hidden; text-overflow: ellipsis;'">{{ contact.departmentName}}</div>
								</b-badge>
							</div>
							<div v-if="contact.chatFlow == true" class="user-list-department ml-1">
								<b-badge pill variant="secondary" :style="'background-color:var(--primary)'">
									<div :style="this.$mq != 'xl'? 'white-space: nowrap;overflow: hidden; text-overflow: ellipsis; max-width: 130px;' : 'white-space: nowrap;overflow: hidden; text-overflow: ellipsis; max-width: 180px;'">BOT</div>
								</b-badge>
							</div>
						</b-row>                    
					</b-col>    
					<b-col md="3">
						<b-row>
							<span :class=" `status-description status-color-${contact.chatStatus}`">{{contact.statusDescription}}</span>
						</b-row>                                        
					</b-col>                                                                      
				</b-row>                                                                                
			</b-col>                                    
		</b-row>               
	</div>                                          
</template>

<script>
export default {
	name: 'WChatContacts',
	props: [
		'contact'
	],
	data () {
		return {  
			colors: ['var(--primary)', 'var(--primary)']          
		}
	},    
	methods: {
		numberFromText (text) {
			// numberFromText("AA");
			const charCodes = text
				.split('') // => ["A", "A"]
				.map(char => char.charCodeAt(0)) // => [65, 65]
				.join('') // => "6565"
			return parseInt(charCodes, 10)
		}              
	},
	mounted () {
		setTimeout(() => {
			const avatars = document.querySelectorAll('.avatar')
			avatars.forEach(avatar => {
				const text = avatar.innerText // => "AA"
				avatar.style.backgroundColor = this.colors[this.numberFromText(text) % this.colors.length] // => "#DC2A2A"
			})             
		}, 100)
	}, 
}
</script>

<style scoped>
    .avatar {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ccc;
        border-radius: 50%;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
    }    
</style>

 
