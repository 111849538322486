<template>
	<div>
		<span class="td-attendant">
			<b-row class="ml-0">
				<span v-if="!content" class="td-contact"><u @click="openLabelDetail(item.contactId, item.waId)">{{label}} não cadastrado</u></span>
				<span v-else class="td-contact"><u @click="openLabelDetail(item.contactId, item.waId)">{{content}}</u></span>
			</b-row>
											
		</span>
	</div>
</template>

<script>
import { avatarUrl} from '@/global'
export default {
	name: 'TableCellDepartment',
	data () {
		return {	
			avt : avatarUrl + this.$route.params.companyId + '&imageId='
		}
	},
	props: [
		'item',
		'content',
		'id',
		'label'
	],
	methods:{
		openLabelDetail (id, waId) {
			this.$emit('openLabelDetail',id,waId)
		}
	},
	mounted () {
		// this.avt = avatarUrl + this.$route.params.companyId + '&imageId='+this.id 
	}
}

</script>

<style>
.td-attendant {
    margin-top: 20px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}
</style>