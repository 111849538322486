<template>
	<div v-if="step == 7">
		<tableT v-if="pixelList" 
			:list="pixelList" 
			:fields="fieldsPixel" 
			:perPage="10" 
			:searchBar="true"
			:searchPlaceHolder = "'Busque pelo nome'"
			:sortBy ="''"
			:filterFields="['pixelName']"
			:selected="10"
			@radioClicked="radioClicked"
		/>
		<div class="w-100 ml-5 mt-3">
			<b-button variant="outline-primary" class="mr-2"
				@click="cancel">Cancelar
			</b-button>   
			<b-button variant="primary" class="float-right mr-5"
				@click="next" :disabled="!selectedPixel">Avançar         
			</b-button>  
		</div> 
	</div>     
	<div v-else-if="step == 7.1">
		<div class="mb-1">
			<span class="custom-font-open-14-400-gray">Nome/Descrição do Pixel:</span>
			<br>
			<span class="custom-font-open-12-400">{{selectedPixel.pixelName}}</span>
		</div>
		<div class="mb-1">
			<span class="custom-font-open-14-400-gray">ID do Pixel</span>
			<br>
			<span class="custom-font-open-12-400">{{selectedPixel.pixelId}}</span>
		</div>
		<div class="mb-5">
			<span class="custom-font-open-14-400-gray">Nome do Evento</span>
			<br>
			<b-form-select v-model="selectedEvent" :options="eventList" style="width:200px"></b-form-select>
		</div>
		
		<div class="w-100 mt-3">
			<b-button variant="outline-primary" class="mr-2"
				@click="cancel">Cancelar
			</b-button>   
			<b-button variant="primary" class="float-right mr-5"
				@click="next" :disabled="!selectedEvent">Avançar         
			</b-button>  
		</div> 
	</div>                                    
</template>

<script>
import WFacebookAPI from '@/api/WFacebookAPI'
import Table from '@/components/global/TableComponent/Table'
export default {
	name: 'WKeyWordsPixel',   
	components:{
		tableT:Table,
	}, 
	data () {
		return {
			pixelList:undefined,
			fieldsPixel: [
				{
					key:'radio',
					label:''
				},
				{
					key: 'pixelName',
					sortable: true,
					label:'Nome/Descrição do Pixel'
					
				},
				{
					key: 'pixelId',
					sortable: true,
					label:'Pixel ID'
				},
			],
			selectedPixel:undefined,
			step: 7,
			eventList:[],
			selectedEvent:undefined
		}
	},
	watch:{
		selectedEvent () {
			// let aux = {id: this.selectedPixel.pixelId,step: 7}
			// this.$emit('sendMessageData', aux)
		}
	},
	computed: {	
	},       
	methods: {
		cancel () {      
			this.$emit('cancel')             
		},   
		next () {
			this.step = 7.1
			if(this.selectedEvent){
				let aux = {id: this.selectedPixel.pixelId,step: 7,extra:this.selectedEvent}
				this.$emit('sendMessageData', aux)
				aux = {id: this.selectedPixel.pixelId}
				this.$emit('next', aux)
			}
			// let aux = {id: 7.1,step: 7.1}
			// this.$emit('sendMessageData', aux)
		},
		radioClicked (obj) {
			this.selectedPixel = obj
			this.eventList = this.selectedPixel.eventList
			// this.step = 7.1
		},
		getPixelList () {
			this.loading = true
			this.pixelList = undefined
			WFacebookAPI.getPixelListApi(this.$route.params.companyId,response=>{
				this.pixelList = response.data
				this.loading = false
			})
		},
	},
	mounted () {  
		this.getPixelList()  
	}, 
}
</script>

<style scoped>

</style>

 
