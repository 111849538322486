<template>
	<b-col class="quickreply-list-scrollable scrollbar">
		<b-row class="ml-1">
			<b-col class="mt-2">
				<b-row class="ml-1">
					<span class="lbl-title">Respostas rápidas</span>
				</b-row>
				<b-row class="ml-1 mb-1">
					<span class="custom-font-open-18-400">Elas servem para otimizar seu tempo!</span>
				</b-row>                
			</b-col>
			<b-col class="mt-4">
				<b-row class="justify-content-end mr-5">
					<b-button class="mr-2" v-b-toggle.sidebar-right variant="secondary"
						@click="create()">
						<span>
							<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M8 3V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M3 8H13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
						</span>
						<span class="ml-1">
							Adicionar resposta
						</span>                        
					</b-button>                                                      
				</b-row>
			</b-col>
		</b-row>
		<b-row class="mt-2">
			<b-col class="mt-2" md="8">
				<b-row class="ml-1"> 	
					<div class="ml-2">
						<search-check-box-filter
							:departmentFilter="true"
							:sortBy="'text'"
							v-on:checkedItems="setSelectedItems($event, originalQuickReplyList)"
						/>
					</div>		
				</b-row>				
			</b-col>
			<b-col md="4">						
				<b-row class="justify-content-end mt-1 mr-5">
					<b-input-group>
						<b-form-input 
							type="search"                     
							placeholder="Buscar resposta"
							v-model="filter"
							@keyup="searchQuickReply"
							class="mt-1"
							@update="clearFilter()">
						</b-form-input>
						<b-input-group-prepend is-text class="mt-1">
							<b-icon icon="search"></b-icon>
						</b-input-group-prepend>                    
					</b-input-group> 						
				</b-row>
			</b-col>
		</b-row>  
		<b-col md="12" sm="12">
			<loading v-if="this.$store.state.loading ==true"/>  
			<!-- <b-row style="margin-rigth:30px;background-color:red" v-if="this.$store.state.loading ==false">
				<b-col md="3">
					<search-check-box-filter
						:list="departmentList"
						:sortBy="'text'"
						v-on:checkedItems="setSelectedItems($event, originalQuickReplyList)"
					/>
				</b-col>
				<b-col md="8">
					<b-input-group>
						<b-form-input 
							type="search"                     
							placeholder="Buscar resposta"
							v-model="filter"
							@keyup="searchQuickReply" class="mt-1">
						</b-form-input>
						<b-input-group-prepend is-text class="mt-1">
							<b-icon icon="search"></b-icon>
						</b-input-group-prepend>                    
					</b-input-group>                                                                                       
				</b-col>                       
			</b-row> -->
			<b-row class="mt-1">      
				<div>
					<quickreply-modal-delete
						:title = "'Excluir palavras chave'"
						:subTitle = "'Tem certeza que deseja excluir a(s) palavra(s) chave?'"
						:description = "quickreplyName"
						:description1= "''"
						:value = "''"
						:placeholder = "''"
						:button1 = "'Fechar'"
						:button2 = "'Excluir'"
						:start="showModalDelete"   
						v-on:sendModalData="deleteQuickReply"
						:key="componentDeleteModalKey"                        
					/>
				</div>                                                 
				<b-col class="ml-2" v-if="this.$store.state.loading ==false">
					<div>
						<b-table-simple id="my-table"
							:per-page="perPage"
							:current-page="currentPage" hover small responsive>
							<b-thead head-variant="ligth">
								<b-tr>
									<b-th>Atalho</b-th>
									<b-th>Descrição</b-th>                
									<b-th>Tipo</b-th>
									<b-th>Prévia</b-th>
									<b-th>Mídia</b-th>
									<b-th>Departamentos</b-th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr :key="index" v-for="(x, index) in lists"
									v-show="x.visible ==true">
									<b-td class="td-quickreply">{{x.quickReplyCommand}}</b-td>
									<b-td class="td-phonenumber">{{x.quickReplyDescription}}</b-td>                     
									<b-td class="td-date">{{x.quickReplyTypeDescription}}</b-td> 
									<b-td class="td-data">												
										<div class="description-ellipsis" v-b-tooltip.hover.left="x.quickReplyContent"  >
											{{x.quickReplyContent}}
										</div>
									</b-td>   	
									<b-td class="td-data">												
										<div class="description-ellipsis" v-b-tooltip.hover.left="x.mediaName"  >
											{{x.mediaName}}
										</div>
									</b-td>   
									<b-td class="td-data" v-show="x.departmentCount < 2 && x.departmentAll == false">{{x.departmentCount}} departamento</b-td> 
									<b-td class="td-data" v-show="x.departmentCount > 1 && x.departmentAll == false">{{x.departmentCount}} departamentos</b-td>      
									<b-td class="td-data" v-show="x.departmentAll == true">Todos departamentos</b-td>      
									<b-td>                  
										<b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" right text="Right align" no-caret>
											<template #button-content>
												<svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
													<circle cx="2" cy="2" r="2" fill="#C4C4C4"/>
													<circle cx="2" cy="11" r="2" fill="#C4C4C4"/>
													<circle cx="2" cy="20" r="2" fill="#C4C4C4"/>
												</svg>
											</template>
											<b-dropdown-item @click="editQuickReply(x.quickReplyId, x.quickReplyType)">
												<span>
													<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M10.0779 1.50136C10.2368 1.34241 10.4255 1.21632 10.6332 1.1303C10.8409 1.04428 11.0635 1 11.2883 1C11.513 1 11.7356 1.04428 11.9433 1.1303C12.151 1.21632 12.3397 1.34241 12.4986 1.50136C12.6576 1.66031 12.7837 1.84901 12.8697 2.05669C12.9557 2.26436 13 2.48695 13 2.71174C13 2.93653 12.9557 3.15912 12.8697 3.3668C12.7837 3.57447 12.6576 3.76317 12.4986 3.92212L4.32855 12.0922L1 13L1.90779 9.67145L10.0779 1.50136Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
													</svg>
												</span>
												<span class="custom-font-open-12-400 ml-1">
													Editar conteúdo
												</span>                                        
											</b-dropdown-item>  
											<b-dropdown-item  @click="editDepartments(x.quickReplyId, x.departmentAll)">
												<span>
													<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M9.04183 4.37435L11.0835 2.33268M12.2502 1.16602L11.0835 2.33268L12.2502 1.16602ZM6.64433 6.77185C6.94553 7.06904 7.18496 7.42287 7.34885 7.81298C7.51274 8.20308 7.59784 8.62175 7.59926 9.04488C7.60068 9.46801 7.51838 9.88725 7.35711 10.2784C7.19584 10.6696 6.95878 11.0251 6.65958 11.3243C6.36038 11.6235 6.00495 11.8605 5.61375 12.0218C5.22256 12.1831 4.80333 12.2654 4.3802 12.2639C3.95706 12.2625 3.53839 12.1774 3.14829 12.0135C2.75818 11.8497 2.40435 11.6102 2.10716 11.309C1.52274 10.7039 1.19935 9.89348 1.20666 9.05227C1.21397 8.21106 1.55139 7.40637 2.14624 6.81151C2.74109 6.21666 3.54578 5.87925 4.387 5.87194C5.22821 5.86463 6.03864 6.18801 6.64374 6.77243L6.64433 6.77185ZM6.64433 6.77185L9.04183 4.37435L6.64433 6.77185ZM9.04183 4.37435L10.7918 6.12435L12.8335 4.08268L11.0835 2.33268L9.04183 4.37435Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
													</svg>
												</span>
												<span class="custom-font-open-12-400 ml-1">
													Editar departamentos
												</span>                                        
											</b-dropdown-item> 
											<b-dropdown-item @click="setQuickReplyDelete (x.quickReplyId, x.quickReplyCommand)">
												<span>
													<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M3 4H4.33333H15" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														<path d="M6.3335 4.00004V2.66671C6.3335 2.31309 6.47397 1.97395 6.72402 1.7239C6.97407 1.47385 7.31321 1.33337 7.66683 1.33337H10.3335C10.6871 1.33337 11.0263 1.47385 11.2763 1.7239C11.5264 1.97395 11.6668 2.31309 11.6668 2.66671V4.00004M13.6668 4.00004V13.3334C13.6668 13.687 13.5264 14.0261 13.2763 14.2762C13.0263 14.5262 12.6871 14.6667 12.3335 14.6667H5.66683C5.31321 14.6667 4.97407 14.5262 4.72402 14.2762C4.47397 14.0261 4.3335 13.687 4.3335 13.3334V4.00004H13.6668Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														<path d="M7.6665 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														<path d="M10.3335 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
													</svg>
												</span>
												<span class="custom-font-open-12-400 ml-1">
													Excluir resposta
												</span>                                        
											</b-dropdown-item>                                                                                                                                                
										</b-dropdown>           
									</b-td>                           
								</b-tr>
							</b-tbody>
						</b-table-simple>  
					</div>
				</b-col>   
			</b-row>
			<b-row v-show="rows > perPage" class="pb-5">
				<b-col md="4" class="mt-2">
					<b-row>
						<b-col md="7" class="mt-1">
							<span class="mt-2 lblPagination">Visualizar por página:</span> 
						</b-col>
						<b-col md="4">                                                 
							<b-form-select v-model="selected" :options="perPageOptions" size="sm" @change="setPerPage()"></b-form-select>                
						</b-col>
					</b-row>
				</b-col>
				<b-col class="mr-5">
					<b-row class="d-flex justify-content-end">
						<b-pagination
							v-model="currentPage"
							:total-rows="rows"
							:per-page="perPage"
							pills
							size="sm"
							aria-controls="my-table"
						></b-pagination> 
					</b-row>
				</b-col>
        
			</b-row>           
		</b-col>
		
	</b-col>    
</template>

<script>
import Loading from '@/views/Loading'
import WQuickReplyAPI from '@/api/WQuickReplyAPI'
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import Vue2Filters from 'vue2-filters'
import WModal from '@/views/WModal'
import SearchCheckBoxFilterVue from '../../filters/SearchCheckBoxFilter.vue'
export default {
	mixins: [Vue2Filters.mixin],
	components: {
		loading: Loading,    
		'quickreply-modal-delete': WModal,
		'search-check-box-filter': SearchCheckBoxFilterVue  
	},
	name: 'WQuickReplyList',
	data: function () {
		return {
			loading: false,
			filter: '',
			quickreplyId: '',
			quickreplyList: [],
			filteredList: [],
			originalQuickReplyList: [],
			showModalDelete: false,
			perPage: 20,
			selected: 20,
			currentPage: 1,  
			totalPage: 1,  
			paginatedItems: [], 
			perPageOptions: [20, 50, 100, 200, 500],  
			quickreplyName: '',  
			componentDeleteModalKey: 0,
			departmentList: [],
			departmentFilterList: [],
		}
	},
	computed: {
		rows () {
			return this.quickreplyList.length
		},
		lists () {
			const items = this.quickreplyList
			return items.slice(
				(this.currentPage - 1) * this.perPage,
				this.currentPage * this.perPage
			)
		},      
	},    
	methods: {                      
		getQuickReplyList () {
			this.$store.commit('setShowLoading', true)
			WQuickReplyAPI.getQuickReplyList(this.$route.params.companyId, response => {
				let quickreplyList = response.data
				this.$store.commit('setShowLoading', false)
				quickreplyList.forEach(element => {
					element.visible = true
				})
				this.quickreplyList = quickreplyList 
				this.originalQuickReplyList = this.quickreplyList

				this.originalQuickReplyList = this.addDepartmentIdMap(this.originalQuickReplyList)
			})
		},
		getDepartmentsList () {
			WDepartmentsAPI.getDepartmentsOfCompany (
				this.$route.params.companyId, response => {
					let resp = response.data
					resp.forEach(item => {
						let dpt = {
							text: item.departmentName,
							value: item.departmentId
						}
						this.departmentList.push(dpt)
					})
				}
			)
		},
		create () {
			let obj = {step: 9}
			this.$emit('sendMessageData', obj)    
		},
		setPerPage () {
			this.perPage = this.selected
		},
		searchQuickReply () {
			let quickreplyList = this.originalQuickReplyList
			if (this.filteredList.length != 0){
				quickreplyList = this.filteredList
			}
			this.quickreplyList = []            
			quickreplyList.forEach( list => {                
				if ( list.quickReplyDescription.toLowerCase().includes(this.filter.toLowerCase()) || list.quickReplyCommand.toLowerCase().includes(this.filter.toLowerCase())){
					if(this.departmentFilterList.length > 0){
						this.departmentFilterList.forEach(element => {
							if(list.departmentIdList.includes(element)){
								list.visible = true
								this.quickreplyList.push(list)
							}
						})
					}else{
						list.visible = true
						this.quickreplyList.push(list)
					}
				} else {
					list.visible = false
				}                    
			})
		},   

		setQuickReplyDelete (quickreplyId, name) {  
			this.quickreplyName = name
			this.quickreplyId = quickreplyId
			this.componentDeleteModalKey ++
			this.showModalDelete = true 
			this.quickreplyName = this.quickreplyName.substring(0, this.quickreplyName.length -1)
		},   

		deleteQuickReply (deleteQuickReply) {
			this.showModalDelete = false
			if (deleteQuickReply){
				WQuickReplyAPI.deleteQuickReply(this.$route.params.companyId, this.quickreplyId, response => {
					if (response){
						this.getQuickReplyList()                      
					} else {
						alert('Falha ao excluir palavras chave!')
					}                                       
				})                
			}
		},                     
		editQuickReply (quickreplyId, type) {
			let objQuickReply = {quickreplyId: quickreplyId, type: type}
			localStorage.removeItem('__wpp_automation_quickreply_obj')
			localStorage.setItem('__wpp_automation_quickreply_obj', JSON.stringify(objQuickReply))                     
			let obj = {step: 10.1}
			this.$emit('sendMessageData', obj)    
		},    
		editDepartments (quickreplyId, departmentAll ) {
			let objQuickReply = {quickreplyId: quickreplyId, checked: departmentAll }
			localStorage.removeItem('__wpp_automation_quickreply_obj')
			localStorage.setItem('__wpp_automation_quickreply_obj', JSON.stringify(objQuickReply))                     
			let obj = {step: 11.1}
			this.$emit('sendMessageData', obj) 
		},
		getSelectedItems (list, originalQuickReplyList) {
			let quickreplyList = []
			originalQuickReplyList.forEach(item => {
				let containItem = true
				for(let index in list){
					if(!item.departmentIdMap.has(list[index])){
						containItem = false
						break
					}                    
				}
				item.visible = containItem
				if (item.visible){
					quickreplyList.push(item)
				}
			})
			return quickreplyList
		},
		setSelectedItems (list, originalQuickReplyList) {
			this.departmentFilterList = list
			this.quickreplyList = this.getSelectedItems(list, originalQuickReplyList)
			this.filteredList = this.quickreplyList
		},
		addDepartmentIdMap (quickreplyList) {
			/**
             * Takes a quickreplyList and add a departmentIdMap item to each quick reply in it
             * It is map containing all departmentsId included in the given quick reply
             */
			quickreplyList.forEach(item => {
				let newDepartmentMap = new Map(item.departmentIdList.map(e => [e, true]))
				item['departmentIdMap'] = newDepartmentMap
			})
			return quickreplyList
		},
		clearFilter () {
			if(this.filter == ''){
				this.searchQuickReply()
			}
		}            
	},      
	mounted () {
		this.getQuickReplyList()
		this.getDepartmentsList()
	}
}
</script>

<style scoped>
.custom-select {
    width: auto;
}
u {
    cursor: pointer;
}
th {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #999999 !important;    
}

td {
    margin: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #777777; 
    height: 50px;
    vertical-align: middle !important;
}
.td-quickreply {
    margin-top: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px; 
    color: #777777;
}

.td-phonenumber {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;  
}
.td-date {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-data {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-email {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}
.td-property {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}

.td-target {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-fail {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-deliver {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.td-readed {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}
.sidebar-right-quickreplys {
    display: flex !important;
    width: 100% !important;
    flex-direction: column !important;
    background: white !important;
    padding: 10px;
    margin-right: 50px;
}
.custom-pagination-input {
    width: 50px !important;
}
.custom-pagination-select {
    width: 60px !important;
}

.quickreply-list-scrollable {
    height: 90%;
    width: 100%;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
}
.lblPagination {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px; 
    color: #777777;   
}

.quickreply-tag {
    padding: 5px;
    background: #F5F5F5;
    border-radius: 100px;    
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    min-height: 200px;
    -webkit-overflow-scrolling: touch;
}

.form-control:focus {
    background-color: #F5F5F5;
    box-shadow: none;
}
.description-ellipsis { 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
}
</style>
