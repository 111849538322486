<template>
	<b-col class="label-list-scrollable scrollbar mt-3 ml-1">
		<div v-if="innerStep == 6">
			<b-col class="mt-2 ml-3">
				<b-row class="ml-2">
			
					<h3 v-if="archived == false" class="lbl-title">Marcações</h3>
					<h3 v-else class="lbl-title">Marcações - Arquivadas</h3>
				
					<b-col>
						<b-row class="justify-content-end mr-5">
							<b-button v-if="archived == false" v-b-toggle.sidebar-right variant="secondary"
								@click="create()">
								<span>
									<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M8 3V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M3 8H13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								</span>
								<span class="ml-1">
									Adicionar marcação
								</span>                        
							</b-button> 
							<b-button @click="switchMode" class="ml-4" variant="primary">
								<span v-if="archived == false">Arquivadas</span>
								<span v-else>Marcações Ativas</span>
							</b-button>                                                            
						</b-row>
					</b-col>                
				
				</b-row>
				<b-col class="ml-2" sm="10">
					<b-row class="w-75 mt-1">
						<span class="custom-font-open-14-gray-normal">
							Marcações são etiquetas virtuais que você pode colocar em mensages, para achá-las mais fácil no futuro. Da mesma forma, as marcações podem ser usadas para gerenciar um processo, sabendo por quais etapas do seu processo aquele cliente já passou. 
						</span>                       
					</b-row>   
				</b-col>   
			</b-col>
			<b-col md="12" sm="12" class="screen-template">
				<loading v-if="this.$store.state.loading ==true"/>     
				<b-row style="margin-rigth:30px;" v-if="this.$store.state.loading ==false">                       
				</b-row>
				<b-row class="mt-1">   
					<div>
						<label-modal
							:title = "modal.title"
							:subTitle = "modal.subTitle"
							:description = "''"
							:description1= "''"
							:value = "''"
							:placeholder = "''"
							:button1 = "'Fechar'"
							:button2 = "modal.button"
							:start="showModal"   
							v-on:sendModalData="getModalResult"
							:key="modalKey"                        
						/>
					</div>                                                  
					<b-col class="ml-2" v-if="this.$store.state.loading ==false">
						<tableT v-if="labelList" 
							:list="(archived ? archivedLabelList:labelList)" 
							:fields="fields" 
							:perPage="10" 
							:key="reload"
							:departmentFilter="true"
							:advancedSearch="true"
							:searchBar="true"
							:searchPlaceHolder = "'Busque pelo nome'"
							:dropdownOptionsSettingsLabel ="(archived ? dropdownArchivedOptionsSettingsLabel:dropdownOptionsSettingsLabel)"
							:sortBy ="'labelDescription'"
							:selected="10"
							v-on:editLabelDescription ="editLabelDescription"
							v-on:setLabelDelete ="setLabelDelete"
							v-on:editLabelDepartment="editLabelDepartment"
							v-on:setLabelArchive="setLabelArchive"
							v-on:setLabelUnArchive="setLabelUnArchive"
						
						/>
						<div>
						</div>
					</b-col>   
				</b-row>
			</b-col>
		</div>
		<div v-else-if="innerStep==6.1 || innerStep == 6.2">
			<setting-label
				:step="innerStep" :loadedLabel="currentLabel" v-on:sendMessageData="getMessageData" v-on:editLabelDepartment="editLabelDepartment"
			/>
		</div>
		<div v-else-if="innerStep==6.3 || innerStep == 6.4">
			<setting-label-departments 
				:step="innerStep" 
				v-on:sendMessageData="getMessageData"
				:currentLabel="currentLabel"
			/>
		</div>
	</b-col>
	
</template>

<script>
import Loading from '@/views/Loading'
import WLabelsAPI from '@/api/WLabelsAPI'
import Vue2Filters from 'vue2-filters'
import WModal from '@/views/WModal'
import WLabel from './WLabel'
import WSortBy from '@/js/utils/WSortBy'
import Table from '@/components/global/TableComponent/Table'
import WLabelDepartments from './WLabelDepartments'

export default {
	mixins: [Vue2Filters.mixin],
	components: {
		tableT: Table,
		loading: Loading,  
		'setting-label': WLabel,   
		'setting-label-departments' : WLabelDepartments,
		'label-modal': WModal,   
	},
	props:[
		'step'
	],
	name: 'WLabelList',
	data: function () {
		return {
			loading: false,
			innerStep: this.step,
			filter: '',
			labelList: [],   
			archivedLabelList:[],
			perPage: 20,
			currentPage: 1,  
			fields: [
				{
					key: 'labelDescription',
					sortable: true,
					label:'Descrição'
					
				},
				{
					key: 'labelDetail',
					sortable:true,
					label:'Descrição da marcação'
				},
				{
					key:'labelDepartment',
					sortable:true,
					label:'Departamentos'

				},
				{
					key: 'usageCount',
					sortable: true,
					label:'Mensagens marcadas'
				},
				{
					key: 'dropdownOptionsSettingsLabel',
					label:''
				},
				
			],
			dropdownOptionsSettingsLabel: {
				editLabelDescription:true,
				editLabelDepartment:true,
				setLabelArchive:{content:'Arquivar marcação',emit:'setLabelArchive'},
				setLabelDelete:true,
			},
			dropdownArchivedOptionsSettingsLabel: {
				editLabelDepartment:true,
				setLabelArchive:{content:'Desarquivar marcação',emit:'setLabelUnArchive'},
			},
			archived: false,
			reload: 0,
			archivedListPopulated: false,
			currentLabel: undefined,
			modal:{title:'',subTitle:'',button:''},
			modalKey:0,
			showModal: false,
		}
	},
	computed: {
		rows () {
			return this.labelList.length
		},
		orderLabelList () {
			const items = WSortBy.orderMyList('labelDescription',this.labelList)
			return items.slice(
				(this.currentPage - 1) * this.perPage,
				this.currentPage * this.perPage
			)
		},      
	},    
	methods: {    
		
		switchMode () {
			this.archived = !this.archived
			this.getLabelList()
		},
		onClose (id) {
			this.$root.$emit('bv::hide::popover', id)   
		},
		getArchiveLabelList () {
			WLabelsAPI.getListArchivedByCompanyId(this.$route.params.companyId, response => {
				let labelList = response.data
				this.$store.commit('setShowLoading', false)
				labelList.forEach(element => {
					element.visible = true
				})
				this.archivedLabelList = labelList 
				this.archivedListPopulated = true
				this.reload++
			})
		},
		getListActiveByCompanyId () {
			WLabelsAPI.getListActiveByCompanyId(this.$route.params.companyId, response => {
				let labelList = response.data
				this.$store.commit('setShowLoading', false)
			
				this.labelList = labelList 
				this.reload++
			})
		},

		create () {
			this.currentLabel = undefined
			this.innerStep = 6.1              
		},     
		getLabelList () {
			this.$store.commit('setShowLoading', true)
			if(this.archived == true){
				this.getArchiveLabelList()
			}else{
				this.getListActiveByCompanyId()
			}
			this.$store.commit('setShowLoading', false)
		},    
           
		editLabelDescription (item) {
			this.currentLabel = item
			this.innerStep = 6.2                
		},
		editLabelDepartment (item) {
			if(item.label){
				this.currentLabel = item.label
				this.innerStep = item.step
			}else{
				this.currentLabel = item
				this.innerStep = 6.4
			}
		},
		// -----------------------MODAL FUNCTIONS--------------------------------------
		setLabelArchive (item) {
			this.modal.title='Arquivar marcação',
			this.modal.subTitle='Tem certeza que deseja aquivar a marcação',
			this.modal.button='Arquivar'
			this.currentLabel = item
			this.modalKey ++
			this.showModal = true                
		},
		setLabelUnArchive (item) {  
			this.modal.title='Desarquivar marcação',
			this.modal.subTitle='Tem certeza que deseja desarquivar a marcação',
			this.modal.button='Desarquivar'
			this.currentLabel = item
			this.modalKey ++
			this.showModal = true 
		},
		setLabelDelete (item) {
			this.modal.title='Excluir marcação',
			this.modal.subTitle='Tem certeza que deseja excluir a marcação',
			this.modal.button='Excluir'
			this.currentLabel = item
			this.modalKey ++
			this.showModal = true 
		},
		getModalResult (result) {
			if(result){
				switch(this.modal.button){
				case 'Excluir':
					WLabelsAPI.deleteLabel(this.$route.params.companyId, this.currentLabel.labelId, response => {
						response
						this.getLabelList()
						this.showModal = false
					})  
					break
				case 'Arquivar':
					WLabelsAPI.postArchivedLabel(this.$route.params.companyId, this.currentLabel.labelId, response => {
						response
						this.getLabelList()
						this.showModal = false
					}) 
					break
				case 'Desarquivar':
					WLabelsAPI.postUnarchivedLabel(this.$route.params.companyId, this.currentLabel.labelId, response => {
						response
						this.getArchiveLabelList()
						this.showModal = false
					}) 
					break
				}
			}
		},
		// -----------------------MODAL FUNCTIONS--------------------------------------
		getMessageData () {
			this.getLabelList()
			this.innerStep = 6
			this.currentLabel = undefined
		},
	},      
	mounted () {
		this.getLabelList()
	}
}
</script>

<style scoped>

u {
    cursor: pointer;
}
th {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #999999 !important;    
}

td {
    margin: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #777777; 
    height: 50px;
    vertical-align: middle !important;
}
.td-label {
    margin-top: 20px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.td-phonenumber {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;  
}
.td-date {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-data {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-email {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}
.td-property {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}

.td-target {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-fail {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-deliver {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.td-readed {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}



.sidebar-right-labels {
    display: flex !important;
    width: 100% !important;
    flex-direction: column !important;
    background: white !important;
    padding: 10px;
    margin-right: 50px;
}
.custom-pagination-input {
    width: 50px !important;
}
.custom-pagination-select {
    width: 60px !important;
}

.label-list-scrollable {
    width: 98%;
	height: 90vh;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
}
.lblPagination {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px; 
    color: #777777;   
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    min-height: 200px;
    -webkit-overflow-scrolling: touch;
}

.form-control:focus {
    background-color: #F5F5F5;
    box-shadow: none;
}
</style>
