import router from '../router'
import axios from 'axios'
import i18n from '../plugins/i18n'
import { baseApiUrl, access_token, config} from '../global'


axios.defaults.headers.common['Authorization'] = `bearer ${access_token}`


export default {
   
	getUserList: (companyId, callback) => {
		const url = `${baseApiUrl}`+'company/'+ companyId + '/userList/'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	}, 
	getUserProfile: (callback) => {
		const url = `${baseApiUrl}`+'user/profile'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},   
	getUserPreferences: (callback) => {
		const url = `${baseApiUrl}`+'user/preferences/'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},     
	requestPasswordWithToken (newPassword) {
		const params = new URLSearchParams()
		params.append('password',newPassword)        
		const url = `${baseApiUrl}`+'user/requestPasswordWithToken'
		axios.post(url, params, config).then(response => {
			if (response.status == 200){
				alert(i18n.t('Operação realizada com sucesso! Código de segurança foi enviado para seu email.'))                  
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},  
	postLanguage (language) {
		const params = new URLSearchParams()
		params.append('language',language)        
		const url = `${baseApiUrl}`+'user/language'
		axios.post(url, params, config).then(response => {
			if (response.status == 200){
				console.log(i18n.t('Message.success'))                 
			}        
		})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)
			})  
	},          
	confirmPasswordWithToken (confirmPassword, token, callback) {
		const params = new URLSearchParams()
		params.append('password',confirmPassword)      
		params.append('token',token)   
		const url = `${baseApiUrl}`+'user/confirmPasswordWithToken'
		axios.post(url, params, config).then(response => {
			if (response.status == 200){
				callback(true)
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
				callback(false)
			})  
	},       
	save (companyId, name, email, password, role ) {         
		const params = new URLSearchParams()
		params.append('name',name)
		params.append('email',email)
		params.append('language','')
		params.append('password',password)
		params.append('role',role)
		let url = `${baseApiUrl}` +'company/'+ companyId + '/userAdd'
		axios.post(url, params, config)
			.then(response => {
				alert(i18n.t('Message.success') + response.data)  
				router.push({ path: '/user/list'})  
			})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)                
			})    
	},
	update (companyId, userId, params) {        
		axios.post(`${baseApiUrl}` + 'company/'+ companyId + '/' + userId + '/alterCompanyUser', params, config)
			.then(response => {               
				alert(i18n.t('Message.success') + response.data)  
				router.push({ path: '/user/list'})         
			})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)                
			})   
	},    
	remove (companyId, userId) {        
		axios.delete(`${baseApiUrl}` + 'company/' + companyId + '/' + userId + '/delete/', config)
			.then(response => {               
				alert(i18n.t('Message.success') + response.data)  
				window.location.reload()        
			})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)                
			})   
	},       
	resetPassword (email) {
		const params = new URLSearchParams()
		params.append('email',email)
		let url = `${baseApiUrl}` +'user/resetPassword'
		axios.post(url, params, config)
			.then(response => {
				if (response.data || response.data == ''){                
					alert(i18n.t('Message.success'))    
				}
			})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)                
			})    
	}             
}
