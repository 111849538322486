<template>
	<div>
		<b-col md="8">
			<b-row class="ml-3 mt-3">  
				<h3 v-if="step == 4.5" class="lbl-title">Novo departamento - Permissões</h3>  
				<h3 v-else class="lbl-title">Editar departamento - Permissões</h3>                                             
			</b-row>
			<div class="w-100">
				<hr>
			</div>   
			<b-row class="ml-3">
				<span class="custom-font-open-14-gray-normal">
					Para onde as conversas iniciadas devem ser direcionadas?
				</span>                       
			</b-row>                 
			<b-row class="ml-4 mt-3">
				<b-row>
					<b-dropdown id="phoneNumberList" :text="inboxOption" variant="ligth">
						<b-dropdown-item v-for="(value, key,  index) in rules" 
							:key="index" @click="setInboxRule(key, value)">{{value}}
						</b-dropdown-item>
					</b-dropdown>                                
				</b-row>                                     
			</b-row>
			<b-row class="ml-2 mt-3">
				<div v-show="inboxRule ==2">
					<div>
						<!-- <b-dropdown id="inboxRule" :text="agentName" variant="ligth">
                        <b-dropdown-item v-for="(x,  index) in agents" 
                        :key="index" @click="setAgentLead(x.userId, x.name)">
                        <b-avatar :src="avtUrl + companyId + '&imageId='+x.userId" size="1.2em"></b-avatar> 
                        <span class="custom-font-open-12 ml-2">{{x.name}}</span>                         
                        </b-dropdown-item>
                    </b-dropdown>         -->
						<div style="height: 30px; min-width: 200px; margin-bottom:10px;">
							<model-select :options="agents"
								v-model="selectedAgent"
								placeholder="Selecionar">
							</model-select>                                    
						</div>                                                                       
					</div>    
				</div>    
			</b-row>
    
			<b-row class="ml-3 mt-2">
				<span class="custom-font-open-14-gray-normal">
					Um usuário pode assumir o bate-papo de outro usuário dentro do departamento?
				</span>                       
			</b-row>  
			<b-row class="ml-3 mt-2">
				<b-form-radio v-model="takeContactFromUser" name="some-radios" value="1">Sim</b-form-radio>
				<b-form-radio class="ml-2" v-model="takeContactFromUser" name="some-radios" value="0">Não</b-form-radio>            
			</b-row>  
			<b-row class="ml-3 mt-2">
				<span class="custom-font-open-14-gray-normal">
					Informar o nome do usuário no inicio da mensagem
				</span>                       
			</b-row>  
			<b-row class="ml-3 mt-2">
				<b-form-radio v-model="agentNameInHeader" name="some-radios2" value="1">Sim</b-form-radio>
				<b-form-radio class="ml-2" v-model="agentNameInHeader" name="some-radios2" value="0">Não</b-form-radio>            
			</b-row>  
			<b-row class="ml-3 mt-3">
				<span class="custom-font-open-14-gray-normal">
					Informe se um usuário pode ver conversas de outro usuário
				</span>                       
			</b-row>  
			<b-row class="ml-3 mt-2">
				<b-form-radio v-model="seeContactFromUser" name="some-radios3" value="1">Sim</b-form-radio>
				<b-form-radio class="ml-2" v-model="seeContactFromUser" name="some-radios3" value="0">Não</b-form-radio>            
			</b-row>       			                   
			<b-row class="mt-3">
				<b-col>
					<div class="d-flex justify-content-center">
						<b-button variant="outline-primary" class="mr-2"
							@click="cancel()">Cancelar
						</b-button>                      
						<b-button variant="primary" 
							@click="save()">Salvar
						</b-button>                           
					</div>
				</b-col>            
			</b-row>          
		</b-col> 
	</div>
</template>

<script>
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import WAttendantsAPI from '@/api/WAttendantsAPI'
import { ModelSelect } from 'vue-search-select'    
import { avatarUrl} from '@/global'
export default {
	name: 'WDepartmentPermission',
	props: [
		'step'
	],
	components: {
		'model-select': ModelSelect
	},
	data () {
		return {
			companyId: this.$route.params.companyId, 
			inboxRule: '',
			takeContactFromUser: '', 
			agentNameInHeader: '',
			agentLead: '',
			rules: [],
			agents: [],
			avtUrl: avatarUrl,
			inboxOption: '-Selecione-',
			agentName: '-Selecionar usuário-',
			selectedAgent: '',
			seeContactFromUser: ''
		}
	},
	methods: {
		getInboxRules () {
			WDepartmentsAPI.getInboxRules(this.$route.params.companyId, response => {
				this.rules = response.data
			})    
		},             
		setInboxRule (inbox, name) {
			this.inboxRule = inbox
			this.inboxOption = name
			if (inbox == 2){
				this.getAgentsList ()
			}
		},
		setAgentLead (id, name) {
			this.agentLead = id
			this.agentName = name
		}, 
		dynamicSort (property) {
			let sortOrder = 1
			if(property[0] === '-'){
				sortOrder = -1
				property = property.substr(1)
			}
			return function (a,b) {
				let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
				return result * sortOrder
			}
		},                        
		getAgentsList () {
			WAttendantsAPI.getAgentsList(this.$route.params.companyId, response => {
				let agents = response.data  
				let list = []  
				agents.sort(this.dynamicSort('name'))     
				agents.forEach(el => {
					let obj = {value: el.userId, text: el.name.trim()}
					list.push(obj)
				})                  
				this.agents = list
			})    
		},    
		getDepartmentDetail () {
			WDepartmentsAPI.getDeparmentDetail(this.$route.params.companyId, this.departmentId, response => {
				let departmentDetail = response.data                      
				this.inboxRule = departmentDetail.inboxRule
				if (this.inboxRule == 0){
					this.inboxOption = 'Caixa de espera'
				}
				if (this.inboxRule == 1){
					this.inboxOption = 'Distribuição automática entre agentes'
				}   
				if (this.inboxRule == 2){
					this.inboxOption = 'Usuário específico'
				} 
				this.agentLead =  departmentDetail.agentLead     
				if (departmentDetail.takeContactFromUser == true){
					this.takeContactFromUser = 1
				} else {
					this.takeContactFromUser = 0
				}
				if (departmentDetail.agentNameInHeader == true){
					this.agentNameInHeader = 1
				} else {
					this.agentNameInHeader = 0
				}        
				if (departmentDetail.seeContactFromUser == true){
					this.seeContactFromUser = 1
				} else {
					this.seeContactFromUser = 0
				}   				                                                               
			})    
		},                      
		save () {   
			this.agentLead = this.selectedAgent           
			if (this.inboxRule === '' || this.inboxRule === undefined){
				alert('Campo conversas direcionadas obrigatório!')
				return
			}   
			if (this.inboxRule == 2){
				if (this.agentLead == '' || this.agentLead == undefined){
					alert('Campo usuário obrigatório!')
					return
				}       
			}   
			WDepartmentsAPI.postDepartmentPermission(this.$route.params.companyId, this.departmentId, this.inboxRule, this.takeContactFromUser, this.agentNameInHeader, this.seeContactFromUser,  this.agentLead, response => {
				if (response){  
					localStorage.removeItem('__wpp_settings_obj') 
					let obj = {step: 4}
					this.$emit('sendMessageData', obj)                                                           
				} else {
					alert('Falha ao criar propriedade!')
				}
			})                                  
		},
		cancel () {
			localStorage.removeItem('__wpp_settings_obj')
			let obj = {step: 4}
			this.$emit('sendMessageData', obj)             
		}                 
	},
	mounted () {
		let objDepartment = JSON.parse(localStorage.getItem('__wpp_settings_obj'))
		if (objDepartment != null){
			this.departmentId = objDepartment.departmentId
		}    
		this.getInboxRules()             
		if (this.step == 4.6){
			this.getDepartmentDetail() 
		}
	}, 
}
</script>