
<template>
	<b-col id="main" class="webhook-scrollable scrollbar">

		<b-col class="mt-3 ml-4">
			<span class="custom-tittle-webhook-logs">Importações</span>
		</b-col>
		
		<!-- <b-col v-if="loading == true" class="justify-content-center">
			<b-row>
				<span class="sync-message">Carregando</span>
			</b-row>
			<b-row>
				<b-spinner class="sync-spinner mt-5" label="Large Spinner"></b-spinner>
			</b-row>
			
		</b-col> -->
		<loading v-if="this.$store.state.loading ==true"/> 
		<b-col v-else class="ml-2">
			<b-col sm="12" class="tables"> 
				<b-row>
					<b-col sm="12">
						<b-table-simple id="my-table"
							class="log-list scrollbar"
							hover small responsive>
							<b-thead head-variant="ligth">
								<b-tr>
									<b-th>
										<span class="header-custom ml-1">Título</span>
									</b-th>
									<b-th>
										<span class="header-custom">Fonte de dados</span>
									</b-th>
									<b-th>
										<span class="header-custom">Status</span>
									</b-th>
									<b-th>
										<span class="header-custom">Contatos</span>
									</b-th>
									<b-th>
										<span class="header-custom">Mensagens</span>
									</b-th>
									<b-th>
										<span class="header-custom">Espaço em disco</span>
									</b-th>
									<b-th>
										<span class="header-custom">Extra</span>
									</b-th>
									<b-th>
										<span class="header-custom">Progresso</span>
									</b-th>
								</b-tr>
							</b-thead>
							<b-tbody >												
								<b-tr :key="index" v-for="(x, index) in logList">											
									<b-td style="vertical-align:middle"> 
										<div class="ml-1">
											<span class="td-style">
												{{x.transactionTitle}}
											</span>
										</div>
									</b-td>
									<b-td style="vertical-align:middle"> 
										<div>
											<span class="td-style">
												{{x.datasourceDescription}}
											</span>
										</div>
									</b-td>
									<b-td > 
										<div >
											<span class="td-style">
												{{x.statusDescription}}
											</span>
										</div>
									</b-td>
									<b-td style="vertical-align:middle"> 
										<div>
											<span class="td-style">
												{{x.contactCount}}
											</span>
										</div>
									</b-td>
									<b-td style="vertical-align:middle"> 
										<div class="ml-1">
											<span class="td-style">
												{{x.messageCount}}
											</span>
										</div>
									</b-td>
									<b-td style="vertical-align:middle"> 
										<div class="ml-1">
											<span class="td-style">
												{{x.bytesCountDescription}}
											</span>
										</div>
									</b-td>
									<b-td style="vertical-align:middle"> 
										<div class="ml-1">
											<span class="td-style">
												{{x.extraDescription}}
											</span>
										</div>
									</b-td>
									<b-td style="vertical-align:middle"> 
										<div class="ml-1">
											<span class="td-style">
												{{x.progressDescription}}
											</span>
										</div>
									</b-td>
									
								</b-tr>
							</b-tbody>
						</b-table-simple>  
					</b-col>

				</b-row>
			</b-col>
	
		</b-col>
	

	</b-col>  
</template>

<script>
import WKeyAPI from '@/api/WKeyAPI'
import Loading from '@/views/Loading'
export default {
	name: 'WImports',
	props: [
		'step'
	],
	components:{
		loading: Loading,
	},
	data () {
		return { 
			logList:[],
			loading:true,
		}
	},    
	methods: {

		postLogs () {
			WKeyAPI.getDatasourceImporter(this.$route.params.companyId,response => {
				this.logList = response.data
				this.$store.commit('setShowLoading', false)
			}) 
		},	
		onRowSelected (items) {	
			this.getLogDetail(items.transactionId)
			this.selectedRow = items
			if(this.selectedRow.length != ''){
				this.httpCode = items.httpCode
				this.requestTime = new Date(items.requestTime)
				this.requestTime = this.requestTime.toLocaleDateString('pt-BR') +'  '+ this.requestTime.toLocaleTimeString('pt-BR')
				this.transactionId = items.transactionId
				this.ipOrigin = items.ipOrigin
				this.endpoint = items.endpoint
				this.methodSelected = items.httpMethod
				this.userAgent = items.userAgent
			}else{
				this.httpCode = ''
			}
		},
	},
	mounted () { 
		this.$store.commit('setShowLoading', true)
		this.postLogs()
	}, 
}
</script>

<style lang="scss">
	.webhook-scrollable {
			height: 87vh;
			width: 100%;
			background: #fff;
			overflow-y: scroll;
			overflow-x: hidden;
	}
	.main{
		display: flex;
		flex-flow: column;
		height: 100%;
	}
	.tables{
		display: flex;
		flex-flow: column;
		height: 100%;
	}
	.log-list{
		max-height: 63vh;
		overflow: scroll;
	}
	.word-break{
		word-break: break-word;
	}
.custom-tittle-webhook-logs {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 20px !important;  
    color: #414141 !important;
}
.header-custom {
	font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #999999;	
}
.td-style {
    margin-top: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px; 
    color: #777777;
}
.sync-message{
	font-weight: 600;
    font-size: 32px;
    position: absolute;
	font-family: 'Roboto';
    line-height: 40px;
    text-align: center;
    color: var(--primary);
    margin: 10px 0;
	top:1000%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.sync-spinner{
	font-weight: 600;
    font-size: 32px;
    position: absolute;
	font-family: 'Roboto';
    line-height: 40px;
    text-align: center;
    color: var(--primary);
    margin: 10px 0;
	top: 1000%;
	left: 48.9%;
}
</style>

 
