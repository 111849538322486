
<template>
	<div class="preview-responsive">
		<b-overlay :show="loading" variant="light"
			no-wrap>
			<template #overlay>
				<b-spinner style="width: 3rem; height: 3rem; color: var(--secondary)" label="Large Spinner"></b-spinner>
			</template>                    
		</b-overlay>  
		<b-card
			header="Card Header"
			header-tag="header"
			header-bg-variant="danger"
			style="border:none"
		>
			<template #header >
				<b-row class="ml-2">
					<b-col>
						<h6 class="mb-0" style="color: white;">Pré-visualizar</h6>   
					</b-col>
					<b-col>
						<button type="button" style="color: white;" class="close" aria-label="Close" @click="previewClose()">
							<span aria-hidden="true">&times;</span>
						</button>
					</b-col>       
				</b-row>
			</template> 

 
			<b-container fluid="sm" class="">
				<div :key="update" class="scrollbar" style="display:flex;align-items:center;justify-content:center;height:45vh">
					<b-img v-if="currentFile.mediaType == 'image'" :src="currentFile.filePath" alt="imagem" style="max-height:100%;max-width:100%">
					</b-img> 
					<audio controls v-if="currentFile.mediaType == 'audio'" style="max-height:350px;max-width:350px;min-height:200px;min-width:200px">
						<source :src="currentFile.filePath" type="audio/ogg">
					</audio>
					<video id="video" v-if="currentFile.mediaType == 'video'" controls :src="currentFile.filePath" style="height:350px;width:350px">
					</video> 
					<iframe id="pdf" v-if="currentFile.mediaType == 'pdf'" :src="currentFile.filePath" scrolling="no" style="height:100%;width:100%">
					</iframe>
					<div class="custom-font-open-20-400-grey" v-if="currentFile.mediaType == 'others'" style="max-height:400px;max-width:400px;text-align:center;">
						<span>
							<svg width="75" height="75" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M13 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V9L13 2Z" stroke="var(--secondary)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M13 2V9H20" stroke="var(--secondary)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
						</span>
						<br>
						<span>Pré-visualização indisponível </span>
						<br>
						{{currentFile.filePath}} - {{fileSize}}
					</div>
				</div>
				<!-- audio -->
				<!-- <b-row v-if="mediaType == 'audio'" class="justify-content-center" style="min-height: 100px;">
					<audio controls>
						<source :src="filePath" type="audio/ogg">
					</audio>     
				</b-row> -->
				<!-- video -->
				<!-- <b-row v-if="mediaType == 'video'" class="justify-content-center">
					<video controls :src="filePath" height="200" width="300">
					</video>  
				</b-row>     -->
				<!-- doc/files -->
				<!-- <b-row v-if="mediaType == 'pdf'" class="justify-content-center mb-2">
					<iframe :src="filePath" scrolling="no" width="400" height="320">
					</iframe>  
				</b-row>   
				<b-row v-if="mediaType == 'others'" class="justify-content-center" style="min-height: 100px;">
					{{filePath}}
				</b-row>               -->

			</b-container>
        
    
			<div class="mt-5">
				<!-- description -->
				<div style="margin-left:0px;width:100%;display:flex;align-items:center;justify-content: center">
					<input v-show="currentFile.mediaType != 'others' && currentFile.mediaType != 'pdf'" type="text" class="preview-input no-outline"
						id="name-input"
						v-model="currentFile.message"
						placeholder="Adicionar uma legenda..."
						v-on:keyup.enter="sendMessageData">
					<div>
						<img src="@/assets/send.svg" height="40" v-on:click="sendMessageData" style="cursor: pointer"/>
						<div style="position:absolute">
							<span class="circle custom-font-open-12-400" v-show="files.length > 1">{{files.length}}</span>
						</div>
					</div>
				</div>
				<!-- description -->
				<!-- miniatures -->
				<div style="display:flex;align-items:center;justify-content: center">
					<div class="file-queue scrollbar">
						<div v-for="(x,index) in files" :key="index" style="display:flex;overflow:visible;" :class="index == 0? '':'ml-2'">
							<!-- arrow left -->
							<div class="arrow" v-if="allowSwapLeft && x == currentFile" @click="swapLeft(index)" style="cursor:pointer;z-index:99;margin-top:30px">
								<span class="custom-font-open-12-400" v-show="files.length > 1">&#60;</span>
							</div>
							<!-- arrow left -->
							<!-- miniatures -->
							<div style="z-index:0" :style="currentFile == x ?(index >0?'border: solid 4px var(--primary);margin-left:-8px':'border: solid 4px var(--primary)'):''" @click="currentFile = x" class="preview-box-style">
								<div v-if="x.mediaType == 'image'">
									<img :src="x.filePath" alt="imagem" style="height:66px;width:100%">
								</div>
								<div v-else-if="x.mediaType == 'video'" style="display:flex;align-items:center;justify-content: center;height:100%;width:100%">
									<svg width="45" height="45" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M23 7L16 12L23 17V7Z" stroke="var(--secondary)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M14 5H3C1.89543 5 1 5.89543 1 7V17C1 18.1046 1.89543 19 3 19H14C15.1046 19 16 18.1046 16 17V7C16 5.89543 15.1046 5 14 5Z" stroke="var(--secondary)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								</div>
								<div v-else style="display:flex;align-items:center;justify-content: center;height:100%;width:100%">
									<svg width="45" height="45" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M13 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V9L13 2Z" stroke="var(--secondary)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M13 2V9H20" stroke="var(--secondary)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								</div>
								<div class="preview-box-remove" style="cursor:pointer">
									<div class="mr-2" style="display:flex;justify-content:flex-end">
										<span style="font-size:20px" class="custom-font-open-12-400" @click="removeFile(index)">
											x
										</span>
									</div>
								</div>

							</div>
							<!-- miniatures -->
							<!-- arrow right -->
							<div v-if="allowSwapRight && x == currentFile" @click="swapRight(index)" style="z-index:99;margin-left:-8px;margin-top:30px" class="arrow" >
								<span class="custom-font-open-12-400" v-show="files.length > 1">&#62;</span>
							</div>
						</div>
						<label for="file-input-image"> 
							<div class="preview-box-style icon-clickable" style="margin-left:5px">
								<div style="display: flex; justify-content: center;align-items: center;height:100%">
									<div class="mt-2">
									
										<span for="file-input-image" class="custom-font-roboto-10-400-grey" style="font-size:36px">+</span>
									
										<b-form-file id="file-input-image" :multiple="true" @change="handleFileChange($event.target.files)" hidden v-model="blob" class="mt-3" plain></b-form-file> 
									</div>
								</div>
							</div>
						</label>
					</div>
					<br>
					<!-- <button @click="teste">teste</button> -->
				</div>
				<!-- miniatures -->
				<br>
			</div> 
   
		</b-card>
	</div>
  
</template>

<script>
export default {
	name: 'Preview',    
	props: ['file', 'mediaType'],    
	data () {
		return {
			message: '',
			messageData: {},
			filePath: '',
			mqInfinity: false,
			numPages: undefined,
			files:[],
			currentFile:{},
			blob:[],
			update:0,
			compressValue:this.$store.getters.getCompressValue,
			loading: false
		}
	},    
	watch:{
		currentFile () {
			// console.log(this.currentFile)
			this.update ++
		},
		blob () {
			console.log(this.blob)
			// this.loadFile(this.blob)
		}
	},
	computed:{
		allowSwapLeft () {
			return (this.files.indexOf(this.currentFile) > 0 && this.files.length >1)
		},
		allowSwapRight () {
			return (this.files.indexOf(this.currentFile) != this.files.length-1 && this.files.length >1)
		},
		fileSize () {
			let _size = this.currentFile.size
			let fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
				i=0;while(_size>900){_size/=1024;i++}
			let exactSize = (Math.round(_size*100)/100)+' '+fSExt[i]
			return(exactSize)
		}
	},
	methods: {
		teste () {
			console.log(this.files)
		},
		bytesToSize (bytes) {
			if (bytes == 0) 
				return '0 Byte'
			let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
			return + Math.round(bytes / Math.pow(1024, i), 3)
		},
		swapLeft (index) {
			this.files[index-1] = this.files.splice(index,1,this.files[index-1])[0]
			// console.log(this.files)
			// console.log(this.files.splice(index,1,this.files[index-1]))
		},
		swapRight (index) {
			this.files[index+1] = this.files.splice(index,1,this.files[index+1])[0]
		},
		removeFile (index) {
			this.files.splice(index,1)
			if(this.files.length == 0){
				this.$emit('closeMediaPreview')
				return
			}
			setTimeout(() => {
				this.currentFile = this.files[0]
			},100)
		},
		async handleFileChange (file) {	
			let files = Object.values(file)
			console.log(files)
			this.loading = true
			for(const element of files){
				await this.handleFile(element)
			}
			console.log('loading = false')
			this.loading = false
			this.blob = []
		},
		async handleFile (file) {
			return new Promise(async resolve=>{
				let aux = file
				aux.mediaType = this.getMediaType(aux.type)   
				let uploadLimit = JSON.parse(localStorage.getItem('__wpp_preferences_upload_limit'))
				console.log(uploadLimit)
				if (uploadLimit != null) {
					if (aux.mediaType == 'audio') {
						if (aux.size > uploadLimit.fileUploadLimitAudio ) {
							alert('Tamanho do áudio excede o limite máximo permitido!')
							return
						}
					}
					if (aux.mediaType == 'pdf' || aux.mediaType == 'others') {
						console.log(this.bytesToSize(aux.size))
						console.log(this.bytesToSize(uploadLimit.fileUploadLimitDocument))
						console.log(aux.size > uploadLimit.fileUploadLimitDocument)
						if (aux.size > uploadLimit.fileUploadLimitDocument) {
							alert('Tamanho do documento excede o limite máximo permitido!')
							return
						}
					}  
					if (aux.mediaType == 'image') {
					// console.log(uploadLimit.fileUploadLimitImage)
						if (aux.size > 30000000 ) {
							alert('Tamanho da imagem excede o limite máximo permitido!')
							return
						}
						// if(aux.size > 500000){
						// 	console.log('entrei')
						// 	await this.compressImage(aux)
						// 	resolve()
						// 	return
						// }
					}     
					if (aux.mediaType == 'video') {
						if (aux.size > uploadLimit.fileUploadLimitVideo ) {
							alert('Tamanho do vídeo excede o limite máximo permitido!')
							return
						}
					}                   
				}
				this.loadFile(aux)
				resolve()
			})
		},
		// getVideoImage () {
		// 	return new Promise(resolve =>{
		// 		let video = document.getElementById('video')
		// 		console.log(video.videoWidth)
		// 		console.log(video.videoHeight)
		// 		let canvas = document.createElement('canvas')
		// 		canvas.width = video.videoWidth*0.6
		// 		canvas.height = video.videoHeight*0.6
		// 		canvas.getContext('2d').drawImage(video, 0, 0)
		// 		this.currentFile.canvas = canvas.toDataURL()
		// 		console.log(this.currentFile)
		// 		this.update++
		// 		resolve()
		// 	})
		// },
		// getPDFImage () {
		// 	return new Promise(resolve =>{
		// 		let video = document.getElementById('pdf')
		// 		console.log(video.videoWidth)
		// 		console.log(video.videoHeight)
		// 		let canvas = document.createElement('canvas')
		// 		canvas.width = 300
		// 		canvas.height = 300
		// 		canvas.getContext('2d').drawImage(video, 0, 0)
		// 		this.currentFile.canvas = canvas.toDataURL()
		// 		console.log(this.currentFile)
		// 		this.update++
		// 		resolve()
		// 	})
		// },
		compressImage (file) {
			return new Promise(resolve =>{
				console.log('compressimage')
				let that = this
				let f = file
				let fileName = f.name.split('.')[0]
				let img = new Image()
				img.src = URL.createObjectURL(f)
				img.onload = function (e) {
					let canvas = document.createElement('canvas')
					let MAX_WIDTH = that.compressValue
					// let MAX_WIDTH = 5000
					let scaleSize = MAX_WIDTH / e.target.width
					canvas.width = MAX_WIDTH
					canvas.height = e.target.height * scaleSize
					let ctx = canvas.getContext('2d')
					ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
					canvas.toBlob(function (blob) {
						let f2 = new File([blob],fileName + '.png',{type:'image/png', lastModified:new Date().getTime()})
						// let f2 = new File([blob], fileName + '.png')
						that.loadFile(f2)
						resolve()
					}, 'image/jpeg', 1)
				}
			})
		},
		getMediaType (type) {
			switch (true) {
			case (type.indexOf('video') > -1):
				return 'video'            
			case (type.indexOf('voice') > -1):
				return 'voice'
			case (type.indexOf('audio') > -1):
				return 'audio'           
			case (type.indexOf('image') > -1):
				return 'image'
			case (type.indexOf('pdf') > -1):
				return 'pdf'      
			case (type.indexOf('location') > -1):
				return 'location' 
			case (type.indexOf('contacts') > -1):
				return 'contacts'                                                           
			default:
				return 'others'
			}  
		},
		previewClose () {
			this.$emit('closeMediaPreview')
		},
		loadFile (file) {
			if (file.mediaType == 'others' ){
				file.filePath = file.name
			} else {
				try {
					file.filePath = window.URL.createObjectURL(file)             
				} catch (error){
					console.log(error)
					file.filePath = file.name
				}          
			}
			file.message = ''
			file.mediaType = this.getMediaType(file.type)  
			this.files.push(file)
			setTimeout(() => {
				this.currentFile = file
				// setTimeout(() => {
				// 	if(file.mediaType == 'video'){
				// 		this.getVideoImage(this.currentFile)
				// 	}
				// }, 200)
			},100)
		},
		sendMessageData () {
			let obj = {file: this.files, mediaType: this.mediaType, msg: this.message, visible: false}
			this.loading = true
			this.$emit('sendMessageData', obj)
		}       
	},
	mounted () {
		this.blob = this.file
		this.loadFile(this.file)
		// this.currentFile = this.files[0]
	},      
	beforeDestroy () {
	},
}
</script>

<style scoped>
  .preview-responsive {
    width: 100%;
    height: 86vh;
	background-color:white;
	overflow-y:auto ;
	overflow-x: unset; 
  }  
  .preview-input {
    border-top-style: hidden !important;
    border-right-style: hidden !important;
    border-left-style: hidden !important;
    border-bottom-style: groove !important;
    width: 50% !important;
	max-width: 400px;
    border-radius: unset !important;
  }

  .no-outline:focus {
    outline: none !important;
  } 


  .preview-bottom-bar{
      bottom:0;
      left: 0;
      width: 100%;
      padding: 30px;
      position: absolute;
      background: #ECE5DD;
  }  

  .bg-danger {
      background: var(--primary) !important;
  }
  .preview-box-style{
	display: inline-block;
	position: relative;
	height: 70px;
	width: 70px;
	border: solid 2px grey;
	border-radius: 5px;
	overflow: hidden;
  }
  .preview-box-style-highlight{
	border: solid 2px var(--primary);
  }
  .preview-box-style:hover .preview-box-remove{
	/* background-color: yellow; */
	box-shadow: inset 0 0 1em grey;
	opacity: 1;
	z-index: 2;
	border-bottom: hidden;
}
.preview-box-remove{
	position: absolute;
	/* background-image: linear-gradient(black, white); */
    z-index: 100;
	opacity: 0;
	top: 0px;
	right: 0px;
	width: 100%;
	height: 100%;
	transition:ease-in 0.1s;
}
.file-queue{
	max-width: 350px;
	white-space: nowrap;
	overflow-x: scroll;
	overflow-y: hidden;
	display: flex;
}
.teste{
	pointer-events:none;
	overflow: hidden;
}
.circle{
margin-left:25px;
margin-top:-45px;
background-color:white;
border-radius:50%;
padding:3px;
width:15px;
height:15px;
display: flex; 
justify-content: center; 
align-items: center;
box-shadow: 0px 0px 4px black;
}
.arrow{
background-color:white;
border-radius:50%;
padding:3px;
width:15px;
height:15px;
display: flex; 
justify-content: center; 
align-items: center;
box-shadow: 0px 0px 4px black;
}
 
</style>