import axios from 'axios'
import i18n from '../plugins/i18n'
import { baseApiUrl, access_token} from '../global'


axios.defaults.headers.common['Authorization'] = `bearer ${access_token}`


export default {
   
	getListDictionaryId: (companyId, callback) => {
		const url = `${baseApiUrl}`+'api/'+ companyId +'/listDictionaryId'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},    
                        
}
