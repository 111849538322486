import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'

import './config/bootstrap'
import './config/messages'
import './config/axios'
import './config/mq'
import store from './config/store'
import '@/css/global.css'
import '@/css/style.css'
import i18n from '@/plugins/i18n'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
import VEmojiPicker from 'v-emoji-picker'
import VueMask from 'v-mask'
Vue.use(VueMask)
import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer)
import VCalendar from 'v-calendar'
Vue.use(VCalendar)
if ('serviceWorker' in navigator){
	// Use the window load event to keep the page load performant
	window.addEventListener('load', () => {
		navigator.serviceWorker.register('./service-worker.js')
	})
}
import linkify from 'vue-linkify'
Vue.directive('linkified', linkify)
import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)
import VueCryptojs from 'vue-cryptojs'
Vue.use(VueCryptojs)
Vue.config.productionTip = false
Vue.use(VEmojiPicker)


new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app')

