<template>
	<div :key="counter" class="message-aux"> 
		<div v-if="!message">
			loading
		</div>
		<div v-else>
			<!-- <button @click="teste">teste</button> -->
			<!-- {{this.messageInfo.status}} -->
			<div class="divider" v-if="messageInfo.date != undefined && message.date != '-' && message.mediaType != 'becon'">
				<span>{{messageInfo.date}}</span> 
			</div>   
			<div v-if="message.mediaType == 'becon' && message.message.body != ''">  
				<message-default :message="message.message"/>
			</div>        
			<div  v-else style="display:flex;">      
				<div :style="(message.status=='sent' || message.status=='template' || message.status=='quickreply')?'margin-left:auto':''" style="max-width:100%;padding-left:10px;padding-right:10px;padding-top:5px;display:flex" :class="message.messageIndex == contextMessageIndex ? 'animated flash':''"> 
					<div class="mr-2" style="margin-top:auto">
						<message-error v-if="message.status=='sent' || message.status=='template' || message.status=='quickreply'" :messageInfo="message"/>
					</div>
					<div :key="update" :class=" `message ${message.searchIndex} `" ref="message"> 
						<!-- <span v-if="message.chatbot != false">chatbot</span>               -->
						<div v-if="message.contextMessageId != ''" :id="message.contextMessageId"> 
							<message-context @goToContextMessage="sendContextMessageData(message.contextMessageId, message.contextMessage.phonenumberId, message.contextMessage.contact, message.contextMessage.messageIndex, message.contextMessage.contextMediaType)" :contextMessage="message.contextMessage" :messageInfo="message"/>
						</div>    
						<div>
							<div v-if="message.mediaType == 'template'">
								<message-template :messageInfo="message"/>
							</div>
							<div v-if="message.mediaType == 'quickreply'">
								<message-quick-reply :messageInfo="message"/>
							</div>
							<div v-if="message.mediaId != ''">
								<!-- <img :src="message.mediaPath" alt=""> -->
								<message-media @noThumb="noThumb = true" :noThumb="noThumb" @reload="reloadFile" :messageInfo="message"/>
								<message-media-others @reload="reloadFile" :messageInfo="message" :httpStatus="getHttpStatus"/>
							</div>
							<div v-if="typeof message.message == 'string'">
								<message-text :messageInfo="message"/>
							</div>
							<chat-label :contactActive="contactActive" :messageId="message.messageId" v-on:sendLabelData="sendLabelMessageData"/>   
						</div>                
					</div>
				</div>
			</div>
		</div>
	</div>                                          
</template>

<script>
// eslint-disable-next-line no-unused-vars
import WMessageDefault from './WMessageDefault'
import { mediaMessageURL} from '@/global'
import WMessageError from './WMessageError'
import WMessageTemplate from './WMessageTemplate'
import WMessageQuickreply from './WMessageQuickreply'
import WMessageAPI from '@/api/WMessageAPI'
import WMessageMedia from './WMessageMedia'
import WMessageMediaOthers from './WMessageMediaOthers'
import WMessageText from './WMessageText'
import WChatLabel from '../WChatLabel'
import WMessageContextMedia from './WMessageContextMedia'

export default {
	name: 'WMessages',
	components: {
		'message-default': WMessageDefault,
		// eslint-disable-next-line vue/no-unused-components
		'message-error': WMessageError,
		'message-template': WMessageTemplate,
		'message-quick-reply': WMessageQuickreply,		
		'message-media': WMessageMedia,
		'message-media-others': WMessageMediaOthers,
		'message-text': WMessageText,
		// eslint-disable-next-line vue/no-unused-components
		'chat-label': WChatLabel,
		'message-context': WMessageContextMedia,
	},    	
	props: [
		'messageInfo',
		'contactActive',
		'contextMessageIndex',
		'aux',
		'labelMessageId'
	],
	watch:{
		'messageInfo.status' () {
			this.message.tick = this.messageInfo.status
		}
	},
	computed:{
		getStatus () {
			return 'sent'
		},
		getHttpStatus () {
			// if(this.message.thumbnail == false && this.message.mimeType != '' && this.message.httpStatus != 200){
			// 	// console.log(this.message)
			// 	// console.log('computed')
			// 	let http = new XMLHttpRequest()
			// 	if(this.message.mediaType == 'text'){
			// 		return 200
			// 	}
			// 	http.open('HEAD', this.message.mediaPath, false)
			// 	http.send()
			// 	this.reloadFile(http.status)
			// 	return http.status
			// }else{
			// 	return 200
			// }
			return 200
		},
	},
	data () {
		return {
			showMediaView: false,
			componenteMediaView: 0,  
			messageError: '',
			showPopMessageError: false,
			show: true,
			message: undefined,
			update:0,
			counter:0,
			noThumb:false
		}
	},    
	methods: {    	
		reloadFile () {
			if(this.counter <2){
				setTimeout(() => {
					console.log('reload')
					if((this.message.mediaType == 'video' || this.message.mediaType == 'image') && this.message.thumbnail == false){
						WMessageAPI.getThumbnailHas(this.$route.params.companyId,this.message.messageId, response => {
							response
							this.message.thumbnail = response.data 
							// this.message.thumbnail = false
						})
					}
					this.counter++
				}, 5000)
			}else{
				this.noThumb = true
				// this.counter++
			}
		},
		setChatMessage () {
			return new Promise(resolve =>{
				let contact = this.contactActive.waId
				let mediaPath = mediaMessageURL + this.messageInfo.messageId + '&companyId='+this.$route.params.companyId
				let type = this.messageInfo.type
				let mmType = this.messageInfo.mimeType
				if (typeof mmType === 'string' || mmType instanceof String) {
					if (mmType.indexOf('pdf') > -1) {
						type = 'others'    
					}    
				}
				if (this.messageInfo.type == 'document') {
					type = 'others'
				}                                 
				let hasMessageIdInChatMessage = false  
				let msgStatus = 'sent'       
				if (this.messageInfo.status == 4) {
					msgStatus = 'received'                                          
				}                                 
				let mediaFilename = this.messageInfo.mediaFilename    
				if (mediaFilename == '' || mediaFilename == undefined) {
					if (this.messageInfo.type == 'video' || this.messageInfo.type == 'image') {
						mediaFilename = this.messageInfo.mediaId
					}   				
				}                                                  
				let messageBody = this.messageInfo.body
				// template
				if (this.messageInfo.template == true) {
					type = 'template'
					msgStatus = 'template'
					let objMsg = 
				{
					header: this.messageInfo.templateHeader,
					body: this.messageInfo.body,
					footer: this.messageInfo.footer,
					buttons: this.messageInfo.templateButton.split(';')
				} 
					messageBody = objMsg                                                          
				}     
				// template         
				// quickReply            
				if (this.messageInfo.quickReply == true) {
					type = 'quickreply'
					msgStatus = 'quickreply'                                                                                                
					let objMsg = 
				{
					header: this.messageInfo.templateHeader,
					body: this.messageInfo.body,
					footer: this.messageInfo.templateFooter,
					buttons: this.messageInfo.templateButton.split(';')
				} 
					messageBody = objMsg                                                
				}  
				// quickReply  
				let search = ''
				// console.log(this.labelMessageId)
				if (this.messageInfo.messageId == this.labelMessageId) {	
					// console.log('cavalo')
					if (this.messageInfo.status == 4) {
						search = 'received-search-index'
					} else {
						search = 'sent-search-index'
					}                             
				}
				let contextMessage = ''
				if (this.messageInfo.contextMessageId != '') {   
					let contextWithMedia = false
					if (this.messageInfo.mediaId != '') {
						contextWithMedia = true
					}       

					let contextMessageBody = this.messageInfo.contextMessage.body
					let contextMediaFilename = this.messageInfo.contextMessage.mediaFilename 
					let contextType = this.messageInfo.contextMessage.type  
					let contextStatus = this.messageInfo.contextMessage.status       
					if (this.messageInfo.contextMessage.mimeType.indexOf('pdf') > -1) {
						contextType = 'others'
					}
					if (this.messageInfo.contextMessage.type == 'document') {
						contextType = 'others'
					}       
					if (contextMediaFilename == '') {
						contextMediaFilename = this.messageInfo.mediaFilename 
					} 
					if (this.messageInfo.contextMessage.type == 'video' || this.messageInfo.contextMessage.type == 'image') {
						contextMediaFilename = this.messageInfo.mediaId
					} 
                
					if (this.messageInfo.contextMessage.template == true) {   
						contextStatus= 'template'         
						let objMsg = 
					{
						header: this.messageInfo.contextMessage.templateHeader,
						body: this.messageInfo.contextMessage.body,
						footer: this.messageInfo.contextMessage.templateFooter,
						buttons: this.messageInfo.contextMessage.templateButton.split(';')
					} 
						contextMessageBody = objMsg 
						contextWithMedia = true                                                            
					}                          
					if (this.messageInfo.contextMessage.quickReply == true) {
						contextStatus = 'quickreply'  
						let objMsg = 
					{
						header: this.messageInfo.contextMessage.templateHeader,
						body: this.messageInfo.contextMessage.body,
						footer: this.messageInfo.contextMessage.templateFooter,
						buttons: this.messageInfo.contextMessage.templateButton.split(';')
					} 
						contextMessageBody = objMsg 
						contextWithMedia = true                                                                                                                                                       
					}  
					let contextMediaPath = ''             
					if (this.messageInfo.contextMessage.type == 'audio' || this.messageInfo.contextMessage.type == 'voice') {
						contextMediaPath = mediaMessageURL + this.messageInfo.contextMessage.messageId + '&companyId=' + this.$route.params.companyId
					}   
					if (this.messageInfo.contextMessage.type == 'image' || this.messageInfo.contextMessage.type == 'video') {
						contextMediaPath = mediaMessageURL + this.messageInfo.contextMessage.messageId + '&companyId=' + this.$route.params.companyId
					}   				
					contextMessage = 
				{
					contact: contact,
					phonenumberId: this.messageInfo.contextMessage.phonenumberId,
					messageId: this.messageInfo.contextMessage.messageId,
					messageIndex: this.messageInfo.contextMessage.messageIndex,
					searchIndex: search,
					time: this.messageInfo.messageIndexReferenceTime,
					message: messageBody,
					contextMessageBody: contextMessageBody,
					status: contextStatus,
					tick: this.messageInfo.contextMessage.status,
					mediaId: this.messageInfo.mediaId,
					mediaType: type,
					mimeType: this.messageInfo.mimeType,
					mediaPath: mediaPath,     
					thumbPath:mediaPath+'&thumbnail=1',               
					mediaName: contextMediaFilename,
					mediaSize: this.messageInfo.filesize,
					hasURL: this.hasURL(this.messageInfo.contextMessage.body), 
					forwarded: this.messageInfo.contextMessage.forwarded,
					forwardedFrequently: this.messageInfo.contextMessage.forwardedFrequently, 
					userName: this.messageInfo.contextMessage.userName,                                                
					date: 'date',
					template: this.messageInfo.contextMessage.template,
					quickreply: this.messageInfo.contextMessage.quickReply,  
					contextWithMedia: contextWithMedia,
					contextMediaType: contextType,
					contextMediaPath: contextMediaPath,        
					timeSending:  this.getDateTimeFromTimestamp(this.messageInfo.messageIndexReferenceTime)                  
				}                                 
				}
				// console.log(url)
				if (!hasMessageIdInChatMessage) {
					this.message = {
						...this.messageInfo,
						thumbPath:mediaPath+'&thumbnail=1',
						contact: contact,
						phonenumberId: this.messageInfo.phonenumberId,
						chatFlow: this.messageInfo.chatbot,
						messageId: this.messageInfo.messageId,
						messageIndex: this.messageInfo.messageIndex,
						searchIndex: search,
						time: this.messageInfo.messageIndexReferenceTime,
						message: messageBody,
						status: msgStatus,
						tick: this.messageInfo.status,
						mediaId: this.messageInfo.mediaId,
						mediaType: type,
						mimeType: this.messageInfo.mimeType,
						mediaPath: mediaPath,
						mediaName: mediaFilename,
						mediaSize: this.messageInfo.filesize,
						hasURL: this.hasURL(this.messageInfo.body), 
						forwarded: this.messageInfo.forwarded,
						forwardedFrequently: this.messageInfo.forwardedFrequently, 
						userName: this.messageInfo.userName,                                                
						// date: 'date',
						template: this.messageInfo.template,
						quickreply: this.messageInfo.quickReply,
						contextMessageId: this.messageInfo.contextMessageId,
						contextMessage: contextMessage,
						timeSending:  this.getDateTimeFromTimestamp(this.messageInfo.messageIndexReferenceTime)                                                                               
					} 
					resolve()
				// this.loading = false
				// this.$nextTick(() => {							
				// 	if (this.chatMessages.length == this.chatMessageLength) {
				// 		this.loading = false					
				// 		if (searchIndex == null) {
				// 			try {
				// 				document.getElementsByClassName('chat-scrollable')[0].style.opacity = '1'								
				// 			} catch (error) {
				// 				console.log(error)
				// 			}	
				// 			this.scrollToEnd()  																													 
				// 		}	
				// 		this.searchContextMessage = false						
				// 	}				
				// })					                               
				}  
			})
		}, 
		getDateTimeFromTimestamp (time) {
			let date = new Date(time)
			return (('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2))
		},     
		hasURL (text) {
			if(new RegExp('([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?').test(text)) {
				return true
			}
			return false
		},   
		sendLabelMessageData (obj) {
			this.$emit('sendMessageData', obj)
		},
		sendContextMessageData (contextId, id, contact, index, mediaType) {
			console.log('contextWithMedia' + mediaType)
			let contextObj = 
					{
						contextMessageId: contextId, 
						phonenumberId: id,
						contact: contact,
						messageIndex: index
					}
			console.log(this.messageInfo.messageIndex == this.contextMessageIndex)
			let obj = {file: null, mediaType: 'context_message', msg: contextObj, visible: false}
			this.$emit('sendMessageData', obj)				
		}, 
		teste () {
			// console.log(this.getHttpStatus)
			console.log(this.message)
		},    
                      
	},    
	async mounted () {
		console.log('message set')
		await this.setChatMessage()
		// console.log(this.message)
		// this.message = this.messageInfo
		// console.log(this.message.status)
		// console.log(this.messageInfo)
	}, 
}
</script>

<style scoped>
    header {
        height: 30px;
        text-align: center;
        margin-bottom: 10px;
        padding: 20px;
    }
    article {
        margin-top: 10px;
        padding: 5px;
        text-align: center;
        padding: 20px;
    }
    footer {
        margin-top: 10px;
        height: auto;
        text-align: center; 
        padding: 10px;  
    }
    .default-message {
        width: 90%; 
        /* margin: 10px 10px 0px; */
		margin-left:5%;
		margin-top: 10px;
        padding: 10px; 
        display: flex;
        background-color: var(--secondary);
        height: auto; 
        min-height:20px;
        border-radius: 8px; 
        color: white; 
        align-items: center; 
        justify-content: center; 
        font-family: 'Roboto'; 
        font-size: 12px;  
    }  
    .dateReceived {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        color: #777777;
    }    
    .dateSent {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        color: #777777;
    }
    video {
        pointer-events: none;
    }  
    .a {
        display: flex;
    }          
    .a >>> .b {
        display: contents !important;
    } 
	.animated {
		-webkit-animation-duration: 1s;
		animation-duration: 1.5s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
	}
         
	@keyframes flash {
	0%, 100% {
		opacity: 1;
		border-radius: 10px;
	}
	50% {
		background-color:var(--primary);
		border-radius: 10px;
	}
	}
         
	.flash {
	animation-name: flash;
	}      
</style>



 