<template>
	<b-col class="attendant-scrollable scrollbar">
		<div v-if="step == 3.1">
			<b-row class="ml-3 mt-3">  
				<h3 class="lbl-title">Novo usuário</h3>                                              
			</b-row>
			<b-row class="ml-3">
				<span class="custom-font-open-14-gray-normal">Cadastre um usuário a sua equipe</span>                       
			</b-row>
		</div>
		<div v-else>
			<b-row class="ml-3 mt-3">  
				<h3 class="lbl-title">Editar usuário</h3>                                              
			</b-row>
			<b-row class="ml-3">
				<span class="custom-font-open-14-gray-normal">Edite informações de um usuário da sua equipe</span>                       
			</b-row>
		</div>
		<div class="w-100">
			<hr>
		</div>          
		<b-row>
			<b-col md="8">
				<b-row>
					<attendant-photo v-if="step == 3.1" :mode="'ins'" :agentId="''" v-on:sendMessageData="getMessageData"/>
					<attendant-photo v-else :mode="'upd'" :agentId="agentId" v-on:sendMessageData="getMessageData"/>                    
				</b-row>
				<div class="ml-4 mt-3 mr-3">
					<b-row>
						<h6>Dados do usuário</h6>
					</b-row>
					<b-row>           
						<label for="attendant">Nome completo</label>   
						<b-form-input 
							id="attendant" 
							type="text"
							v-model="attendantName" 
							placeholder="Nome"                         
							:autofocus="true"/>                              
					</b-row>
					<b-row class="mt-2">
						<label>Telefone</label>
					</b-row>      
					<b-row>                        
						<b-col md="3" style="margin-left: -15px;">
							<country-code :key="componentCountryKey" :countryNumber="countryNumber" v-on:setCountryCode="getCountryCode"/>
						</b-col>
						<b-col style="margin-top: -5px;">
							<b-form-input
								id="dropdown-form-phoneNumber"
								type="number"
								placeholder="(47) 0000-00000"
								v-model="phoneNumber"
							></b-form-input>
						</b-col>
					</b-row>                       
					<b-row class="mt-2">              
						<label for="email">E-mail</label>   
						<b-form-input 
							id="email" 
							type="text"
							v-model="email" 
							placeholder="exemplo@gmail.com"/>                     
					</b-row>    
					<b-row class="mt-2" v-show="step == 3.1">       
						<label for="password">Senha                             
							<span class="custom-font-open-12-gray mt-1 ml-2">(mínimo de 6 dígitos)</span>                            
						</label>          
						<b-form-input id="password" 
							type="password"
							v-model="password"
							size="20" 
							placeholder="Informe uma senha"/>                                        
					</b-row>
					<b-row class="mt-2" v-show="step == 3.1">       
						<label for="password">Confirme a senha                      
							<span class="custom-font-open-12-gray mt-1 ml-2">(mínimo de 6 dígitos)</span>                            
						</label>          
						<b-form-input id="password"
							type="password"
							v-model="passwordConfirm"
							size="20" 
							placeholder="Informe uma senha" @input="setBtnDisable"/>                                        
					</b-row>  
					<b-row class="mt-2">
						<b-col>
							<div v-if="step==3.1" class="d-flex justify-content-center">
								<b-button variant="outline-primary" class="mr-2"
									@click="cancel()">Cancelar
								</b-button>                      
								<b-button variant="primary" 
									@click="next()" :disabled="btnDisable">Próximo
								</b-button>                           
							</div>
							<div v-else class="d-flex justify-content-center">
								<b-button variant="outline-primary" class="mr-2"
									@click="cancel()">Cancelar
								</b-button>                      
								<b-button variant="primary" 
									@click="next()">Salvar configurações
								</b-button>                           
							</div>                            
						</b-col>
					</b-row>  
				</div>                                  
			</b-col> 
			<b-col md="4">
				<b-row>
					<div class="mt-5">
						<b-form-group label="Funções">
							<b-form-checkbox id="checkbox-1" v-model="admin" value="1" unchecked-value="0"
								@change="setOption">
								Administrador
							</b-form-checkbox>      
							<b-form-checkbox id="checkbox-2" v-model="agent" value="1" unchecked-value="0">
								Atendimento
							</b-form-checkbox>     
							<b-form-checkbox id="checkbox-3" v-model="dashboard" value="1" unchecked-value="0">
								Visualizar painel
							</b-form-checkbox>     
							<b-form-checkbox id="checkbox-4" v-model="developer" value="1" unchecked-value="0">
								Desenvolvedor
							</b-form-checkbox>     
							<b-form-checkbox id="checkbox-5" v-model="contact" value="1" unchecked-value="0">
								Gestão de contato
							</b-form-checkbox>     
							<b-form-checkbox id="checkbox-6" v-model="broadcast" value="1" unchecked-value="0">
								Gestão de transmissão
							</b-form-checkbox>     
							<b-form-checkbox id="checkbox-7" v-model="automation" value="1" unchecked-value="0">
								Gestão de automatização
							</b-form-checkbox>     
							<b-form-checkbox id="checkbox-9" v-model="billing" value="1" unchecked-value="0">
								Financeiro
							</b-form-checkbox>   
						</b-form-group>                                                                                                                                                                                                                   
					</div>
				</b-row>
			</b-col>                         
		</b-row>            
	</b-col>
</template>

<script>
import WAttendantPhoto from './WAttendantPhoto'
import WCountryCode from '../WCountryCode'
import WAttendantsAPI from '@/api/WAttendantsAPI'
import WAvatarAPI from '@/api/WAvatarAPI'
import WUserAPI from '@/api/WUserAPI'
export default {
	name: 'WAttendant',
	props: ['step'],
	components: {
		'attendant-photo': WAttendantPhoto, 
		'country-code': WCountryCode                            
	},
	data () {
		return {
			companyId: '',
			agentId: '',
			selected: [],            
			attendantName: '', 
			email: '',    
			countryNumber: '+55',
			phoneNumber: '',
			password: '',
			passwordConfirm: '',
			btnDisable: true,
			file: null, 
			admin: 0,    
			agent: 0,
			dashboard: 0,
			developer: 0,
			contact: 0,
			broadcast: 0,
			automation: 0,
			template: 0,
			billing: 0, 
			componentCountryKey: 0                                          
		}
	},    
	methods: {
		setBtnDisable () {
			this.btnDisable = false
		},
		setOption () {
			if (this.admin == 1){
				this.agent = 1
				this.dashboard = 1
				this.developer = 1
				this.contact = 1
				this.broadcast = 1
				this.automation = 1
				this.template = 1
				this.billing = 1                
			} else {
				this.agent = 0
				this.dashboard = 0
				this.developer = 0
				this.contact = 0
				this.broadcast = 0
				this.automation = 0
				this.template = 0
				this.billing = 0   
			}
		},
		getMessageData (obj) {
			this.file = obj
		},    
		getCountryCode (code) {
			this.countryNumber = code
		},            
		getAgentDetail () {
			WAttendantsAPI.getDetail(this.$route.params.companyId, this.agentId, response => {
				console.log(response.data)
				let attendantDetail = response.data
				this.attendantName = attendantDetail.name   
				this.countryNumber = attendantDetail.countryNumber 
				this.componentCountryKey ++   
				this.phoneNumber =  attendantDetail.phonenumber.substring(3, attendantDetail.phonenumber.length )     
				this.email = attendantDetail.email     
				this.password = attendantDetail.password
				if (attendantDetail.adminPermission == true){
					this.admin = 1
				}
				if (attendantDetail.agentPermission == true){
					this.agent = 1
				}
				if (attendantDetail.dashboardPermission == true){
					this.dashboard = 1
				}
				if (attendantDetail.developerPermission == true){
					this.developer = 1
				}
				if (attendantDetail.contactPermission == true){
					this.contact = 1
				}
				if (attendantDetail.broadcastPermission == true){
					this.broadcast = 1
				}   
				if (attendantDetail.automationPermission == true){
					this.automation = 1
				}       
				if (attendantDetail.templatePermission == true){
					this.template = 1
				}                   
				if (attendantDetail.billingPermission == true){
					this.billing = 1
				}                                                                                                                                                         
			})            
		},            
		next () {
			if (this.attendantName == '' || this.attendantName == undefined){
				alert('Campo nome obrigatório!')
				return
			}       
			if (this.phoneNumber == '' || this.phoneNumber == undefined){
				alert('Campo telefone obrigatório!')
				return
			}  
			if (this.email == '' || this.email == undefined){
				alert('Campo mensagem obrigatório!')
				return
			}  
			if (this.step == 3.1){
				if (this.password.length < 6){
					alert('Campo senha mínimo de 6 dígitos!')
					return
				}                  
				if (this.password == '' || this.password == undefined){
					alert('Campo senha obrigatório!')
					return
				}
				if(this.password !== this.passwordConfirm){
					alert('Campos de senha tem que ser igual!')
					return
				}                   
			}             
			if (!this.validateEmail(this.email)){
				alert('Email inválido!')
				return
			}                    
			if (this.admin == 0 && this.agent == 0 && this.dashboard == 0 && this.developer == 0 
                && this.contact == 0 && this.broadcast == 0 && this.automation == 0 
                && this.template == 0 && this.billing == 0){
				alert('Selecione uma função!')
				return                
			}                    
			let objAgent = {
				name: this.attendantName,    
				countryNumber: this.countryNumber,     
				phonenumber: this.phoneNumber,     
				email: this.email,     
				password: this.password,     
				admin: this.admin,    
				agent: this.agent, 
				dashboard: this.dashboard, 
				developer: this.developer,      
				contact: this.contact,      
				broadcast: this.broadcast,     
				automation: parseInt(this.automation), 
				template: this.template,
				billing: this.billing,
			}     
			console.log(objAgent)
			if (this.step == 3.1){
				WAttendantsAPI.postCreateAgent(this.$route.params.companyId, objAgent, response => {
					if (response){ 
						let agentId = response.data
						if (agentId != ''){
							let obj = {userId: agentId}                    
							localStorage.removeItem('__wpp_settings_obj')
							localStorage.setItem('__wpp_settings_obj', JSON.stringify(obj))                         
							if (this.file != null){
								WAvatarAPI.postUploadMedia(this.$route.params.companyId, agentId, this.file , response => {
									if (response){          
										let obj = {step: 3.3}
										this.$emit('sendMessageData', obj)                                                                                      
									} else {                 
										alert('Falha no upload do arquivo!')
									}
								})                              
							} else {
								let obj = {step: 3.3}
								this.$emit('sendMessageData', obj) 
							}                                                
						}                                                            
					} else {
						this.show =true 
						alert('Falha ao criar usuário!')
					}
				})                   
			} else {
				WAttendantsAPI.postUpdateAgent(this.$route.params.companyId, this.agentId, objAgent, response => {
					response
					console.log(objAgent)
					if (response){                          
						if (this.file != null){
							WAvatarAPI.postUploadMedia(this.$route.params.companyId, this.agentId, this.file , response => {
								if (response){               
									this.getUserProfile()                                
									let obj = {step: 3}
									this.$emit('sendMessageData', obj)                                                                                      
								} else {                 
									alert('Falha no upload do arquivo!')
								}
							})                              
						} else {
							let obj = {step: 3}
							console.log('sucesso')
							this.getUserProfile()
							this.$emit('sendMessageData', obj) 
						}                                                                                                            
					} else {
						this.show =true 
						alert('Falha ao atualizar usuário!')
					}
				})                   
			}                      
		},
		getUserProfile () {
			WUserAPI.getUserProfile(response => {
				localStorage.setItem('__whatsapp_api_user',JSON.stringify(response.data))
				document.location.reload(true)
			})  
		},	       
		validateEmail (email) {
			let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			return re.test(email)
		},        
		cancel () {
			let obj = {step: 3}
			this.$emit('sendMessageData', obj)                    
		}        
	},
	mounted () {
		if (this.step == 3.2){
			let objAgent = JSON.parse(localStorage.getItem('__wpp_settings_obj'))
			if (objAgent != null){
				this.agentId = objAgent.userId 
				this.getAgentDetail()
			}         
		}        
	}, 
}
</script>

<style>
.custom-control {
    margin-top: 10px !important;
}

.attendant-scrollable {
    height: 80vh;
    width: 100%;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>
