<template>
	<div style="display:flex; margin-right: 30px;">
		<side-bar-menu :key="reload" :loadedItem="item" :menuItems="menuItems" @openMenuItem="openMenuItem" />
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==1">
			<keywords-list 
				v-on:sendMessageData="getMessageData"
			/>
		</b-col>     
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==2 || step == 2.1">
			<keywords-create 
				:step="step"
				v-on:sendMessageData="getMessageData"
			/>           
		</b-col>  
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==3">
			<keywords-action 
				:addNew="false"
				:showButtons="true"
				v-on:sendMessageData="getMessageData"
			/>
		</b-col>                                     
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==4">
			<keywords-select 
				:actionType="actionType"
				v-on:sendMessageData="getMessageData"
			/>
		</b-col>      
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==5 || step==5.1">
			<keywords-action-list 
				:step="step"
				v-on:sendMessageData="getMessageData"
			/>
		</b-col>        
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==6 || step == 7">
			<keywords-finalize 
				:step="step"
				v-on:sendMessageData="getMessageData"
			/>
		</b-col>  
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==8">
			<quickreply-list 
				:step="step"
				v-on:sendMessageData="getMessageData"
			/>
		</b-col>               
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==9">
			<quickreply-actions
				:step="step"
				v-on:sendMessageData="getMessageData"
			/>
		</b-col> 
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==10 || step ==10.1">
			<quick-reply 
				:step="step"
				:action="actionType"
				v-on:sendMessageData="getMessageData"
			/>
		</b-col>        
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==11 || step==11.1">
			<quickreply-departments
				:step="step"
				v-on:sendMessageData="getMessageData"
			/>
		</b-col>                    
		<b-col sm="9" class="mt-4 ml-3 screen-template-custom" v-if="step==12">
			<automatic-messages 
				:step="step"
				v-on:sendMessageData="getMessageData"
			/>
		</b-col>
		<b-col md="10" class="mt-4 ml-3 screen-template-custom" v-if="step==13">
			<template-list 
				:step="step"
				v-on:sendMessageData="getMessageData"
			/>
		</b-col>  
		<b-col md="10" class="mt-4 ml-3 screen-template-custom mr-3" v-if="step==14">
			<chat-flow-list 
				:step="step"
				v-on:sendMessageData="getMessageData"
			/>
		</b-col>  		
	</div>
   
</template>

<script>
import WKeywordsList from './keywords/WKeywordsList'
import WKeywords from './keywords/WKeywords'
import WKeywordsAction from './keywords/WKeywordsAction'
import WKeywordsSelect from './keywords/WKeywordsSelect'
import WKeywordsActionList from './keywords/WKeywordsActionList'
import WKeywordsFinalize from './keywords/WKeywordsFinalize'
import WAutomaticMessages from './messages/WAutomaticMessages'
import WQuickReplyList from './quickreply/WQuickReplyList'
import WQuickReplyActions from './quickreply/WQuickReplyActions'
import WQuickReply from './quickreply/WQuickReply'
import WQuickReplyDepartments from './quickreply/WQuickReplyDepartments'
import WTemplateList from './template/WTemplateList'
import SideBarMenu from '@/components/global/SideBarMenu'
import WChatFlowList from './chatflowbot/WChatFlowList'
// import {baseApiUrl} from '@/global'
export default {    
	components: 
{
	'keywords-list': WKeywordsList,
	'keywords-create': WKeywords,
	'keywords-action': WKeywordsAction,
	'keywords-select': WKeywordsSelect,
	'keywords-action-list': WKeywordsActionList,
	'keywords-finalize': WKeywordsFinalize,
	'automatic-messages': WAutomaticMessages,
	'quickreply-list': WQuickReplyList,
	'quickreply-actions': WQuickReplyActions,        
	'quick-reply': WQuickReply,
	'quickreply-departments': WQuickReplyDepartments,
	'template-list': WTemplateList,
	'side-bar-menu':SideBarMenu,
	'chat-flow-list': WChatFlowList
},
	name: 'WAutomationMain',  
	data: function () {
		return {
			step: 12,
			loading: false,
			reload:0,
			phoneNumber: '',
			departmentName: '',
			templateName: '',
			broadcastStartAt: '',
			whocalled: '',
			actionType: 0,
			keywordId: '',
			completed: false,
			item: 0,
			menuItems:[]
			// menuItems:[
			// 	{
			// 		name:'Palavra Chave',
			// 	},
			// 	{
			// 		name:'Respostas Rápidas'
			// 	},
			// 	{
			// 		name:'Mensagens automáticas',
			// 	},
			// 	{
			// 		name:'Templates'
			// 	}
			// ]			
		}
	},

	methods: { 
		openMenuItem (obj) {	
			switch(obj.index){
			case 0:
				this.step =12
				break
			case 1:
				this.step = 13	
				break
			case 2:
				this.step = 14	
				break
			case 3:
				this.step = 8	
				break
			case 4:
				this.step = 1	
				break				
			}
			localStorage.removeItem('__wpp_automation_keyword_obj') 
			localStorage.removeItem('__wpp_automation_quickreply_obj') 
			localStorage.removeItem('__wpp_automation_step') 
			let objStep = {currentStep: this.step, currentMenuItem: obj.index}
			localStorage.setItem('__wpp_automation_step', JSON.stringify(objStep))
		},
		openKeywordList () {
			this.item = 0     
			this.step =1      
			localStorage.removeItem('__wpp_automation_keyword_obj') 
			localStorage.removeItem('__wpp_automation_quickreply_obj') 
			localStorage.removeItem('__wpp_automation_step') 
			let objStep = {currentStep: this.step, currentMenuItem: this.item}
			localStorage.setItem('__wpp_automation_step', JSON.stringify(objStep))             
		},
		openQuickreplyList () {
			this.item = 1            
			localStorage.removeItem('__wpp_automation_keyword_obj') 
			localStorage.removeItem('__wpp_automation_quickreply_obj') 
			this.step =8
			localStorage.removeItem('__wpp_automation_step') 
			let objStep = {currentStep: this.step, currentMenuItem: this.item}
			localStorage.setItem('__wpp_automation_step', JSON.stringify(objStep))             
		},        
		openAutomationMessages () {
			this.item = 2
			localStorage.removeItem('__wpp_automation_quickreply_obj') 
			localStorage.removeItem('__wpp_automation_keyword_obj') 
			this.step = 12
			localStorage.removeItem('__wpp_automation_step') 
			let objStep = {currentStep: this.step, currentMenuItem: this.item}
			localStorage.setItem('__wpp_automation_step', JSON.stringify(objStep))              
		},
		openTemplateList () {
			this.item = 3
			localStorage.removeItem('__wpp_automation_quickreply_obj') 
			localStorage.removeItem('__wpp_automation_keyword_obj') 
			this.step = 13
			localStorage.removeItem('__wpp_automation_step') 
			let objStep = {currentStep: this.step, currentMenuItem: this.item}
			localStorage.setItem('__wpp_automation_step', JSON.stringify(objStep))  
		},
		getMessageData (obj) {
			if (obj.step == 1){
				localStorage.removeItem('__wpp_automation_keyword_obj') 
			}
			localStorage.removeItem('__wpp_automation_step')           
			let objStep = {currentStep: obj.step, currentMenuItem: this.item}
			localStorage.setItem('__wpp_automation_step', JSON.stringify(objStep))            
			this.step = obj.step
			if (obj.step == 3 && obj.hasOwnProperty('completed')){
				this.keywordId = obj.id
				this.completed = obj.completed
			}            
			if (obj.step == 4 || obj.step == 10){
				this.actionType = obj.actionType      
			}
		},       
	},
	mounted () {		
		this.menuItems.push({name: 'Mensagens automáticas'})
		this.menuItems.push({name: 'Templates'})
		this.menuItems.push({name: 'Fluxos para Chatbot'})
		this.menuItems.push({name: 'Respostas Rápidas'})
		this.menuItems.push({name: 'Palavra Chave'})
		let step = JSON.parse(localStorage.getItem('__wpp_automation_step'))   
		if (step != null){
			this.step = step.currentStep     
			this.item = step.currentMenuItem    
			this.reload++ 
		}        
	}    
}
</script>

<style scoped>

.nav-link {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 20px !important;  
    color: #414141;
}

.automation-list-scrollable {
    height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
}

.active {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 20px !important;  
    color: var(--primary);
    background: #F5F5F5;    
    border-right: solid 3px var(--primary);    
}
</style>