<template>
	<div style="margin-left: -10px;">
		<b-dropdown id="dropdown-form" ref="dropdown" variant="link" toggle-class="text-decoration-none" no-caret>			
			<template #button-content>
				<div @click="getQuickReply()">
					<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M2.18181 -0.0229492C1.59713 -0.0229492 1.03639 0.209315 0.622961 0.622747C0.209528 1.03618 -0.0227356 1.59691 -0.0227356 2.1816V13.818C-0.0227356 14.4026 0.209528 14.9634 0.622961 15.3768C1.03639 15.7902 1.59713 16.0225 2.18181 16.0225H10.9091C11.4938 16.0225 12.0545 15.7902 12.4679 15.3768C12.8814 14.9634 13.1136 14.4026 13.1136 13.818V5.09069C13.1136 4.89178 13.0346 4.70101 12.894 4.56036L8.53032 0.196721C8.38967 0.0560684 8.1989 -0.0229492 7.99999 -0.0229492H2.18181ZM1.68362 1.68341C1.81575 1.55128 1.99495 1.47705 2.18181 1.47705H7.68933L11.6136 5.40135V13.818C11.6136 14.0048 11.5394 14.184 11.4073 14.3162C11.2751 14.4483 11.0959 14.5225 10.9091 14.5225H2.18181C1.99495 14.5225 1.81575 14.4483 1.68362 14.3162C1.55149 14.184 1.47726 14.0048 1.47726 13.818V2.1816C1.47726 1.99474 1.55149 1.81554 1.68362 1.68341ZM6.21307 4.18581C6.37231 3.90636 6.80003 4.03941 6.77264 4.35988L6.52782 7.22421C6.51286 7.39933 6.65097 7.54976 6.82674 7.54976H9.48378C9.71396 7.54976 9.85839 7.79828 9.74443 7.99828L6.78693 13.1887C6.62769 13.4682 6.19997 13.3351 6.22736 13.0146L6.47218 10.1503C6.48714 9.97518 6.34903 9.82476 6.17327 9.82476H3.51622C3.28604 9.82476 3.14161 9.57623 3.25557 9.37623L6.21307 4.18581Z" fill="#BA668A"/>
					</svg>
				</div>

			</template>                    

			<div>
				<b-overlay :show="loading" variant="light"
					no-wrap>
					<template #overlay>
						<b-spinner style="width: 3rem; height: 3rem; color: var(--secondary)" label="Large Spinner"></b-spinner>
					</template>                    
				</b-overlay> 				
				<b-card
					header="Respostas rápidas"
					header-tag="header"
				>    
					<b-form-group>
						<div style="height: 200px; overflow-y: auto;">
							<b-list-group>
								<b-list-group-item v-for="(x, index) in quickReplyList" 
									:key="index"
									v-show="x.visible==true" style="cursor: pointer;">
									<div @click="sendQuickReply(x.quickReplyId, x.quickReplyHeader, x.quickReplyContent, x.quickReplyFooter, x.quickReplyListButton, x.containsMedia)">
										<div class="custom-font-open-14">                
											<strong>{{x.quickReplyCommand}}</strong>
										</div>     
										<div class="custom-font-open-14">
											{{x.quickReplyDescription}}
										</div>                   
										<div class="custom-font-open-14">                
											{{x.quickReplyContent}}
										</div>                                
									</div> 
								</b-list-group-item>
							</b-list-group>                       
						</div> 
						<b-input-group>
							<b-input-group-prepend is-text class="mt-1">
								<b-icon icon="search"></b-icon>
							</b-input-group-prepend>                        
							<b-form-input    
								id="search-Id"                     
								type="search"                     
								placeholder="Buscar resposta rápida"
								v-model="search"
								@keyup="searchQuickReply"
								@keydown.enter.exact.prevent="sendUniqueQuickReply"
								:autofocus="true"
								class="mt-1 custom-font-open-14-gray"
								@update="clearFilter">
							</b-form-input>                                      
						</b-input-group>                              
					</b-form-group>                    
				</b-card>
			</div>                                  
		</b-dropdown>             
	</div>                                          
</template>

<script>
import WTemplatesAPI from '@/api/WTemplatesAPI'
export default {
	name: 'WQuickReply',
	props: [
		'departmentId',
		'showQuickReply'
	],
	data () {
		return { 
			loading: false,
			search: '',
			quickReplyList: []             
		}
	},    
	methods: {
		getQuickReply () {            
			if (this.departmentId != undefined && this.departmentId != ''){
				this.getQuickReplyList()
			}       
			this.$emit('openQuickReply')                        
		},
		getQuickReplyList () {
			this.loading = true
			WTemplatesAPI.getQuickReplyByDepartmentId(this.$route.params.companyId, this.departmentId, response => {
				let quickReplyList = response.data
				this.loading = false
				quickReplyList.forEach(quick => {
					quick.visible = true                     
				})                        
				this.quickReplyList = quickReplyList
			})                
		},
		cancel () {
			this.$refs.dropdown.hide(true)
		},
		sendQuickReply (id, header, content, footer, buttons, contains) {  
			let quick = {
				id: id, 
				header: header,
				content: content,
				footer:  footer,
				buttons: buttons,
				containsMedia: contains                
			}                                                                     
			let obj = {file: null, mediaType: 'quickreply', msg: quick, visible: false}
			this.$emit('sendMessageData', obj) 
			this.$refs.dropdown.hide(true)
		},
		sendUniqueQuickReply () {  
			let quick = {}
			let countItem = 0
			this.quickReplyList.forEach(x => {
				if (x.visible == true){
					countItem ++                        
				}            
			}) 
			if (countItem == 1){
				this.quickReplyList.forEach(x => {
					if (x.visible == true){
						quick = {           
							id: x.quickReplyId, 
							header: x.quickReplyHeader,
							content: x.quickReplyContent,
							footer:  x.quickReplyFooter,
							buttons: x.quickReplyListButton,
							containsMedia: x.containsMedia                
						}                            
					}            
				})                                                                              
				let obj = {file: null, mediaType: 'quickreply', msg: quick, visible: false}
				this.$emit('sendMessageData', obj) 
				this.$refs.dropdown.hide(true)                
			}            
		},        
		searchQuickReply () {
			this.quickReplyList.forEach( el => {
				if ( el.quickReplyCommand.toLowerCase().includes(this.search.toLowerCase())
                    || el.quickReplyDescription.toLowerCase().includes(this.search.toLowerCase()) ){
					el.visible = true
				} else {
					el.visible = false
				}
			})        
		}, 
		hideQuickReply () {
			this.$refs.dropdown.hide(true)
		},
		clearFilter () {
			if(this.search == ''){
				this.searchQuickReply()
			}
		}    
                     
	},
	mounted () {    
		// this.$emit('openQuickReply')        
		if (this.showQuickReply == true){
			this.$refs.dropdown.show(true)            
		}

		if (this.departmentId != undefined && this.departmentId != ''){
			this.getQuickReplyList()
		}        
	}, 

}
</script>

<style scoped>
.card {
    min-width: 500px !important;
    max-width: 500px !important;
}  
@media screen and (min-width: 1300px) {
    .card {
        min-width: 650px !important;
        max-width: 650px !important;
    }
}
@media screen and (max-width: 600px) {
    .card {
        min-width: 340px !important;
        max-width: 340px !important;
    }
}
</style>

 
