<template>
	<div style="margin-left: 30px; margin-right: 30px;">
		<b-col class="mt-2 ml-2">
			<b-row class="ml-1">
				<b-col class="mt-2">
					<h3 class="lbl-title">Chaves de API</h3>
				</b-col>
				<b-col>
					<b-col>
						<b-row class="justify-content-end mr-5">
							<b-button v-b-toggle.sidebar-right variant="secondary"
								@click="create()">
								<span>
									<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M8 3V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M3 8H13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								</span>
								<span class="ml-1">
									Adicionar chave de API
								</span>                        
							</b-button>                                                             
						</b-row>
					</b-col>                
				</b-col>
			</b-row>
			<b-row class="ml-3">
				<span class="custom-font-open-14-gray-normal">
					Visualização de chaves de API de produção.                
				</span>                       
			</b-row>        
		</b-col>
		<b-col md="12" sm="12" class="screen-template">
			<loading v-if="this.$store.state.loading ==true"/>     
			<b-row style="margin-rigth:30px;" v-if="this.$store.state.loading ==false">
				<b-col md="6">
					<div class="search">  
						<b-input-group>
							<b-form-input 
								type="search"                     
								placeholder="Busque pelo nome da chave"
								v-model="filter"
								@keyup="searchKey"
								@update="clearFilter"
								class="mt-1">
							</b-form-input>
							<b-input-group-prepend is-text class="mt-1">
								<b-icon icon="search"></b-icon>
							</b-input-group-prepend>                    
						</b-input-group>                                                                                       
					</div>
				</b-col>                            
			</b-row>
			<b-row class="mt-1">   
				<div>
					<keyapi-modal-delete
						:title = "'Excluir chave api'"
						:subTitle = "'Você realmente deseja remover a chave da api?'"
						:description = "keyapiName"
						:description1 = "''"
						:value = "''"
						:placeholder = "''"
						:button1 = "'Fechar'"
						:button2 = "'Excluir'"
						:start="showModalDelete"   
						v-on:sendModalData="removeKeyAPI"
						:key="componentDeleteModalKey"                        
					/>
				</div>                                                  
				<b-col class="ml-2" v-if="this.$store.state.loading ==false">
					<div class="contatc-list-scrollable scrollbar">
						<b-table-simple id="my-table"
							:per-page="perPage"
							:current-page="currentPage" hover small responsive>
							<b-thead head-variant="ligth">
								<b-tr>
									<b-th>Nome da Chave</b-th>
									<b-th>Descrição da Chave</b-th> 
									<b-th>Token da Chave</b-th>                
									<b-th>Data de Criação</b-th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr :key="index" v-for="(x, index) in orderBy(list, 'apiTitle')"
									v-show="x.visible ==true">
									<b-td class="td-keyapi">{{x.apiTitle}}</b-td>
									<b-td class="td-date">{{x.apiDescription}}</b-td> 
									<b-td class="td-date"> <span class="mt-1" :id="x.apiKey">{{x.apiKey}}</span> <span class="icon-clickable" v-b-tooltip.hover title="Clique para copiar a chave" @click="copy(x.apiKey)"><img src="@/assets/copy.png"></span>                                                                
									</b-td>                             
									<b-td class="td-date">{{timeToDate(x.createdTime)}}</b-td>                                                                                                                                                                            
									<b-td>
										<div>
											<b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" right text="Right align" no-caret>
												<template #button-content>
													<svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
														<circle cx="2" cy="2" r="2" fill="#C4C4C4"/>
														<circle cx="2" cy="11" r="2" fill="#C4C4C4"/>
														<circle cx="2" cy="20" r="2" fill="#C4C4C4"/>
													</svg>
												</template>
												<b-dropdown-item @click="editKeyAPI(x.apiKey, x.apiTitle, x.apiDescription)" >
													<span>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M10.0779 1.50136C10.2368 1.34241 10.4255 1.21632 10.6332 1.1303C10.8409 1.04428 11.0635 1 11.2883 1C11.513 1 11.7356 1.04428 11.9433 1.1303C12.151 1.21632 12.3397 1.34241 12.4986 1.50136C12.6576 1.66031 12.7837 1.84901 12.8697 2.05669C12.9557 2.26436 13 2.48695 13 2.71174C13 2.93653 12.9557 3.15912 12.8697 3.3668C12.7837 3.57447 12.6576 3.76317 12.4986 3.92212L4.32855 12.0922L1 13L1.90779 9.67145L10.0779 1.50136Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														</svg>
													</span>
													<span class="custom-font-open-12-400 ml-1">
														Editar
													</span>                                        
												</b-dropdown-item>                                                                       
												<b-dropdown-item @click="setKeyAPIDelete(x.apiKey, x.apiTitle)">
													<span>
														<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M3 4H4.33333H15" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M6.3335 4.00004V2.66671C6.3335 2.31309 6.47397 1.97395 6.72402 1.7239C6.97407 1.47385 7.31321 1.33337 7.66683 1.33337H10.3335C10.6871 1.33337 11.0263 1.47385 11.2763 1.7239C11.5264 1.97395 11.6668 2.31309 11.6668 2.66671V4.00004M13.6668 4.00004V13.3334C13.6668 13.687 13.5264 14.0261 13.2763 14.2762C13.0263 14.5262 12.6871 14.6667 12.3335 14.6667H5.66683C5.31321 14.6667 4.97407 14.5262 4.72402 14.2762C4.47397 14.0261 4.3335 13.687 4.3335 13.3334V4.00004H13.6668Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M7.6665 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M10.3335 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														</svg>
													</span>
													<span class="custom-font-open-12-400 ml-1">
														Excluir chave
													</span>                                        
												</b-dropdown-item>                                                                                                              
											</b-dropdown>
										</div>      
    
									</b-td>                           
								</b-tr>
							</b-tbody>
						</b-table-simple>  
					</div>
				</b-col>   
			</b-row>
			<b-row v-show="rows > perPage" class="pb-5">
				<b-col md="4" class="mt-2">
					<b-row>
						<b-col md="7" class="mt-1">
							<span class="mt-2 lblPagination">Visualizar por página:</span> 
						</b-col>
						<b-col md="4">                                                 
							<b-form-select v-model="selected" :options="perPageOptions" size="sm" @change="setPerPage()"></b-form-select>                
						</b-col>
					</b-row>
				</b-col>
				<b-col class="mr-5">
					<b-row class="d-flex justify-content-end">
						<b-pagination
							v-model="currentPage"
							:total-rows="rows"
							:per-page="perPage"
							pills
							size="sm"
							aria-controls="my-table"
						></b-pagination> 
					</b-row>
				</b-col>
        
			</b-row>           
		</b-col>
	</div>    
</template>

<script>
import Loading from '@/views/Loading'
import WKeyAPI from '@/api/WKeyAPI'
import Vue2Filters from 'vue2-filters'
import WModal from '@/views/WModal'
export default {
	mixins: [Vue2Filters.mixin],
	components: {
		loading: Loading,  
		'keyapi-modal-delete': WModal,             
	},
	name: 'WKeyAPIList',
	data: function () {
		return {
			loading: false,
			filter: '',
			keyApiList: [],   
			originalKeyAPIList: [],
			showModalDelete: false,                   
			perPage: 20,
			selected: 20,
			currentPage: 1,  
			totalPage: 1,  
			paginatedItems: [], 
			perPageOptions: [20, 50, 100, 200, 500],  
			phoneNumberList: [],   
			keyapiName: '',  
			componentDeleteModalKey: 0,                                             
			popoverShow: false,
			userIndex: null,
			btnPersonalized: true,
			btnDefault: false,
		}
	},
	computed: {
		rows () {
			return this.keyApiList.length
		},
		list () {
			const items = this.keyApiList
			return items.slice(
				(this.currentPage - 1) * this.perPage,
				this.currentPage * this.perPage
			)
		},      
	},    
	methods: {             
		create () {
			let obj = {step: 1.1}
			this.$emit('sendMessageData', obj)               
		},          
		getKeyAPIList () {
			this.$store.commit('setShowLoading', true)
			WKeyAPI.getKeyAPIList(this.$route.params.companyId, response => {
				let keyApiList = response.data
				this.$store.commit('setShowLoading', false)
				keyApiList.forEach(element => {
					element.visible = true                        
				})
				this.keyApiList = keyApiList 
				this.originalKeyAPIList = this.keyApiList
			})
		},         
		setPerPage () {
			this.perPage = this.selected
		},
		searchKey () {
			let keyApiList = this.originalKeyAPIList
			this.keyApiList = []            
			keyApiList.forEach( el => {
				if ( el.apiTitle.toLowerCase().includes(this.filter.toLowerCase()) ){
					el.visible = true
					this.keyApiList.push(el)
				} else {
					el.visible = false
				}
			})
		},   
  
		setKeyAPIDelete (keyapiId, name) {  
			this.keyapiId = keyapiId
			this.componentDeleteModalKey ++
			this.showModalDelete = true 
			this.keyapiName = name
		},   
		copy (key) {                
			let element = document.getElementById(key)    
			this.selectText(element)            
			document.execCommand('copy')
			try {
				this.makeToast(true)
			} catch (err){
				alert('Erro ao copiar a chave')
			}         
		},   
		selectText (element) {
			let range
			if (document.selection){
				range = document.body.createTextRange()
				range.moveToElementText(element)
				range.select()
			} else if (window.getSelection){
				range = document.createRange()
				range.selectNode(element)
				window.getSelection().removeAllRanges()
				window.getSelection().addRange(range)
			}
		} ,        
		makeToast (append = false) {
			this.toastCount++
			const msg = 'Chave copiada para area de transferência'
			this.$bvToast.toast(msg, {
				title: 'Token da chave',
				autoHideDelay: 2000,
				appendToast: append,
				toaster: 'b-toaster-bottom-center',
				variant: 'default',
				solid: true
			})
		},            
		removeKeyAPI (remove) {  
			this.showModalDelete =false
			if (remove){
				WKeyAPI.removeApi(this.$route.params.companyId, this.keyapiId, response => {
					if (response){
						this.getKeyAPIList(false)                    
					} else {
						alert('Falha ao excluir a propriedade!')
					}                                       
				})                
			}            
		},   
                  
		timeToDate (time) {
			if (time ==0){
				return '-'
			}
			let t = new Date(Number(time))

			let fHourMinute = t.getHours() + ':'
			if(t.getMinutes()<10){
				fHourMinute+='0' 
			}
			fHourMinute+=t.getMinutes()  

			let d = new Date(Number(time))
			let day = d.getDate()        
			if(day < 10){
				day ='0' + d.getDate() 
			}
			let month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1)
			let sDate  = day  + '/' + month + '/' + d.getFullYear() 
			return sDate + ' ' + fHourMinute
		},    
		editKeyAPI (apiKeyId, name, description ) {
			let objKeyAPI = {
				apiKeyId: apiKeyId,
				keyApiName: name,
				keyApiDescription: description
			}
			localStorage.removeItem('__wpp_developer_obj')
			localStorage.setItem('__wpp_developer_obj', JSON.stringify(objKeyAPI))            
			let obj = {step: 1.2}
			this.$emit('sendMessageData', obj)                
		},
		clearFilter () {
			if(this.filter == ''){
				this.searchKey()
			}
		}               
	},      
	mounted () {
		this.getKeyAPIList()
	}
}
</script>

<style scoped>

u {
    cursor: pointer;
}
th {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #999999 !important;    
}

td {
    margin: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #777777; 
    height: 50px;
    vertical-align: middle !important;
}
.td-keyapi {
    margin-top: 20px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.td-phonenumber {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;  
}
.td-date {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-data {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-email {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}
.td-keyapi {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}

.td-target {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-fail {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-deliver {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.td-readed {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}



.sidebar-right-keyapis {
    display: flex !important;
    width: 100% !important;
    flex-direction: column !important;
    background: white !important;
    padding: 10px;
    margin-right: 50px;
}
.custom-pagination-input {
    width: 50px !important;
}
.custom-pagination-select {
    width: 60px !important;
}

.contatc-list-scrollable {
    height: 63vh;
    width: 100%;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
}
.lblPagination {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px; 
    color: #777777;   
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    min-height: 200px;
    -webkit-overflow-scrolling: touch;
}

.form-control:focus {
    background-color: #F5F5F5;
    box-shadow: none;
}

</style>
