import axios from 'axios'
import i18n from '../plugins/i18n'
import { baseApiUrl, access_token} from '../global'


axios.defaults.headers.common['Authorization'] = `bearer ${access_token}`


export default {
   
	getEmptyPropertyList: (companyId, contactId, callback) => {
		const url = `${baseApiUrl}`+'contact/'+ companyId + '/' + contactId + '/emptyPropertyList'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},
	postPropertyData: (companyId, contactId, propertyName, propertyValue, callback) =>{  
		const params = new URLSearchParams()
		params.append('propertyName', propertyName)
		params.append('propertyValue', propertyValue)
		const url = `${baseApiUrl}`+'contact/'+ companyId + '/' + contactId + '/propertyData'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(true)   
				}
			})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)                
			})       
	},        
    
	postEmail: (companyId, contactId, email, callback) =>{  
		const params = new URLSearchParams()
		params.append('email', email)
		const url = `${baseApiUrl}`+'contact/'+ companyId + '/' + contactId + '/email'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(true)   
				}
			})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)                
			})       
	},      

	postName: (companyId, contactId, name, callback) =>{  
		const params = new URLSearchParams()
		params.append('name', name)
		const url = `${baseApiUrl}`+'contact/'+ companyId + '/' + contactId + '/name'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(true)   
				}
			})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)                
			})       
	},   

	postErasePropertyData: (companyId, contactId, propertyName, callback) => {  
		const params = new URLSearchParams()
		params.append('propertyName', propertyName)
		const url = `${baseApiUrl}`+'contact/'+ companyId + '/' + contactId + '/erasePropertyData'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(true)    
				}
			})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)                
			})       
	},        
                
	postSearchContacts (companyId, search, callback) {  
		const params = new URLSearchParams()
		params.append('search', search)
		let url = `${baseApiUrl}` + 'contact/'+ companyId + '/searchContacts'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},         
	postContactWaIdOrCreate (companyId, phonenumberId, countryCode, phonenumber, callback) {
		const params = new URLSearchParams()
		params.append('countryNumber', countryCode)
		params.append('phonenumber', phonenumber)        
		let url = `${baseApiUrl}` + 'contact/'+ companyId + '/' + phonenumberId + '/contactWaIdOrCreate'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(response.data) 
				}
			})
			.catch(function (error) {
				callback(false)
				console.log(i18n.t('Message.error') + error)                
			})    
	},  
	postContactWaIdOrCreateWithName (companyId, phonenumberId, countryCode, phonenumber, name, callback) {
		const params = new URLSearchParams()
		params.append('countryNumber', countryCode)
		params.append('phonenumber', phonenumber)        
		params.append('name', name)   
		let url = `${baseApiUrl}` + 'contact/'+ companyId + '/' + phonenumberId + '/contactWaIdOrCreateWithName'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},        
    
	postContactWaIdOrRegister (companyId, countryCode, phonenumber, name, callback) {
		const params = new URLSearchParams()
		params.append('countryNumber', countryCode)
		params.append('phonenumber', phonenumber)  
		params.append('name', name)          
		let url = `${baseApiUrl}` + 'contact/'+ companyId + '/contactWaIdOrRegister'
		axios.post(url, params)
			.then(response => {  
				if (response){                              
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)   
				if (error.response.status == 404){
					alert('Número inválido!')                      
				}             
			})    
	},  
        

	getContactByWaId: (companyId, waId, callback) => {
		const url = `${baseApiUrl}`+'contact/'+ companyId + '/' + waId + '/contactByWaId'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},    
	postFindContactByPhonenumber (companyId, countryCode, phonenumber, callback) {
		const params = new URLSearchParams()
		params.append('countryNumber', countryCode)
		params.append('phonenumber', phonenumber)        
		let url = `${baseApiUrl}` + 'contact/'+ companyId + '/findContactByPhonenumber'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},  
	getContacts: (companyId, callback) => {
		const url = `${baseApiUrl}`+'contact/'+ companyId + '/list'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},          
	getContactList: (companyId, callback) => {
		const url = `${baseApiUrl}`+'contact/'+ companyId + '/list'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},   
	postBlockContact (companyId, waId, callback) {  
		const params = new URLSearchParams()
		params.append('waId', waId)
		let url = `${baseApiUrl}` + 'contact/'+ companyId + '/blockContact'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(true)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},       
	postUnblockContact (companyId, waId, callback) {  
		const params = new URLSearchParams()
		params.append('waId', waId)
		let url = `${baseApiUrl}` + 'contact/'+ companyId + '/unblockContact'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(true)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},      
	deleteContact (companyId, waId, callback) {  
		let url = `${baseApiUrl}` + 'contact/'+ companyId + '/'+ waId +  '/deleteContact'
		axios.delete(url)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(true)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},    
	postAddress (companyId, contactId, address, callback) {
		const params = new URLSearchParams()
		params.append('zipCode', address.zipCode)        
		params.append('neighborhood', address.neighborhood)   
		params.append('complement', address.complement)
		params.append('street', address.street)        
		params.append('city', address.city)   
		params.append('state', address.state)
		params.append('number', address.number)        
		params.append('country', address.country)                   
		let url = `${baseApiUrl}` + 'contact/'+ companyId + '/' + contactId + '/address'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},    
	getContactDetail: (companyId, waId, callback) => {
		const url = `${baseApiUrl}`+'contact/'+ companyId + '/' + waId + '/contactDetail'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},       
	getLoadMoreHistory: (companyId, contactId, time, callback) => {
		const url = `${baseApiUrl}`+'contact/'+ companyId + '/' + contactId + '/' + time + '/loadMoreHistory'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	}, 
	getContactPhonenumberLeadDescription: (companyId, contactId, callback) => {
		const url = `${baseApiUrl}`+'contact/'+ companyId + '/' + contactId + '/contactPhonenumberLeadDescription'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},                 
}
