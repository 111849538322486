<template>
	<div>
		<b-row class="mb-2 mt-3">    
			<b-col md="1" class="ml-3" v-show="step ==11">
				<span class="icon-clickable" @click="goBack">
					<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="40" height="40" rx="8" fill="#F5F5F5"/>
						<path d="M18.2622 18.7135L23.9969 13.0243C24.6018 12.4292 24.6097 11.4692 24.0146 10.8643C23.4433 10.2836 22.4594 10.2756 21.8547 10.8467L15.0549 17.6312C14.9825 17.6786 14.9339 17.7502 14.9095 17.798C14.8615 17.7977 14.8611 17.8457 14.8611 17.8457C14.8128 17.8933 14.788 17.9891 14.7394 18.0607C14.7392 18.0847 14.7152 18.0845 14.715 18.1085C14.6904 18.1803 14.69 18.2283 14.6654 18.3001C14.6652 18.3241 14.617 18.3477 14.6166 18.3957C14.6162 18.4437 14.6157 18.5157 14.6153 18.5637C14.6151 18.5877 14.5905 18.6595 14.5903 18.6835C14.5897 18.7555 14.6135 18.7797 14.6131 18.8277C14.6127 18.8757 14.6123 18.9237 14.6117 18.9956C14.6115 19.0196 14.6593 19.044 14.6587 19.116C14.6825 19.1402 14.682 19.2122 14.7056 19.2604C14.7054 19.2844 14.729 19.3326 14.7768 19.357C14.8002 19.4292 14.7998 19.4772 14.8472 19.5496C14.8472 19.5496 14.895 19.574 14.9188 19.5982C14.9422 19.6704 14.9658 19.7186 15.0376 19.7432L21.7254 26.6381C22.035 26.9287 22.4178 27.0758 22.8258 27.0792C23.2098 27.0823 23.571 26.9412 23.8853 26.6558C24.4902 26.0607 24.4979 25.1248 23.903 24.4959L18.2622 18.7135Z" fill="#414141"/>
					</svg>
				</span>
			</b-col>                     
			<b-col>
				<b-row class="ml-3">  
					<h3 v-if="step==11" class="lbl-title">Nova Resposta Rápida</h3>   
					<h3 v-else class="lbl-title">Editar departamentos</h3>                           
				</b-row>
			</b-col>
		</b-row>  
		<div class="ml-5">
			<b-row>
				<div class="w-75">
					<hr>
				</div>
			</b-row>  
		</div>
		<b-row class="ml-5">
			<div>
				<b-form-checkbox v-model="checked" name="check-button" switch>
					Todos os Departamentos
				</b-form-checkbox>
			</div>               
		</b-row>
		<div class="ml-5 w-75" v-if="checked == false">
			<b-row>
				<b-col md="10" class="mb-2">
					<div class="search" style="margin-left: -15px;">  
						<b-input-group>
							<b-form-input 
								type="search"                     
								placeholder="Busque pelo nome do departamento"
								v-model="filter"
								@keyup="searchDepartment"
								class="mt-1"
								@update="clearFilter">
							</b-form-input>
							<b-input-group-prepend is-text class="mt-1">
								<b-icon icon="search"></b-icon>
							</b-input-group-prepend>                    
						</b-input-group>                                                                                       
					</div>
				</b-col>
			</b-row>
			<div class="quickreply-department-scrollable scrollbar">
				<div v-for="x in departments" :key="x.departmentId" v-show="x.visible == true">
					<label :for="x.departmentId" style="width: 100%;margin: 8px;" class="icon-clickable">                            
						<b-form-checkbox
							:id="x.departmentId"
							checked="1"
							v-model="x.checked"
							unchecked-value="0"
							size="lg"
						>
							<b-row class="ml-0 mt-1">  
								<span class="ml-2 mr-2">
									<div v-if="x.departmentName != undefined" class="avatar" :style="x.avatar">{{ x.departmentName.substring(0,2).toUpperCase() }}</div>
									<div v-else class="avatar"></div>
								</span>         
								<span class="td-data ml-1" style="margin-top:3px">{{x.departmentName}}</span>   
							</b-row>                                
						</b-form-checkbox>                                           
					</label>               
				</div> 
			</div>                                                                                                                          
		</div>
		<div v-if="step == 11">
			<b-row :style="checked ? 'margin-top: 240px;' : 'margin-top: 0;'">
				<div class="w-75 ml-5 mt-2">
					<b-button variant="outline-primary" class="mr-2"
						@click="cancel()">Cancelar
					</b-button>   
					<b-button variant="primary" class="float-right mr-5"
						@click="next">Finalizar           
					</b-button>  
				</div>                   
			</b-row> 
		</div>
		<div v-else>
			<b-row :style="checked ? 'margin-top: 240px;' : 'margin-top: 0;'">
				<div class="w-75 ml-5 mt-2">
					<b-button variant="outline-primary" class="mr-2"
						@click="cancel()">Cancelar
					</b-button>  
					<b-button variant="primary" class="float-right mr-5"
						@click="next()">Salvar           
					</b-button>  
				</div>                   
			</b-row> 
		</div>                  
	</div>                                          
</template>

<script>
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import WQuickReplyAPI from '@/api/WQuickReplyAPI'

import Vue2Filters from 'vue2-filters'
import { avatarUrl} from '@/global'
export default {
	name: 'WQuickReplyDepartments',
	mixins: [Vue2Filters.mixin], 
	props: [
		'step'
	],   
	data () {
		return { 
			addAction: false,
			btnDisable: true,
			checked: false,
			filter: '',
			currentDepartmentIndex: null,
			avtUrl: avatarUrl,            
			departments: [],
			quickreplyId: '',
		}
	},    
	methods: {
		add () {
			this.addAction = true
		},
		setType (type) {
			this.btnDisable = false
			this.automationType = type
		},
		getMessageData (id) {
			id
			this.btnDisable = false
		},
		getDepartmentList () {
			WDepartmentsAPI.getDepartmentsOfUser(this.$route.params.companyId, response => {
				let departments = response.data
				if (this.step == 11.1 && this.checked == false){
					WQuickReplyAPI.getDepartmentsOfQuickReply(this.$route.params.companyId, this.quickreplyId, response => {
						let departmentIdList = response.data
						departments.forEach(dep => {
							dep.checked = false
							for (const key in departmentIdList){                                
								if (dep.departmentId === key){                                    
									dep.checked = true
								}                             
							}
							dep.visible =  true
						})
						this.departments = departments    
					})
				}  else {
					departments.forEach(dep => {
						dep.visible =  true
						dep.checked = 0
					})           
					this.departments = departments         
				}
			})
		},          
            
		searchDepartment () {
			this.departments.forEach( el => {
				if ( el.departmentName.toLowerCase().includes(this.filter.toLowerCase()) ){
					el.visible = true
				} else {
					el.visible = false
				}
			})        
		},            
		next () {     
			let departmentIdList = ''
			let departmentAll = 0
			if (this.checked == true){
				departmentAll = 1               
			} else {
				this.departments.forEach(el => {
					if (el.checked == 1){
						departmentIdList += el.departmentId + ','
					}                   
				}) 
				if (departmentIdList == ''){
					alert('Pelo menos um item deve ser selecionado!')
				}       
				departmentIdList = departmentIdList.substring(0, departmentIdList.length -1)                           
			}
			if(departmentIdList != '' || departmentAll == 1){
				WQuickReplyAPI.postDepartment(this.$route.params.companyId, this.quickreplyId, departmentAll, departmentIdList, response => {
					if (response){ 
						let obj = {step: 8}
						this.$emit('sendMessageData', obj)                                                          
					} else {
						this.show =true 
						alert('Falha ao adicionar template ao departamento!')
					}
				}) 
			}
		},    
		cancel () {      
			let obj = {step: 8}
			this.$emit('sendMessageData', obj)             
		},          
		goBack () {      
			let obj = {step: 9}
			this.$emit('sendMessageData', obj)             
		},
		clearFilter () {
			if(this.filter == ''){
				this.searchDepartment()
			}
		}                                      
	},
	mounted () {  
		let objQuickReply = JSON.parse(localStorage.getItem('__wpp_automation_quickreply_obj'))
		if (objQuickReply != null){
			this.quickreplyId = objQuickReply.quickreplyId
			if (this.step == 11.1){
				this.checked = objQuickReply.checked
			}
		}            
		this.getDepartmentList()                               
	}, 
}
</script>

<style scoped>
    .search input { 
        border: none !important;
        width: 50% !important;
    }    
    .btn-light {
        color: #212529;
        background-color:  #F5F5F5;
        border-color:  #F5F5F5;
    }  
    .mylabel {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #777777;
        cursor: pointer; 
    }
    .tempTitle {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: #777777;    
    }  
    .tempSubTitle {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #999999;            
    }
    .bcn-card-custom-type {
        width: 150px;
        height: 150px;
        max-width: 150px;
        max-height: 150px;     
        border-radius: 16px;    
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;  
        cursor: pointer;
    }

    .bcn-box-shadow {
        background: #FFFFFF;
        box-shadow: 0px 1px 4px 2px rgba(119, 119, 119, 0.2);
        border-radius: 16px;
    }  

    .bcn-box-shadow-check {
        background: #FFFFFF;
        border: 1px solid var(--primary);
        box-sizing: border-box;
        box-shadow: 0px 0px 8px rgba(187, 26, 94, 0.3);
        border-radius: 16px;
    }    
    .quickreply-department-scrollable {
        height: 42vh;
        width: 100%;
        background: #fff;
        overflow-y: auto;
        overflow-x: hidden;
    }
   
</style>

 
