<script src="https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OpusMediaRecorder.umd.js"></script>
<script src="https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/encoderWorker.umd.js"></script>     
<template>
	<b-row class="ml-2 mt-3" v-if="this.$mq == 'xs'">
		<b-col xs="auto">
			<img src="@/assets/audio_close.png" height="30" class="icon-clickable" @click="closeAudio">
		</b-col>
		<b-col xs="auto">
			<div class="audio-minutes">{{minutes}}:{{seconds}}</div>   
			<div class="audio-seconds"><span class="dot blinking ml-2"></span></div>                    
		</b-col>
		<b-col xs="auto" class="ml-3">
			<span @click="endRecord"><img src="@/assets/audio_stop.png" height="30" class="icon-clickable"></span>
		</b-col>
	</b-row>
	<b-row v-else class="ml-2 mt-3">
		<b-col md="3">
			<img src="@/assets/audio_close.png" height="30" class="icon-clickable" @click="closeAudio">
		</b-col>
		<b-col md="3">
			<div class="audio-minutes">{{minutes}}:{{seconds}}</div>   
			<div class="audio-seconds"><span class="dot blinking ml-2"></span></div>                    
		</b-col>
		<b-col md="3" class="ml-3">
			<span @click="endRecord"><img src="@/assets/audio_stop.png" height="30" class="icon-clickable"></span>
		</b-col>
	</b-row>
</template>
<script>   
const workerOptions = {
OggOpusEncoderWasmPath: 'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OggOpusEncoder.wasm',
WebMOpusEncoderWasmPath: 'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/WebMOpusEncoder.wasm'
};    
try {
    window.MediaRecorder =  OpusMediaRecorder
} catch (error) {
    console.log(error)
}

let recorder;
  export default {
    name: 'WAudioRecord',
    data () {
      return {
          minutes: '00',
          seconds: '00',
          totalSeconds: 0,
          timer: null,
          timeout: null,
          mediaRecorder: null,
          mediaStream: null,
          file: null
      }
    },      
    methods: {
        startRecord() {    
                             
            navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {  
                let options = { mimeType: 'audio/ogg' };
                // Start recording                
                recorder = new MediaRecorder(stream, options, workerOptions);
                recorder.start();                
                recorder.addEventListener('dataavailable', (event) => {
                let obj = {file: event.data, mediaType: 'voice', msg: '', visible: false}
                this.$emit('sendMessageData', obj)
                })
            }).catch((error) => {
                alert('Falha ao ligar o microfone! ' + error )
                let obj = {file: null, mediaType: 'voice', msg: '', visible: false}
                this.$emit('sendMessageData', obj)                             
            })
        },  
        endRecord() {
            this.totalSeconds = 0
            this.minutes = '00'
            this.seconds = '00'           
            recorder.stop() //End recording
            setTimeout(() => {
                recorder.stream.getTracks().forEach(i => i.stop());
            }, 300)            
            
            clearInterval(this.timer)
        },   
        closeAudio () {
            recorder.stream.getTracks().forEach(i => i.stop());
            this.totalSeconds = 0
            this.minutes = '00'
            this.seconds = '00'
            clearInterval(this.timer)
            let obj = {file: null, mediaType: 'voice', msg: '', visible: false}
            this.$emit('sendMessageData', obj)
        },         
        setTime() {
            this.totalSeconds++;
            this.seconds = this.pad(this.totalSeconds % 60);
            this.minutes = this.pad(parseInt(this.totalSeconds / 60));
        },
        pad(val) {
            var valString = val + "";
            if (valString.length < 2) {
                return "0" + valString;
            } else {
                return valString;
            }
        },
        startTimer(){
            this.timer = setInterval(() => {
                this.setTime()
            }, 1000)            
        },
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },  
    mounted () {
        this.startTimer()
        setTimeout(() => {
            this.startRecord()
        }, 1000);
    },    

  }
</script>

<style>
    .audio-minutes {
        display: flex;
        position: absolute;
    }
    .audio-seconds {
        display: column;
        margin-top: 25px;
    }    
    .dot {
        height: 20px;
        width: 20px;
        background-color:red;
        border-radius: 50%;
        display: inline-block;
    }
    .blinking {
        -webkit-animation: 1s blink ease infinite;
        -moz-animation: 1s blink ease infinite;
        -ms-animation: 1s blink ease infinite;
        -o-animation: 1s blink ease infinite;
        animation: 1s blink ease infinite;    
    }

    @keyframes "blink" {
        from, to {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
    }

    @-moz-keyframes blink {
        from, to {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
    }

    @-webkit-keyframes "blink" {
        from, to {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
    }

    @-ms-keyframes "blink" {
        from, to {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
    }

    @-o-keyframes "blink" {
        from, to {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
    }
</style>