import axios from 'axios'
import { baseApiUrl, access_token} from '../global'
import i18n from '@/plugins/i18n'

axios.defaults.headers.common['Authorization'] = `bearer ${access_token}`


export default {
   
	getFacebookListApi: (companyId, callback) => {
		const url = `${baseApiUrl}`+'facebook/'+ companyId +'/listFacebook'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},
	postCreateFacebookLink: (companyId,accessToken,graphDomain,fbUserId, callback) => {
		const url = `${baseApiUrl}`+'facebook/'+ companyId +'/createFacebookLink'
		const params = new URLSearchParams()
		params.append('accessToken', accessToken)
		params.append('graphDomain', graphDomain) 
		params.append('fbUserId', fbUserId)
		axios.post(url,params).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},   
	postUpdateFacebookLink: (companyId,facebookId,accessToken, callback) => {
		const url = `${baseApiUrl}`+'facebook/'+ companyId+'/' +facebookId+'/updateFacebookLink'
		const params = new URLSearchParams()
		params.append('accessToken', accessToken)
		axios.post(url,params).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	}, 
	postDeleteFacebookLink: (companyId,facebookId,safeMode, callback) => {
		const url = `${baseApiUrl}`+'facebook/'+ companyId +'/' +facebookId+'/deleteFacebookLink'
		const params = new URLSearchParams()
		params.append('safeMode', safeMode)
		axios.post(url,params).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	}, 
	postCanDeleteFacebookLink: (companyId,facebookId, callback) => {
		const url = `${baseApiUrl}`+'facebook/'+ companyId+'/' +facebookId +'/canDeleteFacebookLink'
		axios.post(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	}, 
	getPixelListApi: (companyId, callback) => {
		const url = `${baseApiUrl}`+'facebook/'+ companyId +'/listPixel'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	}, 
	getListPixelEvents: (companyId,pixelId, callback) => {
		const url = `${baseApiUrl}`+'facebook/'+ companyId+'/' +pixelId+ +'/listPixelEvents'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	}, 
	postCreateApi: (companyId,pixelTitle,pixelId,facebookId, callback) => {
		const url = `${baseApiUrl}`+'facebook/'+ companyId +'/createPixel'
		const params = new URLSearchParams()
		params.append('pixelTitle', pixelTitle)
		params.append('pixelId', pixelId)
		params.append('facebookId', facebookId)
		axios.post(url,params).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	}, 
	postTest: (companyId,pixelId,eventTestValue, callback) => {
		const url = `${baseApiUrl}`+'facebook/'+ companyId+'/' +pixelId +'/test'
		const params = new URLSearchParams()
		params.append('eventTestValue', eventTestValue)
		axios.post(url,params).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	}, 
	removeApi: (companyId,pixelId, callback) => {
		const url = `${baseApiUrl}`+'facebook/'+ companyId+'/' +pixelId +'/remove'
		axios.delete(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	}, 
	postDepartmentAgents: (companyId,pixelId,eventList, callback) => {
		const url = `${baseApiUrl}`+'facebook/'+ companyId+'/' +pixelId +'/eventList'
		const params = new URLSearchParams()
		params.append('eventList', eventList)
		axios.post(url,params).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	}, 
                        
}
