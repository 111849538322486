<template>
	<div>
		<b-modal 
			v-model="show"
			:title="title"
			centered>
			<b-container fluid class="ml-2" >
 
				<b-row v-show="subTitle != ''">
					<h5 class="modal-title">{{subTitle}}</h5>
				</b-row>                    
				<div v-show="description != ''" class="mt-3" style="text-align:center;">
					<span class="modal-description"><b>{{description}}</b></span>
				</div>   
				<b-row v-show="descriptionHtml != ''" class="mt-3" >
					<span class="custom-font-open-18-400" v-html="descriptionHtml" style="text-align:center;"></span>
				</b-row>  
				<b-row v-show="description1 != ''" class="mt-3">
					<span class="modal-description"><b>{{description1}}</b></span>
				</b-row>        
				<b-row v-show="value != ''" >
					<label for="input" class="custom-font-open-14-gray">Valor:</label>  
					<b-form-input id="input" type="text" 
						v-model="inputValue" class="custom-font-open-14-gray">
					</b-form-input>                  
				</b-row>                      
			</b-container>         
			<template #modal-footer>
				<b-col>
					<b-row v-if="button3 != null" class="justify-content-center">
						
						<b-button variant="primary" class="float-right"
							@click="btnAction()">{{button3}}            
						</b-button> 	                    
					</b-row>  
					<div v-else class="w-100">
						<b-button variant="outline-primary" class="mr-2"
							@click="cancel()">{{button1}}
						</b-button>   
						<b-button variant="primary" class="float-right"
							@click="btnAction()">{{button2}}            
						</b-button>                     
					</div>  
				</b-col>
			</template>
		</b-modal>          
	</div>
                                       
</template>

<script>
export default {
	name: 'WModal',
	props: [
		'title',
		'subTitle',
		'description',
		'description1',
		'value',
		'placeholder',
		'button1',
		'button2',
		'button3',
		'start',
		'descriptionHtml',
	],   
	data () {
		return { 
			show: false,
			inputValue: ''
		}
	},    
	methods: {
		cancel () {
			this.show = false
		},
		btnAction () {                       
			this.show = false 
			this.$emit('sendModalData', true) 
		},                          
	},
	mounted () {
		this.inputValue = this.value
		this.show = this.start   
	}, 
}
</script>

<style scoped>
.modal-title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #777777;
}
.modal-description {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #777777;
}
</style>

 
