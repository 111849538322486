


import axios from 'axios'
import i18n from '../plugins/i18n'
import { baseApiUrl, access_token} from '../global'


axios.defaults.headers.common['Authorization'] = `bearer ${access_token}`


export default {
   
	getListByCompanyId: (companyId, callback) => {
		const url = `${baseApiUrl}`+'label/'+ companyId +'/listByCompanyId'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},    
    
    
	getLabel: (companyId, labelId, callback) => {
		const url = `${baseApiUrl}`+'label/'+ companyId + '/' + labelId +'/label'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},   

	postCreate (companyId, text, detail, callback) {  
		const params = new URLSearchParams()
		params.append('text', text)
		params.append('detail', detail)
		let url = `${baseApiUrl}` + 'label/'+ companyId + '/create'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},    
	postLink (companyId, messageId, labelId, callback) {  
		const params = new URLSearchParams()
		params.append('messageId', messageId)
		params.append('labelId', labelId)
		let url = `${baseApiUrl}` + 'label/'+ companyId + '/link'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(true)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},     
	postUnlink (companyId, messageId, labelId, callback) {  
		const params = new URLSearchParams()
		params.append('messageId', messageId)
		params.append('labelId', labelId)
		let url = `${baseApiUrl}` + 'label/'+ companyId + '/unlink'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(response.data)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},    
	getMessageLabels: (companyId, messageId, callback) => {
		const url = `${baseApiUrl}`+'label/'+ companyId + '/' + messageId +'/messageLabels'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},   
	getListByCompanyIdAndMessage: (companyId, messageId, callback) => {
		const url = `${baseApiUrl}`+'label/'+ companyId + '/' + messageId +'/listByCompanyIdAndMessage'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},       
	postPersist (companyId, messageId, labelSelectedList, callback) {  
		const params = new URLSearchParams()
		params.append('labelSelectedList', labelSelectedList)
		let url = `${baseApiUrl}` + 'label/'+ companyId +  '/' + messageId +'/persist'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(true)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},    
	postDescription (companyId, labelId, description,detail, callback) {  
		const params = new URLSearchParams()
		params.append('description', description)
		params.append('detail', detail)
		let url = `${baseApiUrl}` + 'label/'+ companyId +  '/' + labelId +'/update'
		axios.post(url, params)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(true)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},      
	deleteLabel (companyId, labelId, callback) {            
		let url = `${baseApiUrl}` + 'label/'+ companyId + '/' + labelId + '/deleteLabel'
		axios.delete(url)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},  
	postDepartment (companyId, labelId, departmentAll, departmentIdList, callback) {  
		const params = new URLSearchParams()
		params.append('departmentAll', departmentAll)
		params.append('departmentIdList', departmentIdList)
		let url = `${baseApiUrl}` + 'label/'+ companyId +  '/' + labelId +'/department'
		axios.post(url, params)
			.then(response => {
				response
				callback(true)       
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},
	getListByCompanyIdAndDepartmentId: (companyId, departmentId, callback) => {
		const url = `${baseApiUrl}`+'label/'+ companyId + '/' + departmentId +'/listByCompanyIdAndDepartmentId'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},
	getListActiveByCompanyId: (companyId, callback) => {
		const url = `${baseApiUrl}`+'label/'+ companyId +'/listActiveByCompanyId'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},
	getListArchivedByCompanyId: (companyId, callback) => {
		const url = `${baseApiUrl}`+'label/'+ companyId +'/listArchivedByCompanyId'
		axios.get(url).then(response => {
			if (response){
				callback(response.data)
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},
	postArchivedLabel (companyId, labelId, callback) {  
		let url = `${baseApiUrl}` + 'label/'+ companyId +  '/' + labelId +'/archivedLabel'
		axios.post(url)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(true)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},
	postUnarchivedLabel (companyId, labelId, callback) {  
		let url = `${baseApiUrl}` + 'label/'+ companyId +  '/' + labelId +'/unarchivedLabel'
		axios.post(url)
			.then(response => {
				if (response){                
					console.log(i18n.t('Message.success')) 
					callback(true)       
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})       
	},     
}


    

    

    

    
