import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import WPhoneNumberAPI from '@/api/WPhoneNumberAPI'
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import WAttendantsAPI from '@/api/WAttendantsAPI'

Vue.use(Vuex)
Vue.config.devtools = true

export default new Vuex.Store({
	state: {
		user: null,
		companyId: undefined,
		showPreview: false,
		showSideBarMenu: false,      
		loading: false,
		phonenumberList: undefined,
		dashboardStep:{currentStep: 1, currentMenuItem: 1, phoneNumber:undefined},
		departmentList:undefined,
		agentList: [],   
		phoneNumberId: undefined,
		contactId: undefined,
		waId:undefined,
		faceBookStatus:undefined,
		flowHeaderObj: {showFlowHeader: false, flowTitle: 'Título do Fluxo (e.g. Suporte Becon)'},
		compressValue: undefined,
		iconMediaId:undefined,
		logoMediaId:undefined,
		contactOpenId:undefined,
		fileQueue:[],
		isUserOnline:true,
		mobileClass:false
	},
	mutations: {
		setMobileClass (state, status) {
			state.mobileClass = status
		},
		setIsUserOnline (state, status) {
			state.isUserOnline = status
		},
		setFileQueue (state, file) {
			state.fileQueue = state.fileQueue.concat(file)
		},
		shiftFileQueue (state) {
			state.fileQueue.shift()
		},
		setContactOpenId (state, id) {
			state.contactOpenId = id
		},
		setFaceBookStatus (state, status) {
			state.faceBookStatus = status
		},
		setWaId (state, waId) {
			state.waId = waId
		},
		setCompressValue (state, waId) {
			state.compressValue = waId
		},
		setContactId (state, contactId) {
			state.contactId = contactId
		},
		setPhoneNumberId (state, phoneNumberId) {
			state.phoneNumberId = phoneNumberId
		}, 
		setUser (state, user) {
			state.user = user
			if(user){
				axios.defaults.headers.common['Authorization'] = `bearer ${user.access_token}`
			}           
		},

		setShowPreview (state, preview) {
			state.showPreview = preview
		},
		saveStateSideBarMenu (state) {
			localStorage.setItem('__wpp_show_side_bar_menu', true)            
			state.showSideBarMenu = true
		},        
		setShowSideBarMenu (state) {
			if (localStorage.getItem('__wpp_show_side_bar_menu')){
				state.showSideBarMenu = true
			} else {
				state.showSideBarMenu = false 
			}           
		},        
		setCompanyId (state, companyId) {
			state.companyId = companyId
			localStorage.setItem('__company_default', companyId) 
		},    
		setShowLoading (state, show) {
			state.loading = show
		},  
		setPhoneNumberList (state, list) {
			state.phonenumberList = list
		},
		setDepartmentList (state, list) {
			state.departmentList = list
		},
		setAgentList (state, list) {
			state.agentList = list
		},
		setDashboardStep (state, phoneNumber) {
			state.dashboardStep.phoneNumber = phoneNumber
		},
		setFlowHeaderObj (state) {				
			state.flowHeaderObj = JSON.parse(localStorage.getItem('__wpp_flow_header_obj'))			
		}, 	
		setIconMediaId (state, iconMediaId) {
			state.iconMediaId = iconMediaId
		},
		setLogoMediaId (state, logoMediaId) {
			state.logoMediaId = logoMediaId
		},						          
	},
	getters:{
		getMobileClass (state) {
			return state.mobileClass
		},
		getIsUserOnline (state) {
			return state.isUserOnline
		},
		getFileQueue (state) {
			return state.fileQueue
		},
		getContactOpenId (state) {
			return state.contactOpenId
		},
		getCompressValue (state) {
			return state.compressValue
		},
		getFaceBookStatus (state) {
			return state.faceBookStatus
		},
		getWaId (state) {
			return state.waId
		},
		getContactId (state) {
			return state.contactId
		},
		getPhoneNumberId (state) {
			return state.phoneNumberId
		},
		getPhoneNumberList (state) {
			return state.phonenumberList
		},
		getDepartmentList (state) {
			return state.departmentList
		},
		getAgentList (state) {
			return state.agentList
		},
		getDashboardStep (state) {
			return state.dashboardStep
		},
		getFlowHeaderObj (state) {
			return state.flowHeaderObj	
		},		
		getIconMediaId (state) {
			return state.iconMediaId
		},
		getLogoMediaId (state) {
			return state.logoMediaId
		},			
	},
	actions:{
		getPhoneNumberList: context =>{
			return new Promise((resolve) =>{
				WPhoneNumberAPI.getList(context.state.companyId, response =>{
					context.commit('setPhoneNumberList', response.data)
					resolve(response.data)
				})
			})
		},
		getDepartmentList: context =>{
			return new Promise((resolve) =>{
				WDepartmentsAPI.getDepartmentsOfCompany(context.state.companyId, response =>{
					context.commit('setDepartmentList', response.data)
					resolve(response.data)
				})
			})
		},
		getAgentList: context =>{
			return new Promise((resolve) =>{
				WAttendantsAPI.getAgentsList(context.state.companyId, response =>{
					context.commit('setAgentList', response.data)
					resolve(response.data)
				})
			})
		},
	
	}
})