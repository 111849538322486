<template>
	<div>
		<b-col md="8">
			<div v-if="step == 5.1">
				<b-row class="ml-3 mt-3">  
					<h3 class="lbl-title">Criando propriedade</h3>                                              
				</b-row>
				<b-row class="ml-3">
					<span class="custom-font-open-14-gray-normal">
						Essa propriedade ficará disponível para ser adicionada aos contatos, 
						o valor de cada propriedade será preenchido posteriormente em cada contato
					</span>                       
				</b-row>  
			</div>
			<div v-else>
				<b-row class="ml-3 mt-3">  
					<h3 class="lbl-title">Editar descrição</h3>                                              
				</b-row>
				<b-row class="ml-3">
					<span class="custom-font-open-14-gray-normal">
						Como essa propriedade pode estar sendo usada por contatos e templates
						não é possível alterar o nome da mesma, apenas suas descrição.
					</span>                       
				</b-row>  
			</div>
			<div class="w-100">
				<hr>
			</div>          
			<b-row class="ml-3 mt-3" v-show="step == 5.1">
				<label for="inputName">Nome personalizado</label>
				<b-form-input
					id="inputName"
					v-model="name"
					placeholder="Informe um nome"
				></b-form-input>
			</b-row>
			<b-row class="ml-3 mt-3">
				<label for="textarea">Descrição</label>
				<b-form-textarea
					id="textarea"
					v-model="description"
					placeholder="Informe uma descrição"
					rows="5"
				></b-form-textarea>         
			</b-row> 
			<b-row class="mt-3">
				<b-col>
					<div class="d-flex justify-content-center">
						<b-button variant="outline-primary" class="mr-2"
							@click="cancel()">Cancelar
						</b-button>                      
						<b-button variant="primary" 
							@click="save()" :disabled="btnDisable" v-if="step == 5.1">Salvar configuração
						</b-button>      
						<b-button variant="primary" 
							@click="save()" :disabled="btnDisable" v-else>Salvar alteração
						</b-button>                                               
					</div>
				</b-col>            
			</b-row>          
		</b-col> 
	</div>
</template>

<script>
import WPropertiesAPI from '@/api/WPropertiesAPI'
export default {
	name: 'WProperty',
	props: [
		'step'
	],
	data () {
		return {
			propertyId: '',            
			name: '',
			description: ''
		}
	},
	methods: {
		save () {
			const regex = /[^a-zA-Z 0-9áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+/g
			if (this.step == 5.1){
				if (this.name == '' || this.name == undefined || regex.test(this.name)){
					alert('Campo nome invalido!')
					return
				}                        
			} 
			if (this.description == '' || this.description == undefined || regex.test(this.description)){
				alert('Campo descrição invalido!')
				return
			}   
			if (this.step == 5.1){
				WPropertiesAPI.postCreate(this.$route.params.companyId, this.name, this.description, response => {
					if (response){  
						let obj = {step: 5}
						this.$emit('sendMessageData', obj)                                                           
					} else {
						alert('Falha ao criar propriedade!')
					}
				})                        
			} else {
				WPropertiesAPI.postUpdate(this.$route.params.companyId, this.propertyId, this.description, response => {
					if (response){  
						localStorage.removeItem('__wpp_settings_obj') 
						let obj = {step: 5}
						this.$emit('sendMessageData', obj)                                                           
					} else {
						alert('Falha ao criar propriedade!')
					}
				})                        
			}                               
		},   
		cancel () {
			let obj = {step: 5}
			this.$emit('sendMessageData', obj)                    
		},      		         
	},
	mounted () {
		if (this.step == 5.2){
			let objProperty = JSON.parse(localStorage.getItem('__wpp_settings_obj'))
			if (objProperty != null){
				this.propertyId = objProperty.propertyId 
				this.description = objProperty.propertyDescription
			}         
		}        
	},         
}
</script>