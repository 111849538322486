import axios from 'axios'
import i18n from '../plugins/i18n'
import { baseApiUrl, access_token} from '../global'


axios.defaults.headers.common['Authorization'] = `bearer ${access_token}`


export default {
	getDepartmentsOfCompany: (companyId, callback) => {
		const url = `${baseApiUrl}`+'department/'+ companyId + '/departmentsOfCompany'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},      
	getDepartmentsOfUser: (companyId, callback) => {
		const url = `${baseApiUrl}`+'department/'+ companyId + '/departmentsOfUser'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},      
	getUsersOfDepartment: (companyId, departmentId, callback) => {
		const url = `${baseApiUrl}`+'department/'+ companyId + '/' + departmentId + '/usersOfDeparment'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},   
	getInboxRules: (companyId, callback) => {
		const url = `${baseApiUrl}`+'department/'+ companyId + '/inboxRules'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},       
     
	getDeparmentDetail: (companyId, departmentId, callback) => {
		const url = `${baseApiUrl}`+'department/'+ companyId + '/' + departmentId + '/detail'
		axios.get(url).then(response => {
			if (response){
				if (callback){
					callback(response.data)
				}                        
			}        
		})
			.catch(function (error) {
				alert(i18n.t('Message.error') + error)
			})  
	},        
	postCreateDraft (companyId, departmentName, phonenumberId,callback) {
		const params = new URLSearchParams()
		params.append('departmentName',departmentName)
		params.append('phonenumberId', phonenumberId)                                       
		let url = `${baseApiUrl}` + 'department/'+ companyId + '/createDraft'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},   
	postDepartmentName (companyId, departmentId, name, callback) {
		const params = new URLSearchParams()
		params.append('departmentName',name)                             
		let url = `${baseApiUrl}` + 'department/'+ companyId + '/' + departmentId + '/departmentName'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},       
	postDepartmentAgents (companyId, departmentId, agentIdList, callback) {
		const params = new URLSearchParams()
		params.append('agentIdList',agentIdList)                                  
		let url = `${baseApiUrl}` + 'department/'+ companyId + '/' + departmentId + '/departmentAgents'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},  
	postDepartmentPermission (companyId, departmentId, inboxRule, takeContactFromUser, agentNameInHeader, seeContactFromUser, agentLead, callback) {
		const params = new URLSearchParams()
		params.append('inboxRule',inboxRule) 
		params.append('takeContactFromUser',takeContactFromUser)
		params.append('agentNameInHeader',agentNameInHeader)
		params.append('seeContactFromUser', seeContactFromUser)
		params.append('agentLead',agentLead)                                 
		let url = `${baseApiUrl}` + 'department/'+ companyId + '/' + departmentId + '/departmentPermission'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},     
	postCustomPortal (companyId, departmentId, customPortal, callback) {
		const params = new URLSearchParams()
		params.append('customPortal',customPortal)                             
		let url = `${baseApiUrl}` + 'department/'+ companyId + '/' + departmentId + '/customPortal'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},                
	deleteDepartment (companyId, departmentId, callback) {            
		let url = `${baseApiUrl}` + 'department/'+ companyId + '/' + departmentId + '/delete'
		axios.delete(url)
			.then(response => {
				if (response){                                 
					callback(true) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},              
}
