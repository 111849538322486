<template>
	<div class="user">
		<div class="user-logout">    
			<div class="user">
				<b-navbar>
					<b-navbar-nav>
						<b-nav-item-dropdown toggle-class="text-decoration-none" no-caret right>       
							<template #button-content size="lg">
								<div v-if="user">
									<!-- <b-avatar :src="avatarUrl"></b-avatar>   -->
									<b-avatar v-if="user.avatarCustom == true" :src="avatarUrl" style="background-color:white"></b-avatar>              
									<b-avatar v-else class="badge-primary">{{ user.name.substring(0,2).toUpperCase() }}</b-avatar>  
								</div> 
							</template>       
							<b-dropdown-item v-if="paymentOverdue == false && this.$mq != 'xs'" @click.prevent="profile">
								Alterar Senha
							</b-dropdown-item>                  
							<b-dropdown-item @click.prevent="logout">
								Sair
							</b-dropdown-item>
						</b-nav-item-dropdown>
					</b-navbar-nav>
				</b-navbar>                
			</div>       
		</div>                          
	</div>
</template>

<script>
import { avatarUrl } from '@/global'
import WCompanyAPI from '@/api/WCompanyAPI'
export default {
	name: 'Logout',
	data () {
		return {            
			userId: '',
			avatarUrl: '',
			paymentOverdue: false,
			user:undefined
		}
	},     
	methods: {
		getUserProfile () {
			if(this.user != null){				
				this.avatarUrl = avatarUrl + this.$route.params.companyId + '&imageId='+ this.user.userId  
			}
		},
		getCompanyPaymentStatus () {
			let access_token = localStorage.getItem('__wpp_access_token')
			WCompanyAPI.getCompanyPayment(access_token, response => {
				if (response){  				
					this.paymentOverdue = response.data[0].paymentOverdue
				} 
			})  	
		},	  		
		profile () {
			this.$router.push({path: '/user/'+this.$route.params.companyId+'/profile'})            
		},
		logout () {
			localStorage.clear()  
			let obj = {showFlowHeader: false, flowTitle: 'Título do Fluxo (e.g. Suporte Becon)'}			  
			localStorage.removeItem('__wpp_flow_header_obj')
			localStorage.setItem('__wpp_flow_header_obj', JSON.stringify(obj))				                                            
			this.$store.commit('setCompanyId', null)  
			this.$store.commit('setUser', null)
			this.$router.push({ path: '/' })
		},
	},
	mounted () {
		this.user = JSON.parse(localStorage.getItem('__whatsapp_api_user'))
		this.getUserProfile()
		this.getCompanyPaymentStatus()
	}
}
</script>

<style>
    .user {
        position: relative;
        height: 100%;
    }


    .user-logout {
        position: absolute;
        right: 0px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-top: -10px;   
    }

    .navbar-light .navbar-nav .nav-link {
        color: var(--primary) !important;
        font-size: 16px;
    }
</style>
