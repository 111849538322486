<template>
	<div class="scrollbar proxy-scrollable">
		<b-col sm="9" class="ml-4 mt-5" ref="main">
			<b-col>
				<b-row>
					<span class="page-tittle">Proxy</span>
				</b-row>
				<b-row class="mt-2">
					<span class="page-subtitle">Configurações de gestão do gateway de seu sistema que utiliza as APIs da Becon para automatizações no WhatsApp Business API.</span>
				</b-row>
			</b-col>
			<b-col>
				<b-row class="mt-1">
					<span class="page-subtitle">Name Space: <span class="item-url ml-1">{{namespace}}</span></span>
				</b-row>
			</b-col>
			<b-col>
				<b-row class="mt-1">
					<span class="page-sub-subtitle">Com essa configuração, você poderá usar as APIs do WhatsApp como proxy através da plataforma da Becon.</span>
				</b-row>
				<b-row class="mt-1">
					<span class="item-url">{{apiHost}}</span>
				</b-row>
			</b-col>
			<b-col>
				<b-row>
					<span class="page-item-tittle">Persistir transações</span>
				</b-row>
				<b-row>
					<span class="page-item-subtitle">As transações realizadas pelo Gateway deverão aparecer nos LOGs da API.</span>
				</b-row>
				<b-row>
					<b-form-checkbox name="" v-model="traceLog" switch></b-form-checkbox>
				</b-row>
			
			</b-col>
			<b-col>
				<b-row>
					<span class="page-item-tittle">Persistir mensagens</span>
				</b-row>
				<b-row>
					<span class="page-item-subtitle">Todas as mensagens trafegadas pelo Gateway deverão aparecer no Bate-Papo.</span>
				</b-row>
				<b-row>
					<b-form-checkbox name="" v-model="messagePersist" switch></b-form-checkbox>
				</b-row>
			</b-col>
			<b-col>
				<b-row>
					<span class="page-item-tittle">Marcar como ”lida” assíncrono</span>
				</b-row>
				<b-row>
					<span class="page-item-subtitle">A transação de confirmação de leitura não irá aguardar o retorno do servidor.</span>
				</b-row>
				<b-row>
					<b-form-checkbox name="" v-model="markAsReadedAsync" switch></b-form-checkbox>
				</b-row>
			</b-col>
			<b-col>
				<b-row>
					<span class="page-item-tittle">Envio do estado da mensagem.</span>
				</b-row>
				<b-row>
					<span class="page-item-subtitle">O Webhook receberá as requisições de estado das mensagens.</span>
				</b-row>
				<b-row>
					<b-form-checkbox name="" v-model="statusBroadcast" switch></b-form-checkbox>
				</b-row>
			</b-col>
			<b-col>
				<b-row>
					<span class="page-item-tittle">Nome do proxy.</span>
				</b-row>
				<b-row>
					<span class="page-item-subtitle">Nome do proxy que será apresentado na tela de mensagens.</span>
				</b-row>
				<b-row >
					<b-input v-model="proxyName"></b-input>
				</b-row>
			</b-col>
			<b-col>
				<b-row>
					<span class="page-item-tittle">Webhook do seu sistema</span>
				</b-row>
				<b-row>
					<span class="page-item-subtitle">A URL do Webhook de seu servidor que deverá receber as requisições de estado das mensagens.</span>
				</b-row>
				<b-row >
					<b-input v-model="webhookUrl"></b-input>
				</b-row>
			</b-col>
			<b-col>
				<b-row>
					<span class="page-item-tittle">User-Agent</span>
				</b-row>
				<b-row>
					<span class="page-item-subtitle">O User-Agent que será incluso nas chamadas para o endereço de webhook.</span>
				</b-row>
				<b-row >
					<b-input v-model="webhookUserAgent"></b-input>
				</b-row>
			</b-col>
			<b-col>
				<b-row>
					<span class="page-item-tittle">Cabeçalho customizado</span>
				</b-row>
				<b-row>
					<span class="page-item-subtitle">Informe a chave e o valor do cabeçalho customizado. Garantindo uma camada extra de monitoramento e segurança nas suas requisições</span>
				</b-row>
				<b-row >
					
					<b-input style="width:49%" maxlength="100" v-model="headerCustomKey"></b-input>
					
					<b-input style="width:49%" maxlength="100" class="ml-1" v-model="headerCustomValue"></b-input>
			
					
				</b-row>
			</b-col>
			<b-col>
				<b-row>
					<span class="page-item-tittle">Tempo de limpeza de banco de dados</span>
				</b-row>
				<b-row>
					<span class="page-item-subtitle">Tempo em millisegundos que sera executadoa rotina de excluírem do banco de dados as mensagens associadas a seu cliente da WhatsApp Business API. Recomendado o tempo medio de acordo com um ciclo de 10 milhoes de mensagens trocadas
						Minimo de 24 horas. (86400000).</span>
				</b-row>
				<b-row >
					<b-input v-model="deleteMessagesFromDbCycleConfig"></b-input>
				</b-row>
			</b-col>
			<b-col>
				<b-row class="justify-content-end button-style">
					<b-button @click="callpostApi()">Salvar configurações</b-button>
				</b-row>
			
			</b-col>
		</b-col>
	</div>
	
</template>

<script>
import WKeyAPI from '@/api/WKeyAPI'
import {baseUrl} from '@/global'
export default {
	data () {
		return { 
			baseUrl:'',
			traceLog: false,
			messagePersist: false,
			markAsReadedAsync: false,
			statusBroadcast: false,
			webhookUrl: 'teste',
			deleteMessagesFromDbCycleConfig:'',
			apiHost:'',
			namespace:'',
			proxyName:'',
			webhookUserAgent:'',
			headerCustomKey:'',
			headerCustomValue:'',


		}
	}, 
	methods: {
		callgetApi () {
			WKeyAPI.getProxyParameter(this.$route.params.companyId,localStorage.getItem('__wpp_phone_number_id_proxy'),response => {
				this.traceLog = response.data.traceLog
				this.messagePersist = response.data.messagePersist
				this.markAsReadedAsync = response.data.markAsReadedAsync
				this.statusBroadcast = response.data.statusBroadcast
				this.webhookUrl = response.data.webhookUrl
				this.deleteMessagesFromDbCycleConfig = response.data.deleteMessagesFromDbCycleConfig
				this.apiHost = response.data.apiHost
				this.namespace = response.data.namespace
				this.proxyName = response.data.proxyName
				this.webhookUserAgent = response.data.webhookUserAgent
				this.headerCustomKey = response.data.headerCustomKey
				this.headerCustomValue = response.data.headerCustomValue
			})
		},
		callpostApi () {
			if(this.deleteMessagesFromDbCycleConfig > 0){
				if(this.deleteMessagesFromDbCycleConfig < 86400000){
					alert('Valor deve ser maior que 24 horas.')
					return
				}
			}
			if(this.deleteMessagesFromDbCycleConfig < 0){
				alert('Valor não pode ser menor que zero.')
				return
			}
			WKeyAPI.postProxyParameter(this.$route.params.companyId
				,localStorage.getItem('__wpp_phone_number_id_proxy')
				,this.messagePersist
				,this.statusBroadcast
				,this.traceLog
				,this.markAsReadedAsync
				,this.webhookUrl
				,this.webhookUserAgent
				,this.proxyName
				,this.headerCustomKey
				,this.headerCustomValue
				,this.deleteMessagesFromDbCycleConfig
				,response => {
					response.data
					let obj = {step: 6}
					this.$emit('sendMessageData', obj)
					alert('Configurações salvas!')
				})
		},
	}, 
	mounted () { 
		this.baseUrl = baseUrl
		this.callgetApi()
	}, 
}
</script>

<style lang="scss">
.proxy-scrollable {
		height: 87vh;	
		width: 100%;
		background: #fff;
		overflow-y: scroll;
		overflow-x: hidden;
	}
.page-tittle{
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;
	display: flex;
	align-items: center;
	color: #414141;
}
.page-subtitle{
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 18px;
	display: flex;
	align-items: center;
	color: #414141;
	
}
.page-sub-tittle{
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 27px;
	display: flex;
	align-items: center;
	color: #414141;
}
.page-sub-subtitle{
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #414141;
}
.page-item-tittle{
	
	font-family: Open Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 19px;
	color: #777777;
}
.page-item-subtitle{
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #414141;
}
.item-url{
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	display: flex;
	align-items: center;
	text-decoration-line: underline;
	color: var(--primary);
}
.button-style{
	.btn-secondary {	
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 500;
}
}
</style>