<template>
	<div class="screen-template-custom" style="margin:20px">
		<b-col md="6" sm="12" class="ml-5" style="margin-top:40px">
			<b-row class="pt-4 ml-0">
				<h3 class="lbl-title">Redefinir Senha</h3>
			</b-row>
			<span class="custom-font-open-14-gray ">
				Siga o processo abaixo e altere a sua senha de usuário.
			</span> 
			<hr>
			<b-row >                         
				<label for="user-email" class="custom-font-open-14-gray ml-3">Sua nova senha</label>      
			</b-row>  
			<b-row class="ml-1 mb-3">
				<b-form-input 
					id="about" 
					type="password"
					v-model="newPassword"
					class = 'custom-font-open-12-gray'
					placeholder="A senha deve ter no mínimo 6 dígitos, preferencialmente conter uma letra maiúscula e um caracter especial." 
				/>    
					
			</b-row> 
			<b-row class="ml-1">    	
				<label for="user-email" class="custom-font-open-14-gray">Repita sua nova senha</label>
			</b-row>   
			<b-row class="ml-1">
				<b-form-input 
					id="about" 
					type="password"
					v-model="confirmPassword"
					class = 'custom-font-open-12-gray'
					placeholder="A senha deve ter no mínimo 6 dígitos, preferencialmente conter uma letra maiúscula e um caracter especial." 
				/>    	
			</b-row> 
			<b-row class="mt-3 ml-1">
				<label for="" class='custom-font-open-14-gray'>Código de Segurança </label>
				<label class="custom-font-open-14-400-gray">Para finalizar o processo, você deve clicar no botão abaixo. O sistema irá enviar um código para o e-mail <b>{{profile.email}}</b>, garantindo que apenas quem tenha acesso à este e-mail possa alterar sua senha.  </label>
			</b-row>		
			<b-row class="mt-2 ml-1">	
				<label class="custom-font-open-14-400-gray">Caso não tenha acesso à este e-mail, entre em contato com o seu administrador.</label>	
			</b-row>
			<b-row class="mt-2 mb-3 ml-1" v-show="showConfirmEmail">	
				<b-form-input 
					id="about" 
					type="text"
					v-model="emailCode"
					class = 'custom-font-open-12-gray'
					placeholder="Preencha aqui o código de segurança  que recebeu por e-mail." 
				/>		
			</b-row>
			<b-row class="mr-2">			
				<div v-show="showConfirmEmail==false" >
					<b-button variant="primary" class="ml-3" @click="emailSend">Enviar código de segurança
					</b-button>
				</div>
				<div v-show="showConfirmEmail==true">
					<b-button variant="primary" class="ml-3" @click="save">Alterar minha senha           
					</b-button>
				</div>
			
			</b-row>
		</b-col>
	</div>
</template>

<script>
import WUserAPI from '@/api/WUserAPI'
export default {
	name: 'user', 
	data: function () {
		return {	
			confirmPassword: '',
			newPassword:'',
			emailCode: '',          
			showEmailCode: false,
			showConfirmEmail:false,
			buttonValue:'Enviar código de segurança',
			variant:'primary',
			profile: {},      
		}
	},
	methods: {     
		emailSend () {	
			if (this.confirmPassword != this.newPassword ) {
				alert('A confirmação da senha não corresponde!')
				return
			} 
			if (this.newPassword.length < 6) {	
				alert('A senha deve ter no mínimo 6 dígitos!')
				return
			}            
			if (this.newPassword === '' || this.newPassword === undefined) {
				alert('Campo nova senha obrigatório!')
				return
			} 
			if (this.confirmPassword === '' || this.confirmPassword === undefined) {
				alert('Campo confirmar senha obrigatório!')
				return
			}      
			this.showConfirmEmail = true
			this.showEmailCode = true
			WUserAPI.requestPasswordWithToken(this.confirmPassword)        
		},  
		save () {
			if (this.emailCode === '' || this.emailCode === undefined) {
				alert('Campo código obrigatório!')
				return
			}   
			WUserAPI.confirmPasswordWithToken(this.confirmPassword, this.emailCode, response =>{ 
				if(response == true){
					this.$router.push({path: '/user/PasswordSucess'}) 
				}
			})                                 
		},
		getUserProfile () {
			WUserAPI.getUserProfile(response => {
				this.profile = response.data
			})  
		},	
	},
	mounted () {
		this.getUserProfile()           
	},	
}
</script>
<style scoped>
	::placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #999999;    
}
</style>
