import axios from 'axios'
import i18n from '../plugins/i18n'
import { baseApiUrl, access_token} from '../global'


axios.defaults.headers.common['Authorization'] = `bearer ${access_token}`


export default {
   
	postLinkType1Server (companyId, server, apiKey, phonenumber,phonenumberName,namespace,wabaId, callback) {
		const params = new URLSearchParams()
		params.append('server', server)  
		params.append('apiKey', apiKey)
		params.append('phonenumber', phonenumber)     
		params.append('phonenumberName', phonenumberName)   
		params.append('namespace', namespace)                    
		params.append('wabaId', wabaId)         
		let url = `${baseApiUrl}` + 'system/'+ companyId + '/linkType1Server'
		axios.post(url, params)
			.then(response => {
				if (response){                
					callback(response.data) 
				}
			})
			.catch(function (error) {
				console.log(i18n.t('Message.error') + error)                
			})    
	},  

	
}
