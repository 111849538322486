<template>
	<b-col md="12" sm="12" class="mt-4">
		<b-row class="ml-0">
			<b-col sm="6">
				<b-row v-show="disable==true" class="ml-1">
					<span class="custom-bold-subtitle mt-1">Dados da empresa</span>
					<span class="ml-2 mt-1 icon-clickable" @click="edit">
						<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M7 12.333H13" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M10 1.33316C10.2652 1.06794 10.6249 0.918945 11 0.918945C11.1857 0.918945 11.3696 0.955525 11.5412 1.0266C11.7128 1.09767 11.8687 1.20184 12 1.33316C12.1313 1.46448 12.2355 1.62038 12.3066 1.79196C12.3776 1.96354 12.4142 2.14744 12.4142 2.33316C12.4142 2.51888 12.3776 2.70277 12.3066 2.87435C12.2355 3.04594 12.1313 3.20184 12 3.33316L3.66667 11.6665L1 12.3332L1.66667 9.66649L10 1.33316Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>              
					</span>
				</b-row>
				<b-row v-show="disable==false" class="ml-1">
					<span class="custom-bold-subtitle">Editar dados da empresa</span>
				</b-row>
				<Loading v-if="loading==true || this.$store.state.loading==true"/> 
				<b-row v-else class="ml-1">
					<b-form md="10" sm="12">
						<b-row>              
							<b-col md="12" sm="12">
								<b-form-group label="Nome da empresa" label-for="company">
									<b-form-input id="company" type="text"
										v-model="companyName" placeholder="Nome" 
										:disabled="disable"
										:autofocus="true"/>
								</b-form-group>
							</b-col>                                
						</b-row>         
						<b-row>              
							<b-col md="12" sm="12">
								<b-form-group label="E-mail" label-for="email">
									<b-form-input id="email" type="text"
										v-model="email" placeholder="exemplo@gmail.com" :disabled="disable"/>
								</b-form-group>
							</b-col>                                
						</b-row>    
						<b-row>              
							<b-col md="12" sm="12">
								<b-form-group label="Site" label-for="site">
									<b-form-input id="site" type="text"
										v-model="site" placeholder="https://" :disabled="disable"/>
								</b-form-group>
							</b-col>                                
						</b-row> 
						<b-row v-show="disable==false" class="mb-5">
							<b-col md="10" sm="12">
								<div class="d-flex justify-content-center">
									<b-button variant="outline-primary" class="mr-2"
										@click="cancel()">Cancelar
									</b-button>                      
									<b-button variant="primary" 
										@click="save()">Salvar
									</b-button>                           
								</div>
							</b-col>
						</b-row>                       
					</b-form>
				</b-row>
			</b-col>
			<b-col :class="(this.$mq == 'sm'? '':'ml-3 mb-2')" class="ml-1">
				<span class="custom-bold-subtitle mt-1">Configurações únicas da empresa</span>
				<b-row class="ml-0 mt-3">
					<span class="resume-style">Aberto por padrão</span>
					<span class="ml-1" id="popover-help-sync" style="cursor: pointer;">
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M8.00004 14.6666C11.6819 14.6666 14.6667 11.6818 14.6667 7.99992C14.6667 4.31802 11.6819 1.33325 8.00004 1.33325C4.31814 1.33325 1.33337 4.31802 1.33337 7.99992C1.33337 11.6818 4.31814 14.6666 8.00004 14.6666Z" fill="#C4C4C4"/>
							<path d="M6.05994 6.00014C6.21667 5.55458 6.52604 5.17887 6.93324 4.93956C7.34044 4.70024 7.8192 4.61276 8.28472 4.69261C8.75024 4.77246 9.17248 5.01449 9.47666 5.37582C9.78083 5.73716 9.94731 6.19448 9.9466 6.6668C9.9466 8.00014 7.9466 8.6668 7.9466 8.6668" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M8 11.3333H8.00667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>							
					</span>
					<b-popover :show.sync="showPopHelp1" target="popover-help-sync" title="" placement="bottom">
						<div>
							<b>Mensagem Recente:</b> O sistema organizará a lista de atendimentos, em<b> Bate-Papo</b>, como o WhatsApp, colocando o atendimento com a mensagem mais recente no topo.
							<br>
							<br>
							<b>Abertura de Atendimento:</b> O sistema organizará os atendimentos pela hora/data de abertura do mesmo, mantendo os mais antigos, mais no topo. Ideal para times de Suporte.
						</div>							
						<div>
							<a href="javascript:;" @click="showPopHelp1=false" class="float-right" style="color: var(--primary);"><b>Entendi!</b></a>
						</div>                            
					</b-popover>  						
				</b-row>
				<b-dropdown id="dropdown" variant="light" :text="selected">
					<b-dropdown-item @click="change(x,index)" v-for="(x,index) in chatResumeOptions" :key="index">
						<span>{{x}}</span>
					</b-dropdown-item>
				</b-dropdown>
			</b-col>
		</b-row>
		
		
		
	</b-col>
</template>

<script>
import Loading from '@/views/Loading'
import WCompanyAPI from '@/api/WCompanyAPI'
export default {    
	components: {Loading},
	name: 'CompanyData',  
	props: ['companyId', 'mode','dataObj'],
	data: function () {
		return {
			loading: false,
			companyName: this.dataObj.companyName, 
			email: this.dataObj.email,
			site: this.dataObj.site,
			disable: true,
			chatResumeOptions:['Mensagem Recente (Padrão)','Abertura de Atendimento'],
			selected:'',
			showPopHelp1: false,
		}
	},
	methods: {
		change (x,index) {
			this.selected = x
			this.$emit('changeResumeOption',index)
			setTimeout(() => {
				document.activeElement.blur()
			}, 100)
		},
		edit () {
			this.disable = false
		}, 
		cancel () {
			this.disable = true 
		},
		save () {
			if (this.site.search(/^http[s]?:\/\//) == -1) {
				this.site = 'https://' + this.site
			}
			WCompanyAPI.postProfile(this.$route.params.companyId,this.companyName,this.site,this.email,response => {
				response
				this.cancel()
			})  
			// alert('Configurações alteradas com sucesso!')
			// document.location.reload(true)
		},      
	},
	mounted () {
		this.selected = this.chatResumeOptions[this.dataObj.chatResumeOrder]
	}    
}
</script>

<style scoped>
.resume-style{
	font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #414141;
}
.custom-toggle-data{
	border: solid 1px black;
	color:red !important;
}
</style>

