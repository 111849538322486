<template>
	<div> 
		<div v-if="messageInfo.chatFlow == true">
			<span>
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32px" height="31px" viewBox="0 0 31 31" version="1.1">
					<g id="surface1">
						<path style="fill-rule:nonzero;fill:var(--primary);fill-opacity:1;stroke-width:20;stroke-linecap:round;stroke-linejoin:miter;stroke:var(--primary);stroke-opacity:1;stroke-miterlimit:4;" d="M 42.087954 242.018019 L 61.176663 242.018019 C 63.827873 242.018019 66.015121 244.167843 66.015121 246.838836 L 66.015121 334.00441 C 66.015121 336.675403 63.827873 338.825227 61.176663 338.825227 L 40.762349 338.825227 C 34.134325 338.825227 27.837702 335.633065 23.927167 330.225932 C 21.408518 326.708039 20.016633 322.473538 20.016633 318.043599 L 20.016633 264.102571 C 20.016633 259.802923 21.275958 255.568422 23.595766 251.985383 C 27.705141 245.731351 34.664567 242.018019 42.087954 242.018019 Z M 42.087954 242.018019 " transform="matrix(0.0589354,0,0,0.0599613,0,0)"/>
						<path style="fill-rule:nonzero;fill:var(--primary);fill-opacity:1;stroke-width:20;stroke-linecap:round;stroke-linejoin:miter;stroke:var(--primary);stroke-opacity:1;stroke-miterlimit:4;" d="M 481.923639 242.018019 L 462.834929 242.018019 C 460.18372 242.018019 457.996472 244.167843 457.996472 246.838836 L 457.996472 334.00441 C 457.996472 336.675403 460.18372 338.825227 462.834929 338.825227 L 483.249244 338.825227 C 489.877268 338.825227 496.173891 335.633065 500.084425 330.225932 C 502.603075 326.708039 503.99496 322.473538 503.99496 318.043599 L 503.99496 264.102571 C 503.99496 259.802923 502.735635 255.568422 500.349546 251.985383 C 496.306452 245.731351 489.347026 242.018019 481.923639 242.018019 Z M 481.923639 242.018019 " transform="matrix(0.0589354,0,0,0.0599613,0,0)"/>
						<path style=" stroke:none;fill-rule:nonzero;fill:var(--primary);fill-opacity:1;" d="M 25.527344 8.15625 L 5.851562 8.15625 C 5.46875 8.15625 5.191406 8.21875 4.992188 8.3125 C 4.621094 8.488281 4.03125 9.039062 3.835938 9.40625 C 3.730469 9.613281 3.628906 9.910156 3.550781 10.332031 C 3.265625 11.882812 3.527344 23.003906 3.546875 23.933594 C 3.550781 23.980469 3.554688 24.019531 3.566406 24.0625 C 3.707031 24.578125 3.886719 24.886719 4.496094 25.429688 C 4.914062 25.761719 5.199219 25.917969 5.761719 26.113281 C 5.820312 26.132812 5.882812 26.144531 5.949219 26.144531 L 6.363281 26.144531 C 6.710938 26.144531 6.980469 26.441406 6.953125 26.792969 L 6.722656 29.710938 C 6.683594 30.21875 7.246094 30.542969 7.652344 30.246094 L 13.109375 26.257812 C 13.207031 26.183594 13.328125 26.144531 13.453125 26.144531 L 25.535156 26.144531 C 25.582031 26.144531 25.625 26.140625 25.667969 26.128906 C 26.230469 25.988281 26.480469 25.804688 26.875 25.429688 C 27.296875 24.914062 27.4375 24.601562 27.566406 24.0625 C 27.578125 24.019531 27.582031 23.972656 27.582031 23.929688 L 27.582031 10.332031 C 27.519531 9.707031 27.316406 9.242188 26.875 8.777344 C 26.445312 8.328125 26.328125 8.339844 25.675781 8.171875 C 25.628906 8.160156 25.578125 8.15625 25.527344 8.15625 Z M 25.527344 8.15625 "/>
						<path style="fill-rule:nonzero;fill:var(--primary);fill-opacity:1;stroke-width:20;stroke-linecap:round;stroke-linejoin:miter;stroke:var(--primary);stroke-opacity:1;stroke-miterlimit:4;" d="M 65.61744 242.995212 L 90.53881 242.995212 C 93.521421 242.995212 95.97379 245.470766 95.97379 248.46749 L 95.97379 323.515877 C 95.97379 326.577747 93.521421 328.988155 90.53881 328.988155 L 64.490675 328.988155 C 58.724294 328.988155 53.289315 326.577747 49.44506 322.408392 C 45.932208 318.629914 44.010081 313.678805 44.010081 308.532258 L 44.010081 264.62374 C 44.010081 259.542339 45.799647 254.656376 49.047379 250.747606 C 53.156754 245.861643 59.188256 242.995212 65.61744 242.995212 Z M 65.61744 242.995212 " transform="matrix(0.0589354,0,0,0.0599613,0,0)"/>
						<path style="fill-rule:nonzero;fill:var(--secondary);fill-opacity:1;stroke-width:20;stroke-linecap:round;stroke-linejoin:miter;stroke:var(--secondary);stroke-opacity:1;stroke-miterlimit:4;" d="M 478.675907 243.125504 L 460.979083 243.125504 C 458.592994 243.125504 456.604587 245.145035 456.604587 247.555444 L 456.604587 325.014239 C 456.604587 327.424647 458.592994 329.379032 460.979083 329.379032 L 479.802671 329.379032 C 485.767893 329.379032 491.335433 326.577747 494.848286 321.822077 C 497.234375 318.629914 498.4937 314.721144 498.4937 310.747228 L 498.4937 262.995086 C 498.4937 259.086316 497.300655 255.242692 495.113407 251.920237 C 491.467994 246.447959 485.237651 243.125504 478.675907 243.125504 Z M 478.675907 243.125504 " transform="matrix(0.0589354,0,0,0.0599613,0,0)"/>
						<path style=" stroke:none;fill-rule:nonzero;fill:var(--primary);fill-opacity:1;" d="M 25.703125 8.996094 L 7.128906 8.996094 C 6.792969 8.996094 6.542969 9.046875 6.359375 9.125 C 5.980469 9.285156 5.386719 9.839844 5.203125 10.214844 C 5.109375 10.40625 5.019531 10.671875 4.953125 11.042969 C 4.6875 12.496094 4.929688 22.914062 4.953125 23.816406 C 4.953125 23.863281 4.957031 23.902344 4.972656 23.949219 C 5.101562 24.425781 5.273438 24.71875 5.84375 25.226562 C 6.238281 25.539062 6.507812 25.683594 7.039062 25.867188 C 7.097656 25.886719 7.160156 25.898438 7.222656 25.898438 L 7.574219 25.898438 C 7.917969 25.898438 8.191406 26.195312 8.164062 26.546875 L 7.953125 29.171875 C 7.914062 29.683594 8.476562 30.007812 8.882812 29.710938 L 13.96875 26.011719 C 14.070312 25.9375 14.191406 25.898438 14.3125 25.898438 L 25.710938 25.898438 C 25.753906 25.898438 25.800781 25.894531 25.84375 25.882812 C 26.367188 25.75 26.605469 25.578125 26.976562 25.226562 C 27.375 24.742188 27.507812 24.449219 27.628906 23.949219 C 27.640625 23.902344 27.644531 23.855469 27.644531 23.8125 L 27.644531 11.042969 C 27.585938 10.453125 27.394531 10.019531 26.976562 9.578125 C 26.570312 9.15625 26.460938 9.167969 25.847656 9.011719 C 25.800781 9 25.75 8.996094 25.703125 8.996094 Z M 25.703125 8.996094 "/>
						<path style="fill:none;stroke-width:25;stroke-linecap:round;stroke-linejoin:miter;stroke:var(--secondary);stroke-opacity:1;stroke-miterlimit:4;" d="M 425.983115 150.031628 L 429.628528 152.246598 C 439.703125 158.435484 447.656754 167.425655 452.561492 178.174773 C 456.140625 185.992314 457.996472 194.461316 457.996472 202.995464 L 457.996472 379.737021 C 457.996472 389.704385 455.345262 399.411164 450.307964 408.010459 C 446.795111 413.873614 442.288054 419.085307 436.985635 423.319808 L 425.983115 431.984249 " transform="matrix(0.0589354,0,0,0.0599613,0,0)"/>
						<path style="fill-rule:nonzero;fill:var(--secondary);fill-opacity:1;stroke-width:20;stroke-linecap:round;stroke-linejoin:miter;stroke:var(--secondary);stroke-opacity:1;stroke-miterlimit:4;" d="M 472.445565 255.177545 L 471.849042 254.916961 C 470.523438 254.330645 469.131552 254.004914 467.739667 254.004914 C 462.370968 254.004914 457.996472 258.369708 457.996472 263.77684 L 457.996472 315.112021 C 457.996472 320.584299 462.437248 325.014239 467.872228 325.014239 C 469.197833 325.014239 470.523438 324.753654 471.716482 324.232485 L 472.246724 323.9719 C 476.952621 321.952369 480.001512 317.392137 480.001512 312.310736 L 480.001512 266.969002 C 480.001512 261.887601 477.018901 257.262223 472.445565 255.177545 Z M 472.445565 255.177545 " transform="matrix(0.0589354,0,0,0.0599613,0,0)"/>
						<path style="fill:none;stroke-width:20;stroke-linecap:round;stroke-linejoin:miter;stroke:var(--secondary);stroke-opacity:1;stroke-miterlimit:4;" d="M 32.80872 239.998488 L 52.493952 239.998488 C 55.277722 239.998488 57.53125 242.213458 57.53125 245.014743 L 57.53125 334.981603 C 57.53125 337.782888 55.277722 339.997858 52.493952 339.997858 L 31.483115 339.997858 C 24.58997 339.997858 18.094506 336.675403 14.051411 331.137979 C 11.400202 327.489793 10.008317 323.059854 10.008317 318.564768 L 10.008317 262.799647 C 10.008317 258.369708 11.267641 254.004914 13.72001 250.291583 C 17.961946 243.842112 25.120212 239.998488 32.80872 239.998488 Z M 32.80872 239.998488 " transform="matrix(0.0589354,0,0,0.0599613,0,0)"/>
						<path style="fill:none;stroke-width:20;stroke-linecap:round;stroke-linejoin:miter;stroke:var(--secondary);stroke-opacity:1;stroke-miterlimit:4;" d="M 492.661038 240.519657 L 472.975806 240.519657 C 470.258317 240.519657 468.004788 242.734627 468.004788 245.470766 L 468.004788 335.502772 C 468.004788 338.238911 470.258317 340.519027 472.975806 340.519027 L 494.052923 340.519027 C 500.946069 340.519027 507.375252 337.196573 511.418347 331.659148 C 514.069556 327.945817 515.527722 323.581023 515.527722 319.020791 L 515.527722 263.320817 C 515.527722 258.890877 514.202117 254.526084 511.749748 250.812752 C 507.574093 244.363281 500.349546 240.519657 492.661038 240.519657 Z M 492.661038 240.519657 " transform="matrix(0.0589354,0,0,0.0599613,0,0)"/>
						<path style=" stroke:none;fill-rule:nonzero;fill:var(--primary);fill-opacity:1;" d="M 15.5 5.15625 C 16.898438 5.15625 18.035156 4.003906 18.035156 2.578125 C 18.035156 1.15625 16.898438 0 15.5 0 C 14.101562 0 12.964844 1.15625 12.964844 2.578125 C 12.964844 4.003906 14.101562 5.15625 15.5 5.15625 Z M 15.5 5.15625 "/>
						<path style=" stroke:none;fill-rule:nonzero;fill:var(--primary);fill-opacity:1;" d="M 16.414062 4.257812 C 17.144531 4.257812 17.738281 3.519531 17.738281 2.609375 C 17.738281 1.699219 17.144531 0.960938 16.414062 0.960938 C 15.679688 0.960938 15.085938 1.699219 15.085938 2.609375 C 15.085938 3.519531 15.679688 4.257812 16.414062 4.257812 Z M 16.414062 4.257812 "/>
						<path style="fill:none;stroke-width:17;stroke-linecap:butt;stroke-linejoin:miter;stroke:var(--secondary);stroke-opacity:1;stroke-miterlimit:4;" d="M 263 77.523942 C 282.022429 77.523942 297.532006 62.084299 297.532006 42.996472 C 297.532006 23.97379 282.022429 8.469002 263 8.469002 C 243.977571 8.469002 228.467994 23.97379 228.467994 42.996472 C 228.467994 62.084299 243.977571 77.523942 263 77.523942 Z M 263 77.523942 " transform="matrix(0.0589354,0,0,0.0599613,0,0)"/>
						<path style=" stroke:none;fill-rule:nonzero;fill:var(--secondary);fill-opacity:1;" d="M 16.03125 4.917969 L 14.96875 4.917969 L 14.96875 8.335938 L 16.03125 8.335938 Z M 16.03125 4.917969 "/>
						<path style=" stroke:none;fill-rule:nonzero;fill:var(--secondary);fill-opacity:1;" d="M 8.429688 9.234375 C 8.722656 9.234375 8.957031 8.992188 8.957031 8.695312 C 8.957031 8.394531 8.722656 8.15625 8.429688 8.15625 C 8.136719 8.15625 7.898438 8.394531 7.898438 8.695312 C 7.898438 8.992188 8.136719 9.234375 8.429688 9.234375 Z M 8.429688 9.234375 "/>
						<path style="fill:none;stroke-width:20;stroke-linecap:round;stroke-linejoin:miter;stroke:var(--secondary);stroke-opacity:1;stroke-miterlimit:4;" d="M 176.504284 145.471396 L 432.80998 145.471396 C 433.605343 145.471396 434.400706 145.601689 435.196069 145.797127 C 446.264869 148.46812 448.253276 148.272681 455.477823 155.503906 C 463.03377 162.995716 466.480343 170.422379 467.474546 180.520035 L 467.474546 398.824849 C 467.474546 399.606603 467.408266 400.388357 467.209425 401.170111 C 465.022177 409.704259 462.636089 414.720514 455.477823 422.994078 C 448.849798 429.052671 444.607863 431.984249 435.063508 434.264365 C 434.334425 434.394657 433.605343 434.52495 432.87626 434.52495 L 228.136593 434.52495 C 226.081905 434.52495 224.093498 435.111265 222.436492 436.283896 L 129.909274 500.387727 C 122.949849 505.208543 113.538054 499.801411 114.267137 491.397555 L 118.111391 445.339214 C 118.575353 439.476058 114.002016 434.52495 108.103075 434.52495 L 101.077369 434.52495 C 100.016885 434.52495 99.022681 434.329511 98.028478 434.00378 C 88.484123 430.876764 83.579385 428.40121 76.487399 422.994078 C 66.280242 414.329637 63.165071 409.378528 60.845262 401.170111 C 60.580141 400.388357 60.513861 399.736895 60.447581 398.955141 C 60.049899 383.450353 55.741683 205.340726 60.513861 180.520035 C 61.839466 173.679688 63.496472 168.924017 65.352319 165.666709 C 68.732611 159.542969 78.740927 150.748236 85.103831 148.012097 C 88.417843 146.513735 93.05746 145.471396 99.486643 145.471396 C 122.485887 145.471396 109.49496 145.471396 109.49496 145.471396 " transform="matrix(0.0589354,0,0,0.0599613,0,0)"/>
						<path style=" stroke:none;fill-rule:nonzero;fill:var(--secondary);fill-opacity:1;" d="M 9.460938 18.40625 C 10.84375 18.40625 11.964844 17.265625 11.964844 15.859375 C 11.964844 14.453125 10.84375 13.3125 9.460938 13.3125 C 8.074219 13.3125 6.953125 14.453125 6.953125 15.859375 C 6.953125 17.265625 8.074219 18.40625 9.460938 18.40625 Z M 9.460938 18.40625 "/>
						<path style="fill-rule:nonzero;fill:var(--secondary);fill-opacity:1;stroke-width:17;stroke-linecap:butt;stroke-linejoin:miter;stroke:var(--secondary);stroke-opacity:1;stroke-miterlimit:4;" d="M 160.530746 298.499748 C 179.288054 298.499748 194.53251 283.255544 194.53251 264.493448 C 194.53251 245.731351 179.288054 230.487147 160.530746 230.487147 C 141.707157 230.487147 126.528982 245.731351 126.528982 264.493448 C 126.528982 283.255544 141.707157 298.499748 160.530746 298.499748 Z M 160.530746 298.499748 " transform="matrix(0.0589354,0,0,0.0599613,0,0)"/>
						<path style=" stroke:none;fill-rule:nonzero;fill:var(--secondary);fill-opacity:1;" d="M 21.539062 18.40625 C 22.925781 18.40625 24.046875 17.265625 24.046875 15.859375 C 24.046875 14.453125 22.925781 13.3125 21.539062 13.3125 C 20.15625 13.3125 19.035156 14.453125 19.035156 15.859375 C 19.035156 17.265625 20.15625 18.40625 21.539062 18.40625 Z M 21.539062 18.40625 "/>
						<path style="fill-rule:nonzero;fill:var(--secondary);fill-opacity:1;stroke-width:17;stroke-linecap:butt;stroke-linejoin:miter;stroke:var(--secondary);stroke-opacity:1;stroke-miterlimit:4;" d="M 365.469254 298.499748 C 384.292843 298.499748 399.471018 283.255544 399.471018 264.493448 C 399.471018 245.731351 384.292843 230.487147 365.469254 230.487147 C 346.711946 230.487147 331.46749 245.731351 331.46749 264.493448 C 331.46749 283.255544 346.711946 298.499748 365.469254 298.499748 Z M 365.469254 298.499748 " transform="matrix(0.0589354,0,0,0.0599613,0,0)"/>
						<path style="fill:none;stroke-width:20;stroke-linecap:round;stroke-linejoin:miter;stroke:var(--secondary);stroke-opacity:1;stroke-miterlimit:4;" d="M 220.978327 332.506048 C 233.107611 344.623236 248.020665 350.030368 263.530242 350.030368 C 278.973538 350.030368 291.633065 346.25189 306.015877 332.506048 " transform="matrix(0.0589354,0,0,0.0599613,0,0)"/>
					</g>
				</svg>
			</span>
			<br> 
		</div>
		<span class="dateSent">
			{{messageInfo.userName}} - {{ getDateTimeFromTimestamp(messageInfo.time) }}                    
			<span v-show="messageInfo.tick == 0" :id="messageInfo.messageId" class="h6 icon-clickable" @click="getMessageError(messageInfo.phonenumberId, messageInfo.messageId)"><b-icon icon="exclamation-circle-fill" variant="danger"></b-icon></span>
			<span v-show="messageInfo.tick == 1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck" x="2047" y="2061"><path d="M15.01 3.316l .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#92a58c"/></svg></span>                               
			<span v-show="messageInfo.tick == 2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck" x="2047" y="2061"><path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#92a58c"/></svg></span>                                    
			<span v-show="messageInfo.tick == 3"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck-ack" x="2063" y="2076"><path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#4fc3f7"/></svg></span>                                    
		</span>   
		<b-popover :show.sync="showPopMessageError" :target="messageInfo.messageId" title="Mensagem de erro" placement="right">
			<b-row class="ml-2">
				<span class="custom-font-open-12"><b>Código:</b> {{messageError.code}}</span>
			</b-row> 
			<b-row class="ml-2">
				<span class="custom-font-open-12"><b>Título:</b> {{messageError.title}}</span>
			</b-row>  
			<b-row class="ml-2">
				<span class="custom-font-open-12"><b>Hora:</b> {{getDateTimeFromTimestamp(messageError.errorTime)}}</span>
			</b-row>                                                               
			<div>
				<a href="javascript:;" @click="showPopMessageError=false" class="float-right" style="color: var(--primary); font-size:12px;">Ok</a>                    
			</div>                            
		</b-popover>                                                                   			
	</div>                                          
</template>

<script>
import WMessageAPI from '@/api/WMessageAPI'
export default {
	name: 'WMessageError',
	props: [
		'messageInfo'
	],
	data () {
		return {
			messageError: '',
			showPopMessageError: false,
			show: true                    
		}
	},    
	methods: {        
		getDateTimeFromTimestamp (time) {
			let date = new Date(time)
			return (('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2))
		},  
         
		getMessageError (phonenumberId, messageId) {
			WMessageAPI.postError(this.$route.params.companyId, phonenumberId, messageId, response => {
				this.messageError = response.data                
				this.showPopMessageError = true
			})   
		}                             
	},    
	mounted () {
	}, 
}
</script>

<style scoped>   
    .dateSent {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        color: #777777;
    }    
</style>



 