<template>
	<div id="main" class="template-list-scrollable scrollbar" style="margin-right: 30px;"> 
		<div :class="[toSync == true? 'blur-screen disable':'']">
			<b-col class="mt-2 ml-2" >
				<b-row class="ml-1">
					<b-col class="mt-2">
						<h3 class="lbl-title">Templates do WhatsApp</h3>
						<b-row class="ml-1">
							<span class="custom-font-open-18-400">Eles são úteis para enviar mensagens proativas!</span>
							<div class="mt-2">                                                                                                            
							</div>
							<span class="ml-1" id="popover-help-sync" style="cursor: pointer;">
								<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M8.00004 14.6666C11.6819 14.6666 14.6667 11.6818 14.6667 7.99992C14.6667 4.31802 11.6819 1.33325 8.00004 1.33325C4.31814 1.33325 1.33337 4.31802 1.33337 7.99992C1.33337 11.6818 4.31814 14.6666 8.00004 14.6666Z" fill="#C4C4C4"/>
									<path d="M6.05994 6.00014C6.21667 5.55458 6.52604 5.17887 6.93324 4.93956C7.34044 4.70024 7.8192 4.61276 8.28472 4.69261C8.75024 4.77246 9.17248 5.01449 9.47666 5.37582C9.78083 5.73716 9.94731 6.19448 9.9466 6.6668C9.9466 8.00014 7.9466 8.6668 7.9466 8.6668" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M8 11.3333H8.00667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>							
							</span>
							<b-popover :show.sync="showPopHelp1" target="popover-help-sync" title="" placement="bottom">
								<div>
									Template é um modelo padrão de mensagem, que deve ser pré-aprovado pelo time do Facebook, para que possa ser usado como mensagem proativa. Ou seja, templates são a única fora de enviar mensagem para um contato sem que existe uma sessão aberta. Porém, vale dizer que Templates também podem ser usados em sessões abertas. Para mais informações, <u @click="openHelpSite('https://becon.com.br/como-usar-templates-no-whatsapp/')" style="color: var(--primary);">clique aqui.</u>
								</div>							
								<div>
									<a href="javascript:;" @click="showPopHelp1=false" class="float-right" style="color: var(--primary);"><b>x</b></a>
								</div>                            
							</b-popover>  						
						</b-row>              
					</b-col>
					<b-col>              
					</b-col>  
				</b-row>
			</b-col>
			<b-col md="12" sm="12" >
				<b-tooltip target="searchIcon" triggers="hover">
					Pesquisar mensagens
				</b-tooltip>
				<b-row >
					<b-col v-if="isAccountSynced == true" class="mt-2">
						<span class="warning-sync ml-3"><b>Atenção:</b> Conta não sincronizada!</span>
					</b-col>
					<b-col >
						<b-row class="justify-content-end mr-0">
							<b-button 
								variant="primary"
								@click="blurScreen()"
								class="mr-4 sync-button">
								<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g clip-path="url(#clip0_1309_415)">
										<path d="M9.91699 0.583984L12.2503 2.91732L9.91699 5.25065" stroke="var(--secondary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M1.75 6.41602V5.24935C1.75 4.63051 1.99583 4.03702 2.43342 3.59943C2.871 3.16185 3.46449 2.91602 4.08333 2.91602H12.25" stroke="var(--secondary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M4.08333 13.4167L1.75 11.0833L4.08333 8.75" stroke="var(--secondary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M12.25 7.58398V8.75065C12.25 9.36949 12.0042 9.96298 11.5666 10.4006C11.129 10.8382 10.5355 11.084 9.91667 11.084H1.75" stroke="var(--secondary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									</g>
									<defs>
										<clipPath id="clip0_1309_415">
											<rect width="14" height="14" fill="white"/>
										</clipPath>
									</defs>
								</svg>

								<span class="ml-1 ">
									Sincronizar com o Facebook
								</span>                        
							</b-button>
							<b-button 
								variant="secondary"
								@click="create()">
								<span>
									<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M8 3V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M3 8H13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								</span>
								<span class="ml-1 ">
									Adicionar template
								</span>                        
							</b-button>

						</b-row>
					</b-col>  		                                                     
				</b-row>
				<div>
				
					<template-modal-synced
						:title="'Sua conta Facebook não está sincronizada'"
						:subTitle = "''"
						:description = "''"
						:description1= "''"
						:descriptionHtml= "'Sua conta do Facebook <b>deve estar sincronizada</b> com a plataforma da Becon para que você possa usar os templates.<br><br> Por favor, procure o <b>nosso suporte</b> ou contate o seu <b>administrador</b> assim que possível.'"
						:value = "''"
						:placeholder = "''"
						:button3 = "'Suporte becon'"
						:start="showModalSynced"   
						v-on:sendModalData="openLinkWhastapp"
						:key="componentSyncedModalKey"                        
					/>
				</div>  			
				<b-row class="ml-2 mt-4">
					<b-col md="8">
						<b-row> 	
							<div>
								<search-check-box-filter
									:departmentFilter="true"
									:sortBy="'text'"
									:placeHolder="'Departamentos'"
									v-on:checkedItems="setSelectedItems($event, originalTemplateList)"
								/>
							</div>		
						</b-row>				
					</b-col>
					<b-col class="mt-0" md="4">						
						<b-row class="justify-content-end">
							<b-input-group >
								<b-form-input 
									type="search"                     
									v-model="filter"
									placeholder="Buscar pelo nome"
									@keyup="searchTemplate"
									@update="clearFilter">
									
								</b-form-input>
								<b-input-group-prepend is-text>
									<b-icon icon="search"></b-icon>
								</b-input-group-prepend>                    
							</b-input-group> 							
						</b-row>
					</b-col>
				</b-row>
			
				<loading v-if="this.$store.state.loading ==true"/>     
				<b-row style="margin-rigth:30px;" v-if="this.$store.state.loading ==false">
					<b-col md="3">
					</b-col>
					<b-col md="8">
					</b-col>                       
				</b-row>
				<b-row class="mt-1">      
					<div>
						<template-modal-delete
							:title = "'Excluir template'"
							:subTitle = "'Tem certeza que deseja excluir o template ?'"
							:description = "templateName"
							:description1= "''"
							:value = "''"
							:placeholder = "''"
							:button1 = "'Fechar'"
							:button2 = "'Excluir'"
							:start="showModalDelete"   
							v-on:sendModalData="deleteTemplate"
							:key="componentDeleteModalKey"                        
						/>
					</div>                                                 
					<b-col class="ml-2" v-if="this.$store.state.loading ==false">
						<div class="template-list-scrollable-items scrollbar">
							<b-table-simple id="my-table"
								:per-page="perPage"
								:current-page="currentPage" hover small responsive>
								<b-thead head-variant="ligth">
									<b-tr>
										<b-th v-if="clicked == false">
											<a href="javascript:;" @click="sortByAsc('templateDescription')">
												<span class="header-custom">Nome do template</span>
												<span :class="activeCol1"></span>
											</a>
										</b-th>
										<b-th v-if="clicked == true">
											<a href="javascript:;" @click="sortByDesc('-templateDescription', 1)">
												<span class="header-custom">Nome do template</span>
												<span id="active1" :class="activeCol1"></span>
											</a>
										</b-th>    									        
										<b-th v-if="clicked == false">
											<a href="javascript:;" @click="sortByAsc('typeDescription')">
												<span class="header-custom">Categoria</span>
												<span :class="activeCol2"></span>
											</a>
										</b-th>
										<b-th v-if="clicked == true">
											<a href="javascript:;" @click="sortByDesc('-typeDescription', 2)">
												<span class="header-custom">Categoria</span>
												<span id="active2" :class="activeCol2"></span>
											</a>
										</b-th> 							      									            
										<b-th>Prévia</b-th>
										<b-th>Departamentos</b-th>
										<b-th v-if="clicked == false">
											<b-row>
												<b-col style="padding-top: 10px; padding-bottom: 0px;">
													<a href="javascript:;" @click="sortByAsc('statusDescription')">									
														<span class="header-custom">Status</span>											
														<span :class="activeCol3"></span>
													</a>
												</b-col>
												<b-col style="padding-top: 10px; padding-bottom: 0px;">
													<span id="popover-help-sync2" style="cursor: pointer;">
														<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M8.00004 14.6666C11.6819 14.6666 14.6667 11.6818 14.6667 7.99992C14.6667 4.31802 11.6819 1.33325 8.00004 1.33325C4.31814 1.33325 1.33337 4.31802 1.33337 7.99992C1.33337 11.6818 4.31814 14.6666 8.00004 14.6666Z" fill="#C4C4C4"/>
															<path d="M6.05994 6.00014C6.21667 5.55458 6.52604 5.17887 6.93324 4.93956C7.34044 4.70024 7.8192 4.61276 8.28472 4.69261C8.75024 4.77246 9.17248 5.01449 9.47666 5.37582C9.78083 5.73716 9.94731 6.19448 9.9466 6.6668C9.9466 8.00014 7.9466 8.6668 7.9466 8.6668" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M8 11.3333H8.00667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														</svg>											
													</span>
													<b-popover :show.sync="showPopHelp2" target="popover-help-sync2" title="" placement="bottom">
														<div>
															Assim que você cria um template, ele é enviado para análise do Facebook. Veja mais informações <u @click="openHelpSite('https://becon.com.br/como-usar-templates-no-whatsapp/')" style="color: var(--primary);">clicando aqui</u>, ou consulte nosso suporte para boas práticas na construção de templates.
														</div>											
														<div>
															<a href="javascript:;" @click="showPopHelp2=false" class="float-right" style="color: var(--primary);"><b>x</b></a>
														</div>                            
													</b-popover>  	
												</b-col>	
											</b-row>									
										</b-th>
										<b-th v-if="clicked == true">
											<b-row>
												<b-col style="padding-top: 10px; padding-bottom: 0px;">
													<a href="javascript:;" @click="sortByDesc('-statusDescription', 3)">
														<span class="header-custom">Status</span>
														<span id="active3" :class="activeCol3"></span>
													</a>
												</b-col>
												<b-col style="padding-top: 10px; padding-bottom: 0px;">
													<span class="ml-1" id="popover-help-sync2" style="cursor: pointer;">
														<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M8.00004 14.6666C11.6819 14.6666 14.6667 11.6818 14.6667 7.99992C14.6667 4.31802 11.6819 1.33325 8.00004 1.33325C4.31814 1.33325 1.33337 4.31802 1.33337 7.99992C1.33337 11.6818 4.31814 14.6666 8.00004 14.6666Z" fill="#C4C4C4"/>
															<path d="M6.05994 6.00014C6.21667 5.55458 6.52604 5.17887 6.93324 4.93956C7.34044 4.70024 7.8192 4.61276 8.28472 4.69261C8.75024 4.77246 9.17248 5.01449 9.47666 5.37582C9.78083 5.73716 9.94731 6.19448 9.9466 6.6668C9.9466 8.00014 7.9466 8.6668 7.9466 8.6668" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M8 11.3333H8.00667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														</svg>											
													</span>
													<b-popover :show.sync="showPopHelp2" target="popover-help-sync2" title="" placement="bottom">
														<div>
															Assim que você cria um template, ele é enviado para análise do Facebook. Veja mais informações <u @click="openHelpSite('https://becon.com.br/como-usar-templates-no-whatsapp/')" style="color: var(--primary);">clicando aqui</u>, ou consulte nosso suporte para boas práticas na construção de templates.
														</div>											
														<div>
															<a href="javascript:;" @click="showPopHelp2=false" class="float-right" style="color: var(--primary);"><b>x</b></a>
														</div>                            
													</b-popover>  
												</b-col>
											</b-row>											
										</b-th> 										
										<b-th>Mídia</b-th>
									</b-tr>
								</b-thead>
								<b-tbody>												
									<b-tr :key="index" v-for="(x, index) in orderedList"						
										v-show="x.visible ==true">												
										<b-td class="td-template"><u class="icon-clickable" @click="viewTemplate(x.templateId)">{{x.templateDescription}}</u></b-td>
										<b-td class="td-phonenumber">{{x.typeDescription}}</b-td>							 
										<b-td class="td-date">
											<b-tooltip v-if="x.body != ''" :target="x.templateId" triggers='hover' placement='leftbottom' custom-class='w-template-list-tooltip'>
												<span class="custom-font-open-14-white">
													{{x.body}}
												</span>							
											</b-tooltip>  												
											<div class="description-ellipsis" :id="x.templateId">
												{{x.body}}
											</div>
										</b-td>
										<b-td class="td-data" v-show="x.departmentAll == true">Todos departamentos</b-td> 
										<b-td class="td-data" v-show="x.departmentAll == false">
											<span :id="'dep'+index"><u>{{x.departmentCount}} Departamentos</u></span>                                                              
											<b-popover                       
												:target="'dep'+index" 
												placement="auto"
												triggers="click">
												<template #title>
													<b-button @click="onClose('dep'+index)" class="close ml-3" aria-label="Close">
														<span class="d-inline-block" aria-hidden="true">&times;</span>
													</b-button>
													Departamentos
												</template>    
												<div :key="departmentId" v-for="departmentId in x.departmentIdList">                                
													<div :key="index_" v-for="(dep, index_) in departmentList" v-show="dep.value == departmentId">
														<span class="custom-font-open-12-400">{{dep.text}}</span>
													</div>      
												</div>                                                                            
											</b-popover>                                  
										</b-td>   									
										<b-td class="td-status">
											<svg :class=" `contact-status-color-${x.status}`" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
												<circle cx="4" cy="4" r="4" fill="`contact-status-color-${2}`"/>
											</svg>
											<span :class=" `ml-2 contact-status-color-${x.status}`">{{x.statusDescription}}</span>
										</b-td> 
										<b-td class="td-data" >{{x.mediaName}}</b-td>                            
										<b-td>  
											<div v-if="isAccountSynced == true" class="icon-clickable" @click="openSynced">
												<svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
													<circle cx="2" cy="2" r="2" fill="#C4C4C4"/>
													<circle cx="2" cy="11" r="2" fill="#C4C4C4"/>
													<circle cx="2" cy="20" r="2" fill="#C4C4C4"/>
												</svg>													
											</div>  								              
											<b-dropdown v-else size="sm"  variant="link" toggle-class="text-decoration-none" right text="Right align" no-caret>
												<template #button-content>
													<svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
														<circle cx="2" cy="2" r="2" fill="#C4C4C4"/>
														<circle cx="2" cy="11" r="2" fill="#C4C4C4"/>
														<circle cx="2" cy="20" r="2" fill="#C4C4C4"/>
													</svg>												
												</template>
												<b-dropdown-item @click="viewTemplate(x.templateId)">
													<span>
														<img src="@/assets/view.png" alt="">
													</span>
													<span class="custom-font-open-12-400 ml-1">
														Visualizar template
													</span>                                        
												</b-dropdown-item>  
												<b-dropdown-item  @click="editDepartments(x.templateId, x.departmentAll)">
													<span>
														<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M10.0779 1.50136C10.2368 1.34241 10.4255 1.21632 10.6332 1.1303C10.8409 1.04428 11.0635 1 11.2883 1C11.513 1 11.7356 1.04428 11.9433 1.1303C12.151 1.21632 12.3397 1.34241 12.4986 1.50136C12.6576 1.66031 12.7837 1.84901 12.8697 2.05669C12.9557 2.26436 13 2.48695 13 2.71174C13 2.93653 12.9557 3.15912 12.8697 3.3668C12.7837 3.57447 12.6576 3.76317 12.4986 3.92212L4.32855 12.0922L1 13L1.90779 9.67145L10.0779 1.50136Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														</svg>
													</span>
													<span class="custom-font-open-12-400 ml-1">
														Editar departamentos
													</span>                                        
												</b-dropdown-item>   
												<b-dropdown-item @click="setTemplateDelete (x.templateId, x.templateName)">
													<span>
														<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M3 4H4.33333H15" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M6.3335 4.00004V2.66671C6.3335 2.31309 6.47397 1.97395 6.72402 1.7239C6.97407 1.47385 7.31321 1.33337 7.66683 1.33337H10.3335C10.6871 1.33337 11.0263 1.47385 11.2763 1.7239C11.5264 1.97395 11.6668 2.31309 11.6668 2.66671V4.00004M13.6668 4.00004V13.3334C13.6668 13.687 13.5264 14.0261 13.2763 14.2762C13.0263 14.5262 12.6871 14.6667 12.3335 14.6667H5.66683C5.31321 14.6667 4.97407 14.5262 4.72402 14.2762C4.47397 14.0261 4.3335 13.687 4.3335 13.3334V4.00004H13.6668Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M7.6665 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M10.3335 7.33337V11.3334" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														</svg>
													</span>
													<span class="custom-font-open-12-400 ml-1">
														Excluir template
													</span>                                        
												</b-dropdown-item>									                                                                                                                                           
											</b-dropdown>           
										</b-td>                           
									</b-tr>
								</b-tbody>
							</b-table-simple>  
							<b-row v-show="rows > perPage" class="pb-5">
								<b-col md="4" class="mt-2">
									<b-row>
										<b-col md="7" class="mt-1">
											<span class="mt-2 lblPagination">Visualizar por página:</span> 
										</b-col>
										<b-col md="4">                                                 
											<b-form-select v-model="selected" :options="perPageOptions" size="sm" @change="setPerPage()"></b-form-select>                
										</b-col>
									</b-row>
								</b-col>
								<b-col class="mr-5">
									<b-row class="d-flex justify-content-end">
										<b-pagination
											v-model="currentPage"
											:total-rows="rows"
											:per-page="perPage"
											pills
											size="sm"
											aria-controls="my-table"
										></b-pagination> 
									</b-row>
								</b-col>        
							</b-row> 						
						</div>
					</b-col>   
				</b-row>          
			</b-col>
		</div> 
		<b-row v-if="toSync == true">
			<span class="sync-message">Sincronizando</span>
			<b-spinner class="sync-spinner" label="Large Spinner"></b-spinner>
		</b-row>
	</div> 
</template>

<script>
import Loading from '@/views/Loading'
import WTemplatesAPI from '@/api/WTemplatesAPI'
import WDepartmentsAPI from '@/api/WDepartmentsAPI'
import Vue2Filters from 'vue2-filters'
import WModal from '@/views/WModal'
import SearchCheckBoxFilterVue from '../../filters/SearchCheckBoxFilter'
export default {
	mixins: [Vue2Filters.mixin],
	components: {
		loading: Loading,    
		'template-modal-synced': WModal,
		'template-modal-delete': WModal,
		'search-check-box-filter': SearchCheckBoxFilterVue
	},
	name: 'WTemplateList',
	data: function () {
		return {
			loading: false,
			filter: '',
			templateId: '',
			templateList: [],
			teste:[],
			filteredList: [],
			originalTemplateList: [],
			showModalDelete: false,
			perPage: 20,
			selected: 20,
			currentPage: 1,  
			totalPage: 1,  
			paginatedItems: [], 
			perPageOptions: [20, 50, 100, 200, 500],  
			templateName: 'teste',  
			componentDeleteModalKey: 0,
			departmentList: [],
			bool: true,
			showPopHelp1: false,
			showPopHelp2: false,
			automationList: [],
			isAccountSynced: false,
			showModalSynced: false, 
			componentSyncedModalKey: 0, 
			activeCol1: 'sortIcon up',
			activeCol2: 'sortIcon up',
			activeCol3: 'sortIcon up',
			activeCol4: 'sortIcon up',			
			clicked: false,	
			clickedCol: 0,
			toSync: false,

		}
	},
	computed: {
		
		rows () {
			return this.templateList.length
		},		
		orderedList () {
			if (this.clickedCol == 0) {
				const items = this.templateList
				items.sort(this.dynamicSort(['-statusDescription', 'templateDescription']))
				return items.slice(
					(this.currentPage - 1) * this.perPage,
					this.currentPage * this.perPage
				)
			} else {
				const items = this.templateList				
				return items.slice(
					(this.currentPage - 1) * this.perPage,
					this.currentPage * this.perPage
				)								
			}					
		},      	
	},    
	methods: { 
		blurScreen () {
			this.toSync = true
			WTemplatesAPI.syncWhatsappTemplates(this.$route.params.companyId,response => {
				if (response){                                                   
					console.log('Operação realizada com sucesso!')                                                               
				} else {                 
					alert('Ja sincronizado!')
				}
			})    
			setTimeout(() => {
				this.toSync = false
			}, 2000)
		},
		sortByAsc (colName) {
			let list = this.templateList 			
			this.clicked = true            
			if (colName == 'templateDescription') {
				this.activeCol1 = 'sortIcon down'
				this.activeCol2 = 'sortIcon up'
				this.activeCol3 = 'sortIcon up'
				this.activeCol4 = 'sortIcon up'				
				this.clickedCol = 1                                       
			}
			if (colName == 'typeDescription') {
				this.activeCol1 = 'sortIcon up'
				this.activeCol2 = 'sortIcon down'
				this.activeCol3 = 'sortIcon up'
				this.activeCol4 = 'sortIcon up'				
  
				this.clickedCol = 2                       
			}   
			if (colName == 'statusDescription') {
				this.activeCol1 = 'sortIcon up'
				this.activeCol2 = 'sortIcon up'
				this.activeCol3 = 'sortIcon down' 
				this.activeCol4 = 'sortIcon up'				
				this.clickedCol = 3              
			}       		                                  
			this.templateList = list.sort(this.dynamicSort([colName]))			     
		},
		sortByDesc (colName, clickCol) {   
			let list = this.templateList 	
			if (this.clickedCol > 0  && this.clickedCol != clickCol) {
				this.activeCol1 = 'sortIcon up'
				this.activeCol2 = 'sortIcon up'
				this.activeCol3 = 'sortIcon up'             
				this.activeCol4 = 'sortIcon up'				
				if (clickCol ==1) {
					this.activeCol1 = 'sortIcon down'
				}  
				if (clickCol ==2) {
					this.activeCol2 = 'sortIcon down'
				}  
				if (clickCol ==3) {
					this.activeCol3 = 'sortIcon down'					
				}       			                                                          
			} else {
				this.activeCol1 = 'sortIcon up'
				this.activeCol2 = 'sortIcon up'
				this.activeCol3 = 'sortIcon up'  			
			}
			
			this.clicked = false  
			this.templateList = list.sort(this.dynamicSort([colName]))         
		},		
		dynamicSort (fields) {
			return function (a, b) {
				return fields
					.map(function (o) {
						let dir = 1
						if (o[0] === '-') {
							dir = -1
							o=o.substring(1)
						}
						if (a[o].toLowerCase() > b[o].toLowerCase()) 
							return dir
						if (a[o].toLowerCase() < b[o].toLowerCase()) 
							return -(dir)
						return 0
					})
					.reduce(function firstNonZeroValue (p,n) {
						return p ? p : n
					}, 0)
			}
		},
		onClose (id) {
			this.$root.$emit('bv::hide::popover', id)   
		},		
		openHelpSite (site) {
			window.open(site, '_blank')
		},    		
		isSynced () {
			WTemplatesAPI.getIsCompanySyncedWithFacebook(this.$route.params.companyId, response => {
				this.isAccountSynced = response.data
			})
		},
		postCancel () {
			this.showModalSynced = false
			this.$router.push({ path: '/automation/'+ this.$route.params.companyId +'/main' })	
		},
		openLinkWhastapp () {
			let number = '5547984124491'
			let wmessage = ''
			wmessage = 'Olá, preciso de ajuda com a sincronização com facebook, podes me ajudar?'	
			let message = wmessage.split(' ').join('%20')
			window.open('https://api.whatsapp.com/send?phone=' + number + '&text=%20' + message, '_blank')
		},                       
		getTemplateList () {
			this.$store.commit('setShowLoading', true)
			WTemplatesAPI.getListByCompanyId(this.$route.params.companyId, response => {
				this.templateList = response.data
				
				this.$store.commit('setShowLoading', false)
				this.templateList.forEach(element => {
					element.visible = true
				})
				this.originalTemplateList = this.templateList				
				this.originalTemplateList = this.addDepartmentIdMap(this.originalTemplateList)
			})
		},
		getDepartments () {
			WDepartmentsAPI.getDepartmentsOfUser(this.$route.params.companyId,response => {
				let departments = response.data
				departments.forEach(item => {
					let dpt = {
						text: item.departmentName,
						value: item.departmentId
					}			
					this.departmentList.push(dpt)   							
				})                                   
			})    			
		},		
		openSynced () {
			if(this.isAccountSynced == true){
				this.showModalSynced = true
				this.componentSyncedModalKey ++
			}			
		},		
		create () {
			if(this.isAccountSynced == true){
				this.showModalSynced = true
				this.componentSyncedModalKey ++
				return
			}
			this.$router.push({path: '/automation/template/'+this.$route.params.companyId+'/create'})  
		},
		setPerPage () {
			this.perPage = this.selected
		},
		searchTemplate () {
			let templateList = this.originalTemplateList
			if (this.filteredList.length != 0){
				templateList = this.filteredList
			}
			this.templateList = []            
			templateList.forEach( list => {                
				if ( list.templateDescription.toLowerCase().includes(this.filter.toLowerCase()) ){
					list.visible = true
					this.templateList.push(list)
				} else {
					list.visible = false
				}                    
			})
		},   
		setTemplateDelete (templateId, name) {  
			this.templateName = name
			this.templateId = templateId
			this.componentDeleteModalKey ++
			this.showModalDelete = true 
		},   

		deleteTemplate (deleteTemplate) {
			this.showModalDelete = false
			if (deleteTemplate){
				WTemplatesAPI.deleteTemplate(this.$route.params.companyId, this.templateId, response => {
					if (response == true) {
						this.getTemplateList()
					} else {
						alert('Falha ao excluir template')
					}					                                                          
				})                
			}
		},                     
		viewTemplate (templateId) {
			this.$router.push({ path: '/automation/template/'+ this.$route.params.companyId +'/'+templateId+'/view/finalize' })  
		},    
		editDepartments (templateId ) {
			this.$router.push({ path: '/automation/template/'+ this.$route.params.companyId +'/'+templateId+'/upd/department' })  	  
		},
		getSelectedItems (list, originalTemplateList) {
			let automationList = []
			originalTemplateList.forEach(item => {
				let containItem = true
				for(let index in list){
					if(!item.departmentIdMap.has(list[index])){
						containItem = false
						break
					}                    
				}
				item.visible = containItem
				if (item.visible){
					automationList.push(item)
				}
			})
			return automationList
		},
		setSelectedItems (list, originalTemplateList) {
			this.automationList = this.getSelectedItems(list, originalTemplateList)
			this.templateList = this.automationList
		},	
		addDepartmentIdMap (automationList) {
			/**
             * Takes a quickreplyList and add a departmentIdMap item to each quick reply in it
             * It is map containing all departmentsId included in the given quick reply
             */
			automationList.forEach(item => {
				let newDepartmentMap = new Map(item.departmentIdList.map(e => [e, true]))
				item['departmentIdMap'] = newDepartmentMap
			})
			return automationList
		},
		clearFilter () {
			if(this.filter == ''){
				this.searchTemplate()
			}
		}  			
	},      
	mounted () {
		this.isSynced()
		this.getTemplateList()
		this.getDepartments()
	}
}
</script>

<style scoped>
.sync-button{
	background-color: white;
	color: var(--secondary);
	border-color: var(--secondary);
}
.custom-select {
    width: auto;
}
u {
    cursor: pointer;
}
th {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #999999 !important;    
}

td {
    margin: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #777777; 
    height: 50px;
    vertical-align: middle !important;
}
.td-template {
    margin-top: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px; 
    color: #777777;
}

.td-phonenumber {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;  
}
.td-date {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-data {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.td-email {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}
.td-property {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; 
    color: #777777;    
}

.td-target {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-fail {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;   
    color: #777777;
}

.td-deliver {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}

.td-readed {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;    
    color: #777777;
}
.sidebar-right-templates {
    display: flex !important;
    width: 100% !important;
    flex-direction: column !important;
    background: white !important;
    padding: 10px;
    margin-right: 50px;
}
.custom-pagination-input {
    width: 50px !important;
}
.custom-pagination-select {
    width: 60px !important;
}
.template-list-scrollable {
    height: 90%;
    width: 100%;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
}

.lblPagination {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px; 
    color: #777777;   
}

.template-tag {
    padding: 5px;
    background: #F5F5F5;
    border-radius: 100px;    
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    min-height: 200px;
    -webkit-overflow-scrolling: touch;
}

.form-control:focus {
    background-color: #F5F5F5;
    box-shadow: none;
}
.description-ellipsis { 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
}

.td-status {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;    
}
.warning-sync {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;    
    color: var(--primary);
}
.sortIcon{
	margin-left: 3px;
	width:0;
	height:0;
	border:7px solid transparent;
	display:block;
}  

.sortIcon.up {
	border-bottom-color:#C0C0C0; 
}

.sortIcon.down {
	border-top-color:#C0C0C0; 
	margin-top: 7px;
}

.header-custom {
	font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #999999;	
}
.blur-screen{
	filter: blur(3px);
	-webkit-filter: blur(3px);	
	background-color:rgba(119, 119, 119, 0.5);
}
.sync-message{
	font-weight: 600;
    font-size: 32px;
    position: absolute;
	font-family: 'Roboto';
    line-height: 40px;
    text-align: center;
    color: var(--primary);
    margin: 10px 0;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.sync-spinner{
	font-weight: 600;
    font-size: 32px;
    position: absolute;
	font-family: 'Roboto';
    line-height: 40px;
    text-align: center;
    color: var(--primary);
    margin: 10px 0;
	top: 42%;
	left: 49.5%;
}
</style>
